import { PERIOD } from '../enums'

const isReminderChanged = (newReminder, oldReminder) => {
  const period = oldReminder.attributes.amount > 0 ? PERIOD.AFTER : PERIOD.BEFORE

  return (
    Math.abs(oldReminder.attributes.amount) !== newReminder.amount ||
    oldReminder.attributes.timeUnit !== newReminder.timeUnit ||
    oldReminder.attributes.description !== newReminder.description ||
    period !== newReminder.period
  )
}

export default isReminderChanged
