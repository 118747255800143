import { Fragment, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  DisclosureButton,
  Divider,
  DropDown,
  Elevation,
  ModalItem,
  Spacing,
  SvgMask,
  Typography,
} from '../../atoms'
import WorkspaceSettingsModal from './WorkspaceSettingsModal'
import { PERMISSIONS } from '../../../enums'
import FolderCreateModal from '../Sidebar/Folders/FolderCreateModal'
import FolderDeleteModal from '../Sidebar/Folders/FolderDeleteModal'
import FolderRenameModal from '../Sidebar/Folders/FolderRenameModal'
import { usePermissions } from '../../../hooks'
import ManageAccess from '../../popups/ManageAccess'
import { getCompanyWorkspace } from '../../../store/selectors'

const WorkspaceDropdown = ({
  className,
  enabled,
  onClick,
  onClose,
  workspaceId,
  folderId,
  overlay,
}) => {
  const { t } = useTranslation()

  const companyWorkspace = useSelector(getCompanyWorkspace)

  const { isPermissionEnabled } = usePermissions({ workspaceId, folderId })
  const folderPermissionsAddSubFolders = isPermissionEnabled(PERMISSIONS.FOLDER_ADD_SUBFOLDERS)
  const folderPermissionsRemoveFolders = isPermissionEnabled(PERMISSIONS.FOLDER_REMOVE_FOLDER)
  const folderPermissionsUpdateFolders = isPermissionEnabled(PERMISSIONS.FOLDER_UPDATE_METADATA)
  const folderPermissionsChangePermissions = isPermissionEnabled(
    PERMISSIONS.FOLDER_CHANGE_PERMISSIONS,
  )
  const userHaveAccessToWorkspaceMoreMenu =
    isPermissionEnabled(PERMISSIONS.WORKSPACE_UPDATE) ||
    isPermissionEnabled(PERMISSIONS.WORKSPACE_MANAGE_MEMBERS)

  const workspaceCreateFolders = isPermissionEnabled(PERMISSIONS.WORKSPACE_MANAGE_FOLDERS)

  const [settingsVisible, setSettingsVisible] = useState(false)
  const [folderCreateVisible, setFolderCreateVisible] = useState(false)
  const [folderDeleteVisible, setFolderDeleteVisible] = useState(false)
  const [folderRenameVisible, setFolderRenameVisible] = useState(false)
  const [manageAccessVisible, setManageAccessVisible] = useState(false)

  const onSettingsClick = useCallback(
    (e) => {
      setSettingsVisible(true)

      if (typeof onClose === 'function') {
        // used to remove More Icon, timeout needed because events race condition
        setTimeout(() => onClose(e), 100)
      }
    },
    [onClose],
  )

  const onManageAccess = useCallback(
    (e) => {
      setManageAccessVisible(true)

      if (typeof onClose === 'function') {
        // used to remove More Icon, timeout needed because events race condition
        setTimeout(() => onClose(e), 100)
      }
    },
    [onClose],
  )

  const onFolderCreateClick = useCallback(
    (e) => {
      setFolderCreateVisible(true)

      if (typeof onClose === 'function') {
        // used to remove More Icon, timeout needed because events race condition
        setTimeout(() => onClose(e), 100)
      }
    },
    [onClose],
  )

  const onFolderDeleteClick = useCallback(
    (e) => {
      setFolderDeleteVisible(true)

      if (typeof onClose === 'function') {
        // used to remove More Icon, timeout needed because events race condition
        setTimeout(() => onClose(e), 100)
      }
    },
    [onClose],
  )

  const onFolderRenameClick = useCallback(
    (e) => {
      setFolderRenameVisible(true)

      if (typeof onClose === 'function') {
        // used to remove More Icon, timeout needed because events race condition
        setTimeout(() => onClose(e), 100)
      }
    },
    [onClose],
  )

  const onSettingsModalClose = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setSettingsVisible(false)
    setFolderCreateVisible(false)
  }, [])

  const createFolderPermission = useMemo(() => {
    if (folderId && folderPermissionsAddSubFolders) {
      return true
    }
    return !folderId && workspaceId !== companyWorkspace?.id && workspaceCreateFolders
  }, [
    companyWorkspace?.id,
    folderId,
    folderPermissionsAddSubFolders,
    workspaceCreateFolders,
    workspaceId,
  ])

  const visible =
    folderPermissionsChangePermissions ||
    userHaveAccessToWorkspaceMoreMenu ||
    createFolderPermission ||
    folderPermissionsUpdateFolders ||
    folderPermissionsRemoveFolders

  if (!visible) {
    return null
  }

  if (!enabled) {
    return (
      <WorkspaceSettingsModal
        visible={settingsVisible}
        onClose={onSettingsModalClose}
        workspaceId={workspaceId}
      />
    )
  }

  return (
    <>
      {settingsVisible && (
        <WorkspaceSettingsModal
          visible={settingsVisible}
          onClose={onSettingsModalClose}
          workspaceId={workspaceId}
        />
      )}
      {folderCreateVisible && (
        <FolderCreateModal
          visible={folderCreateVisible}
          onClose={() => setFolderCreateVisible(false)}
          workspaceId={workspaceId}
          parentFolderId={folderId}
          mainFolder={!folderId}
        />
      )}
      {folderDeleteVisible && (
        <FolderDeleteModal
          visible={folderDeleteVisible}
          onClose={() => setFolderDeleteVisible(false)}
          workspaceId={workspaceId}
          folderId={folderId}
        />
      )}
      {folderRenameVisible && (
        <FolderRenameModal
          visible={folderRenameVisible}
          onClose={() => setFolderRenameVisible(false)}
          workspaceId={workspaceId}
          folderId={folderId}
        />
      )}
      {manageAccessVisible && (
        <ManageAccess
          visible={manageAccessVisible}
          onClose={() => {
            setManageAccessVisible(false)
          }}
          workspaceId={workspaceId}
          folderId={folderId}
        />
      )}
      <DisclosureButton
        iconButton
        icon="dots-vertical"
        onClick={onClick}
        iconAfter={null}
        overlay={overlay}
        className={className}
      >
        {folderId && folderPermissionsChangePermissions ? (
          <ModalItem
            icon="person-2"
            value={t('workspace:ManageAccess')}
            onClick={onManageAccess}
            data-test="dropdown_sidebar_folder_manageAccess"
          />
        ) : undefined}
        {!folderId && (
          <>
            {userHaveAccessToWorkspaceMoreMenu && (
              <ModalItem
                value={t('sidebar:settings')}
                icon="gear"
                onClick={onSettingsClick}
                data-test="dropdown_sidebar_workspace_settings"
              />
            )}
            {workspaceId !== companyWorkspace?.id && <Spacing size="4" />}
          </>
        )}
        {createFolderPermission && (
          <ModalItem
            icon="folder-plus"
            value={t(folderId ? 'workspace:CreateNewSubFolder' : 'workspace:CreateNewFolder')}
            onClick={onFolderCreateClick}
            data-test="dropdown_sidebar_workspace_createNewFolder"
          />
        )}
        {folderId && (
          <Fragment key="folderEdit">
            <>
              <Spacing size="4" />
              <Divider />
              <Spacing size="4" />
            </>
            {folderPermissionsUpdateFolders && (
              <>
                <ModalItem
                  icon="pencil-rectangle"
                  value={t('Rename')}
                  onClick={onFolderRenameClick}
                  data-test="dropdown_sidebar_workspace_renameFolder"
                />
                <Spacing size="4" />
              </>
            )}
            {folderPermissionsRemoveFolders && (
              <ModalItem
                icon="trash"
                value={t('Delete')}
                onClick={onFolderDeleteClick}
                data-test="dropdown_sidebar_workspace_deleteFolder"
              />
            )}
          </Fragment>
        )}
      </DisclosureButton>
    </>
  )
}

export default styled(WorkspaceDropdown)`
  ${Elevation} {
    ${ModalItem} {
      ${Typography} {
        color: ${({ theme }) => theme.palette.type.secondary};
      }

      &:hover {
        background: ${({ theme }) => theme.palette.divider};

        ${Typography} {
          color: ${({ theme }) => theme.palette.theme.primary};
        }
      }
    }
  }
`
