export default {
  en: {
    phraseNeed:
      'This phrase will be needed to restore access to your account if you lose your Security Key or Password',
    phraseSave: 'Save the phrase to a safe place',
    inbox: 'Check your inbox',
    linkInEmail: 'Use the link from email to complete the registration',
    AtLeastCharacters: 'At least 8 characters',
    Short: 'Short',
    Weak: 'Weak',
    Good: 'Good',
    Great: 'Great',
    Strength: 'Strength',
    recoveryDeleteOldPhrase:
      'Attention! Delete the old Recovery Phrase (Recovery Phrase.txt). It is no longer valid.',
    recoveryTooltip1: '(New Recovery Phrase.txt) is an arbitary string of 12 words.',
    recoveryTooltip2:
      'You will need this phrase in case you would lose your password. With help of this phrase, you can recover it.',
    recoveryTooltip3: 'Please keep this phrase in a safe place and do not give it to anyone.',
    recoveryLabel:
      'To improve the security of your account, you need to get a new Recovery Phrase.',
    recoveryNewPhrase: 'New Recovery Phrase',
    signature: 'Signature',
    text: 'Text',
    yourAccountIsBeingSetUp: 'Your account is being set up',
    pleaseWait: 'Please wait…',
    phraseTitle: 'Recovery Phrase',
    IAgreeToThe:
      '<0>I agree to the</0><1/><2>Terms and Conditions</2><3/><4>as well as to the</4><5/><6>Privacy Policy</6>',
    downloadRecoveryPhrase: 'Download Recovery Phrase',
    iDownloadedRecoveryPhrase:
      'I downloaded the Recovery Phrase.txt file and saved it in a safe place.',
    continueRegistration: 'Continue registration',
    thisPhraseRequired: 'This phrase is required in case of password recovery.',
    downloadFirst: 'Download Recovery Phrase first',
  },
  de: {
    phraseNeed:
      'Diese Phrase wird benötigt, um den Zugang zu deinem Konto wiederherzustellen, falls dein Sicherheitsschlüssel oder Passwort verloren geht.',
    phraseSave: 'Speichere die Phrase an einem sicheren Ort ab',
    inbox: 'Überprüfe deinen E-Mail-Eingang',
    linkInEmail: 'Verwende den Link aus der Email, um die Registrierung abzuschließen',
    AtLeastCharacters: 'Mindestens 8 Zeichen',
    Short: 'Kurz',
    Weak: 'Schwach',
    Good: 'Gut',
    Great: 'Prima',
    Strength: 'Stärke',
    recoveryDeleteOldPhrase:
      'Achtung! Bitte lösche die alte Wiederherstellungsphrase (Recovery Phrase.txt). Sie ist nicht mehr gültig.',
    recoveryTooltip1: '(New Recovery Phrase.txt) ist eine beliebige Folge aus 12 Wörtern.',
    recoveryTooltip2:
      'Diese Phrase wird für den Fall benötigt, falls dein Passwort verloren gehen. Mit Hilfe dieser Phrase kannst du ihn wiederherstellen.',
    recoveryTooltip3:
      'Bitte bewahre die Wiederherstellungsphrase an einem sicheren Ort auf und geben sie nicht an Dritte weiter.',
    recoveryLabel:
      'Um die Sicherheit deines Nutzerkontos zu verbessern, benötigst du eine neue Wiederherstellungsphrase.',
    recoveryNewPhrase: 'Neue Wiederherstellungsphrase',
    signature: 'Unterschrift',
    text: 'Text',
    yourAccountIsBeingSetUp: 'Dein Konto wird gerade eingerichtet.',
    pleaseWait: 'Bitte warte…',
    phraseTitle: 'Wiederherstellungsphrase',
    IAgreeToThe:
      '<0>Ich akzeptiere die</0><1/><2>Allgemeinen Servicebedingungen</2><3/><4>und die</4><5/><6>Datenschutzerklärung</6>',
    downloadRecoveryPhrase: 'Lade Wiederherstellungsphrase herunter',
    iDownloadedRecoveryPhrase:
      'Ich habe die Recovery Phrase.txt heruntergeladen und an einem sicheren Ort abgespeichert.',
    continueRegistration: 'Registrierung fortsetzen',
    thisPhraseRequired: 'Diese Phrase wird benötigt, falls dein Passwort verloren geht.',
    downloadFirst: 'Lade erst die Wiederherstellungsphrase herunter',
  },
  fr: {
    securityKeyNeed1: 'La phrase de récupération est nécessaire en cas de perte du mot de passe.',
    downloadRecoveryPhrase1: 'Télécharger le',
    downloadRecoveryPhrase2_marker1: 'Phrase de récupération',
    downloadRecoveryPhrase3: '.',
    phraseNeed:
      "Cette phrase sera nécessaire pour rétablir l'accès à votre compte si vous perdez votre clé de sécurité ou votre mot de passe.",
    phraseSave: 'Sauvegarder la phrase dans un endroit sûr',
    phraseTooltip0: '(Recovery Phrase.txt) est une chaîne arbitraire de 12 mots.',
    phraseTooltip1:
      "Vous aurez besoin de cette phrase si vous perdez votre mot de passe ou votre clé de sécurité. Avec l'aide de cette phrase, vous pouvez récupérer ces mots de passe et clés de sécurité.",
    phraseTooltip2: 'Veuillez conserver cette phrase en lieu sûr et ne la donner à personne.',
    phrase: 'La phrase de récupération',
    inbox: 'Vérifiez votre boîte de réception',
    linkInEmail: "Utilisez le lien figurant dans l'e-mail pour compléter l'enregistrement",
    AtLeastCharacters: 'Au moins 8 caractères',
    Short: 'Court',
    Weak: 'Faible',
    Good: 'Bon',
    Great: 'Excellent',
    Strength: 'Fort',
    recoveryDeleteOldPhrase:
      "Attention ! Supprimez l'ancienne phrase de récupération (Recovery Phrase.txt). Elle n'est plus valable.",
    recoveryTooltip1: '(New Recovery Phrase.txt) est une chaîne arbitraire de 12 mots.',
    recoveryTooltip2:
      "Vous aurez besoin de cette phrase au cas où vous perdriez votre mot de passe. À l'aide de cette phrase, vous pourrez le récupérer.",
    recoveryTooltip3: 'Veuillez conserver cette phrase en lieu sûr et ne la donner à personne.',
    recoveryLabel:
      'Pour améliorer la sécurité de votre compte, vous devez obtenir une nouvelle phrase de récupération.',
    recoveryNewPhrase: 'Nouvelle phrase de récupération',
    signature: 'Signature',
    text: 'Texte',
    yourAccountIsBeingSetUp: 'Votre compte est en cours de création',
    pleaseWait: 'Veuillez patienter…',
    phraseTitle: 'Phrase de récupération',
    savePhrase: 'Sauvegarder la phrase dans un endroit sûr',
    IAgreeToThe:
      "<0>j'accepte</0><1/><2>les Termes et Conditions</2><3/><4>ainsi que les conditions </4><5/><6>Privacy Policy</6>",
    downloadRecoveryPhrase: 'Télécharge la phrase de récupération',
    iDownloadedRecoveryPhrase:
      "J'ai téléchargé le fichier Recovery Phrase.txt et l'ai enregistré dans un endroit sûr.",
    continueRegistration: "Continuer l'enregistrement",
    thisPhraseRequired: 'Cette phrase est nécessaire en cas de récupération du mot de passe.',
    downloadFirst: "Télécharge d'abord la phrase de récupération",
  },
}
