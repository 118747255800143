/* eslint-disable eqeqeq */
import compact from 'lodash/compact'

import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { WORKSPACE_ROLES } from '../enums'
import { selectAccountId, selectCompanyWorkspaceId, selectIncluded } from '../store/selectors'

const formFalsyResponse = () => ({ workspace: null, membership: null, canCreateContracts: false })

const useWorkspace = (workspaceId) => {
  const selectWorkspace = ({ workspaces }) => workspaces.allWorkspaces[workspaceId]
  const selectWorkspaceInfo = createSelector(
    [selectWorkspace, selectCompanyWorkspaceId, selectIncluded, selectAccountId],
    (workspace, companyWorkspaceId, included, accountId) => ({
      workspace,
      companyWorkspaceId,
      included,
      accountId,
    }),
  )

  const { workspace, companyWorkspaceId, included, accountId } = useSelector((state) =>
    selectWorkspaceInfo(state, workspaceId),
  )

  if (!workspace || included.workspacememberships == {}) {
    return formFalsyResponse()
  }

  if (workspace.id === companyWorkspaceId) {
    return formFalsyResponse()
  }

  const {
    relationships: {
      members: { data: members },
    },
  } = workspace

  const memberships = compact(members.map((m) => included?.workspacememberships[m.id]))

  if (memberships.length === 0) {
    // not all data is loaded
    return formFalsyResponse()
  }

  const membership = memberships.find((m) => m.relationships.grantedToAccount.data.id === accountId)

  if (!membership) {
    return formFalsyResponse()
  }

  const canCreateContracts =
    membership.attributes.role === WORKSPACE_ROLES.ADMIN ||
    membership.attributes.role === WORKSPACE_ROLES.EDITOR

  return { workspace, membership, canCreateContracts }
}

export default useWorkspace
