import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'

import { DisclosureButton, ModalItem, Spacing } from '../atoms'

import { changeLanguage } from '../../actions/account'
import { LANGUAGES } from '../../enums'
import { selectAccountLang } from '../../store/selectors'

const languageStr = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
}

const DEFAULT_PLACEMENT = 'bottom-end'

const LanguageSwitcher = ({ className, placement = DEFAULT_PLACEMENT, disabled, value }) => {
  const lang = useSelector(selectAccountLang)
  const langToUse = value || lang
  const dispatch = useDispatch()
  const { palette } = useTheme()

  const changeLanguageToEnglish = () => dispatch(changeLanguage({ lang: LANGUAGES.EN }))
  const changeLanguageToDeutsch = () => dispatch(changeLanguage({ lang: LANGUAGES.DE }))
  const changeLanguageToFrancais = () => dispatch(changeLanguage({ lang: LANGUAGES.FR }))

  return (
    <DisclosureButton
      data-test="dropdown_language"
      value={languageStr[langToUse]}
      placement={placement}
      className={className}
      disabled={!!disabled}
    >
      <ModalItem
        value={languageStr.en}
        onClick={changeLanguageToEnglish}
        iconAfter={langToUse === LANGUAGES.EN ? 'checkmark' : undefined}
        iconColor={palette.accent.success}
        data-test="dropdown_languageEN"
      />
      <Spacing size={4} />
      <ModalItem
        value={languageStr.de}
        onClick={changeLanguageToDeutsch}
        iconAfter={langToUse === LANGUAGES.DE ? 'checkmark' : undefined}
        iconColor={palette.accent.success}
        data-test="dropdown_languageDE"
      />
      <Spacing size={4} />
      <ModalItem
        value={languageStr.fr}
        onClick={changeLanguageToFrancais}
        iconAfter={langToUse === LANGUAGES.FR ? 'checkmark' : undefined}
        iconColor={palette.accent.success}
        data-test="dropdown_languageFR"
      />
    </DisclosureButton>
  )
}

export default styled(LanguageSwitcher)`
  width: fit-content;

  ${ModalItem} {
    min-width: 95px;

    > div:first-child {
      min-width: initial;
    }
  }
`
