import styled from 'styled-components'

import { Flex, Spacing, Typography, SvgMask } from '../../atoms'

const ReadOnlyIban = ({ number, ...props }) => {
  return (
    <Flex {...props}>
      <SvgMask icon="IBAN" />
      <Spacing size="8" />
      <Typography variant="14">IBAN: •••• {number}</Typography>
    </Flex>
  )
}

export default styled(ReadOnlyIban)`
  ${SvgMask} {
    background-color: ${({ theme }) => theme.palette.type.secondary};
  }
`
