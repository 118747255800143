import styled from 'styled-components'

import React from 'react'
import { Spacing, Button, Divider } from '../../atoms'

const ShowMore = ({ onClick, className, ...props }) => {
  return (
    <div className={className} {...props}>
      <ButtonContainer>
        <Button
          width="fit-content"
          tertiary
          iconAfter="chevron-small-down"
          onClick={onClick}
          i18n="home:showMore"
          data-test="button_activity_showMore"
        >
          Show more
        </Button>
      </ButtonContainer>
      <Line primary />
    </div>
  )
}

const Line = styled(Divider)`
  width: 100%;
  position: absolute;
  top: 12px;
`

const ButtonContainer = styled.div`
  background: #ffffff;
  z-index: 11;
`

export default styled(ShowMore)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  background: white;
  border-radius: 4px;
  position: relative;
  z-index: 10;
`
