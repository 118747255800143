import { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { createPortal } from 'react-dom'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Button, Modal, Spinner } from '../../../atoms'

import { useDispatchAsync } from '../../../../hooks'
import {
  deleteFolder,
  getFolders,
  setActiveWorkspace,
  updateFolders,
} from '../../../../actions/workspaces'
import { createNotification } from '../../../../actions/notifications'
import { generateSlug } from '../../../../pages/Workspaces/slugs'
import { selectWorkspaces } from '../../../../store/selectors'

const FolderDeleteModal = ({ visible, onClose, workspaceId, folderId, className, ...props }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [deleteAnimation, setDeleteAnimation] = useState(false)
  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    ...workspaces,
  }))
  const { activeFolder, allWorkspaces, allFolders } = useSelector(selectData)

  const onDelete = async (e) => {
    setDeleteAnimation(true)
    const folderTitle = allFolders[folderId]?.attributes.name

    await dispatchAsync(deleteFolder, { folderId })

    dispatch(
      createNotification({
        value: t('workspace:FolderDeleted'),
        description: folderTitle,
        state: 'success',
      }),
    )
    setDeleteAnimation(false)

    if (typeof onClose === 'function') {
      onClose(e)
    }

    if (activeFolder === folderId) {
      dispatch(setActiveWorkspace({ id: workspaceId, folderId: null }))
      navigate(`/workspaces/${generateSlug(allWorkspaces[workspaceId])}`)
    }

    await dispatchAsync(updateFolders, { workspaceId })
    await dispatchAsync(getFolders, {})
  }

  return createPortal(
    <Modal
      headerI18n="workspace:deleteFolder"
      subHeader={t('workspace:AreYouSureAllSubFolders')}
      width="552"
      visible={visible}
      onClose={onClose}
      type="dialog"
      buttons={
        <>
          <Button
            i18n="cancel"
            width="fit-content"
            secondary
            onClick={onClose}
            data-test="button_contract-draft_modal_cancel"
          >
            Cancel
          </Button>
          <Button
            i18n={deleteAnimation ? undefined : 'Delete'}
            width="fit-content"
            tone="critical"
            onClick={deleteAnimation ? undefined : onDelete}
            data-test="button_workspace_folderDeleteModal_deleteFolder"
            process={deleteAnimation}
          >
            Delete
          </Button>
        </>
      }
      {...props}
    />,
    document.body,
  )
}

export default styled(FolderDeleteModal)``
