import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18next'

import getToken from '../../store/TokenExtra'
import { logout } from '../../actions/logout'
import { createNotification } from '../../actions/notifications'
import { selectCurrentUser } from '../../store/selectors'

const JWT_TIMEOUT_CHECK = 1000 * 60 * 1 /* msec */
const MAX_JWT_REMAINING_TTL = 10 /* minutes */
const MSEC_IN_SEC = 1000 /* msec */
const MSEC_IN_MIN = 1000 * 60 /* msec */

const JWTRuntime = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentUser = useSelector(selectCurrentUser)
  const [diff, setData] = useState(null)

  useEffect(() => {
    let timeoutToken

    const effectHandler = () => {
      if (!currentUser) {
        return
      }

      let decodedJwt

      try {
        const jwt = getToken()
        decodedJwt = jwtDecode(jwt)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return
      }

      if (!decodedJwt) {
        return
      }

      const expirationDate = new Date(decodedJwt.exp * MSEC_IN_SEC)
      const diffInMiliseconds = expirationDate - new Date()
      const diffInMinutes = diffInMiliseconds / MSEC_IN_MIN

      setData([diffInMinutes])

      if (diffInMinutes < MAX_JWT_REMAINING_TTL) {
        dispatch(createNotification({ value: t('yourSession'), state: 'warning' }))
        dispatch(logout())
      }

      if (timeoutToken) {
        clearTimeout(timeoutToken)
      }
      timeoutToken = setTimeout(effectHandler, JWT_TIMEOUT_CHECK)
    }

    effectHandler()

    return () => {
      if (timeoutToken) {
        clearTimeout(timeoutToken)
        timeoutToken = null
      }
    }
  }, [currentUser, dispatch, t])

  return (
    <div data-test="core_jwt-runtime" data-diff={currentUser && diff ? Math.round(diff) : 'void'} />
  )
}

export default JWTRuntime
