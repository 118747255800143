import { createAction } from '@reduxjs/toolkit'

import { resetEncryptionSharingMap } from '../runtime/encryptionSharingMap'

export const logout = createAction('logout', () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user_id')
  localStorage.removeItem('user_firstName')
  localStorage.removeItem('user_lastName')
  localStorage.removeItem('avatarColor')
  localStorage.removeItem('user_emailAddress')
  localStorage.removeItem('user_type')
  localStorage.removeItem('user_activated')
  localStorage.removeItem('user_publicKey')
  localStorage.removeItem('user_address')
  localStorage.removeItem('user_secret')
  localStorage.removeItem('user_privateKey')
  localStorage.removeItem('features')
  localStorage.removeItem('accountCreatedOn')
  localStorage.removeItem('accountCompanyRole')
  localStorage.removeItem('companyId')

  resetEncryptionSharingMap()

  return {}
})
