import { useLifecycles } from 'react-use'
import { useDispatch } from 'react-redux'
import pick from 'lodash/pick'

import { updateBoundingTarget } from '../actions/boundingTargets'

const useBoundingRef = (ref, id) => {
  const dispatch = useDispatch()

  const tryBounding = () => {
    const bounding = ref.current?.getBoundingClientRect()

    if (bounding?.left) {
      dispatch(
        updateBoundingTarget({
          id,
          // client bounding is special non-seriazable object
          // so we must gather its properties with the lodash/pick
          data: pick(bounding, ['x', 'y', 'width', 'height', 'top', 'right', 'bottom', 'left']),
        }),
      )
    } else {
      setTimeout(tryBounding, 1000)
    }
  }

  useLifecycles(() => {
    // need to wait for ref to be set :(
    setTimeout(tryBounding, 1000)
  })
}

export default useBoundingRef
