import { Suspense, useEffect, useState } from 'react'
import { Await, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HorizontalRow, PageContainer, Spinner, VerticalRow } from '../components/atoms'
import Sidebar from '../components/organisms/Sidebar'
import renderRouteList, { AdminPage, LoginPage, RecoveryPage, SignupPage } from './RouteList'

import { HistorySpy } from '../history'
import i18n from '../i18n'
import TFAEnforcementBanner from '../components/organisms/TFAEnforcementBanner'
import { printf } from '../hooks/printf'
import {
  applicationRedirect,
  selectAccountLang,
  selectApplicationShowSidebar,
  selectCurrentUser,
} from '../store/selectors'
import getDefaultRoute from './getDefaultRoute'

const Router = () => {
  const location = useLocation()
  const { pathname } = location

  const currentUser = useSelector(selectCurrentUser)
  const lang = useSelector(selectAccountLang)
  const showSidebar = useSelector(selectApplicationShowSidebar)
  const redirect = useSelector(applicationRedirect)

  const isSidebarDisabled = !currentUser || !showSidebar
  const isAdmin = currentUser?.userType === 'admin'

  const DEFAULT_REDIRECT = getDefaultRoute(currentUser?.userType, redirect)

  // we need to wait 1 tick for account information to be set in redux store
  const [firstTick, setFirstTick] = useState(true)
  useEffect(() => {
    if (firstTick && currentUser) {
      setFirstTick(false)
    }
  }, [firstTick, currentUser])

  // set initial language
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  useEffect(() => {
    // eslint-disable-next-line no-console
    printf(() => console.log(`>> Router: path is ${pathname}`))
  }, [pathname])

  return (
    <PageContainer>
      <HistorySpy />
      <VerticalRow>
        <Sidebar disabled={isSidebarDisabled} />
        <HorizontalRow style={{ flex: 'auto', margin: 0 }} direction="column" wrap="nowrap">
          <Suspense fallback={<Spinner global />}>
            <Routes>
              <Route
                path="/login"
                element={currentUser ? <Navigate to={DEFAULT_REDIRECT} /> : <LoginPage />}
              />
              <Route
                path="/sign-up"
                element={currentUser ? <Navigate to={DEFAULT_REDIRECT} /> : <SignupPage />}
              />
              <Route
                path="/resetPassword"
                element={currentUser ? <Navigate to={DEFAULT_REDIRECT} /> : <RecoveryPage />}
              />
              <Route
                path="/"
                element={<Navigate to={currentUser ? DEFAULT_REDIRECT : '/login'} />}
              />
              <Route path="/contracts" element={<Navigate to="/my-contracts" />} />
              <Route
                path="/admin"
                element={
                  <Await fallback={<Spinner />} resolve={() => !firstTick}>
                    {isAdmin ? <AdminPage /> : <Navigate to={currentUser ? '/admin' : '/login'} />}
                  </Await>
                }
              />
              <Route path="/_blank" element={<Navigate to="/playground" />} />
              <Route
                path="/company/settings/billing"
                element={<Navigate to="/company/settings/billing/overview" />}
              />
              {renderRouteList()}
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </Suspense>

          <TFAEnforcementBanner disabled={isSidebarDisabled} />
        </HorizontalRow>
      </VerticalRow>
    </PageContainer>
  )
}

export default Router
