const TemplateOfType = {
  ADD: 'add',
  CHANGE: 'change',
  REMOVE: 'remove',
  DM_ARCHIVED: 'dm_archived',
  DM_SIGNED: 'dm_signed',
  DA_INVITATION_VIEWER_ADDED: 'da_invitation_viewer_added',
  DM_SIGNATURE_REQUEST_ADDED: 'dm_signature_request_added',
  DM_SIGNATURE_REQUEST_REMOVED: 'dm_signature_request_removed',
}

export default TemplateOfType
