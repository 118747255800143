export const IMPORT_STEPS = {
  WORKSPACE: 'WORKSPACE',
  FILES: 'FILES',
  UPLOADED: 'UPLOADED',
  SELECT: 'SELECT',
  PROGRESS: 'PROGRESS',
  AIPROFILES: 'AIPROFILES',
}

export const IMPORT_TYPE = {
  BASIC: 'import_basic',
  SMART: 'import_smart',
}
