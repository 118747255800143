import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { COMPANY_SUBSCRIPTION } from '../enums'
import { callApi } from '../utils/callApi'

export const setCompanyAdmins = createAction('company/admins/set')
export const setCompanySettings = createAction('company/settings/set')
export const setCompanyRoles = createAction('company/roles/set')
export const updateCompanyRoles = createAction('company/roles/update')

export const fetchCompanySubscription = createAsyncThunk('company/subscription/fetch', async () => {
  const data = await callApi({ endpoint: 'subscriptions' })

  if (data === '' || !data) {
    return { subscription: COMPANY_SUBSCRIPTION.FREE_SUBSCRIPTION }
  }

  return { subscription: data }
})
