import React, { Fragment } from 'react'
import styled, { useTheme } from 'styled-components'
import { Link } from 'react-router-dom'

import { Flex, Spacing, SvgMask, Typography } from '../atoms'

const TitleTypography = styled.div`
  display: grid;

  ${Typography} {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
`

const BreadcrumbTypography = styled(Typography)`
  max-width: 40vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
`

const renderBreadcrumb = (breadcrumb, isLast, id, variant, delimiter, theme, variantActive) => {
  const { title, route, onClick, noAction, lastInactive, activeTitle, leftContent } = breadcrumb

  const view = route ? (
    <Link to={route}>
      <BreadcrumbTypography
        variant={variant}
        color="tertiary"
        i18n={title}
        data-test="typography_link_titleBar_title"
      >
        {title}
      </BreadcrumbTypography>
    </Link>
  ) : (
    <div>
      {isLast && !noAction ? (
        <TitleTypography>
          <Typography
            variant="header 18px medium"
            i18n={breadcrumb.title}
            data-test="typography_titleBar_title"
          >
            {breadcrumb.title}
          </Typography>
        </TitleTypography>
      ) : (
        <BreadcrumbTypography
          i18n={title}
          variant={(isLast && !lastInactive) || activeTitle ? variantActive : variant}
          onClick={onClick}
          color={(isLast && !lastInactive) || activeTitle ? 'primary' : 'tertiary'}
          cursor={onClick ? 'pointer' : 'inherit'}
        >
          {title}
        </BreadcrumbTypography>
      )}
    </div>
  )

  return (
    <Fragment key={title}>
      {leftContent}
      {view}
      {!isLast &&
        (delimiter ? (
          <>
            <Spacing size="4" />
            <SvgMask icon={delimiter} color={theme.palette.type.tertiary} />
            <Spacing size="4" />
          </>
        ) : (
          <>
            <Spacing size="12" />
            <Typography color="tertiary" variant={variant}>
              /
            </Typography>
            <Spacing size="12" />
          </>
        ))}
    </Fragment>
  )
}

const ContentHeader = ({
  children,
  id,
  breadcrumbs = [],
  rightBlock,
  title,
  delimiter,
  variantActive = 'header 18px medium',
  variant = 'header 18px medium',
  ...props
}) => {
  const theme = useTheme()

  if (title) {
    return (
      <div {...props}>
        <Typography i18n={title} variant={variant} data-test="typography_contentHeader_title">
          {title}
        </Typography>
        {rightBlock}
      </div>
    )
  }

  return (
    <Flex {...props} content="space-between" data-test="contentHeader">
      <Breadcrumbs>
        {breadcrumbs.map((b, i) =>
          renderBreadcrumb(
            b,
            i === breadcrumbs.length - 1,
            id,
            variant,
            delimiter,
            theme,
            variantActive,
          ),
        )}
        {children}
      </Breadcrumbs>
      {rightBlock}
    </Flex>
  )
}

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${({ theme }) => theme.palette.type.tertiary};
  }

  ${TitleTypography} {
    color: ${({ theme }) => theme.palette.type.primary};
  }
`

export default styled(ContentHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`
