import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

const slideAnimation = keyframes`
  0% { width: 0px }
  100% { width: 264px }
`

const slideOutAnimation = keyframes`
  0% { width: 264px }
  100% { width: 0px }
`

const BaseAnimatedContainer = ({ children, visible, delay, ...props }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (visible && !show) {
      setShow(true)
    } else if (!visible && show) {
      setTimeout(() => setShow(false), delay)
    }
  }, [visible, show, delay])

  if (!show) {
    return null
  }

  return (
    <div {...props} data-hide={!visible}>
      {children}
    </div>
  )
}

BaseAnimatedContainer.propTypes = {
  delay: PropTypes.number,
  visible: PropTypes.bool,
}

const AnimatedContainer = styled(BaseAnimatedContainer)`
  animation: ${slideAnimation} 0.3s;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  &[data-hide='true'] {
    animation: ${slideOutAnimation} 0.3s;
  }
`

export default AnimatedContainer
