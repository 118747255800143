import React, { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { createPortal } from 'react-dom'

import BasePopup from '../Modal/BasePopup'
import Elevation from '../Elevation'

const Popup = ({
  enabled,
  children,
  tooltip,
  className,
  trigger = 'hover',
  options = {},
  isAnimated,
  portal,
  portalClassName,
  canUpdate,
  autoHeight,
  subTooltip,
  style,
  width,
  ...props
}) => {
  const theme = useTheme()
  const [target, setTarget] = useState(null)
  const [hiddenTooltip, setHiddenTooltip] = useState(true)
  const [mouseOnTarget, setMouseOnTarget] = useState(false)
  const [mouseOnTooltip, setMouseOnTooltip] = useState(false)
  const [hideTooltip, setHideTooltip] = useState(false)

  useEffect(() => {
    let timeoutId

    if (subTooltip) {
      if (!mouseOnTooltip && !mouseOnTarget) {
        timeoutId = setTimeout(() => {
          setHideTooltip(true)
        }, 500)
      } else {
        setHideTooltip(false)
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [mouseOnTarget, mouseOnTooltip, subTooltip, hideTooltip])

  useEffect(() => {
    if (hideTooltip) {
      setHiddenTooltip(!mouseOnTooltip && !mouseOnTarget)
    }
  }, [hideTooltip, mouseOnTarget, mouseOnTooltip])

  const tooltipedChildren = useMemo(() => {
    let handlers = {}

    if (trigger === 'hover') {
      if (subTooltip) {
        handlers = {
          ...handlers,
          onMouseEnter: () => {
            setHiddenTooltip(false)
            setMouseOnTarget(true)
          },
          onMouseLeave: () => setMouseOnTarget(false),
        }
      } else {
        handlers = {
          ...handlers,
          onMouseEnter: () => setHiddenTooltip(false),
          onMouseLeave: () => setHiddenTooltip(true),
        }
      }
    }

    if (trigger === 'click') {
      handlers = {
        ...handlers,
        onClick: () => setHiddenTooltip(false),
      }
    }

    return React.cloneElement(children, handlers)
  }, [children, subTooltip, trigger])

  // apply close onClick on every click inside the tooltip
  // used when there is several ModalItems inside the tooltip
  const modifiedTooltip = useMemo(() => {
    if (subTooltip) {
      return React.cloneElement(tooltip, {
        onMouseEnter: () => {
          setMouseOnTooltip(true)
          setMouseOnTarget(false)
        },
        onMouseLeave: () => setMouseOnTooltip(false),
      })
    }
    return tooltip
  }, [subTooltip, tooltip])

  const modal = useMemo(() => {
    if (portal) {
      return createPortal(
        <BasePopup
          overlay={trigger === 'click'}
          onClose={() => setHiddenTooltip(true)}
          hidden={hiddenTooltip}
          target={target}
          options={{
            placement: 'bottom-start',
            ...options,
          }}
          isAnimated={isAnimated}
          width={width}
          canUpdate={canUpdate}
          autoHeight={autoHeight}
          {...props}
        >
          {modifiedTooltip}
        </BasePopup>,
        document.body,
      )
    }
    return (
      <BasePopup
        overlay={trigger === 'click'}
        onClose={() => setHiddenTooltip(true)}
        hidden={hiddenTooltip}
        target={target}
        options={{
          placement: 'bottom-start',
          ...options,
        }}
        isAnimated={isAnimated}
        canUpdate={canUpdate}
        autoHeight={autoHeight}
        width={width}
        {...props}
      >
        {modifiedTooltip}
      </BasePopup>
    )
  }, [
    autoHeight,
    canUpdate,
    hiddenTooltip,
    isAnimated,
    modifiedTooltip,
    options,
    portal,
    props,
    target,
    trigger,
    width,
  ])

  if (!enabled) {
    return children
  }

  return (
    <div ref={setTarget} className={className}>
      {modal}
      {tooltipedChildren}
    </div>
  )
}

export default styled(Popup)`
  ${Elevation} {
    padding: 0;
  }
`
