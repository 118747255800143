const add = (e, parseResult) => {
  const type = e.type.toString()
  let result = parseResult

  if (result[type]) {
    if (result[type].data) {
      result[type].data.push({ ...e })
    } else {
      result[type].data = [{ ...e }]
    }
  } else {
    result = {
      ...result,
      [type]: {
        data: [
          {
            ...e,
          },
        ],
      },
    }
  }
  return result
}

export const parseResponse = ({ json }) => {
  let parseResult = {}
  const data = Array.isArray(json.data) ? json.data : [json.data]

  data.forEach((e) => {
    parseResult = add(e, parseResult)
  })
  if (json.included) {
    json.included.forEach((e) => {
      parseResult = add(e, parseResult)
    })
  }
  return parseResult
}
