import { useState, useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'

const GenericInput = ({ value: inputValue, type, onClick, autoFocus, datalist, ...props }) => {
  const ref = useRef()

  const [inputId, setInputId] = useState(null)

  useEffect(() => {
    setInputId(v4())
  }, [])
  useEffect(
    () =>
      autoFocus &&
      ref?.current.focus(autoFocus === 'prevent-scroll' ? { preventScroll: true } : undefined),
    [autoFocus],
  )

  const onMouseClick = useCallback(
    (e) => {
      if (typeof onClick === 'function') {
        onClick(e)
      }
    },
    [onClick],
  )

  return (
    <>
      <input
        ref={ref}
        onClick={onMouseClick}
        list={datalist ? inputId : undefined}
        data-id={inputId}
        type={type}
        value={type === 'password' ? undefined : inputValue}
        {...props}
      />
      {datalist && (
        <datalist id={inputId}>
          {datalist.map(({ value, view }) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <option value={value} key={value}>
              {view}
            </option>
          ))}
        </datalist>
      )}
    </>
  )
}

const BaseInput = styled(GenericInput)`
  height: 30px;
  -webkit-appearance: none;
  ${({ theme }) => theme.typography['body-large-02']};
  border-radius: ${({ theme }) => theme.radius['input-border-radius']};
  padding: ${({ theme }) =>
    `${theme.space['input-padding-vertical']} ${theme.space['input-padding-horizontal']}`};
  -webkit-text-fill-color: ${({ theme, disabled }) =>
    disabled ? theme.component.input.value['input-color-value-disabled'] : undefined};
  color: ${({ theme, disabled, readOnly }) => {
    if (disabled) return theme.component.input.value['input-color-value-disabled']
    if (readOnly) return theme.component.input.value['input-color-value-read-only']
    return theme.component.input.value['input-color-value-filled']
  }};
  box-sizing: border-box;
  font-family:
    Inter,
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  background-color: ${({ theme, disabled, readOnly }) =>
    disabled || readOnly
      ? theme.component.input.surface['input-color-background-disabled']
      : theme.component.input.surface['input-color-surface']};
  box-shadow: none;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  outline: none;
  opacity: 1;
  ::placeholder {
    color: ${({ theme }) => theme.component.input.value['input-color-value']};
    opacity: 1;
  }

  :hover {
    border: 1px solid
      ${({ $error, theme, disabled, readOnly }) => {
        if (disabled || readOnly) {
          return theme.component.input.border['input-color-border-disabled']
        }
        return $error
          ? theme.component.input.border['input-color-border-error']
          : theme.component.input.border['input-color-border-hover']
      }};
  }

  :focus {
    border: ${({ theme, readOnly }) =>
      !readOnly && `1px solid ${theme.component.input.border['input-color-border-active']}`};
  }

  border: 1px solid
    ${({ $error, theme, disabled, readOnly }) => {
      if (disabled || readOnly) {
        return theme.component.input.border['input-color-border-disabled']
      }
      if ($error) {
        return theme.component.input.border['input-color-border-error']
      }
      return theme.component.input.border['input-color-border']
    }};

  &::-webkit-calendar-picker-indicator,
  &::-webkit-list-button,
  &::-webkit-input-placeholder {
    display: none !important;
  }

  &::-webkit-credentials-auto-fill-button {
    opacity: 0;
    pointer-events: none;
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset;
  }

  &:-moz-autofill {
    -moz-box-shadow: 0 0 0 30px white inset;
  }
`

export const Wrapper = styled.div`
  position: relative;

  img {
    width: 16px;
    height: 16px;
    top: 13px;
    right: 15px;
    position: absolute;
    cursor: pointer;
  }

  > span {
    margin-top: -10px;
    margin-bottom: 7px;
    display: block;
    text-align: left;
    color: ${({ theme }) => theme.palette.accent.warning};
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
  }

  label {
    padding: 0 12px;
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;
    transition:
      box-shadow 0.3s,
      border 0.3s;

    :hover {
      border: 1px solid
        ${({ $error, theme }) =>
          $error ? theme.palette.accent.warning : theme.palette.theme.primary};
    }

    border: 1px solid
      ${({ $error, theme }) => ($error ? theme.palette.accent.warning : theme.palette.divider)};

    span {
      text-align: left;
      color: ${({ theme, accepted, $error }) =>
        accepted || $error ? theme.palette.type.primary : theme.palette.type.tertiary};
      font-size: 14px;
    }

    > div {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }
`

export default BaseInput
