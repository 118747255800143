import styled from 'styled-components'
import { Link } from 'react-router-dom'

export default styled(Link)`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  position: static;
  flex-wrap: inherit;
`
