import { useMemo } from 'react'
import { createPortal } from 'react-dom'

import SettingsModal from '../SettingsModal'
import WorkspaceMembersTab from './WorkspaceMembersTab'
import WorkspaceGeneralTab from './WorkspaceGeneralTab'

const TABS = {
  MEMBERS: 'members',
  GENERAL: 'general',
}

const defaultTab = TABS.GENERAL

const Components = {
  [TABS.MEMBERS]: WorkspaceMembersTab,
  [TABS.GENERAL]: WorkspaceGeneralTab,
}

const menuItems = [
  { id: TABS.GENERAL, i18n: 'General' },
  { id: TABS.MEMBERS, i18n: 'Members' },
]

const WorkspaceSettingsModal = ({ workspaceId, visible, onClose }) => {
  const params = useMemo(
    () => ({
      workspaceId,
      onCloseMainModal: (event) => {
        onClose(event)
      },
    }),
    [onClose, workspaceId],
  )

  return createPortal(
    <SettingsModal
      defaultTab={defaultTab}
      menuItems={menuItems}
      components={Components}
      visible={visible}
      onClose={onClose}
      headerI18n="sidebar:workspaceSettings"
      params={params}
    />,
    document.body,
  )
}

export default WorkspaceSettingsModal
