export const colors = {
  'brand-01': '#DCD6FF',
  'brand-02': '#7565E2',
  'brand-03': '#6658C7',
  'neutral-01': '#FFFFFF',
  'neutral-02': '#F9F9FB',
  'neutral-03': '#F4F4F6',
  'neutral-04': '#E8E8EB',
  'neutral-05': '#E0DFE2',
  'neutral-06': '#A9A7B4',
  'neutral-07': '#5E5D65',
  'neutral-08': '#1D1C21',
  'info-01': '#D7E3F5',
  'info-02': '#B2C9EB',
  'info-03': '#458BF5',
  'info-04': '#3D7DDB',
  'success-01': '#D7F5E1',
  'success-02': '#B2EBC5',
  'success-03': '#3BB864',
  'success-04': '#339E56',
  'warning-01': '#F5E9D7',
  'warning-02': '#EBD4B2',
  'warning-03': '#F5AE45',
  'warning-04': '#DB9C3E',
  'critical-01': '#F5D9D7',
  'critical-02': '#EBB6B2',
  'critical-03': '#F55045',
  'critical-04': '#DB473D',
  'additional-01': '#DF5F51',
  'additional-02': '#C74137',
  'additional-03': '#EB992F',
  'additional-04': '#E7AE2A',
  'additional-05': '#7BB82C',
  'additional-06': '#1DA364',
  'additional-07': '#27AC9A',
  'additional-08': '#217CA3',
  'additional-09': '#5181E0',
  'additional-10': '#4961AE',
  'additional-11': '#6651B8',
  'additional-12': '#79399E',
  'additional-13': '#A43B8F',
  'additional-14': '#B8425A',
  'additional-15': '#CC396A',
  'additional-16': '#626D7A',
}
