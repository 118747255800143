import { createSelector } from 'reselect'

export const selectIncluded = ({ included }) => included
export const selectTasks = ({ tasks }) => tasks
export const selectDigitalAssets = ({ digitalAssets }) => digitalAssets
export const selectAccount = ({ account }) => account
export const selectWorkspaces = ({ workspaces }) => workspaces
export const selectPartners = ({ partners }) => partners
export const selectComments = ({ comments }) => comments
export const selectTable = ({ table }) => table
export const selectFilters = ({ filters }) => filters
export const selectHints = ({ hints }) => hints
export const selectTags = ({ tags }) => tags
export const selectWorkflow = ({ workflow }) => workflow
export const selectTemplates = ({ templates }) => templates
export const selectHome = ({ home }) => home
export const selectAdmin = ({ admin }) => admin
export const selectDocuments = ({ documents }) => documents
export const selectSharepoint = ({ sharepoint }) => sharepoint
export const selectApplication = ({ application }) => application
export const selectSearch = ({ search }) => search
export const selectCompany = ({ company }) => company
export const selectNotifications = ({ notifications }) => notifications
export const selectCustomFields = ({ customFields }) => customFields
export const selectCompanyWorkspaceId = ({ workspaces }) => workspaces.companyWorkspace?.id

// Memo
export const selectCurrentUserId = createSelector([selectAccount], (account) => {
  return account?.currentUser?.id
})
export const selectCurrentUser = createSelector([selectAccount], (account) => account.currentUser)

export const getCompanyWorkspace = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.companyWorkspace,
)
export const getAllWorkspaces = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.allWorkspaces,
)
export const getCheckWorkspaceSwitcher = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.checkWorkspaceSwitcher,
)
export const getAllFolders = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.allFolders,
)
export const getFolders = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.folders || [],
)
export const getActiveFolder = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.activeFolder,
)

export const applicationRedirect = createSelector(
  [selectApplication],
  (application) => application.redirect,
)
export const featuresSelector = createSelector(
  [selectAccount],
  (account) => account.entity?.enabledFeatures || [],
)
export const getCurrentUserAccount = createSelector([selectAccount], (account) => account.entity)
export const sharepointUsersSelector = createSelector(
  [selectSharepoint],
  (sharepoint) => sharepoint.naturalPersons,
)

export const companyMembershipSelector = createSelector(
  [selectAccount],
  (account) => account.companyMembership,
)

export const betaFeaturesEnabledSelector = createSelector(
  [selectApplication],
  (application) => application.debugger,
)

export const navigationEnabledSelector = createSelector(
  [selectApplication],
  (application) => application.navigation,
)
export const mobileEnabledSelector = createSelector(
  [selectDigitalAssets],
  (digitalAssets) => digitalAssets.mobileEnabled,
)

export const desktopSidebarEnabledSelector = createSelector(
  [selectDigitalAssets],
  (digitalAssets) => digitalAssets.desktopSidebar,
)

export const mobileSidebarEnabledSelector = createSelector(
  [selectDigitalAssets],
  (digitalAssets) => digitalAssets.mobileSidebar,
)

export const identVerifiedSelector = createSelector(
  [selectAccount],
  (account) => account.entity?.identVerified,
)
export const getDevices = createSelector([selectAccount], (account) => account?.devices)
export const getCompanySettings = createSelector([selectCompany], (company) => company?.settings)

export const companySelector = createSelector([selectAccount], (account) => account.company)
export const identStateSelector = createSelector([selectAccount], (account) => account.identState)

export const selectIncludedByKey = (key) =>
  createSelector([selectIncluded], (included) => included[key])

export const selectIncludedSelector = createSelector([selectIncluded], (included) => included)

export const selectDigitalAssetDetails = createSelector(
  [selectDigitalAssets],
  (digitalAssets) => digitalAssets.details,
)

export const selectActiveFolder = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.activeFolder,
)

export const activeWorkspaceIdSelector = createSelector(
  [selectWorkspaces],
  (workspaces) => workspaces.active,
)
export const selectAccountId = createSelector([selectAccount], (account) => account?.entity?.id)
export const getCompanyAdmins = createSelector([selectCompany], (company) => company.admins)
export const selectUserType = createSelector(
  [selectAccount],
  (account) => account.currentUser?.userType,
)
export const selectAccountLang = createSelector([selectAccount], (account) => account.lang)

export const selectApplicationShowSidebar = createSelector(
  [selectApplication],
  (application) => application.showSidebar,
)
export const selectNotificationsList = createSelector(
  [selectNotifications],
  (notifications) => notifications.list,
)

export const selectCurrentTable = createSelector(
  [selectTable, (_, tableId) => tableId],
  (tables, tableId) => ({
    currentTable: tables[tableId],
  }),
)

export const selectIncludedTags = createSelector([selectIncluded], (included) => included.tags)
export const getIncludedNaturalPersons = createSelector(
  [selectIncluded],
  (included) => included.naturalpersons,
)
export const selectAssetById = createSelector(
  [selectDigitalAssetDetails, (_, id) => id],
  (details, id) => details[id] || {},
)
