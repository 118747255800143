import { useTranslation } from 'react-i18next'

const usePreformatValue = (value = '0') => {
  const {
    i18n: { language },
  } = useTranslation()

  if (!value) return null
  if (typeof value === 'string') {
    return new Intl.NumberFormat(language).format(Number.parseFloat(value.replace(',', '.'), 10))
  }
  return value
}

export default usePreformatValue

export const parseFormattedNumber = ({ formattedValue, formatInfo }) => {
  if (typeof formattedValue === 'string') {
    let numericString = formattedValue.replace(formatInfo.currencySymbol, '')
    numericString = numericString.split(formatInfo.groupSeparator).join('')
    numericString = numericString.replace(formatInfo.decimalSeparator, '.')
    return parseFloat(numericString)
  }
  return formattedValue
}

export const getNumberFormatInfo = ({ locale, currency }) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  const parts = formatter.formatToParts(12345.67)
  const info = {}

  parts.forEach((part) => {
    if (part.type === 'currency') {
      info.currencySymbol = part.value
    } else if (part.type === 'group') {
      info.groupSeparator = part.value
    } else if (part.type === 'decimal') {
      info.decimalSeparator = part.value
    }
  })

  return info
}

export const useFormatValue = ({ value, currency }) => {
  const {
    i18n: { language },
  } = useTranslation()
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}
