/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import { v4 } from 'uuid'

import { OVERLAY_HEIGHT, OVERLAY_WIDTH } from './Overlays/const'

let pageInitialState = {}
let overlays = []

export const removeOverlay = (overlay) => {
  overlays = overlays.filter((o) => o.id !== overlay.id)
  overlay.renderPage(overlay.canvas)
}

export const registerOverlay = (newOverlay, fromData) => {
  if (!fromData) {
    newOverlay.offsetY -= OVERLAY_HEIGHT * 0.5
    newOverlay.id = v4()
  }

  const { canvas, pageId, offsetX, offsetY, type, theme } = newOverlay
  let initialImageData = pageInitialState[pageId]
  const ctx = canvas.getContext('2d')
  const scale = window.devicePixelRatio
  ctx.setTransform(scale, 0, 0, scale, 0, 0)

  newOverlay.sourceWidth = canvas.width
  newOverlay.sourceHeight = canvas.height
  newOverlay.renderPage = (predefinedCanvas) => renderPageCanvas(pageId, predefinedCanvas)

  if (!initialImageData) {
    initialImageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    pageInitialState[pageId] = initialImageData
  }

  overlays.push(newOverlay)
  renderPageCanvas(pageId)
}

const getCanvasByPage = (pageId) => overlays.find((o) => o.pageId === pageId).canvas

export const renderPageCanvas = (pageId, predefinedCanvas) => {
  const canvas = predefinedCanvas || getCanvasByPage(pageId)
  const ctx = canvas.getContext('2d')
  const initialImageData = pageInitialState[pageId]
  ctx.fillStyle = 'white'
  ctx.strokeStyle = 'transparent'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.putImageData(initialImageData, 0, 0)
  const scale = window.devicePixelRatio
  ctx.font = `${14}px Inter`

  overlays
    .filter((o) => o.pageId == pageId)
    .forEach((overlay) => {
      if (typeof overlay.renderer === 'function') {
        overlay.renderer(overlay, ctx)
      } else {
        throw new Error('Every overlay should have renderer method')
      }
    })
}

export const detectCollision = ({ e, pageId, detectMovement, detectOverlays }) => {
  const {
    nativeEvent: { offsetX, offsetY },
  } = e

  const moveHandlersFound = detectMovement
    ? overlays.filter((o) => {
        return (
          o.pageId == pageId &&
          offsetX >= o.offsetX - 36 &&
          offsetX <= o.offsetX + 12 &&
          offsetY >= o.offsetY - 18 &&
          offsetY <= o.offsetY + 30
        )
      })
    : []

  const deleteHandlersFound = detectMovement
    ? overlays.filter((o) => {
        return (
          o.pageId == pageId &&
          offsetX >= o.offsetX + o.width - 12 &&
          offsetX <= o.offsetX + o.width + 36 &&
          offsetY >= o.offsetY - 18 &&
          offsetY <= o.offsetY + 30
        )
      })
    : []

  const overlaysFound = detectOverlays
    ? overlays.filter((o) => {
        return (
          o.pageId == pageId &&
          offsetX >= o.offsetX &&
          offsetX <= o.offsetX + (o.width || OVERLAY_WIDTH) &&
          offsetY >= o.offsetY &&
          offsetY <= o.offsetY + OVERLAY_HEIGHT &&
          o.render === undefined
        )
      })
    : []

  const detected = [
    ...moveHandlersFound.map((o) => ({ target: o, type: 'move' })),
    ...deleteHandlersFound.map((o) => ({ target: o, type: 'delete' })),
    ...overlaysFound.map((o) => ({ target: o, type: 'overlay' })),
  ]

  return detected
}

export const getOnMouseOverHandler = (pageId, moveTarget) => {
  if (moveTarget) {
    return (e) => {
      const { movementX, movementY } = e
      moveTarget.offsetX += movementX
      moveTarget.offsetY += movementY
      moveTarget.renderPage()
      return e
    }
  }

  return (e) => {
    const collisions = detectCollision({ e, pageId, detectMovement: true, detectOverlays: true })
    const collision = collisions[0]

    if (collision) {
      if (collision.type === 'move') {
        e.target.style.cursor = 'move'
      } else {
        e.target.style.cursor = 'pointer'
      }
    } else {
      e.target.style.cursor = ''
    }
  }
}

export const flushOverlays = () => {
  pageInitialState = {}
  overlays.forEach((overlay) => {
    overlay.canvas.ondrop = undefined
  })
  overlays = []
}

export const getAllOverlays = () => overlays
