export const OVERLAY_WIDTH = 160
export const OVERLAY_HEIGHT = 44

export const icons = {}

const registerIcon = (key, path) => {
  const img = new Image()
  img.src = path
  icons[key] = img
}

registerIcon('signature', '/img/icons/icons16px/signature.svg')
registerIcon('text', '/img/icons/icons16px/typing.svg')
registerIcon('drag', '/img/icons/icons16px/drag_point.svg')
registerIcon('delete', '/img/icons/icons16px/drag_delete.svg')
