import { createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../utils/callApi'
import { updateIncluded } from './included'

export const fetchEvents = createAsyncThunk('events/fetch', async ({ daId, callback }, api) => {
  const token = api.extra.getToken()

  const events = await callApi({
    endpoint: `events?filter[refersTo.id]=${daId}&sort=date&include=reminders&include=scopes.ownedBy`,
    accessToken: token,
    accessName: 'Bearer',
  })

  if (events.included) {
    api.dispatch(updateIncluded(events.included))
  }

  api.extra.sendCallback(callback)

  return { events, id: daId }
})

export const deleteEvent = createAsyncThunk('events/delete', async ({ daId, eventId }, api) => {
  const token = api.extra.getToken()
  await callApi({
    endpoint: `events/${eventId}`,
    method: 'DELETE',
    accessToken: token,
    accessName: 'Bearer',
  })
  api.dispatch(fetchEvents({ daId }))

  return {}
})

export const createEvent = createAsyncThunk(
  'events/create',
  async ({ daId, json, callback, notUpdateAtTheEnd }, api) => {
    const token = api.extra.getToken()

    const event = await callApi({
      endpoint: `events`,
      method: 'POST',
      accessToken: token,
      accessName: 'Bearer',
      body: json,
    })

    if (!notUpdateAtTheEnd) {
      api.dispatch(fetchEvents({ daId }))
    }

    api.extra.sendCallback(callback, event)

    return {}
  },
)

export const patchEvent = createAsyncThunk(
  'events/change',
  async ({ daId, eventId, json, callback }, api) => {
    const token = api.extra.getToken()

    const event = await callApi({
      endpoint: `events/${eventId}`,
      method: 'PATCH',
      accessToken: token,
      accessName: 'Bearer',
      body: json,
    })

    api.dispatch(fetchEvents({ daId }))

    api.extra.sendCallback(callback, event)

    return {}
  },
)

export const createReminder = createAsyncThunk(
  'events/reminders/create',
  async ({ daId, json, callback, notUpdateAtTheEnd }, api) => {
    const token = api.extra.getToken()

    await callApi({
      endpoint: `eventreminders`,
      method: 'POST',
      accessToken: token,
      accessName: 'Bearer',
      body: json,
    })

    if (!notUpdateAtTheEnd) {
      api.dispatch(fetchEvents({ daId, callback }))
    }

    api.extra.sendCallback(callback)

    return {}
  },
)

export const changeReminder = createAsyncThunk(
  'events/reminders/change',
  async ({ json, daId, callback, reminderId, notUpdateAtTheEnd }, api) => {
    const token = api.extra.getToken()

    await callApi({
      endpoint: `eventreminders/${reminderId}`,
      method: 'PATCH',
      accessToken: token,
      accessName: 'Bearer',
      body: json,
    })

    if (notUpdateAtTheEnd) {
      api.extra.sendCallback(callback)
    } else {
      api.dispatch(fetchEvents({ daId, callback }))
    }

    return {}
  },
)

export const deleteReminder = createAsyncThunk(
  'events/reminders/delete',
  async ({ reminderId, eventId, daId, callback }, api) => {
    const token = api.extra.getToken()

    await callApi({
      endpoint: `eventreminders/${reminderId}`,
      method: 'DELETE',
      accessToken: token,
      accessName: 'Bearer',
    })

    api.extra.sendCallback(callback)

    return { reminderId, eventId, daId }
  },
)
