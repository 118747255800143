import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'

import Spacing from './Spacing'
import Typography from './Typography'
import SvgMask from './SvgMask'
import Spinner from './Spinner'

const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BaseCheckbox = ({
  checked,
  disabled,
  icon,
  variant,
  i18n,
  label,
  onRemove,
  removing,
  color,
  partially,
  iconPartially,
  children,
  alternative,
  required,
  tooltip,
  ...props
}) => {
  const theme = useTheme()

  const renderToColor = disabled
    ? theme.semantic.palette.text['text-disabled']
    : color || theme.semantic.palette.text.text
  return (
    <div {...props}>
      <Box checked={checked} disabled={disabled} alternative={alternative} data-test="checkbox">
        {checked && <SvgMask icon={partially ? iconPartially : icon} size={12} />}
      </Box>
      {(label || i18n) && (
        <Grid title={tooltip ? i18n || label : ''}>
          <Typography i18n={i18n} variant={variant} color={renderToColor}>
            {label}
          </Typography>
          {required && (
            <>
              <Spacing size="4" />
              <Typography variant={variant} color={theme.semantic.palette.text['text-critical']}>
                *
              </Typography>
            </>
          )}
        </Grid>
      )}
      {children}
      {typeof onRemove === 'function' && (
        <div style={{ marginLeft: 'auto' }}>
          {!removing ? (
            <DeleteIcon
              size={16}
              color={theme.palette.type.tertiary}
              icon="trash"
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                onRemove()
              }}
            />
          ) : (
            <Spinner />
          )}
        </div>
      )}
    </div>
  )
}

BaseCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  i18n: PropTypes.string,
  label: PropTypes.any,
}

const Checkbox = styled(({ partially, variant, alternative, ...props }) => {
  let Container = StandardCheckbox
  if (alternative) {
    Container = AlternativeCheckbox
  }

  return (
    <Container
      partially={partially}
      iconPartially={!alternative ? 'minus' : 'checkmark'}
      icon="checkmark"
      variant={variant || 'body-medium-02'}
      {...props}
    />
  )
})``

const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: auto 4px 8px;
`

const DeleteIcon = styled(SvgMask)`
  margin-left: auto;
  &:hover {
    background-color: ${({ theme }) => theme.palette.type.primary};
  }
`
const StandardCheckbox = styled(BaseCheckbox)`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  justify-content: flex-start;
  cursor: pointer;
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};

  ${Grid} ${Typography} {
    white-space: ${({ whitespace }) => whitespace || 'nowrap'};
    text-overflow: ${({ textoverflow }) => textoverflow || 'ellipsis'};
    overflow: ${({ overflow }) => overflow || 'hidden'};
  }

  &:hover {
    ${Box} {
      border: ${({ disabled, checked, theme }) =>
        !disabled &&
        !checked &&
        `1px solid ${theme.component.checkbox.border['checkbox-color-border-uncheked-hover']}`};
      background-color: ${({ checked, disabled, theme }) =>
        checked &&
        !disabled &&
        theme.component.checkbox.surface['checkbox-color-surface-checked-hover']};
    }
  }
  ${Box} {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border-radius: ${({ theme }) => theme.radius['checkbox-standard-border-radius']};
    background-color: ${({ checked, disabled, theme }) => {
      if (checked) {
        if (disabled) {
          return theme.component.checkbox.surface['checkbox-color-surface-checked-disabled']
        }
        return theme.component.checkbox.surface['checkbox-color-surface-checked']
      }
      if (disabled)
        return theme.component.checkbox.surface['checkbox-color-surface-unchecked-disabled']
      return theme.component.checkbox.surface['checkbox-color-surface-unchecked']
    }};
    border: ${({ theme, checked, disabled }) => {
      if (checked) return undefined
      if (disabled)
        return `1px solid ${theme.component.checkbox.border['checkbox-color-border-uncheked-disabled']}`
      return `1px solid ${theme.component.checkbox.border['checkbox-color-border-uncheked']}`
    }};
    ${SvgMask} {
      background-color: ${({ disabled, theme }) =>
        disabled
          ? theme.component.checkbox.icon['checkbox-color-icon-checked-disabled']
          : theme.component.checkbox.icon['checkbox-color-icon-checked']};
    }
  }
`

const AlternativeCheckbox = styled(Checkbox)`
  ${Box} {
    border-radius: ${({ theme }) => theme.radius['checkbox-alternative-border-radius']};
  }
`
export default Checkbox
