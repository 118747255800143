export const FOLDER_ACCESS_PROFILES = {
  VIEWER: 'viewer',
  PARENT_OWNER: 'parent_owner',
  MAINTAINER: 'maintainer',
  OWNER: 'owner',
  EDITOR: 'editor',
}

export const INHERITANCE_MODE_PERMISSIONS = {
  NONE: 'none',
  ACCESSIBLE: 'accessible',
  ALL: 'all',
}
