import styled from 'styled-components'

const Spacing = ({ size, inline, height, width, visible = true, ...props }) => {
  if (!visible) {
    return null
  }

  return <div {...props} data-size={size} />
}

export default styled(Spacing)`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  width: ${({ size, width, space }) => space || `${width || size}px`};
  min-width: ${({ size, width, space }) => space || `${width || size}px`};
  height: ${({ size, height, space }) => space || `${height || size}px`};
  min-height: ${({ size, height, space }) => space || `${height || size}px`};
`
