import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Modal, RadioButton, Spacing, Spinner, Typography } from '../../atoms'
import { callApi } from '../../../utils/callApi'
import {
  FOLDER_ACCESS_PROFILES,
  INHERITANCE_MODE_PERMISSIONS,
} from '../../../enums/folderAccessProfiles'

const ChangeUserFolderPermissionsModal = ({
  visible,
  onClose,
  usersNP,
  accessProfile,
  folderId,
  accountId,
  userFolderPermissionId,
  confirmChanging,
}) => {
  const { t } = useTranslation()

  const [selectedRadioOption, setSelectedRadioOption] = useState(INHERITANCE_MODE_PERMISSIONS.NONE)
  const [animation, setAnimation] = useState(false)

  const userName = `${usersNP.attributes.firstName} ${usersNP.attributes.lastName}`

  const createUserFolderPermissions = useCallback(() => {
    const asyncCreateUserFolderPermissions = async () => {
      setAnimation(true)
      try {
        const body = {
          data: {
            type: 'userfolderpermissions',
            attributes: {
              inheritanceMode: selectedRadioOption,
            },
            relationships: {
              folder: {
                data: { type: 'folders', id: folderId },
              },
              grantedToAccount: {
                data: { type: 'accounts', id: accountId },
              },
              accessProfile: {
                data: { type: 'folderaccessprofiles', id: accessProfile.id },
              },
            },
          },
        }
        await callApi({
          endpoint: 'userfolderpermissions',
          method: 'POST',
          body,
        })
        setAnimation(false)
        confirmChanging()
        onClose()
      } catch (e) {
        setAnimation(false)
        confirmChanging()
        onClose()
      }
    }

    asyncCreateUserFolderPermissions()
  }, [accessProfile.id, accountId, confirmChanging, folderId, onClose, selectedRadioOption])

  const changeUserFolderPermissions = useCallback(() => {
    const asyncChangeUserFolderPermissions = async () => {
      setAnimation(true)
      try {
        const body = {
          data: {
            type: 'userfolderpermissions',
            attributes: {
              inheritanceMode: selectedRadioOption,
            },
            relationships: {
              accessProfile: {
                data: { type: 'folderaccessprofiles', id: accessProfile.id },
              },
            },
          },
        }
        await callApi({
          endpoint: `userfolderpermissions/${userFolderPermissionId}`,
          method: 'PATCH',
          body,
        })
        setAnimation(false)
        confirmChanging()
        onClose()
      } catch (e) {
        setAnimation(false)
        confirmChanging()
        onClose()
      }
    }

    asyncChangeUserFolderPermissions()
  }, [accessProfile.id, confirmChanging, onClose, selectedRadioOption, userFolderPermissionId])

  useEffect(() => {
    if (!visible) {
      setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.NONE)
    }
  }, [visible])

  const onClick = () => {
    if (userFolderPermissionId) changeUserFolderPermissions()
    else createUserFolderPermissions()
  }
  return (
    <Modal
      visible={visible}
      headerI18n={
        accessProfile.attributes.type === FOLDER_ACCESS_PROFILES.EDITOR
          ? 'workspace:ChangeToEditor'
          : 'workspace:ChangeToViewer'
      }
      subHeader={t('workspace:YouCanChangePermissions', { userName })}
      onClose={onClose}
      type="oldModal"
      buttons={
        <>
          <Button
            i18n="cancel"
            width="fit-content"
            secondary
            onClick={onClose}
            data-test="button_changeAccessModal_cancel"
          >
            Cancel
          </Button>
          <Button
            width="fit-content"
            onClick={animation ? undefined : onClick}
            i18n={animation ? undefined : 'Change'}
            data-test="button_changeAccessModal_confirm_change"
            process={animation}
          >
            Change
          </Button>
        </>
      }
    >
      <Spacing size="24" />
      <Typography variant="body 14 medium" i18n="workspace:ChangePermissionsFor">
        Change permissions for
      </Typography>
      <Spacing size="16" />
      <RadioButton
        labelI18n="workspace:OnlyThisFolder"
        value={INHERITANCE_MODE_PERMISSIONS.NONE}
        onChange={() => setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.NONE)}
        checked={selectedRadioOption === INHERITANCE_MODE_PERMISSIONS.NONE}
        name="changeAccessFolder"
      />
      <Spacing size="8" />
      <RadioButton
        label={t('workspace:ThisFolderAndSubfoldersAccessible', {
          firstName: usersNP.attributes.firstName,
        })}
        value={INHERITANCE_MODE_PERMISSIONS.ACCESSIBLE}
        onChange={() => setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.ACCESSIBLE)}
        checked={selectedRadioOption === INHERITANCE_MODE_PERMISSIONS.ACCESSIBLE}
        name="changeAccessFolder"
      />
      <Spacing size="8" />
      <RadioButton
        label={t('workspace:ThisFolderAndAllSubfolders', {
          firstName: usersNP.attributes.firstName,
        })}
        value={INHERITANCE_MODE_PERMISSIONS.ALL}
        onChange={() => setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.ALL)}
        checked={selectedRadioOption === INHERITANCE_MODE_PERMISSIONS.ALL}
        name="changeAccessFolder"
      />
    </Modal>
  )
}

export default ChangeUserFolderPermissionsModal
