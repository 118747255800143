import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'

export default class AesServices {
  constructor(props = {}) {
    this.key = props.key || null
    this.file = props.file || null
    this.object = props.object || null
    this.fileAES = props.fileAES || null
    this.encryptFile = this.encryptFile.bind(this)
    this.decryptFile = this.decryptFile.bind(this)
  }

  encryptFile() {
    const aesCrypt = AES.encrypt(JSON.stringify({ cryptoEmail: this.file }), this.key.toString())
    return aesCrypt.toString()
  }

  encryptObject() {
    const ciphertext = AES.encrypt(JSON.stringify(this.object), this.key.toString())
    return ciphertext.toString()
  }

  decryptFile() {
    let plaintext
    const type = typeof this.fileAES
    const bytes = AES.decrypt(this.fileAES.toString(), this.key.toString())
    const msgNotDecryptable = 'Could not decrypt the file'
    try {
      plaintext = bytes.toString(Utf8)
    } catch (e) {
      throw Error(msgNotDecryptable)
    }

    try {
      plaintext = Object.values(JSON.parse(plaintext)).toString()
    } catch (e) {
      if (type === 'object') {
        plaintext = JSON.parse(plaintext)
      }
    }

    if (plaintext === '') {
      return msgNotDecryptable
    }
    return plaintext
  }
}
