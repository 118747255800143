import styled, { useTheme } from 'styled-components'
import { Flex, Typography } from '../atoms'

const Intro = ({ image, headerI18n, subHeader, subHeaderI18n, className, children }) => {
  const theme = useTheme()

  return (
    <div className={className}>
      <div>
        <KeyVisual>{image}</KeyVisual>
        <Flex direction="column" gap={theme.semantic.space['space-2x-small']}>
          <Typography variant="header-x-large" align="center" i18n={headerI18n} />
          {subHeaderI18n && (
            <Typography
              variant="body-large-02"
              color="secondary"
              align="center"
              i18n={subHeaderI18n}
            />
          )}
          {subHeader}
        </Flex>
        {children}
      </div>
    </div>
  )
}

const KeyVisual = styled.div`
  display: flex;
  width: 288px;
  height: 288px;
  padding: 36px 24px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`

export default styled(Intro)`
  max-width: 848px;
  > div {
    display: flex;
    gap: ${({ theme }) => theme.space['space-500']};
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
`
