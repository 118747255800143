import styled, { useTheme } from 'styled-components'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { SvgMask, Typography } from '../../atoms'

const Segment = ({ value, icon, selected, onMount, id, disabled, ...props }) => {
  const ref = useRef()
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    if (typeof onMount === 'function') {
      onMount(id, ref)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-test={`tab-${id}`} title={t(value)} {...props} ref={ref} {...props}>
      {value && (
        <Grid>
          <Typography nowrap i18n={value} variant="body-medium-01">
            {value}
          </Typography>
        </Grid>
      )}
      {icon && <SvgMask icon={icon} />}
    </div>
  )
}

const Grid = styled.div`
  display: grid;
`

export default styled(Segment)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-x-small']};
  padding: ${({ theme }) =>
    `${theme.semantic.space['space-5x-small']} ${theme.semantic.space['space-4x-small']}`};
  box-sizing: border-box;
  background-color: ${({ theme, selected }) => selected && theme.semantic.palette.surface.surface};
  flex: auto;
  pointer-events: ${({ disabled }) => disabled && 'none'};

  ${Typography} {
    color: ${({ selected, disabled, theme }) => {
      if (selected) {
        return theme.semantic.palette.text.text
      }
      if (disabled) {
        return theme.semantic.palette.text['text-disabled']
      }
      return theme.semantic.palette.text['text-secondary']
    }};
  }

  ${SvgMask} {
    background-color: ${({ selected, $disabled, theme }) => {
      if (selected) {
        return theme.semantic.palette.icon.icon
      }
      if ($disabled) {
        return theme.semantic.palette.icon['icon-disabled']
      }
      return theme.semantic.palette.icon['icon-secondary']
    }};
  }

  &:hover {
    ${Typography} {
      color: ${({ disabled, theme }) => !disabled && theme.semantic.palette.text.text};
    }
    ${SvgMask} {
      background-color: ${({ disabled, theme }) => !disabled && theme.semantic.palette.icon.icon};
    }
  }
`
