import { createReducer } from '@reduxjs/toolkit'

import { updateBoundingTarget } from '../actions/boundingTargets'

const initialState = { data: {} }

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(updateBoundingTarget, (state, action) => {
    const { id, data } = action.payload
    const newData = { ...state.data, [id]: data }
    return {
      ...state,
      data: newData,
    }
  })
})

export default reducer
