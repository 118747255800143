import { SingletonHooksContainer } from 'react-singleton-hook'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import Router from './router'
import store from './store'
import theme from './theme'

import NotificationsContainer from './components/organisms/Notifications'
import { CalendarStyling } from './components/organisms/Calendar/Styles'
import UnloadLock from './components/core/UnloadLock'
import JWTRuntime from './components/core/JWTRuntime'
import Startup from './components/core/Startup'
import I18nController from './components/core/I18nController'
import Versions from './components/organisms/Versions'
import { BrowserNotSupport } from './components/organisms/BrowserNotSupport'
import GlobalPopups from './components/popups/GlobalPopups'

import './i18n'
import { TooltipStyling } from './components/atoms/Modal/Tooltip'

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: Inter, Arial, Helvetica Neue, Helvetica, sans-serif;
    overflow-x: hidden;
  }

  * {
    scrollbar-color: ${({ theme }) => theme.palette.background.scroll} transparent;

    &::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.background.scroll};
      border-radius: 9px;
      border-style: solid;
      border-color: transparent;
      background-clip: content-box;
      border-top-width: 4px;
      border-right-width: 3px;
      border-bottom-width: 3px;
      border-left-width: 4px;

      &:hover {
        background-color: ${({ theme }) => theme.palette.background.hovered};
      }
    }
  }
  
  .scrollStyle::-webkit-scrollbar-thumb {
      box-shadow: inset 4px 0 0 0 #f1f1f1;
  }

  .scrollType1 {
    padding: 0 10px 0 0;
  }

  .scrollType2 {
    padding: 0 4px 0 0;
    margin: 0 6px 0 0;
  }

  a {
    text-decoration: none;
  }

  input, textarea, select {
    touch-action: manipulation; 
  }
`

const App = () => {
  const isSafariMobile =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  const viewport = isSafariMobile
    ? 'width=device-width,initial-scale=1,shrink-to-fit=no,maximum-scale=1'
    : 'width=device-width,initial-scale=1,shrink-to-fit=no'

  return (
    <Provider store={store}>
      <SingletonHooksContainer />
      <Startup />
      <I18nController />
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <NotificationsContainer />
          <BrowserRouter>
            <Helmet>
              <meta charset="utf-8" />
              <meta name="viewport" content={viewport} />
              <title>INHUBBER</title>
            </Helmet>
            <div className="App">
              <UnloadLock />
              <GlobalStyles />
              <GlobalPopups />
              <CalendarStyling />
              <TooltipStyling />
              <Router />
              <BrowserNotSupport />
              <Versions />

              <JWTRuntime />
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  )
}

export default App
