import styled, { useTheme } from 'styled-components'
import { useMeasure } from 'react-use'
import { useMemo } from 'react'
import { useResponsive } from '../../hooks'

const Grid = ({ background, children, className }) => {
  const theme = useTheme()

  const [ref, { width }] = useMeasure()

  const style = useMemo(() => {
    if (width < 416 && width > 321 && width !== 0) return theme.grid.xsmall
    if (width < 560 && width > 415) return theme.grid.small
    if (width < 848 && width > 559) return theme.grid.medium
    if (width > 848) return theme.grid.large
    return {
      columnGap: '16px',
      gridTemplateColumns: 'repeat(2, 1fr)',
    }
  }, [theme.grid.large, theme.grid.medium, theme.grid.small, theme.grid.xsmall, width])

  return (
    <div className={className}>
      <div>
        <ContainerGrid background={background} ref={ref}>
          <Content style={{ ...style }}>{children}</Content>
        </ContainerGrid>
      </div>
    </div>
  )
}

const Content = styled.div`
  display: inline-grid;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
`

export const ContainerGrid = styled.div`
  padding: 0 ${({ theme }) => theme.semantic.space['space-medium']};
  display: flex;
  justify-content: center;
  background: ${({ background }) => background || undefined};
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
  width: calc(100% - 1px);
  border-right: ${({ $fullContent, theme }) =>
    !$fullContent ? `1px solid ${theme.palette.divider}` : ''};
  > div {
    flex: auto;
    overflow-x: hidden;
    overflow-y: visible;
    display: grid;
    max-width: 100%;
  }
`

export default styled(Grid).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  overflow-y: auto;
  overflow-x: hidden;
  align-items: stretch;
  flex-direction: column;
  flex: 100%;
  > div {
    display: flex;
    min-height: 100%;
    align-items: stretch;
  }
`
