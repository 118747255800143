/* eslint-disable no-shadow */
import React, { useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from '@react-spring/web'

import { Elevation, Flex, Spacing, SvgMask, Typography } from '../../atoms'
import { removeNotification } from '../../../reducers/notifications'
import { selectNotificationsList } from '../../../store/selectors'

const NotificationsContainer = ({ ...rest }) => {
  const notifications = useSelector(selectNotificationsList)

  if (notifications.length === 0) {
    return null
  }

  return (
    <div {...rest}>
      {notifications.map((n) => (
        <Notification {...n} key={n.id} />
      ))}
    </div>
  )
}

const iconsByState = {
  success: 'checkmark-circle',
  attention: 'info-circle',
  warning: 'exclamationmark-circle',
}

const BaseNotification = ({ id, state, value, className, lifeLength, description }) => {
  const iconPath = iconsByState[state]
  const { palette } = useTheme()
  const dispatch = useDispatch()

  const springProps = useSpring({
    from: { transform: 'translate3d(0,-40px,0)' },
    to: { transform: 'translate3d(0,0px,0)' },
  })

  useEffect(() => {
    // remove notification after predefined time
    setTimeout(() => dispatch(removeNotification({ id })), lifeLength)
  }, [dispatch, id, lifeLength])

  if (!iconPath) {
    throw new Error(`Invalid Notification state=${state}`)
  }

  return (
    <animated.div style={springProps}>
      <Elevation variant="small" className={className} data-test={`notification-${id}`}>
        <Container align="flex-start">
          <SvgMask
            color={palette.accent[state]}
            icon={iconPath}
            alt={`notification icon ${state}`}
          />
          <Flex direction="column" align="flex-start">
            <Typography variant="body-large-01">{value}</Typography>
            <Typography variant="body-medium-02" color="secondary">
              {description}
            </Typography>
          </Flex>
        </Container>
      </Elevation>
    </animated.div>
  )
}

const Container = styled(Flex)`
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
`

export const Notification = styled(BaseNotification)`
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
  pointer-events: all;
  width: 344px;

  img {
    margin-top: 2px;
  }
`

export default styled(NotificationsContainer)`
  width: 100vw;
  height: fit-content;
  position: fixed;
  z-index: 7000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  padding-top: 40px;
  pointer-events: none;
`
