import slugify from 'slugify'
import filter from 'lodash/filter'

export const generateSlug = (entity = {}) => {
  const title = entity?.attributes?.title || ''
  const id = entity?.id || ''

  return `${slugify(title)}-${id.split('-')[0]}`.toLowerCase()
}

export const generateSlugsFromWorkspaces = (workspaces) =>
  filter(workspaces, (item) => item !== null).map(generateSlug)

// eslint-disable-next-line default-param-last
export const getWorkspaceBySlug = (workspaces = {}, slug) => {
  return filter(workspaces, (ws) => generateSlug(ws) === slug)[0]
}

// eslint-disable-next-line default-param-last
export const getFolderBySlug = (folders = {}, slug) => {
  return filter(folders, (f) => f.attributes.slug === slug)[0]
}
