const ActivityTypes = {
  CONTRACT_CREATED: 'contract/created',
  INVITED_VIEW: 'invite/view',
  INVITED_SIGN: 'invite/sign',
  CONTRACT_SIGNED: 'contract/signed',
  COMMENT: 'comment',
  SHOW_MORE: 'show-more',
}

export default ActivityTypes
