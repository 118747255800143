import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'

import {
  BaseInput,
  Button,
  Checkbox,
  Flex,
  Modal,
  Spacing,
  Spinner,
  ValidationError,
} from '../../../atoms'

import { useDispatchAsync } from '../../../../hooks'
import { createFolder, updateFolders } from '../../../../actions/workspaces'
import { createNotification } from '../../../../actions/notifications'

const FolderCreateModal = ({
  visible,
  onClose,
  workspaceId,
  parentFolderId,
  className,
  mainFolder,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatchAsync = useDispatchAsync()
  const dispatch = useDispatch()
  const [isCreating, setIsCreating] = useState(false)
  const [newFolderTitle, setNewFolderTitle] = useState('')
  const [error, setError] = useState(false)
  const [check, setCheck] = useState(false)

  useEffect(() => {
    setCheck(false)
    setError(false)
    setNewFolderTitle('')
  }, [visible])

  const onCreateFolder = async (e) => {
    setError(false)
    if (newFolderTitle.length < 1) {
      setError(true)
      return
    }
    setIsCreating(true)
    await dispatchAsync(createFolder, {
      workspaceId,
      folderTitle: newFolderTitle,
      parentFolderId,
      inheritPermissions: check,
    })

    await dispatchAsync(updateFolders, { workspaceId })

    dispatch(
      createNotification({
        state: 'success',
        value: t('workspace:FolderCreated'),
        description: newFolderTitle,
      }),
    )
    setIsCreating(false)

    if (typeof onClose === 'function') {
      onClose(e)
    }
  }

  return createPortal(
    <Modal
      className={className}
      headerI18n="workspace:CreateNewFolder"
      width="360"
      visible={visible}
      onClose={onClose}
      buttons={
        <Button
          i18n={isCreating ? '' : 'Create'}
          onClick={onCreateFolder}
          data-test="button_folderCreateModal_createFolder"
          process={isCreating}
        >
          Create
        </Button>
      }
      type="oldModal"
      {...props}
    >
      <Spacing size="24" />
      <Flex direction="column" align="flex-start">
        <BaseInput
          autoFocus
          value={newFolderTitle}
          onChange={(e) => {
            setError(false)
            setNewFolderTitle(e.target.value)
          }}
          placeholder={t('workspace:FolderName')}
          $error={!!error}
          data-test="input_folderCreateModal_folderName"
        />
        {error && (
          <>
            <Spacing size="4" />
            <ValidationError offset="none" i18n="Required" />
          </>
        )}
        <>
          <Spacing size="24" />
          <Checkbox
            color="primary"
            variant="14"
            align="flex-start"
            whitespace="normal"
            onClick={() => {
              setCheck(!check)
            }}
            checked={check}
            i18n={
              mainFolder
                ? 'workspace:createMainFolderCheckbox'
                : 'workspace:createSubFolderCheckbox'
            }
            data-test="checkbox_folderCreateModal_addAllUsers"
          />
        </>
      </Flex>
    </Modal>,
    document.body,
  )
}

export default styled(FolderCreateModal)`
  ${BaseInput} {
    margin: 0;
  }
`
