import keythereum from 'keythereum'
import Wallet from 'ethereumjs-wallet'

const keyGenDictionary =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_,.;:#*?=)(/&%$§"!¡“¢[]|{}≠@'

export const keythereumParams = { keyBytes: 32, ivBytes: 16 }

export const randomKey = (length = 64) => {
  const randomValues = window.crypto.getRandomValues(new Uint8Array(length))
  const key = Array.from(randomValues)
    .map((a) => Math.round((a / 255) * keyGenDictionary.length))
    .map((a) => keyGenDictionary[a])
    .join('')

  return key
}

export const exportFromFile = ({ password, file }) => {
  const privateKeyBuffer = keythereum.recover(password, file)

  if (privateKeyBuffer !== undefined) {
    const wallet = Wallet.fromPrivateKey(privateKeyBuffer)

    // Get a public key
    const publicKeyString = wallet.getPublicKeyString()
    const publicKey = wallet.getPublicKey()
    const privateKey = wallet.getPrivateKey()
    return { publicKeyString, publicKey, privateKey }
  }

  throw new Error(
    'privateKeyBuffer should be defined. Invalid arguments was provided to exportFromFile',
  )
}
