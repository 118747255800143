import { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { ContentGrid, Spacing, Divider, Typography, Button, Checkbox, Flex } from '../../atoms'
import { ContentHeader, PersonaDetails } from '../../molecules'
import TermsConditions from '../../popups/TermsConditions'
import PrivacyPolicy from '../../popups/PrivacyPolicy'
import CookiePolicy from '../../popups/CookiePolicy'
import LanguageSwitcher from '../LanguageSwitcher'
import ProfileInput from '../Sidebar/ProfileInput'

import { FEATURES } from '../../../enums'
import { enableDebugger } from '../../../actions/application'
import {
  betaFeaturesEnabledSelector,
  identVerifiedSelector,
  selectCurrentUser,
} from '../../../store/selectors'

const ContentBody = styled.div`
  grid-column: 1 / -1;
  grid-row: 3;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const ContentFooter = styled.div`
  grid-column: 1 / -1;
  grid-row: 4;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const Title = styled(Typography).attrs(() => ({ variant: '12 medium' }))`
  color: ${({ theme }) => theme.palette.type.secondary};
`

const ValueTypography = styled(Typography).attrs(() => ({ variant: '13 medium' }))`
  text-transform: capitalize;
`

const Account = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [termsConditionsModal, setTermsConditionsModal] = useState(false)
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false)
  const [cookiePolicyModal, setCookiePolicyModal] = useState(false)

  const currentUser = useSelector(selectCurrentUser)
  const isBetaFeaturesEnabled = useSelector(betaFeaturesEnabledSelector)
  const identVerified = useSelector(identVerifiedSelector)

  const { emailAddress, firstName, lastName, address, publicKey, userType, features } = currentUser

  const isBetaFeaturesAllowed = (features || []).indexOf(FEATURES.BetaFeatures) !== -1

  return (
    <ContentGrid {...props} rows="24px 32px auto auto" height="auto" width="inherit">
      <Helmet>
        <title>{t('MyProfile')} - INHUBBER</title>
      </Helmet>
      <ContentHeader title="Profile" variant="header 16px medium" />
      <ContentBody>
        <PersonaDetails
          firstName={firstName}
          lastName={lastName}
          labels={[emailAddress]}
          size="big"
          seed={emailAddress}
          verified={identVerified}
        />
        <Spacing size="32" />
        <Divider />
        <Spacing size="32" />
        <Title i18n="BlockchainAddress" />
        <Spacing size="4" />
        <ProfileInput value={address} readOnly />
        <Spacing size="24" />
        <Title i18n="PublicKey" />
        <Spacing size="4" />
        <ProfileInput value={Buffer.from(JSON.parse(publicKey).data).toString('hex')} readOnly />
        <Spacing size="24" />
        <Title i18n="AccountType" />
        <Spacing size="4" />
        <ValueTypography i18n={userType} />
        <Spacing size="24" />
        <Title i18n="Language" />
        <Spacing size="4" />
        <LanguageSwitcher placement="bottom-start" />
        {isBetaFeaturesAllowed && (
          <>
            <Spacing size="32" />
            <Divider />
            <Spacing size="32" />
            <Title i18n="Beta Features" />
            <Spacing size="4" />
            <Checkbox
              i18n="Enabled"
              checked={isBetaFeaturesEnabled}
              onClick={() => dispatch(enableDebugger(!isBetaFeaturesEnabled))}
              data-test="checkbox_settingsModal_profile_betaEnabled"
            />
          </>
        )}
        <Spacing size="32" />
      </ContentBody>
      <ContentFooter>
        <Divider />
        <Spacing size="32" />
        <Typography i18n="testingTermsPolicies" variant="16 medium" />
        <Spacing size="24" />
        <Flex>
          <Button
            width="fit-content"
            i18n="TandC"
            tertiary
            onClick={() => setTermsConditionsModal(true)}
            data-test="button_termsPolicies_accountPage"
          >
            TermsConditions
          </Button>

          <Spacing size={16} />
          <Button
            i18n="PrivacyPolicy"
            width="fit-content"
            tertiary
            onClick={() => setPrivacyPolicyModal(true)}
          >
            Privacy policy
          </Button>
          <Spacing size={16} />

          <Button
            i18n="CookiePolicy"
            width="fit-content"
            tertiary
            onClick={() => setCookiePolicyModal(true)}
          >
            Cookie policy
          </Button>
        </Flex>
      </ContentFooter>

      <TermsConditions
        visible={termsConditionsModal}
        onClose={() => setTermsConditionsModal(false)}
      />
      <PrivacyPolicy visible={privacyPolicyModal} onClose={() => setPrivacyPolicyModal(false)} />
      <CookiePolicy visible={cookiePolicyModal} onClose={() => setCookiePolicyModal(false)} />
    </ContentGrid>
  )
}

export default styled(Account)`
  padding: 0;
  overflow-x: hidden;
`
