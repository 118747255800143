import styled, { useTheme } from 'styled-components'

import Flex from './Flex'
import SvgMask from './SvgMask'
import Typography from './Typography'

const WorkspaceIcon = ({ icon, name, small, className }) => {
  const theme = useTheme()

  if (name) {
    return (
      <WorkspaceName $small={small}>
        <Typography
          variant={small ? 'body-x-small-01' : 'body-large-01'}
          color={theme.semantic.palette.text['text-inverse']}
        >
          {name.substring(0, 2)}
        </Typography>
      </WorkspaceName>
    )
  }
  return (
    <Flex className={className}>
      <SvgMask icon={icon || 'folder'} color="white" />
    </Flex>
  )
}

const WorkspaceName = styled(Flex)`
  box-sizing: border-box;
  width: ${({ $small }) => ($small ? '16px' : '32px')};
  min-width: ${({ $small }) => ($small ? '16px' : '32px')};
  height: ${({ $small }) => ($small ? '16px' : '32px')};
  min-height: ${({ $small }) => ($small ? '16px' : '32px')};
  border-radius: ${({ theme, $small }) =>
    $small ? theme.semantic.radius['border-radius-x-small'] : '4px'};
  align-items: center;
  justify-content: center;
  padding: ${({ $small }) => ($small ? '2px 0' : '6px 0')};
  background-color: ${({ color, theme }) => color || theme.palette.avatar[9]};
`

export default styled(WorkspaceIcon)`
  box-sizing: border-box;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  background-color: ${({ color, theme }) => color || theme.palette.avatar[9]};
`
