import Api from '@parity/api'

export const hostprovider =
  localStorage.getItem('localhost_mode') !== 'true'
    ? process.env.REACT_APP_API_WS
    : process.env.REACT_APP_API_LOCALWS

export const createParityConnection = () => {
  const provider = new Api.Provider.Http(hostprovider, -1)
  const api = new Api(provider)
  return { provider, api }
}
