import styled, { useTheme } from 'styled-components'
import { Divider, Flex, Persona, SvgMask, Tooltip, Typography } from '../atoms'
import PersonaDetails from './PersonaDetails'

const PersonaTooltip = ({
  firstName,
  lastName,
  emailAddress,
  placement,
  className,
  verified,
  size,
  details,
  initials,
  strategy,
  portal,
}) => {
  const theme = useTheme()
  return (
    <Tooltip
      portal={portal}
      className={className}
      enabled
      options={{
        ...(placement ? { placement } : undefined),
        ...(strategy ? { strategy } : undefined),
      }}
      type="personaInfo"
      tooltip={
        <Flex
          direction="column"
          align="flex-start"
          gap={theme.semantic.space['space-small']}
          width="100%"
        >
          <PersonaDetails
            size="big"
            firstName={firstName}
            lastName={lastName}
            seed={firstName && emailAddress}
            verified={verified}
            initials={initials}
            color={initials && 16}
          />
          <Divider />
          <Flex gap="8px">
            <SvgMask icon="envelope" color={theme.palette.type.secondary} />
            <Typography variant="body-medium-02" color="secondary">
              {emailAddress}
            </Typography>
          </Flex>
        </Flex>
      }
    >
      <Flex cursor="pointer" gap={theme.semantic.space['space-2x-small']}>
        <Persona
          size={size || 'medium'}
          firstName={firstName}
          lastName={lastName}
          seed={firstName && emailAddress}
          verified={verified}
          initials={initials}
        />
        {details && (
          <Grid>
            <Typography variant="body-large-01" color={theme.semantic.palette.text.text}>
              {firstName ? `${firstName} ${lastName}` : emailAddress}
            </Typography>
          </Grid>
        )}
      </Flex>
    </Tooltip>
  )
}

const Grid = styled.div`
  display: grid;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export default styled(PersonaTooltip)``
