import { createReducer } from '@reduxjs/toolkit'

import {
  eraseSearchResults,
  searchDigitalAssets,
  searchDigitalAssetsNextPage,
} from '../actions/digitalAssets'

const initialState = {
  data: [],
  links: {},
  meta: {},
  searching: false,
  nothingFound: false,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchDigitalAssets.pending, (state) => {
      return {
        ...state,
        searching: true,
        data: [],
        links: {},
        meta: {},
        nothingFound: false,
      }
    })
    .addCase(searchDigitalAssets.fulfilled, (state, action) => {
      const nothingFound = action.payload.data.length === 0
      return {
        ...state,
        ...action.payload,
        searching: false,
        nothingFound,
      }
    })
    .addCase(searchDigitalAssetsNextPage.pending, (state) => {
      return {
        ...state,
        searching: true,
      }
    })
    .addCase(searchDigitalAssetsNextPage.fulfilled, (state, action) => {
      const newData = [...state.data, ...action.payload.data]
      return {
        ...state,
        ...action.payload,
        data: newData,
        searching: false,
      }
    })
    .addCase(eraseSearchResults.fulfilled, () => {
      return {
        ...initialState,
        searching: false,
      }
    })
})
export default reducer
