import { createReducer } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'

import { loadInitialReducerState } from '../store/persist'
import {
  createFolder,
  deleteFolder,
  disablePermissionModal,
  fetchCompanyMembers,
  getCompanyWorkspaceFullfiled,
  getFolders,
  getWorkspaces,
  renameFolder,
  setActiveWorkspace,
  setActiveWorkspaceFailure,
  setCheckWorkspaceSwitcher,
  setCompanyMembers,
  setUpdateWorkspaceMembers,
  updateFolders,
} from '../actions/workspaces'
import { logout } from '../actions/logout'

const initialState = {
  companyWorkspace: null,
  companyWorkspaceEnabled: false,
  active: null,
  activeFolder: null,
  allWorkspaces: {},
  permissionModalVisible: false,
  checkWorkspaceSwitcher: true,
  workspaceOrder: [],
  foldersOrder: [],
  membership: {},
  folders: {},
  allFolders: {},
  members: [],
  canCreateNewWorkspaces: false,
  updateWorkspaceMembers: false,
  companyMembersLoaded: false,
  companyMembersLoading: false,
}
const reducer = createReducer(
  { ...initialState, ...loadInitialReducerState('workspaces', 'inhubber-redux') },
  (builder) => {
    builder
      .addCase(logout, (state) => {
        return { ...state, ...initialState }
      })
      .addCase(getCompanyWorkspaceFullfiled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          companyWorkspace: payload.companyWorkspace,
          companyWorkspaceEnabled: payload.companyWorkspaceEnabled,
          allWorkspaces: {
            ...state.allWorkspaces,
            [payload.companyWorkspace?.id]: payload.companyWorkspace,
          },
        }
      })
      .addCase(setActiveWorkspace, (state, action) => {
        const { payload } = action
        return {
          ...state,
          active: payload.id,
          activeFolder: payload.folderId || null,
        }
      })
      .addCase(setActiveWorkspaceFailure, (state) => {
        return {
          ...state,
          active: null,
          activeFolder: null,
          permissionModalVisible: true,
        }
      })
      .addCase(disablePermissionModal, (state) => {
        return { ...state, permissionModalVisible: false }
      })
      .addCase(setUpdateWorkspaceMembers, (state, action) => {
        const { payload } = action
        return {
          ...state,
          updateWorkspaceMembers: payload.updateWorkspaceMembers,
        }
      })
      .addCase(setCheckWorkspaceSwitcher, (state, action) => {
        const { payload } = action
        return {
          ...state,
          checkWorkspaceSwitcher: payload.checkWorkspaceSwitcher,
        }
      })
      .addCase(getWorkspaces.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          allWorkspaces: { ...state.allWorkspaces, ...keyBy(payload.workspaces, 'id') },
          membership: payload.membership,
          workspaceOrder: payload.order,
          foldersOrder: payload.foldersOrder,
          allFolders: { ...state.allFolders, ...keyBy(payload.folders, 'id') },
          canCreateNewWorkspaces: !!payload.canCreateNewWorkspaces,
        }
      })
      .addCase(fetchCompanyMembers.pending, (state) => {
        return {
          ...state,
          companyMembersLoading: true,
        }
      })
      .addCase(fetchCompanyMembers.fulfilled, (state) => {
        return {
          ...state,
          companyMembersLoaded: true,
          companyMembersLoading: false,
        }
      })
      .addCase(fetchCompanyMembers.rejected, (state) => {
        return {
          ...state,
          companyMembersLoaded: true,
          companyMembersLoading: false,
        }
      })
      .addCase(setCompanyMembers, (state, action) => {
        const { payload } = action
        return {
          ...state,
          members: [...state.members, ...payload],
        }
      })
      .addCase(createFolder.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          allFolders: { ...state.allFolders, ...keyBy([payload], 'id') },
        }
      })
      .addCase(updateFolders.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          folders: { ...payload.folders },
          allFolders: { ...payload.allFolders },
        }
      })
      .addCase(deleteFolder.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          folders: { ...state.folders, ...payload },
        }
      })
      .addCase(renameFolder.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          allFolders: { ...state.allFolders, ...keyBy([payload], 'id') },
        }
      })
      .addCase(getFolders.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          foldersOrder: payload.foldersOrder,
        }
      })
  },
)

export default reducer
