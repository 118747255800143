import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { getRandomColor } from '../../utils/random'

import Typography from './Typography'
import { colors } from '../../theme/colors'

const DEFAULT_PERSONA_COLOR = 'avatar-09-color-surface-default'

const dictionaries = {
  size: {
    xsmall: 16,
    small: 24,
    medium: 32,
    big: 48,
  },
  variant: {
    xsmall: 'body-x-small-01',
    small: 'body-small-01',
    medium: 'body-large-01',
    big: 'header-large',
  },
}

const getInitialsFrom = (firstName = '', lastName = '', label = '') => {
  if (firstName || lastName) {
    const initials =
      `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`.trim()
    return initials
  }
  if (firstName === null || lastName === null) {
    if (label === null) return ''
  }
  return label.replaceAll(/[^a-z]*/gi, '').substring(0, 2)
}

const Persona = ({
  seed,
  size,
  color,
  firstName,
  lastName,
  initials,
  verified,
  unregistered,
  label,
  ...props
}) => {
  return (
    <div {...props}>
      <Typography variant={getVariant(size)}>
        {initials || getInitialsFrom(firstName, lastName, label)}
      </Typography>
      {verified && <img src="/img/verified_persona.svg" />}
    </div>
  )
}

Persona.propTypes = {
  firstName: PropTypes.string,
  initials: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'big']),
}

const getDictionary = (name) => {
  return (size) => {
    const result = dictionaries[name][size]

    if (result) {
      return result
    }

    throw new Error(`Invalid persona size: ${size}`)
  }
}

const getSize = getDictionary('size')
const getVariant = getDictionary('variant')

const getColor = (color, theme, seed, firstName, lastName) => {
  if (!lastName) {
    return theme.component.avatar.surface['avatar-color-surface-unregistered']
  }

  if (seed) {
    return theme.palette.avatar[getRandomColor(seed)]
  }

  if (!color) {
    return theme.component.avatar.surface[DEFAULT_PERSONA_COLOR]
  }

  if (typeof color === 'number') {
    return theme.palette.avatar[color || 9]
  }
  return color
}

export default styled(Persona)`
  width: ${({ size }) => getSize(size)}px;
  min-width: ${({ size }) => getSize(size)}px;
  height: ${({ size }) => getSize(size)}px;
  min-height: ${({ size }) => getSize(size)}px;

  position: relative;
  background: ${({ theme, color, seed, firstName, lastName }) =>
    getColor(color, theme, seed, firstName, lastName)};
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-full']};

  display: flex;
  justify-content: center;
  align-items: center;

  ${Typography} {
    text-transform: uppercase;
    color: ${({ color, theme, lastName }) => {
      if (color === 16) {
        return theme.palette.type.secondary
      }
      if (!lastName) {
        return theme.component.avatar.text['avarar-color-text-unregistered']
      }
      return theme.component.avatar.text['avarar-color-text-default']
    }};
    user-select: none;
  }

  > img {
    position: absolute;
    bottom: -2px;
    right: -2px;
  }
`
