import { callApi } from '../../utils/callApi'
import { logout } from '../../actions/logout'
import { parseResponse } from './parseResponse'
import { patchBcTxId } from '../../actions/blockchain'
import getToken from '../../store/TokenExtra'

export function sendresult({ result, _proofOutdated, retryCount = 0, finishFunc }) {
  const jwt = getToken()
  const { dispatch } = window.reduxStore
  const start = _proofOutdated.length
  let finish = 0

  const newResult = _proofOutdated.map((e) => {
    const body = {
      data: {
        type: e.type,
        attributes: {
          bcTxId: result.sent,
        },
      },
    }
    const { id } = e

    return callApi({
      endpoint: `${e.type}/${id}`,
      method: 'PATCH',
      applicationJson: true,
      body,
      accessToken: jwt,
      accessName: 'Bearer',
      headers: {},
      retryCount,
    })
      .then((json) => {
        finish++

        if (json?.data) dispatch(patchBcTxId(parseResponse({ json })))

        if (start === finish) {
          if (finishFunc) {
            finishFunc()
          }
        }
      })
      .catch((err) => {
        finish++
        /* eslint-disable eqeqeq */
        if (err?.response?.status == '401') {
          dispatch(logout())
        }
        if (start === finish) {
          if (finishFunc) {
            finishFunc()
          }
        }
      })
  })
  return newResult
}
