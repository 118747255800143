import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import SidebarItem from './SidebarItem'
import { Divider, Spacing } from '../../atoms'
import MenuItem from './MenuItem'
import ActionListComponent from '../../../pages/Playground/ActionListComponent'

const AtomsSubMenuItems = ({ pathname }) => {
  return (
    <>
      <MenuItem
        offset={1}
        value="Part 1"
        route="/playground/atoms/radio"
        activeHover
        isActive={() => pathname?.indexOf('playground/atoms/radio') !== -1}
      />
      <MenuItem
        offset={1}
        value="Part 2"
        route="/playground/atoms/button"
        activeHover
        isActive={() => pathname?.indexOf('playground/atoms/button') !== -1}
      />
    </>
  )
}

const DesignTokensMenuItems = ({ pathname }) => {
  return (
    <>
      <MenuItem
        offset={1}
        value="Colors"
        route="/playground/tokens/colors"
        isActive={() => pathname?.indexOf('playground/tokens/colors') !== -1}
        activeHover
      />
      <MenuItem
        offset={1}
        value="Space"
        route="/playground/tokens/space"
        isActive={() => pathname?.indexOf('playground/tokens/space') !== -1}
        activeHover
      />
      <MenuItem
        offset={1}
        value="Radius"
        route="/playground/tokens/radius"
        isActive={() => pathname?.indexOf('playground/tokens/radius') !== -1}
        activeHover
      />
    </>
  )
}

const FoundationMenuItems = ({ pathname }) => {
  return (
    <>
      <MenuItem
        offset={1}
        value="Icons"
        icon="playground/icons/"
        route="/playground/foundations/icons"
        activeHover
        isActive={() => pathname?.indexOf('/playground/foundations/icons') !== -1}
      />
      <MenuItem
        offset={1}
        value="Typography"
        route="/playground/foundations/typography"
        isActive={() => pathname?.indexOf('playground/foundations/Typography') !== -1}
        activeHover
      />
      <MenuItem
        offset={1}
        value="Shadow"
        route="/playground/foundations/shadows"
        isActive={() => pathname?.indexOf('playground/foundations/Shadows') !== -1}
        activeHover
      />
    </>
  )
}

const MoleculesSubMenuItems = ({ pathname }) => {
  return (
    <>
      <MenuItem
        offset={1}
        value="Part 1"
        route="/playground/molecules/newComponents"
        activeHover
        isActive={() => pathname?.indexOf('playground/molecules/newComponents') !== -1}
      />
      <MenuItem
        offset={1}
        value="Buttons"
        route="/playground/molecules/actionButton"
        activeHover
        isActive={() => pathname?.indexOf('playground/molecules/actionButton') !== -1}
      />
      <MenuItem
        offset={1}
        value="Action Icon"
        route="/playground/molecules/actionIcon"
        activeHover
        isActive={() => pathname?.indexOf('playground/molecules/actionIcon') !== -1}
      />
      <MenuItem
        offset={1}
        value="AssigneeInput"
        route="/playground/molecules/assigneeInput"
        activeHover
        isActive={() => pathname?.indexOf('playground/molecules/assigneeInput') !== -1}
      />
    </>
  )
}

const OrganismsSubMenuItems = ({ pathname }) => {
  return (
    <>
      <MenuItem
        offset={1}
        value="New Components"
        route="/playground/organisms/newOrganisms"
        activeHover
        isActive={() => pathname?.indexOf('playground/organisms/newOrganisms') !== -1}
      />
      <MenuItem offset={1} value="Task Card" route="/playground/organisms/taskCard" activeHover />
      <MenuItem
        offset={1}
        value="Test Antialiased"
        route="/playground/organisms/testAntialiased"
        activeHover
      />
    </>
  )
}
const PlaygroundSidebarPage = () => {
  const location = useLocation()

  return (
    <div>
      <MenuItem
        i18n="BackToWorkspace"
        value="Back to workspace"
        icon="arrow-left-circle"
        route="/my-contracts"
        activeHover
      />
      <Spacing size="20" />
      <Divider />
      <Spacing size="12" />
      <MenuItem
        value="General (old page)"
        icon="archivebox"
        route="/playground"
        noHighlight={location.pathname.replace('/playground', '').length > 0}
        activeHover
      />
      <MenuItem
        value="Design Tokens"
        route="/playground/tokens/colors"
        activeHover
        subMenuItems={<DesignTokensMenuItems pathname={location.pathname} />}
        subMenuRoute="/playground/tokens"
      />
      <MenuItem
        value="Foundations"
        route="/playground/foundations/icons"
        activeHover
        subMenuItems={<FoundationMenuItems pathname={location.pathname} />}
        subMenuRoute="/playground/foundations"
      />
      <MenuItem
        value="Action list"
        route="/playground/actionList"
        activeHover
        isActive={() => location.pathname?.indexOf('playground/actionList') !== -1}
      />
      <MenuItem
        value="Atoms"
        icon="playground/atom"
        route="/playground/atoms/radio"
        activeHover
        subMenuItems={<AtomsSubMenuItems pathname={location.pathname} />}
        subMenuRoute="/playground/atoms"
      />
      <MenuItem
        value="Molecules"
        icon="playground/molecules"
        route="/playground/molecules/newComponents"
        activeHover
        subMenuItems={<MoleculesSubMenuItems pathname={location.pathname} />}
        subMenuRoute="/playground/molecules"
      />
      <MenuItem
        value="Organisms"
        icon="playground/organisms/"
        route="/playground/organisms/newOrganisms"
        activeHover
        subMenuItems={<OrganismsSubMenuItems pathname={location.pathname} />}
        subMenuRoute="/playground/organisms"
      />
    </div>
  )
}

const SidebarSubItem = styled(SidebarItem)`
  padding-left: 44px;
`

export default PlaygroundSidebarPage
