/* eslint-disable no-underscore-dangle */
import { createAsyncThunk } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import ecies from 'eth-ecies'
import getDefaultRoute from '../../router/getDefaultRoute'
import { callApi } from '../../utils/callApi'
import AesServices from '../../services/aesServices'
import proofOutdated from '../../services/proofOutdated/proofOutdated'
import filterResult from '../../services/proofOutdated/filter_result'
import { sendresult } from '../../services/proofOutdated/sendresult'
import BlockChainService from '../../services/blockchainService'
import { printf } from '../../hooks/printf'
import { getLocation, getNavigate } from '../../history'
import { fixTransactionNonceMissing } from './fixTransactionNonceMissing'
import { setLoggedIn } from '../application'

const redirectFunc = ({ jwt, userinfo }) => {
  localStorage.setItem('token', jwt)
  localStorage.setItem('user_id', userinfo.id)
  localStorage.setItem('user_firstName', userinfo.firstName)
  localStorage.setItem('user_lastName', userinfo.lastName)
  localStorage.setItem('avatarColor', userinfo.avatarColor)
  localStorage.setItem('user_emailAddress', userinfo.emailAddress)
  localStorage.setItem('user_type', userinfo.userType)
  localStorage.setItem('user_activated', userinfo.userActivated)
  localStorage.setItem('user_publicKey', JSON.stringify(userinfo.publicKey))
  localStorage.setItem('user_address', userinfo.address)
  localStorage.setItem('user_secret', userinfo.secret)
  localStorage.setItem('user_privateKey', JSON.stringify(userinfo.privateKey))
  localStorage.setItem('features', JSON.stringify(userinfo.features))

  const navigate = getNavigate()
  const location = getLocation()

  const defaultRoute = getDefaultRoute(userinfo.userType)
  const pathname = location?.state?.lastLocation?.pathname || localStorage.getItem('redirectTo')
  localStorage.removeItem('redirectTo')
  // try to redirect to previous page (before login)
  // if there is any
  try {
    const redirectedFrom = pathname
    if (redirectedFrom) {
      // eslint-disable-next-line no-console
      printf(() => console.log(`Redirect to: ${redirectedFrom}`))

      setTimeout(() => navigate(redirectedFrom), 1000)
    } else {
      navigate(defaultRoute)
    }
  } catch (e) {
    navigate(defaultRoute)
  }
}

const cleanLocalStorage = () => {
  const curLang = localStorage.getItem('lang')
  localStorage.clear()
  localStorage.setItem('lang', curLang)
}

const defaultFunctionHandler = () => {}

export default createAsyncThunk(
  'account/login',
  async (
    {
      data,
      keypair,
      actsec,
      errorLogin = defaultFunctionHandler,
      errorConfirm = defaultFunctionHandler,
      accountInfo,
    },
    api,
  ) => {
    const pubk = keypair.publicKey
    const privk = keypair.privateKey
    const { address } = data.data.attributes
    // eslint-disable-next-line no-underscore-dangle
    const _secret = data.data.attributes.password
    const toSendData = {
      data: {
        type: 'sessions',
        attributes: {
          publicKey: data.data.attributes.publicKey,
          secret: data.data.attributes.secret,
        },
      },
    }

    try {
      let jwt

      if (accountInfo.old) {
        const json = await callApi({
          endpoint: `sessions`,
          method: 'POST',
          applicationJson: true,
          body: toSendData,
        })
        jwt = json.data.id
      } else {
        jwt = accountInfo.jwt
      }

      localStorage.setItem('token', jwt)
      // eslint-disable-next-line new-cap
      const decriptedJwt = jwtDecode(jwt)
      const userid = decriptedJwt.upn

      const persons = await callApi({
        endpoint: `naturalpersons/${userid}?include=account`,
        accessToken: jwt,
        accessName: 'Bearer',
      })

      const companyId = persons?.data?.relationships?.company?.data?.id
      let company
      if (companyId) {
        company = await callApi({
          endpoint: `companies/${companyId}`,
          accessToken: jwt,
          accessName: 'Bearer',
        })
      }

      const account = persons.included.find((i) => i.type === 'accounts')
      const accId = persons.data.relationships.account.data.id

      const userinfo = {
        jwt,
        id: userid,
        firstName: persons.data.attributes.firstName,
        lastName: persons.data.attributes.lastName,
        emailAddress: persons.data.attributes.emailAddress,
        userType: account.attributes.type,
        userActivated: account.attributes.state,
        avatarColor: account.attributes.avatarColor,
        publicKey: pubk,
        address,
        secret: _secret,
        privateKey: privk,
        features: decriptedJwt.features,
        loaded: true,
      }

      await fixTransactionNonceMissing({
        address: userinfo.address,
        secret: userinfo.secret,
      })

      const sendProofs = (proofs) => {
        const proof = proofOutdated(proofs)
        const _proofOutdated = filterResult(proof)
        if (_proofOutdated.length) {
          const bc = new BlockChainService({
            address,
            password: _secret,
          })
          // eslint-disable-next-line camelcase
          const _proofOutdated_string = JSON.stringify(_proofOutdated)

          bc.createTransaction(
            _proofOutdated_string,
            // eslint-disable-next-line no-shadow
            (result, _proofOutdated) =>
              sendresult({
                result,
                _proofOutdated,
                finishFunc: () => redirectFunc({ jwt, userinfo }),
              }),
            _proofOutdated,
          )
        }
      }
      if (actsec && account.attributes.state !== 'activated') {
        const activationJson = {
          data: {
            type: 'accounts',
            attributes: {
              activationData: {
                activationSecret: actsec,
              },
            },
          },
        }

        const activationResponse = await callApi({
          endpoint: `accounts/${accId}`,
          method: 'PATCH',
          body: activationJson,
          accessToken: jwt,
          accessName: 'Bearer',
        })

        const proofs = []
        proofs.push(activationResponse)

        const accessgrants = await callApi({
          endpoint: `accessgrants?filter[grantedTo.id]=${userid}`,
          accessToken: jwt,
          accessName: 'Bearer',
        })

        const nextAccessGrantOrFinish = (i) => {
          if (i + 1 < accessgrants.data.length) {
            patchAccess(i + 1)
          } else {
            sendProofs(proofs)
          }
        }

        const patchAccess = async (i) => {
          const accessgrant = accessgrants.data[i]
          let decriptedDs
          try {
            decriptedDs = new AesServices({
              fileAES: accessgrant.attributes.encrDS,
              key: userinfo.emailAddress,
            }).decryptFile()
          } catch (e) {
            // this document secret was encrypted with actual public key
            // instead of email; no need to convert it here
            nextAccessGrantOrFinish(i)
            return
          }

          if (decriptedDs === '' || decriptedDs === 'Could not decrypt the file') {
            // still the same issue
            // this document secret was encrypted with actual public key
            // instead of email; no need to convert it here
            nextAccessGrantOrFinish(i)
            return
          }

          const userPublickKey2 = Buffer.from(pubk, 'hex')
          let encrDS = ecies.encrypt(userPublickKey2, decriptedDs)
          encrDS = encrDS.toString('base64')

          const sendJson = {
            data: {
              type: 'accessgrants',
              attributes: {
                encrDS,
              },
            },
          }

          const accessPatch = callApi({
            endpoint: `accessgrants/${accessgrant.id}`,
            method: 'PATCH',
            body: sendJson,
            accessToken: jwt,
            accessName: 'Bearer',
          })

          proofs.push(accessPatch)
          nextAccessGrantOrFinish(i)
        }

        if (accessgrants.data.length > 0) {
          await patchAccess(0)
        } else {
          sendProofs(proofs)
        }
      } else {
        const accountRequest = await callApi({
          endpoint: `accounts/${accId}`,
          accessToken: jwt,
          accessName: 'Bearer',
        })
        localStorage.setItem('accountCreatedOn', accountRequest?.data?.attributes?.created)
        if (accountRequest.data && accountRequest.data.attributes.state !== 'unconfirmed') {
          redirectFunc({ jwt, userinfo })
        } else {
          cleanLocalStorage()
          errorConfirm()
          return {}
        }
      }

      api.dispatch(setLoggedIn(true))

      return {
        jwt,
        company: company?.data || {},
        currentUser: {
          ...userinfo,
          publicKey: JSON.stringify(userinfo.publicKey),
          privateKey: JSON.stringify(userinfo.privateKey),
        },
      }
    } catch (error) {
      if (error?.response) {
        /* eslint-disable eqeqeq */
        if (error?.response?.status == '403') {
          cleanLocalStorage()
        } else if (error?.response?.status == '401') {
          // errorLogin
        }
      }

      errorLogin()
    }

    return {}
  },
)
