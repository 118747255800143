/* eslint-disable no-param-reassign */
import i18n from '../../../../i18n'
import { OVERLAY_HEIGHT, icons } from './const'
import { renderMoveHandler, renderDeleteHandler } from './handlers'

const renderer = (overlay, ctx) => {
  const { theme } = overlay

  if (overlay.render) {
    const mod = overlay.render.width / overlay.width
    overlay.height = OVERLAY_HEIGHT
    ctx.drawImage(overlay.render, overlay.offsetX, overlay.offsetY, overlay.width, overlay.height)
  } else {
    const width = 52 + ctx.measureText(i18n.t('signup:signature')).width
    overlay.width = width
    ctx.fillStyle = theme.palette.background.primary
    ctx.strokeStyle = theme.palette.divider
    ctx.fillRect(overlay.offsetX, overlay.offsetY, width, OVERLAY_HEIGHT)

    ctx.beginPath()
    ctx.moveTo(overlay.offsetX, overlay.offsetY)
    ctx.lineTo(overlay.offsetX + width, overlay.offsetY)
    ctx.lineTo(overlay.offsetX + width, overlay.offsetY + OVERLAY_HEIGHT)
    ctx.lineTo(overlay.offsetX, overlay.offsetY + OVERLAY_HEIGHT)
    ctx.lineTo(overlay.offsetX, overlay.offsetY)
    ctx.lineWidth = 1
    ctx.stroke()

    ctx.drawImage(icons.signature, overlay.offsetX + 12, overlay.offsetY + 14)
    ctx.fillStyle = theme.palette.type.primary
    ctx.fillText(i18n.t('signup:signature'), overlay.offsetX + 40, overlay.offsetY + 26)
    ctx.fillText(i18n.t('signup:signature'), overlay.offsetX + 40, overlay.offsetY + 26)
  }

  renderMoveHandler(overlay, ctx)
  renderDeleteHandler(overlay, ctx)
}

const onClick = (e, overlay) => overlay.actions.signatureModalOpen(e, overlay)
const onRemove = (e, overlay) => overlay.actions.removeOverlay(overlay)

export default (data) => {
  return {
    ...data,
    type: 'signature',
    renderer,
    onClick,
    onRemove,
  }
}
