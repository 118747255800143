import { useEffect, useState } from 'react'
import PDFDocument from '../../organisms/FilePreview/PDFDocument'

const document = {
  id: null,
}

const TermsConditionsDe = () => {
  const [preview, setPreview] = useState(null)
  useEffect(() => {
    const lazyLoad = async () => {
      if (!preview) {
        const { default: loadPreview } = await import('../../../static/docs/k2c-tc-de.pdf')
        setPreview(loadPreview)
      }
    }

    lazyLoad()
  }, [preview])

  if (!preview) {
    return null
  }

  return <PDFDocument preview={preview} document={document} />
}
export default TermsConditionsDe
