import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { Modal } from '../atoms'
import { CookiePolicyDe } from './TermsPolicies/CookiePolicyDe'
import { CookiePolicyEn } from './TermsPolicies/CookiePolicyEn'
import { LANGUAGES } from '../../enums'

const CookiePolicy = ({ visible, onClose, className }) => {
  const { i18n } = useTranslation()
  return (
    <Modal
      termsConditions
      onClose={onClose}
      visible={visible}
      className={className}
      headerI18n="CookiePolicy"
    >
      <div style={{ minWidth: '70vw', height: '100%' }}>
        {i18n.language === LANGUAGES.DE ? <CookiePolicyDe /> : <CookiePolicyEn />}
      </div>
    </Modal>
  )
}

export default styled(CookiePolicy)``
