import styled from 'styled-components'

import { Typography } from '../../atoms'

export default styled.div`
  padding: 0 24px;
  border-right: 1px solid ${({ theme }) => theme.palette.accent.grey};
`

const BaseMenuItem = ({ i18n, ...props }) => {
  return (
    <div {...props}>
      <Typography variant="14 medium" i18n={i18n} />
    </div>
  )
}

export const MenuItem = styled(BaseMenuItem)`
  padding: 4px 8px;
  cursor: pointer;
  background: ${({ selected, theme }) => (selected ? theme.palette.accent.grey : 'transparent')};
  border-radius: 8px;

  ${Typography} {
    color: ${({ selected, theme }) =>
      selected ? theme.palette.accent.black : theme.palette.accent.darkGrey};
  }
`
