import { createReducer } from '@reduxjs/toolkit'
import JsSHA from 'jssha'

import {
  debuggerRedirect,
  enableDebugger,
  enableNavigation,
  setLoggedIn,
  setLoggedInAsNewAccout,
  setLoggedInAsOldAccout,
  setProduktlyInit,
  toggleDocumentView,
  toggleDocumentViewStatus,
  toggleInviteParticipantsModal,
  toggleLoadingEncryption,
  toggleSidebar,
  toggleSigning,
  toggleUnloadLock,
} from '../actions/application'
import { logout } from '../actions/logout'
import { loadInitialReducerState } from '../store/persist'

const initialState = {
  showSidebar: true,
  showInviteParticipantsModal: false,
  loggedInAsOldAccount: false,
  unloadLock: [],
  debugger: false,
  redirect: false,
  signing: false,
  encryptionLoading: true,
  documentView: false,
  loggedIn: false,
  produktlyInit: false,
  documentViewStatus: 'active',
  ...loadInitialReducerState('application', 'inhubber-redux'),
  navigation: false,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleSidebar, (state, action) => {
      return {
        ...state,
        showSidebar: !!action.payload.state,
      }
    })
    .addCase(toggleInviteParticipantsModal, (state, action) => {
      return {
        ...state,
        showInviteParticipantsModal: action.payload.state,
      }
    })
    .addCase(toggleSigning, (state, action) => {
      return {
        ...state,
        signing: action.payload.state,
      }
    })
    .addCase(toggleUnloadLock, (state, action) => {
      const { id = '', lock } = action.payload
      const shaObj = new JsSHA('SHA3-256', 'TEXT')
      shaObj.update(id)
      const hash = shaObj.getHash('HEX')
      const unloadLock = lock
        ? [...state.unloadLock.filter((l) => l !== hash), hash]
        : state.unloadLock.filter((l) => l !== hash)

      return {
        ...state,
        unloadLock,
      }
    })
    .addCase(setLoggedInAsOldAccout, (state) => {
      return {
        ...state,
        loggedInAsOldAccount: true,
      }
    })
    .addCase(setLoggedInAsNewAccout, (state) => {
      return {
        ...state,
        loggedInAsOldAccount: false,
      }
    })
    .addCase(enableDebugger, (state, action) => {
      return {
        ...state,
        debugger: !!action.payload,
      }
    })
    .addCase(debuggerRedirect, (state, action) => {
      return {
        ...state,
        redirect: action.payload,
      }
    })
    .addCase(logout, () => {
      return {
        ...initialState,
      }
    })
    .addCase(toggleLoadingEncryption, (state, action) => {
      return {
        ...state,
        encryptionLoading: !!action.payload,
      }
    })
    .addCase(toggleDocumentView, (state, action) => {
      return {
        ...state,
        documentView: !!action.payload,
      }
    })
    .addCase(setLoggedIn, (state, action) => {
      return {
        ...state,
        loggedIn: !!action.payload,
      }
    })
    .addCase(toggleDocumentViewStatus, (state, action) => {
      return {
        ...state,
        documentViewStatus: action.payload,
      }
    })
    .addCase(setProduktlyInit, (state, action) => {
      return {
        ...state,
        produktlyInit: action.payload,
      }
    })
    .addCase(enableNavigation, (state, action) => {
      return {
        ...state,
        navigation: !!action.payload,
      }
    })
})

export default reducer
