import { useTheme } from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import { Button, Modal, Spacing, Spinner, Typography } from '../atoms'
import { callApi } from '../../utils/callApi'
import TextInput from '../atoms/Input/TextInput'
import { createNotification } from '../../actions/notifications'
import { setActiveWorkspace } from '../../actions/workspaces'
import { selectIncluded, selectWorkspaces } from '../../store/selectors'
import { generateSlug } from '../../pages/Workspaces/slugs'

const RemoveWorkspace = ({ visible, onClose, workspaceId, workspaceTitle, confirmDelete }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const [deleteAnimation, setDeleteAnimation] = useState(false)
  const [error, setError] = useState(false)
  const [confirmText, setConfirmText] = useState()

  const selectYourComponentData = createSelector(
    [selectWorkspaces, selectIncluded],
    (workspaces, included) => ({
      activeWorkspaceId: workspaces.active,
      workspaces: included.workspaces,
    }),
  )

  const { activeWorkspaceId, workspaces } = useSelector(selectYourComponentData)

  useEffect(() => {
    setConfirmText('')
    setError(false)
  }, [visible])

  const removeUserAccess = async (event) => {
    setError(false)
    if (workspaceTitle === confirmText) {
      setDeleteAnimation(true)
      try {
        const workspace = workspaces[workspaceId]
        const workspaceSlug = generateSlug(workspace)
        if (
          activeWorkspaceId === workspaceId &&
          location.pathname.includes(`workspaces/${workspaceSlug}`)
        ) {
          dispatch(setActiveWorkspace({ id: null, folderId: null }))
          navigate('/my-contracts')
        }
        await callApi({
          endpoint: `workspaces/${workspaceId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/vnd.api+json',
          },
        })
        dispatch(
          createNotification({
            value: t('workspaceWasDeleted', { title: workspaceTitle }),
            description: t('WorkspaceHasBeenRemoved'),
            state: 'success',
          }),
        )
        setDeleteAnimation(false)
        onClose()
        if (typeof confirmDelete === 'function') confirmDelete(event)
      } catch (e) {
        setDeleteAnimation(false)
      }
    } else {
      setError(true)
    }
  }

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      header={t('workspace:DeleteWorkspace')}
      subHeader={t('workspace:DeletingThisWorkspace')}
      type="oldModal"
      buttons={
        <>
          <Button i18n="cancel" width="fit-content" secondary onClick={onClose}>
            Cancel
          </Button>
          <Button
            i18n={deleteAnimation ? undefined : 'Delete'}
            width="fit-content"
            tone="critical"
            onClick={deleteAnimation ? undefined : removeUserAccess}
            disabled={deleteAnimation}
            process={deleteAnimation}
            data-test="button_removeWorkspace_modal_delete"
          >
            Delete
          </Button>
        </>
      }
    >
      <Spacing size="24" />
      <Typography variant="12" color="secondary">
        {t('PleaseEnterContinue', { title: workspaceTitle })}
      </Typography>
      <Spacing size="4" />
      <TextInput
        type="text"
        placeholder={t('EnterContinue', { title: workspaceTitle })}
        value={confirmText}
        onChange={(e) => setConfirmText(e.target.value)}
        error={error}
        validator={() => {
          return error ? t('PleaseEnterRequiredWWsName') : ''
        }}
        data-test="input_removeWorkspace_modal_title"
      />
    </Modal>
  )
}

export default RemoveWorkspace
