import { useEffect } from 'react'

const useInnerRef = (handler, innerRef) => {
  useEffect(() => {
    if (innerRef && typeof handler === 'function') {
      // eslint-disable-next-line no-param-reassign
      innerRef.current = handler()
    }
  }, [handler, innerRef])
}

export default useInnerRef
