import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Modal from '../atoms/Modal/Modal'
import TermsConditionsDe from './TermsPolicies/TermsConditionsDe'
import TermsConditionsEn from './TermsPolicies/TermsConditionsEn'
import { LANGUAGES } from '../../enums'

const TermsConditions = ({ visible, onClose, className }) => {
  const { i18n } = useTranslation()
  return (
    <Modal
      termsConditions
      onClose={onClose}
      visible={visible}
      className={className}
      headerI18n="TandC_title"
      data-test="modal_terms-conditions"
    >
      <div style={{ minWidth: '70vw', height: '100%' }}>
        {i18n.language === LANGUAGES.DE ? <TermsConditionsDe /> : <TermsConditionsEn />}
      </div>
    </Modal>
  )
}

export default styled(TermsConditions)``
