import styled from 'styled-components'

const ScrollContainer = ({ children, className }) => {
  return <div className={className}>{children}</div>
}

export default styled(ScrollContainer)`
  overflow: auto;
  overflow-x: hidden;
  max-height: ${({ height }) => height || 'initial'};
`
