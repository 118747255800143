import { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import Typography from './Typography'
import SvgMask from './SvgMask'
import BasePopup from './Modal/BasePopup'
import Flex from './Flex'
import Elevation from './Elevation'
import LabelField from './LabelField'
import ModalItem from './Modal/ModalItem'

const getIcon = (state, icon) => {
  if (icon === null) {
    return undefined
  }

  if (icon) {
    return icon
  }

  return state ? 'chevron-small-up' : 'chevron-small-down'
}

const Select = ({
  labelI18n,
  label,
  valueI18n,
  value,
  disabled,
  rightIcon,
  canUpdate,
  error,
  errorMessage,
  errorMessageI18n,
  sideLabel,
  templateColumn,
  description,
  required,
  helpText,
  helpTextI18n,
  containerWidth,
  className,
  ...props
}) => {
  const labelProps = {
    labelI18n,
    label,
    error,
    errorMessage,
    errorMessageI18n,
    sideLabel,
    templateColumn,
    description,
    required,
    helpText,
    helpTextI18n,
    width: containerWidth,
  }

  return (
    <LabelField {...labelProps} className={className}>
      <BaseSelectField
        disabled={disabled}
        i18n={valueI18n || value}
        data-test="dropdown_button"
        error={error}
        {...props}
      />
    </LabelField>
  )
}

Select.propTypes = {
  children: PropTypes.any,
  valueI18n: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.bool,
}

const BaseSelectField = ({
  i18n,
  children,
  variant,
  disabled,
  rightIcon,
  error = false,
  icon,
  portal,
  placement,
  overlay,
  onClickOnSubMenu,
  onChangeSubMenu,
  haveSubMenu,
  canUpdate,
  onOpen,
  autoHeight,
  menuWidth,
  zIndex,
  portalClassName,
  readOnly,
  width,
  className,
  ...props
}) => {
  const theme = useTheme()

  const [opened, setOpened] = useState(false)
  const [target, setTarget] = useState(null)

  useEffect(() => {
    if (onClickOnSubMenu) {
      setOpened(false)
      if (typeof onChangeSubMenu === 'function') onChangeSubMenu(false)
    }
  }, [onChangeSubMenu, onClickOnSubMenu])

  const menu = useMemo(() => {
    if (portal) {
      return createPortal(
        <BasePopup
          target={target}
          options={{ placement: placement || 'bottom-start', strategy: 'fixed' }}
          spacing={4}
          overlay={overlay === undefined ? true : overlay}
          onClose={(e) => {
            if (haveSubMenu) e.stopPropagation()
            setOpened(false)
          }}
          onClick={(e) => {
            if (haveSubMenu) e.stopPropagation()
            setOpened(false)
          }}
          closeOnClick
          canUpdate={canUpdate}
          className={portalClassName}
          width={menuWidth || target?.offsetWidth}
          autoHeight={autoHeight}
          zIndex={zIndex}
        >
          {children}
        </BasePopup>,
        document.body,
      )
    }
    return (
      <BasePopup
        target={target}
        options={{ placement: placement || 'bottom-start', strategy: 'fixed' }}
        spacing={4}
        overlay={overlay === undefined ? true : overlay}
        onClose={(e) => {
          if (haveSubMenu) e.stopPropagation()
          setOpened(false)
        }}
        onClick={(e) => {
          if (haveSubMenu) e.stopPropagation()
          setOpened(false)
        }}
        closeOnClick
        canUpdate={canUpdate}
        width={menuWidth || target?.offsetWidth}
        autoHeight={autoHeight}
        zIndex={zIndex}
      >
        {children}
      </BasePopup>
    )
  }, [
    autoHeight,
    canUpdate,
    children,
    haveSubMenu,
    menuWidth,
    overlay,
    placement,
    portal,
    portalClassName,
    target,
    zIndex,
  ])

  return (
    <SelectField
      ref={setTarget}
      opened={opened}
      error={error}
      disabled={disabled}
      readOnly={readOnly}
      width={width}
      className={className}
    >
      <button
        type="button"
        disabled={disabled}
        onClick={
          disabled
            ? undefined
            : (e) => {
                if (!opened && typeof onOpen === 'function') onOpen(e)
                setOpened(!opened)
              }
        }
        {...props}
      >
        <Flex>
          {icon && (
            <SvgMask
              icon={icon}
              color={
                theme.component.input.icon[
                  disabled ? 'input-color-icon-disabled' : 'input-color-icon-filled'
                ]
              }
            />
          )}
          <Typography i18n={i18n} variant={variant || 'body-large-02'} />
        </Flex>
        <SvgMask
          icon={getIcon(opened, rightIcon)}
          color={theme.component.input.icon['input-color-icon']}
        />
      </button>
      {opened && menu}
    </SelectField>
  )
}

const SelectField = styled.div`
  width: ${({ width }) => width || '100%'};
  ${Elevation} {
    animation: none;
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: auto;
    border-radius: ${({ theme }) => theme.radius['input-border-radius']};
    border: 1px solid
      ${({ theme, opened, error, disabled, readOnly }) => {
        if (disabled || readOnly) {
          return theme.component.input.border['input-color-border-disabled']
        }
        if (error) {
          return theme.component.input.border['input-color-border-error']
        }
        if (opened) {
          return theme.component.input.border['input-color-border-active']
        }
        return theme.component.input.border['input-color-border']
      }};
    background: ${({ theme, disabled, readOnly }) =>
      disabled || readOnly
        ? theme.component.input.surface['input-color-background-disabled']
        : theme.component.input.surface['input-color-surface']};
    width: ${({ width }) => width || '100%'};
    padding: ${({ theme }) => theme.space['input-padding-vertical']};
    gap: ${({ theme }) => theme.space['input-gap']};
    height: 32px;
    pointer-events: ${({ disabled, readOnly }) => (disabled || readOnly) && 'none'};

    &:hover {
      border: ${({ theme, error }) =>
        !error && `1px solid ${theme.component.input.border['input-color-border-hover']}`};
      cursor: pointer;
    }

    &:focus {
      border: ${({ theme, error }) =>
        !error && `1px solid ${theme.component.input.border['input-color-border-active']}`};
    }

    > ${Flex} {
      gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
    }

    ${Typography} {
      color: ${({ theme, disabled, readOnly }) => {
        if (disabled) {
          return theme.component.input.value['input-color-value-disabled']
        }
        if (readOnly) {
          return theme.component.input.value['input-color-value-read-only']
        }
        return theme.component.input.value['input-color-value-filled']
      }};
    }
  }
  ${ModalItem} {
    min-height: 32px;
  }
`

export default styled(Select)``
