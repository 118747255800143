import jsSHA from 'jssha'

class ShaService {
  constructor(props) {
    this.algorithm = props.algorithm || 'SHA3-256'
    this.type = props.type || 'TEXT'
    this.value = props.value
  }

  encrypt() {
    // eslint-disable-next-line new-cap
    const shaObj = new jsSHA(this.algorithm, this.type)
    shaObj.update(this.value)
    return shaObj.getHash('HEX')
  }
}

export default ShaService
