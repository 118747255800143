import styled from 'styled-components'
import React, { useEffect, useState } from 'react'

import { callApi } from '../../utils/callApi'
import { SiteVersion } from '../../utils/constans'
import Typography from '../atoms/Typography'
import { Spacing } from '../atoms'

const Versions = ({ ...props }) => {
  const [backEndVersion, setbackEndVersion] = useState('')
  const isTestEnvironment = process.env.REACT_APP_API_STAGE !== 'prod'

  useEffect(() => {
    if (!isTestEnvironment) {
      return
    }

    callApi({
      endpoint: `info`,
      method: 'GET',
      headers: {},
    }).then((json) => json?.build?.version && setbackEndVersion(json.build.version))
  }, [isTestEnvironment])

  if (!backEndVersion || !isTestEnvironment) {
    return null
  }

  return (
    <div data-test="versions" {...props}>
      <Typography variant="10">
        frontend: <span data-test="versions-fe">{SiteVersion}</span>
      </Typography>
      <Spacing size="4" />
      <Typography variant="10">
        backend: <span data-test="versions-be">{backEndVersion}</span>
      </Typography>
    </div>
  )
}

export default styled(Versions)`
  padding: 4px 8px;
  background: ${({ theme }) => theme.palette.background.primary};
  position: fixed;
  bottom: 0px;
  right: 0px;
  border-top-left-radius: 4px;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  border-left: 1px solid ${({ theme }) => theme.palette.divider};

  user-select: none;
  pointer-events: none;

  opacity: 0.64;

  > ${Typography} {
    display: block;
  }
`
