import styled from 'styled-components'

import Typography from '../Typography'

import { useResponsive } from '../../../hooks'

const Description = ({ description, descriptionI18n, className }) => {
  return (
    <div className={className}>
      <Typography i18n={descriptionI18n} variant="body-large-02" color="secondary">
        {description}
      </Typography>
    </div>
  )
}

export default styled(Description).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  padding: ${({ $isGreaterOrEqualThan, theme }) =>
    $isGreaterOrEqualThan('md')
      ? `${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-medium']} 0 ${theme.semantic.space['space-medium']}`
      : `${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-small']} 0 ${theme.semantic.space['space-small']}`};
`
