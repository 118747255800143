import { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { useMeasure } from 'react-use'
import { animated, useSpring } from '@react-spring/web'

import ModalOverlay from './ModalOverlay'
import BaseElevation from './BaseElevation'

const BaseModal = ({
  hidden,
  overlay,
  children,
  target,
  options,
  onClose,
  isAnimated,
  closeOnClick = false,
  ...props
}) => {
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(target, popperElement, options)
  useEffect(() => () => setPopperElement(null), [])

  const [ref, { height }] = useMeasure()
  const expand = useSpring({
    height: !hidden ? height : 0,
    overflow: 'hidden',
    padding: hidden ? 0 : 20,
    margin: hidden ? 0 : -20,
  })

  if (hidden) {
    return null
  }

  if (!isAnimated) {
    return (
      <>
        {overlay && !hidden && <ModalOverlay onClick={onClose} data-test="modal_overlay" />}
        <div
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: 5500,
          }}
          {...attributes.popper}
          {...props}
        >
          <BaseElevation hidden={hidden} closeOnClick={closeOnClick} type="modal">
            {children}
          </BaseElevation>
        </div>
      </>
    )
  }

  return (
    <>
      {overlay && !hidden && <ModalOverlay onClick={onClose} data-test="modal_overlay" />}
      <div
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 5500 }}
        {...attributes.popper}
        {...props}
      >
        <animated.div style={expand}>
          <div ref={ref}>
            <BaseElevation hidden={hidden} closeOnClick={closeOnClick} type="modal">
              {children}
            </BaseElevation>
          </div>
        </animated.div>
      </div>
    </>
  )
}

export default BaseModal
