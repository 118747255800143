import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { createPortal } from 'react-dom'

import Button, { IconButton, SecondaryButton, TertiaryButton } from './Button'
import Elevation from './Elevation'
import ModalOverlay from './Modal/ModalOverlay'
import BasePopup from './Modal/BasePopup'
import SvgMask from './SvgMask'

const getIcon = (disabled, state, icon) => {
  if (disabled || icon === null) {
    return undefined
  }

  if (icon) {
    return icon
  }

  return state ? 'chevron-small-up' : 'chevron-small-down'
}

const DisclosureButton = ({
  disabled,
  i18n,
  tertiary,
  primary,
  children,
  value,
  placement,
  width,
  menuWidth,
  input,
  icon = null,
  iconAfter,
  overlay,
  onOpen,
  canUpdate,
  portal,
  portalClassName,
  autoHeight,
  zIndex,
  haveSubMenu,
  onClickOnSubMenu,
  onChangeSubMenu,
  process,
  iconButton,
  modifiers,
  type,
  ...props
}) => {
  const { palette } = useTheme()

  const [visible, setVisible] = useState(false)
  const [target, setTarget] = useState(null)

  // refactor component

  let Container = SecondaryButton

  if (tertiary || type === 'tertiary') {
    Container = TertiaryButton
  } else if (input) {
    Container = InputDropDown
  } else if (primary) {
    Container = Button
  } else if (iconButton || type === 'icon') {
    Container = IconButton
  }

  useEffect(() => {
    if (onClickOnSubMenu) {
      setVisible(false)
      if (typeof onChangeSubMenu === 'function') onChangeSubMenu(false)
    }
  }, [onChangeSubMenu, onClickOnSubMenu])

  const menu = useMemo(() => {
    if (portal) {
      return createPortal(
        <BasePopup
          target={target}
          options={{
            placement: placement || 'bottom-start',
            strategy: 'fixed',
            modifiers: modifiers || [
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ],
          }}
          spacing={4}
          overlay={overlay === undefined ? true : overlay}
          onClose={(e) => {
            if (haveSubMenu) e.stopPropagation()
            setVisible(false)
          }}
          onClick={(e) => {
            if (haveSubMenu) e.stopPropagation()
            setVisible(false)
          }}
          closeOnClick
          canUpdate={canUpdate}
          className={portalClassName}
          width={menuWidth}
          autoHeight={autoHeight}
          zIndex={zIndex}
        >
          {children}
        </BasePopup>,
        document.body,
      )
    }
    return (
      <BasePopup
        target={target}
        options={{
          placement: placement || 'bottom-start',
          strategy: 'fixed',
          modifiers: modifiers || [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
        }}
        spacing={0}
        overlay={overlay === undefined ? true : overlay}
        onClose={(e) => {
          if (haveSubMenu) e.stopPropagation()
          setVisible(false)
        }}
        onClick={(e) => {
          if (haveSubMenu) e.stopPropagation()
          setVisible(false)
        }}
        closeOnClick
        canUpdate={canUpdate}
        width={menuWidth}
        autoHeight={autoHeight}
        zIndex={zIndex}
      >
        {children}
      </BasePopup>
    )
  }, [
    autoHeight,
    canUpdate,
    children,
    haveSubMenu,
    menuWidth,
    modifiers,
    overlay,
    placement,
    portal,
    portalClassName,
    target,
    zIndex,
  ])

  return (
    <div ref={setTarget} style={{ display: visible ? 'initial' : undefined }} {...props}>
      <Container
        i18n={i18n}
        width={width ? `${width}` : 'fit-content'}
        icon={getIcon(null, true, icon)}
        iconAfter={getIcon(disabled, visible, iconAfter)}
        onClick={
          disabled
            ? undefined
            : (e) => {
                if (!visible && typeof onOpen === 'function') onOpen(e)
                setVisible(!visible)
              }
        }
        disabled={disabled}
        color={input ? palette.type.secondary : undefined}
        data-test="dropdown_button"
        variant={input && 'body 14 regular'}
        process={process}
      >
        {value || i18n}
      </Container>
      {visible && menu}
    </div>
  )
}

DisclosureButton.propTypes = {
  children: PropTypes.any,
  i18n: PropTypes.string,
  placement: PropTypes.string,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  value: PropTypes.any,
}

export const DropDown = styled(DisclosureButton)``

export default styled(DisclosureButton)`
  ${Elevation} {
    width: fit-content;
    height: fit-content;

    animation: none;

    padding: 4px;
    min-width: 0;
    max-width: 1000px;
  }

  ${Button} {
    min-width: inherit;
    justify-content: space-between;
  }

  ${ModalOverlay} {
    cursor: initial;
  }
`

export const InputDropDown = styled(SecondaryButton)`
  display: flex;
  justify-content: space-between;
  ${SvgMask} {
    margin-left: auto;
  }
  :hover {
    background: ${({ theme }) => theme.palette.background.white};
  }
  :hover:after {
    border: 1px solid ${({ theme }) => theme.palette.theme.primary};
  }
  :focus:after {
    border: 1px solid ${({ theme }) => theme.palette.theme.primary};
  }
`
