import { useMemo, useCallback, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { IbanElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { ValidationError } from '../../atoms'

const IBANInput = ({ className, onChange, error: validationError }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState()

  const { palette } = useTheme()

  const IBAN_STYLE = useMemo(
    () => ({
      base: {
        color: palette.type.primary,
        iconColor: palette.type.primary,
        fontSize: '14px',
        '::placeholder': {
          color: palette.type.tertiary,
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: palette.accent.warning,
        iconColor: palette.accent.warning,
        ':-webkit-autofill': {
          color: palette.accent.warning,
        },
      },
    }),
    [palette],
  )

  const IBAN_ELEMENT_OPTIONS = useMemo(
    () => ({
      supportedCountries: ['SEPA'],
      style: IBAN_STYLE,
    }),
    [IBAN_STYLE],
  )

  const disabled = !stripe || !elements

  const innerOnChange = useCallback(
    (e) => {
      setError(e.error?.message)

      if (disabled) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
      } else if (typeof onChange === 'function') {
        const iban = elements.getElement(IbanElement)
        onChange({ event: e, iban: e.error ? null : { element: iban, stripe } })
      }
    },
    [disabled, elements, onChange, stripe],
  )

  const errorToShow = validationError || error

  return (
    <div className={className}>
      <IbanElement options={IBAN_ELEMENT_OPTIONS} onChange={innerOnChange} disabled={disabled} />

      {errorToShow && <ValidationError offset="none">{errorToShow}</ValidationError>}
    </div>
  )
}

export default styled(IBANInput)`
  > .StripeElement {
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    transition: border 0.3s ${({ theme }) => theme.animation.bezier};

    :hover,
    :focus {
      border: 1px solid ${({ theme: { palette } }) => palette.theme.primary};
    }
  }
`
