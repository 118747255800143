/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
import light, { post$ } from '@parity/light.js'
import asyncify from '../../runtime/asyncify'
import { web3connect } from '../../services/createWeb3Connectin'
import { createParityConnection, hostprovider } from '../../services/provider'
import { systemAddress } from '../../utils/constans'

export const fixTransactionNonceMissing = async ({ address, secret }) => {
  let repeat = true
  let sendConfirm = true
  while (repeat) {
    if (sendConfirm) {
      const [web3] = await asyncify(web3connect)
      const { provider } = createParityConnection()
      light.setProvider(provider)
      const getTransactionCount = await asyncify(web3.eth.getTransactionCount, address)
      const nonce = getTransactionCount[1]
      const nonce16 = nonce.toString(16)
      const data = await fetchParityAllTransactions()
      const { result } = data
      const myData = result
        .filter((e) => e.from === `0x${address}`)
        .sort((i) => parseInt(i.nonce, 16))
      if (myData.length > 0) {
        sendConfirm = false
        web3connect((_web3) => {
          if (!provider.isConnected || provider.isConnecting) {
            setTimeout(() => fixTransactionNonceMissing({ address, secret }), 1000)
          } else {
            post$(
              {
                from: address,
                to: systemAddress,
                data: _web3.utils.toHex('fixTransaction'),
                nonce: `0x${nonce16}`,
              },
              {
                passphrase: secret,
              },
            ).subscribe((_result) => {
              if (_result.confirmed) {
                sendConfirm = true
              }
            })
          }
        })
      } else {
        repeat = false
      }
    }
    await new Promise((resolve) => {
      setTimeout(resolve, 500)
    })
  }
  return true
}

const fetchParityAllTransactions = async () => {
  const url = hostprovider
  const body = {
    method: 'parity_allTransactions',
    params: [],
    id: 1,
    jsonrpc: '2.0',
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error('error')
    }

    const data = await response.json()
    return data
  } catch (error) {
    return {}
  }
}
