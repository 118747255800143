import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import Flex from '../atoms/Flex'
import { Divider, Elevation, Spacing, SvgMask, Tooltip, Typography } from '../atoms'
import Arrow from '../../pages/Signup/Arrow'
import { LANGUAGES } from '../../enums'

const CenterTypography = styled(Typography).attrs(() => ({ variant: '12', color: 'secondary' }))`
  text-align: center;
`

const BottomTooltip = styled(Flex)`
  width: ${({ $language }) => ($language ? '290px' : '232px')};
  ${Typography} {
    flex: 84px;
  }
`

const VerifiedIconTooltip = () => {
  const { palette } = useTheme()
  const { i18n } = useTranslation()
  return (
    <>
      <Arrow placement="top" />
      <Flex direction="column" content="center">
        <Flex content="center">
          <Typography variant="body 14 medium" i18n="VerifiedUser">
            Verified user
          </Typography>
          <Spacing size={5} />
          <img src="/img/verified.svg" />
        </Flex>
        <Spacing size={4} />
        <Flex width="232px">
          <CenterTypography i18n="ThisUserHasCompletedAnIdentityVerification">
            This user has completed an identity verification.
          </CenterTypography>
        </Flex>
        <Spacing size={16} />
        <Divider color={palette.accent.grey} />
        <Spacing size={16} />
        <BottomTooltip align="initial" width="auto" $language={i18n.language === LANGUAGES.DE}>
          <SvgMask icon="realLivingPerson" color={palette.accent.success} />
          <Spacing size={8} />
          <Typography variant="body 12 medium" i18n="RegistrationDataIsValid">
            Registration data is valid
          </Typography>
          <Spacing size={16} />
          <SvgMask icon="idCard" color={palette.accent.success} />
          <Spacing size={8} />
          <Typography variant="body 12 medium" i18n="IdDocumentIsValid">
            ID document is valid
          </Typography>
        </BottomTooltip>
      </Flex>
    </>
  )
}
const VerifiedIcon = ({ size, tooltip, ...props }) => {
  return (
    <div {...props}>
      <Tooltip
        enabled
        tooltip={<VerifiedIconTooltip />}
        options={{
          placement: 'top',
          strategy: 'fixed',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            },
          ],
        }}
      >
        <Flex>
          <img src="/img/verified.svg" />
        </Flex>
      </Tooltip>
    </div>
  )
}
export default styled(VerifiedIcon)`
  cursor: pointer;
  ${Elevation} {
    padding: 16px;
    border-radius: 8px;
  }

  [data-popper-placement='top'] {
    ${Arrow} {
      bottom: 0;
      left: calc(50% - 10px);
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
  }
  [data-popper-placement='bottom'] {
    ${Arrow} {
      top: 0;
      left: calc(50% - 10px);
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
  }
`
