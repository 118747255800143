import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import ecies from 'eth-ecies'
import { updateIncluded } from './included'
import { callApi } from '../utils/callApi'
import { updateProofs } from '../services/proofOutdated/updateProofs'
import { randomKey } from '../services/keyCreationService'
import getDocumentSecret from '../services/functions/getDocumentSecret'
import { createNotification } from './notifications'
import i18n from '../i18n'

export {
  default as patchDigitalAsset,
  patchDigitalAssetSync,
} from './digitalAssets/patchDigitalAsset'
export { default as undraftDigitalAsset } from './digitalAssets/undraftDigitalAsset'
export { default as getDigitalAssetsFirstPage } from './digitalAssets/getDigitalAssetsFirstPage'
export { default as reloadDigitalAssets } from './digitalAssets/reloadDigitalAssets'
export { default as createDigitalAsset } from './digitalAssets/createDigitalAsset'
export { default as resetUploadDocumentFlag } from './digitalAssets/resetUploadDocumentFlag'

export const fetchDigitalAssetShareWith = createAsyncThunk(
  'digitalassets/shareWith/fetch',
  async ({ id }, api) => {
    const token = api.extra.getToken()
    const isShareWithExist = api.getState().digitalAssets.shareWith?.id === id

    if (isShareWithExist) {
      return api.getState().digitalAssets.shareWith
    }

    const { naturalPersons } = await callApi({
      endpoint: `digitalassets/${id}/shareWith`,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/json',
    })

    return { id, naturalPersons }
  },
)

export const fetchCompanyMembers = createAsyncThunk(
  'digitalassets/shareWith/fetch',
  async ({ companyId, daId }, api) => {
    const isShareWithExist = api.getState().digitalAssets.shareWith?.id === daId

    if (isShareWithExist) {
      return api.getState().digitalAssets.shareWith
    }

    const userData = []
    const fetchData = async ({ link }) => {
      const { data, included, links } = await callApi({
        endpoint: link,
        accessName: 'Bearer',
      })

      data.forEach((companymembership) => {
        included.forEach((account) => {
          if (companymembership.relationships.grantedToAccount.data.id === account.id) {
            included.forEach((naturalperson) => {
              if (
                account.relationships.ownedBy.data.id === naturalperson.id &&
                naturalperson.attributes.reservation === false
              ) {
                userData.push({
                  publicKey: account.attributes.publicKey,
                  avatarColor: account.attributes.avatarColor,
                  email: naturalperson.attributes.emailAddress,
                  firstName: naturalperson.attributes.firstName,
                  lastName: naturalperson.attributes.lastName,
                  reservation: naturalperson.attributes.reservation,
                  id: naturalperson.id,
                })
              }
            })
          }
        })
      })

      if (links.next) {
        await fetchData({ link: links.next.split('/ui-api/')[1] })
      }
      return userData
    }

    await fetchData({
      link: `companies/${companyId}/members?include=grantedToAccount.ownedBy&sort=grantedToAccount.ownedBy.emailAddress,grantedToAccount.ownedBy.firstName,grantedToAccount.ownedBy.lastName`,
    })

    return { id: daId, naturalPersons: userData }
  },
)

export const fetchDigitalAssetsDetails = createAsyncThunk(
  'digitalassets/fetch',
  async ({ id, callback }, api) => {
    const signal = api.extra.signal.registerAndAbortSignal('getDetails')
    const asset = await callApi({
      endpoint: `digitalassets/${id}?include=linkedWith,documents.activities,documents.signatures,tags,accessGrants.grantedTo.account,customFields.customFieldDefinition,workflows,createdBy,activities,workspaceAccessGrant.grantedTo,folder,accessGrants.tasks`,
      signal,
    })
    const contracts = asset?.data
    if (contracts) {
      api.dispatch(updateIncluded(asset.included || []))
      api.dispatch(updateIncluded([contracts]))
      api.extra.sendCallback(callback, contracts)

      return { asset: contracts, id }
    }
    return {}
  },
)

export const moveToWorkspace = createAsyncThunk(
  'digitalassets/moveToWorkspace',
  async ({ callback, body, wsAgId }, api) => {
    const resp = await callApi({
      endpoint: wsAgId ? `workspaceaccessgrants/${wsAgId}` : 'workspaceaccessgrants',
      method: wsAgId ? 'PATCH' : 'POST',
      applicationJson: true,
      body,
      acceptType: 'application/vnd.api+json',
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    })
    api.extra.sendCallback(callback, resp)

    return {}
  },
)

export const unarchiveContract = createAsyncThunk(
  'digitalassets/unarchive',
  async ({ callback, id }, api) => {
    const response = await callApi({
      endpoint: `digitalassets/${id}/unarchive`,
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    api.extra.sendCallback(callback, response)

    return {}
  },
)

export const patchDigitalAssetList = createAsyncThunk(
  'digitalassetsList/patch',
  async ({ json, id, include, sync = {}, callback }, api) => {
    const userpass = localStorage.getItem('user_secret')
    const address = localStorage.getItem('user_address')
    const state = { ...api.getState() }

    const response = await callApi({
      endpoint: `digitalassets/${id}${include ? `?include=${include}` : ''}`,
      method: 'PATCH',
      applicationJson: true,
      body: json,
    })

    const digitalAssets = [
      ...state.digitalAssets.data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            attributes: { ...response.data.attributes },
          }
        }
        return item
      }),
    ]

    updateProofs({ data: response, address, userpass, callback })
    return { data: digitalAssets, id }
  },
)

export const removeSignAccess = createAsyncThunk(
  'digitalassets/sign/remove',
  async ({ userId, daId, showNotification }, api) => {
    const address = localStorage.getItem('user_address')
    const userpass = localStorage.getItem('user_secret')
    const token = api.extra.getToken()

    const deleteResponse = await callApi({
      endpoint: `digitalassets/${daId}/signaturerequest/naturalpersons/${userId}`,
      method: 'DELETE',
      applicationJson: true,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/json',
    })

    updateProofs({
      data: deleteResponse,
      address,
      userpass,
      callback: async () => {
        const fetchDetailsResult = await api.extra.dispatchAsync(
          api.dispatch,
          fetchDigitalAssetsDetails,
          { id: daId },
        )
        const newState = fetchDetailsResult?.attributes?.state
        if (showNotification && newState) {
          api.dispatch(
            createNotification({
              state: 'success',
              value: i18n.t('documents:contractStatusChanged'),
              description: i18n.t('documents:contractStatusChangedTo', {
                newState: i18n.t(`${newState}`),
              }),
            }),
          )
        }
      },
    })
  },
)

export const createSignAccess = createAsyncThunk(
  'digitalassets/sign/create',
  async ({ userId, daId, deleteRequest, verify, callback }, api) => {
    const address = localStorage.getItem('user_address')
    const userpass = localStorage.getItem('user_secret')
    const token = api.extra.getToken()

    const payload = JSON.stringify({ requiresIdent: !!verify })

    if (deleteRequest) {
      const deleteResponse = await callApi({
        endpoint: `digitalassets/${daId}/signaturerequest/naturalpersons/${userId}`,
        method: 'DELETE',
        applicationJson: true,
        withoutResult: true,
        accessToken: token,
        accessName: 'Bearer',
        acceptType: 'application/json',
      })

      await new Promise((res) => {
        updateProofs({
          data: deleteResponse,
          address,
          userpass,
          callback: res,
        })
      })
    }

    const postResponse = await callApi({
      endpoint: `digitalassets/${daId}/signaturerequest/naturalpersons/${userId}`,
      method: 'POST',
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/json',
      body: payload,
      applicationJson: false,
      headers: { 'Content-Type': 'application/json' },
    })

    updateProofs({
      data: postResponse,
      address,
      userpass,
      callback: callback || (() => api.dispatch(fetchDigitalAssetsDetails({ id: daId }))),
    })
  },
)

export const removeAccessGrant = createAsyncThunk(
  'digitalassets/accessgrant/remove',
  async ({ daId, npId, showNotification, callback }, api) => {
    const token = api.extra.getToken()

    const state = api.getState()
    const digitalAsset = state.digitalAssets.details[daId]?.asset
    const accessGrantsIds = digitalAsset.relationships.accessGrants.data.map((grant) => grant.id)
    const accessGrants = accessGrantsIds.map((id) => state.included.accessgrants[id])
    const accessGrant = accessGrants.find((grant) => grant.relationships.grantedTo.data.id === npId)

    if (!accessGrant) {
      throw new Error(`Access grant (daId=${daId}, npId=${npId}) not found`)
    }

    const resp = await callApi({
      endpoint: `accessgrants/${accessGrant.id}`,
      method: 'DELETE',
      applicationJson: true,
      withoutResult: true,
      accessToken: token,
      accessName: 'Bearer',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    })

    api.extra.sendCallback(callback, resp)
    const fetchDetailsResult = await api.extra.dispatchAsync(
      api.dispatch,
      fetchDigitalAssetsDetails,
      { id: daId },
    )
    const newState = fetchDetailsResult?.attributes?.state
    if (showNotification && newState) {
      api.dispatch(
        createNotification({
          state: 'success',
          value: i18n.t('documents:contractStatusChanged'),
          description: i18n.t('documents:contractStatusChangedTo', {
            newState: i18n.t(`${newState}`),
          }),
        }),
      )
    }
  },
)

export const deleteDigitalAsset = createAsyncThunk(
  'degitalassets/delete',
  async ({ callback, daId }, api) => {
    const token = api.extra.getToken()

    for (let i = 0; i < daId.length; i++) {
      const id = daId[i]

      // eslint-disable-next-line no-await-in-loop
      await callApi({
        endpoint: `digitalassets/${id}`,
        method: 'DELETE',
        withoutResult: true,
        accessToken: token,
        accessName: 'Bearer',
      })
    }

    api.extra.sendCallback(callback)

    return { daId }
  },
)

export const detachDigitalAsset = createAsyncThunk(
  'degitalassets/detach',
  async ({ callback, id }, api) => {
    const token = api.extra.getToken()

    // eslint-disable-next-line no-await-in-loop
    await callApi({
      endpoint: `workspaceaccessgrants/${id}`,
      method: 'DELETE',
      withoutResult: true,
      accessToken: token,
      accessName: 'Bearer',
    })

    // eslint-disable-next-line no-await-in-loop
    await new Promise((r) => {
      setTimeout(r, 3000)
    })

    api.extra.sendCallback(callback)

    return { id }
  },
)

// user signed the contract and it might change its status after that
// check that all contracts in the table fullfill the status filter (if any)
export const filterDigitalAssetsByStatus = createAsyncThunk(
  'digitalassets/filter/by-status',
  (args, api) => {
    const byStatus = (api.getState().digitalAssets.filters?.byStatus || []).map((s) =>
      s.toLowerCase(),
    )

    if (!byStatus || byStatus.length === 0) {
      return { contracts: api.getState().digitalAssets.data }
    }

    const contractsInTable = api
      .getState()
      .digitalAssets.data.filter((da) => byStatus.indexOf(da.attributes.state) !== -1)

    return { contracts: contractsInTable }
  },
)
/* eslint-disable default-param-last */
export const tryLazyLoadDigitalAssetsNextPage = createAsyncThunk(
  'digitalassets/get/next-page',
  async ({ callback, linksNext } = {}, api) => {
    const token = api.extra.getToken()
    const {
      meta: { links },
      lastFilter,
    } = api.getState().digitalAssets
    if (linksNext || links.next) {
      const signal = api.extra.signal.registerAndAbortSignal('contracts-fetch')

      const contractsResponse = await callApi({
        endpoint: linksNext || links.next.split('/ui-api/')[1],
        accessToken: token,
        accessName: 'Bearer',
        signal,
      })

      const contracts = contractsResponse.data

      api.dispatch(updateIncluded(contractsResponse.included))
      api.dispatch(updateIncluded(contracts))

      api.extra.sendCallback(callback, contractsResponse)
      return {
        contracts,
        lastFilter,
        meta: {
          links: contractsResponse.links,
        },
      }
    }

    api.extra.sendCallback(callback, [])

    return { contracts: [], meta: { links } }
  },
)

export const searchDigitalAssets = createAsyncThunk(
  'digitalassets/search',
  async ({ value = '' }, api) => {
    const token = api.extra.getToken()
    const query = [
      `filter=${encodeURIComponent(
        `{"OR": [{"LIKE": {"title": "%${value}%"}}, {"LIKE": {"description": "%${value}%"}}, {"LIKE": {"customFields": {"stringValue":"%${value}%"}}}]}`,
      )}`,
      'sort=-lastModified',
    ].join('&')

    const signal = api.extra.signal.registerAndAbortSignal('search')

    const { data, links, meta } = await callApi({
      endpoint: `digitalassets?${query}`,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/vnd.api+json',
      signal,
    })

    return { data, links, meta }
  },
)

export const searchDigitalAssetsNextPage = createAsyncThunk(
  'digitalassets/search/next-page',
  async ({ link, callback }, api) => {
    const signal = api.extra.signal.registerAndAbortSignal('search')

    const contractsResponse = await callApi({
      endpoint: link.replace('http:', 'https:'),
      acceptType: 'application/vnd.api+json',
      signal,
      clearHost: true,
    })

    const { data, links, meta } = contractsResponse

    api.extra.sendCallback(callback, contractsResponse)

    return { data, links, meta }
  },
)

export const eraseSearchResults = createAsyncThunk('search/results/delete', (skip, api) => {
  // needed the thunk api extra for this call
  api.extra.signal.registerAndAbortSignal('search', false)
  return {}
})

export const resendInvitation = createAsyncThunk(
  'digitalassets/invite/resend',
  async ({ daId, npId, callback }, api) => {
    const token = api.extra.getToken()

    const result = await callApi({
      endpoint: `digitalassets/${daId}/resendinvitation/naturalpersons/${npId}`,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/json',
      method: 'POST',
      body: {},
    })

    api.extra.sendCallback(callback, result)
  },
)

export const digitalassetCopyTo = createAsyncThunk(
  'digitalassets/copyTo',
  async ({ daId, workspaceId, folderId, callback }, api) => {
    try {
      const userpass = localStorage.getItem('user_secret')
      const address = localStorage.getItem('user_address')

      const apiPublicKey = Buffer.from(process.env.REACT_APP_API_PUBLIC_KEY, 'hex')
      const daSecretBuffer = await getDocumentSecret({ daId })
      const sourceDaSecret = daSecretBuffer.toString('utf-8')
      const sourceEncrDS = ecies.encrypt(apiPublicKey, sourceDaSecret).toString('base64')

      const daSecret = randomKey()
      const encrDS = ecies.encrypt(apiPublicKey, daSecret).toString('base64')

      const body = {
        sourceEncrDS,
        encrDS,
        targetLocation: workspaceId
          ? {
              workspaceId,
              folderId,
            }
          : null,
      }

      const result = await callApi({
        endpoint: `digitalassets/${daId}/copyTo`,
        acceptType: 'application/json',
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body,
      })

      await new Promise((r) => {
        updateProofs({
          data: result,
          address,
          userpass,
          callback: () => r(),
        })
      })

      api.extra.sendCallback(callback, result)
    } catch (e) {
      api.extra.sendCallback(callback, e, e)
    }
  },
)

export const getDigitalassetsForLinks = createAsyncThunk(
  'digitalassets/get/forLinks',
  async ({ callback, currentUserCompanyId, currentDaId, value }, api) => {
    const query = [
      `include=workspaceAccessGrant.grantedTo,folder,ownedBy.account`,
      `filter[company.id]=${currentUserCompanyId}`,
      `filter[id][NEQ]=${currentDaId}`,
      value &&
        `filter=${encodeURIComponent(
          `{"OR": [{"LIKE": {"title": "%${value}%"}}, {"LIKE": {"description": "%${value}%"}}, {"LIKE": {"customFields": {"stringValue":"%${value}%"}}}]}`,
        )}`,
    ].join('&')

    const signal = api.extra.signal.registerAndAbortSignal('getDigitalassetsForLinks')

    const resp = await callApi({
      endpoint: `digitalassets?${query}`,
      signal,
    })

    api.dispatch(updateIncluded(resp.included))

    api.extra.sendCallback(callback, { data: resp.data, links: resp.links })
  },
)

export const getDigitalassetsForLinksNext = createAsyncThunk(
  'digitalassets/get/forLinks',
  async ({ callback, nextLink }, api) => {
    const resp = await callApi({
      endpoint: nextLink,
    })

    api.dispatch(updateIncluded(resp.included))

    api.extra.sendCallback(callback, { data: resp.data, links: resp.links })
  },
)

export const addContractLink = createAsyncThunk(
  'digitalassets/ContractLink/add',
  async ({ callback, body }, api) => {
    const resp = await callApi({
      endpoint: 'links',
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      body,
    })

    api.extra.sendCallback(callback, resp)
  },
)

export const getAssigneeForContract = createAsyncThunk(
  'digitalassets/AssigneeForContract/get',
  async ({ callback, searchValue, alreadyInvitedUsers }, api) => {
    const { persons } = await api.extra.dispatchAsync(
      api.dispatch,
      getAssigneePartnersForContract,
      {
        searchValue,
        alreadyInvitedUsers,
      },
    )

    const companyMembers = await api.extra.dispatchAsync(
      api.dispatch,
      getAssigneeCompanyMembersForContract,
      {
        searchValue,
        alreadyInvitedUsers,
        partners: persons,
      },
    )
    api.extra.sendCallback(callback, { persons, ...companyMembers })
  },
)

export const getAssigneePartnersForContract = createAsyncThunk(
  'digitalassets/AssigneePartnersForContract/get',
  async ({ callback, searchValue, alreadyInvitedUsers }, api) => {
    const { account } = api.getState()
    const currentUserNpId = account.currentUser.id
    const signal = api.extra.signal.registerAndAbortSignal('partnerships')
    const encodedSearchValue =
      searchValue?.length > 0 && searchValue.replace(/\\/g, '\\\\').replace(/"/g, '\\"')

    const alreadyInvited = alreadyInvitedUsers.map((item) => {
      const accId = item.relationships.account.data.id
      return { npId: item.id, accId }
    })
    const invitedUsersNpIds =
      alreadyInvited?.length > 0
        ? `&filter[partner.id][NEQ]=${alreadyInvited.map((item) => item.npId).join(',')}`
        : ''
    const searchPartner = `partnerships?include=partner.account&filter[issuer.id]=${currentUserNpId}${invitedUsersNpIds}&filter=${encodeURIComponent(
      `{"OR": [{"LIKE": {"partner": {"emailAddress": "%${encodedSearchValue}%"}}}, {"LIKE": {"partner": {"firstName": "%${encodedSearchValue}%"}}}, {"LIKE": {"partner": {"lastName": "%${encodedSearchValue}%"}}}]}`,
    )}&sort=partner.emailAddress&page[limit]=4`

    const url = `/partnerships?include=partner.account&filter[issuer.id]=${currentUserNpId}${invitedUsersNpIds}&sort=partner.emailAddress&page[limit]=12`
    const res = await callApi({
      endpoint: searchValue ? searchPartner : url,
      signal,
    })

    const persons =
      res?.data?.map((partner) => {
        const npId = partner.relationships.partner.data.id
        const np = res.included.find((i) => i.id === npId)
        const accId = np.relationships.account.data.id
        const account = res.included.find((i) => i.id === accId)
        return { person: np, account }
      }) || []

    if (res?.data?.length > 0) {
      api.dispatch(updateIncluded([...res.data, ...res.included]))
    }

    api.extra.sendCallback(callback, { persons })
  },
)

export const getAssigneeCompanyMembersForContract = createAsyncThunk(
  'digitalassets/AssigneeCompanyMembersForContract/get',
  async ({ callback, searchValue, alreadyInvitedUsers, partners = [] }, api) => {
    const { account } = api.getState()
    const { company } = account
    const signal2 = api.extra.signal.registerAndAbortSignal('companyMembers')
    const encodedSearchValue =
      searchValue?.length > 0 && searchValue.replace(/\\/g, '\\\\').replace(/"/g, '\\"')

    const alreadyInvited = alreadyInvitedUsers.map((item) => {
      const accId = item.relationships.account.data.id
      return { npId: item.id, accId }
    })

    const invitedUsersAccIds =
      alreadyInvited?.length > 0
        ? `&filter[grantedToAccount.id][NEQ]=${alreadyInvited.map((item) => item.accId).join(',')}${
            partners.length > 0 ? `,${partners.map((item) => item.account.id).join(',')}` : ''
          }`
        : ''

    const searchParams = encodedSearchValue
      ? `&filter=${encodeURIComponent(
          `{"OR": [{"LIKE": {"grantedToAccount": {"ownedBy": {"emailAddress": "%${encodedSearchValue}%"}}}}, {"LIKE": {"grantedToAccount": {"ownedBy": {"firstName": "%${encodedSearchValue}%"}}}}, {"LIKE": {"grantedToAccount": {"ownedBy": {"lastName": "%${encodedSearchValue}%"}}}}]}`,
        )}`
      : ''

    const searchCompaniesMembers = `companies/${
      company.id
    }/members?include=grantedToAccount.ownedBy${invitedUsersAccIds}${searchParams}&sort=grantedToAccount.ownedBy.emailAddress&page[limit]=${partners.length === 0 ? '12' : '4'}`

    const companyResponse =
      (searchValue || partners.length === 0) && company.id
        ? await callApi({
            endpoint: searchCompaniesMembers,
            signal: signal2,
          })
        : ''

    const companyMembers =
      companyResponse?.data?.map((partner) => {
        const accId = partner.relationships.grantedToAccount.data.id
        const account = companyResponse.included.find((i) => i.id === accId)
        const npId = account.relationships.ownedBy.data.id
        const np = companyResponse.included.find((i) => i.id === npId)
        return { person: np, account }
      }) || []

    if (companyResponse?.data?.length > 0) {
      api.dispatch(updateIncluded([...companyResponse.data, ...companyResponse.included]))
    }

    api.extra.sendCallback(callback, { companyMembers })
  },
)

export const getAssigneeForWorkspace = createAsyncThunk(
  'digitalassets/AssigneeForWorkspace/get',
  async (
    { callback, searchValue, alreadyInvitedPersons, accountIds, pageLimit, filterInvitedPersons },
    api,
  ) => {
    const { account } = api.getState()
    const { company } = account
    const signal = api.extra.signal.registerAndAbortSignal('companyMembers')
    const alreadyInvited = alreadyInvitedPersons.map((item) => {
      const accId = accountIds ? item : item.relationships.grantedToAccount.data.id
      return { accId }
    })

    const encodedSearchValue =
      searchValue?.length > 0 && searchValue.replace(/\\/g, '\\\\').replace(/"/g, '\\"')

    const filter = filterInvitedPersons
      ? '&filter[grantedToAccount.id]'
      : '&filter[grantedToAccount.id][NEQ]'

    const invitedUsersAccIds =
      alreadyInvited?.length > 0
        ? `${filter}=${alreadyInvited.map((item) => item.accId).join(',')}`
        : ''
    const page = pageLimit || 4

    const searchCompaniesMembers = `companies/${
      company.id
    }/members?include=grantedToAccount.ownedBy&filter[grantedToAccount.ownedBy.reservation]=false${invitedUsersAccIds}&filter=${encodeURIComponent(
      `{"OR": [{"LIKE": {"grantedToAccount": {"ownedBy": {"emailAddress": "%${encodedSearchValue}%"}}}}, {"LIKE": {"grantedToAccount": {"ownedBy": {"firstName": "%${encodedSearchValue}%"}}}}, {"LIKE": {"grantedToAccount": {"ownedBy": {"lastName": "%${encodedSearchValue}%"}}}}]}`,
    )}&sort=grantedToAccount.ownedBy.emailAddress&page[limit]=${page}`

    const getCompaniesMembersUrl = `companies/${company.id}/members?include=grantedToAccount.ownedBy&filter[grantedToAccount.ownedBy.reservation]=false${invitedUsersAccIds}&sort=grantedToAccount.ownedBy.emailAddress&page[limit]=12`

    const companyResponse = await callApi({
      endpoint: searchValue ? searchCompaniesMembers : getCompaniesMembersUrl,
      signal,
      disablePromiseConcatenation: true,
    })

    const companyMembers =
      companyResponse?.data?.map((partner) => {
        const accId = partner.relationships.grantedToAccount.data.id
        const account = companyResponse.included.find((i) => i.id === accId)
        const npId = account.relationships.ownedBy.data.id
        const np = companyResponse.included.find((i) => i.id === npId)
        return { person: np, account, companymemberships: partner, id: npId }
      }) || []

    if (companyResponse.data.length > 0) {
      api.dispatch(updateIncluded([...companyResponse.included, ...companyResponse.data]))
    }

    api.extra.sendCallback(callback, {
      persons: [],
      companyMembers,
      nextPageURL: companyResponse.links?.next,
    })
  },
)

export const getAssigneeForWorkspaceNextPage = createAsyncThunk(
  'digitalassets/AssigneeForWorkspace/get/next-page',
  async ({ nextPageURL, callback }, api) => {
    const signal = api.extra.signal.registerAndAbortSignal('companyMembers')

    const companyResponse = await callApi({
      endpoint: nextPageURL.replace('http:', 'https:'),
      signal,
      clearHost: true,
    })

    const companyMembers =
      companyResponse?.data?.map((partner) => {
        const accId = partner.relationships.grantedToAccount.data.id
        const account = companyResponse.included.find((i) => i.id === accId)
        const npId = account.relationships.ownedBy.data.id
        const np = companyResponse.included.find((i) => i.id === npId)
        return { person: np, account, companymemberships: partner, id: npId }
      }) || []

    if (companyResponse.data.length > 0) {
      api.dispatch(updateIncluded([...companyResponse.included, ...companyResponse.data]))
    }

    api.extra.sendCallback(callback, {
      companyMembers,
      nextPageURL: companyResponse.links?.next,
    })
  },
)

export const updateLabelsCol = createAsyncThunk(
  'digitalassets/updateLablesCol',
  async ({ daId, col, callback }, api) => {
    return { daId, col }
  },
)

export const archiveDocuments = createAsyncThunk(
  'digitalassets/archiveDocuments',
  async ({ daId, body, callback }, api) => {
    const resp = callApi({
      endpoint: `digitalassets/${daId}/documents/archive`,
      method: 'PUT',
      body,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    api.extra.sendCallback(callback, resp)
  },
)

export const digitalAssetInviteLock = createAction('digitalassets/invite/lock')
export const setSelectedDigitalAssets = createAction('digitalassets/selected')
export const setUpdateSelected = createAction('digitalassets/update-selected')
export const removeLoading = createAction('digitalassets/loading-remove')
export const setDocumentsSortBy = createAction('digitalassets/documents/sortBy')
export const setActiveDigitalAsset = createAction('digitalassets/active/set')

// prevent date change for this period to let everything be updated
export const toggleDateChangeGracePeriod = createAction('digitalassets/date/grace-period')

export const storeFulltextSearchIndex = createAction('digitalassets/fulltextsearch/index')
export const setDocumentOpen = createAction('digitalassets/DocumentOpenId')
export const setDocumentUpdate = createAction('digitalassets/DocumentUpdate')
export const setDocumentsDelete = createAction('digitalassets/DocumentDelete')
export const removeDocumentFromDeleteList = createAction(
  'digitalassets/removeDocumentFromDeleteList',
)

export const enableDesktopSidebar = createAction('digitalassets/DesktopSidebarEnable/toggle')

export const mobileSidebarEnabled = createAction('digitalassets/mobileSidebarEnabled/toggle')

export const mobileEnabledSidebar = createAction('digitalassets/mobileEnabledSidebar/toggle')
