import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import getToken from '../../../store/TokenExtra'
import {
  Button,
  Divider,
  DropDown,
  Elevation,
  Flex,
  ModalItem,
  Spacing,
  SvgImage,
  Tooltip,
  Typography,
} from '../../atoms'
import PasswordModal from '../../popups/PasswordModal'
import { callApi } from '../../../utils/callApi'
import SetUpAuthenticationModal from '../../popups/SetUpAuthenticationModal'
import { getMyDevices, removePending2FA } from '../../../actions/account'
import DeleteDeviceModal from '../../popups/DeleteDeviceModal'
import { useDispatchAsync } from '../../../hooks'
import { getCompanySettings, getDevices } from '../../../store/selectors'

const SecurityTab = ({ className }) => {
  const [passwordModal, setPasswordModal] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [authModal, setAuthModal] = useState(false)
  const [activationData, setActivationData] = useState(false)
  const [passwordHash, setPasswordHash] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const devices = useSelector(getDevices)
  const settings = useSelector(getCompanySettings)

  const companySettingsTFA = settings && settings.find((i) => i.type === 'companysettings2fa')
  const activeDevices =
    Array.isArray(devices) && devices?.filter((item) => item.attributes.state === 'active')

  const hideErrors = () => {
    setCodeError(false)
    setPasswordError(false)
  }

  useEffect(() => {
    dispatch(getMyDevices())
  }, [dispatch])

  const addSecondFactors = async (passHash) => {
    hideErrors()
    const token = getToken()
    try {
      const response = await callApi({
        endpoint: `secondfactors`,
        method: 'POST',
        disableNotifications: true,
        accessToken: token,
        accessName: 'Bearer',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'X-INHUBBER-SECRET': passHash,
        },
        body: {
          data: {
            type: 'totps',
            attributes: {
              description: 'secondfactors',
            },
          },
        },
      })
      if (response?.data?.attributes?.secret) {
        setPasswordHash(passHash)
        setPasswordModal(false)
        setActivationData(response.data)
        setAuthModal(true)
        dispatch(getMyDevices())
      }
    } catch {
      setPasswordError(true)
    }
  }

  const patchSecondFactors = async (activationSecret) => {
    hideErrors()
    const token = getToken()
    try {
      const response = await callApi({
        endpoint: `secondfactors/${activationData.id}`,
        method: 'PATCH',
        disable401Redirect: true,
        disableNotifications: true,
        accessToken: token,
        accessName: 'Bearer',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'X-INHUBBER-SECRET': passwordHash,
        },
        body: {
          data: {
            attributes: {
              factor: activationSecret,
            },
          },
        },
      })
      if (response) {
        setAuthModal(false)
        dispatch(getMyDevices())
      }
    } catch {
      setCodeError(true)
    }
  }

  return (
    <div className={className} data-test="settingsModal_security-and-login">
      <Typography variant="16 medium" i18n="tfaTitle">
        Two-Factor Authentication
      </Typography>
      <Spacing size="4" />
      <Typography variant="14" color="secondary" i18n="sidebar:tfaDescription">
        Protect your account from unauthorized access by requring a second authentication method in
        addition to your password.
      </Typography>
      <Spacing size="24" />
      <Flex content="space-between">
        <SvgImage size="32" object icon="authentication-app" />
        <Spacing size="12" />
        <AuthAppContent>
          <Typography variant="14 medium" i18n="sidebar:tfaApp">
            Authentication App
          </Typography>
          <Typography variant="12" color="secondary" i18n="sidebar:tfaAppDescription">
            Use a mobile authentication app to get a verification code to enter every time you log
            in.
          </Typography>
        </AuthAppContent>
        <Spacing size="24" />
        {(!devices ||
          (Array.isArray(devices) &&
            devices?.filter((item) => item.attributes.state === 'active').length === 0)) && (
          <Button
            secondary
            width="fit-content"
            i18n="sidebar:tfaSetup"
            onClick={() => {
              setPasswordModal(true)
              hideErrors()
            }}
          >
            Set up
          </Button>
        )}
        <PasswordModal
          visible={passwordModal}
          onClose={() => setPasswordModal(false)}
          onContinue={(e, passHash) => {
            addSecondFactors(passHash)
          }}
          passwordError={passwordError}
        />
        <SetUpAuthenticationModal
          visible={authModal}
          onClose={() => {
            setAuthModal(false)
            devices.forEach((item) => {
              if (item.attributes.state === 'pending') {
                dispatch(removePending2FA({ id: item.id, passwordHash }))
              }
            })
          }}
          authSecret={activationData?.attributes?.secret}
          onActivate={(activationSecret) => patchSecondFactors(activationSecret)}
          codeError={codeError}
        />
        {Array.isArray(devices) &&
          devices?.filter((item) => item.attributes.state === 'active').length > 0 && (
            <DropDown
              value={t('Manage')}
              placement="bottom-start"
              data-test="dropdown_settingsModal_profile_manage"
            >
              <ModalItem
                value={t('Add a new device')}
                onClick={() => {
                  hideErrors()
                  setPasswordModal(true)
                }}
                data-test="button_settingsModal_profile_addNewDevice"
              />
            </DropDown>
          )}
      </Flex>
      <Spacing size="16" />
      {Array.isArray(devices) &&
        devices?.filter((item) => item.attributes.state === 'active').length > 0 && (
          <DevicesContainer>
            {devices.map((item, counter) => {
              if (item.attributes.state === 'active') {
                return (
                  <DeviceItem
                    key={item.id}
                    item={item}
                    counter={counter}
                    hideDelete={activeDevices.length < 2 && companySettingsTFA}
                  />
                )
              }
              return false
            })}
          </DevicesContainer>
        )}
    </div>
  )
}

const AuthAppContent = styled.div`
  display: grid;
`
const DevicesContainer = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
`
const DeviceContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;

  ${Elevation} {
    padding: 4px;
    min-width: initial;
    max-width: initial;
  }
`
const BaseDeviceItem = ({ item, counter, hideDelete }) => {
  const [passwordModal, setPasswordModal] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const dispatchAsync = useDispatchAsync()
  const { t } = useTranslation()
  const deleteDevice = async (passwordHash) => {
    setPasswordError(false)
    const resultError = await dispatchAsync(removePending2FA, {
      id: item.id,
      passwordHash,
    })
    if (resultError) {
      setPasswordError(true)
    }
  }

  return (
    <>
      {counter > 0 && <Divider />}
      <Spacing size="18" />
      <DeviceContainer
        title={t('Device', { count: counter + 1 })}
        data-test={`item_settingsModal_securityAndLogin_deviceContainer_${counter + 1}`}
      >
        <Typography
          variant="14 medium"
          color="primary"
          i18n="Device"
          i18nProps={{ count: counter + 1 }}
        >
          Device
        </Typography>
        {!hideDelete && (
          <Tooltip
            enabled
            trigger="click"
            hideOnItemClick
            isAnimated
            options={{
              strategy: 'fixed',
            }}
            tooltip={
              <div>
                <ModalItem
                  i18n="Delete"
                  onClick={() => {
                    setPasswordModal(true)
                    setPasswordError(false)
                  }}
                  data-test="button_deviceContainer_dropdown_delete"
                />
              </div>
            }
          >
            <Button
              icon="dots-vertical"
              iconSize="24"
              tertiary
              width="fit-content"
              data-test={`button_securityAndLogin_deviceContainer_more_${counter + 1}`}
            />
          </Tooltip>
        )}
      </DeviceContainer>
      <DeleteDeviceModal
        visible={passwordModal}
        deviceName={t('Device', { count: counter + 1 })}
        onClose={() => setPasswordModal(false)}
        onContinue={(e, passHash) => {
          deleteDevice(passHash)
        }}
        passwordError={passwordError}
      />
      <Spacing size="18" />
    </>
  )
}

const DeviceItem = styled(BaseDeviceItem)``

export default SecurityTab
