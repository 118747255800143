import styled, { keyframes, useTheme } from 'styled-components'

const generateKeyframes = (color) => {
  return keyframes`
  0% { }

  30% {
    background-color: ${color};
  }

  100% { }
`
}

const Circle = styled.div`
  background-color: ${({ color }) => color};
  height: 6px;
  width: 6px;
  animation-name: ${({ animation }) => animation};
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
  border-radius: 100%;
`

const Spinner = ({ animation, grid, global, className }) => {
  const theme = useTheme()

  const animations = {
    default: [generateKeyframes(theme.colors['brand-03']), theme.colors['brand-01']],
    white: [generateKeyframes('white'), 'rgba(211, 213, 216, 0.97)'],
    success: [generateKeyframes('rgba(18, 182, 81, 0.98)'), 'rgba(211, 213, 216, 0.97)'],
    attention: [generateKeyframes(theme.colors['critical-04']), 'rgba(211, 213, 216, 0.97)'],
    critical: [generateKeyframes(theme.colors['critical-04']), theme.colors['critical-01']],
  }
  return (
    <div className={className} data-test="spinner">
      <Circle
        color={
          animation && animations[animation][1] ? animations[animation][1] : animations.default[1]
        }
        animation={
          animation && animations[animation][0] ? animations[animation][0] : animations.default[0]
        }
      />
      <Circle
        color={
          animation && animations[animation][1] ? animations[animation][1] : animations.default[1]
        }
        animation={
          animation && animations[animation][0] ? animations[animation][0] : animations.default[0]
        }
      />
      <Circle
        color={
          animation && animations[animation][1] ? animations[animation][1] : animations.default[1]
        }
        animation={
          animation && animations[animation][0] ? animations[animation][0] : animations.default[0]
        }
      />
    </div>
  )
}

export default styled(Spinner)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
  height: 16px;
  width: 100%;
  padding: 0 ${({ theme }) => theme.semantic.space['space-4x-small']};
  gap: ${({ theme }) => theme.semantic.space['space-4x-small']};
  box-sizing: border-box;

  ${({ grid }) =>
    grid
      ? `
    height: inherit;
    grid-column: 1/-1;
  `
      : ''}

  ${({ global }) =>
    global
      ? `
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  `
      : ''}

  ${Circle}:nth-child(1) {
    animation-delay: 0.6s;
  }

  ${Circle}:nth-child(2) {
    animation-delay: 1.2s;
  }

  ${Circle}:nth-child(3) {
    animation-delay: 1.8s;
  }
`
