export default {
  en: {
    verifyProcess: 'Verification process',
    profileSettings: 'Profile settings',
    companySettings: 'Company settings',
    settings: 'Settings',
    workspaceSettings: 'Workspace settings',
    securityAndLogin: 'Security and login',
    tfaDescription:
      'Protect your account from unauthorized access by requiring a second authentication method in addition to your password.',
    tfaApp: 'Authentication App',
    tfaAppDescription:
      'Use a mobile authentication app to get a verification code to enter every time you log in.',
    tfaSetup: 'Set up',
    archive: 'Archive',
    help: 'Help',
    WhatsNew: 'What’s new?',
    guide: 'User guide ↗',
    WebSite: 'Web site ↗',
    ContactUs: 'Contact us ↗',
  },
  de: {
    verifyProcess: 'Überprüfungsprozess',
    profileSettings: 'Profileinstellungen',
    companySettings: 'Firmeneinstellungen',
    settings: 'Einstellungen',
    workspaceSettings: 'Arbeitsbereichseinstellungen',
    securityAndLogin: 'Sicherheit und Anmeldung',
    tfaDescription:
      'Schütze dein Konto vor unberechtigtem Zugriff, indem es zusätzlich zu deinem Passwort eine zweite Authentifizierungsmethode abgefragt wird.',
    tfaApp: 'Authentifizierungs-App',
    tfaAppDescription:
      'Verwende eine mobile Authentifizierungs-App, um einen Verifizierungscode zu erhalten, der bei jeder Anmeldung benötigt wird.',
    tfaSetup: 'Einrichten',
    archive: 'Archiv',
    help: 'Hilfe',
    WhatsNew: 'Was ist neu?',
    guide: 'Benutzerhandbuch ↗',
    WebSite: 'Webseite ↗',
    ContactUs: 'Kontakt ↗',
  },
  fr: {
    verifyProcess: 'Processus de vérification',
    profileSettings: 'Paramètres du profil',
    companySettings: "Paramètres de l'entreprise",
    settings: 'Paramètres',
    workspaceSettings: "Paramètres de l'espace de travail",
    securityAndLogin: 'Sécurité et connexion',
    tfaDescription:
      "Protégez votre compte contre  d'accès non autorisés en exigeant une deuxième méthode d'authentification en plus de votre mot de passe.",
    tfaApp: "Application d'authentification",
    tfaAppDescription:
      "Utilisez une application d'authentification mobile pour obtenir un code de vérification à saisir à chaque fois que vous vous connectez.",
    tfaSetup: 'Mise en place',
    archive: 'Archives',
    help: 'Aide',
    WhatsNew: 'Quoi de neuf ?',
    guide: "Guide d'utilisation ↗",
    WebSite: 'Site web ↗',
    ContactUs: 'Contactez-nous ↗',
  },
}
