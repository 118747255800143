import { useRef, useMemo, useState, useCallback } from 'react'
import { useWindowSize } from 'react-use'

const useAutoHeight = ({ offset = 0 } = {}) => {
  const { height } = useWindowSize()
  const ref = useRef()
  const [renderState, setRenderState] = useState(0)

  const styles = useMemo(() => {
    if (!ref.current) {
      return { height: 0 }
    }

    const { top } = ref.current.getBoundingClientRect()

    return { height: height - top + offset }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, offset, ref.current, renderState])

  const updateHeight = useCallback(() => setRenderState(Math.random()), [])

  return [styles, ref, updateHeight]
  // you need to place ref on <div> at the top your element
  // and assing styles to element object
}

export default useAutoHeight
