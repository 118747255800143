import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import get from 'lodash/get'
import set from 'lodash/set'
import throttle from 'lodash/throttle'

import rootReducer from '../reducers'
import { saveState } from './persist'
import SignalExtra from './SignalExtra'
import getToken from './TokenExtra'
import sendCallback from './CallbackExtra'
import dispatchThen from './dispatchThen'

export { default as dispatchThen } from './dispatchThen'

const ACTIONS_TO_OMIT_IN_LOG = {
  'table-register': true,
  'application/encryption-data/toggle': true,
}

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error,
  duration: true,
  level: { prevState: false, action: 'log', nextState: 'log', error: 'error' },
  predicate: (getState, action) => {
    if (typeof action === 'function' || process.env.REACT_APP_API_STAGE !== 'local') {
      return false
    }

    return !ACTIONS_TO_OMIT_IN_LOG[action.type]
  },
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: {
          signal: new SignalExtra(),
          getToken,
          sendCallback,
          dispatchAsync: dispatchThen,
        },
      },
    }).concat(logger),
})

// values by these paths are going to be saved to localstorage automaticaly
const persistPaths = [
  'table',
  'account.lang',
  'account.company',
  'account.extending',
  'templates',
  'application.debugger',
  'application.documentView',
  'home.requestsIsExpanded',
  'customFields.data',
  'filters.data',
  'digitalAssets.documentsSortBy',
  'workspaces.companyWorkspace',
  'workspaces.companyWorkspaceEnabled',
  'workspaces.active',
  'workspaces.activeFolder',
  'workspaces.folders',
  'workspaces.allWorkspaces',
  'workspaces.allFolders',
]

// saving selected values to localstorage automaticaly
store.subscribe(
  throttle(() => {
    const state = store.getState()
    const result = persistPaths
      .map((path) => get(state, path))
      .reduce((acc, value, index) => {
        set(acc, persistPaths[index], value)
        return acc
      }, {})

    saveState('inhubber-redux', result)
  }),
  1000,
)

window.reduxStore = store

export default store
