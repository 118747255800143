import proofOutdated from './proofOutdated'
import filterResult from './filter_result'
import BlockChainService from '../blockchainService'
import { sendresult } from './sendresult'

export async function updateProofs({ data, address, userpass, retryCount = 0, callback }) {
  const proof = proofOutdated(data)
  const proofOutdatedResult = filterResult(proof)
  const userAddress = address || localStorage.getItem('user_address')
  const userPassword = userpass || localStorage.getItem('user_secret')
  if (proofOutdatedResult.length) {
    const bc = new BlockChainService({
      address: userAddress,
      password: userPassword,
    })
    const proofOutdatedString = JSON.stringify(proofOutdatedResult)
    return new Promise((resolve) => {
      bc.createTransaction(
        proofOutdatedString,
        (result, _proofOutdated) =>
          sendresult({
            result,
            _proofOutdated,
            retryCount,
            finishFunc: () => (callback ? callback() : resolve()),
          }),
        proofOutdatedResult,
      )
    })
  }
  if (callback) {
    callback()
  }
  return undefined
}
