import React, { useRef, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Typography, Spacing, Badge, Flex } from '../../atoms'
import Segment from './Segment'

const Tab = ({
  counter,
  selected,
  onMount,
  value,
  id,
  textOverflow,
  disabled,
  fitted,
  ...props
}) => {
  const ref = useRef()
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    if (typeof onMount === 'function') {
      onMount(id, ref)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-test={`tab-${id}`} title={t(value)} {...props} ref={ref}>
      <Flex gap={theme.semantic.space['space-4x-small']}>
        <Grid>
          <ValueTypography $textOverflow={textOverflow} i18n={value} variant="body-medium-01">
            {value}
          </ValueTypography>
        </Grid>
        {counter !== undefined && <Badge active={selected} value={counter} />}
      </Flex>
    </div>
  )
}

const Grid = styled.div`
  display: grid;
`

const ValueTypography = styled(Typography)`
  user-select: none;
  transition: color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: ${({ $textOverflow }) => ($textOverflow ? 'hidden' : undefined)};
`

export default styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  flex: ${({ fitted }) => fitted && 'auto'};

  ${ValueTypography} {
    color: ${({ selected, disabled, theme }) => {
      if (selected) {
        return theme.semantic.palette.text.text
      }
      if (disabled) {
        return theme.semantic.palette.text['text-disabled']
      }
      return theme.semantic.palette.text['text-secondary']
    }};
  }

  &:hover {
    ${ValueTypography} {
      transition: color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
      color: ${({ theme }) => theme.semantic.palette.text.text};
    }
    ${Badge} {
      transition: background 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
      background: ${({ theme }) => theme.semantic.palette.surface['surface-brand-active']};
      > ${Typography} {
        color: ${({ theme, active }) => !active && theme.semantic.palette.text['text-inverse']};
      }
    }
  }
`
