import ecies from 'eth-ecies'
import { getPrivateKey } from '../../runtime/encryptionSharingMap'
import { callApi } from '../../utils/callApi'

const MEMOIZE_TTL = 60 * 1000 * 60 * 60 /* hour */
export const getEncrData = async ({ daId }) => {
  const response = await callApi({
    endpoint: `digitalassets/${daId}/encrData`,
    applicationJson: false,
    acceptType: 'application/vnd.api+json',
    accessName: 'Bearer',
  })
  return response
}
const memoize = (memoizedFunction) => {
  let memoizedScope = []

  return async (...args) => {
    const key = JSON.stringify(args[0])
    const timestamp = new Date()
    const memoizedResult = memoizedScope.find(
      (v) => v.key === key && timestamp - v.timestamp < MEMOIZE_TTL,
    )

    if (memoizedResult) {
      memoizedScope = memoizedScope.filter((v) => timestamp - v.timestamp < MEMOIZE_TTL)
      return memoizedResult.result
    }

    const result = await memoizedFunction(...args)
    memoizedScope.push({ key, timestamp, result })
    return result
  }
}

const getDocumentSecret = async ({ daId }) => {
  const response = await getEncrData({ daId })

  const { encrDS, publicKey } = response?.data.attributes || {}
  const privateKey = getPrivateKey(publicKey)

  if (privateKey === undefined) {
    // user tried to access the document earlier than it appear to be available
    // (during the app initialization)
    // we should try once more after some time
    await new Promise((r) => {
      setTimeout(r, 5000)
    })
    const res = await getDocumentSecret({ daId })
    return res
  }
  const privateKeyBuffer = Buffer.from(privateKey, 'hex')
  const encrDSBuffer = Buffer.from(encrDS, 'base64')
  const dsBuffer = ecies.decrypt(privateKeyBuffer, encrDSBuffer)
  return dsBuffer
}

export default getDocumentSecret
