import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { createPortal } from 'react-dom'

import styled from 'styled-components'
import differenceBy from 'lodash/differenceBy'
import { useDispatch } from 'react-redux'
import { useDispatchAsync } from '../../../hooks'
import { Elevation, Flex, Modal, SearchInput, Spacing, Spinner } from '../../atoms'
import Tabs from '../../organisms/Tabs'
import AccessTab from './AccessTab'
import GeneralAccess from './GeneralAccess'
import Tab from '../../organisms/Tabs/Tab'
import getToken from '../../../store/TokenExtra'
import callApiPagination from '../../../utils/callApi/callApiPagination'
import { callApi } from '../../../utils/callApi'
import { FOLDER_ACCESS_PROFILES } from '../../../enums/folderAccessProfiles'
import { getFolderPermissions, setUpdateWorkspaceMembers } from '../../../actions/workspaces'
import { Scroll } from '../../atoms/Modal/Modal'

const ManageAccess = ({ visible, onClose, workspaceId, folderId, className }) => {
  const { t } = useTranslation()
  const dispatchAsync = useDispatchAsync()
  const [filter, setFilter] = useState('')
  const [workspaceMembers = [], setWorkspaceMembers] = useState([])
  const [folderMembers = [], setFolderMembers] = useState([])
  const [accessProfiles = [], setAccessProfiles] = useState([])
  const dispatch = useDispatch()
  const onSearchInputChange = (e) => {
    setFilter(e.target.value)
  }
  const newOnChange = debounce(onSearchInputChange, 500, { trailing: true, leading: false })

  const getMembers = useCallback(() => {
    const asyncGetMembers = async () => {
      const jwt = getToken()
      const response = await callApiPagination({
        endpoint: `workspaces/${workspaceId}/members`,
        accessToken: jwt,
      })
      const workspacePersons = response.data

      setWorkspaceMembers(workspacePersons)
    }
    dispatch(setUpdateWorkspaceMembers({ updateWorkspaceMembers: true }))
    asyncGetMembers()
  }, [dispatch, workspaceId])

  const getFolderMembers = useCallback(() => {
    const asyncGetMembers = async () => {
      const folderPermissions = await dispatchAsync(getFolderPermissions, { folderId })
      setFolderMembers(folderPermissions.data)
    }
    dispatch(setUpdateWorkspaceMembers({ updateWorkspaceMembers: true }))
    asyncGetMembers()
  }, [dispatch, dispatchAsync, folderId])

  const getFolderAccessProfiles = useCallback(() => {
    const asyncGetFolderAccessProfiles = async () => {
      const folderAccessProfiles = await callApi({
        endpoint: `folderaccessprofiles`,
        method: 'GET',
      })
      setAccessProfiles(folderAccessProfiles.data)
    }

    asyncGetFolderAccessProfiles()
  }, [])

  const withoutAccessMembers = useMemo(() => {
    return differenceBy(workspaceMembers, folderMembers, 'relationships.grantedToAccount.data.id')
  }, [folderMembers, workspaceMembers])

  useEffect(() => {
    if (visible) {
      getMembers()
      getFolderAccessProfiles()
      getFolderMembers()
    }
  }, [getFolderAccessProfiles, getFolderMembers, getMembers, visible])

  const maintainers = useMemo(() => {
    const accessProfile = accessProfiles.find(
      (f) => f.attributes?.type === FOLDER_ACCESS_PROFILES.MAINTAINER,
    )
    if (accessProfile) {
      return folderMembers.filter((f) => f.relationships.accessProfile.data.id === accessProfile.id)
    }
    return []
  }, [folderMembers, accessProfiles])

  const parentFolders = useMemo(() => {
    const accessProfile = accessProfiles.find(
      (f) => f.attributes?.type === FOLDER_ACCESS_PROFILES.PARENT_OWNER,
    )
    if (accessProfile) {
      return folderMembers.filter((f) => f.relationships.accessProfile.data.id === accessProfile.id)
    }
    return []
  }, [folderMembers, accessProfiles])

  const withAccessMembers = useMemo(() => {
    const accessProfile = accessProfiles.find(
      (f) => f.attributes?.type === FOLDER_ACCESS_PROFILES.OWNER,
    )
    if (accessProfile) {
      const generalAccess = [...maintainers, ...parentFolders]
      const members = differenceBy(folderMembers, generalAccess, 'id')
      const owner = members.filter(
        (f) => f.relationships?.accessProfile.data.id === accessProfile.id,
      )
      const withoutOwner = members.filter(
        (f) => f.relationships.accessProfile.data.id !== accessProfile.id,
      )
      return [...owner, ...withoutOwner]
    }
    return []
  }, [accessProfiles, folderMembers, maintainers, parentFolders])

  const tabsConfig = useMemo(
    () => [
      {
        value: 'workspace:WithAccess',
        id: 'with-access',
        content: (
          <AccessTab
            folderId={folderId}
            members={withAccessMembers}
            accessProfiles={accessProfiles}
            getFolderMembers={getFolderMembers}
            membersType="withAccess"
          />
        ),
        enabled: true,
      },
      {
        value: 'workspace:WithoutAccess',
        id: 'without-access',
        content: (
          <AccessTab
            folderId={folderId}
            members={withoutAccessMembers}
            accessProfiles={accessProfiles}
            getFolderMembers={getFolderMembers}
            membersType="withoutAccess"
          />
        ),
        enabled: true,
      },
    ],
    [accessProfiles, folderId, getFolderMembers, withAccessMembers, withoutAccessMembers],
  )

  return createPortal(
    <Modal
      type="default"
      visible={visible}
      headerI18n="workspace:ManageAccess"
      onClose={onClose}
      noBottomLine
      className={className}
    >
      <FixingContent>
        <SearchInput
          onChange={newOnChange}
          placeholder={t('workspace:SearchPersons')}
          data-test="input_search_manageAccessFolder_wsMembers"
        />
        <Spacing size="8" />
      </FixingContent>
      <Spacing size="16" />
      {folderMembers.length === 0 ? (
        <>
          <Spacing size="24" />
          <Spinner />
          <Spacing size="24" />
        </>
      ) : (
        <>
          {filter === '' ? (
            <Flex direction="column" width="100%" gap="16px" align="strech">
              <Tabs config={tabsConfig} fitted />
            </Flex>
          ) : (
            <AccessTab
              folderId={folderId}
              filter={filter}
              members={[
                ...withAccessMembers,
                ...withoutAccessMembers,
                ...parentFolders,
                ...maintainers,
              ]}
              accessProfiles={accessProfiles}
              getFolderMembers={getFolderMembers}
              membersType="search"
            />
          )}
          <Spacing size={filter === '' ? 24 : 0} />
          <GeneralAccess
            folderId={folderId}
            visible={filter === ''}
            maintainers={maintainers}
            parentFolders={parentFolders}
            accessProfiles={accessProfiles}
          />
        </>
      )}
    </Modal>,
    document.body,
  )
}

const FixingContent = styled.div`
  position: absolute;
  width: calc(100% - 48px);
  background-color: ${({ theme }) => theme.palette.background.white};
  z-index: 3;
  padding-top: ${({ theme }) => theme.semantic.space['space-medium']};

  + * {
    margin-top: 64px;
  }
`

export default styled(ManageAccess)`
  > ${Elevation} {
    padding-bottom: 0;
    position: relative;

    ${Scroll} > div {
      padding-top: 0;
    }
  }
`
