export default (accountType, redirect) => {
  if (redirect) {
    return redirect
  }

  if (!accountType) {
    return '/my-contracts'
  }

  if (accountType === 'admin') {
    return '/admin'
  }

  return '/home'
}
