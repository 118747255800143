import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import keythereum from 'keythereum'
import Wallet from 'ethereumjs-wallet'
import ecies from 'eth-ecies'

import { createSelector } from 'reselect'
import { useTranslation } from 'react-i18next'
import store from '../../../store'
import { callApi } from '../../../utils/callApi'
import getToken from '../../../store/TokenExtra'
import { keythereumParams } from '../../../services/keyCreationService'
import { getPrivateKey, setEncryptionPair } from '../../../runtime/encryptionSharingMap'
import {
  getCompanyWorkspaceFullfiled,
  getWorkspaces,
  inviteUserToWorkspace,
} from '../../../actions/workspaces'
import { getMyDevices, setCompanyMembership } from '../../../actions/account'
import { setCompanyAdmins, setCompanySettings } from '../../../actions/company'
import getCustomFieldsDefinitions from './getCustomFieldDefinitions'
import {
  setLoggedIn,
  setProduktlyInit,
  toggleLoadingEncryption,
} from '../../../actions/application'

import migrateToCompanyWorkspace from './migrateToCompanyWorkspace'
import { useDispatchAsync } from '../../../hooks'
import useAccountType from '../../../hooks/useAccountType'
import { WORKSPACE_ROLES } from '../../../enums'
import { printf } from '../../../hooks/printf'
import {
  selectAccount,
  selectApplication,
  selectDigitalAssets,
  selectIncluded,
  selectWorkspaces,
} from '../../../store/selectors'
import { getNavigate } from '../../../history'
import { removeDocument } from '../../../actions/documents'
import { removeDocumentFromDeleteList } from '../../../actions/digitalAssets'

const tryToAuthorize = ({ dispatch }) => {
  const authorized = localStorage.getItem('token')
  if (authorized) {
    const userInfo = {
      jwt: localStorage.getItem('token'),
      id: localStorage.getItem('user_id'),
      firstName: localStorage.getItem('user_firstName'),
      lastName: localStorage.getItem('user_lastName'),
      emailAddress: localStorage.getItem('user_emailAddress'),
      userType: localStorage.getItem('user_type'),
      userActivated: localStorage.getItem('user_activated'),
      publicKey: localStorage.getItem('user_publicKey'),
      secret: localStorage.getItem('user_secret'),
      privateKey: localStorage.getItem('user_privateKey'),
      address: localStorage.getItem('user_address'),
      features: localStorage.getItem('features'),
      loaded: true,
      avatarColor: localStorage.getItem('avatarColor'),
    }
    dispatch({
      type: 'account/login/fulfilled',
      payload: {
        jwt: authorized,
        currentUser: userInfo,
      },
    })
  } else {
    dispatch({
      type: 'account/login/fulfilled',
      payload: {
        jwt: null,
        currentUser: null,
      },
    })
  }
}

const { dispatch } = store

const genericActions = [() => tryToAuthorize({ dispatch })]

const Startup = () => {
  const [[userCompany, userAccount], setUserData] = useState([])
  const dispatchAsync = useDispatchAsync()

  const {
    i18n: { language },
  } = useTranslation()

  useEffect(() => {
    genericActions.forEach((executeAction) => executeAction())
  }, [])

  const selectUserData = createSelector(
    [selectAccount, selectWorkspaces, selectIncluded, selectApplication, selectDigitalAssets],
    (account, workspaces, included, application, digitalAssets) => ({
      ...account,
      ...workspaces,
      workspacememberships: included.workspacememberships,
      accounts: included.accounts,
      loggedIn: application.loggedIn,
      produktlyInit: application.produktlyInit,
      documentsDelete: digitalAssets.documentsDelete,
    }),
  )

  const {
    currentUser,
    companyWorkspace: loadedCompanyWorkspace,
    allWorkspaces,
    workspacememberships,
    accounts,
    companyMembership,
    loggedIn,
    documentsDelete,
    produktlyInit,
  } = useSelector(selectUserData)

  const [isInhubberAdmin] = useAccountType('admin')

  const removeDocuments = useCallback(
    ({ item }) => {
      const removeDocumentsSequentially = async () => {
        await item.uploadedFiles.reduce(async (previousPromise, file) => {
          await previousPromise
          const resp = await dispatchAsync(removeDocument, {
            digitalAssetId: item.daId,
            documentId: file.id,
          })
          return resp
        }, Promise.resolve())
      }
      removeDocumentsSequentially()
      dispatch(removeDocumentFromDeleteList({ taskId: item.taskId }))
    },
    [dispatchAsync],
  )

  // Delete not sended documents from Tasks
  useEffect(() => {
    if (documentsDelete?.length > 0) {
      const item = documentsDelete[0]
      removeDocuments({ item })
    }
  }, [documentsDelete, removeDocuments])

  // Produktly
  const initiateProduktly = useCallback(
    ({ reTry = 0 }) => {
      if (currentUser?.id && userAccount?.id && (userCompany?.id || reTry > 10)) {
        window.Produktly.identifyUser(currentUser.id, {
          ...(userCompany?.id ? { companyId: userCompany?.id } : {}),
          accountType: userAccount.attributes.type,
          accountState: userAccount.attributes.state,
          ...(userCompany?.id ? { companyRole: companyMembership?.attributes.role } : {}),
          registeredOn: userAccount.attributes.created,
          language,
        })
        window.Produktly.configure({
          token: process.env.REACT_APP_API_PRODUKTLY_TOKEN,
          redirect: (url) => {
            const currentLink = new URL(url)
            const link = currentLink.pathname + currentLink.search
            const navigate = getNavigate()
            navigate(link)
          },
        })
        dispatch(setProduktlyInit(true))
        dispatch(setLoggedIn(false))
      } else {
        setTimeout(() => initiateProduktly({ reTry: reTry + 1 }), 1000)
      }
    },
    [
      companyMembership?.attributes?.role,
      currentUser?.id,
      language,
      userAccount?.attributes.created,
      userAccount?.attributes.state,
      userAccount?.attributes.type,
      userAccount?.id,
      userCompany?.id,
    ],
  )

  useEffect(() => {
    if (!loggedIn && !produktlyInit) {
      dispatch(setLoggedIn(true))
    }
  }, [loggedIn, produktlyInit])

  useEffect(() => {
    if (loggedIn) {
      initiateProduktly({})
    }
  }, [initiateProduktly, loggedIn])

  // Company Workspace Setup
  useEffect(() => {
    if (!currentUser?.id) {
      return
    }

    const companyWorkspaceSetup = async () => {
      const jwt = getToken()
      const npResponse = await callApi({
        endpoint: `naturalpersons/${currentUser.id}?include=account,company`,
        accessToken: jwt,
      })

      const account = npResponse?.included.find((i) => i.type === 'accounts')
      const company = npResponse?.included.find((i) => i.type === 'companies')

      if (account?.attributes.type === 'basic' || !company) {
        // skip the workspace setup for basic accounts and users without company (ronins)
        dispatch(toggleLoadingEncryption(false)) // remove loading spinner from private routes since no workspace encryption is needed there
        setUserData([null, account])
        return
      }

      const companyMembers = await callApi({
        endpoint: `companies/${company.id}/members?filter[grantedToAccount.id]=${account.id}`,
      })

      const myCompanyMembership = companyMembers?.data.find(
        (ca) => ca.relationships.grantedToAccount.data.id === account.id,
      )
      localStorage.setItem('accountCompanyRole', myCompanyMembership?.attributes?.role)
      localStorage.setItem('companyId', company.id)
      if (myCompanyMembership) {
        dispatch(setCompanyMembership(myCompanyMembership))
      }

      if (company) {
        await dispatchAsync(getMyDevices, {})
        const companySettings = await callApi({
          endpoint: `companies/${company.id}/settings`,
          accessToken: jwt,
        })

        dispatch(setCompanySettings({ data: companySettings.data }))
      }

      const companyAdmins = await callApi({
        endpoint: `companies/${company.id}/members?include=grantedToAccount.ownedBy&filter[role]=admin`,
        accessToken: jwt,
      })

      dispatch(
        setCompanyAdmins({
          data: companyAdmins.data.map((a) => {
            const account = companyAdmins.included.find(
              (e) => e.id === a.relationships.grantedToAccount.data.id,
            )
            const naturalPerson = companyAdmins.included.find(
              (e) => e.id === account.relationships.ownedBy.data.id,
            )

            return {
              memberId: a.id,
              membership: a,
              accountId: a.relationships.grantedToAccount.data.id,
              account,
              naturalPersonId: account.relationships.ownedBy.data.id,
              naturalPerson,
            }
          }),
        }),
      )

      if (companyMembers.data.length === 0 || companyMembers.data[0].attributes.role !== 'admin') {
        // current user is not the company admin
        setUserData([company, account])
        return
      }

      let companyWorkspace
      try {
        companyWorkspace = await callApi({
          endpoint: `companies/${company.id}/companyWorkspace`,
          disableNotifications: true,
        })
      } catch (e) {
        // workspace is missing => company admin should create it
        const userPublicKey = Buffer.from(
          JSON.parse(localStorage.getItem('user_publicKey')).data,
          'hex',
        )
        const dk = keythereum.create(keythereumParams)
        const wallet = Wallet.fromPrivateKey(dk.privateKey)
        const publicKeyString = wallet.getPublicKeyString().replace('0x', '')
        const privateKey = wallet.getPrivateKey()
        const encrPrivateKey = ecies.encrypt(userPublicKey, privateKey).toString('hex')
        companyWorkspace = await callApi({
          method: 'POST',
          endpoint: `companies/${company.id}/companyWorkspace`,
          body: {
            data: {
              type: 'workspaces',
              attributes: {
                encrData: {
                  publicKey: publicKeyString,
                  encrWsPrivateKey: encrPrivateKey,
                },
              },
              relationships: {
                company: {
                  data: { type: 'companies', id: company.id },
                },
              },
            },
          },
        })
      }

      const workspaceMembers = await callApi({
        endpoint: `workspaces/${companyWorkspace.data.id}/members?include=grantedToAccount`,
        accessToken: jwt,
      })
      const currentUserWorkspaceMembership = workspaceMembers.data.find(
        (m) => m.relationships.grantedToAccount.data.id === account.id,
      )
      const workspaceMemberIds = workspaceMembers.data.map(
        (m) => m.relationships.grantedToAccount.data.id,
      )

      if (!currentUserWorkspaceMembership) {
        // looks like this is new company admin without workspace; skip
        setUserData([company, account])
        return
      }

      const { encrWsPrivateKey } = currentUserWorkspaceMembership.attributes
      const userPrivateKey = Buffer.from(
        JSON.parse(localStorage.getItem('user_privateKey')).data,
        'hex',
      )
      const wsPrivateKey = ecies.decrypt(userPrivateKey, Buffer.from(encrWsPrivateKey, 'hex'))

      const adminsWithoutWorkspace = companyAdmins.data.filter(
        (m) =>
          m.attributes.role === 'admin' &&
          workspaceMemberIds.indexOf(m.relationships.grantedToAccount.data.id) === -1,
      )

      for (let i = 0; i < adminsWithoutWorkspace.length; i++) {
        const adminWithoutWorkspace = adminsWithoutWorkspace[i]
        const adminAccount = companyAdmins.included.find(
          (inc) => inc.id === adminWithoutWorkspace.relationships.grantedToAccount.data.id,
        )

        if (!adminAccount.attributes.publicKey) {
          // admin account wasn't created yet
          // eslint-disable-next-line no-continue
          continue
        }

        const encrPrivateKey = ecies
          .encrypt(Buffer.from(adminAccount.attributes.publicKey, 'hex'), wsPrivateKey)
          .toString('hex')

        // eslint-disable-next-line no-await-in-loop
        await dispatchAsync(inviteUserToWorkspace, {
          workspaceId: companyWorkspace.data.id,
          role: WORKSPACE_ROLES.ADMIN,
          encrPrivateKey,
          accountId: adminAccount.id,
          jwt,
        })
      }

      setUserData([company, account])
    }

    companyWorkspaceSetup()
  }, [currentUser?.id, dispatchAsync])

  // workspace and private keys preparation
  useEffect(() => {
    if (!userAccount?.id || !userCompany?.id) {
      return
    }

    const prepareWorkspaces = async () => {
      const jwt = getToken()

      try {
        // get all custom workspaces
        dispatch(getWorkspaces())

        const workspace = await callApi({
          endpoint: `companies/${userCompany.id}/companyWorkspace?include=members`,
          accessToken: jwt,
          disableNotifications: true,
        })
        const { publicKey: wsPublicKey } = workspace.data.attributes

        if (!workspace.included) {
          // workspace exists but is hidden from current user
          dispatch(
            getCompanyWorkspaceFullfiled({
              companyWorkspace: workspace.data,
              companyWorkspaceEnabled: false,
              admin: false,
            }),
          )
          return
        }
        const currentMember = workspace.included.find(
          (i) => i.relationships.grantedToAccount.data.id === userAccount.id,
        )
        const isCurrentUserAnAdmin = !!workspace.included?.find(
          (i) =>
            i.attributes.role !== 'viewer' &&
            i.relationships.grantedToAccount.data.id === userAccount.id,
        )

        dispatch(
          getCompanyWorkspaceFullfiled({
            companyWorkspace: workspace.data,
            companyWorkspaceEnabled: !!currentMember,
            admin: isCurrentUserAnAdmin,
          }),
        )

        if (!currentMember) {
          throw new Error('Something wrong between user and workspace relationship')
        }

        const userPrivateKey = Buffer.from(
          JSON.parse(localStorage.getItem('user_privateKey')).data,
          'hex',
        )
        const { encrWsPrivateKey } = currentMember.attributes
        const wsPrivateKey = ecies.decrypt(userPrivateKey, Buffer.from(encrWsPrivateKey, 'hex'))

        setEncryptionPair(wsPublicKey, wsPrivateKey)
      } catch (e) {
        setTimeout(() => setUserData([userCompany, userAccount]), 250)
        dispatch(
          getCompanyWorkspaceFullfiled({
            companyWorkspace: null,
            companyWorkspaceEnabled: false,
          }),
        )
      }
    }

    prepareWorkspaces()
  }, [userAccount, userCompany])

  useEffect(() => {
    if (!userAccount?.id || !userCompany?.id || isInhubberAdmin) {
      return
    }

    const prepareAllWorkspaces = () => {
      Object.keys(allWorkspaces).forEach((key) => {
        const workspace = allWorkspaces[key]
        if (workspace) {
          const { publicKey: wsPublicKey } = workspace?.attributes || {}
          const privateKey = getPrivateKey(wsPublicKey)
          if (!privateKey) {
            const userPrivateKeyString = localStorage.getItem('user_privateKey')
            if (!userPrivateKeyString) {
              // eslint-disable-next-line no-console
              console.warn("Wasn't able to extract workspace keys; investigate me")
              return
            }
            const userPrivateKey = Buffer.from(JSON.parse(userPrivateKeyString).data, 'hex')
            workspace?.relationships?.members?.data.forEach((item) => {
              if (
                workspacememberships[item.id]?.relationships?.grantedToAccount?.data?.id ===
                userAccount.id
              ) {
                const { encrWsPrivateKey } = workspacememberships[item.id].attributes
                const wsPrivateKey = ecies.decrypt(
                  userPrivateKey,
                  Buffer.from(encrWsPrivateKey, 'hex'),
                )
                setEncryptionPair(wsPublicKey, wsPrivateKey)
              }
            })
          }
        }
      })
      dispatch(toggleLoadingEncryption(false)) // remove loading spinner from private routes
    }
    prepareAllWorkspaces()
  }, [allWorkspaces, userAccount, userCompany, isInhubberAdmin, workspacememberships, accounts])

  useEffect(() => {
    if (!currentUser?.id) {
      return
    }

    setTimeout(() => {
      try {
        // we need to wait some time for newly created user to sign up everything
        const userPrivateKey = Buffer.from(
          JSON.parse(localStorage.getItem('user_privateKey')).data,
          'hex',
        )
        const userPublicKey = Buffer.from(
          JSON.parse(localStorage.getItem('user_publicKey')).data,
          'hex',
        )
        setEncryptionPair(userPublicKey, userPrivateKey)
      } catch (e) {
        printf(() => {
          // eslint-disable-next-line no-console
          console.log(
            'Issue with setting private encryption pair. Ignore, if you seeing this after logout.',
          )
        })
      }
    }, 5000)
  }, [currentUser?.id])

  useEffect(() => {
    if (!loadedCompanyWorkspace) {
      return
    }
    migrateToCompanyWorkspace({ companyWorkspace: loadedCompanyWorkspace })
  }, [loadedCompanyWorkspace])

  useEffect(() => {
    if (userCompany?.id && currentUser?.id && !isInhubberAdmin) {
      getCustomFieldsDefinitions(userCompany.id)
    }
  }, [isInhubberAdmin, userCompany?.id, currentUser?.id])
}

export default Startup
