import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import forEach from 'lodash/forEach'

import PlainEn from './plain_en-us'
import PlainDe from './plain_de-de'
import PlainFR from './plain_fr-fr'
import MainEn from './en-us'
import MainDe from './de-de'
import MainFr from './fr-fr'

import Templates from './templates'
import Workspace from './workspace'
import Recovery from './recovery'
import Home from './home'
import Sidebar from './sidebar'
import Company from './company'
import Archive from './archive'
import Files from './files'
import Activity from './activity'
import Import from './import'
import Plans from './plans'
import Delete from './delete'
import Smart from './smartImport'
import { LANGUAGES } from '../enums'
import Signature from './Signature'
import Tasks from './tasks'
import CustomFields from './customfield'
import Events from './events'
import Signup from './signup'
import Documents from './documents'
import Participants from './participants'
import Contracts from './contracts'
import Attributes from './attributes'
import Filters from './filters'

const constructResources = (lang, dictionaries) => {
  const result = {}
  forEach(dictionaries, (r, index) => {
    result[index.toLowerCase()] = r[lang]
  })
  return result
}

const dictionaries = {
  Templates,
  Workspace,
  Recovery,
  Home,
  Sidebar,
  Company,
  Archive,
  Files,
  Activity,
  Import,
  Plans,
  Delete,
  Smart,
  Signature,
  Tasks,
  CustomFields,
  Events,
  Signup,
  Documents,
  Participants,
  Contracts,
  Attributes,
  Filters,
}

export const resources = {
  en: {
    translation: {
      ...PlainEn,
      ...MainEn,
    },
    ...constructResources(LANGUAGES.EN, dictionaries),
  },
  de: {
    translation: {
      ...PlainDe,
      ...MainDe,
    },
    ...constructResources(LANGUAGES.DE, dictionaries),
  },
  fr: {
    translation: {
      ...PlainFR,
      ...MainFr,
    },
    ...constructResources(LANGUAGES.FR, dictionaries),
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: LANGUAGES.EN,
    fallbackLng: LANGUAGES.EN,
    keySeparator: false,
    interpolation: { escapeValue: false, skipOnVariables: false },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  })

export default i18n
