class SignalExtra {
  constructor() {
    this.container = {}
  }

  registerAndAbortSignal(token, createNewSignal = true) {
    if (this.container[token]) {
      this.container[token].abort()
    }

    if (createNewSignal) {
      this.container[token] = new AbortController()
      return this.container[token].signal
    }

    return null
  }
}

export default SignalExtra
