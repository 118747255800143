function filterResult(Outdated) {
  const parseresult = []

  // eslint-disable-next-line no-restricted-syntax
  for (const key in Outdated) {
    // eslint-disable-next-line no-prototype-builtins
    if (Outdated.hasOwnProperty(key)) {
      if (typeof Outdated[key] === 'object' && Outdated[key] !== null) {
        // Outdated[key].push(Outdated[key - 1]);
        // Outdated[key].push(Outdated[key - 2]);
        const temp = {}
        if (Outdated[key].hash && Outdated[key].id && Outdated[key].type) {
          temp.hash = Outdated[key].hash
          temp.type = Outdated[key].type
          temp.id = Outdated[key].id
        } else {
          let n = 1
          temp.hash = Outdated[key].hash
          const checkN = () => {
            if (typeof Outdated[key - n] === 'object' || Array.isArray(Outdated[key - n])) {
              n++
              checkN()
            } else {
              temp.type = Outdated[key - n]
              temp.id = Outdated[key - n - 1]
            }
          }
          checkN()
        }
        parseresult.push(temp)
      }
    }
  }

  const result = []
  parseresult.reverse()
  parseresult.map((e) => {
    if (result.length === 0) {
      result.push(e)
    }
    let duplicate = false
    result.map((r) => {
      if (e.id === r.id) {
        duplicate = true
      }
      return undefined
    })
    if (!duplicate) {
      result.push(e)
    }
    return undefined
  })
  // console.error(result);
  return result
}

export default filterResult
