import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { selectAccount } from '../store/selectors'

const useDigitalAssetCreatedByCompany = (id) => {
  const selectAssetDetails = (state) => state.digitalAssets.details[id]
  const selectDigitalAsset = (state) => {
    const { digitalAssets } = state
    return id ? digitalAssets.data.find((i) => i.id === id) : {}
  }

  const selectCompanyInfo = createSelector(
    [selectAccount, selectAssetDetails, selectDigitalAsset],
    (account, assetDetails, digitalAsset) => ({
      assetDetails,
      digitalAsset,
      ...account,
    }),
  )

  const { company, digitalAsset, assetDetails } = useSelector(selectCompanyInfo)
  const digitalAssetCompanyId =
    assetDetails?.asset?.relationships?.company?.data?.id ||
    digitalAsset?.relationships?.company?.data?.id ||
    ''
  return digitalAssetCompanyId === company?.id
}

export default useDigitalAssetCreatedByCompany
