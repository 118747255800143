/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

const useConditionalInterval = (
  handler,
  condition,
  interval = 8,
  maxIntervalLength = 500,
  deps = [],
) => {
  useEffect(() => {
    if (condition) {
      const intervalSet = Date.now()
      let intrvl = setInterval(() => {
        if (Date.now() - intervalSet > maxIntervalLength) {
          clearInterval(intrvl)
          intrvl = null
        }
        handler()
      }, interval)
      return () => {
        if (intrvl) {
          clearInterval(intrvl)
        }
      }
    }

    return () => {}
  }, deps)
}

export default useConditionalInterval
