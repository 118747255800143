export default {
  Log_Out: 'Abmelden',
  Login: 'Anmelden',
  contracts: 'Verträge',
  th_created_on: 'Erstellt am',
  th_owner: 'Inhaber',
  th_workspace: 'Arbeitsbereich',
  active: 'Aktiv',
  pending: 'Offen',
  pendingLoading: 'Ausstehend…',
  documents: 'Dokumente',
  cancel: 'Abbrechen',
  Create: 'Erstellen',
  Convert: 'Konvertieren',
  Title: 'Titel',
  view: 'Lesen',
  remove: 'Entfernen',
  Remove: 'Entfernen',
  back: 'Zurück',
  Description: 'Beschreibung',
  Save: 'Speichern',
  Dates: 'Ereignisse',
  Close: 'Schließen',
  Status: 'Status',
  sign: 'Signieren',
  size: 'Größe:',
  Detail: 'Details',
  Invite: 'Einladen',
  LogIn: 'Anmeldung',
  Password: 'Passwort',
  SignUp: 'Registrierung',
  accepted: 'angenommen',
  AccountType: 'Kontotyp:',
  PublicKey: 'Öffentlicher Schlüssel:',
  BlockchainAddress: 'Blockchain-Adresse:',
  issuer: 'Ersteller',
  basic: 'Einfach',
  TandC: 'ASB (Allgemeine Servicebedingungen)',
  testingTermsPolicies: 'INHUBBER Bedingungen und Richtlinien',
  value: 'Wert',
  Labels: 'Labels',
  CreateLabels: 'Labels erstellen',
  TagAlreadyCreated: 'Label existiert bereits',
  SignContract: 'Vertrag signieren',
  AddCustomDate: 'Benutzerdefiniertes Ereignis hinzufügen',
  AddDate: 'Hinzufügen',
  Add: 'Hinzufügen',
  Reminders: 'Erinnerungen',
  AddReminder: 'Erinnerung hinzufügen',
  RemindMe: 'Erinnern',
  before: 'vorher',
  after: 'später',
  Reminder: 'Erinnerung',
  docAnalysis: 'Analyse',
  Analyse: 'Analysieren',
  NoResults: 'Keine Ergebnisse',
  PasswordsDontMatch: 'Passwörter stimmen nicht überein',
  declined: 'Abgelehnt',
  signed: 'Signiert',
  expired: 'Abgelaufen',
  archived: 'Archiviert',
  start_date: 'Vertragsbeginn',
  expiry_date: 'Vertragsende',
  expiryDateError: 'Vertragsende muss nach Vertragsbeginn sein',
  startDateError: 'Vertragsbeginn muss vor Vertragsende liegen',
  LogInINHUBBER: 'Bei INHUBBER anmelden',
  LoadSecurityKey: 'Sicherheitsschlüssel laden',
  NextGenerationContract: 'Vertragsmanagement der nächsten Generation',
  ContractsWorkForYou: 'Lassen Sie Ihre Verträge für Sie arbeiten ',
  AlreadyHaveAccount: 'Bereits registriert?',
  SignUpAccount: 'Registrieren Sie sich',
  SecurityKey: 'Sicherheitsschlüssel',
  RecoveryPhrase: 'Wiederherstellungs-Phrase ',
  name: 'Name',
  closeAnalysis: 'Analyseergebnisse schließen',
  someFields: 'Einige der Felder (Datum, Beschreibung) sind nicht gesetzt',
  OrganizeContracts: 'Organisieren Sie Ihre Verträge',
  InviteAll: 'Alle einladen',
  Invited: 'Eingeladen',
  fileAccepted: 'Sicherheitsschlüssel akzeptiert',
  Profile: 'Profil',
  SortUp: 'Aufsteigend',
  SortDown: 'Absteigend',
  CancelSort: 'Sortierung aufheben',
  startDate: 'Vertragsbeginn',
  ContractsDropFilesHere: 'Dateien hier ablegen',
  DropUploadFiles: 'Dateien zum Hochladen ablegen',
  AddLabels: 'Labels hinzufügen',
  ShareContract: 'Vertrag teilen',
  InvitePeopleToContract: 'Personen zum Vertrag einladen',
  InviteByEmail: 'Einladen per E-Mail',
  AddLabelsToContract: 'Labels dem Vertrag hinzufügen',
  HaveNoLabels: 'Sie haben noch keine Labels',
  Delete: 'Löschen',
  ClearAll: 'Alle löschen',
  CreateLabelsToSort: 'Labels zum Sortieren von Verträgen erstellen',
  YourContractWill: 'Ihr Vertrag wird durchgehend verschlüsselt und sicher gespeichert',
  draft: 'Entwurf',
  RequestSigningContract: 'Anfrage den Vertrag zu unterzeichnen',
  CanViewContract: 'Leserechte am Vertrag',
  ExceededLimit: 'Überschrittenes Limit: 50 Dateien oder 50 MB',
  FileDuplicate: 'Duplikat der Datei - diese Datei ist bereits hochgeladen',
  InvalidEmail: 'Ungültige E-Mail-Adresse',
  BeforeCreatingContract: 'Bevor Sie einen Vertrag erstellen, geben Sie ihm einen Titel',
  ContractDraftAccessible: 'Der Vertragsentwurf ist nur dem Inhaber zugänglich',
  DeleteDocument: 'Dokument löschen?',
  CreateEvent: 'Termin erstellen',
  Events: 'Termine',
  ForSecurityReasons:
    'Aus Sicherheitsgründen werden sämtliche ungespeicherte Daten nach Einsicht der Analyseergebnisse gelöscht.',
  Activity: 'Vorgang',
  YouNaveNoEvents: 'Sie haben noch keine Termine',
  ReminderDescription: 'Beschreibung',
  AddToEvents: 'Zu den Terminen hinzufügen',
  SelectAll: 'Alle auswählen',
  UnselectAll: 'Auswahl aufheben',
  UserAlreadyInvited: 'Dieser Benutzer ist bereits eingeladen',
  Company: 'Unternehmen',
  AreYouSureDeleteDocument:
    'Bist du dir sicher? Alle Signaturanfragen werden gelöscht. Eingeladene Personen werden weiterhin Zugriff zum Vertrag haben.',
  AddReminderToEvent: 'Eine Erinnerung zu dem Termin hinzufügen',
  ViewResults: 'Ergebnisse anzeigen',
  LastModifiedOn: 'Letzte Änderung am',
  CreateNewEvent: 'Neuen Termin erstellen',
  AddEvent: 'Termin hinzufügen',
  AnalysisResults: 'Analyseergebnisse',
  SelectAnalyzedDates: 'Ausgewählte Analyseregebnisse den Terminen und Erinnerungen hinzufügen',
  EditEvent: 'Termin bearbeiten',
  Event: 'Termin',
  DeleteEvent: 'Termin löschen',
  email: 'E-Mail-Adresse',
  ContractSigning: 'Vertragsunterzeichnung',
  SignatureStatus: 'Signaturstatus',
  TandC_title: 'Allgemeine Servicebedingungen (ASB) der key2contract GmbH',
  Language: 'Sprache',
  Download: 'Herunterladen',
  PreviewsForThisFiletype: 'Vorschau für diesen Dateityp ist nicht verfügbar',
  FindContractsBy: 'Text eingeben zur Suche nach Verträgen über Vertragstitel oder Beschreibung',
  results: 'Ergebnisse',
  Uploaded: 'Hochgeladen',
  openContractsWithLabel: 'Verträge mit diesem Label öffnen',
  ExportCSV: '{{count}} Verträge in CSV exportieren',
  ExportCSVSmall: 'CSV exportieren',
  PreparingCSV: 'CSV zum Herunterladen vorbereiten…',
  StartExport: 'Export starten',
  CookiePolicy: 'Cookie-Richtlinie',
  PrivacyPolicy: 'Datenschutzerklärung',
  Search: 'Suche',
  members: 'Mitglieder',
  Members: 'Mitglieder',
  General: 'Allgemein',
  BackToWorkspace: 'Zurück zum Arbeitsbereich',
  Recipients: 'Empfänger',
  AddDocument: 'Dokument hinzufügen',
  invitesYou: '{{name}} lädt Sie ein, den Vertrag zu signieren',
  invitesYouKYC:
    '{{name}} hat dich eingeladen, diesen Vertrag mit einer verifizierten Signatur zu unterzeichnen',
  VerifiedSignature: 'Verifizierte Signatur',
  ThisSignatureRequires: 'Diese Signatur erfordert eine Legitimationsprüfung vor dem Signieren.',
  CloseDocAnalysis: 'Möchten Sie die Ergebnisse der Dokumentenanalyse schließen?',
  CloseDocAnalysisDescription: 'Die Analyseergebnisse werden nicht gespeichert.',
  search_labels: '{{count}} Labels durchsuchen',
  AddLabelsToContracts: 'Labels zum Vertrag hinzufügen',
  Continue: 'Fortsetzen',
  notPossibleInviteUser: 'Dieser Benutzer kann nicht eingeladen werden',
  SharePointSynchronizationEnabled: 'SharePoint-Synchronisierung aktiviert',
  SharePointSynchronization: 'Die SharePoint-Synchronisierung wurde für diesen Vertrag aktiviert.',
  SharePointSynchronizationDisabled: 'SharePoint-Synchronisierung deaktiviert',
  SharePointSynchronizationHas:
    'Die SharePoint-Synchronisierung wurde für diesen Vertrag deaktiviert.',
  ResendInvite: 'Einladung erneut versenden',
  ResendInvitationSuccessful: 'Einladung wurde erfolgreich versandt',
  InvitationResend: 'Einladung wurde an {{userName}} erneut gesendet',
  YouHaveHoAnalyzableDocuments: 'Du hast keine Dokumente zum Analysieren',
  TheFormatOfTheDocumentDoesNotMeet:
    'Das Format des Dokuments/der Dokumente entspricht nicht den Anforderungen für die Analyse',
  AvailableFormats: 'Verfügbare Formate:',
  PageNotFound: 'Seite nicht gefunden',
  ThePageCouldNotBeFound:
    'Die Seite konnte nicht gefunden werden. Klicken Sie auf den Button unten, um zu den "Verträgen" zurückzukehren.',
  GoToContracts: 'Zu den Verträgen wechseln',
  CreateLabel: 'Label erstellen',
  reminderCalendar1:
    'Es ist eine Erinnerung für den Vertrag "{{contract}}" bezüglich des Termins "{{event}}"',
  reminderCalendar2: 'Datum des Termins: "{{date}}"',
  reminderCalendar3: 'Link zur Erinnerung - {{link}}',
  'Add to calendar': 'Zum Kalender hinzufügen',
  PleaseEnterYourFirstName: 'Bitte trage deinen Vornamen ein',
  PleaseEnterYourLastName: 'Bitte trage deinen Nachnamen ein',
  PleaseEnterOnlyLatinCharacters: 'Bitte nur lateinische Buchstaben',
  MyProfile: 'Mein Profil',
  CreateAccount: 'Benutzerkonto erstellen',
  IfYouContinueTheContractStatusMightChange:
    'Wenn du fortfährst, kann sich der Vertragsstatus ändern.',
  ThereAreNoOpenSignatureRequestsAnymore:
    'Es gibt keine offenen Signaturanfragen mehr. Dadurch kann sich der Status des Vertrags ändern.',
  SortBy: 'Sortieren nach',
  filter: 'Filter',
  verifyIdentity: 'Identität verifizieren',
  verifyIdentityDescription:
    'Um einen Vertrag mit verifizierter Signatur zu unterzeichnen, musst du eine Identitätsprüfung durchlaufen.',
  ToVerifyYourIdentity:
    'Mithilfe der Nect-App kannst du deine Identität prüfen lassen. Die Taste "Verifizieren" leitet dich zur Verifizierung auf',
  forVerification: 'weiter.',
  Verify: 'Verifizieren',
  ExpireThisWeek: 'Laufen diese Woche aus',
  ExpireNextWeek: 'Laufen nächste Woche aus',
  SetUpAuthenticationApp: 'Authentifizierungs-App einrichten',
  ScanQRCode: '1. Scanne diesen QR-Code mit deiner Authentifizierungs-App',
  EnterCodeGenerated: '2. Gib den von der App generierten 6-stelligen Code ein',
  YourAuthenticationApp: 'Oder gib diesen Code in deine Authentifizierungs-App ein.',
  weRecommendGoogleAuthenticator:
    'Wenn du noch keine Authentifizierungs-App hast, empfehlen wir Google Authenticator, Authy oder Duo.',
  Device: 'Gerät {{count}}',
  FilterContracts: 'Verträge filtern',
  contractsCounter: '1 Vertrag',
  contractsCounter_other: '{{count}} Verträge',
  NothingFound: 'Nichts gefunden',
  NoContractsMatchingCurrentFilters:
    'Es gibt keine Verträge, die den aktuellen Filtern entsprechen',
  ClearFilters: 'Filter zurücksetzen',
  VerifiedUser: 'Verifizierter Nutzer',
  ThisUserHasCompletedAnIdentityVerification:
    'Dieser Nutzer hat eine Identitätsprüfung abgeschlossen.',
  RegistrationDataIsValid: 'Registrierungsdaten sind gültig',
  IdDocumentIsValid: 'Ausweisdokument ist gültig',
  IdentityProofedSignature: 'Identitätsgeprüfte Signatur',
  OnlyMyContracts: 'Nur meine Verträge',
  incorrectAuthenticationCode:
    'Sieht aus, als hättest du einen falschen Authentifizierungs-Code eingegeben.',
  tfaTitle: 'Zwei-Faktor-Authentifizierung',
  ConfirmYourPasswordToSetUpTwoFactorAuthentication:
    'Bestätige dein Passwort, um die Zwei-Faktor-Authentifizierung für dieses Konto einzurichten.',
  Code: 'Code',
  EnterThe6DigitCodeGenerated:
    'Gebe den 6-stelligen Code ein, der von deiner Authentifizierungs-App generiert wurde.',
  passwordtodelete:
    'Du wirst es nicht zur Authentifizierung verwenden können. Bestätige mit deinem Passwort zum Fortfahren.',
  DeleteDevice: '{{deviceName}} löschen',
  AllContractsAvailableToYouWillAppearHere:
    'Alle für dich verfügbaren Verträge werden hier angezeigt.',
  AllCompanyContractsFromAllWorkspacesWillAppearHere:
    'Alle Unternehmensverträge aus allen Arbeitsbereichen werden hier angezeigt.',
  optional: '(optional)',
  YouNeedIncludeStreetAddress: 'Du musst eine Straßenadresse angeben.',
  YouNeedIncludeCity: 'Du musst eine Stadt angeben.',
  YouNeedIncludeCountry: 'Du musst ein Land angeben.',
  Saved: 'Gespeichert',
  SaveInfo: 'Information speichern',
  Billing: 'Rechnungsstellung',
  BillingInfo: 'Information zur Rechnungsstellung',
  emptyArchiveDescription: 'Alle archivierten Verträge werden hier angezeigt.',
  Role: 'Rolle',
  SearchForMembers: 'Suche nach Nutzern…',
  Update: 'Aktualisieren',
  WsInformationUpdated: 'Die Arbeitsbereichs-Information wurde aktualisiert.',
  DeleteLabel: '{{tagName}} löschen?',
  PleaseEnterContinue: 'Bitte gib "{{title}}" ein, um fortzufahren',
  EnterContinue: 'Gib "{{title}}" ein, um fortzufahren',
  workspaceWasDeleted: '{{title}} wurde gelöscht',
  PleaseEnterRequiredWWsName: 'Bitte gib den gewünschten Arbeitsbereichsnamen ein.',
  WorkspaceHasBeenRemoved: 'Der Arbeitsbereich wurde gelöscht und ist nicht mehr verfügbar.',
  Overview: 'Übersicht',
  gotIt: 'Verstanden',
  SelectedContractsCannot: 'Ausgewählte Verträge können nicht archiviert werden',
  ArchivingOnlyPossible:
    'Die Archivierung ist nur für Verträge mit dem Status "Offen" und "Abgelaufen" möglich.',
  Archive: 'Archivieren',
  AddSomeDetails: 'Details hinzufügen...',
  ThisContractWasImported: 'Dieser Vertrag wurde mithilfe der KI-Datenanalyse importiert.',
  IfYouContinue:
    'Wenn du fortfährst, stimmst du zu, den Vertrag mit einer elektronischen Signatur zu autorisieren.',
  IHaveReadAndUnderstood:
    'Den Inhalt des Vertrags sowie der Anlagen habe ich gelesen und verstanden und möchte den Vertrag nebst Anlagen unterzeichnen.',
  ThisFileProtected: 'Diese Datei ist vor Änderungen geschützt',
  FilesWhichProtected:
    'Dateien, die vor Änderungen geschützt sind, können nicht hochgeladen werden',
  SetUpTwoFactorAuthentication: 'Zwei-Faktoren-Authentifizierung einrichten',
  ItIsYourCompanyPolicyToSetAuthentication:
    'Es gehört zu den Richtlinien deines Unternehmens, eine zweite Authentifizierungsmethode einzurichten, um dein Konto zu schützen.',
  SetUpTFA: '2FA einrichten',
  pageNOfN: 'Seite {{count}} von {{count2}}',
  Modified: 'Geändert',
  proofOfSignature: 'Signaturnachweis',
  GeneratedOn: 'Erzeugt am',
  ContractTitle: 'Vertragstitel',
  ContractDescription: 'Vertragsbeschreibung',
  ContractOwner: 'Vertragsinhaber',
  ListSigners: 'Liste der Unterzeichner',
  LinkToContract: 'Link zum Vertrag',
  DocumentList: 'Liste der Dokumente',
  DocumentHash: 'Dokumenten-Hash (SHA3-256)',
  SignatureTimestamp: 'Signatur Zeitstempel',
  DigitalSignature: 'Digitale Unterschrift (ECDSA)',
  TransactionID: 'Transaktions-ID',
  InhubberProofSignature: 'Inhubber Signaturnachweis',
  Information: 'Information',
  Email: 'E-Mail',
  SearchMembers: '{{count}} Mitglieder durchsuchen',
  ChangeContractOwner: 'Vertragsinhaber ändern',
  ToTransferOwnership:
    'Um die Inhaberrechte an einem Vertrag zu übertragen, machen Sie eines der Unternehmensmitglieder zum Vertragsinhaber.',
  MakeOwner: 'Zum Inhaber machen',
  TransferOwnership: 'Besitz übertragen',
  TransferOwnershipSubHeader:
    'Bist du dir sicher, dass du {{owner}} zum Inhaber des Vertrags inklusive aller Aufgaben machen willst? Nur der neue Vertragsinhaber und Admins können diese Aktion rückgängig machen.',
  OwnershipTransferred: 'Besitz an dem Vertrag wurde übertragen',
  showMore: 'Mehr anzeigen',
  MultipleTransferOwnershipSubHeader:
    'Bist du dir sicher, dass du {{owner}} zum Inhaber der Verträge inklusive aller Aufgaben machen willst? Nur der neue Vertragsinhaber und Admins können diese Aktion rückgängig machen.',
  More: 'Mehr',
  OwnershipContractsTransferred: 'Besitz an den Verträgen wurde übertragen',
  MoveContractsTo: 'Vertrag verschieben zu…',
  MoveContractsTo_other: 'Verschieben {{count}} Verträge nach…',
  MoveTo: 'Verschieben nach',
  Move: 'Verschieben',
  ContractsMovedTo: 'Vertrag verschoben nach {{workspace}}',
  ContractsMovedTo_other: '{{count}} Verträge verschoben nach {{workspace}}',
  ContractIsNowAvailableIn: 'Vertrag ist nun verfügbar in {{workspace}}',
  ContractIsNowAvailableIn_other: 'Verträge sind nun verfügbar in {{workspace}}',
  ExtendedSearch: 'Erweiterte Suche',
  InvitePeople: 'Personen einladen',
  AllWorkspaceMembers: 'Alle Mitglieder des Arbeitsbereichs',
  AllFolderMembers: 'Alle Mitglieder des Ordners',
  CanAccess: 'Haben Zugriff',
  AddAssignees: 'Verantwortliche',
  EmailOrName: 'Name oder E-Mail-Adresse',
  Approval: 'Freigabe',
  AddTask: 'Aufgabe hinzufügen',
  TaskTitle: 'Aufgabentitel',
  WriteTaskTitle: 'Trage den Aufgabentitel ein',
  AddMoreDetailsToThisTask: 'Füge weitere Details zu dieser Aufgabe hinzu…',
  AssignPeopleToApprove: 'Beauftrage Personen mit der Vertragsfreigabe.',
  Assignees: 'Verantwortliche',
  Tasks: 'Aufgaben',
  YouCannotCancelAnInvitation:
    'Du kannst eine Einladung für Personen mit zugewiesenen Aufgaben nicht stornieren.',
  AnAssigneeIsPerson:
    'Ein Verantwortlicher ist eine Person, die einer Aufgabe zugewiesen wird. Verantwortliche werden automatisch zum Vertrag eingeladen und haben die Möglichkeit, ihn einzusehen.',
  AssigneesRemoved:
    'Verantwortliche, die aus der Aufgabe entfernt wurden, werden weiterhin Zugriff auf den Vertrag haben, bis du ihre Einladung entfernt hast.',
  DeleteTask: 'Aufgabe löschen',
  TaskHasBeenCreated: 'Aufgabe wurde erstellt',
  TaskHasBeenDeleted: 'Aufgabe wurde gelöscht',
  DeleteTaskQuestion: 'Aufgabe löschen?',
  AreYouSureDeleteTask:
    'Bist du dir sicher? Du kannst diese Aktion nicht rückgängig machen. Alle Verantwortlichen dieser Aufgabe werden weiterhin Zugriff auf den Vertrag haben, bis du ihre Einladung entfernt hast.',
  Change: 'Ändern',
  Confirm: 'Bestätigen',
  Rename: 'Umbenennen',
  CreateContract: 'Vertrag erstellen',
  SearchContracts: 'Verträge durchsuchen',
  orUseCreateContractButton: 'oder benutze die Schaltfläche "Vertrag erstellen".',
  ThisFolderIsEmpty: 'Dieser Ordner ist leer',
  AddTitle: 'Titel hinzufügen',
  AreYouSure: 'Bist du dir sicher? Du kannst diese Aktion nicht rückgängig machen.',
  TitleCanNotBeEmpty: 'Titel darf nicht leer sein',
  StartDateCanNotBeEmpty: 'Anfangsdatum darf nicht leer sein',
  DiscardChanges: 'Änderungen verwerfen?',
  TheChangesYou: 'Die Änderungen, die du gemacht hast, werden nicht gespeichert.',
  Discard: 'Verwerfen',
  DAY: 'Tag',
  WEEK: 'Woche',
  MONTH: 'Monat',
  YEAR: 'Jahr',
  DAY_other: 'Tage',
  WEEK_other: 'Wochen',
  MONTH_other: 'Monate',
  YEAR_other: 'Jahre',
  Review: 'Prüfung',
  Custom: 'Sonstiges',
  AssignPeopleToReview: 'Beauftrage Personen mit der Vertragsprüfung.',
  CreateCustomTasks: 'Erstelle individuelle Aufgaben für deinen Bedarf.',
  ContractValue: 'Vertragswert',
  AddedDocumentsWillAppearHere: 'Hinzugefügte Dokumente erscheinen hier',
  DropFilesHere: '<0>Dateien hier ablegen, oder</0><1/><2>Durchsuchen</2>',
  dropFilesDescription1: 'PDF, DOCX und',
  dropFilesDescription2_marker1: 'mehr',
  dropFilesDescription3: ', je bis zu 50 MB.',
  fileTypes: 'PDF, DOCX, XLS, JPEG, SVG, MP4, MP3, ZIP und andere.',
  AddDocuments: 'Dokumente hinzufügen',
  InviteRecipients: 'Empfänger einladen',
  AddedLabelsWillAppearHere: 'Hinzugefügte Labels erscheinen hier',
  CustomFields: 'Benutzerdefinierte Felder',
  Properties: 'Eigenschaften',
  Info: 'Info',
  Created: 'Erstellt',
  Creator: 'Ersteller',
  Statistics: 'Statistiken',
  Location: 'Ort',
  ContractID: 'Vertrags-ID',
  Copied: 'Kopiert',
  CopyContractID: 'Vertrags-ID kopieren',
  AddedCustomFieldsWillAppearHere: 'Hinzugefügte benutzerdefinierte Felder erscheinen hier',
  SelectPageView: 'Seitenansicht wählen',
  FormView: 'Formularansicht',
  DocumentView: 'Dokumentenansicht',
  hintForStartDate: 'Datum, an dem der Vertrag rechtsverbindlich wird.',
  hintForExpiryDate:
    'Letzter Tag, an dem der Vertrag in Kraft ist. Nach diesem Datum endet der Vertrag und verliert seine Rechtsverbindlichkeit.',
  DateUploaded: 'Datum hochgeladen',
  DateLastModified: 'Datum zuletzt geändert',
  UploadedOn: 'Hochgeladen am',
  Contacts: 'Kontakte',
  ContractsWillContinue:
    'Verträge werden weiterhin in "Meine Verträge" für jeden Vertragsteilnehmer angezeigt.',
  CopyTo: 'Kopieren nach',
  CopyContractTo: 'Kopieren Vertrag nach...',
  CopyContractTo_other: 'Kopieren {{count}} Verträge nach...',
  Copy: 'Kopieren',
  ContractCopiedTo: 'Vertrag kopiert nach  {{workspace}}',
  ContractCopiedTo_other: '{{count}} Verträge kopiert nach {{workspace}}',
  CopyOfContractIsNowAvailable: 'Kopie des Vertrags ist nun verfügbar in {{workspace}}',
  CopyOfContractIsNowAvailable_other: 'Kopien der Verträge sind nun verfügbar in  {{workspace}}',
  CancelCopyingContracts: 'Kopieren der Verträge abbrechen?',
  CancelCopyingContractsDes: 'Bist du dir sicher? Kopiervorgang der Verträge wird abgebrochen.',
  CancelCopying: 'Kopieren abbrechen',
  LinkedContracts: 'Verknüpfte Verträge',
  LinkedContractsWillAppearHere: 'Verknüpfte Verträge erscheinen hier',
  LinkContracts: 'Verträge verknüpfen',
  LinkType: 'Typ der Verknüpfung',
  UnlinkContract: 'Vertrag ablösen?',
  AreYouSureContractNoLonger:
    'Bist du dir sicher? Dieser Vertrag wird nicht mehr mit deinem Vertrag verknüpft sein.',
  Unlink: 'Ablösen',
  NoContractsAvailableToLink: 'Es gibt keine Verträge zum Verknüpfen',
  Link: 'Verknüpfen',
  LinkAnotherContracts: 'Weitere Verträge verknüpfen',
  LinkRelatedContracts:
    'Verknüpfe verwandte Verträge mit der gewünschten Beziehungsart. Wähle den Typ der Verknüpfung und die zu verknüpfenden Verträge aus.',
  ContractLinked: 'Vertrag verknüpft mit {{contractTitle}}',
  ContractLinked_other: '{{count}} Verträge verknüpft mit {{contractTitle}}',
  LinkedContract: 'Der verknüpfte Vertrag ist nun verfügbar in {{contractTitle}}',
  LinkedContract_other: 'Verknüpfte Verträge sind nun verfügbar in {{contractTitle}}',
  ContractHasBeenUnlinked: 'Vertrag wurde abgelöst',
  VerifiableOnEtherscan: 'Verifizierbar auf etherscan',
  AddMore: 'Mehr hinzufügen',
  Apply: 'Anwenden',
  InvalidRange: 'Ungültiger Bereich',
  addComment: 'Kommentar hinzufügen...',
  comment: 'Kommentar',
  comments: 'Kommentare',
  AllResults: 'Alle Ergebnisse',
  Hide: 'Ausblenden',
  RefineYourSearch: 'Verfeinere deine Suche um andere Ergebnisse zu sehen',
  send: 'Senden',
  followers: 'Beobachter',
  unarchive: 'Aus dem Archiv holen',
  'You can export':
    'Du kannst deine Vertragsablage exportieren. Sobald der Export abgeschlossen ist, senden wir dir den Download-Link per E-Mail.',
  export: 'Export',
  'Export has been started': 'Der Export wurde gestartet',
  exportIsDone:
    'Sobald der Export abgeschlossen ist, wird dir der Link per E-Mail zugeschickt ({{email}}).',
  'Add a title': 'Titel hinzufügen',
  'Add a description': 'Beschreibung hinzufügen',
  one_month: '1 Monat',
  three_months: '3 Monate',
  six_months: '6 Monate',
  one_year: '1 Jahr',
  two_years: '2 Jahre',
  one_time: 'Einmalig',
  on_demand: 'Auf Abruf',
  total: 'Gesamt',
  other: 'Sonstiges',
  financials: 'Finanzen',
  currency: 'Währung',
  periodicity: 'Periodizität',
  includes_all: 'Enthält alle Vertragszahlungen über den ausgewählten Zeitraum.',
  term: 'Laufzeit',
  Payment: 'Zahlung',
  'Recurring or one-time payments': 'Wiederkehrende oder einmalige Zahlungen',
  'Recurring payments': 'Wiederkehrende Zahlungen',
  'One-time fees': 'Einmalige Gebühren',
  'Add custom description': 'Benutzerdefinierte Beschreibung hinzufügen',
  'Edit custom description': 'Benutzerdefinierte Beschreibung bearbeiten',
  'Custom description': 'Benutzerdefinierte Beschreibung',
  'Select file': 'Datei auswählen',
}
