import { createReducer } from '@reduxjs/toolkit'

import { loadInitialReducerState } from '../store/persist'
import { addTemplate, changeTemplate, deleteTemplate } from '../actions/templates'

const initialState = { data: [], ...loadInitialReducerState('templates', 'inhubber-redux') }

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addTemplate, (state, action) => {
      const { payload } = action
      return { ...state, data: [...state.data, payload] }
    })
    .addCase(changeTemplate, (state, action) => {
      const { payload } = action
      const updatedData = state.data.map((template) =>
        template.id === payload.id ? payload : template,
      )
      return { ...state, data: updatedData }
    })
    .addCase(deleteTemplate, (state, action) => {
      const { payload } = action
      const updatedData = state.data.filter((template) => template.id !== payload.id)
      return { ...state, data: updatedData }
    })
})

export default reducer
