import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createSelector } from 'reselect'
import { Button, Modal } from '../atoms'

import { disablePermissionModal } from '../../actions/workspaces'
import { selectWorkspaces } from '../../store/selectors'

const GlobalPopups = () => {
  const dispatch = useDispatch()

  const closeWorkspacePermissionModal = useCallback(
    () => dispatch(disablePermissionModal()),
    [dispatch],
  )

  const selectPermissionModalVisible = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.permissionModalVisible,
  )

  const permissionModalVisible = useSelector(selectPermissionModalVisible)

  return (
    <Modal
      visible={permissionModalVisible}
      onClose={closeWorkspacePermissionModal}
      headerI18n="workspace:restrictModalHeader"
      subHeaderI18n="workspace:restrictModalDescription"
      data-test="modal_workspace-permissions"
      buttons={
        <Button
          width="fit-content"
          onClick={closeWorkspacePermissionModal}
          i18n="gotIt"
          data-test="button_workspace-permissions_close"
        >
          Got it
        </Button>
      }
    />
  )
}

export default GlobalPopups
