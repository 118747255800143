import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from '../atoms'
import PrivacyPolicyDe from './TermsPolicies/PrivacyPolicyDe'
import PrivacyPolicyEn from './TermsPolicies/PrivacyPolicyEn'
import { LANGUAGES } from '../../enums'

const PrivacyPolicy = ({ visible, onClose, className }) => {
  const { i18n } = useTranslation()
  return (
    <Modal
      termsConditions
      onClose={onClose}
      visible={visible}
      className={className}
      headerI18n="PrivacyPolicy"
      data-test="modal_privacy-policy"
    >
      <div style={{ minWidth: '70vw', height: '100%' }}>
        {i18n.language === LANGUAGES.DE ? <PrivacyPolicyDe /> : <PrivacyPolicyEn />}
      </div>
    </Modal>
  )
}

export default styled(PrivacyPolicy)``
