import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

const LazyLoader = ({ action, className }) => {
  const { ref, inView, entry } = useInView()
  const [previousInteractionTime, setTime] = useState(0)

  useEffect(() => {
    if (inView && action && (entry?.time || 0) - previousInteractionTime > 1000) {
      action()
      setTime(entry?.time)
    }
  }, [action, inView, previousInteractionTime, entry])

  return <div ref={ref} className={className} data-function="lazy-loader" />
}

LazyLoader.propTypes = {
  action: PropTypes.func,
  className: PropTypes.string,
}

export default styled(LazyLoader)`
  position: relative;
  top: -${({ offset }) => offset || 0}px;
  background-color: red;
`
