import styled from 'styled-components'

export const MenuGroupStyle = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: ${({ theme }) => theme.semantic.space['space-4x-small']};
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  width: ${({ width }) => width}px;

  &:nth-child(n + 2) {
    border-top: 1px solid ${({ theme }) => theme.semantic.palette.border['border-tertiary']};
  }
`
