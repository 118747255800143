import styled from 'styled-components'

const SegmentControl = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
  padding: ${({ theme }) => theme.semantic.space['space-5x-small']};
  box-sizing: border-box;
  background: ${({ theme }) => theme.semantic.palette.surface['surface-tertiary']};
`

export default SegmentControl
