import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { createSelector } from 'reselect'
import { Button, Modal, RadioButton, Spacing, Spinner, Typography } from '../../atoms'

import { callApi } from '../../../utils/callApi'
import { INHERITANCE_MODE_PERMISSIONS } from '../../../enums/folderAccessProfiles'
import { createNotification } from '../../../actions/notifications'
import { selectWorkspaces } from '../../../store/selectors'

const RemoveAccessModal = ({
  visible,
  onClose,
  usersNP,
  userFolderPermissionId,
  confirmDelete,
  folderId,
}) => {
  const { palette } = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [selectedRadioOption, setSelectedRadioOption] = useState(INHERITANCE_MODE_PERMISSIONS.NONE)
  const [animation, setAnimation] = useState(false)

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    folderTitle: workspaces.allFolders[folderId]?.attributes.name,
  }))
  const { folderTitle } = useSelector(selectData)

  const userName = `${usersNP.attributes.firstName} ${usersNP.attributes.lastName}`

  const onClick = () => {
    const asyncRemoveAccess = async () => {
      setAnimation(true)
      try {
        await callApi({
          endpoint: `userfolderpermissions/${userFolderPermissionId}?inheritanceMode=${selectedRadioOption}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })
        setAnimation(false)
        confirmDelete()
        onClose()
        dispatch(
          createNotification({
            state: 'success',
            value: t('workspace:FolderAccessRemoved'),
            description: t('workspace:userNameHasLostAccessToTheFolderName', {
              userName,
              folderTitle,
            }),
          }),
        )
      } catch (e) {
        setAnimation(false)
        confirmDelete()
        onClose()
      }
    }

    asyncRemoveAccess()
    onClose()
  }

  useEffect(() => {
    if (!visible) {
      setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.NONE)
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      headerI18n="workspace:RemoveAccess"
      subHeader={t('workspace:YouCanRemoveAccessOfName', { userName })}
      onClose={onClose}
      type="oldModal"
      buttons={
        <>
          <Button
            i18n="cancel"
            width="fit-content"
            secondary
            onClick={onClose}
            data-test="button_removeAccessModal_cancel"
          >
            Cancel
          </Button>
          <Button
            i18n="remove"
            width="fit-content"
            background={palette.accent.warning}
            onClick={animation ? undefined : onClick}
            data-test="button_removeAccessModal_confirm_remove"
          >
            {animation ? <Spinner animation="white" /> : 'Remove'}
          </Button>
        </>
      }
    >
      <Spacing size="24" />
      <Typography variant="body 14 medium" i18n="workspace:RemoveAccessTo">
        Remove access to
      </Typography>
      <Spacing size="16" />
      <RadioButton
        labelI18n="workspace:OnlyThisFolder"
        value={INHERITANCE_MODE_PERMISSIONS.NONE}
        onChange={() => setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.NONE)}
        checked={selectedRadioOption === INHERITANCE_MODE_PERMISSIONS.NONE}
        name="removeAccess"
      />
      <Spacing size="8" />
      <RadioButton
        labelI18n="workspace:ThisFolderAndSubfolders"
        value={INHERITANCE_MODE_PERMISSIONS.ALL}
        onChange={() => setSelectedRadioOption(INHERITANCE_MODE_PERMISSIONS.ALL)}
        checked={selectedRadioOption === INHERITANCE_MODE_PERMISSIONS.ALL}
        name="removeAccess"
      />
    </Modal>
  )
}

export default RemoveAccessModal
