import React, { useState } from 'react'

import styled from 'styled-components'
import { Button, Flex, SearchInput, Spacing, TextInput, Typography } from '../../components/atoms'
import { Banner } from '../../components/molecules'
import EmptyState from '../../components/molecules/EmptyState'
import PlaygroundTemplate from '../../components/templates/Playground'
import ActionList from '../../components/atoms/ActionList'
import { InputDropDown } from '../../components/atoms/DisclosureButton'
import Popup from '../../components/atoms/ActionList/Popup'

const ActionListComponent = () => {
  const [width, setWidth] = useState('128')
  const data = (id) => {
    return [
      {
        id: `${id}labels0`,
        items: [
          {
            id: `${id}label0`,
            label: 'Label',
          },
          {
            id: `${id}label1`,
            label: 'Label',
          },
          {
            id: `${id}label2`,
            label: 'Label',
          },
        ],
      },
    ]
  }

  const nestedItems = [
    {
      id: 'label0',
      items: [
        {
          id: 'label1',
          label: 'Label',
          items: [
            { id: 'label11', label: 'Label' },
            { id: 'label12', label: 'Label', actions: { state: true } },
            { id: 'label13', label: 'Label', selected: true },
          ],
        },
        {
          id: 'label2',
          label: 'Label',
        },
        {
          id: 'label3',
          label: 'Label',
        },
        {
          id: 'label4',
          label: 'Label',
        },
        {
          id: 'label5',
          label: 'Label',
        },
      ],
    },
  ]

  const person = {
    address: 'test',
    avatarColor: 'FB8C00',
    emailAddress: 'michael.bowl@silverbeck.de',
    features: '["inviteForQes","betaFeatures","fulltextsearch","analyseDocuments","analyseQna"]',
    firstName: 'Michael',
    id: '123123123123',
    jwt: 'test',
    lastName: 'Bowl',
    userActivated: 'activated',
    userType: 'issuer',
  }

  const createPersonArray = (template, count) => {
    return Array.from({ length: count }, (_, index) => {
      return {
        ...template,
        emailAddress: `${template.emailAddress}.${index + 1}.mock`,
        id: `${template.id}-${index + 1}`,
      }
    })
  }

  const personArray = createPersonArray(person, 3)

  const persons = [
    {
      id: 'persons',
      type: 'persona',
      items: [
        ...personArray,
        {
          address: 'test',
          avatarColor: 'FB8C00',
          emailAddress: 'michael.bowl@silverbeck.de',
          features:
            '["inviteForQes","betaFeatures","fulltextsearch","analyseDocuments","analyseQna"]',
          firstName: 'Michael',
          id: '11',
          jwt: 'test',
          lastName: 'Bowl',
          userActivated: 'activated',
          userType: 'issuer',
          selected: true,
        },
        {
          address: 'test',
          avatarColor: 'FB8C00',
          emailAddress: 'michael.bowl@silverbeck.de',
          features:
            '["inviteForQes","betaFeatures","fulltextsearch","analyseDocuments","analyseQna"]',
          firstName: 'Michael',
          id: '112',
          jwt: 'test',
          lastName: 'Bowl',
          userActivated: 'activated',
          userType: 'issuer',
          selected: true,
          disabled: true,
        },
        {
          address: 'test',
          avatarColor: 'FB8C00',
          emailAddress: 'michael.bowl@silverbeck.de',
          features:
            '["inviteForQes","betaFeatures","fulltextsearch","analyseDocuments","analyseQna"]',
          firstName: 'Michael',
          id: '1123',
          jwt: 'test',
          lastName: 'Bowl',
          userActivated: 'activated',
          userType: 'issuer',
          disabled: true,
        },
      ],
    },
  ]

  const statesDefault = [
    {
      id: 'defaul',
      items: [
        {
          id: 'defaul0',
          label: 'Defaul',
        },
        {
          id: 'selected',
          label: 'Selected',
          selected: true,
        },
        {
          id: 'selected-disabled',
          label: 'Selected & Disabled',
          selected: true,
          disabled: true,
        },
        {
          id: 'disabled',
          label: 'Disabled',
          disabled: true,
        },
      ],
    },
  ]
  const lists = [
    {
      id: 'list0',
      items: [
        {
          id: 'listItem0',
          label: 'Defaul',
        },
        {
          id: 'listItem1',
          label: 'Defaul',
        },
      ],
    },
    {
      id: 'list1',
      items: [
        {
          id: 'listItem01',
          label: 'Defaul',
        },
        {
          id: 'listItem11',
          label: 'Defaul',
        },
        {
          id: 'listItem22',
          label: 'Defaul',
        },
      ],
    },
    {
      id: 'list2',
      items: [
        {
          id: 'listItem02',
          label: 'Defaul',
        },
        {
          id: 'listItem12',
          label: 'Defaul',
        },
        {
          id: 'listItem22',
          label: 'Defaul',
        },
      ],
    },
  ]

  const description = [
    {
      id: 'description',
      items: [
        {
          id: 'label01',
          label: 'Label',
          description: 'Description',
        },
        {
          id: 'label11',
          label: 'Label',
          description: 'Description',
        },
        {
          id: 'label22',
          label: 'Label',
          description: 'Description',
        },
      ],
    },
  ]

  const header = [
    {
      id: 'header',
      header: 'header',
      items: [
        {
          id: 'label0header1',
          label: 'Label',
        },
        {
          id: 'label11header',
          label: 'Label',
        },
        {
          id: 'label22header',
          label: 'Label',
        },
      ],
    },
    {
      id: 'header1',
      header: 'header',
      items: [
        {
          id: 'label0header11',
          label: 'Label',
        },
        {
          id: 'label11header1',
          label: 'Label',
        },
        {
          id: 'label22header1',
          label: 'Label',
        },
      ],
    },
  ]

  const icon = [
    {
      id: 'icon',
      items: [
        {
          id: 'label0icon',
          label: 'Label',
          icon: 'folder-arrow-right',
        },
        {
          id: 'label1icon',
          label: 'Label',
          icon: 'person-plus',
        },
        {
          id: 'label2icon',
          label: 'Label',
          icon: 'clipboard-arrow-right',
        },
      ],
    },
  ]

  const actions = [
    {
      id: 'actions',
      items: [
        {
          id: 'label0actions',
          label: 'Label',
          actions: { state: true },
        },
        {
          id: 'label1actions',
          label: 'Label',
          actions: { state: false },
        },
        {
          id: 'label2actions',
          label: 'Label',
          actions: { state: false },
        },
      ],
    },
  ]
  return (
    <PlaygroundTemplate>
      <Typography variant="24 medium">Action List</Typography>
      <Spacing size="16" />
      <Flex direction="column" gap="16px" align="flex-start">
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Nested items</Typography>
          <Popup
            width="200px"
            enabled
            trigger="click"
            tooltip={<ActionList width="200px" data={nestedItems} />}
          >
            <InputDropDown>Nested items</InputDropDown>
          </Popup>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">States Default</Typography>
          <Container>
            <ActionList width="200px" data={statesDefault} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">States Persons</Typography>
          <Container>
            <ActionList width="200px" data={persons} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Lists</Typography>
          <Container>
            <ActionList width="200px" data={lists} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Additions (Banner)</Typography>
          <Container>
            <ActionList width="272px" data={data('banner')}>
              <Banner
                icon="info-circle"
                header="You can change company role after user has finished the registration."
              />
            </ActionList>
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Search bar</Typography>
          <Container>
            <ActionList
              width="272px"
              data={data('search-bar')}
              content={<SearchInput placeholder="Search" borderless />}
            />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Empty state</Typography>
          <Container>
            <ActionList data={[]} width="272px">
              <EmptyState width="100%" type="special" description="Empty state" />
            </ActionList>
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Description</Typography>
          <Container>
            <ActionList width="272px" data={description} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Header</Typography>
          <Container>
            <ActionList width="272px" data={header} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Icon</Typography>
          <Container>
            <ActionList width="272px" data={icon} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Actions</Typography>
          <Container>
            <ActionList width="272px" data={actions} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Nested items</Typography>
          <Container>
            <ActionList width="200px" data={nestedItems} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Scroll bar</Typography>
          <Container>
            <ActionList width="200px" height="150px" data={lists} />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Bottom actions</Typography>
          <Container>
            <ActionList
              width="200px"
              data={data('bottom-actions')}
              action={<Button type="secondary">Action</Button>}
            />
          </Container>
        </Flex>
        <Flex direction="column" gap="8px" align="flex-start">
          <Typography variant="body 13 medium">Sizing</Typography>
          <TextInput
            placeholder="width"
            value={width}
            onChange={(e) => {
              setWidth(e.target.value)
            }}
          />
          <Container>
            <ActionList width={`${width}px`} data={data('width')} />
          </Container>
        </Flex>
      </Flex>
    </PlaygroundTemplate>
  )
}

const Container = styled(Flex)`
  ${({ theme }) => theme.shadow.medium};
`

export default ActionListComponent
