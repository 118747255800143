import styled from 'styled-components'
import { useCallback } from 'react'

import { useNavigate } from 'react-router-dom'
import { Button, Spacing, Typography } from '../../atoms'

import { usePermissions } from '../../../hooks'

const SearchNothingFound = ({ className, enabled, query, onClose }) => {
  const { isPermissionEnabled, PERMISSIONS } = usePermissions()
  const navigate = useNavigate()

  const onClick = useCallback(
    (e) => {
      const urlEncodedQuery = window.encodeURI(query)
      navigate(`/extended-search/${urlEncodedQuery}`)

      if (typeof onClose === 'function') {
        onClose(e)
      }
    },
    [navigate, onClose, query],
  )

  if (!enabled) {
    return null
  }

  const betaFeaturesEnabled = isPermissionEnabled(PERMISSIONS.BETA_FEATURES)

  return (
    <div className={className}>
      <Typography variant="14 medium" color="tertiary" i18n="NoResults">
        No results
      </Typography>
      {betaFeaturesEnabled && (
        <div>
          <Spacing size="8" />
          <Button width="fit-content" onClick={onClick} i18n="ExtendedSearch">
            Extended search
          </Button>
          <Spacing size="16" />
        </div>
      )}
    </div>
  )
}

export default styled(SearchNothingFound)``
