import styled, { useTheme } from 'styled-components'
import { Fragment, useCallback, useMemo, useState } from 'react'
import compact from 'lodash/compact'
import { useDispatch, useSelector } from 'react-redux'
import filter from 'lodash/filter'

import { createSelector } from 'reselect'
import { Divider, ScrollContainer, Spacing, Typography } from '../../atoms'
import WorkspaceCreateModal from '../Workspaces/WorkspaceCreateModal'
import MenuItem from './MenuItem'
import WorkspaceItem from './WorkspaceItem'

import { setActiveWorkspace } from '../../../actions/workspaces'
import { setActiveDigitalAsset } from '../../../actions/digitalAssets'
import { DIGITALASSET_STATES, PERMISSIONS } from '../../../enums'
import { generateSlug } from '../../../pages/Workspaces/slugs'
import { useMatchPath, usePermissions } from '../../../hooks'
import {
  betaFeaturesEnabledSelector,
  selectIncluded,
  selectWorkspaces,
} from '../../../store/selectors'

const DefaultSidebarPage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { pathname } = window.location
  const { isPermissionEnabled } = usePermissions()

  const isIssuer = isPermissionEnabled(PERMISSIONS.USER_ISSUER)
  const isBasic = isPermissionEnabled(PERMISSIONS.USER_BASIC)
  const isInhubberAdmin = isPermissionEnabled(PERMISSIONS.USER_INHUBBER_ADMIN)

  const [wsCreateModalVisible, setWsCreateModalVisible] = useState(false)

  const { id: digitalAssetId } = useMatchPath('/contract-details/:id')
  const activeWorkspaceIdSelector = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.active,
  )

  const activeDaSelector = createSelector(
    [selectIncluded],
    (included) => included.digitalassets[digitalAssetId],
  )

  const allWorkspacesSelector = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.allWorkspaces,
  )

  const workspaceOrderSelector = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.workspaceOrder,
  )

  const isDebuggerMode = useSelector(betaFeaturesEnabledSelector)
  const activeWorkspaceId = useSelector(activeWorkspaceIdSelector)
  const activeDa = useSelector(activeDaSelector)
  const allWorkspaces = useSelector(allWorkspacesSelector)
  const workspaceOrder = useSelector(workspaceOrderSelector)

  const workspaceCreationEnabled = isPermissionEnabled(PERMISSIONS.WORKSPACES_CREATE)

  const customWorkspaceItems = useMemo(
    () =>
      filter(
        workspaceOrder,
        (workspaceId) => !isInhubberAdmin && allWorkspaces[workspaceId].attributes.title !== null,
      )
        .map((workspaceId) => allWorkspaces[workspaceId])
        .sort((a, b) => {
          const nameA = a?.attributes?.title?.toUpperCase()
          const nameB = b?.attributes?.title?.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        }),
    [allWorkspaces, isInhubberAdmin, workspaceOrder],
  )

  const workspaceSectionEnabled = customWorkspaceItems.length > 0

  const refresh = useCallback(() => {
    dispatch(setActiveWorkspace({ id: null }))
    dispatch(setActiveDigitalAsset(null))
  }, [dispatch])

  const firstGroupItems = useMemo(
    () =>
      compact([
        isIssuer || isBasic ? (
          <MenuItem
            key="home"
            value="Home"
            i18n="home:home"
            icon="house"
            route="/home"
            isActive={() =>
              (pathname?.indexOf('home') !== -1 && !activeWorkspaceId) ||
              (pathname?.indexOf('selfident') !== -1 && !activeWorkspaceId)
            }
            data-test="button_sidebar_home"
          />
        ) : undefined,
        !isInhubberAdmin ? (
          <Fragment key="non-admin=items">
            <MenuItem
              value="My contracts"
              i18n="workspace:defaultWorkspace"
              icon="contract"
              route="/my-contracts"
              onClick={refresh}
              isActive={() =>
                pathname?.toLowerCase().indexOf('contract') !== -1 &&
                !activeWorkspaceId &&
                activeDa?.attributes.state !== DIGITALASSET_STATES.ARCHIVED
              }
            />
            <MenuItem
              value="Archive"
              i18n="sidebar:archive"
              icon="archivebox"
              route="/archive"
              onClick={refresh}
              isActive={() =>
                (!activeWorkspaceId && pathname?.toLowerCase().indexOf('archive') !== -1) ||
                (pathname?.toLowerCase().indexOf('contract-') !== -1 &&
                  activeDa?.attributes.state === DIGITALASSET_STATES.ARCHIVED)
              }
              data-test="button_sidebar_archive"
            />
          </Fragment>
        ) : undefined,

        !isInhubberAdmin && isDebuggerMode ? (
          <MenuItem
            key="templates"
            value="Templates"
            i18n="templates:templates"
            icon="templates"
            route="/templates"
            isActive={() => pathname?.indexOf('template') !== -1 && !activeWorkspaceId}
          />
        ) : undefined,
        isInhubberAdmin ? (
          <MenuItem
            key="admin"
            value="Administration"
            route="/admin"
            i18n="admin"
            icon="gear"
            data-test="link_sidebar_admin"
            isActive={() => pathname?.indexOf('admin') !== -1 && !activeWorkspaceId}
          />
        ) : undefined,
      ]),
    [
      isIssuer,
      isBasic,
      isInhubberAdmin,
      refresh,
      isDebuggerMode,
      pathname,
      activeWorkspaceId,
      activeDa?.attributes.state,
    ],
  )

  const workspaceItems = useMemo(
    () =>
      compact([
        ...customWorkspaceItems.map((workspace) => {
          const route = `/workspaces/${generateSlug(workspace)}`
          return (
            <WorkspaceItem
              key={workspace.id}
              value={workspace.attributes.title}
              route={route}
              workspaceId={workspace.id}
              isActive={() =>
                activeWorkspaceId === workspace.id &&
                (pathname?.indexOf(route) !== -1 ||
                  (pathname?.indexOf('contract-') !== -1 &&
                    activeDa?.attributes.state !== DIGITALASSET_STATES.ARCHIVED))
              }
              data-test={`link_sidebar_workspace-${workspace.id}`}
            />
          )
        }),
        workspaceCreationEnabled && (
          <MenuItem
            key="createWorkspaceButton"
            iconBackground={theme.semantic.palette.surface.surface}
            color={theme.semantic.palette.icon['icon-secondary']}
            onClick={() => setWsCreateModalVisible(true)}
            icon="plus"
            i18n="workspace:NewWorkspace"
            value="New workspace"
            data-test="button_sidebar_createWorkspaceButton"
            iconHoverBackground={theme.semantic.palette.icon.icon}
          />
        ),
      ]),
    [
      activeDa?.attributes.state,
      activeWorkspaceId,
      customWorkspaceItems,
      pathname,
      theme.semantic.palette.icon,
      theme.semantic.palette.surface.surface,
      workspaceCreationEnabled,
    ],
  )

  return (
    <>
      <SidebarPage>
        <MenuContainer>{firstGroupItems}</MenuContainer>
        <WorkspaceContainer>
          {(workspaceSectionEnabled || workspaceCreationEnabled) && (
            <div data-test="sidebar_workspaceContainer">
              <Workspaces>
                <Typography variant="header-small" color="tertiary" i18n="workspace:workspaces">
                  Workspaces
                </Typography>
              </Workspaces>
              <WorkspaceItems>{workspaceItems}</WorkspaceItems>
            </div>
          )}
        </WorkspaceContainer>
      </SidebarPage>

      <WorkspaceCreateModal
        visible={wsCreateModalVisible}
        onClose={() => setWsCreateModalVisible(false)}
      />
    </>
  )
}

const Workspaces = styled.div`
  padding: ${({ theme }) =>
    `${theme.space['space-0']} ${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-2x-small']}`};
`

const MenuContainer = styled.div`
  padding: ${({ theme }) => theme.semantic.space['space-small']};
  border-bottom: 1px solid ${({ theme }) => theme.semantic.palette.border.border};
`

const WorkspaceContainer = styled(ScrollContainer)`
  padding: ${({ theme }) =>
    `${theme.semantic.space['space-small']} 0 ${theme.semantic.space['space-small']} ${theme.semantic.space['space-small']}`};
  flex: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.semantic.palette.border.border};
  box-sizing: border-box;
  > div {
    width: 240px;
  }

  &:not(:hover) {
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`

const WorkspaceItems = styled.div`
  min-width: 210px;
`

export const SidebarPage = styled.div`
  width: 100%;
  display: grid;
  height: 100%;
  grid-auto-rows: min-content 1fr;
`

export default DefaultSidebarPage
