import { Fragment, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import compact from 'lodash/compact'

import { createSelector } from 'reselect'
import { Divider, DropDown, Elevation, Flex, ModalItem, Spacing, Typography } from '../../atoms'
import { PersonaDetails } from '../../molecules'
import RemoveAccessModal from './RemoveAccessModal'
import TransferOwnershipModal from './TransferOwnershipModal'
import ChangeUserFolderPermissionsModal from './ChangeUserFolderPermissionsModal'

import { FOLDER_ACCESS_PROFILES } from '../../../enums/folderAccessProfiles'
import { selectAccount, selectIncluded } from '../../../store/selectors'

const AccessTab = ({
  members,
  folderId,
  filter,
  accessProfiles,
  getFolderMembers,
  membersType,
  className,
}) => {
  const selectData = createSelector([selectAccount, selectIncluded], (account, included) => ({
    currentUserId: account?.currentUser?.id,
    included,
  }))
  const { currentUserId, included } = useSelector(selectData)

  const [toEditorVisible, setToEditorVisible] = useState(false)
  const [removeAccessVisible, setRemoveAccessVisible] = useState(false)
  const [transferOwnershipVisible, setTransferOwnershipVisible] = useState(false)
  const [accessProfilesType, setAccessProfilesType] = useState(undefined)

  const filteringNP = useCallback(
    (firstName, lastName, emailAddress) => {
      if (filter) {
        const filterLowerCase = filter.toLowerCase()
        return (
          emailAddress.toLowerCase().indexOf(filterLowerCase) !== -1 ||
          firstName.toLowerCase().indexOf(filterLowerCase) !== -1 ||
          lastName.toLowerCase().indexOf(filterLowerCase) !== -1 ||
          [firstName, lastName].join(' ').toLowerCase().indexOf(filterLowerCase) !== -1
        )
      }
      return true
    },
    [filter],
  )

  const folderMembers = useMemo(() => {
    return compact(
      members.map((i, index) => {
        const account = included.accounts[i.relationships.grantedToAccount.data.id]
        if (!account) {
          return null
        }
        const usersNP = included.naturalpersons[account.relationships.ownedBy.data.id]

        const { firstName, lastName, emailAddress } = usersNP.attributes
        const filtering = filteringNP(firstName, lastName, emailAddress)
        if (!filtering) {
          return null
        }

        const accessProfile = i.relationships?.accessProfile
          ? accessProfiles.find((f) => f.id === i.relationships?.accessProfile.data.id)
          : undefined

        const visibleValue = accessProfile ? accessProfile.attributes.type : 'NoAccess'
        return (
          <Fragment key={`access-tab-${i.id}`}>
            <Spacing size={index > 0 ? 12 : 0} />
            <Flex
              content="space-between"
              data-test={`folderMemberInfo_manageAccess_${visibleValue}`}
            >
              <PersonaDetails
                size="medium"
                firstName={firstName}
                lastName={lastName}
                labels={[emailAddress]}
                seed={emailAddress}
                verified={account.attributes.identVerified}
                verifiedPosition="persona"
              />
              <Spacing size="16" />
              {visibleValue === FOLDER_ACCESS_PROFILES.PARENT_OWNER ||
              visibleValue === FOLDER_ACCESS_PROFILES.MAINTAINER ? undefined : (
                <DropDown
                  tertiary
                  i18n={`workspace:${visibleValue}`}
                  placement="bottom-end"
                  disabled={
                    visibleValue === FOLDER_ACCESS_PROFILES.OWNER || currentUserId === usersNP.id
                  }
                  data-test={`dropdown_folderMemberInfo_folderAccessProfiles_${visibleValue}`}
                >
                  <ModalItem
                    i18n={`workspace:${FOLDER_ACCESS_PROFILES.EDITOR}`}
                    descriptionI18n="workspace:CanManageThisFolder"
                    selected={visibleValue === FOLDER_ACCESS_PROFILES.EDITOR}
                    onClick={() => {
                      if (visibleValue !== FOLDER_ACCESS_PROFILES.EDITOR) {
                        const type = accessProfiles.find(
                          (f) => f.attributes.type === FOLDER_ACCESS_PROFILES.EDITOR,
                        )
                        setAccessProfilesType(type)
                        setToEditorVisible(i.id)
                      }
                    }}
                    data-test="dropdown_accessTab_folderAccessProfiles_editor"
                  />
                  <Spacing size="4" />
                  <ModalItem
                    i18n={`workspace:${FOLDER_ACCESS_PROFILES.VIEWER}`}
                    descriptionI18n="workspace:CanViewContracts"
                    selected={visibleValue === FOLDER_ACCESS_PROFILES.VIEWER}
                    onClick={() => {
                      if (visibleValue !== FOLDER_ACCESS_PROFILES.VIEWER) {
                        const type = accessProfiles.find(
                          (f) => f.attributes.type === FOLDER_ACCESS_PROFILES.VIEWER,
                        )
                        setAccessProfilesType(type)
                        setToEditorVisible(i.id)
                      }
                    }}
                    data-test="dropdown_accessTab_folderAccessProfiles_viewer"
                  />
                  <Spacing size="4" />
                  <Divider />
                  <Spacing size="4" />
                  <ModalItem
                    i18n="workspace:NoAccess"
                    descriptionI18n="workspace:RemoveAccessToThisFolder"
                    selected={!accessProfile}
                    onClick={() => {
                      if (accessProfile) setRemoveAccessVisible(i.id)
                    }}
                    data-test="dropdown_accessTab_folderAccessProfiles_noAccess"
                  />
                </DropDown>
              )}
              {toEditorVisible === i.id && accessProfilesType ? (
                <ChangeUserFolderPermissionsModal
                  usersNP={usersNP}
                  visible={toEditorVisible === i.id}
                  onClose={() => setToEditorVisible(false)}
                  accessProfile={accessProfilesType}
                  folderId={folderId}
                  accountId={account.id}
                  userFolderPermissionId={accessProfile ? i.id : undefined}
                  confirmChanging={() => {
                    getFolderMembers()
                  }}
                />
              ) : undefined}

              {removeAccessVisible === i.id ? (
                <RemoveAccessModal
                  usersNP={usersNP}
                  visible={removeAccessVisible === i.id}
                  onClose={() => setRemoveAccessVisible(false)}
                  userFolderPermissionId={accessProfile ? i.id : undefined}
                  confirmDelete={() => {
                    getFolderMembers()
                  }}
                  folderId={folderId}
                />
              ) : undefined}
              {transferOwnershipVisible === i.id ? (
                <TransferOwnershipModal
                  visible={transferOwnershipVisible === i.id}
                  onClose={() => setTransferOwnershipVisible(false)}
                  userName={`${usersNP.attributes.firstName} ${usersNP.attributes.lastName}`}
                  folderId={folderId}
                />
              ) : undefined}
            </Flex>
          </Fragment>
        )
      }),
    )
  }, [
    members,
    included,
    filteringNP,
    accessProfiles,
    currentUserId,
    toEditorVisible,
    accessProfilesType,
    folderId,
    removeAccessVisible,
    transferOwnershipVisible,
    getFolderMembers,
  ])

  return (
    <div className={className} data-test={`list_members_manageAccess_${membersType}`}>
      {folderMembers.length > 0 || !filter ? (
        folderMembers
      ) : (
        <Flex content="center" data-test="typography_searchMembers_manageAccess_noResults">
          <Typography i18n="NoResults" color="secondary" variant="body 13 regular">
            No results
          </Typography>
        </Flex>
      )}
    </div>
  )
}
export default styled(AccessTab)`
  ${DropDown} ${Elevation} {
    width: 264px;
  }
`
