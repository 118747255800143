import { useCallback } from 'react'

import Elevation from '../Elevation'

const BaseElevation = ({
  hidden,
  children,
  isAnimated,
  closeOnClick = false,
  popperStyle,
  ...props
}) => {
  const stopPropagation = useCallback(
    (e) => (closeOnClick ? undefined : e.stopPropagation()),
    [closeOnClick],
  )

  if (hidden) {
    return null
  }

  return (
    <Elevation
      variant="large"
      onClick={stopPropagation}
      onMouseDown={!isAnimated ? stopPropagation : undefined}
      style={popperStyle}
      {...props}
    >
      {children}
    </Elevation>
  )
}

export default BaseElevation
