import styled from 'styled-components'

const PlaygroundTemplate = ({ className, children }) => {
  return <div className={className}>{children}</div>
}

export default styled(PlaygroundTemplate)`
  padding: 8px 32px;
  overflow: auto;
`
