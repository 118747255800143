export default {
  en: {
    Repeat: 'Repeat',
    Never: 'Never',
    EveryMonth: 'Every month',
    EveryThreeMonths: 'Every 3 months',
    EverySixMonths: 'Every 6 months',
    EveryYear: 'Every year',
    Custom: 'Custom',
    RepeatEvery: 'Repeat every',
    Month: 'Month',
    Months: 'Months',
    Year: 'Year',
    Years: 'Years',
    EveryMonthOnDay: 'every month on day {{dayOfMonth}}',
    EveryMonthsOnDay: 'every {{number}} months on day {{dayOfMonth}}',
    EveryYearOnDay: 'every year on {{month}} {{dayOfMonth}}',
    EveryYears: 'every {{number}} years on {{month}} {{dayOfMonth}}',
    EveryLastMonthOnDay: 'every month on the last day of a month',
    EveryLastMonthsOnDay: 'every {{number}} months on the last day of a month',
    EveryLastYearOnDay: 'every year on the last day of {{month}}',
    EveryLastYears: 'every {{number}} years on the last day of {{month}}',
    DeleteEvent: 'Delete event?',
    StartDate: 'Start date',
    EventHasBeenCreated: 'Event has been created',
    EventHasBeenUpdated: 'Event has been updated',
    EventHasBeenDeleted: 'Event has been deleted',
    AddReminderDescription: 'Add reminder description…',
    CreateEventForField: 'Create an event for this field',
    EventHasBeenCreatedEditEvent: 'Event has been created.',
    EventRepeats: 'Event repeats',
    StartDateUpdatesAutomatically:
      'Start date updates automatically when the date in the field linked with this event changes.',
    ReminderHasBeenCreated: 'Reminder has been created',
    ReminderHasBeenUpdated: 'Reminder has been updated',
    ReminderHasBeenDeleted: 'Reminder has been deleted',
    DeleteReminder: 'Delete reminder?',
    onDay: 'On day {{dayOfMonth}}',
    onDayYear: 'On {{month}} {{dayOfMonth}}',
    lastDay: 'On the last day of a month',
    lastDayYear: 'On the last day of {{month}}',
    addRecipients: 'Add recipients',
  },
  de: {
    Repeat: 'Wiederholen',
    Never: 'Nie',
    EveryMonth: 'Jeden Monat',
    EveryThreeMonths: 'Alle 3 Monate',
    EverySixMonths: 'Alle 6 Monate',
    EveryYear: 'Jedes Jahr',
    Custom: 'Benutzerdefiniert',
    RepeatEvery: 'Periodizität',
    Month: 'Monat',
    Months: 'Monate',
    Year: 'Jahr',
    Years: 'Jahre',
    EveryMonthOnDay: 'jeden Monat am {{dayOfMonth}}.',
    EveryMonthsOnDay: 'alle {{number}} Monate am {{dayOfMonth}}.',
    EveryYearOnDay: 'jedes Jahr am {{dayOfMonth}}. {{month}}',
    EveryYears: 'alle {{number}} Jahre am {{dayOfMonth}}. {{month}}',
    EveryLastMonthOnDay: 'jeden Monat am letzten Tag eines Monats',
    EveryLastMonthsOnDay: 'alle {{number}} Monate am letzten Tag eines Monats',
    EveryLastYearOnDay: 'jedes Jahr am letzten Tag im {{month}}',
    EveryLastYears: 'alle {{number}} Jahre am letzten Tag im {{month}}',
    DeleteEvent: 'Termin löschen?',
    StartDate: 'Anfangsdatum',
    EventHasBeenCreated: 'Termin wurde erstellt',
    EventHasBeenUpdated: 'Termin wurde aktualisiert',
    EventHasBeenDeleted: 'Termin wurde gelöscht',
    AddReminderDescription: 'Erinnerungsbeschreibung hinzufügen…',
    CreateEventForField: 'Termin für dieses Feld erstellen',
    EventHasBeenCreatedEditEvent: 'Termin wurde erstellt.',
    EventRepeats: 'Termin wiederholt sich',
    StartDateUpdatesAutomatically:
      'Startdatum wird automatisch aktualisiert, wenn sich das Datum in dem mit diesem Ereignis verknüpften Feld ändert.',
    ReminderHasBeenCreated: 'Erinnerung wurde erstellt',
    ReminderHasBeenUpdated: 'Erinnerung wurde aktualisiert',
    ReminderHasBeenDeleted: 'Erinnerung wurde gelöscht',
    DeleteReminder: 'Erinnerung löschen?',
    onDay: 'Am {{dayOfMonth}}.',
    onDayYear: 'Am {{dayOfMonth}}. {{month}}',
    lastDay: 'Am letzten Tag eines Monats',
    lastDayYear: 'Am letzten Tag im {{month}}',
    addRecipients: 'Empfänger hinzufügen',
  },
  fr: {
    Repeat: 'Répéter',
    Never: 'Jamais',
    EveryMonth: 'Chaque mois',
    EveryThreeMonths: 'Tous les 3 mois',
    EverySixMonths: 'Tous les 6 mois',
    EveryYear: 'Chaque année',
    Custom: 'personaliser',
    RepeatEvery: 'Répéter chaque',
    Month: 'Mois',
    Months: 'Mois',
    Year: 'Année',
    Years: 'Années',
    EveryMonthOnDay: 'tous les mois, le {{dayOfMonth}} eme jour',
    EveryMonthsOnDay: 'tous les {{number}} mois le {{dayOfMonth}}eme jour',
    EveryYearOnDay: 'tous les ans le {{dayOfMonth}} {{month}}',
    EveryYears: 'tous les {{number}} ans le {{dayOfMonth}} {{month}} ',
    EveryLastMonthOnDay: 'tous les mois, le dernier jour du mois',
    EveryLastMonthsOnDay: "tous les {{number}} mois le dernier jour d'un mois",
    EveryLastYearOnDay: 'chaque année le dernier jour du {{month}}',
    EveryLastYears: 'tous les {{number}} ans, le dernier jour du {{month}}',
    DeleteEvent: "Supprimer l'événement?",
    StartDate: 'Date de début',
    EventHasBeenCreated: "L'événement a été créé",
    EventHasBeenUpdated: "L'événement a été mis à jour",
    EventHasBeenDeleted: "L'événement a été supprimé",
    AddReminderDescription: 'Ajouter une description au rappel…',
    CreateEventForField: 'Créer un événement pour ce champ',
    EventHasBeenCreatedEditEvent: "L'événement a été créé.",
    EventRepeats: "l'événement se répète",
    StartDateUpdatesAutomatically:
      'La date de début est mise à jour automatiquement lorsque la date du champ lié à cet événement change.',
    ReminderHasBeenCreated: 'Un rappel a été créé',
    ReminderHasBeenUpdated: 'Le rappel a été mis à jour',
    ReminderHasBeenDeleted: 'Le rappel a été supprimé',
    DeleteReminder: 'Supprimer le rappel?',
    onDay: 'Le {{dayOfMonth}eme jour }',
    onDayYear: 'Le {{dayOfMonth}} {{month}} ',
    lastDay: "Le dernier jour d'un mois",
    lastDayYear: 'Le dernier jour de {{month}}',
    addRecipients: 'Ajouter des destinataires',
  },
}
