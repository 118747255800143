import { useMemo } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { ElementTooltip, Typography } from '../../atoms'

import formatDate, { formatDistance } from '../../../utils/formatDate'

const DATE_FORMAT = 'dd MMM yyyy'
const TIME_FORMAT = 'HH:mm'
const DEFAULT_I18N = 'activity:newDateFormat'

const ActivityDate = ({
  inline,
  date,
  variant,
  i18n = DEFAULT_I18N,
  showTodayText,
  color,
  ...props
}) => {
  const { t } = useTranslation()
  const dateObj = useMemo(() => new Date(date), [date])
  const rightNow = new Date(date).toDateString() === new Date().toDateString()

  const tooltipBody = useMemo(
    () => (
      <Typography
        variant="body-medium-02"
        i18n={i18n}
        i18nProps={{
          day: formatDate(dateObj, DATE_FORMAT),
          time: formatDate(dateObj, TIME_FORMAT),
        }}
      />
    ),
    [dateObj, i18n],
  )

  return (
    <ElementTooltip tooltip={tooltipBody} options={{ placement: 'top' }} {...props}>
      <Typography color={color || 'secondary'} variant={variant || 'body-large-02'}>
        {rightNow && showTodayText ? t('activity:today') : formatDistance(dateObj, new Date())}
      </Typography>
    </ElementTooltip>
  )
}

export default styled(ActivityDate)`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'inherit')};
`
