import styled from 'styled-components'
import MenuItem from './MenuItem'
import { MenuGroupStyle } from './MenuGroupStyle'
import Flex from '../Flex'
import Typography from '../Typography'

const MenuGroup = ({ data, width, className }) => {
  const { header, headerI18n, items, type } = data

  return (
    <MenuGroupStyle className={className}>
      {(header || headerI18n) && (
        <Header self="stretch">
          <Typography i18n={headerI18n} variant="header-small" color="tertiary">
            {header}
          </Typography>
        </Header>
      )}
      {items.map((item) => (
        <MenuItem key={item.id} data={item} onClick={item.onClick} width={width} type={type} />
      ))}
    </MenuGroupStyle>
  )
}

const Header = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.semantic.space['space-4x-small']} ${theme.semantic.space['space-2x-small']}`};

  ${Typography} {
    flex: 1 0 0;
  }
`

export default styled(MenuGroup)``
