import MenuItem from '../MenuItem'

const NewFolderButton = ({ onClick, visible }) => {
  if (!visible) {
    return null
  }
  return (
    <MenuItem
      offset={1}
      value="New folder"
      i18n="workspace:NewFolder"
      icon="plus"
      onClick={onClick}
    />
  )
}

export default NewFolderButton
