/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import { API_URL } from './const'
import { logout } from '../../actions/logout'
import getToken from '../../store/TokenExtra'

function uploadFile({
  endpoint,
  method = 'GET',
  body,
  accessToken = getToken(),
  accessName = 'Bearer',
  acceptType,
  uploadProgress,
}) {
  const xhr = new XMLHttpRequest()

  xhr.open(method, `${API_URL}/${endpoint}`)
  xhr.setRequestHeader('Authorization', accessToken ? `${accessName} ${accessToken}` : '')
  xhr.setRequestHeader('Accept', acceptType || '*/*')
  xhr.upload.addEventListener('progress', (e) => uploadProgress(e.loaded))
  xhr.send(body)

  return new Promise((resolve, reject) => {
    xhr.onload = () => {
      if (xhr.status == 401) {
        const { dispatch } = window.reduxStore
        dispatch(logout())
      }
      if (xhr.status >= 400) {
        return reject({ xhr })
      }

      return resolve(JSON.parse(xhr.response))
    }

    xhr.onerror = () => reject({ xhr })
  })
}

export default uploadFile
