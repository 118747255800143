import styled, { useTheme } from 'styled-components'
import React, { useMemo } from 'react'
import Typography from '../Typography'
import SvgMask from '../SvgMask'
import Flex from '../Flex'
import Toggle from '../Toggle'
import { MenuGroupStyle } from './MenuGroupStyle'
import Popup from './Popup'
import { PersonaDetails } from '../../molecules'

const MenuItem = ({ data, width, type, className }) => {
  const theme = useTheme()

  const { icon, actions, selected, disabled, onClick, items } = data

  if (items) {
    return (
      <TooltipContainer
        autoHeight
        width={width}
        enabled
        tooltip={
          <MenuGroupStyle width={width}>
            {items.map((item) => (
              <MenuItem key={item.id} data={item} onClick={item.onClick} />
            ))}
          </MenuGroupStyle>
        }
        options={{
          placement: 'right-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-4, 4],
              },
            },
          ],
        }}
        subTooltip
      >
        <Container disabled={disabled}>
          {icon && <SvgMask icon={icon} color={theme.semantic.palette.icon['icon-secondary']} />}
          <Label data={data} type={type} />
          {actions && <Toggle checked={actions.state} />}
          {selected && <SvgMask icon="checkmark" />}
          <SvgMask
            icon="chevron-small-right"
            color={theme.semantic.palette.icon['icon-secondary']}
          />
        </Container>
      </TooltipContainer>
    )
  }

  return (
    <Container onClick={onClick} disabled={disabled} className={className}>
      {icon && <SvgMask icon={icon} color={theme.semantic.palette.icon['icon-secondary']} />}
      <Label data={data} type={type} />
      {actions && <Toggle checked={actions.state} />}
      {selected && <SvgMask icon="checkmark" />}
    </Container>
  )
}

const Label = ({ type, data }) => {
  const { label, labelI18n, description, descriptionI18n, disabled, selected } = data

  const renderColor = useMemo(() => {
    if (disabled) return 'disabled'
    if (selected) return 'primary'
    return 'secondary'
  }, [disabled, selected])

  if (type === 'persona') {
    const { firstName, lastName, emailAddress } = data
    return (
      <Flex direction="column" align="flex-star" flex="1 0 0">
        <PersonaDetails
          firstName={firstName}
          lastName={lastName}
          size="medium"
          seed={emailAddress}
          labels={[emailAddress]}
          disabled={disabled}
        />
      </Flex>
    )
  }
  return (
    <Content direction="column" align="flex-star" flex="1 0 0">
      <Typography i18n={labelI18n} variant="body-large-03" color={renderColor}>
        {label}
      </Typography>
      {(description || descriptionI18n) && (
        <Typography i18n={descriptionI18n} variant="body-medium-02" color="tertiary">
          {description}
        </Typography>
      )}
    </Content>
  )
}

const Content = styled(Flex)`
  ${Typography} {
    display: -webkit-box;
    align-self: stretch;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Container = styled.div`
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  display: flex;
  padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
  align-items: center;
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-x-small']};
  background: ${({ theme }) => theme.semantic.palette.surface.surface};
  align-self: stretch;
  box-sizing: border-box;

  &:hover {
    background: ${({ theme }) =>
      theme.component.button.surface['button-secondary-color-surface-hover']};

    ${Content} ${Typography} {
      color: ${({ theme }) => theme.semantic.palette.text.text};
    }
  }
`

const TooltipContainer = styled(Popup)`
  width: 100%;
`

export default styled(MenuItem)``
