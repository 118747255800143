import { createAction } from '@reduxjs/toolkit'

export const toggleSidebar = createAction('application/sidebar/toggle')
export const toggleInviteParticipantsModal = createAction(
  'application/InviteParticipantsModal/toggle',
)

export const toggleSigning = createAction('application/signing/toggle')

export const setLoggedInAsOldAccout = createAction('application/logged-in/account-old')
export const setLoggedInAsNewAccout = createAction('application/logged-in/account-new')
export const toggleUnloadLock = createAction('application/unload-lock/toggle')

export const enableDebugger = createAction('application/debugger')
export const debuggerRedirect = createAction('application/debugger/redirect')

export const toggleLoadingEncryption = createAction('application/encryption-data/toggle')
export const toggleDocumentView = createAction('application/document-view')
export const toggleDocumentViewStatus = createAction('application/document-status')

export const setLoggedIn = createAction('application/logged-in/toggle')
export const setProduktlyInit = createAction('application/Produktly/toggle')
export const enableNavigation = createAction('application/leftSidebar')
