export default {
  en: {
    archive: 'Archive',
    modalQuestion: 'Archive contract?',
    modalQuestion_other: 'Archive {{count}} contracts?',
    modalDescription: 'This contract will be still available in the Archive.',
    modalDescription_other: 'This contract will be still available in the Archive.',
  },
  de: {
    archive: 'Archiv',
    modalQuestion: 'Vertrag archivieren?',
    modalQuestion_other: '{{count}} Verträge archivieren ?',
    modalDescription: 'Der Vertrag wird im Archiv verfügbar sein.',
    modalDescription_other: 'Die Verträge werden weiterhin im Archiv verfügbar sein.',
  },
  fr: {
    archive: 'Archive',
    modalQuestion: 'Archiver le contrat?',
    modalQuestion_other: 'Archiver {{count}} contrat?',
    modalDescription: 'Ce contrat sera toujours disponible dans les Archives.',
    modalDescription_other: 'Ce contrat sera toujours disponible dans les Archives.',
  },
}
