import JsSHA from 'jssha'
import memoize from 'lodash/memoize'

const SHA_VARIANT = 'SHA3-256'
const SHA_INPUT_FORMAT = 'TEXT'
const HASH_FORMAT = 'UINT8ARRAY'

export const getRandomColor = memoize((seed, size = 16) => {
  const shaObj = new JsSHA(SHA_VARIANT, SHA_INPUT_FORMAT)
  shaObj.update(seed)
  const hash = shaObj.getHash(HASH_FORMAT)
  const color = hash.reduce((a, b) => a + b, 0) % size || 0
  return color
})
