import ActivityTypes from '../components/molecules/Activity/ActivityTypes'
import TemplateOfType from '../components/molecules/Activity/TemplateOfType'

export default {
  en: {
    [ActivityTypes.CONTRACT_CREATED]: `<span>{{userName}}</span> created a contract`,
    [ActivityTypes.INVITED_VIEW]: `<span>{{userName}}</span> was invited to the contract`,
    [ActivityTypes.INVITED_SIGN]: `<span>{{userName}}</span> was invited to sign a contract`,
    newDateFormat: '{{day}} at {{time}}',
    onlyDateFormat: '{{day}}',
    today: 'today',
    change_owner:
      '<span>{{userName}}</span> changed $t(attributes:{{resourceType}}) $t(attributes:{{attribute}}) from <span>{{oldValue}}</span> to <span>{{newValue}}</span>',
    [TemplateOfType.ADD]:
      '<span>{{userName}}</span> added the $t(attributes:{{attribute}}) <span>{{newValue}}</span> to the $t(attributes:{{resourceType}})',
    change:
      '<span>{{userName}}</span> changed $t(attributes:{{resourceType}}) $t(attributes:{{attribute}}) from <span>$t(attributes:{{oldValue}})</span> to <span>$t(attributes:{{newValue}})</span>',
    change_title:
      '<span>{{userName}}</span> changed $t(attributes:{{resourceType}}) $t(attributes:{{attribute}}) of <span>{{resourceLabelPath}}</span> from <span>$t(attributes:{{oldValue}})</span> to <span>$t(attributes:{{newValue}})</span>',
    [TemplateOfType.REMOVE]:
      '<span>{{userName}}</span> removed the $t(attributes:{{attribute}}) <span>{{oldValue}}</span> from the $t(attributes:{{resourceType}})',
    [TemplateOfType.DM_ARCHIVED]:
      '<span>{{userName}}</span> archived the document <span>{{resourceLabelPath}}</span>',
    [TemplateOfType.DM_SIGNED]:
      '<span>{{userName}}</span> signed the document <span>{{resourceLabelPath}}</span>',
  },

  de: {
    [ActivityTypes.CONTRACT_CREATED]: `<span>{{userName}}</span> hat den Vertrag erstellt`,
    [ActivityTypes.INVITED_VIEW]: `<span>{{userName}}</span> wurde zum Vertrag eingeladen`,
    [ActivityTypes.INVITED_SIGN]: `<span>{{userName}}</span> war eingeladen, den Vertrag zu unterzeichnen`,
    newDateFormat: '{{day}} um {{time}}',
    onlyDateFormat: '{{day}}',
    today: 'heute',
    change_owner:
      '<span>{{userName}}</span> hat $t(attributes:{{attribute}}) von $t(attributes:{{resourceType}}) <span>{{resourceLabelPath}}</span> von <span>{{oldValue}}</span> auf <span>{{newValue}}</span> geändert',
    [TemplateOfType.ADD]:
      '<span>{{userName}}</span> hat $t(attributes:{{attribute}}) <span>{{newValue}}</span> zu $t(attributes:{{resourceType}}) hinzugefügt',
    change:
      '<span>{{userName}}</span> hat $t(attributes:{{attribute}}) von $t(attributes:{{resourceType}}) <span>{{resourceLabelPath}}</span> von <span>$t(attributes:{{oldValue}})</span> auf <span>$t(attributes:{{newValue}})</span> geändert',
    change_title:
      '<span>{{userName}}</span> hat $t(attributes:{{attribute}}) von $t(attributes:{{resourceType}}) <span>{{resourceLabelPath}}</span> von <span>$t(attributes:{{oldValue}})</span> auf <span>$t(attributes:{{newValue}})</span> geändert',
    [TemplateOfType.REMOVE]:
      '<span>{{userName}}</span> hat $t(attributes:{{attribute}}) <span>{{oldValue}}</span> aus $t(attributes:{{resourceType}}) gelöscht',
    [TemplateOfType.DM_ARCHIVED]:
      '<span>{{userName}}</span> hat das Dokument <span>{{resourceLabelPath}}</span> archiviert',
    [TemplateOfType.DM_SIGNED]:
      '<span>{{userName}}</span> hat das Dokument <span>{{resourceLabelPath}}</span> signiert',
  },
  fr: {
    [ActivityTypes.CONTRACT_CREATED]: `<span>{{userName}}</span> a créé un contrat`,
    [ActivityTypes.INVITED_VIEW]: `<span>{{userName}}</span> a été invité au contrat`,
    [ActivityTypes.INVITED_SIGN]: `<span>{{userName}}</span> a été invité à signer un contrat`,
    newDateFormat: '{{day}} à {{time}}',
    onlyDateFormat: '{{day}}',
    today: "aujourd'hui",
    [TemplateOfType.ADD]:
      '<span>{{userName}}</span> a ajouté le $t(attributes:{{attribute}}) <span>{{newValue}}</span> au $t(attributes:{{resourceType}})',
    change:
      '<span>{{userName}}</span> a changé le $t(attributes:{{resourceType}}) $t(attributes:{{attribute}}) à partir de <span>$t(attributes:{{oldValue}})</span> à <span>$t(attributes:{{newValue}})</span>',
    change_title:
      '<span>{{userName}}</span> a changé le $t(attributes:{{resourceType}}) $t(attributes:{{attribute}}) de <span>{{resourceLabelPath}}</span> à partir de <span>$t(attributes:{{oldValue}})</span> à <span>$t(attributes:{{newValue}})</span>',
    [TemplateOfType.REMOVE]:
      '<span>{{userName}}</span> à supprimer le $t(attributes:{{attribute}}) <span>{{oldValue}}</span> à partir de $t(attributes:{{resourceType}})',
    [TemplateOfType.DM_ARCHIVED]:
      '<span>{{userName}}</span> a archivé le document <span>{{resourceLabelPath}}</span>',
    [TemplateOfType.DM_SIGNED]:
      '<span>{{userName}}</span> a signé le document <span>{{resourceLabelPath}}</span> signiert',
  },
}
