import { createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../../utils/callApi'
import { updateProofs } from '../../services/proofOutdated/updateProofs'
import { createNotification } from '../notifications'
import i18n from '../../i18n'

const undraftDigitalAsset = createAsyncThunk(
  'digitalassets/undraft',
  async ({ id, callback }, api) => {
    const userpass = localStorage.getItem('user_secret')
    const address = localStorage.getItem('user_address')

    const response = await callApi({
      endpoint: `digitalassets/${id}/undraft`,
      method: 'PUT',
      acceptType: 'application/json',
      applicationJson: true,
    })

    api.dispatch(
      createNotification({
        value: i18n.t('ContractCreated'),
        state: 'success',
      }),
    )

    updateProofs({ data: response, address, userpass, callback })
  },
)

export default undraftDigitalAsset
