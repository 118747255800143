import styled from 'styled-components'

import Typography from '../Typography'
import Flex from '../Flex'
import Button from '../Button'

import { useResponsive } from '../../../hooks'

const BaseHeader = ({
  i18n,
  children,
  onClose,
  disableCloseButton,
  rightColumn,
  type,
  paddingBottom,
  ...props
}) => {
  let render = children

  if (typeof children === 'string' || i18n) {
    render = (
      <Typography i18n={i18n} variant="header-large">
        {children}
      </Typography>
    )
  }

  return (
    <div {...props}>
      <TopHeaderColumn width="100%">
        {render}
        {rightColumn}
        {!disableCloseButton && (
          <Button
            type="icon"
            icon="xmark"
            onClick={onClose}
            tertiary
            data-test="button_modal_close"
            width="fit-content"
          />
        )}
      </TopHeaderColumn>
    </div>
  )
}

const TopHeaderColumn = styled(Flex)`
  gap: ${({ theme }) => theme.semantic.space['space-small']};
  flex-wrap: nowrap;
  > ${Typography} {
    &:first-child {
      flex: auto;
    }
  }
`

export const Header = styled(BaseHeader).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding: ${({ $isGreaterOrEqualThan, theme }) =>
    $isGreaterOrEqualThan('md')
      ? theme.semantic.space['space-medium']
      : theme.semantic.space['space-medium']};
  padding-bottom: ${({ paddingBottom }) => !paddingBottom && 0};
`
