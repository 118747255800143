import { useLocation } from 'react-router-dom'

const useLocator = (locations) => {
  const { pathname } = useLocation()
  const innerLocations = Array.isArray(locations) ? locations : [locations]

  return innerLocations.map((l) => pathname.indexOf(l) !== -1)
}

export default useLocator
