import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../utils/callApi'
import { updateIncluded } from './included'

export const getTasks = createAsyncThunk('tasks/get', async ({ daId, callback }, api) => {
  const tasks = await callApi({
    endpoint: `tasks?filter[digitalAsset.id]=${daId}&sort=created&include=assignees,attachments`,
  })
  const result = {}
  result[daId] = [...tasks.data]
  api.dispatch(updateIncluded(tasks.data))
  return { ...result }
})
