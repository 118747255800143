import { createReducer } from '@reduxjs/toolkit'

import { loadInitialReducerState } from '../store/persist'
import { getTasks } from '../actions/tasks'

const initialState = {}
const reducer = createReducer(
  {
    ...initialState,
    ...loadInitialReducerState('tasks', 'inhubber-redux'),
  },
  (builder) => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      const { payload } = action
      return { ...state, ...payload }
    })
  },
)

export default reducer
