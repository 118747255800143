/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit'

import { loadInitialReducerState } from '../store/persist'
import {
  changeFields,
  changeFieldsOrder,
  registerTable,
  setTableSort,
  toggleDateSwitch,
} from '../actions/table'

const initialState = {
  ...loadInitialReducerState('table', 'inhubber-redux'),
}

const onTableFieldsChange = (state, { payload: { id, fieldId, value } }) => {
  state[id] = {
    ...state[id],
    fields: {
      ...(state[id].fields || {}),
      [fieldId]: value || false,
    },
  }

  // remove sorting if field that is selected for sorting was removed
  if (
    !value &&
    (state[id]?.sort?.request === `-${fieldId}` ||
      state[id]?.sort?.request === `+${fieldId}` ||
      state[id]?.sort?.request === fieldId)
  ) {
    state[id].sort.request = ''
    state[id].sort.sortDirection = ''
  }
}

const onTableFieldsOrderChange = (state, { payload: { id, order } }) => {
  return { ...state, [id]: { ...state[id], fieldsOrder: order } }
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(registerTable.type, (state, action) => {
      const {
        payload: { id, data },
      } = action
      const updatedState = { ...state }

      if (!updatedState[id]) {
        updatedState[id] = { id, data }
      } else {
        updatedState[id] = { ...updatedState[id], id, data }
      }

      return updatedState
    })
    .addCase(setTableSort.type, (state, action) => {
      const { payload } = action
      return {
        ...state,
        [payload.id]: { ...state[payload.id], sort: payload },
      }
    })
    .addCase(toggleDateSwitch.type, (state, action) => {
      const { payload } = action
      return {
        ...state,
        [payload.id]: { ...state[payload.id], date: payload.date },
      }
    })
    .addCase(changeFields.type, onTableFieldsChange)
    .addCase(changeFieldsOrder.type, onTableFieldsOrderChange)
})

export default reducer
