import { useRef, useEffect, useState, useCallback } from 'react'
import { fabric } from 'fabric'
import styled, { useTheme } from 'styled-components'
import trimCanvas from 'trim-canvas'
import { useTranslation } from 'react-i18next'

import { Spacing, Flex, Typography, Modal, SvgMask, Divider, Button } from '../../../atoms'

import { OVERLAY_HEIGHT } from './const'

const CANVAS_WIDTH = 504
const CANVAS_HEIGHT = 240

const SignatureModal = ({ visible, onChange, ...props }) => {
  const ref = useRef()
  const canvasElRef = useRef()
  const { palette } = useTheme()
  const { t } = useTranslation()

  const [isInInitialState, setInInitialState] = useState(true)

  const onMouseDown = useCallback(() => setInInitialState(false), [])
  const onClear = useCallback(() => {
    ref.current.clear()
    setInInitialState(true)
  }, [])

  useEffect(() => {
    if (!visible) {
      return
    }

    setInInitialState(true)

    setTimeout(() => {
      const fabricCanvas = new fabric.Canvas('canvas')

      fabricCanvas.isDrawingMode = true
      fabricCanvas.freeDrawingBrush.color = palette.type.primary
      fabricCanvas.freeDrawingBrush.width = 4
      fabricCanvas.renderAll()

      ref.current = fabricCanvas
    }, 42)
  }, [palette.type.primary, visible])

  const onAdd = useCallback(() => {
    if (typeof onChange !== 'function') {
      return
    }

    const FONT_SIZE = 36

    const result = window.document.createElement('canvas')
    result.width = canvasElRef.current.width
    result.height = OVERLAY_HEIGHT * 3

    const ctx = result.getContext('2d')
    ctx.font = `${FONT_SIZE}px Inter`
    ctx.fillStyle = 'black'
    ctx.fillText('Signed with INHUBBER by:', 0, FONT_SIZE * 0.75)

    const signature = trimCanvas(canvasElRef.current)
    ctx.drawImage(
      signature,
      0,
      0,
      signature.width,
      signature.height,
      0,
      FONT_SIZE,
      result.width,
      result.height - FONT_SIZE,
    )

    onChange(result)
  }, [onChange])

  return (
    <Modal
      visible={visible}
      header="Add your signature"
      buttons={
        <Button disabled={isInInitialState} onClick={isInInitialState ? undefined : onAdd}>
          Add signature
        </Button>
      }
      {...props}
    >
      <Spacing size="8" />
      {/* <Flex content="center">Draw Type</Flex>
      <Spacing size="16" /> */}
      <BackgroundDiv onMouseDown={onMouseDown}>
        <canvas width={CANVAS_WIDTH} height={CANVAS_HEIGHT} id="canvas" ref={canvasElRef} />
        <Overlay>
          {isInInitialState ? (
            <>
              <Spacing size="98" />
              <SvgMask icon="signature" color={palette.background.secondary} />
              <Spacing size="8" />
              <Typography variant="14" color={palette.background.secondary}>
                Use the mouse cursor to draw
              </Typography>
              <Spacing size="74" />
            </>
          ) : (
            <>
              <ClearButton onClick={onClear}>Clear</ClearButton>
              <Spacing size="216" />
            </>
          )}
          <Divider color={palette.type.tertiary} />
        </Overlay>
      </BackgroundDiv>
      <Spacing size="16" />
      <JustifiedTypography>
        By clicking Add signature, I agree that the signature will be the electronic representation
        of my signature for all purposes when I use them on documents, including legally binding
        contracts — just the same as a pen-and-paper signature.
      </JustifiedTypography>
    </Modal>
  )
}

const BackgroundDiv = styled.div`
  background: ${({ theme }) => theme.palette.background.tertiary};
  height: ${CANVAS_HEIGHT}px;
`

const JustifiedTypography = styled(Typography).attrs(() => ({ variant: '12', color: 'secondary' }))`
  display: inline-block;
  text-align: justify;
`

const Overlay = styled(Flex)`
  padding: 0 24px;
  position: relative;
  width: ${CANVAS_WIDTH}px;
  top: -${CANVAS_HEIGHT}px;
  height: ${CANVAS_HEIGHT}px;
  margin-bottom: -${CANVAS_HEIGHT}px;
  box-sizing: border-box;

  flex-direction: column;
  pointer-events: none;
`

const ClearButton = styled(Button).attrs(() => ({ tertiary: true, width: 'fit-content' }))`
  pointer-events: all;
  position: absolute;
  right: 24px;
  top: 8px;
`

export default SignatureModal
