import PropTypes from 'prop-types'
import React, { Fragment, useCallback, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import get from 'lodash/get'

import { Divider, Spacing, SvgMask, Typography } from '../../atoms'
import { ActivityTypes } from './generate'
import ActivityDate from './ActivityDate'
import Comment from './Comment'
import ShowMore from './ShowMore'
import TemplateOfType from './TemplateOfType'

const icons = {
  [ActivityTypes.CONTRACT_CREATED]: ['doc-text-checkmark'],
  [ActivityTypes.INVITED_VIEW]: ['person-plus'],
  [ActivityTypes.INVITED_SIGN]: ['person-plus'],
  [ActivityTypes.CONTRACT_SIGNED]: ['doc-small-text-checkmark-circle'],
  [TemplateOfType.CHANGE]: {
    ownedBy: ['person-arrow-down-arrow-up'],
    type: ['doc-text-arrow-down-arrow-up'],
  },
  [TemplateOfType.REMOVE]: ['doc-text-xmark'],
  [TemplateOfType.DM_ARCHIVED]: ['doc-text-archivebox'],
  [TemplateOfType.DM_SIGNED]: ['doc-text-checkmark'],
  [TemplateOfType.ADD]: ['doc-text-plus'],
}

const ActivityGroup = ({ activityGroup = [], label, ...props }) => {
  const theme = useTheme()

  const [hideActivity, setHideActivity] = useState(true)

  const elementsToDisplay =
    activityGroup.length > 5 && hideActivity
      ? [
          ...activityGroup.slice(0, 1),
          { type: ActivityTypes.SHOW_MORE },
          ...activityGroup.slice(activityGroup.length - 3),
        ]
      : activityGroup

  const mapActivity = useCallback(
    (activity, i) => {
      const { attributeType, type } = activity

      if (type === ActivityTypes.COMMENT) {
        return <Comment {...activity} key={activity.id} />
      }

      if (type === ActivityTypes.SHOW_MORE) {
        return <ShowMore onClick={() => setHideActivity(false)} key="show-more" />
      }

      if (!icons[type]) {
        return null
      }

      const iconColor = 'type.tertiary'

      const icon = icons[type]

      return (
        <ActivityContainer key={activity.id}>
          <SvgMaskContainer>
            <SvgMask
              size="16"
              icon={icon[attributeType] || icon}
              color={get(theme.palette, iconColor)}
            />
          </SvgMaskContainer>
          <Action>
            <Typography variant="body-large-02">{activity.view()}</Typography>
            <Typography variant="body-large-02">・</Typography>
            <ActivityDate variant="body-large-02" date={activity.timestamp} />
          </Action>
        </ActivityContainer>
      )
    },
    [theme.palette],
  )

  return (
    <div {...props}>
      {label && (
        <>
          <Spacing size="16" />
          <Typography color={theme.palette.type.secondary} variant="body 12 regular">
            {label}
          </Typography>
        </>
      )}
      <Spacing size="24" />
      {elementsToDisplay.map(mapActivity)}
      <Divider primary />
    </div>
  )
}

ActivityGroup.propTypes = {
  activityGroup: PropTypes.array,
  label: PropTypes.string,
}

const ActivityContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
`

const SvgMaskContainer = styled.div`
  padding: 2px 4px;
`

const Action = styled.div`
  width: 100%;
  ${Typography} {
    display: inline;
    * {
      display: inline;
    }
  }
  ${ActivityDate} {
    display: inline-block;
  }
`

export default styled(ActivityGroup)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.semantic.space['space-small']};
`
