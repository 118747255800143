export default {
  en: {
    OpenTask: 'Open task',
    CompleteTask: 'Complete task',
    YouCompletedTask: 'You completed task',
    CompletedTask: 'Completed task',
    CompleteTheTask: 'Complete the task',
    YouHaveCompletedTheTask: 'You have completed the task',
    Completed: 'Completed',
    AllAssigneesHave:
      'All assignees have completed the task. Updating the task will automatically complete it.',
    NoPendingTasks: 'No pending tasks',
    TasksAssignedToYou: 'Tasks assigned to you will appear here.',
    TaskHasBeenUpdated: 'Task has been updated',
    CreateApprovalTask: 'Create approval task',
    CreateCustomTask: 'Create custom task',
    CreateReviewTask: 'Create review task',
    ApprovalTask: 'Approval task',
    CustomTask: 'Custom task',
    ReviewTask: 'Review task',
    CreateTask: 'Create task',
    Complete: 'Completed・{{date}}',
    Attachments: 'Attachments',
    AddAsDocument: 'Add as document',
    DocumentAdded: 'Document added',
    AttachmentCount: '{{count}} attachment',
    AttachmentCount_other: '{{count}} attachments',
  },
  de: {
    OpenTask: 'Offene Aufgabe',
    CompleteTask: 'Aufgabe abschließen',
    YouCompletedTask: 'Du hast Aufgabe abgeschlossen',
    CompletedTask: 'Abgeschlossene Aufgabe',
    CompleteTheTask: 'Die Aufgabe abschließen',
    YouHaveCompletedTheTask: 'Du hast die Aufgabe abgeschlossen',
    Completed: 'Abgeschlossen',
    AllAssigneesHave:
      'Alle Verantwortlichen haben die Aufgabe abgeschlossen. Wenn Sie die Aufgabe aktualisieren, wird sie automatisch abgeschlossen.',
    NoPendingTasks: 'Keine ausstehenden Aufgaben',
    TasksAssignedToYou: 'Die Aufgaben, die dir zugewiesen wurden, werden hier angezeigt.',
    TaskHasBeenUpdated: 'Aufgabe wurde aktualisiert',
    CreateApprovalTask: 'Freigabeaufgabe erstellen',
    CreateCustomTask: 'Sonstige Aufgabe erstellen',
    CreateReviewTask: 'Prüfungsaufgabe erstellen',
    ApprovalTask: 'Freigabeaufgabe',
    CustomTask: 'Sonstige Aufgabe',
    ReviewTask: 'Prüfungsaufgabe',
    CreateTask: 'Aufgabe erstellen',
    Complete: 'Abgeschlossen・{{date}}',
    Attachments: 'Anhänge',
    AddAsDocument: 'als Dokument hinzufügen',
    DocumentAdded: 'Dokument hinzugefügt',
    AttachmentCount: '{{count}} Anhang',
    AttachmentCount_other: '{{count}} Anhänge',
  },
  fr: {
    OpenTask: 'Tâche ouverte',
    CompleteTask: 'Tâche complète',
    YouCompletedTask: 'Vous avez terminé la tâche',
    CompletedTask: 'Tâche accomplie',
    CompleteTheTask: 'Terminer la tâche',
    YouHaveCompletedTheTask: 'Vous avez terminé la tâche',
    Completed: 'Terminé',
    UpdateTask: 'Mise à jour de la tâche',
    AllAssigneesHave:
      "Tous les assignés ont terminé la tâche. La mise à jour de la tâche l'achèvera automatiquement.",
    NoPendingTasks: 'Pas de tâches en suspens',
    TasksAssignedToYou: 'Les tâches qui vous sont assignées apparaissent ici.',
    TaskHasBeenUpdated: 'La tâche a été mise à jour',
    CreateApprovalTask: 'Créer une tâche de approbation',
    CreateCustomTask: 'Créer une tâche personaliser',
    CreateReviewTask: 'Créer une tâche de révision',
    ApprovalTask: 'tâche de approbation',
    CustomTask: 'tâche personaliser',
    ReviewTask: 'tâche de révision',
    CreateTask: 'Créer une tâche',
    Complete: 'Terminé・{{date}}',
    Attachments: 'Pièces jointes',
    AddAsDocument: 'ajouter en tant que document',
    DocumentAdded: 'Document ajouté',
    AttachmentCount: '{{count}} pièce jointe',
    AttachmentCount_other: '{{count}} pièces jointes',
  },
}
