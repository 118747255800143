import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { usePopper } from 'react-popper'
import { useDispatch, useSelector } from 'react-redux'
import { useMeasure } from 'react-use'
import { animated, useSpring } from '@react-spring/web'

import { useNavigate } from 'react-router-dom'
import { Divider, Elevation, Flex, ModalOverlay, Spacing, SvgImage, Typography } from '../../atoms'
import { PersonaDetails } from '../../molecules'
import SidebarItem from './SidebarItem'
import ProfileModal from '../ProfileModal'

import { logout } from '../../../actions/logout'
import {
  companyMembershipSelector,
  companySelector,
  identVerifiedSelector,
  selectCurrentUser,
} from '../../../store/selectors'

const ProfileMenu = ({ visible, target, onClose, onLogOut, ...props }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { palette } = useTheme()

  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(target, popperElement, {
    placement: 'top-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
  })

  const currentUser = useSelector(selectCurrentUser)
  const identVerified = useSelector(identVerifiedSelector)
  const company = useSelector(companySelector)
  const companyMembership = useSelector(companyMembershipSelector)

  const { firstName = '', lastName = '', emailAddress = '' } = currentUser || {}

  const [profileModalVisible, setProfileModalVisible] = useState(false)

  const [ref, { height }] = useMeasure()
  const expand = useSpring({
    height: visible ? height : 0,
    transform: visible ? 'translateY(0px)' : 'translateY(30px)',
  })
  const wrapperStyles = useMemo(
    () => ({
      ...styles.popper,
      height: visible ? undefined : 0,
      padding: visible ? undefined : 0,
    }),
    [styles.popper, visible],
  )

  const onInnerClose = useCallback(
    (e) => {
      if (typeof onClose === 'function') {
        onClose(e)
      }
    },
    [onClose],
  )

  const onLogOutClick = useCallback(
    (e) => {
      onInnerClose(e)
      dispatch(logout())
      localStorage.removeItem('redirectTo')
      navigate('/login')
    },
    [dispatch, navigate, onInnerClose],
  )

  const onProfileClick = useCallback(
    (e) => {
      onInnerClose(e)
      setProfileModalVisible(true)
    },
    [onInnerClose],
  )

  const onCompanySettingsClick = useCallback(
    (e) => {
      onInnerClose(e)
      navigate('/company/settings/general')
    },
    [navigate, onInnerClose],
  )

  const isCompanyVisible = companyMembership?.attributes.role === 'admin'

  return (
    <>
      {visible && <ModalOverlay onClick={onClose} />}

      <div ref={setPopperElement} style={wrapperStyles} {...attributes.popper} {...props}>
        <animated.div style={expand}>
          <Elevation variant="small" ref={ref}>
            <PersonaDetails
              size="medium"
              firstName={firstName}
              lastName={lastName}
              labels={[emailAddress]}
              seed={emailAddress}
              verified={identVerified}
              verifiedPosition="persona"
            />
            <Spacing size={8} />
            <Divider />
            <Spacing size={8} />
            <SidebarItem
              value="Profile"
              icon="person"
              variant="14"
              onClick={onProfileClick}
              iconSize="16"
              i18n="Profile"
              color={palette.type.primary}
              data-test="button_sidebar_profile_menu_account"
            />
            <Spacing size={8} />
            <Divider />
            {isCompanyVisible && (
              <>
                <Spacing size={12} />
                <Flex>
                  <SvgImage icon="company_logo" size="32" />
                  <Spacing size="12" />
                  <Typography variant="14 medium">{company?.attributes?.name}</Typography>
                </Flex>
                <Spacing size={12} />
                <SidebarItem
                  value="Company settings"
                  icon="gear"
                  variant="14"
                  iconSize="16"
                  i18n="sidebar:companySettings"
                  color={palette.type.primary}
                  data-test="button_sidebar_profile_menu_company_settings"
                  onClick={onCompanySettingsClick}
                />
                <Spacing size={8} />
                <Divider />
              </>
            )}
            <Spacing size={8} />
            <SidebarItem
              iconSize="16"
              icon="rectangle-portrait-and-arrow-right"
              i18n="Log_Out"
              value="Log Out"
              variant="14"
              color={palette.type.primary}
              onClick={onLogOutClick}
              data-test="button_sidebar_profile_menu_logout"
            />
          </Elevation>
        </animated.div>
        <Spacing size="12" />
      </div>

      <ProfileModal visible={profileModalVisible} onClose={() => setProfileModalVisible(false)} />
    </>
  )
}

ProfileMenu.propTypes = {
  onClose: PropTypes.func,
  target: PropTypes.any,
  visible: PropTypes.bool,
}

export default styled(ProfileMenu)`
  z-index: 100;
  padding: 16px;
  margin-left: -16px;
  overflow: hidden;

  ${Elevation} {
    padding: 12px;
    width: 360px;
  }

  ${PersonaDetails} {
    cursor: default;

    div${Typography} {
      max-width: 292px;
    }
  }
`
