import { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import { useParams } from 'react-router-dom'

import { createSelector } from 'reselect'
import { Divider } from '../../atoms'
import ActivityGroup from './ActivityGroup'

import { generateFromAccessGrants, generateFromCreatedBy, generateFromTemplate } from './generate'
import {
  selectComments,
  selectDigitalAssets,
  selectIncluded,
  selectSharepoint,
} from '../../../store/selectors'

const ActivityStream = ({ ...props }) => {
  const { id } = useParams()

  const selectData = createSelector(
    [selectDigitalAssets, selectIncluded, selectSharepoint, selectComments],
    (digitalAssets, included, sharepoint, comments) => {
      const { asset } = digitalAssets.details[id]

      const sharepointUsers = sharepoint.naturalPersons || []
      const createdById = asset?.relationships?.createdBy.data.id

      const accessGrantIds = asset.relationships?.accessGrants.data.map((i) => i.id) || []
      const accessGrants = compact(accessGrantIds.map((id) => included.accessgrants[id]))

      const documents = asset.relationships?.documents.data || []
      const documentsActivitiesIds =
        compact(
          flatten(
            documents.map((i) => included.documentsmetadata[i.id]?.relationships?.activities?.data),
          ),
        ) || []
      const documentsActivities =
        compact(documentsActivitiesIds.map(({ id }) => included.activities[id])) || []

      const activities = asset?.relationships?.activities.data || []
      const activitiesMap = activities.map(({ id }) => included.activities[id]) || []

      const naturalPersonIds = accessGrants.map((a) => a.relationships.grantedTo.data.id)
      const naturalPersons = naturalPersonIds.map((id) => included.naturalpersons[id])

      const templateActivitiesArray = [...activitiesMap, ...documentsActivities]

      return {
        digitalAsset: asset,
        accessGrants,
        naturalPersons,
        sharepointEmails: sharepointUsers.map((user) => user.email),
        contractComments: comments.data,
        createdBy: createdById ? included.naturalpersons[createdById] : null,
        activitiesPersons: templateActivitiesArray.map(
          (i) => included.naturalpersons[i.relationships?.executor.data.id],
        ),
        documentsmetadata: included.documentsmetadata,
        templateActivities: templateActivitiesArray,
      }
    },
  )
  const {
    digitalAsset,
    accessGrants,
    naturalPersons,
    sharepointEmails,
    contractComments,
    createdBy,
    activitiesPersons,
    documentsmetadata,
    templateActivities,
  } = useSelector(selectData)

  const { created } = digitalAsset?.attributes || {}

  const activityLog = useMemo(
    () =>
      orderBy(
        [
          ...generateFromAccessGrants({
            accessGrants,
            naturalPersons,
            sharepointEmails,
            createdById: createdBy.id,
          }),
          generateFromCreatedBy({ createdBy, created }),
          ...contractComments,
          ...generateFromTemplate({
            activities: templateActivities,
            activitiesPersons,
            documentsmetadata,
          }),
        ],
        'date',
        'asc',
      ),
    [
      accessGrants,
      naturalPersons,
      sharepointEmails,
      createdBy,
      created,
      contractComments,
      templateActivities,
      activitiesPersons,
      documentsmetadata,
    ],
  )

  return (
    <div data-test="activityStream_contractDetails" {...props}>
      <ActivityGroup activityGroup={activityLog} />
    </div>
  )
}

export default styled(ActivityStream)`
  overflow-x: hidden;
  overflow-y: auto;

  ${ActivityGroup}:last-child {
    > ${Divider} {
      display: none;
    }
  }
`
