import { createReducer } from '@reduxjs/toolkit'

import { selectCustomField } from '../actions/documents'

const initialData = {
  focused: null,
}

const reducer = createReducer({ ...initialData }, (builder) => {
  builder.addCase(selectCustomField, (state, action) => {
    return {
      ...state,
      focused: action.payload.focused ? action.payload.id : null,
    }
  })
})

export default reducer
