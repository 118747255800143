import { createReducer } from '@reduxjs/toolkit'

import { fetchUnconmirmedHints } from '../actions/hints'
import { logout } from '../actions/logout'

const initialState = {
  data: [],
  loaded: false,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUnconmirmedHints.fulfilled, (state, action) => {
      const { hints } = action.payload
      return {
        ...state,
        data: hints || [],
        loaded: true,
      }
    })
    .addCase(logout, () => ({ ...initialState }))
})

export default reducer
