import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import MenuItem from './MenuItem'
import WorkspaceDropdown from '../Workspaces/WorkspaceDropdown'
import { Button, Flex, Spinner, SvgMask, WorkspaceIcon } from '../../atoms'
import Folders from './Folders'

import { getRandomColor } from '../../../utils/random'
import { setActiveWorkspace } from '../../../actions/workspaces'
import { usePermissions } from '../../../hooks'
import {
  activeWorkspaceIdSelector,
  getActiveFolder,
  getAllWorkspaces,
} from '../../../store/selectors'

const WorkspaceItem = ({ icon, workspaceId, notHaveFolders, isActive, loading, ...props }) => {
  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState(false)

  const { isPermissionEnabled, PERMISSIONS } = usePermissions({ workspaceId })
  const canCreateFolder = isPermissionEnabled(PERMISSIONS.WORKSPACE_MANAGE_FOLDERS)

  const allWorkspaces = useSelector(getAllWorkspaces)
  const activeWorkspaceId = useSelector(activeWorkspaceIdSelector)
  const activeFolder = useSelector(getActiveFolder)

  const allFolders = allWorkspaces[workspaceId]?.relationships?.folders?.data || []

  const isActiveWorkspace = useMemo(
    () => isActive(null, { pathname: window.location.pathname }),
    [isActive],
  )

  const userHaveAccessToMoreMenu =
    isPermissionEnabled(PERMISSIONS.WORKSPACE_UPDATE) ||
    isPermissionEnabled(PERMISSIONS.WORKSPACE_MANAGE_MEMBERS)

  useEffect(() => {
    if (
      isActiveWorkspace &&
      activeFolder &&
      activeWorkspaceId === workspaceId &&
      activeWorkspaceId
    ) {
      setExpanded(true)
    }
  }, [isActiveWorkspace, activeFolder, activeWorkspaceId, dispatch, isActive, workspaceId])

  const onClick = useCallback(() => {
    dispatch(setActiveWorkspace({ id: workspaceId }))
  }, [dispatch, workspaceId])

  if (loading)
    return (
      <LoadingContainer content="center">
        <Flex>
          <Spinner />
        </Flex>
      </LoadingContainer>
    )
  return (
    <>
      <BaseWorkspaceItem
        id={workspaceId}
        onClick={onClick}
        isActive={isActive}
        menu={userHaveAccessToMoreMenu}
        folders={!notHaveFolders && (canCreateFolder || allFolders?.length > 0)}
        expanded={expanded}
        onChangeExpanded={(state) => setExpanded(state)}
        {...props}
      />
      <Folders visible={expanded} disabled={notHaveFolders} workspaceId={workspaceId} />
    </>
  )
}
const BaseWorkspaceItem = ({
  id,
  icon,
  onClick,
  isActive,
  menu,
  folders,
  expanded,
  onChangeExpanded,
  ...props
}) => {
  const theme = useTheme()

  const onMoreClick = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const onExpandClick = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      onChangeExpanded(!expanded)
    },
    [expanded, onChangeExpanded],
  )

  const iconColor = id ? theme.palette.avatar[getRandomColor(id)] : null
  const iconToDraw = icon || <WorkspaceIcon color={iconColor} />
  const dropdownIcon = expanded ? 'chevron-small-down' : 'chevron-small-right'

  return (
    <Container
      iconBackground
      onClick={onClick}
      icon={iconToDraw}
      tooltip
      isActive={isActive}
      {...props}
    >
      {menu && <WorkspaceDropdown workspaceId={id} enabled onClick={onMoreClick} overlay />}
      {folders && (
        <ExpandButton
          onClick={onExpandClick}
          $display={expanded}
          content="center"
          data-test="button_sidebar_workspace_expand"
        >
          <SvgMask icon={dropdownIcon} />
        </ExpandButton>
      )}
    </Container>
  )
}

const LoadingContainer = styled(Flex)`
  background: ${({ theme }) => theme.semantic.palette.surface['surface-secondary']};
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
  padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
  width: 100%;
  height: 48px;
  box-sizing: border-box;
`

const ExpandButton = styled(Flex)`
  display: ${({ $display }) => ($display ? undefined : 'none')};
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: 0 -4px;
  flex: 24px;
  ${SvgMask} {
    background-color: ${({ theme }) => theme.semantic.palette.icon['icon-secondary']};
  }
`

const Container = styled(MenuItem)`
  ${WorkspaceDropdown} {
    display: none;

    ${Button} {
      min-height: 24px;
      padding: 4px;
      height: 24px;
      &:hover {
        background: transparent;
        ${SvgMask} {
          background-color: ${({ theme }) => theme.palette.type.primary};
        }
      }
    }
  }

  &:hover {
    ${WorkspaceDropdown}, ${ExpandButton} {
      display: flex;
    }
  }
`

export default styled(WorkspaceItem)``
