import styled from 'styled-components'

const Flex = ({
  children,
  content,
  direction,
  align,
  position,
  wrap,
  cursor,
  gap,
  flex,
  ...props
}) => {
  return <div {...props}>{children}</div>
}

export default styled(Flex)`
  display: flex;
  justify-content: ${({ content }) => content || 'flex-start'};
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'center'};
  position: ${({ position }) => position || 'static'};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'inherit')};
  cursor: ${({ cursor }) => cursor || 'inherit'};
  width: ${({ width }) => width || undefined};
  gap: ${({ gap }) => gap || undefined};
  flex: ${({ flex }) => flex || undefined};
  align-self: ${({ self }) => self || undefined};
`
