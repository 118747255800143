import styled, { useTheme } from 'styled-components'

import { Flex, Spacing, Typography } from '../atoms'

const EmptyState = ({
  illustration,
  header,
  headerI18n,
  nonStandardHeader,
  description,
  descriptionI18n,
  nonStandardDescription,
  children,
  actions,
  type,
  width,
  className,
}) => {
  const theme = useTheme()

  if (type === 'special') {
    return (
      <Flex direction="column" width={width || '344px'} className={className}>
        <Typography variant="body-medium-02" i18n={descriptionI18n} color="tertiary">
          {description}
        </Typography>
      </Flex>
    )
  }

  return (
    <Flex
      direction="column"
      width="344px"
      gap={theme.semantic.space['space-medium']}
      className={className}
    >
      {illustration && (
        <Flex>
          <ImgContainer direction="column" content="center">
            <img src={illustration} />
            <Spacing size={theme.semantic.space['space-medium']} />
          </ImgContainer>
        </Flex>
      )}
      <Flex direction="column">
        {nonStandardHeader || (
          <Typography variant="header-medium" i18n={headerI18n}>
            {header}
          </Typography>
        )}
        {nonStandardDescription || (
          <Typography
            variant={type === 'special' ? 'body-medium-02' : 'body-large-02'}
            i18n={descriptionI18n}
            color={type === 'special' ? 'tertiary' : 'secondary'}
          >
            {description}
          </Typography>
        )}
        {actions && (
          <>
            <Spacing space={theme.semantic.space['space-small']} />
            <Flex content="center" width="fit-content">
              {actions}
            </Flex>
          </>
        )}
        {children}
      </Flex>
    </Flex>
  )
}

const ImgContainer = styled(Flex)`
  width: 168px;
  height: 168px;
`

export default styled(EmptyState)`
  padding: ${({ theme, type }) => type === 'special' && theme.semantic.space['space-small']};
  box-sizing: border-box;

  ${Typography} {
    text-align: center;
  }
`
