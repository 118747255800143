import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { createSelector } from 'reselect'
import { Button, Flex, Spacing, Typography } from '../atoms'
import ProfileModal from './ProfileModal'

import PROFILE_TABS from '../../enums/profileTabs'
import { selectAccount, selectCompany } from '../../store/selectors'

const TFAEnforcementBanner = ({ disabled, ...props }) => {
  const [profileModalVisible, setProfileModalVisible] = useState(false)

  const selectDevicesAndSettings = createSelector(
    [selectAccount, selectCompany],
    (account, company) => ({
      devices: account.devices,
      settings: company.settings,
    }),
  )

  const { devices, settings } = useSelector(selectDevicesAndSettings)

  const companySettingsTFA =
    Array.isArray(settings) && settings.find((i) => i.type === 'companysettings2fa')
  const activeDevices =
    Array.isArray(devices) && devices?.find((i) => i.attributes.state === 'active')

  if (!companySettingsTFA || disabled) {
    return null
  }

  return (
    <>
      {!activeDevices && (
        <Flex content="space-between" {...props}>
          <Flex direction="column" align="flex-start">
            <Typography variant="body 14 medium" color="white" i18n="SetUpTwoFactorAuthentication">
              Set up Two-Factor Authentication
            </Typography>
            <Spacing size="4" />
            <Typography
              variant="body 12 regular"
              color="white"
              i18n="ItIsYourCompanyPolicyToSetAuthentication"
            >
              It is your company’s policy to set up a second authentication method to protect your
              account.
            </Typography>
          </Flex>
          <Button
            width="fit-content"
            secondary
            onClick={() => setProfileModalVisible(true)}
            i18n="SetUpTFA"
          >
            Set up 2FA
          </Button>
        </Flex>
      )}
      <ProfileModal
        visible={profileModalVisible}
        defaultTab={PROFILE_TABS.SECURITY}
        onClose={() => setProfileModalVisible(false)}
      />
    </>
  )
}

export default styled(TFAEnforcementBanner)`
  margin-top: auto;
  background-color: ${({ theme }) => theme.palette.accent.warning};
  padding: 16px 36px;
`
