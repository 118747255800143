import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import SvgMask from './SvgMask'
import Typography from './Typography'
import Spacing from './Spacing'
import { semantic } from '../../theme/semantic'

const dictionaries = {
  typography: {
    small: 'body 10 regular',
    big: 'body 10 medium',
  },
  padding: {
    small: '4px 8px',
    big: '5px 8px',
  },
}

const Label = ({ truncate, i18n, value, onClose, ...props }) => {
  return (
    <div {...props} data-test={`label_component-${value}`}>
      <Typography i18n={i18n} variant="body-small-01">
        {value}
      </Typography>
      {onClose && <SvgMask icon="xmark-small" size={16} onClick={onClose} />}
    </div>
  )
}

Label.propTypes = {
  i18n: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.any,
  variant: PropTypes.string,
}

export default styled(Label)`
  padding: ${({ theme }) => theme.semantic.space['space-4x-small']};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-full']};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: ${({ theme }) => theme.semantic.palette.surface.surface};
  min-height: 24px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${({ theme }) => theme.semantic.palette.border['border-secondary']};
    pointer-events: none;
    border-radius: inherit;
  }

  ${({ truncate }) =>
    truncate
      ? `
      max-width: 141px;
      ${Typography} {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }`
      : ''}
  ${Typography} {
    color: ${({ theme }) => theme.semantic.palette.text['text-secondary']};
    padding: 0 ${({ theme }) => theme.semantic.space['space-4x-small']};
  }

  ${SvgMask} {
    background-color: ${({ theme }) => theme.semantic.palette.icon['icon-secondary']};
  }

  &:hover {
    background-color: ${({ theme }) => theme.semantic.palette.surface['surface-hover']};

    ${Typography} {
      color: ${({ theme }) => theme.semantic.palette.text.text};
    }
  }
`
