import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Spinner } from '../atoms'
import { callApi } from '../../utils/callApi'

const ChangeUserWorkspaceRole = ({
  visible,
  onClose,
  workspaceMembershipId,
  newUserRole,
  confirmChanging,
}) => {
  const { t } = useTranslation()
  const [changeAnimation, setChangeAnimation] = useState(false)

  const changeUserAccess = async () => {
    setChangeAnimation(true)
    try {
      const resp = await callApi({
        endpoint: `workspacememberships/${workspaceMembershipId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: {
          data: {
            type: 'workspacememberships',
            attributes: {
              role: newUserRole,
            },
          },
        },
      })
      setChangeAnimation(false)
      confirmChanging()
      onClose()
    } catch (e) {
      setChangeAnimation(false)
      confirmChanging()
      onClose()
    }
  }

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      header={t('workspace:ChangeRole')}
      subHeader={t('workspace:ChangeRoleDesc')}
      type="dialog"
      buttons={
        <>
          <Button i18n="cancel" width="fit-content" secondary onClick={onClose}>
            Cancel
          </Button>
          <Button
            i18n={changeAnimation ? undefined : 'Change'}
            width="fit-content"
            onClick={changeAnimation ? undefined : changeUserAccess}
            data-test="button_changeUserWorkspaceRole_confirm_change"
          >
            {changeAnimation ? <Spinner animation="white" /> : 'Change'}
          </Button>
        </>
      }
    />
  )
}

export default ChangeUserWorkspaceRole
