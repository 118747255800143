import { createReducer } from '@reduxjs/toolkit'
import { v4 } from 'uuid'
import JsSHA from 'jssha'

import { createNotification, removeNotification } from '../actions/notifications'

export { createNotification, removeNotification } from '../actions/notifications'

const reducer = createReducer(
  {
    list: [],
  },
  (builder) => {
    builder
      .addCase(createNotification, (state, action) => {
        const { payload } = action
        const createdAt = new Date()
        const shaObjString = (payload.value || '') + (payload.description || '')

        const shaObj = new JsSHA('SHA3-256', 'TEXT')
        shaObj.update(shaObjString)
        const hash = shaObj.getHash('HEX')

        if (state.list.find((n) => n.hash === hash) && payload.state === 'warning') {
          // there is an active notification with the same message
          // we should not show another one
          return state
        }

        const updatedList = [
          ...state.list,
          {
            lifeLength: 5000,
            id: v4(),
            createdAt: createdAt.toUTCString(),
            hash,
            ...payload,
          },
        ]

        return { ...state, list: updatedList }
      })
      .addCase(removeNotification, (state, action) => {
        const { payload } = action
        const updatedList = state.list.filter((notification) => notification.id !== payload.id)
        return { ...state, list: updatedList }
      })
  },
)

export default reducer
