import styled, { useTheme } from 'styled-components'
import linkify from 'linkify-it'

import Typography from './Typography'
import Spacing from './Spacing'

const RecognizeLinks = ({ title = '', variant, className, ...props }) => {
  const { palette } = useTheme()
  const linkifyText = linkify()

  const parts = []

  let lastIndex = 0

  const links = linkifyText.match(title)

  if (links) {
    links.forEach((link) => {
      parts.push(
        <Typography
          variant={variant || 'body-large-02'}
          {...props}
          key={`non-link-${title.slice(lastIndex, link.index)}`}
        >
          {title.slice(lastIndex, link.index)}
        </Typography>,
      )

      parts.push(
        <Typography
          variant={variant || 'body-large-02'}
          as="a"
          {...props}
          key={link.text}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          cursor="pointer"
          color={palette.theme.primary}
        >
          {link.text}
        </Typography>,
      )

      lastIndex = link.lastIndex
    })
  }

  if (lastIndex < title.length) {
    parts.push(
      <Typography
        variant={variant || 'body-large-02'}
        {...props}
        key={`non-link-${title.slice(lastIndex)}`}
      >
        {title.slice(lastIndex)}
      </Typography>,
    )
  }

  return <div className={className}>{renderGroupItems(parts)}</div>
}

const renderGroupItems = (items = []) => {
  return items.reduce(
    (accu, elem) =>
      accu === null ? [elem] : [...accu, <Spacing size="4" key={`space-${elem.key}`} />, elem],
    null,
  )
}

export default styled(RecognizeLinks)`
  ${({ theme }) => theme.typography['body-large-02']}
  a,
  span,
  div {
    display: inline;
  }
`
