import JsSHA from 'jssha'

import { CALLAPI_CACHE_LENGHT } from '../constans'
import { printf } from '../../hooks/printf'

const PROMISE_STATE = {
  PENDING: 'pending',
  DONE: 'done',
  FAILED: 'failed',
}

const createPromiseCollection = () => {
  const collection = {}
  return {
    addPromise: ({ method, path, promise: originalPromise }) => {
      if (method !== 'GET') {
        return originalPromise
      }

      const timestamp = new Date()
      const key = `${method}-${path}`

      const shaObj = new JsSHA('SHA3-256', 'TEXT')
      shaObj.update(key)
      const hash = shaObj.getHash('HEX')

      const resultPromise = new Promise((resolve, reject) => {
        collection[hash] = {
          state: PROMISE_STATE.PENDING,
          originalPromise,
          method,
          path,
          hash,
          timestamp,
          promises: [{ resolve, reject }],
        }
      })

      originalPromise
        .then((r) => {
          const entity = collection[hash]
          entity.promises.forEach(({ resolve }) => resolve(r))
          entity.state = PROMISE_STATE.DONE
          entity.data = r
        })
        .catch((e) => {
          const entity = collection[hash]
          entity.promises.forEach(({ reject }) => reject(e))
          entity.state = PROMISE_STATE.FAILED
          entity.data = e
        })

      return resultPromise
    },
    // eslint-disable-next-line consistent-return
    isInCollection: ({ method, path }) => {
      if (method !== 'GET') {
        return null
      }

      const timestamp = new Date()
      const key = `${method}-${path}`

      const shaObj = new JsSHA('SHA3-256', 'TEXT')
      shaObj.update(key)
      const hash = shaObj.getHash('HEX')

      const entity = collection[hash]

      if (!entity) {
        return null
      }

      if (timestamp - entity.timestamp > CALLAPI_CACHE_LENGHT) {
        collection[hash] = undefined
        return null
      }

      // eslint-disable-next-line no-console
      printf(() => console.log(`Using saved promise for ${method} ${path}`))

      if (entity.state === PROMISE_STATE.PENDING) {
        const resultPromise = new Promise((resolve, reject) => {
          collection[hash].promises.push({ resolve, reject })
        })

        return resultPromise
      }
      if (entity.state === PROMISE_STATE.FAILED) {
        return Promise.reject(entity.data)
      }
      if (entity.state === PROMISE_STATE.DONE) {
        return Promise.resolve(entity.data)
      }
    },
  }
}

export default createPromiseCollection
