/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import get from 'lodash/get'
import i18n from '../../i18n'
import { createNotification } from '../../actions/notifications'
import { logout } from '../../actions/logout'
import getToken from '../../store/TokenExtra'
import createPromiseCollection from './promiseCollection'
import { getLocation, getNavigate } from '../../history'

export const accept = {
  jsonApi: 'application/vnd.api+json',
  appJson: 'application/json',
  octetStream: 'application/octet-stream',
}

let tempAPI_URL
let tempbase_URL

if (localStorage.getItem('localhost_mode') !== 'true') {
  tempAPI_URL = process.env.REACT_APP_API_ROOT
    ? `${process.env.REACT_APP_API_ROOT}/ui-api`
    : '/ui-api'
  tempbase_URL = `${process.env.REACT_APP_API_ROOT}/`
} else {
  tempAPI_URL = process.env.REACT_APP_API_LOCALROOT
    ? `${process.env.REACT_APP_API_LOCALROOT}/ui-api`
    : '/ui-api'
  tempbase_URL = `${process.env.REACT_APP_API_LOCALROOT}/`
}

export const API_URL = tempAPI_URL
export const base_URL = tempbase_URL

const promises = createPromiseCollection()

function callApi({
  endpoint,
  method = 'GET',
  body,
  applicationJson = true,
  headers = {},
  withoutResult,
  clearHost,
  accessToken = getToken(),
  accessName = 'Bearer',
  acceptType,
  signal,
  retryCount = 0,
  disableDefHeaders,
  disableNotifications,
  disable401Redirect,
  disablePromiseConcatenation,
}) {
  const { dispatch } = window.reduxStore
  const urlPath = clearHost ? endpoint : `${API_URL}/${endpoint}`
  const baseHeaders = {
    'Crnk-Compact': 'true',
    Authorization: accessToken ? `${accessName} ${accessToken}` : '',
    Accept: acceptType || '*/*',
  }

  const useContentType = applicationJson && ['POST', 'PATCH'].includes(method) && !disableDefHeaders

  const savedPromise = disablePromiseConcatenation
    ? undefined
    : promises.isInCollection({ method, path: endpoint })

  const fetchPromise =
    savedPromise ||
    fetch(urlPath, {
      headers: {
        ...baseHeaders,
        ...(useContentType ? { 'Content-Type': accept.jsonApi } : {}),
        ...headers,
      },
      signal: signal || null,
      method,
      body: applicationJson ? JSON.stringify(body) : body,
    })

  const registeredPromise = disablePromiseConcatenation
    ? fetchPromise
    : savedPromise || promises.addPromise({ method, path: endpoint, promise: fetchPromise })

  if (withoutResult) {
    return registeredPromise
  }

  const sessionEnd = (response) => {
    if (response.status == 401) {
      if (!disableNotifications) {
        dispatch(createNotification({ value: i18n.t('yourSession'), state: 'warning' }))
      }
      if (!disable401Redirect) {
        dispatch(logout())
        const navigate = getNavigate()
        const location = getLocation()
        const isAlreadyRedirecting = !!location.state
        const state = isAlreadyRedirecting
          ? location.state
          : { lastLocation: { pathname: location.pathname } }
        navigate('/login', {
          state,
        })
        return true
      }
    }
    return false
  }

  const retryUpdate = () => {
    return new Promise((resolve, reject) => {
      if (
        retryCount < 5 &&
        (body?.data?.attributes?.bcTxId !== undefined ||
          endpoint.includes('documentsmetadata') ||
          endpoint.includes('digitalassets'))
      ) {
        setTimeout(
          () => {
            callApi({
              endpoint,
              method,
              body,
              applicationJson,
              acceptType,
              retryCount: retryCount + 1,
            })
              .then(resolve)
              .catch(reject)
          },
          100 * 2 ** retryCount,
        )
      } else {
        reject(new Error('Max retries reached'))
      }
    })
  }

  return registeredPromise
    .then((response) => {
      if (sessionEnd(response)) {
        throw new Error('Session ended')
      }

      if (response.status == 409 && method === 'PATCH') {
        throw new Error('try later')
      }

      if (response.status !== 204) {
        return response
          .clone()
          .text()
          .then((text) => {
            const json = text ? JSON.parse(text) : {}
            return { json, response }
          })
          .catch((error) => {
            return { json: {}, response }
          })
      }

      return { response }
    })
    .then(({ json, response }) => {
      if (response.status == 502 && !disableNotifications) {
        dispatch(createNotification({ value: i18n.t('ConnectionError'), state: 'warning' }))
      }
      if (json) {
        return response.ok ? json : Promise.reject({ ...json, response })
      }

      return response.statusText
    })
    .catch((error) => {
      if (error.message === 'Session ended') {
        // Handle the case where the session ended
        return Promise.resolve()
      }

      if (error.name === 'AbortError' || signal?.aborted) {
        return Promise.reject(error)
      }

      if (disableNotifications) {
        return Promise.reject(error)
      }

      const errorCode = get(error, 'errors[0].status')

      if (errorCode == 500 && body?.data?.attributes?.bcTxId !== undefined) {
        return Promise.reject(error)
      }

      if (error == 'TypeError: Failed to fetch' || errorCode == 502) {
        dispatch(createNotification({ value: i18n.t('ConnectionError'), state: 'warning' }))
      } else if (errorCode == 409 || error.message === 'try later') {
        return retryUpdate()
          .then((data) => {
            return Promise.resolve(data)
          })
          .catch(() => {
            dispatch(createNotification({ value: i18n.t('UnexpectedError'), state: 'warning' }))
            return Promise.reject(error)
          })
      } else if (errorCode != 401) {
        dispatch(createNotification({ value: i18n.t('UnexpectedError'), state: 'warning' }))
      }

      return Promise.reject(error)
    })
}

export default callApi
