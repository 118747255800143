// convert callback based functions to async/await
const asyncify = (func, ...args) => {
  return new Promise((resolve) => {
    const funcArgs = [...args, (...responseArgs) => resolve(responseArgs)]
    func(...funcArgs)
  })
}

export const readAsDataURL = (fileToReader) => {
  const reader = new FileReader()

  return new Promise((resolve) => {
    reader.onload = resolve
    reader.readAsDataURL(fileToReader)
  })
}

export const readAsText = (file) => {
  const reader = new FileReader()

  return new Promise((resolve) => {
    reader.onloadend = () => resolve(reader.result)
    reader.readAsText(file)
  })
}

export default asyncify
