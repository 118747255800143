import documentType from '../hooks/documentType'

export default {
  en: {
    SelectWhetherYouWant:
      'Select whether you want to add files to be signed, already signed files or other files not requiring an e-signature.',
    TheContractStatus: 'The contract status will change to Pending',
    ByAddingNewDocuments:
      'By adding new documents for e-signature, the contract status will change to Pending.',
    [documentType.e_signature]: 'E-signature',
    AFileRequiringAnESignature:
      'A file requiring an e-signature. It can be a contract document, agreement or any attachment.',
    [documentType.signed_offline]: 'Signed offline',
    AnOfflineSignedFile: 'An offline signed file that doesn’t require an e-signature.',
    [documentType.other]: 'Other',
    AnyOtherRelatedFile: 'Any other related file.',
    exceededSizeLimit: 'Exceeded limit: some of the files bigger than 50 MB',
    qesOnlyPdf: 'Invalid format: only PDF documents allowed',
    exceededLimit: 'Exceeded limit: only 30 files can be uploaded',
    fileDuplicate: 'File duplicates: some of the files already uploaded',
    enqueuedDuplicate: 'File duplicates: some of the files already enqueued for upload',
    cancelDocumentHeader: 'Cancel document upload?',
    cancelDocumentSubHeader: 'Are you sure? Document upload will be canceled.',
    cancelUpload: 'Cancel upload',
    documentsRequire: '1 document requires signature invitations',
    documentsRequire_other: '{{count}} documents require signature invitations',
    inOrderThisContract:
      'In order this contract to take legal effect, invite persons to sign documents which require e-signature.',
    changeType: 'Change type',
    contractStatusChanged: 'Contract status changed',
    contractStatusChangedTo: 'The contract status has been changed to "{{newState}}"',
    archiveDocumentHeader: 'Archive document?',
    archiveAllDocumentHeader: 'Archive all documents of the signature set?',
    areYouSureArchiveDocument:
      'Are you sure? The document will be available in archived documents.',
    areYouSureArchiveESigDocument:
      'Are you sure? The document will be available in archived documents. All requests for document signatures will be deleted. Invited persons will still have access to the contract.',
    areYouSureArchiveAllDocument:
      'Are you sure? This document belongs to a partially signed signature set. All documents of the signature set will be archived. Documents will be available in archived documents. All requests for document signatures will be deleted. Invited persons will still have access to the contract.',
    documentArchived: 'Document archived',
    documentArchived_other: '{{count}} documents archived',
    documentArchivedSubHeader: 'The document will be available in archived documents',
    documentArchivedSubHeader_other: 'The documents will be available in archived documents',
    documentSetForESignature: 'Document set for e-signature',
    invitationSent: 'Invitation sent',
    invitationSentSubHeader: 'The invited person will be notified by email',
    signDocuments: 'Sign documents',
    ifYouContinueSignDocuments:
      'If you continue, you agree to authorize the following document set with an e-signature:',
    iHaveReadAndUnderstood:
      'I have read and understood the content of these documents and would like to sign them.',
    documentSigned: 'Document signed',
    documentSigned_other: '{{count}} documents signed',
    documentSignedSubHeader: 'The document is signed with an e-signature',
    documentSignedSubHeader_other: 'The documents are signed with an e-signature',
    contractStatusWillBeChanged: 'The contract status will be changed',
    contractStatusWillBeChangedSubHeader:
      'By clicking "Continue", the contract will have no open signature requests and its status will change.',
    questionChangeType: 'Change type?',
    signatoriesInformation: 'Signatories information',
    signatoriesInformationDescription:
      'Add information about the signatories of the documents. This data must match the data in the identification documents and is necessary for the signers to identify themselves.',
    headerQESSigning:
      '<0>If you continue, you agree to authorize the following documents with QES signatures. By clicking the "Sign" button, you will be redirected to the service of our partner (</0><1>{{link}}</1><2>), where you will go through an identification process and sign the document.</2>',
    hintQESSigningHeader: 'Qualified Electronic Signature (QES)',
    hintQESSigningSubHeader:
      '<0>According to</0><1/><2>{{link}}</2><3>, QES holds the same legal power as a traditional signature. It is created using a qualified certificate, which is issued by a qualified trust service provider.</3>',
  },
  de: {
    SelectWhetherYouWant:
      'Wähle aus, ob du Dateien zur E-Signatur, bereits signierte Dateien oder andere Dateien, die keine elektronische Signatur benötigen, hinzufügen möchtest.',
    TheContractStatus: 'Vertragsstatus wird geändert auf Offen',
    ByAddingNewDocuments:
      'Durch Hinzufügen neuer Dokumente zur E-Signatur ändert sich der Vertragsstatus auf Offen',
    [documentType.e_signature]: 'E-Signatur',
    AFileRequiringAnESignature:
      'Eine Datei, die eine E-Signatur erfordert. Dies kann ein Vertragsdokument, eine Vereinbarung oder ein Anhang sein.',
    [documentType.signed_offline]: 'Offline signiert',
    AnOfflineSignedFile:
      'Eine offline signierte Datei, die keine elektronische Unterschrift erfordert.',
    [documentType.other]: 'Anderes',
    AnyOtherRelatedFile: 'Jede andere zugehörige Datei.',
    exceededSizeLimit: 'Limit überschritten: einige der Dateien größer als 50 MB',
    qesOnlyPdf: 'Ungültiges Format: nur PDF-Dokumente erlaubt',
    exceededLimit: 'Limit überschritten: nur 30 Dateien können hochgeladen werden',
    fileDuplicate: 'Datei-Duplikate: einige der Dateien bereits hochgeladen',
    enqueuedDuplicate: 'Datei-Duplikate: einige der Dateien bereits in der Upload-Warteschlange',
    cancelDocumentHeader: 'Hochladen der Dokumente abbrechen?',
    cancelDocumentSubHeader: 'Bist du dir sicher? Hochladen der Dokumente wird abgebrochen.',
    cancelUpload: 'Hochladen abbrechen',
    documentsRequire: '1 Dokument erfordert Einladungen zu Signatur',
    documentsRequire_other: '{{count}} Dokumente erfordern Einladungen zu Signatur',
    inOrderThisContract:
      'Damit dieser Vertrag rechtswirksam werden kann, lade Personen ein, Dokumente zu unterzeichnen, die eine E-Signatur erfordern.',
    changeType: 'Typ ändern',
    contractStatusChanged: 'Vertragsstatus geändert',
    contractStatusChangedTo: 'Der Vertragsstatus wurde geändert auf "{{newState}}"',
    archiveDocumentHeader: 'Dokument archivieren?',
    archiveAllDocumentHeader: 'Alle Dokumente des Signaturpakets archivieren?',
    areYouSureArchiveDocument:
      'Bist du dir sicher? Das Dokument wird unter archivierten Dokumenten verfügbar sein.',
    areYouSureArchiveESigDocument:
      'Bist du dir sicher? Das Dokument wird unter archivierten Dokumenten verfügbar sein. Alle Signaturanfragen werden gelöscht. Eingeladene Personen werden weiterhin Zugriff zum Vertrag haben.',
    areYouSureArchiveAllDocument:
      'Bist du dir sicher? Dieses Dokument gehört zum teilweise signierten Signaturpaket. Alle Dokumente des Signaturpakets werden archiviert. Dokumente werden unter archivierten Dokumenten verfügbar sein. Alle Signaturanfragen werden gelöscht. Eingeladene Personen werden weiterhin Zugriff zum Vertrag haben.',
    documentArchived: 'Dokument archiviert',
    documentArchived_other: '{{count}} Dokumente archiviert',
    documentArchivedSubHeader: 'Das Dokument wird unter archivierten Dokumenten verfügbar sein.',
    documentArchivedSubHeader_other:
      'Die Dokumente werden unter archivierten Dokumenten verfügbar sein.',
    documentSetForESignature: 'Dokumentenpaket zu E-Signatur',
    invitationSent: 'Einladung verschickt',
    invitationSentSubHeader: 'Die eingeladene Person wird per E-Mail benachrichtigt',
    signDocuments: 'Dokumente signieren',
    ifYouContinueSignDocuments:
      'Wenn du fortfährst, stimmst du zu, das folgende Dokumentenpaket mit einer E-Signatur zu autorisieren:',
    iHaveReadAndUnderstood:
      'Den Inhalt dieser Dokumente habe ich gelesen und verstanden und möchte sie unterzeichnen.',
    documentSigned: 'Dokument signiert',
    documentSigned_other: '{{count}} Dokumente signiert',
    documentSignedSubHeader: 'Das Dokument ist signiert mit einer E-Signatur',
    documentSignedSubHeader_other: 'Die Dokumente sind signiert mit einer E-Signatur',
    contractStatusWillBeChanged: 'Der Vertragsstatus wird geändert',
    contractStatusWillBeChangedSubHeader:
      'Wenn du auf "Weiter" klickst, hat der Vertrag keine offenen Signaturanforderungen mehr und sein Status ändert sich.',
    questionChangeType: 'Typ ändern?',
    signatoriesInformation: 'Informationen zu den unterzeichnenden Personen',
    signatoriesInformationDescription:
      'Füge Informationen über die UnterzeichnerInnen der Dokumente hinzu. Diese Daten müssen mit den Daten in den Ausweisdokumenten übereinstimmen und sind erforderlich, damit die UnterzeichnerInnen sich ausweisen können.',
    headerQESSigning:
      '<0>Wenn du fortfährst, stimmst du zu, die folgenden Dokumente mit QES-Signaturen zu autorisieren. Wenn du auf die Schaltfläche "Signieren" klickst, wirst du zum Dienst unseres Partners (</0><1>{{link}}</1><2>) weitergeleitet, wo du einen Identifizierungsprozess durchlaufen und das Dokument unterzeichnen wirst.</2>',
    hintQESSigningHeader: 'Qualifizierte elektronische Signatur (QES)',
    hintQESSigningSubHeader:
      '<0>Laut</0><1/><2>{{link}}</2> <3>hat die QES die gleiche rechtliche Wirkung wie eine handschriftliche Signatur. Sie wird mit Hilfe eines qualifizierten Zertifikats erstellt, das von einem qualifizierten Vertrauensdiensteanbieter ausgestellt wird.</3>',
  },
  fr: {
    SelectWhetherYouWant:
      'Sélectionnez soit un fichier que vous voulez signer soit un fichier déjà signé ou un autre fichier ne nécessitant pas une e-Signature.',
    TheContractStatus: 'Le status du contrat va changer pour En attente',
    ByAddingNewDocuments:
      'En ajoutant un nouveau contrat pour e-signature, le status du contrat va changer pour En attente.',
    [documentType.e_signature]: 'E-Signature',
    AFileRequiringAnESignature:
      'Un fichier nécessitant une e-signature. Ce peut être un contrat, un accord, ou tout autre document joint.',
    [documentType.signed_offline]: 'Signé offline',
    AnOfflineSignedFile: "Un fichier signé offline qui ne requiert pas d'e-signature.",
    [documentType.other]: 'Autre',
    AnyOtherRelatedFile: 'Tout autre fichier en relation.',
    exceededSizeLimit: 'Limite dépassée: certains fichiers dépassent 50 MB',
    qesOnlyPdf: 'Format invalide: seuls les documents PDF sont autorisés',
    exceededLimit: 'Limite dépassée: seuls 30 fichiers peuvent être uploadés',
    fileDuplicate: 'Fichiers dupliqués: certains fichiers sont déjà uploadés',
    enqueuedDuplicate: "Fichiers dupliqués: Certains fichiers sont déjà dans la file pour l'upload",
    cancelDocumentHeader: "Annuler l'upload des documents?",
    cancelDocumentSubHeader: 'Êtes-vous sûr? Les documents uploadés seront annulés.',
    cancelUpload: "Annuler l'upload",
    documentsRequire: '1 document nécessite une invitation pour signature',
    documentsRequire_other: '{{count}} documents nécessitent une invitation pour signature',
    inOrderThisContract:
      'Pour que ce contrat prenne effet, invitez des personnes pour signer des documents nécessitant une e-signature.',
    changeType: 'Changer le type',
    contractStatusChanged: 'Le status du contrat a été changé',
    contractStatusChangedTo: 'Le status du contrat a été changé pour "{{newState}}"',
    archiveDocumentHeader: 'Archiver le document?',
    archiveAllDocumentHeader: "Archiver tous les documents de l'ensemble des signatures?",
    areYouSureArchiveDocument:
      'Êtes-vous sûr? Le document sera disponible dans les documents archivés.',
    areYouSureArchiveESigDocument:
      'Êtes-vous sûr? Le document sera disponible dans les documents archivés. Toutes les requêtes pour signer des documents seront supprimées. Les personnes invitées auront toujours accès au contrat.',
    areYouSureArchiveAllDocument:
      'Êtes-vous sûr? Ce document appartient à un ensemble partiellement signé. Tous les documents de la signature seront archivés. Les documents seront disponibles dans les documents archivés. Toutes les requêtes pour signer des documents seront supprimées. Les personnes invitées auront toujours accès au contrat.',
    documentArchived: 'Document archivé',
    documentArchived_other: '{{count}} documents archivés',
    documentArchivedSubHeader: 'Le document sera disponible dans les documents archivés',
    documentArchivedSubHeader_other:
      'Les documents seront disponibles dans les documents archivés.',
    documentSetForESignature: 'Document prêt pour e-signature',
    invitationSent: 'Invitation envoyée',
    invitationSentSubHeader: 'La personne invitée sera notifiée par email',
    signDocuments: 'Signer des documents',
    ifYouContinueSignDocuments:
      "Si vous continuez, vous acceptez d'autoriser le contrat suivant choisi avec une e-signature:",
    iHaveReadAndUnderstood:
      "J'ai lu et compris le contenu de ces documents et j'aimerais les signer.",
    documentSigned: 'Document signé',
    documentSigned_other: '{{count}} documents signés',
    documentSignedSubHeader: 'Le document est signé avec une e-signature',
    documentSignedSubHeader_other: 'Les documents sont signés avec une e-signature',
    contractStatusWillBeChanged: 'Le status du contrat va être changé',
    contractStatusWillBeChangedSubHeader:
      'En cliquant sur "continuer", le contrat n\'aura pas de requête de signature ouverte et son status va changer.',
    questionChangeType: 'Changer le type?',
    signatoriesInformation: 'Informations du signataire',
    signatoriesInformationDescription:
      "Ajouter des informations à propos des signataires du document. Ces informations doivent correspondre dans le document d'identification et il est nécessaire pour les signataires de s'identifier eux-mêmes.",
    headerQESSigning:
      '<0>Si vous continuez, vous acceptez d\'autoriser la signature de ces documents avec une signature QES. En cliquant sur le bouton "Signer", vous serez redirigé au service de notre partenaire (</0><1>{{link}}</1><2>), où vous devrez faire un processus d\'identification et signer le document.</2>',
    hintQESSigningHeader: 'Signature Électronique Qualifiée (QES)',
    hintQESSigningSubHeader:
      "<0>D'après</0><1/><2>{{link}}</2><3>, QES a le même pouvoir d'autorité légal que la signature traditionnelle. Cela est créé en utilisant un certificat qualifié, qui est émis par un prestataire de service qualifié.</3>",
  },
}
