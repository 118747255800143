import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { useMeasure } from 'react-use'
import { animated, useSpring } from '@react-spring/web'
import { Link } from 'react-router-dom'

import { createSelector } from 'reselect'
import {
  Divider,
  Elevation,
  Flex,
  LazyLoader,
  Modal,
  SearchInput,
  Spacing,
  Spinner,
  SvgMask,
  Typography,
} from '../../atoms'
import SearchNothingFound from './SearchNothingFound'

import {
  eraseSearchResults,
  searchDigitalAssets,
  searchDigitalAssetsNextPage,
} from '../../../actions/digitalAssets'
import { setActiveWorkspace } from '../../../actions/workspaces'
import { DIGITALASSET_STATES } from '../../../enums'
import { selectSearch } from '../../../store/selectors'

const Search = ({ visible, onClose, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const debouncedFetch = useRef(debounce((v) => dispatch(searchDigitalAssets({ value: v })), 300))
  const debouncedErase = useRef(debounce(() => dispatch(eraseSearchResults()), 300))

  const [ref, { height }] = useMeasure()
  const expand = useSpring({ height: `${height}px` })

  const [value, setValue] = useState('')

  const searchSelector = createSelector([selectSearch], (search) => ({
    isLoading: search.searching,
    contracts: search.data,
    meta: search.meta,
    links: search.links,
    nothingFound: search.nothingFound,
  }))

  const { isLoading, contracts, meta, links, nothingFound } = useSelector(searchSelector)

  useEffect(() => {
    if (visible) {
      setValue('')
      dispatch(eraseSearchResults())
    }
  }, [dispatch, visible])

  const hintTextVisible = !isLoading && contracts.length === 0 && !nothingFound
  const nothingFoundVisible = nothingFound

  const onTryLoadNextPage = () => {
    const nextLink = links.next

    if (nextLink) {
      dispatch(searchDigitalAssetsNextPage({ link: nextLink }))
    }
  }

  const onOpenContract = () => {
    onClose()
    dispatch(setActiveWorkspace({ id: null }))
  }

  return (
    <Modal emptyHeader visible={visible} onClose={onClose} width="552" {...props}>
      <animated.div style={expand}>
        <div ref={ref}>
          <SearchInput
            placeholder={t('search')}
            autoFocus
            value={value}
            onChange={(e) => {
              const v = e.target.value
              setValue(v)
              if (v) {
                debouncedFetch.current(v)
              } else {
                debouncedErase.current()
              }
            }}
            data-test="input_modal-search"
          />
          <Spacing size="16" />
          <HintTextContainer>
            {hintTextVisible && (
              <Typography variant="14 medium" color="tertiary" i18n="FindContractsBy">
                Type to find contracts by contract name or description
              </Typography>
            )}
            <SearchNothingFound enabled={nothingFoundVisible} query={value} onClose={onClose} />
            {isLoading && contracts.length === 0 && <Spinner />}
          </HintTextContainer>
          {contracts.length > 0 && (
            <>
              <SearchEntriesContainer>
                {contracts.map((c) => (
                  <Link
                    key={c.id}
                    to={
                      c.attributes.state === DIGITALASSET_STATES.DRAFT
                        ? `/contract-new/${c.id}`
                        : `/contract-details/${c.id}`
                    }
                  >
                    <SearchEntry onClick={onOpenContract} data-test="entry_modal-search">
                      <SvgMask icon="doc-text-checkmark" />
                      <Spacing size="8" />
                      <Typography variant="13" color="primary">
                        {c.attributes.title}
                      </Typography>
                    </SearchEntry>
                  </Link>
                ))}
                {!isLoading && <LazyLoader offset={34} action={onTryLoadNextPage} />}
              </SearchEntriesContainer>
              {isLoading && <Spinner />}
            </>
          )}
          {meta?.totalResourceCount ? (
            <>
              <Spacing size="12" />
              <Divider />
              <Spacing size="16" />
              <Flex>
                <Spacing size="8" />
                <Typography variant="13" color="secondary">
                  {meta.totalResourceCount}
                </Typography>
                <Spacing size="4" />
                <Typography variant="13" color="secondary" i18n="results">
                  results
                </Typography>
              </Flex>
              <Spacing size="25" />
            </>
          ) : (
            ''
          )}
        </div>
      </animated.div>
    </Modal>
  )
}

const SearchEntry = styled(Flex)`
  padding: 8px;
  margin: 0 0 4px 0;
  cursor: pointer;
  border-radius: 4px;

  ${SvgMask} {
    background-color: ${({ theme }) => theme.palette.type.tertiary};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.background.primary};
  }
`

const SearchEntriesContainer = styled.div`
  max-height: 376px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -6px;
  padding-right: 6px;
`

const HintTextContainer = styled(Flex)`
  text-align: center;
  justify-content: center;
  > ${Typography} {
    min-height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${Spinner} {
    min-height: 72px;
  }
`

export default styled(Search)`
  align-items: stretch;
  > div {
    overflow: hidden;
  }

  ${Elevation} {
    padding: 20px 24px 0 24px;
    margin-top: 96px;
    min-height: 156px;
    box-sizing: border-box;
    max-width: 504px;
  }

  ${SearchInput} {
    height: 40px;
  }
`
