import { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'
import TextAreaAutosize from 'react-textarea-autosize'
import { useTranslation } from 'react-i18next'
import LabelField from './LabelField'

const TextArea = ({
  input,
  placeholder,
  view,
  minRows,
  autoFocus,
  className,
  labelI18n,
  label,
  error,
  errorMessage,
  errorMessageI18n,
  sideLabel,
  templateColumn,
  description,
  required,
  helpText,
  helpTextI18n,
  containerWidth,
  withoutBorder,
  ...props
}) => {
  const [customHeight, setHeight] = useState(0)
  const onHeightChange = useCallback(
    (height) => (withoutBorder ? setHeight(height) : setHeight(height - 2)),
    [withoutBorder],
  )
  const { t } = useTranslation()
  const minRowsToRender = minRows || 1

  const ref = useRef()

  useEffect(() => {
    const target = ref?.current
    if (autoFocus && target) {
      target.focus()
      target.setSelectionRange(target.value.length, target.value.length)
    }
  }, [autoFocus])

  useEffect(() => {
    const target = ref?.current
    if (target && props.value !== target.value) {
      target.value = props.value
    }
  }, [props.value])

  const labelProps = {
    labelI18n,
    label,
    error,
    errorMessage,
    errorMessageI18n,
    sideLabel,
    templateColumn,
    description,
    required,
    helpText,
    helpTextI18n,
    width: containerWidth,
  }

  return (
    <LabelField {...labelProps} className={className}>
      <TextAreaAutosize
        {...props}
        minRows={minRowsToRender}
        onHeightChange={onHeightChange}
        style={{ height: customHeight }}
        spellCheck="false"
        placeholder={t(placeholder)}
        ref={ref}
      />
    </LabelField>
  )
}

export default styled(TextArea)`
  textarea {
    margin: 0;
    ${({ theme }) => theme.typography['body-large-02']};
    vertical-align: top;
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.component.input.value['input-color-value-disabled']
        : theme.component.input.value['input-color-value-filled']};
    width: ${({ width }) => width || '100%'};
    padding: ${({ theme }) =>
      `${theme.space['input-padding-vertical']} ${theme.space['input-padding-horizontal']}`};
    outline: 0;
    border-radius: ${({ theme }) => theme.radius['input-border-radius']};
    box-sizing: border-box;
    font-family:
      Inter,
      Arial,
      Helvetica Neue,
      Helvetica,
      sans-serif;
    resize: none;
    flex-shrink: 0;
    overflow: hidden;
    border: 1px solid
      ${({ error, theme, disabled, readOnly }) => {
        if (disabled || readOnly) {
          return theme.component.input.border['input-color-border-disabled']
        }
        if (error) {
          return theme.component.input.border['input-color-border-error']
        }
        return theme.component.input.border['input-color-border']
      }};
    -webkit-text-fill-color: ${({ theme, disabled }) =>
      disabled ? theme.component.input.value['input-color-value-disabled'] : undefined};
    color: ${({ theme, disabled, readOnly }) => {
      if (disabled) return theme.component.input.value['input-color-value-disabled']
      if (readOnly) return theme.component.input.value['input-color-value-read-only']
      return theme.component.input.value['input-color-value-filled']
    }};
    background-color: ${({ theme, disabled, readOnly }) =>
      disabled || readOnly
        ? theme.component.input.surface['input-color-background-disabled']
        : theme.component.input.surface['input-color-surface']};

    &:hover {
      background-color: ${({ theme, disabled, input, readOnly }) => {
        if (disabled || readOnly) {
          return theme.component.input.surface['input-color-background-disabled']
        }
        return 'transparent'
      }};
      border: 1px solid
        ${({ error, theme, disabled, readOnly }) => {
          if (disabled || readOnly) {
            return theme.component.input.border['input-color-border-disabled']
          }
          return error
            ? theme.component.input.border['input-color-border-error']
            : theme.component.input.border['input-color-border-hover']
        }};
    }

    &:focus {
      border: ${({ theme, readOnly }) =>
        !readOnly && `1px solid ${theme.component.input.border['input-color-border-active']}`};
      background-color: transparent;
      cursor: text;
    }

    &::placeholder {
      color: ${({ theme }) => theme.component.input.value['input-color-value']};
      opacity: 1;
    }
  }
`
