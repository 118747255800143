export default {
  Required: 'Pflichtfeld',
  WrongCredentials: 'Falsche Anmeldedaten',
  WrongPassword: 'Falsches Passwort',
  DuringRegistration:
    'Ein Fehler ist aufgetreten. Bitte kontaktieren Sie unseren Support: support@inhubber.com',
  AlreadyRegistered: 'Benutzer mit dieser E-Mail Adresse ist bereits registriert',
  RegistrationNotPossible: 'Registrierung nicht möglich',
  alreadyInvited: 'Person mit dieser E-Mail Adresse ist bereits eingeladen',
  RightNow:
    'Im Moment wird dieser Vertrag durch eine andere Person signiert. Bitte versuchen Sie es in einer Minute wieder.',
  MaxFileSize: 'Maximale Dateigröße 50 MB',
  UserWith: 'Benutzer mit dieser E-Mail-Adresse ist bereits registriert',
  PleaseConfirm: 'Bitte bestätigen Sie ihr Benutzerkonto zuerst',
  PleaseUse: 'Bitte benutzen Sie den Aktivierungslink in der Einladungsemail',
  UnexpectedError: 'Ein unerwarteter Fehler. Bitte versuchen Sie es später nochmal.',
  ConnectionError:
    'Es konnte keine Verbindung zum Service hergestellt werden. Bitte stellen Sie sicher, dass die Internetverbindung besteht und Ihre Firewall alle notwendigen Verbindungen zulässt',
  FirstName: 'Vorname',
  Surname: 'Nachname',
  RepeatPassword: 'Passwort wiederholen',
  wrongBrowser:
    'Im Moment ist unsere Anwendung nur für die Browser Mozilla Firefox, letzten Microsoft Edge und Google Chrome verfügbar. Wir bitten um Ihr Verständnis.',
  enter_valid: 'E-Mail eingeladener Person',
  admin: 'Verwaltung',
  InvalidSecurityKey: 'Falsche Schlüsseldatei',
  search: 'Suche',
  Select_date: 'Datum auswählen',
  January: 'Januar',
  February: 'Februar',
  March: 'März',
  April: 'April',
  May: 'Mai',
  June: 'Juni',
  July: 'Juli',
  August: 'August',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'Dezember',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mär',
  Apr: 'Apr',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dez',
  Sunday: 'Sonntag',
  Monday: 'Montag',
  Tuesday: 'Dienstag',
  Wednesday: 'Mittwoch',
  Thursday: 'Donnerstag',
  Friday: 'Freitag',
  Saturday: 'Samstag',
  MON: 'MON',
  TUE: 'DIE',
  WED: 'MIT',
  THU: 'DON',
  FRI: 'FRE',
  SAT: 'SAM',
  SUN: 'SON',
  Today: 'Heute',
  New_Contract: 'Neuer Vertrag',
  Document: 'Dokument',
  InvalidDateFormat: 'Ungültiges Datumsformat',
  existsAccountType: 'Der ausgewählte Kontotyp existiert für den Benutzer',
  yourSession: 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein',
  DraftSaved: 'Alle Änderungen werden automatisch gespeichert',
  ContractCreated: 'Vertrag erfolgreich erstellt',
  dateOfBirth: 'Geburtsdatum',
  placeOfBirth: 'Geburtsort',
}
