/* eslint-disable no-param-reassign */
import FieldLeaf from './FieldLeaf'

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>
  }

  if (leaf.field) {
    children = <FieldLeaf id={leaf.field}>{children}</FieldLeaf>
  }

  return <span {...attributes}>{children}</span>
}

export default Leaf
