import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createEditor, Transforms } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { animated, useSpring } from '@react-spring/web'
import { v4 } from 'uuid'

import { createSelector } from 'reselect'
import { Flex, Persona, Spacing } from '../../atoms'
import Leaf from './Leaf'
import Element from './Element'

import getValueLength from './getValueLength'
import useConditionalInterval from './useConditionalInterval'
import withImages from './withImages'
import { TEMPLATE_FEATURES } from '../../../enums'
import { selectAccount } from '../../../store/selectors'

const PLACEHOLDER = 'Add a comment...'

export const DEFAULT_TEXT_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

const MAX_COMMENT_LENGTH = process.env.REACT_APP_COMMENT_LENGTH

const CommentsEditor = ({
  className,
  onCreate,
  onChange,
  placeholder,
  innerRef,
  maxValueLength = MAX_COMMENT_LENGTH,
  disabledFeatures = [],
  defaultValue = DEFAULT_TEXT_VALUE,
  readOnly = false,
}) => {
  const editor = useMemo(() => withImages(withReact(createEditor())), [])

  const [value, setValue] = useState(defaultValue)
  const [focused, setFocused] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [customHeight, setCustomHeight] = useState(20)
  const [toolbarDisabled, setToolbarDisabled] = useState(true)

  useEffect(() => {
    if (innerRef) {
      // eslint-disable-next-line no-param-reassign
      innerRef.current = {
        setValue,
        getValue: () => value,
        editor,
      }
    }
  }, [innerRef, value, editor])

  const onFocus = useCallback(() => {
    setExpanded(true)
    setFocused(true)
    setToolbarDisabled(false)
  }, [])

  const unfocus = useCallback(() => {
    setExpanded(false)
    setTimeout(() => setToolbarDisabled(true), 150)
  }, [])

  const onBlur = useCallback(() => {
    if (JSON.stringify(value) === JSON.stringify(DEFAULT_TEXT_VALUE)) {
      unfocus()
    }
    setFocused(false)
  }, [unfocus, value])

  const selectData = createSelector([selectAccount], (account) => ({
    currentUser: account.currentUser,
  }))
  const { currentUser } = useSelector(selectData)
  const { firstName = '', lastName = '', emailAddress } = currentUser || {}

  const expand = useSpring({ height: customHeight, config: { duration: 150 } })
  const appear = useSpring({
    opacity: expanded ? 1 : 0,
    config: { duration: 150 },
  })

  const valueAsString = useMemo(() => JSON.stringify(value), [value])

  const ref = useRef()
  const intervalHandler = useCallback(() => ref.current.scrollIntoView(), [])
  useConditionalInterval(
    intervalHandler,
    expanded && !disabledFeatures.includes(TEMPLATE_FEATURES.TIMER),
    8,
    150,
    [expanded, valueAsString],
  )

  useEffect(() => {
    const target = ref.current.parentElement.querySelector('[data-id=spring-element] > div')
    const { height } = target.getBoundingClientRect()
    setCustomHeight(height + 3)
  }, [valueAsString, expanded])

  const onInternalChange = useCallback(
    (v) => {
      if (getValueLength(v) < maxValueLength) {
        setValue(v)
        if (typeof onChange === 'function') {
          onChange({ value })
        }
      } else {
        const selectionOffset = getValueLength(v) - getValueLength(value)
        // set selection to last known position since we didn't accepted the new character
        Transforms.select(editor, {
          path: editor.selection.anchor.path,
          offset: editor.selection.anchor.offset - selectionOffset,
        })
      }
    },
    [maxValueLength, onChange, value, editor],
  )

  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const commentButtonEnabled = !disabledFeatures.includes(TEMPLATE_FEATURES.COMMENT_BUTTON)

  const generateUUID = useCallback(() => `text:${v4()}`, [])

  return (
    <div className={className}>
      <Persona size="medium" firstName={firstName} lastName={lastName} seed={emailAddress} />
      <Spacing size="8" />
      <Container
        $expanded={expanded && !disabledFeatures.includes(TEMPLATE_FEATURES.EXPAND_PADDING)}
        $focused={focused}
      >
        <animated.div style={expand} data-id="spring-element">
          <Slate editor={editor} initialValue={value} onChange={onInternalChange}>
            <Editable
              placeholder={placeholder || PLACEHOLDER}
              onFocus={onFocus}
              onBlur={onBlur}
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              spellCheck={false}
              readOnly={readOnly}
            />

            {/* <animated.div style={appear}>
              <Toolbar content="space-between" $hidden={toolbarDisabled}>
                <Flex>
                  <MarkButton icon="bold" format="bold" /> */}
            {/* <Spacing size="8" />
                  <MarkButton icon="italic" format="italic" />
                  <Spacing size="8" />
                  <MarkButton icon="underline" format="underline" />
                  <Spacing size="8" />
                  <MarkButton icon="strikethrough" format="strikethrough" /> */}
            {/* <Spacing size="8" />
                  <MarkButton icon="typing" format="field" onValue={generateUUID} />
                  <Spacing size="16" />
                  <InsertImageButton />
                </Flex>
                {commentButtonEnabled && (
                  <Button
                    secondary
                    width="fit-content"
                    onClick={(e) => {
                      if (typeof onCreate === 'function') {
                        onCreate({ value })
                      } else {
                        dispatch(
                          createCommentSync({
                            type: ActivityTypes.COMMENT,
                            authorId: '53b6d8ac-154a-4865-b16e-f016c1c2aef7',
                            value,
                            date: new Date(),
                            id: Math.random(),
                          }),
                        )
                      }

                      const point = { path: [0, 0], offset: 0 }
                      editor.selection = { anchor: point, focus: point }
                      editor.history = { redos: [], undos: [] }
                      setValue(DEFAULT_TEXT_VALUE)
                      unfocus()
                      e.stopPropagation()
                    }}
                  >
                    Comment
                  </Button>
                )}
              </Toolbar>
            </animated.div> */}
          </Slate>
        </animated.div>
        <div ref={ref} />
      </Container>
    </div>
  )
}

export const Toolbar = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${({ $hidden }) => ($hidden ? '0px' : '14px 12px')};
  width: 100%;
  height: ${({ $hidden }) => ($hidden ? '0px' : 'inherit')};
  box-sizing: border-box;
`

export const Container = styled.div`
  font-size: 14px;
  padding: 0px 12px;
  padding-bottom: ${({ $expanded }) => ($expanded ? '50px' : '0px')};
  border: 1px solid
    ${({ theme, $focused }) => ($focused ? theme.palette.theme.primary : theme.palette.divider)};
  border-radius: 4px;
  width: 100%;
  position: relative;

  :hover {
    border: 1px solid
      ${({ $error, theme: { palette }, disabled }) => {
        if (disabled) {
          return palette.divider
        }
        return $error ? palette.accent.warning : palette.theme.primary
      }};
  }

  transition:
    padding 0.14s,
    border-color 0.3s ${({ theme }) => theme.animation.bezier};

  [data-slate-editor] {
    padding: 10px 0;

    p {
      margin: 0;
    }
  }

  [data-slate-node='element'] {
    text-align: justify;
  }
`

export default styled(CommentsEditor)`
  display: flex;
  ${Persona} {
    margin-top: 4px;
  }
`
