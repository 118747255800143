import React from 'react'
import styled from 'styled-components'
import { useResponsive } from '../../hooks'

const ContentGrid = ({ children, rows, height, ...props }) => {
  return <div {...props}>{children}</div>
}

export default styled(ContentGrid).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || 'repeat(12, 1fr)'};
  grid-template-rows: ${({ rows }) => rows || '1fr'};
  grid-column-gap: 24px;
  background: ${({ theme }) => theme.palette.background.white};

  padding: ${({ $isGreaterOrEqualThan }) => ($isGreaterOrEqualThan('md') ? '0 24px' : '0 16px')};
  width: ${({ width }) => width || undefined};
  height: ${({ height }) => height || '100vh'};
  overflow: ${({ overflow }) => overflow || undefined};
  overflow-y: hidden;
`
