import { createAsyncThunk } from '@reduxjs/toolkit'

export const getFilterTarget = ({
  activeWorkspaceId,
  activeWorkspaceFolderId,
  locationPathName,
}) => {
  if (activeWorkspaceFolderId) {
    return activeWorkspaceFolderId
  }

  if (activeWorkspaceId) {
    return activeWorkspaceId
  }

  if (locationPathName.indexOf('archive') !== -1) {
    return 'archive'
  }

  return 'default'
}

export const changeFilters = createAsyncThunk(
  'filters/changeFilters',
  async ({ newFilter, clearAll, byStatus }, api) => {
    const state = api.getState()
    const { active: activeWorkspaceId, activeFolder: activeFolderId } = state.workspaces

    const { location } = window

    const filterTarget = getFilterTarget({
      activeWorkspaceId,
      activeWorkspaceFolderId: activeFolderId,
      locationPathName: location.pathname,
    })
    let attributes = { ...state.filters.data[filterTarget], ...newFilter }

    if (!newFilter) {
      attributes = {
        byStatus,
      }
    }

    if (clearAll === true) attributes = { ...newFilter }

    return { attributes, workspaceId: filterTarget }
  },
)
