import styled from 'styled-components'

import { Typography, Spinner, Spacing } from '../atoms'

const SignOverlay = ({ className, visible }) => {
  if (!visible) {
    return null
  }

  return (
    <div className={className}>
      <Typography variant="14 medium" i18n="ContractSigning">
        Contract signing
      </Typography>
      <Spacing size="8" />
      <Spinner />
    </div>
  )
}

export default styled(SignOverlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 90;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.palette.overlay.background};
`
