export const COMPANY_SUBSCRIPTION = {
  SUBSCRIPTION_NOT_LOADED: 'SUBSCRIPTION_NOT_LOADED',
  FREE_SUBSCRIPTION: 'FREE_SUBSCRIPTION',
}

export const ALL_SUBSCRIPTIONS = {
  FREE: 'plan-free',
  BASIC: 'basic_1',
  ENTERPRISE: 'plan-enterprise',
}
