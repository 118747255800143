import { useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'
import { animated, useSpring } from '@react-spring/web'
import { usePopper } from 'react-popper'

import Spacing from '../Spacing'
import BaseElevation from './BaseElevation'
import ModalOverlay from './ModalOverlay'

const BasePopup = ({
  hidden,
  overlay,
  children,
  target,
  options,
  spacing = 4,
  onClose,
  isAnimated,
  closeOnClick = false,
  canUpdate,
  autoHeight = false,
  autoMaxHeight = false,
  hiddenY,
  width,
  popperPlacement,
  dontUpdateHeight = false,
  zIndex,
  ...props
}) => {
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes, update } = usePopper(target, popperElement, options)

  useEffect(() => () => setPopperElement(null), [])

  useEffect(() => {
    if (typeof popperPlacement === 'function') {
      popperPlacement(attributes.popper?.['data-popper-placement'])
    }
  }, [attributes, popperPlacement])

  useEffect(() => {
    if (canUpdate && typeof update === 'function') update()
  }, [canUpdate, children, update])

  const popperHeight = useMemo(() => {
    if (autoHeight || autoMaxHeight) {
      const bottom = target?.getBoundingClientRect().bottom
      const bodyHeight = document.body.offsetHeight
      const heightElement = popperElement?.getBoundingClientRect().height
      const distanceTop = bodyHeight - bottom
      const distanceBottom = bodyHeight - distanceTop - 44
      if (
        !canUpdate &&
        options.placement.indexOf('bottom') !== -1 &&
        heightElement > distanceBottom
      ) {
        return distanceTop - 10
      }

      if (heightElement > distanceTop) {
        if (distanceBottom > distanceTop) {
          if (heightElement > distanceBottom) {
            return distanceBottom
          }
          return undefined
        }
        if (heightElement > distanceTop) {
          return distanceTop - 10
        }
        return undefined
      }
      return undefined
    }
    return undefined
  }, [autoHeight, autoMaxHeight, target, popperElement, canUpdate, options.placement])

  const [ref, { height }] = useMeasure()
  const expand = useSpring({
    height: !hidden ? height : 0,
    overflow: 'hidden',
    padding: hidden ? 0 : 20,
    margin: hidden ? 0 : -20,
  })

  if (hidden) {
    return null
  }

  if (!isAnimated) {
    return (
      <>
        {overlay && !hidden && <ModalOverlay onClick={onClose} data-test="modal_overlay" />}
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: zIndex || 5500 }}
          {...attributes.popper}
          {...props}
        >
          <Spacing size={spacing} />
          <BaseElevation
            hidden={hidden}
            closeOnClick={closeOnClick}
            popperStyle={{
              height: autoHeight ? popperHeight : undefined,
              maxHeight: autoMaxHeight ? popperHeight : undefined,
              overflowY: popperHeight && !hiddenY ? 'scroll' : hiddenY,
              overflowX: popperHeight ? 'hidden' : undefined,
              width,
            }}
          >
            {children}
          </BaseElevation>
        </div>
      </>
    )
  }
  return (
    <>
      {overlay && !hidden && <ModalOverlay onClick={onClose} data-test="modal_overlay" />}
      <div
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: zIndex || 5500 }}
        {...attributes.popper}
        {...props}
      >
        <animated.div style={expand}>
          <div ref={ref}>
            <Spacing size={spacing} />
            <BaseElevation
              hidden={hidden}
              closeOnClick={closeOnClick}
              popperStyle={{
                height: autoHeight ? popperHeight : undefined,
                maxHeight: autoMaxHeight ? popperHeight : undefined,
                overflowY: popperHeight && !hiddenY ? 'scroll' : hiddenY,
                overflowX: popperHeight ? 'hidden' : undefined,
                width,
              }}
            >
              {children}
            </BaseElevation>
          </div>
        </animated.div>
      </div>
    </>
  )
}

export default BasePopup
