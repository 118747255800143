import { useCallback, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import compact from 'lodash/compact'

import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { BACKEND_PERMISSIONS, PERMISSIONS, SMARTIMPORTSTATES, WORKSPACE_ROLES } from '../../enums'

import { useSingletonPermissions } from './useSingletonPermissions'
import useWorkspace from '../useWorkspace'
import useDigitalAssetCreatedByCompany from '../isDigitalAssetCreatedByCompany'
import {
  activeWorkspaceIdSelector,
  selectActiveFolder,
  selectCurrentUserId,
  selectDigitalAssetDetails,
  selectDigitalAssets,
  selectWorkspaces,
} from '../../store/selectors'

const DEFAULT_ARRAY = []

const usePermissions = ({ digitalAssetId, workspaceId, folderId } = {}) => {
  const assetSelector = createSelector(
    [selectDigitalAssets, selectDigitalAssetDetails],
    (digitalAssets, digitalAssetDetails) =>
      digitalAssetDetails[digitalAssetId]?.asset ||
      digitalAssets.data.find((d) => d.id === digitalAssetId),
  )

  const workspaceMembershipSelector = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.membership[workspaceId || workspaces.active],
  )

  const workspaceUserPermissionsSelector = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.allWorkspaces[workspaceId]?.meta?.userPermissions || DEFAULT_ARRAY,
  )

  const folderUserPermissionsSelector = createSelector(
    [selectWorkspaces, selectActiveFolder],
    (workspaces, activeFolder) =>
      workspaces.allFolders[folderId || activeFolder]?.meta?.userPermissions || DEFAULT_ARRAY,
  )

  const canCreateNewWorkspacesSelector = createSelector(
    [selectWorkspaces],
    (workspaces) => workspaces.canCreateNewWorkspaces,
  )

  const asset = useSelector(assetSelector)
  const userId = useSelector(selectCurrentUserId)
  const activeWorkspaceId = useSelector(activeWorkspaceIdSelector)
  const workspaceMembership = useSelector(workspaceMembershipSelector)
  const workspaceUserPermissions = useSelector(workspaceUserPermissionsSelector)
  const folderUserPermissions = useSelector(folderUserPermissionsSelector)
  const canCreateNewWorkspaces = useSelector(canCreateNewWorkspacesSelector)

  const assetUserPermissions = asset?.meta?.userPermissions

  const wsUserPermissionsHashMap = useMemo(
    () => keyBy(workspaceUserPermissions, (k) => k),
    [workspaceUserPermissions],
  )

  const folderUserPermissionsHashMap = useMemo(
    () => keyBy(folderUserPermissions, (k) => k),
    [folderUserPermissions],
  )

  const assetUserPermissionsHashMap = useMemo(
    () => keyBy(assetUserPermissions, (k) => k),
    [assetUserPermissions],
  )

  const generalPermissions = useSingletonPermissions() // array

  const daOwnedById = asset?.relationships?.ownedBy.data.id
  const isContractEditable = daOwnedById === userId

  const smartImportState = asset?.attributes?.smartImportState

  const isDigitalAssetCreatedByCompany = useDigitalAssetCreatedByCompany(digitalAssetId)

  const { canCreateContracts } = useWorkspace(workspaceId || activeWorkspaceId)

  const [permissionsArray, permissionsMap] = useMemo(() => {
    const conditions = {
      [PERMISSIONS.CONTRACT_EDITABLE]: () => isContractEditable,

      [PERMISSIONS.CONTRACT_SMARTIMPORT_ENABLED]: () =>
        smartImportState === SMARTIMPORTSTATES.ANALYZED ||
        smartImportState === SMARTIMPORTSTATES.FINISHED,

      [PERMISSIONS.CONTRACTS_CREATE]: () => canCreateContracts,

      [PERMISSIONS.WORKSPACE_MAINTAINER]: () => workspaceMembership?.role === WORKSPACE_ROLES.ADMIN,

      [PERMISSIONS.DIGITALASSET_CREATED_BY_COMPANY]: () => isDigitalAssetCreatedByCompany,

      [PERMISSIONS.WORKSPACES_CREATE]: () => canCreateNewWorkspaces,
      [PERMISSIONS.WORKSPACE_MANAGE_FOLDERS]: () =>
        wsUserPermissionsHashMap[BACKEND_PERMISSIONS.WORKSPACES.MANAGE_FOLDERS],
      [PERMISSIONS.WORKSPACE_MANAGE_MEMBERS]: () =>
        wsUserPermissionsHashMap[BACKEND_PERMISSIONS.WORKSPACES.MANAGE_MEMBERS],
      [PERMISSIONS.WORKSPACE_UPDATE]: () =>
        wsUserPermissionsHashMap[BACKEND_PERMISSIONS.WORKSPACES.UPDATE],
      [PERMISSIONS.WORKSPACE_DELETE]: () =>
        wsUserPermissionsHashMap[BACKEND_PERMISSIONS.WORKSPACES.DELETE],

      [PERMISSIONS.FOLDER_REMOVE_CONTRACTS]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_REMOVE_CONTRACTS],
      [PERMISSIONS.FOLDER_UPDATE_METADATA]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_UPDATE_METADATA],
      [PERMISSIONS.FOLDER_CHANGE_PERMISSIONS]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_CHANGE_PERMISSIONS],
      [PERMISSIONS.FOLDER_ADD_SUBFOLDERS]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_ADD_SUBFOLDERS],
      [PERMISSIONS.FOLDER_ACCESS]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_ACCESS],
      [PERMISSIONS.FOLDER_ADD_CONTRACTS]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_ADD_CONTRACTS],
      [PERMISSIONS.FOLDER_REMOVE_FOLDER]: () =>
        folderUserPermissionsHashMap[BACKEND_PERMISSIONS.FOLDERS.FOLDER_REMOVE_FOLDER],
      [PERMISSIONS.CONTRACT_COPY]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.CONTRACT_COPY],
      [PERMISSIONS.CONTRACT_UNARCHIVE]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.CONTRACT_UNARCHIVE],
      [PERMISSIONS.UPLOAD_E_SIGNATURE]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.UPLOAD_E_SIGNATURE],
      [PERMISSIONS.UPLOAD_SIGNED_OFFLINE]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.UPLOAD_SIGNED_OFFLINE],
      [PERMISSIONS.UPLOAD_OTHER]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.UPLOAD_OTHER],
      [PERMISSIONS.UPLOAD_DOCUMENTS]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.UPLOAD_E_SIGNATURE] ||
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.UPLOAD_SIGNED_OFFLINE] ||
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.UPLOAD_OTHER],
      [PERMISSIONS.INVITE_E_SIGNATURE]: () =>
        assetUserPermissionsHashMap[BACKEND_PERMISSIONS.DIGITALASSET.INVITE_E_SIGNATURE],
    }

    const conditionResult = map(conditions, (condition, index) =>
      condition() ? index : undefined,
    ).concat(generalPermissions)
    const result = compact(conditionResult) // remove undefined
    const resultMap = keyBy(result, (k) => k) // create a dictionary

    return [result, resultMap]
  }, [
    generalPermissions,
    isContractEditable,
    smartImportState,
    canCreateContracts,
    workspaceMembership?.role,
    isDigitalAssetCreatedByCompany,
    canCreateNewWorkspaces,
    wsUserPermissionsHashMap,
    folderUserPermissionsHashMap,
    assetUserPermissionsHashMap,
  ])

  const isEnabled = useCallback((permission) => !!permissionsMap[permission], [permissionsMap])

  return {
    rawData: permissionsArray,
    isPermissionEnabled: isEnabled,
    PERMISSIONS,
  }
}

export default usePermissions
