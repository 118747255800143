import styled from 'styled-components'

import Typography from './index'

const ValidationError = styled(Typography).attrs(() => ({ variant: '10 medium' }))`
  color: ${({ theme }) => theme.palette.accent.warning};
  position: ${({ offset }) => (offset === 'none' ? 'inherit' : 'absolute')};
  top: ${({ offset }) => (offset === 'none' ? 0 : 44)}px;
`

export default ValidationError
