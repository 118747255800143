import styled from 'styled-components'

import { ElementTooltip, Flex, SvgMask, Typography } from '../atoms'

import { TASKS_STATE } from '../../enums'

const actionColors = {
  [TASKS_STATE.OPEN]: {
    background: ({ theme }) => theme.palette.background.white,
    color: ({ theme }) => theme.palette.type.white,
    borderColor: ({ theme }) => theme.palette.divider,
  },
  [TASKS_STATE.PARTIAL]: {
    background: ({ theme }) => theme.palette.accent.successLight,
    color: ({ theme }) => theme.palette.accent.success,
    borderColor: ({ theme }) => theme.palette.accent.success,
  },
  [TASKS_STATE.COMPLETE]: {
    background: ({ theme }) => theme.palette.accent.success,
    color: ({ theme }) => theme.palette.background.white,
    borderColor: ({ theme }) => theme.palette.accent.success,
  },
}

const ActionIcon = ({ tooltipI18n, icon, taskState, assigneeState, position, onClick }) => {
  if (tooltipI18n) {
    return (
      <Flex data-test={`actionIcon_taskCard_${taskState}_${assigneeState || 'noAssignee'}`}>
        <ElementTooltip
          tooltip={
            <Typography variant="12 medium" i18n={tooltipI18n}>
              {tooltipI18n}
            </Typography>
          }
          position={position}
          options={
            !position && {
              placement: 'top',
            }
          }
        >
          <Circle
            content="center"
            onClick={onClick}
            $taskState={taskState}
            $assigneeState={assigneeState}
          >
            <SvgMask icon={icon} color={actionColors[taskState].color} />
          </Circle>
        </ElementTooltip>
      </Flex>
    )
  }
  return (
    <Circle
      content="center"
      onClick={onClick}
      $taskState={taskState}
      $assigneeState={assigneeState}
      data-test={`actionIcon_taskCard_${taskState}_${assigneeState || 'noAssignee'}`}
    >
      <SvgMask icon={icon} color={actionColors[taskState].color} />
    </Circle>
  )
}

const Circle = styled(Flex)`
  border-radius: 100%;
  border: 1px solid #e6e8eb;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${({ $taskState }) => actionColors[$taskState].borderColor};
  background: ${({ $taskState }) => actionColors[$taskState].background};
  &:hover {
    ${({ theme, $taskState, $assigneeState }) => {
      if ($taskState === TASKS_STATE.OPEN && $assigneeState) {
        return `
          background: ${theme.palette.accent.successLight};
          border-color: ${theme.palette.accent.success};
          ${SvgMask} {
            background-color: ${theme.palette.accent.success}; 
          }`
      }
      return undefined
    }}
  }
`

export default ActionIcon
