import styled, { useTheme } from 'styled-components'

import ElementTooltip from './Modal/ElementTooltip'
import Typography from './Typography'
import SvgMask from './SvgMask'

const FilterItem = ({ i18n, value, onClose, onClick, className, ...props }) => {
  const theme = useTheme()

  return (
    <div {...props} data-test="filterItemByStatus" onClick={onClick} className={className}>
      <ElementTooltip
        tooltip={<Typography variant="12 medium" i18n="filters:EditFilter" />}
        options={{
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <div>
          <Typography i18n={i18n} variant="body 12 medium" color="secondary">
            {value}
          </Typography>
        </div>
      </ElementTooltip>

      {onClose && (
        <ElementTooltip
          tooltip={
            <Typography variant="12 medium" i18n="filters:RemoveFilter">
              Remove filter
            </Typography>
          }
          options={{
            placement: 'top',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
          }}
          {...props}
        >
          <div>
            <SvgMask
              icon="xmark-small"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClose()
              }}
              color={theme.semantic.palette.icon['icon-secondary']}
            />
          </div>
        </ElementTooltip>
      )}
    </div>
  )
}

export default styled(FilterItem)`
  padding: ${({ theme }) => theme.semantic.space['space-4x-small']};
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${({ theme }) => theme.semantic.palette.border['border-secondary']};
    pointer-events: none;
    border-radius: inherit;
  }

  ${ElementTooltip} {
    font-size: 12px;
  }

  ${Typography} {
    white-space: nowrap;
  }

  ${Typography}:first-child {
    padding: 0 ${({ theme }) => theme.semantic.space['space-4x-small']};
    color: ${({ theme }) => theme.semantic.palette.text['text-secondary']};
  }

  ${ElementTooltip} {
    padding: 0;
    margin: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.semantic.palette.surface['surface-hover']};

    ${Typography}:first-child {
      color: ${({ theme }) => theme.semantic.palette.text.text};
    }
  }
`
