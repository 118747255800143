import { createReducer } from '@reduxjs/toolkit'
import { v4 } from 'uuid'

import { createWorkflow, updateWorkflow } from '../actions/workflow'

const initialState = { data: [] }
const reducer = createReducer({ ...initialState }, (builder) => {
  builder
    .addCase(createWorkflow.type, (state) => {
      const newWorkflow = {
        id: v4(),
        title: `Workflow #${state.data.length + 1}`,
        type: 'bpmn',
        createdAt: new Date(),
        modifiedAt: new Date(),
      }
      return { ...state, data: [...state.data, newWorkflow] }
    })
    .addCase(updateWorkflow.type, (state, action) => {
      const { payload } = action
      const updatedData = state.data.map((workflow) =>
        workflow.id === payload.id ? { ...workflow, xml: payload.xml } : workflow,
      )
      return { ...state, data: updatedData }
    })
})

export default reducer
