import { useTheme } from 'styled-components'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createSelector } from 'reselect'

import { Button, Modal, Spinner } from '../atoms'
import { WORKSPACE_ROLES } from '../../enums'
import SelectNewOwner from '../organisms/FolderPermissions/SelectNewOwner'
import { createNotification } from '../../actions/notifications'
import { callApi } from '../../utils/callApi'
import {
  getIncludedNaturalPersons,
  selectIncludedSelector,
  selectWorkspaces,
} from '../../store/selectors'

const RemoveUserFromWorkspace = ({
  visible,
  onClose,
  firstName,
  lastName,
  workspaceMembershipId,
  workspaceId,
  confirmDelete,
  workspaceMembers,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [deleteAnimation, setDeleteAnimation] = useState(false)

  const [selectPersonId, setSelectPersonId] = useState(null)

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    wsTitle: workspaces.allWorkspaces[workspaceId].attributes.title,
    companyWsId: workspaces.companyWorkspace?.id,
  }))

  const { wsTitle, companyWsId } = useSelector(selectData)

  const naturalPersons = useSelector(getIncludedNaturalPersons)
  const included = useSelector(selectIncludedSelector)

  const maintainersNP = useMemo(() => {
    if (visible) {
      const maintainers = workspaceMembers.filter(
        (i) => i.attributes.role === WORKSPACE_ROLES.ADMIN && i.id !== workspaceMembershipId,
      )
      const accounts = maintainers.map(
        (i) => included.accounts[i.relationships.grantedToAccount.data.id],
      )
      return accounts.map((i) => naturalPersons[i.relationships.ownedBy.data.id])
    }
    return []
  }, [included.accounts, naturalPersons, visible, workspaceMembers, workspaceMembershipId])

  const selectAccountId = useMemo(() => {
    if (selectPersonId) {
      return maintainersNP.find((f) => f.id === selectPersonId)
    }
    return undefined
  }, [maintainersNP, selectPersonId])

  const removeUserAccessFromGeneral = async () => {
    const resp = await callApi({
      endpoint: `workspaces/${workspaceId}/relationships/members`,
      method: 'DELETE',
      accessName: 'Bearer',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      body: {
        data: {
          id: workspaceMembershipId,
        },
      },
    })

    return resp
  }

  const removeUserAccess = async () => {
    setDeleteAnimation(true)
    try {
      const OwnershipTo = selectAccountId?.relationships?.account?.data?.id
      const resp = OwnershipTo
        ? await callApi({
            endpoint: `workspacememberships/${workspaceMembershipId}?transferFolderOwnershipTo=${OwnershipTo}`,
            method: 'DELETE',
            headers: {
              Accept: 'application/json',
            },
          })
        : await removeUserAccessFromGeneral()
      setDeleteAnimation(false)
      confirmDelete(resp)
      dispatch(
        createNotification({
          state: 'success',
          value: t('workspace:WorkspaceAccessRemoved'),
          description: t('workspace:UserHasLostAccessToWorkspace', {
            userName: `${firstName} ${lastName}`,
            wsTitle: OwnershipTo ? wsTitle : t('General'),
          }),
        }),
      )

      onClose()
    } catch (e) {
      setDeleteAnimation(false)
      confirmDelete()
      onClose()
      // throw new Error('UnexpectedError')
    }
  }

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      header={t('workspace:RemoveFromWorkspaceNew')}
      subHeader={t('workspace:UserWillLoseAccessToWorkspace', {
        userName: `${firstName} ${lastName}`,
        firstName,
        wsTitle: companyWsId === workspaceId ? t('General') : wsTitle,
      })}
      type="dialog"
      buttons={
        <>
          <Button i18n="cancel" width="fit-content" secondary onClick={onClose}>
            Cancel
          </Button>
          <Button
            i18n={deleteAnimation ? undefined : 'Remove'}
            width="fit-content"
            tone="critical"
            onClick={deleteAnimation ? undefined : removeUserAccess}
            data-test="button_workspaceSettingsModal_removeUser"
          >
            {deleteAnimation ? <Spinner animation="white" /> : 'Delete'}
          </Button>
        </>
      }
    >
      {companyWsId !== workspaceId ? (
        <SelectNewOwner
          naturalPersons={maintainersNP}
          firstName={firstName}
          onChange={(s) => selectPersonId !== s && setSelectPersonId(s)}
        />
      ) : undefined}
    </Modal>
  )
}

export default RemoveUserFromWorkspace
