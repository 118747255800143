import { icons } from './const'

export const renderMoveHandler = (overlay, ctx) => {
  ctx.drawImage(
    icons.drag,
    overlay.offsetX - 36,
    overlay.offsetY - 18,
    icons.drag.width,
    icons.drag.height,
  )
}

export const renderDeleteHandler = (overlay, ctx) => {
  ctx.drawImage(
    icons.delete,
    overlay.offsetX + overlay.width - 12,
    overlay.offsetY - 18,
    icons.delete.width,
    icons.delete.height,
  )
}
