export default {
  Log_Out: 'Log out',
  Login: 'Log in',
  contracts: 'Contracts',
  th_created_on: 'Created on',
  th_owner: 'Owner',
  th_workspace: 'Workspace',
  active: 'Active',
  pending: 'Pending',
  pendingLoading: 'Pending…',
  documents: 'Documents',
  cancel: 'Cancel',
  Create: 'Create',
  Title: 'Title',
  Convert: 'Convert',
  view: 'View',
  remove: 'remove',
  Remove: 'Remove',
  back: 'Back',
  Description: 'Description',
  Save: 'Save',
  Dates: 'Dates',
  Close: 'Close',
  Status: 'Status',
  sign: 'Sign',
  size: 'Size:',
  Detail: 'Details',
  Invite: 'Invite',
  LogIn: 'Log In',
  Password: 'Password',
  SignUp: 'Sign up',
  accepted: 'accepted',
  AccountType: 'Account Type:',
  PublicKey: 'Public Key:',
  BlockchainAddress: 'Blockchain Address:',
  issuer: 'Issuer',
  basic: 'Basic',
  TandC: 'Terms & Conditions',
  testingTermsPolicies: 'INHUBBER terms & policies',
  value: 'Value',
  Labels: 'Labels',
  CreateLabels: 'Create labels',
  TagAlreadyCreated: 'Label already exists',
  SignContract: 'Sign contract',
  AddCustomDate: 'Add custom date',
  AddDate: 'Add',
  Add: 'Add',
  Reminders: 'Reminders',
  AddReminder: 'Add reminder',
  RemindMe: 'Remind me',
  before: 'before',
  after: 'after',
  Reminder: 'Reminder',
  docAnalysis: 'Analysis',
  Analyse: 'Analyse',
  NoResults: 'No results',
  PasswordsDontMatch: "Passwords don't match",
  declined: 'Declined',
  signed: 'Signed',
  expired: 'Expired',
  archived: 'Archived',
  start_date: 'Start date',
  expiry_date: 'Expiry date',
  expiryDateError: 'Expiry date must be after Start date',
  startDateError: 'Start date must be before expiry date',
  LogInINHUBBER: 'Log in to INHUBBER',
  LoadSecurityKey: 'Load Security Key',
  NextGenerationContract: 'Next generation contract management',
  ContractsWorkForYou: 'Let your contracts work for you',
  AlreadyHaveAccount: 'Already have an account?',
  SignUpAccount: 'Sign up for your account',
  SecurityKey: 'Security Key',
  name: 'Name',
  someFields: 'some of the fields (Date, Description) are not set',
  closeAnalysis: 'Close analysis results',
  OrganizeContracts: 'Organize your contracts',
  fileAccepted: 'Security Key Accepted',
  InviteAll: 'Invite all',
  Invited: 'Invited',
  Profile: 'Profile',
  SortUp: 'Sort up',
  SortDown: 'Sort down',
  CancelSort: 'Cancel sort',
  startDate: 'Start Date',
  ContractsDropFilesHere: 'Drop files here',
  DropUploadFiles: 'Drop to upload files',
  ShareContract: 'Share contract',
  InvitePeopleToContract: 'Invite people to the contract',
  InviteByEmail: 'Invite by email',
  AddLabelsToContract: 'Add labels to the contract',
  HaveNoLabels: 'You have no labels yet',
  Delete: 'Delete',
  ClearAll: 'Clear all',
  CreateLabelsToSort: 'Create labels to sort contracts',
  YourContractWill: 'Your contract will be end-to-end encrypted and securely stored',
  draft: 'Draft',
  RequestSigningContract: 'Request for signing a contract',
  CanViewContract: 'Can view contract',
  ExceededLimit: 'Exceeded limit: 50 files or 50 MB',
  FileDuplicate: 'File duplicate - this file is already uploaded',
  InvalidEmail: 'Invalid email address',
  UserAlreadyInvited: 'This user is already invited',
  BeforeCreatingContract: 'Before creating a contract, give it a name',
  ContractDraftAccessible: 'Contract draft is accessible only to the owner',
  Company: 'Company',
  DeleteDocument: 'Delete document?',
  AreYouSureDeleteDocument:
    'Are you sure? All requests for document signatures will be deleted. Invited persons will still have access to the contract.',
  Events: 'Events',
  CreateEvent: 'Create event',
  AddReminderToEvent: 'Add a reminder to your event',
  ForSecurityReasons:
    'For security reasons, all unsaved data will be deleted after viewing the analysis results.',
  ViewResults: 'View results',
  LastModifiedOn: 'Last modified on',
  Activity: 'Activity',
  YouNaveNoEvents: 'You have no events yet',
  CreateNewEvent: 'Create a new event',
  AddEvent: 'Add event',
  ReminderDescription: 'Reminder description',
  AnalysisResults: 'Analysis results',
  SelectAnalyzedDates: 'Select the analyzed dates and add to the events',
  SelectAll: 'Select all',
  UnselectAll: 'Unselect all',
  AddToEvents: 'Add to events',
  EditEvent: 'Edit event',
  Event: 'Event',
  DeleteEvent: 'Delete event',
  email: 'Email',
  ContractSigning: 'Contract signing',
  SignatureStatus: 'Signature status',
  TandC_title: 'General Terms and Conditions (T&C) of key2contract GmbH',
  Language: 'Language',
  Download: 'Download',
  PreviewsForThisFiletype: 'Previews for this filetype are not available',
  FindContractsBy: 'Type to find contracts by contract name or description',
  results: 'results',
  Uploaded: 'Uploaded',
  openContractsWithLabel: 'Open contracts with this label',
  ExportCSV: 'Export {{count}} contracts to CSV',
  ExportCSVSmall: 'Export CSV',
  PreparingCSV: 'Preparing CSV for download…',
  StartExport: 'Start Export',
  CookiePolicy: 'Cookie Policy',
  PrivacyPolicy: 'Privacy Policy',
  Search: 'Search',
  members: 'members',
  Members: 'Members',
  General: 'General',
  BackToWorkspace: 'Back to workspace',
  Recipients: 'Recipients',
  AddDocument: 'Add document',
  invitesYou: '{{name}} invites you to sign a contract',
  invitesYouKYC: '{{name}} invited you to Sign this contract with Verified Signature',
  VerifiedSignature: 'Verified Signature',
  ThisSignatureRequires: 'This signature requires KYC-verification before signing.',
  search_labels: 'Search {{count}} Labels',
  CloseDocAnalysis: 'Do you want to close document analysis results?',
  CloseDocAnalysisDescription: 'The analysis results will not be saved.',
  AddLabelsToContracts: 'Add labels to your contracts',
  RecoveryPhrase: 'Recovery Phrase',
  AddLabels: 'Add Labels',
  Continue: 'Continue',
  notPossibleInviteUser: 'Not possible to invite this user',
  SharePointSynchronizationEnabled: 'SharePoint synchronization enabled',
  SharePointSynchronization: 'SharePoint synchronization has been enabled for this contract.',
  SharePointSynchronizationDisabled: 'SharePoint synchronization disabled',
  SharePointSynchronizationHas: 'SharePoint synchronization has been disabled for this contract.',
  ResendInvite: 'Resend invite',
  ResendInvitationSuccessful: 'Invitation was sent successfully',
  InvitationResend: 'Invitation was resent to {{userName}}',
  CreateLabel: 'Create label',
  YouHaveHoAnalyzableDocuments: 'You have no documents to analyze',
  TheFormatOfTheDocumentDoesNotMeet:
    'The format of the document(s) does not meet the analysis requirements',
  AvailableFormats: 'Available formats:',
  PageNotFound: 'Page not found',
  ThePageCouldNotBeFound:
    'The page could not be found. Click the button below to go back to Contracts page.',
  GoToContracts: 'Go to Contracts',
  reminderCalendar1:
    'It is a reminder for the contract "{{contract}}" regarding the event "{{event}}"',
  reminderCalendar2: 'Event date: “{{date}}“',
  reminderCalendar3: 'Link to the reminder - {{link}}',
  'Add to calendar': 'Add to calendar',
  PleaseEnterYourFirstName: 'Please enter your first name',
  PleaseEnterYourLastName: 'Please enter your last name',
  PleaseEnterOnlyLatinCharacters: 'Please enter only latin characters',
  MyProfile: 'My profile',
  CreateAccount: 'Create account',
  IfYouContinueTheContractStatusMightChange: 'If you continue, the contract status might change.',
  ThereAreNoOpenSignatureRequestsAnymore:
    'There are no open signature requests anymore. This might change the status of the contract.',
  SortBy: 'Sort by',
  filter: 'Filter',
  verifyIdentity: 'Verify your identity',
  verifyIdentityDescription:
    'To sign a contract with Verified Signature, you must go through an identity verification process.',
  ToVerifyYourIdentity:
    'You need to verify your identity using the Nect app. The "Verify" button will redirect you to',
  forVerification: 'for verification.',
  Verify: 'Verify',
  ExpireThisWeek: 'Expire this week',
  ExpireNextWeek: 'Expire next week',
  SetUpAuthenticationApp: 'Set up Authentication App',
  ScanQRCode: '1. Scan this QR code with your authentication app',
  EnterCodeGenerated: '2. Enter the 6-digit code generated by app',
  YourAuthenticationApp: 'Or enter this code into your authentication app.',
  weRecommendGoogleAuthenticator:
    "If you don't have an authentication app yet, we recommend Google Authenticator, Authy, or Duo.",
  Device: 'Device {{count}}',
  FilterContracts: 'Filter contracts',
  contractsCounter: '1 contract',
  contractsCounter_other: '{{count}} contracts',
  NothingFound: 'Nothing found',
  NoContractsMatchingCurrentFilters: 'There are no contracts matching your current filters',
  ClearFilters: 'Clear filters',
  VerifiedUser: 'Verified user',
  ThisUserHasCompletedAnIdentityVerification: 'This user has completed an identity verification.',
  RegistrationDataIsValid: 'Registration data is valid',
  IdDocumentIsValid: 'ID document is valid',
  IdentityProofedSignature: 'Identity proofed signature',
  OnlyMyContracts: 'Only my contracts',
  incorrectAuthenticationCode: 'Looks like you entered an incorrect authentication code.',
  tfaTitle: 'Two-Factor Authentication',
  ConfirmYourPasswordToSetUpTwoFactorAuthentication:
    'Confirm your password to set up Two-Factor Authentication on this account.',
  Code: 'Code',
  EnterThe6DigitCodeGenerated: 'Enter the 6-digit code generated by your authentication app.',
  passwordtodelete:
    "You won't be able to use it for authentication. Confirm with your password to proceed.",
  DeleteDevice: 'Delete {{deviceName}}',
  AllContractsAvailableToYouWillAppearHere: 'All contracts available to you will appear here.',
  AllCompanyContractsFromAllWorkspacesWillAppearHere:
    'All company contracts from all workspaces will appear here.',
  optional: '(optional)',
  YouNeedIncludeStreetAddress: "You'll need to include a street address.",
  YouNeedIncludeCity: "You'll need to include a city.",
  YouNeedIncludeCountry: "You'll need to include a country.",
  Saved: 'Saved',
  SaveInfo: 'Save info',
  Billing: 'Billing',
  BillingInfo: 'Billing info',
  emptyArchiveDescription: 'All archived contracts will appear here.',
  Role: 'Role',
  SearchForMembers: 'Search for members…',
  Update: 'Update',
  WsInformationUpdated: 'Workspace information has been updated.',
  DeleteLabel: 'Delete {{tagName}}?',
  PleaseEnterContinue: 'Please enter "{{title}}" to continue',
  EnterContinue: 'Enter "{{title}}" to continue',
  workspaceWasDeleted: '{{title}} was deleted',
  PleaseEnterRequiredWWsName: 'Please enter the required workspace name.',
  WorkspaceHasBeenRemoved: 'Workspace has been removed and is no longer available.',
  Overview: 'Overview ',
  gotIt: 'Got it',
  SelectedContractsCannot: 'Selected contracts cannot be archived',
  ArchivingOnlyPossible:
    'Archiving is only possible for contracts with "Pending" and "Expired” status.',
  Archive: 'Archive',
  AddSomeDetails: 'Add some details…',
  ThisContractWasImported: 'This contract was imported with AI data analysis.',
  IfYouContinue:
    'If you continue, you agree to authorize the contract with an electronic signature.',
  IHaveReadAndUnderstood:
    'I have read and understood the content of the contract and the annexes and would like to sign the contract including the annexes.',
  ThisFileProtected: 'This file is protected from changes',
  FilesWhichProtected: 'Files which are protected from changes can not be uploaded.',
  SetUpTwoFactorAuthentication: 'Set up Two-Factor Authentication',
  ItIsYourCompanyPolicyToSetAuthentication:
    "It is your company's policy to set up a second authentication method to protect your account.",
  SetUpTFA: 'Set up 2FA',
  pageNOfN: 'Page {{count}} of {{count2}}',
  Modified: 'Modified',
  proofOfSignature: 'Proof of Signature',
  GeneratedOn: 'Generated On',
  ContractTitle: 'Contract Title',
  ContractDescription: 'Contract Description',
  ContractOwner: 'Contract Owner',
  ListSigners: 'List of Signers',
  LinkToContract: 'Link to Contract',
  DocumentList: 'Document List',
  DocumentHash: 'Document Hash (SHA3-256)',
  SignatureTimestamp: 'Signature Timestamp',
  DigitalSignature: 'Digital Signature (ECDSA)',
  TransactionID: 'Transaction ID',
  InhubberProofSignature: 'Inhubber Proof of Signature',
  Information: 'Information',
  Email: 'Email',
  SearchMembers: 'Search {{count}} members',
  ChangeContractOwner: 'Change contract owner',
  ToTransferOwnership:
    'To transfer ownership of a contract, make one of the company members the owner of the contract.',
  MakeOwner: 'Make Owner',
  TransferOwnership: 'Transfer Ownership',
  TransferOwnershipSubHeader:
    'Are you sure you want to make {{owner}} the owner of the contract and all contract tasks? Only the new contract owner and admins can undo this action. ',
  OwnershipTransferred: 'Ownership of the contract has been transferred',
  showMore: 'Show more',
  MultipleTransferOwnershipSubHeader:
    'Are you sure you want to make {{owner}} the owner of contracts and all contract tasks? Only company admin can undo this action.',
  More: 'More',
  OwnershipContractsTransferred: 'Ownership of the contracts has been transferred',
  MoveContractsTo: 'Move contract to...',
  MoveContractsTo_other: 'Move {{count}} contracts to...',
  MoveTo: 'Move to',
  Move: 'Move',
  ContractsMovedTo: 'Contract moved to {{workspace}}',
  ContractsMovedTo_other: '{{count}} contracts moved to {{workspace}}',
  ContractIsNowAvailableIn: 'Contract is now available in {{workspace}}',
  ContractIsNowAvailableIn_other: 'Contracts are now available in {{workspace}}',
  ExtendedSearch: 'Extended search',
  InvitePeople: 'Invite people',
  AllWorkspaceMembers: 'All workspace members',
  AllFolderMembers: 'All folder members',
  CanAccess: 'Can access',
  AddAssignees: 'Add assignees',
  EmailOrName: 'Name or email',
  Approval: 'Approval',
  AddTask: 'Add task',
  TaskTitle: 'Task title',
  WriteTaskTitle: 'Write a task title',
  AddMoreDetailsToThisTask: 'Add more details to this task…',
  AssignPeopleToApprove: 'Assign people to approve the contract.',
  Assignees: 'Assignees',
  Tasks: 'Tasks',
  YouCannotCancelAnInvitation: 'You cannot cancel an invitation for persons with assigned tasks.',
  AnAssigneeIsPerson:
    'An assignee is a person assigned to a task. Assignees are invited automatically to the contract and have the ability to view it.',
  AssigneesRemoved:
    'Assignees removed from the task will still have access to the contract, until you cancel their invitation.',
  DeleteTask: 'Delete task',
  TaskHasBeenCreated: 'Task has been created',
  TaskHasBeenDeleted: 'Task has been deleted',
  DeleteTaskQuestion: 'Delete task?',
  AreYouSureDeleteTask:
    'Are you sure? You cannot undo this action. All task assignees will still have access to the contract, until you cancel their invitation.',
  Change: 'Change',
  Confirm: 'Confirm',
  Rename: 'Rename',
  CreateContract: 'Create contract',
  SearchContracts: 'Search contracts',
  orUseCreateContractButton: 'or use “Create contract” button.',
  ThisFolderIsEmpty: 'This folder is empty',
  AddTitle: 'Add title',
  AreYouSure: 'Are you sure? You cannot undo this action.',
  TitleCanNotBeEmpty: 'Title can not be empty',
  StartDateCanNotBeEmpty: 'Start date can not be empty',
  DiscardChanges: 'Discard changes?',
  TheChangesYou: 'The changes you have made will not be saved.',
  Discard: 'Discard',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  DAY_other: 'days',
  WEEK_other: 'weeks',
  MONTH_other: 'months',
  YEAR_other: 'years',
  Review: 'Review',
  Custom: 'Custom',
  AssignPeopleToReview: 'Assign people to review the contract.',
  CreateCustomTasks: 'Create custom tasks for your needs.',
  ContractValue: 'Contract value',
  AddedDocumentsWillAppearHere: 'Added documents will appear here',
  DropFilesHere: '<0>Drop files here, or</0><1/><2>Browse</2>',
  dropFilesDescription1: 'PDF, DOCX and',
  dropFilesDescription2_marker1: 'more',
  dropFilesDescription3: ', up to 50 MB each.',
  fileTypes: 'PDF, DOCX, XLS, JPEG, SVG, MP4, MP3, ZIP and other. ',
  AddDocuments: 'Add documents',
  InviteRecipients: 'Invite recipients',
  AddedLabelsWillAppearHere: 'Added labels will appear here',
  CustomFields: 'Custom fields',
  Properties: 'Properties',
  Info: 'Info',
  Created: 'Created',
  Creator: 'Creator',
  Statistics: 'Statistics',
  Location: 'Location',
  ContractID: 'Contract ID',
  Copied: 'Copied',
  CopyContractID: 'Copy contract ID',
  AddedCustomFieldsWillAppearHere: 'Added custom fields will appear here',
  SelectPageView: 'Select the page view',
  FormView: 'Form view',
  DocumentView: 'Document view',
  hintForStartDate: 'Date on which the contract gets legally binding.',
  hintForExpiryDate:
    'Final day that the contract is in effect. After this date the contract terminates and stops to be legally binding.',
  DateUploaded: 'Date uploaded',
  DateLastModified: 'Date last modified',
  UploadedOn: 'Uploaded on',
  Contacts: 'Contacts',
  ContractsWillContinue:
    'Contracts will continue to appear in “My contracts” for each contract participant.',
  CopyTo: 'Copy to',
  CopyContractTo: 'Copy contract to...',
  CopyContractTo_other: 'Copy {{count}} contracts to',
  Copy: 'Copy',
  ContractCopiedTo: 'Contract copied to {{workspace}}',
  ContractCopiedTo_other: '{{count}} contracts copied to {{workspace}}',
  CopyOfContractIsNowAvailable: 'Copy of contract is now available in {{workspace}}',
  CopyOfContractIsNowAvailable_other: 'Copies of contracts are now available in {{workspace}}',
  CancelCopyingContracts: 'Cancel copying contracts?',
  CancelCopyingContractsDes: 'Are you sure? Copying contracts will be cancelled.',
  CancelCopying: 'Cancel copying',
  LinkedContracts: 'Linked contracts',
  LinkedContractsWillAppearHere: 'Linked contracts will appear here',
  LinkContracts: 'Link contracts',
  LinkType: 'Link type',
  UnlinkContract: 'Unlink contract?',
  AreYouSureContractNoLonger:
    'Are you sure? This contract will no longer be linked to your contract.',
  Unlink: 'Unlink',
  NoContractsAvailableToLink: 'No contracts available to link',
  Link: 'Link',
  LinkAnotherContracts: 'Link another contracts',
  LinkRelatedContracts:
    'Link related contracts with the desired relationship type. Select the link type and contracts that need to be linked.',
  ContractLinked: 'Contract linked to {{contractTitle}}',
  ContractLinked_other: '{{count}} contracts linked to {{contractTitle}}',
  LinkedContract: 'Linked contract is now available in {{contractTitle}}',
  LinkedContract_other: 'Linked contracts are now available in {{contractTitle}}',
  ContractHasBeenUnlinked: 'Contract has been unlinked',
  VerifiableOnEtherscan: 'Verifiable on etherscan',
  AddMore: 'Add more',
  Apply: 'Apply',
  InvalidRange: 'Invalid range',
  addComment: 'Add a comment...',
  comment: 'Comment',
  comments: 'Comments',
  AllResults: 'All results',
  Hide: 'Hide',
  RefineYourSearch: 'Refine your search to see other results',
  send: 'Send',
  followers: 'Followers',
  unarchive: 'Unarchive',
  'You can export':
    'You can export your contract repository. Once the export is done, we’ll email you the download link.',
  export: 'Export',
  'Export has been started': 'Export has been started',
  exportIsDone: 'Once the export is done, the link will be emailed to you ({{email}}).',
  'Add a title': 'Add a title',
  'Add a description': 'Add a description',
  one_month: '1 month',
  three_months: '3 months',
  six_months: '6 months',
  one_year: '1 year',
  two_years: '2 years',
  one_time: 'One-time',
  on_demand: 'On-demand',
  total: 'Total',
  other: 'Other',
  financials: 'Financials',
  currency: 'Currency',
  periodicity: 'Periodicity',
  includes_all: 'Includes all of the contract payments over the selected period.',
  term: 'Term',
  Payment: 'Payment',
  'Recurring or one-time payments': 'Recurring or one-time payments',
  'Recurring payments': 'Recurring payments',
  'One-time fees': 'One-time fees',
  'Add custom description': 'Add custom description',
  'Edit custom description': 'Edit custom description',
  'Custom description': 'Custom description',
  'Select file': 'Select file',
}
