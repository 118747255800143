import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { addDays, addMonths, addYears } from 'date-fns'

import { callApi } from '../utils/callApi'
import { toUTC } from '../utils/formatDate'
import { updateIncluded } from './included'

import { DATE_FILTERS, LOADING } from '../enums'

export const MAX_EVENTS = 6

export const setRequestsExpand = createAction('home/setRequestsExpand')

export const resetHomeData = createAsyncThunk('home/reset', async (payload, api) => {
  api.dispatch(syncHomeDataUpdate({ tasks: LOADING, events: LOADING }))
})

export const prepareHomeData = createAsyncThunk('home/fetch', async (payload, api) => {
  const token = api.extra.getToken()
  const { id } = api.getState().home.requestsFilter
  const { ge, lt } = getRequestsFilter(id)

  const dashboardQuery = [
    'include=pendingForMySignature.ownedBy.account,pendingForMySignature.myAccessGrant,expireSoon.ownedBy.account',
    'sort[pendingForMySignature]=-myAccessGrant.lastModified',
    `filter[expireSoon][expiryDate][GE]=${ge}`,
    `filter[expireSoon][expiryDate][LT]=${lt}`,
    'sort[expireSoon]=expiryDate',
  ].join('&')

  const dashboardData = await callApi({
    endpoint: `dashboards?${dashboardQuery}`,
    accessToken: token,
    accessName: 'Bearer',
  })

  const homeData = dashboardData.data.find((d) => d.id === 'home')

  const {
    attributes,
    relationships: { expireSoon, pendingForMySignature },
  } = homeData

  const included = (dashboardData.included || []).filter((i) => i.relationships)
  const pendingForMySignatureDA = pendingForMySignature.data.map((da) =>
    included.find((i) => i.id === da.id && i.relationships),
  )
  const expireSoonDA = expireSoon.data
    .map((da) => included.find((i) => i.id === da.id && !!i.relationships))
    .filter((da) => da.attributes.expiryDate)
  const nonDAIncluded = included?.filter((i) => i.type !== 'digitalassets') || []

  api.dispatch(updateIncluded((included || []).filter((i) => i.type !== 'pendingForMySignature')))

  api.dispatch(
    syncHomeDataUpdate({
      active: attributes.active,
      draft: attributes.draft,
      pending: attributes.pending,
      expired: attributes.expired,
      totalContracts: attributes.total,
      signed: attributes.signed,
      archived: attributes.archived,
      requests: {
        data: pendingForMySignatureDA,
        included: nonDAIncluded,
      },
      expireSoon: {
        data: expireSoonDA,
        included: nonDAIncluded,
      },
    }),
  )

  const todayStart = new Date().toISOString().slice(0, 10)
  const rightNow = toUTC(new Date(`${todayStart}T00:00:00.000Z`))

  const eventsQuery = [
    'sort=date',
    `filter[date][GE]=${rightNow}`,
    `filter[refersTo.state][neq]=archived`,
    `page[limit]=${MAX_EVENTS}`,
    'include=refersTo',
  ].join('&')

  const events = await callApi({
    endpoint: `events?${eventsQuery}`,
    accessToken: token,
    accessName: 'Bearer',
  })
  const filteredEvents = (events?.data || []).filter(
    (d) => new Date(d.attributes.date) >= new Date(rightNow),
  )

  api.dispatch(syncHomeDataUpdate({ events: { data: filteredEvents, included: events.included } }))

  const tasksQuery = [
    'include=assignees.account',
    'include=digitalAsset,attachments',
    `filter[assignedToMe]=true`,
    `filter[completedByMe]=false`,
    `sort=-created`,
    'page[limit]=5',
    'page[offset]=0',
  ].join('&')

  const tasks = await callApi({
    endpoint: `tasks?${tasksQuery}`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
    },
  })

  api.dispatch(syncHomeDataUpdate({ tasks: { data: tasks.data, included: tasks.included } }))
  api.dispatch(updateIncluded(tasks.included || []))
})

export const updateDashboardsData = createAsyncThunk('home/fetch/update', async (payload, api) => {
  const token = api.extra.getToken()

  const dashboardData = await callApi({
    endpoint: `dashboards`,
    accessToken: token,
    accessName: 'Bearer',
  })

  const homeData = dashboardData.data.find((d) => d.id === 'home')

  const { attributes } = homeData

  api.dispatch(
    syncHomeDataUpdate({
      active: attributes.active,
      draft: attributes.draft,
      pending: attributes.pending,
      expired: attributes.expired,
      totalContracts: attributes.total,
      signed: attributes.signed,
    }),
  )
})

export const syncHomeDataUpdate = createAction('home/update/sync')

export const changeExpiredSoonFilter = createAsyncThunk(
  'home/expiredSoon/filter',
  async ({ filter }, api) => {
    const token = api.extra.getToken()
    const { ge, lt } = getRequestsFilter(filter)

    api.dispatch(
      syncHomeDataUpdate({
        requestsFilter: getRequestsFilter(filter),
        expireSoon: LOADING,
      }),
    )

    const dashboardQuery = [
      'include=pendingForMySignature.ownedBy.account,pendingForMySignature.myAccessGrant,expireSoon.ownedBy.account',
      'sort[pendingForMySignature]=-myAccessGrant.lastModified',
      `filter[expireSoon][expiryDate][GE]=${ge}`,
      `filter[expireSoon][expiryDate][LT]=${lt}`,
      'sort[expireSoon]=expiryDate',
    ].join('&')

    const dashboardData = await callApi({
      endpoint: `dashboards?${dashboardQuery}`,
      accessToken: token,
      accessName: 'Bearer',
    })

    const homeData = dashboardData.data.find((d) => d.id === 'home')

    const {
      relationships: { expireSoon },
    } = homeData

    const included = (dashboardData.included || []).filter((i) => i.relationships)

    api.dispatch(updateIncluded(included || []))

    const expireSoonDA = expireSoon.data
      .map((da) => included.find((i) => i.id === da.id && i.relationships))
      .filter((da) => da.attributes.expiryDate)

    const nonDAIncluded = included?.filter((i) => i.type !== 'digitalassets') || []

    return {
      expireSoon: {
        data: expireSoonDA,
        included: nonDAIncluded,
      },
    }
  },
)

export const getRequestsFilter = (id) => {
  const date = new Date().setHours(0, 0, 0, 0)
  const formattedDate = new Date(date)

  if (id === DATE_FILTERS.DAYS7) {
    return {
      ge: toUTC(formattedDate, true),
      lt: toUTC(addDays(new Date(date), 7), true),
      id,
    }
  }

  if (id === DATE_FILTERS.DAYS30) {
    return {
      ge: toUTC(formattedDate, true),
      lt: toUTC(addDays(new Date(date), 30), true),
      id,
    }
  }

  if (id === DATE_FILTERS.MONTHS3) {
    return {
      ge: toUTC(formattedDate, true),
      lt: toUTC(addMonths(new Date(date), 3), true),
      id,
    }
  }

  if (id === DATE_FILTERS.MONTHS6) {
    return {
      ge: toUTC(formattedDate, true),
      lt: toUTC(addMonths(new Date(date), 6), true),
      id,
    }
  }

  if (id === DATE_FILTERS.YEAR_NEXT) {
    return {
      ge: toUTC(formattedDate, true),
      lt: toUTC(addYears(new Date(date), 1), true),
      id,
    }
  }

  if (id === DATE_FILTERS.ALL) {
    return {
      ge: toUTC(formattedDate, true),
      lt: toUTC(addMonths(new Date(date), 9999), true),
      id,
    }
  }

  throw new TypeError(`Invalid type: ${id}`)
}
