import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../utils/callApi'
import { updateIncluded } from './included'

export const createTag = createAsyncThunk('tags/create', async ({ json, callback }, api) => {
  const token = api.extra.getToken()

  const newTag = await callApi({
    endpoint: `tags`,
    method: 'POST',
    accessToken: token,
    accessName: 'Bearer',
    applicationJson: true,
    body: json,
  })

  api.extra.sendCallback(callback, newTag)

  return {}
})

export const getTags = createAsyncThunk(
  'tags/get',
  async ({ like, local, alreadySelected, getSelected, callback }, api) => {
    const token = api.extra.getToken()
    const signal = api.extra.signal.registerAndAbortSignal('tags')
    const filter = like ? `filter[value][like]=${encodeURIComponent(like)}` : 'filter[type]=label'
    const alreadySelectedIds =
      alreadySelected?.length > 0
        ? `&filter[id][NEQ]=${alreadySelected.map((item) => item.id).join(',')}`
        : ''
    const findIds =
      getSelected?.length > 0 ? `&filter[id]=${getSelected.map((item) => item.id).join(',')}` : ''
    const tags = await callApi({
      disablePromiseConcatenation: true,
      endpoint: `tags?${filter}${alreadySelectedIds}${findIds}&sort=value&page[limit]=12`,
      accessToken: token,
      accessName: 'Bearer',
      signal,
    })
    // eslint-disable-next-line no-await-in-loop
    await new Promise((r) => {
      setTimeout(r, 1000)
    })

    const { data, meta, links } = tags

    api.extra.sendCallback(callback, tags)
    api.dispatch(updateIncluded(data || []))
    return { tags: data, meta, filter: local ? '' : like, nextPage: links?.next }
  },
)

export const findTag = createAsyncThunk('tags/find', async ({ value, callback }, api) => {
  const token = api.extra.getToken()
  const filter = `filter[value]=${encodeURIComponent(value)}`
  const tags = await callApi({
    endpoint: `tags?${filter}`,
    accessToken: token,
    accessName: 'Bearer',
  })

  const { data } = tags

  api.extra.sendCallback(callback, data)

  return {}
})

export const continueTagsFetch = createAsyncThunk(
  'tags/continue',
  async ({ nextPageURL, callback }, api) => {
    const token = api.extra.getToken()

    const tags = await callApi({
      // TODO: fix me after backend will start to send proper protocol in "links"
      disablePromiseConcatenation: true,
      endpoint: nextPageURL.replace('http:', 'https:'),
      accessToken: token,
      accessName: 'Bearer',
      clearHost: true,
    })

    // eslint-disable-next-line no-await-in-loop
    await new Promise((r) => {
      setTimeout(r, 1000)
    })

    const { data, links } = tags

    api.extra.sendCallback(callback, tags)
    api.dispatch(updateIncluded(data || []))
    return { tags: data, nextPage: links?.next }
  },
)
