import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ecies from 'eth-ecies'
import { createSelector } from 'reselect'

import find from 'lodash/find'
import filter from 'lodash/filter'
import getToken from '../../../store/TokenExtra'
import { WORKSPACE_ROLES } from '../../../enums'

import {
  BaseInput,
  Button,
  Divider,
  DropDown,
  Elevation,
  Flex,
  ModalItem,
  Spacing,
  TextInput,
  Typography,
} from '../../atoms'
import { PersonaDetails } from '../../molecules'
import RemoveUserFromWorkspace from '../../popups/RemoveUserFromWorkspace'
import {
  checkAdminAccess,
  inviteUserToWorkspace,
  setUpdateWorkspaceMembers,
  updateWorkspace,
} from '../../../actions/workspaces'
import callApiPagination from '../../../utils/callApi/callApiPagination'
import ChangeUserWorkspaceRole from '../../popups/ChangeUserWorkspaceRole'
import { useDispatchAsync } from '../../../hooks'
import ModalOverlay from '../../atoms/Modal/ModalOverlay'
import AssigneePopup from '../../molecules/AssigneePopup'
import { ASSIGNEE_POPUPS_TYPE } from '../../../enums/AssigneePopupsType'
import { updateIncluded } from '../../../actions/included'
import ActionInput from '../../atoms/ActionInput'
import {
  companyMembershipSelector,
  companySelector,
  getCompanyAdmins,
  getCurrentUserAccount,
  selectAccountId,
  selectCurrentUser,
  selectIncludedSelector,
  selectWorkspaces,
} from '../../../store/selectors'

const WorkspaceMembersTab = ({ className, workspaceId }) => {
  const { t } = useTranslation()
  const [emailToAdd, setEmailToAdd] = useState('')
  const [workspaceMembers = [], setWorkspaceMembers] = useState([])
  const [role, setRole] = useState(WORKSPACE_ROLES.VIEWER)
  const [visible, setVisible] = useState(null)
  const [target, setTarget] = useState(null)
  const dispatchAsync = useDispatchAsync()
  const dispatch = useDispatch()

  const company = useSelector(companySelector)
  const currentUserAccount = useSelector(getCurrentUserAccount)
  const included = useSelector(selectIncludedSelector)

  const workspaceTitle = included.workspaces[workspaceId].attributes.title
  const selectAdminRole = useCallback(() => setRole(WORKSPACE_ROLES.ADMIN), [])
  const selectViewerRole = useCallback(() => setRole(WORKSPACE_ROLES.VIEWER), [])

  useEffect(() => {
    if (workspaceTitle === null && role !== WORKSPACE_ROLES.ADMIN) {
      selectAdminRole()
    }
  }, [role, selectAdminRole, workspaceTitle])

  const getMembers = useCallback(() => {
    const asyncGetMembers = async () => {
      const response = await callApiPagination({
        endpoint: `workspaces/${workspaceId}/members?include=grantedToAccount.ownedBy&sort=grantedToAccount.ownedBy.emailAddress,grantedToAccount.ownedBy.firstName,grantedToAccount.ownedBy.lastName`,
      })

      dispatch(updateIncluded(response.included))

      const workspacePersons = response.data

      setWorkspaceMembers(workspacePersons)
    }
    dispatch(updateWorkspace({ wsId: workspaceId }))
    dispatch(setUpdateWorkspaceMembers({ updateWorkspaceMembers: true }))
    asyncGetMembers()
  }, [dispatch, workspaceId])

  useEffect(() => {
    if (company?.id) {
      getMembers()
    }
  }, [company?.id, getMembers])
  const onAddClick = useCallback(
    (addAccessToFolders = false) => {
      if (visible) setVisible(false)
      const inviteToWorkspace = async () => {
        const jwt = getToken()

        const naturalPerson = find(
          included.naturalpersons,
          (obj) => obj.attributes.emailAddress === emailToAdd,
        )

        const account = included.accounts[naturalPerson?.relationships.account.data.id]

        const currentUserWorkspaceMembership = workspaceMembers.find(
          (m) => m.relationships.grantedToAccount.data.id === currentUserAccount.id,
        )
        const { encrWsPrivateKey } = currentUserWorkspaceMembership.attributes
        const userPrivateKey = Buffer.from(
          JSON.parse(localStorage.getItem('user_privateKey')).data,
          'hex',
        )
        const wsPrivateKey = ecies.decrypt(userPrivateKey, Buffer.from(encrWsPrivateKey, 'hex'))
        const encrPrivateKey = ecies
          .encrypt(Buffer.from(account.attributes.publicKey, 'hex'), wsPrivateKey)
          .toString('hex')

        await dispatchAsync(inviteUserToWorkspace, {
          workspaceId,
          role,
          encrPrivateKey,
          accountId: account.id,
          addAccessToFolders,
          jwt,
        })

        setEmailToAdd('')
        getMembers()
      }

      inviteToWorkspace()
    },
    [
      currentUserAccount.id,
      dispatchAsync,
      emailToAdd,
      getMembers,
      included.accounts,
      included.naturalpersons,
      role,
      visible,
      workspaceId,
      workspaceMembers,
    ],
  )

  const onInputChange = useCallback((e) => setEmailToAdd(e.target.value), [])

  const { userToAddIsCompanyAdmin, isAddButtonDisabled } = useMemo(() => {
    const companyMembers = filter(
      included.companymemberships,
      (obj) => obj.relationships.company.data.id === company?.id,
    )
    const accounts =
      companyMembers.map((i) => {
        const accId = i.relationships.grantedToAccount.data.id
        return included.accounts[accId]
      }) || []
    const naturalPersons = accounts.map((i) => {
      const npId = i.relationships.ownedBy.data.id
      return included.naturalpersons[npId]
    })

    const companyAdmins = filter(
      companyMembers,
      (obj) => obj.attributes.role === WORKSPACE_ROLES.ADMIN_COMPANY,
    )
    const adminsAcc =
      companyAdmins.map((i) => {
        const accId = i.relationships.grantedToAccount.data.id
        return included.accounts[accId]
      }) || []
    const adminsNaturalPersons = adminsAcc.map((i) => {
      const npId = i.relationships.ownedBy.data.id
      return included.naturalpersons[npId]
    })
    return {
      isAddButtonDisabled: !naturalPersons.find((i) => i.attributes.emailAddress === emailToAdd),
      userToAddIsCompanyAdmin: !!adminsNaturalPersons.find(
        (i) => i.attributes.emailAddress === emailToAdd,
      ),
    }
  }, [
    company?.id,
    emailToAdd,
    included.accounts,
    included.companymemberships,
    included.naturalpersons,
  ])

  useEffect(() => {
    if (userToAddIsCompanyAdmin) {
      selectAdminRole()
    }
  }, [selectAdminRole, userToAddIsCompanyAdmin])

  return (
    <div className={className}>
      <Flex>
        {visible && (
          <ModalOverlay onClick={() => setVisible(false)} data-test="modal_overlay_search" />
        )}
        <div ref={setTarget} style={{ position: 'relative', zIndex: 31, width: '100%' }}>
          <Flex width="100%">
            <TextInput
              placeholder={t('EmailOrName')}
              value={emailToAdd}
              onChange={onInputChange}
              data-test="input_workspaceSettingsModal_membersTab_emailToAdd"
              onClick={() => {
                if (!visible) setVisible(true)
              }}
            >
              <DropDown
                disabled={userToAddIsCompanyAdmin}
                tertiary
                value={t(`workspace:${role}`)}
                placement="bottom-end"
                onClick={() => {
                  if (visible) setVisible(false)
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [8, 4],
                    },
                  },
                ]}
              >
                <ModalItem
                  i18n="workspace:maintainer"
                  descriptionI18n="workspace:maintainerDesc"
                  onClick={selectAdminRole}
                  selected={role === WORKSPACE_ROLES.ADMIN}
                />
                <ModalItem
                  i18n="workspace:viewer"
                  descriptionI18n="workspace:viewerDesc"
                  onClick={selectViewerRole}
                  selected={role === WORKSPACE_ROLES.VIEWER}
                />
              </DropDown>
            </TextInput>
            {visible && (
              <AssigneePopup
                type={ASSIGNEE_POPUPS_TYPE.INVITE_TO_WORKSPACE}
                alreadyInvitedPersons={workspaceMembers}
                searchValue={emailToAdd}
                onSelect={(e) => {
                  setEmailToAdd(e)
                }}
                target={target}
                onClose={() => setVisible(false)}
                width={target?.offsetWidth}
              />
            )}
          </Flex>
        </div>
        <Spacing size="16" />
        {role === WORKSPACE_ROLES.VIEWER ? (
          <GrantAccessDropDown
            width="fit-content"
            i18n="Add"
            primary
            placement="bottom-end"
            disabled={isAddButtonDisabled}
            data-test="button_workspaceSettingsModal_membersTab_add"
            onClick={() => {
              if (visible) setVisible(false)
            }}
          >
            <ModalItem
              onClick={() => onAddClick(false)}
              data-test="dropdown_workspaceSettingsModal_membersTab_toWorkspace"
            >
              <div>
                <Typography i18n="workspace:ToWorkspace" variant="14">
                  To workspace
                </Typography>
                <Typography i18n="workspace:GrantAccessToWorkspace" variant="10">
                  Grant access to workspace.
                </Typography>
              </div>
            </ModalItem>
            <Spacing size="4" />
            <ModalItem
              onClick={() => onAddClick(true)}
              data-test="dropdown_workspaceSettingsModal_membersTab_toWorkspaceAndFolders"
            >
              <div>
                <Typography i18n="workspace:ToWorkspaceAndFolders" variant="14">
                  To workspace and folders
                </Typography>
                <Typography i18n="workspace:GrantAccessToWorkspaceAndExistingFolders" variant="10">
                  Grant access to workspace and existing folders.
                </Typography>
              </div>
            </ModalItem>
          </GrantAccessDropDown>
        ) : (
          <Button
            width="fit-content"
            i18n="Add"
            onClick={isAddButtonDisabled ? undefined : onAddClick}
            disabled={isAddButtonDisabled}
            data-test="button_workspaceSettingsModal_membersTab_add"
          >
            Add
          </Button>
        )}
      </Flex>
      <Spacing size="24" />
      {workspaceMembers.map((member, index) => (
        <Fragment key={member.id}>
          <WorkspaceMemberInfo
            {...member}
            workspaceId={workspaceId}
            getMembers={getMembers}
            workspaceMembers={workspaceMembers}
          />
          {index < workspaceMembers.length - 1 && <Spacing size="16" />}
        </Fragment>
      ))}
    </div>
  )
}

const WorkspaceMemberInfo = ({
  relationships,
  attributes,
  id,
  workspaceId,
  getMembers,
  workspaceMembers,
}) => {
  const companyMembership = useSelector(companyMembershipSelector)
  const currentUser = useSelector(selectCurrentUser)
  const accountId = useSelector(selectAccountId)
  const admins = useSelector(getCompanyAdmins)
  const included = useSelector(selectIncludedSelector)

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    currentWorkspace: workspaces.allWorkspaces[workspaceId],
  }))

  const { currentWorkspace } = useSelector(selectData)
  const workspaceTitle = currentWorkspace.attributes.title
  const workspaceMemberships =
    currentWorkspace?.relationships.members.data.map((m) => included.workspacememberships[m.id]) ||
    []

  const account = included.accounts[relationships.grantedToAccount.data.id]
  const person = included.naturalpersons[account.relationships.ownedBy.data.id]
  const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)
  const [changeRoleModal, setChangeRoleModal] = useState({ visible: false, newUserRole: '' })
  const { t } = useTranslation()
  const wsMembership = workspaceMemberships.find(
    (wsm) => wsm.relationships.grantedToAccount.data.id === accountId,
  )

  const itsNotAdmin = admins.find((a) => a.accountId === account.id) === undefined
  const isRemoveEnabled =
    currentUser.id !== person.id &&
    (checkAdminAccess(companyMembership?.attributes.role) ||
      checkAdminAccess(wsMembership?.attributes.role))

  return (
    <Flex content="space-between" data-test="workspaceSettingsModal_membersTab_workspaceMemberInfo">
      <PersonaDetails
        firstName={person.attributes.firstName}
        lastName={person.attributes.lastName}
        size="medium"
        labels={[person.attributes.emailAddress]}
        seed={person.attributes.emailAddress}
        verified={account.attributes.identVerified}
        verifiedPosition="persona"
      />
      <DropDown
        tertiary
        value={t(`workspace:${attributes.role}`)}
        placement="bottom-end"
        disabled={!isRemoveEnabled || !itsNotAdmin}
      >
        <ModalItem
          i18n="workspace:maintainer"
          descriptionI18n="workspace:maintainerDesc"
          onClick={() => {
            if (attributes.role !== WORKSPACE_ROLES.ADMIN) {
              setChangeRoleModal({ visible: true, newUserRole: WORKSPACE_ROLES.ADMIN })
            }
          }}
          selected={attributes.role === WORKSPACE_ROLES.ADMIN}
          data-test="dropdown_memberRole_workspaceSettingsModal_maintainer"
        />
        {workspaceTitle !== null && (
          <ModalItem
            i18n="workspace:viewer"
            descriptionI18n="workspace:viewerDesc"
            onClick={() => {
              if (attributes.role !== WORKSPACE_ROLES.VIEWER) {
                setChangeRoleModal({ visible: true, newUserRole: WORKSPACE_ROLES.VIEWER })
              }
            }}
            selected={attributes.role === WORKSPACE_ROLES.VIEWER}
            data-test="dropdown_memberRole_workspaceSettingsModal_viewer"
          />
        )}
        <Fragment key="cancel-invite">
          <>
            <Spacing size="4" />
            <Divider />
            <Spacing size="4" />
          </>
          <ModalItem
            i18n={t('workspace:RemoveFromWorkspace')}
            descriptionI18n={t('workspace:WillRemoveWorkspaceAccess')}
            onClick={() => setRemoveUserModalVisible(true)}
            data-test="dropdown_memberRole_workspaceSettingsModal_removeMember"
          />
        </Fragment>
      </DropDown>
      <RemoveUserFromWorkspace
        key={person.id}
        visible={removeUserModalVisible}
        onClose={() => setRemoveUserModalVisible(false)}
        firstName={person.attributes.firstName}
        lastName={person.attributes.lastName}
        workspaceId={workspaceId}
        workspaceMembershipId={id}
        confirmDelete={() => {
          getMembers()
        }}
        workspaceMembers={workspaceMembers}
      />
      <ChangeUserWorkspaceRole
        key={`changeRole_${person.id}`}
        visible={changeRoleModal.visible}
        newUserRole={changeRoleModal.newUserRole}
        onClose={() => setChangeRoleModal({ visible: false, newUserRole: '' })}
        workspaceMembershipId={id}
        confirmChanging={() => {
          getMembers()
        }}
      />
    </Flex>
  )
}

const GrantAccessDropDown = styled(DropDown)`
  z-index: 31;
  ${Elevation} {
    min-width: 264px;
  }
`

export default styled(WorkspaceMembersTab)`
  ${BaseInput} {
    margin-bottom: 0;
  }
`
