import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { useEffect, useMemo, useState } from 'react'

import { Elevation, Flex, Select, Spacing, SvgMask, Typography } from '../../atoms'
import { PersonaDetails } from '../../molecules'
import { selectCurrentUserId, selectIncludedSelector } from '../../../store/selectors'

const SelectNewOwner = ({ naturalPersons, firstName, onChange }) => {
  const { palette } = useTheme()

  const [selectNPId, setSelectNPId] = useState(null)
  const [selectName, setSelectName] = useState(undefined)

  const included = useSelector(selectIncludedSelector)
  const currentNP = useSelector(selectCurrentUserId)

  useEffect(() => {
    if (!selectNPId) {
      setSelectNPId(currentNP)
    }
  }, [currentNP, selectNPId])

  const selectPerson = useMemo(() => {
    return naturalPersons.find((i) => i.id === selectNPId)
  }, [naturalPersons, selectNPId])

  useEffect(() => {
    if (selectPerson) {
      setSelectName(`${selectPerson?.attributes.firstName} ${selectPerson?.attributes.lastName}`)
    }
  }, [naturalPersons, selectNPId, selectPerson])

  useEffect(() => {
    onChange(selectNPId)
  }, [onChange, selectNPId])

  const options = useMemo(() => {
    const movedItem = naturalPersons.find((f) => f.id === currentNP)
    if (movedItem) {
      const newArray = naturalPersons.filter((f) => f.id !== currentNP)
      newArray.unshift(movedItem)
      return newArray
    }
    return naturalPersons
  }, [currentNP, naturalPersons])

  return (
    <>
      <Typography
        i18n="workspace:SelectNewOwnerForFolders"
        i18nProps={{
          name: firstName,
        }}
        variant="body 13 regular"
        color="secondary"
      />
      <Spacing size={16} />
      <DropDownSelect value={selectName} data-test="dropDown_selectNewOwner">
        {options.map((o) => {
          const account = included.accounts[o.relationships.account.data.id]

          return (
            <Option
              onClick={() => setSelectNPId(o.id)}
              key={`select_owner_${o.id}`}
              content="space-between"
              data-test="dropDown_option_selectNewOwner"
            >
              <PersonaDetails
                firstName={o.attributes.firstName}
                lastName={o.attributes.lastName}
                size="medium"
                labels={[o.attributes.emailAddress]}
                seed={o.attributes.emailAddress}
                verified={account.attributes?.identVerified}
                verifiedPosition="persona"
              />
              <Spacing size={16} />
              {selectNPId === o.id ? (
                <SvgMask icon="checkmark" color={palette.accent.success} />
              ) : undefined}
            </Option>
          )
        })}
      </DropDownSelect>
    </>
  )
}

const Option = styled(Flex)`
  width: 100%;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  box-sizing: border-box;

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.palette.divider};
  }
`

const DropDownSelect = styled(Select)`
  ${Elevation} {
    max-height: 300px;
    overflow: auto;
    margin-top: 8px;
  }
`

export default SelectNewOwner
