// ! make sure that your async redux action will going to call CALLBACK in the end
export default (dispatch, action, payload) => {
  return new Promise((res, rej) => {
    dispatch(
      action({
        ...payload,
        callback: (data, err) => (err ? rej(data, err) : res(data)),
      }),
    )
  })
}
