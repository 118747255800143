import { createReducer } from '@reduxjs/toolkit'

import { getSharepointUser } from '../actions/sharepoint'
import { logout } from '../actions/logout'

const initialState = { naturalPersons: null }

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSharepointUser.fulfilled, (state, action) => {
      const { payload } = action
      return {
        ...state,
        naturalPersons: payload.naturalPersons,
      }
    })
    .addCase(logout, () => ({ ...initialState }))
})

export default reducer
