import { useState, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'

import BaseInput from './BaseInput'
import TextInput from './TextInput'
import Button from '../Button'
import SvgMask from '../SvgMask'

const SearchInput = ({
  icon,
  placeholder,
  onChange,
  defaultValue,
  searchBar,
  className,
  parentValue,
  parentWidth,
  ...props
}) => {
  const theme = useTheme()

  const [value, setValue] = useState(defaultValue || '')
  const iconToRender = !value ? icon || 'magnifier' : 'xmark-small'

  const innerOnChange = useCallback(
    (e) => {
      setValue(e.target.value)
      if (onChange) {
        onChange(e)
      }
    },
    [onChange],
  )

  const onClick = useCallback(() => {
    setValue('')
    if (onChange) {
      onChange({ target: { value: '' } })
    }
  }, [onChange])

  return (
    <SearchInputWrapper className={className} $width={parentWidth}>
      <TextInput
        {...props}
        type="text"
        placeholder={placeholder}
        value={typeof parentValue === 'string' ? parentValue : value}
        onChange={innerOnChange}
      >
        {value ? (
          <Button type="icon" icon={iconToRender} onClick={value ? onClick : undefined} />
        ) : (
          <SvgMask icon={iconToRender} color={theme.component.input.icon['input-color-icon']} />
        )}
      </TextInput>
    </SearchInputWrapper>
  )
}

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ $width }) => $width};
`

export default styled(SearchInput)`
  box-sizing: border-box;
`
