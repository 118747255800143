import { createReducer } from '@reduxjs/toolkit'

import { createCommentSync } from '../actions/comments'

const initialState = { data: [] }

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(createCommentSync, (state, action) => {
    const newData = [...state.data, action.payload]
    return {
      ...state,
      data: newData,
    }
  })
})
export default reducer
