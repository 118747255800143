/* eslint-disable no-underscore-dangle */
import { createReducer } from '@reduxjs/toolkit'
import { logout } from '../actions/logout'
import {
  changeLanguage,
  getMyAccount,
  getMyDevices,
  initializeNectRedirect,
  login,
  setCompanyMembership,
  setExtending,
} from '../actions/account'
import { LANGUAGES } from '../enums'
import { loadInitialReducerState } from '../store/persist'

const getDefaultLanguage = () => {
  const supportedLanguages = [LANGUAGES.DE, LANGUAGES.EN]
  const browserLanguages = navigator.languages
  return browserLanguages.find((language) => supportedLanguages.includes(language)) || LANGUAGES.EN
}

const initialState = {
  jwt: null,
  currentUser: null,
  showError: false,
  showWarning: false,
  WarningText: '',
  ErrorText: '',
  hideHeaderLink: false,
  isLoading: false,
  isAuthenticated: false,
  company: {},
  entity: {},
  extending: {},
  identState: null,
  nectRedirect: false,
  devices: [],
  companyMembership: null,
  lang: localStorage.getItem('lang') || getDefaultLanguage(),
  ...loadInitialReducerState('account', 'inhubber-redux'),
}
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    .addCase(login.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      }
    })
    .addCase(changeLanguage, (state, action) => {
      return {
        ...state,
        lang: action.payload.lang,
      }
    })
    .addCase(logout, () => {
      return initialState
    })
    .addCase(getMyAccount.fulfilled, (state, action) => {
      return {
        ...state,
        entity: { ...action.payload.account, id: action.payload.accountId },
        identState: action.payload.identState,
      }
    })
    .addCase(getMyDevices.fulfilled, (state, action) => {
      return {
        ...state,
        devices: [...action.payload.devices],
      }
    })
    .addCase(initializeNectRedirect.pending, (state) => {
      return {
        ...state,
        nectRedirect: true,
      }
    })
    .addCase(setCompanyMembership, (state, action) => {
      return {
        ...state,
        companyMembership: action.payload,
      }
    })
    .addCase(setExtending.fulfilled, (state, action) => {
      return {
        ...state,
        extending: action.payload,
      }
    })
})

export default reducer
