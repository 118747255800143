import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../../utils/callApi'
import { updateProofs } from '../../services/proofOutdated/updateProofs'
import { updateIncluded } from '../included'

const patchDigitalAsset = createAsyncThunk(
  'digitalassets/patch',
  async ({ json, id, include, sync = {}, callback }, { getState, dispatch, extra }) => {
    const userpass = localStorage.getItem('user_secret')
    const address = localStorage.getItem('user_address')

    const attributes = {
      ...getState().digitalAssets.details[id].asset.attributes,
      ...json.data.attributes,
    }

    dispatch(patchDigitalAssetSync({ attributes, id, ...sync }))

    const response = await callApi({
      endpoint: `digitalassets/${id}${include ? `?include=${include}` : ''}`,
      method: 'PATCH',
      applicationJson: true,
      body: json,
    })

    updateProofs({ data: response, address, userpass, callback })

    dispatch(updateIncluded(response.included))
    dispatch(updateIncluded([response.data]))

    return { attributes: response.data.attributes, id }
  },
)

export default patchDigitalAsset

export const patchDigitalAssetSync = createAction('digitalassets/patch/sync')
