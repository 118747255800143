export default {
  en: {
    RequestToResetPassword: 'Request to Reset password',
    EnterYourEmailAddress: 'Enter your email address to receive a new password.',
    YourEmail: 'Your Email',
    ResetPassword: 'Reset password',
    WeHaveSentAnEmail:
      'We have sent an email with a link to reset your password to the email address you provided ({{email}}).',
    ForgotPassword: 'Forgot password?',
    SelectYourRecoveryPhrase: `Select your Recovery Phrase file (or type it manually) and enter your new password twice.`,
    NewPassword: 'New password',
    PasswordResetWasSuccessful: 'Password reset was successful',
    UseYourNewPassword: 'Use your new password to log in',
    ErrorOnResetting: 'Error on resetting. Please check your recovery phrase',
    ErrorOnResettingTryAgainLater:
      'Error on resetting. Please try again later or request a new password reset.',
    SomethingWentWrong: 'Something went wrong',
    YourPasswordResetLink:
      'Your password reset link may be out of date. Please double-check the link or request a new one.',
    GetNewResetLink: 'Get a new reset link',
    ContactUs: "<0>If you haven't found the file, contact us:</0><1/><2>{{mail}}</2>",
    WhatIsTheRecoveryPhrase: 'What is the Recovery Phrase?',
    DuringRegistration:
      'During registration, you should have downloaded the file "Recovery Phrase.txt" to your device.',
    DependingOnYourBrowser:
      'Depending on your browser, the download itself may have happened unnoticed.',
    WhereIsTheRecoveryPhraseFile: 'Where is the Recovery Phrase file?',
    CheckTheDownloads:
      'Check the "Downloads" folder on the device from which you registered (PC, laptop, mobile phone) or other places where this file may be stored.',
  },
  de: {
    RequestToResetPassword: 'Anfrage zum Zurücksetzen des Passworts',
    EnterYourEmailAddress: 'Gib deine E-Mail-Adresse ein, um ein neues Passwort zu erhalten.',
    YourEmail: 'Deine E-Mail-Adresse',
    ResetPassword: 'Passwort zurücksetzen',
    WeHaveSentAnEmail:
      'Wir haben eine E-Mail mit einem Link zum Zurücksetzen des Passworts an deine E-Mail-Adresse ({{email}}) gesendet.',
    ForgotPassword: 'Passwort vergessen?',
    SelectYourRecoveryPhrase: `Lade die Wiederherstellungsphrase hoch (oder gebe sie manuell ein). Gebe das neue Passwort zweimal ein.`,
    NewPassword: 'Neues Passwort',
    PasswordResetWasSuccessful: 'Wiederherstellung des Passworts war erfolgreich',
    UseYourNewPassword: 'Benutze das neue Passwort, um dich anzumelden',
    ErrorOnResetting: 'Fehler beim Zurücksetzen. Bitte prüfen Sie die Phrase zur Wiederherstellung',
    ErrorOnResettingTryAgainLater:
      'Fehler beim Zurücksetzen. Bitte versuchen Sie es später erneut oder fordern Sie ein neues Zurücksetzen des Passworts an.',
    SomethingWentWrong: 'Etwas ist schief gelaufen',
    YourPasswordResetLink:
      'Dein Link zum Zurücksetzen des Passworts ist möglicherweise veraltet. Bitte überprüfe den Link oder fordere dir einen neuen Link an.',
    GetNewResetLink: 'Holen Sie sich einen neuen Reset-Link',
    ContactUs:
      '<0>Wenn Sie die Datei nicht gefunden haben, kontaktieren Sie uns:</0><1/><2>{{mail}}</2>',
    WhatIsTheRecoveryPhrase: 'Was ist die Wiederherstellungsphrase?',
    DuringRegistration:
      'Während der Registrierung sollten Sie die Datei "Recovery Phrase.txt" auf Ihr Gerät heruntergeladen haben.',
    DependingOnYourBrowser: 'Je nach Browser kann der Download selbst unbemerkt erfolgt sein.',
    WhereIsTheRecoveryPhraseFile: 'Wo ist die Datei mit der Wiederherstellungsphrase?',
    CheckTheDownloads:
      'Überprüfen Sie den "Downloads"-Ordner auf dem Gerät, von dem aus Sie sich registriert haben (PC, Laptop, Mobiltelefon) oder andere Orte, an denen diese Datei gespeichert sein könnte.',
  },
  fr: {
    RequestToResetPassword: 'Demande de réinitialisation du mot de passe',
    EnterYourEmailAddress: 'Saisissez votre Email pour recevoir un nouveau mot de passe.',
    YourEmail: 'Votre Email',
    ResetPassword: 'Réinitialiser le mot de passe',
    WeHaveSentAnEmail:
      "Nous avons envoyé un e-mail avec un lien pour réinitialiser votre mot de passe à l'adresse e-mail que vous avez fournie ({{email}}).",
    ForgotPassword: 'Mot de passe oublié?',
    SelectYourRecoveryPhrase: `Sélectionnez votre fichier de phrase de récupération (ou tapez-la manuellement) et saisissez deux fois votre nouveau mot de passe.`,
    NewPassword: 'nouveau mot de passe',
    PasswordResetWasSuccessful: 'La réinitialisation du mot de passe a réussi',
    UseYourNewPassword: 'Utilisez votre nouveau mot de passe pour vous connecter',
    ErrorOnResetting:
      'Erreur lors de la réinitialisation. Veuillez vérifier votre phrase de récupération',
    ErrorOnResettingTryAgainLater:
      'Erreur lors de la réinitialisation. Veuillez réessayer plus tard ou demander une nouvelle réinitialisation du mot de passe.',
    SomethingWentWrong: "Quelque chose n'a pas fonctionné",
    YourPasswordResetLink:
      'Le lien de réinitialisation de votre mot de passe est peut-être obsolète. Veuillez revérifier le lien ou en demander un nouveau.',
    GetNewResetLink: 'Obtenir un nouveau lien de réinitialisation',
    ContactUs: "<0>Si vous n'avez pas trouvé le fichier, contactez-nous:</0><1/><2>{{mail}}</2>",
    WhatIsTheRecoveryPhrase: "Qu'est-ce que la phrase de récupération?",
    DuringRegistration:
      "Lors de l'enregistrement, vous devez avoir téléchargé le fichier Recovery Phrase.txt sur votre appareil.",
    DependingOnYourBrowser:
      'Selon votre navigateur, le téléchargement lui-même peut être passé inaperçu.',
    WhereIsTheRecoveryPhraseFile: 'Où se trouve le fichier de la phrase de récupération ?',
    CheckTheDownloads:
      "Vérifiez le dossier Telechargements de l'appareil à partir duquel vous vous êtes inscrit (PC, ordinateur portable, téléphone portable) ou d'autres endroits où ce fichier peut être stocké.",
  },
}
