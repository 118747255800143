import { createReducer } from '@reduxjs/toolkit'

import { loadInitialReducerState } from '../store/persist'

import { logout } from '../actions/logout'
import { continueTagsFetch, getTags } from '../actions/tags'

const initialState = {
  tags: [],
  meta: {},
  filter: '',
  nextPage: '',
  isLoading: false,
}
const reducer = createReducer(
  {
    ...initialState,
    ...loadInitialReducerState('tags', 'inhubber-redux'),
  },
  (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        return { ...state, isLoading: true }
      })
      .addCase(getTags.fulfilled, (state, action) => {
        const { payload } = action
        return { ...state, ...payload, isLoading: false }
      })
      .addCase(continueTagsFetch.pending, (state) => {
        return { ...state, nextPage: '', isLoading: true }
      })
      .addCase(continueTagsFetch.fulfilled, (state, action) => {
        const { payload } = action
        return {
          ...state,
          tags: [...state.tags, ...payload.tags],
          nextPage: payload.nextPage,
          isLoading: false,
        }
      })
      .addCase(logout, () => ({ ...initialState }))
  },
)

export default reducer
