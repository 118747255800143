export default {
  en: {
    modalDraftQuestion: 'Delete draft?',
    modalDraftQuestion_other: 'Delete {{count}} drafts?',
    modalDraftDescription: 'The draft will be deleted and cannot be restored.',
    modalDraftDescription_other: 'The drafts will be deleted and cannot be restored.',
  },
  de: {
    modalDraftQuestion: 'Entwurf löschen?',
    modalDraftQuestion_other: '{{count}} Entwürfe löschen?',
    modalDraftDescription: 'Der Entwurf wird gelöscht und kann nicht wiederhergestellt werden.',
    modalDraftDescription_other:
      'Entwürfe werden gelöscht und können nicht wieder hergestellt werden.',
  },
  fr: {
    modalDraftQuestion: 'Supprimer le brouillon?',
    modalDraftQuestion_other: 'Supprimer {{count}} brouillons?',
    modalDraftDescription: 'Le brouillon sera supprimé et ne pourra pas être restauré.',
    modalDraftDescription_other:
      'Les brouillons seront supprimés et ne pourront pas être restaurés.',
  },
}
