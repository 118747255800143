export default {
  Log_Out: 'Se déconnecter',
  Login: 'Se connecter',
  contracts: 'Contrats',
  th_created_on: 'Créé le',
  th_owner: 'Propriétaire',
  th_workspace: "L'espace de travail",
  active: 'Actif',
  pending: 'En attente',
  pendingLoading: 'En attente…',
  documents: 'Documents',
  cancel: 'Annuler',
  Create: 'Créer',
  Title: 'Titre',
  Convert: 'Convertir',
  view: 'Vue',
  remove: 'retirer',
  Remove: 'Retirer',
  back: 'Retour',
  Description: 'Description',
  Save: 'Sauvegarder',
  Dates: 'Dates',
  Close: 'Fermer',
  Status: 'Statut',
  sign: 'Signer',
  size: 'Taille:',
  Detail: 'Détails',
  Invite: 'Inviter',
  LogIn: 'Se connecter',
  Password: 'Mot de passe',
  SignUp: "S'enregistrer",
  accepted: 'accepté',
  AccountType: 'Type de compte:',
  PublicKey: 'Clé publique:',
  BlockchainAddress: 'Adresse de la Blockchain:',
  issuer: 'Émetteur',
  basic: 'Basique',
  TandC: 'Conditions générales',
  testingTermsPolicies: "Conditions & politiques d'INHUBBER",
  value: 'Valeur',
  Labels: 'Étiquettes',
  CreateLabels: 'Créer des étiquettes',
  TagAlreadyCreated: "L'étiquette existe déjà",
  SignContract: 'Signer le contrat',
  AddCustomDate: 'Ajouter une date personnalisée',
  AddDate: 'Ajouter une date',
  Add: 'Ajouter',
  Reminders: 'Rappels',
  AddReminder: 'Ajouter un rappel',
  RemindMe: 'Me rappeler',
  before: 'avant',
  after: 'après',
  Reminder: 'Rappel',
  docAnalysis: 'Analyse',
  Analyse: 'Analyser',
  NoResults: 'Aucun résultats',
  PasswordsDontMatch: 'Le mot de passe ne correspond pas',
  declined: 'Décliné',
  signed: 'Signé',
  expired: 'Expiré',
  archived: 'Archivé',
  start_date: 'Date de début',
  expiry_date: "Date d'expiration",
  expiryDateError: "La date d'expiration doit être après à la date de début",
  startDateError: "La date de début doit être antérieure à la date d'expiration",
  LogInINHUBBER: 'Se connecter à INHUBBER',
  LoadSecurityKey: 'Changer la clé de sécurité',
  NextGenerationContract: 'Nouvelle génération de management des contrats',
  ContractsWorkForYou: 'Laissez vos contrats travailler pour vous',
  AlreadyHaveAccount: 'Avez-vous déjà un compte?',
  SignUpAccount: 'Se connecter à son compte',
  SecurityKey: 'Clé de sécurité',
  name: 'Nom',
  someFields: 'certains champs (Date, Description) ne sont pas renseignés',
  closeAnalysis: "Résultats de l'analyse approfondie",
  OrganizeContracts: 'Organiser votre contrats',
  fileAccepted: 'Clé de sécurité acceptée',
  InviteAll: 'Inviter tout le monde',
  Invited: 'Invités',
  Profile: 'Profil',
  SortUp: 'Trier',
  SortDown: 'Trier vers la bas',
  CancelSort: 'Annuler le tri',
  startDate: 'Date de début',
  ContractsDropFilesHere: 'Déposer les fischiers ici',
  DropUploadFiles: 'Déposer pour télécharger',
  ShareContract: 'Partager contrat',
  InvitePeopleToContract: 'Inviter les personnes au contrat',
  InviteByEmail: 'Inviter par email',
  AddLabelsToContract: 'Ajouter des étiquettes au contrat',
  HaveNoLabels: "Vous n'avez pas encore d'étiquettes",
  Delete: 'Supprimer',
  ClearAll: 'tout supprimer',
  CreateLabelsToSort: 'Créer des étiquettes pour trier les contrats',
  YourContractWill: 'Votre contrat sera crypté de bout en bout et stocké en toute sécurité',
  draft: 'Brouillon',
  RequestSigningContract: "Demande de signature d'un contrat",
  CanViewContract: 'Peut consulter le contrat',
  ExceededLimit: 'Limite dépassée: 50 fischiers ou 50 MB',
  FileDuplicate: 'Fischier dupliqué - ce fichier a déjà été téléchargé',
  InvalidEmail: 'Addresse email invalide',
  UserAlreadyInvited: 'Cet utilisateur est déjà invité',
  BeforeCreatingContract: 'Avant de créer un contrat, donnez-lui un nom',
  ContractDraftAccessible: "Le projet de contrat n'est accessible qu'au propriétaire",
  Company: 'Enterprise',
  DeleteDocument: 'Supprimer le document?',
  AreYouSureDeleteDocument: 'Êtes-vous sûr de vouloir supprimer le document ci-joint?',
  Events: 'Événements',
  CreateEvent: 'Créer un événement',
  AddReminderToEvent: 'Ajouter un rappel à votre événement',
  ForSecurityReasons:
    "Pour des raisons de sécurité, toutes les données non sauvegardées seront supprimées après l'affichage des résultats de l'analyse.",
  ViewResults: 'Voir les résultats',
  LastModifiedOn: 'Dernière modification le',
  Activity: 'Activité',
  YouNaveNoEvents: "Vous n'avez pas encore d'événements",
  CreateNewEvent: 'Créer un nouvel événement',
  AddEvent: 'Ajouter un événement',
  ReminderDescription: 'Description du rappel',
  AnalysisResults: "Résultat de l'analyse",
  SelectAnalyzedDates: 'Sélectionnez les dates analysées et ajoutez-les aux événements.',
  SelectAll: 'Sélectionner tout',
  UnselectAll: 'Desélectionner tout',
  AddToEvents: 'Ajouter aux événements',
  EditEvent: 'Éditer un événement',
  Event: 'Événement',
  DeleteEvent: "Supprimer l'événement",
  email: 'Email',
  ContractSigning: 'Signature du contrat',
  SignatureStatus: 'Statut de la signature',
  TandC_title: "Conditions générales d'utilisation (CGU) de key2contract GmbH",
  Language: 'Langue',
  Download: 'Télécharger',
  PreviewsForThisFiletype: 'Les aperçus pour ce type de fichier ne sont pas disponibles',
  FindContractsBy: 'Tapez pour trouver des contrats par leur nom ou leur description',
  results: 'résultats',
  Uploaded: 'Téléchargé',
  openContractsWithLabel: 'Contrats ouverts avec ce label',
  ExportCSV: 'Exporter les contrats {{count}} au format CSV',
  ExportCSVSmall: 'Exporter CSV',
  PreparingCSV: 'Préparation du CSV pour le téléchargement…',
  StartExport: "Démarrer l'exportation",
  CookiePolicy: 'Politique des cookies',
  PrivacyPolicy: 'Politique de confidentialité',
  Search: 'Recherche',
  members: 'membre',
  Members: 'Membres',
  General: 'Général',
  BackToWorkspace: "Retour à l'espace de travail",
  Recipients: 'Destinataires',
  AddDocument: 'Ajouter un document',
  invitesYou: '{{name}} vous invite à signer un contrat',
  invitesYouKYC: '{{name}} vous a invité(e) à Signer ce contrat avec une Signature vérifiée',
  VerifiedSignature: 'Signature vérifiée',
  ThisSignatureRequires: 'Cette signature nécessite une vérification KYC avant la signature.',
  search_labels: 'Recherche {{count}} Étiquettes',
  CloseDocAnalysis: "Voulez-vous clôturer les résultats de l'analyse des documents?",
  CloseDocAnalysisDescription: "Les résultats de l'analyse ne seront pas sauvegardés.",
  AddLabelsToContracts: 'Ajouter des étiquettes à vos contrats',
  RecoveryPhrase: 'Phrase de récupération',
  AddLabels: 'Ajouter des étiquettes',
  Continue: 'Continuer',
  notPossibleInviteUser: "Impossible d'inviter cet utilisateur",
  SharePointSynchronizationEnabled: 'Synchronisation du SharePoint activée',
  SharePointSynchronization: 'La synchronisation SharePoint a été activée pour ce contrat.',
  SharePointSynchronizationDisabled: 'Synchronisation SharePoint désactivée',
  SharePointSynchronizationHas: 'La synchronisation SharePoint a été désactivée pour ce contrat.',
  ResendInvite: "Renvoyer l'invitation",
  ResendInvitationSuccessful: "L'invitation a été envoyée avec succès",
  InvitationResend: "L'invitation a été renvoyée à {{userName}}",
  CreateLabel: 'Créer une étiquette',
  YouHaveHoAnalyzableDocuments: "Vous n'avez pas de documents à analyser",
  TheFormatOfTheDocumentDoesNotMeet:
    "Le format du (des) document(s) ne répond pas aux exigences de l'analyse",
  AvailableFormats: 'Formats disponibles:',
  PageNotFound: 'Page non trouvée',
  ThePageCouldNotBeFound:
    "La page n'a pas été trouvée. Cliquez sur le bouton ci-dessous pour revenir à la page Contrats.",
  GoToContracts: 'Aller aux contrats',
  reminderCalendar1:
    "Il s'agit d'un rappel pour le contrat {{contract}} concernant l'événement {{event}}",
  reminderCalendar2: "Date de l'événement: {{date}}",
  reminderCalendar3: 'Lien vers le rappel - {{link}}',
  'Add to calendar': 'Ajouter au calendrier',
  PleaseEnterYourFirstName: 'Veuillez saisir votre prénom',
  PleaseEnterYourLastName: 'Veuillez saisir votre nom',
  PleaseEnterOnlyLatinCharacters: "Veuillez saisir uniquement des caractères de l'alphabet latins",
  MyProfile: 'Mon profil',
  CreateAccount: 'Créer un compte',
  IfYouContinueTheContractStatusMightChange:
    'Si vous continuez, le statut du contrat pourrait changer.',
  ThereAreNoOpenSignatureRequestsAnymore:
    "Il n'y a plus de demande de signature ouverte. Cela pourrait modifier le statut du contrat.",
  SortBy: 'Trier par',
  filter: 'Filtrer',
  verifyIdentity: 'Vérifiez votre identité',
  verifyIdentityDescription:
    "Pour signer un contrat avec une Signature vérifiée, vous devez passer par un processus de vérification d'identité.",
  ToVerifyYourIdentity:
    "Vous devez vérifier votre identité en utilisant l'application Nect. Le bouton Verifier vous redirigera vers",
  forVerification: 'pour vérification.',
  Verify: 'Vérifier',
  QuickFilters: 'Filtres rapides',
  ByStatus: 'Par statut',
  ExpireThisWeek: 'Expire cette semaine',
  ExpireNextWeek: 'Expire la semaine prochaine',
  SetUpAuthenticationApp: "Mise en place de l'application d'authentification",
  ScanQRCode: "1. Scannez ce code QR avec votre application d'authentification",
  EnterCodeGenerated: "2. Saisir le code à 6 chiffres généré par l'app",
  YourAuthenticationApp: 'Ou saisissez ce code dans votre application.',
  weRecommendGoogleAuthenticator:
    "Si vous n'avez pas encore d'application d'authentification, nous vous recommandons Google Authenticator, Authy ou Duo.",
  Device: 'Appareil {{count}}',
  FilterContracts: 'Filtrer les contrats',
  contractsCounter: '1 contrat',
  contractsCounter_other: '{{count}} contrats',
  NothingFound: "Rien n'a été trouvé",
  NoContractsMatchingCurrentFilters: 'Aucun contrat ne correspond à vos filtres actuels',
  ClearFilters: 'Effacer les filtres',
  VerifiedUser: 'Utilisateur vérifié',
  ThisUserHasCompletedAnIdentityVerification:
    "Cet utilisateur a effectué une vérification d'identité.",
  RegistrationDataIsValid: "Les données d'enregistrement sont valides",
  IdDocumentIsValid: "Le document d'identité est valide",
  IdentityProofedSignature: "Signature avec preuve d'identité",
  OnlyMyContracts: 'Seulement mes contrats',
  incorrectAuthenticationCode:
    "Il semble que vous ayez saisi un code d'authentification incorrect.",
  tfaTitle: 'authentification à deux facteurs',
  ConfirmYourPasswordToSetUpTwoFactorAuthentication:
    "Confirmez votre mot de passe pour configurer l'authentification à deux facteurs sur ce compte.",
  Code: 'Code',
  byLabels: 'Par étiquettes',
  EnterThe6DigitCodeGenerated:
    "Saisissez le code à 6 chiffres généré par votre application d'authentification.",
  passwordtodelete:
    "Vous ne pourrez pas l'utiliser pour l'authentification. Confirmez avec votre mot de passe pour continuer.",
  DeleteDevice: 'Suprimer {{deviceName}}',
  AllContractsAvailableToYouWillAppearHere:
    'Tous les contrats qui vous sont disponibles apparaitront ici.',
  AllCompanyContractsFromAllWorkspacesWillAppearHere:
    "Tous les contrats d'entreprise de tous les espaces de travail apparaîtront ici.",
  optional: '(optionnel)',
  YouNeedIncludeStreetAddress: 'Vous devez indiquer une adresse postale.',
  YouNeedIncludeCity: 'Vous devez inclure une ville.',
  YouNeedIncludeCountry: 'Vous devez inclure un pays.',
  Saved: 'Sauvé',
  SaveInfo: 'Sauver info',
  Billing: 'Facturation',
  BillingInfo: 'Information de facturation',
  emptyArchiveDescription: 'Tous les contrats archivés apparaîtront ici.',
  Role: 'Rôle',
  SearchForMembers: 'Recherche de membres…',
  Update: 'Mise à jour',
  WsInformationUpdated: "Les informations sur l'espace de travail ont été mises à jour.",
  DeleteLabel: 'Supprimer {{tagName}}?',
  PleaseEnterContinue: 'Veuillez saisir "{{title}}" pour continuer',
  EnterContinue: 'Entrez le "{{title}}" pour continuer',
  workspaceWasDeleted: '{{title}} a été supprimé',
  PleaseEnterRequiredWWsName: "Veuillez saisir le nom de l'espace de travail requis.",
  WorkspaceHasBeenRemoved: "L'espace de travail a été supprimé et n'est plus disponible.",
  Overview: 'Aperçu',
  gotIt: "J'ai compris",
  SelectedContractsCannot: 'Les contrats sélectionnés ne peuvent pas être archivés',
  ArchivingOnlyPossible:
    "L'archivage n'est possible que pour les contrats dont le statut est en attente ou expiré.",
  Archive: 'Archiver',
  AddSomeDetails: 'Ajouter quelques détails…',
  ThisContractWasImported: "Ce contrat a été importé avec l'analyse des données de l'IA.",
  IfYouContinue: 'Si vous continuez, vous autoriser le contrat par une signature électronique.',
  IHaveReadAndUnderstood:
    "J'ai lu et compris le contenu du contrat et de ses annexes et je souhaite signer le contrat et ses annexes.",
  ThisFileProtected: 'Ce fichier est protégé contre les modifications',
  FilesWhichProtected:
    'Les fichiers qui sont protégés contre les modifications ne peuvent pas être téléchargés.',
  SetUpTwoFactorAuthentication: "Mise en place de l'authentification à deux facteurs",
  ItIsYourCompanyPolicyToSetAuthentication:
    "c'est à la politique de votre entreprise est de mettre en place une deuxième méthode d'authentification pour protéger votre compte.",
  SetUpTFA: 'Mettre en place 2FA',
  pageNOfN: 'Page {{count}} de {{count2}}',
  Modified: 'Modifié',
  proofOfSignature: 'Preuve de signature',
  GeneratedOn: 'Généré Le',
  ContractTitle: 'Titre du contrat',
  ContractDescription: 'Description du Contrat',
  ContractOwner: 'Propriétaire du Contrat',
  ListSigners: 'Liste des Signataires',
  LinkToContract: 'Lien vers le Contrat',
  DocumentList: 'Liste des Documents',
  DocumentHash: "Corrélation entre le Document et l'Information (SHA3-256)",
  SignatureTimestamp: 'Signature Horodatage',
  DigitalSignature: 'Signature Numérique (ECDSA)',
  TransactionID: 'ID de la Transaction',
  InhubberProofSignature: 'Preuve de la Signature Inhubber',
  Information: 'Information',
  Email: 'Email',
  SearchMembers: 'Recherche {{count}} membres',
  ChangeContractOwner: 'Modifier le titulaire du contrat',
  ToTransferOwnership:
    "Pour transférer la propriété d'un contrat, faites de l'un des membres de l'entreprise le propriétaire du contrat.",
  MakeOwner: 'Faire Propriétaire',
  TransferOwnership: 'Transfert de Propriété',
  TransferOwnershipSubHeader:
    'Êtes-vous sûr de vouloir faire {{owner}} le propriétaire du contrat et de toutes les tâches liées au contrat?  Seuls le nouveau propriétaire du contrat et les administrateurs peuvent annuler cette action.',
  OwnershipTransferred: 'La propriété du contrat a été transférée',
  showMore: 'En savoir plus',
  MultipleTransferOwnershipSubHeader:
    "Êtes-vous sûr de vouloir faire {{owner}} le propriétaire des contrats et de toutes les tâches liées aux contrats? Seul l'administrateur de la société peut annuler cette action.",
  More: 'Plus',
  OwnershipContractsTransferred: 'La propriété des contrats a été transférée',
  MoveContractsTo: 'Déplacer le contrat vers...',
  MoveContractsTo_other: 'Déplacer {{count}} contrats vers...',
  MoveTo: 'Déplacer vers',
  Move: 'Déplacer',
  ContractsMovedTo: 'Le contrat a été déplacé vers {{workspace}}',
  ContractsMovedTo_other: '{{count}} contrats déplacés vers {{workspace}}',
  ContractIsNowAvailableIn: 'Le Contrat est maintenant disponible dans {{workspace}}',
  ContractIsNowAvailableIn_other: 'Les Contrats sont désormais disponibles dans {{workspace}}',
  ExtendedSearch: 'Recherche étendue',
  InvitePeople: 'Inviter des personnes',
  AllWorkspaceMembers: "Tous les membres de l'espace de travail",
  AllFolderMembers: 'Tous les membres du dossier',
  CanAccess: 'Peut accéder',
  AddAssignees: 'Ajouter des assignés',
  EmailOrName: 'Nom ou email',
  Approval: 'Approbation',
  AddTask: 'Ajouter une tâche',
  TaskTitle: 'Titre de la tâche',
  WriteTaskTitle: 'Écrire un titre de tâche',
  AddMoreDetailsToThisTask: 'Ajouter plus de détails à cette tâche…',
  AssignPeopleToApprove: "Désigner des personnes chargées d'approuver le contrat.",
  Assignees: 'Assignees',
  Tasks: 'Tâche',
  YouCannotCancelAnInvitation:
    'Vous ne pouvez pas annuler une invitation pour les personnes auxquelles des tâches ont été attribuées.',
  AnAssigneeIsPerson:
    'Un assignee est une personne affectée à une tâche. Les cessionnaires sont automatiquement invités au contrat et ont la possibilité de le consulter.',
  AssigneesRemoved:
    "Les assignés retirés de la tâche auront toujours accès au contrat, jusqu'à ce que vous annuliez leur invitation.",
  DeleteTask: 'Supprimer une tâche',
  TaskHasBeenCreated: 'La tâche a été créé',
  TaskHasBeenDeleted: 'La tâche a été supprimée',
  DeleteTaskQuestion: 'Supprimer la tâche?',
  AreYouSureDeleteTask:
    "Êtes-vous sûr? Vous ne pouvez pas annuler cette action. Tous les attributaires de tâches auront toujours accès au contrat, jusqu'à ce que vous annuliez leur invitation.",
  Change: 'Changer',
  Confirm: 'Confirmer',
  Rename: 'Renommer',
  CreateContract: 'Créer un contrat',
  SearchContracts: 'Recherche de contrat',
  orUseCreateContractButton: 'ou utilisez le bouton “Créer un contrat”.',
  ThisFolderIsEmpty: 'Ce dossier est vide',
  AddTitle: 'Ajouter un titre',
  AreYouSure: 'Êtes-vous sûr? Vous ne pouvez pas annuler cette action.',
  TitleCanNotBeEmpty: 'Le titre ne peut pas être vide',
  StartDateCanNotBeEmpty: 'La date de début ne peut pas être vide',
  DiscardChanges: 'rejeter les modifications?',
  TheChangesYou: 'Les modifications apportées ne seront pas sauvegardées.',
  Discard: 'Écarter',
  DAY: 'jour',
  WEEK: 'semaine',
  MONTH: 'mois',
  YEAR: 'année',
  DAY_other: 'jours',
  WEEK_other: 'semaines',
  MONTH_other: 'mois',
  YEAR_other: 'années',
  Review: 'Révision',
  Custom: 'Personaliser',
  AssignPeopleToReview: 'Assigner des personnes à la révision du contrat.',
  CreateCustomTasks: 'Créer des tâches personnalisées en fonction de vos besoins.',
  ContractValue: 'Valeur du contrat',
  AddedDocumentsWillAppearHere: 'Les documents ajoutés appataîtront ici',
  DropFilesHere: '<0>Déposer les fischiers ici, ou</0><1/><2>chercher</2>',
  dropFilesDescription1: 'PDF, DOCX et',
  dropFilesDescription2_marker1: 'plus',
  dropFilesDescription3: ", jusq'à 50 MB chacun.",
  fileTypes: 'PDF, DOCX, XLS, JPEG, SVG, MP4, MP3, ZIP et autres. ',
  AddDocuments: 'Ajouter des documents',
  InviteRecipients: 'Inviter les destinataires',
  AddedLabelsWillAppearHere: 'Les étiquettes ajoutées apparaîtront ici',
  CustomFields: 'Champs personnalisés',
  Properties: 'Propriétés',
  Info: 'Info',
  Created: 'Créé',
  Creator: 'Créateur',
  Statistics: 'Statistiques',
  Location: 'Localisation',
  ContractID: 'ID du Contrat',
  Copied: 'Copié',
  CopyContractID: "Copie de l'ID du contrat",
  AddedCustomFieldsWillAppearHere: 'Le champ personnalisé ajouté apparaîtra ici',
  SelectPageView: 'Sélectionner la vue de la page',
  FormView: 'Vue du formulaire',
  DocumentView: 'Vue du document',
  hintForStartDate: 'Date à laquelle le contrat devient juridiquement contraignant.',
  hintForExpiryDate:
    "Dernier jour où le contrat est en vigueur. Après cette date, le contrat prend fin et n'a plus aucune valeur juridique.",
  DateUploaded: 'Date de téléchargement',
  DateLastModified: 'Date de la dernière modification',
  UploadedOn: 'Téléchargé sur',
  Contacts: 'Contacts',
  ContractsWillContinue:
    "Les contrats continueront d'apparaître dans la rubrique “Mes contrats” pour chaque participant au contrat.",
  CopyTo: 'Copier dans',
  CopyContractTo: 'Copier du contrat dans...',
  CopyContractTo_other: 'Copie de {{count}} contrats dans',
  Copy: 'Copie',
  ContractCopiedTo: 'Contrats copiés dans {{workspace}}',
  ContractCopiedTo_other: '{{count}} contrats copiés dans {{workspace}}',
  CopyOfContractIsNowAvailable: 'Une copie du contrat est désormais disponible dans {{workspace}}',
  CopyOfContractIsNowAvailable_other:
    'Des copies des contrats sont désormais disponibles dans {{workspace}}',
  CancelCopyingContracts: 'Annuler les contrats de copie?',
  CancelCopyingContractsDes: 'Vous-êtes sûr? La copie des contrats sera annulée.',
  CancelCopying: 'Annuler le copiage',
  LinkedContracts: 'Contrats liés',
  LinkedContractsWillAppearHere: 'Les contrats liés apparaîtront ici',
  LinkContracts: 'Lier les contrats',
  LinkType: 'Type de lien',
  UnlinkContract: 'Dissociation du contrat?',
  AreYouSureContractNoLonger: 'Êtes-vous sûr ? Ce contrat ne sera plus lié à votre contrat.',
  Unlink: 'Délier',
  NoContractsAvailableToLink: 'Pas des contrats disponibles à lier',
  Link: 'Lier',
  LinkAnotherContracts: 'Lier un autre contrat',
  LinkRelatedContracts:
    'Liez les contrats liés avec le type de lien souhaité. Sélectionnez le type de lien et les contrats à lier.',
  ContractLinked: 'Contrat lié à {{contractTitle}}',
  ContractLinked_other: '{{count}} contrats liés à {{contractTitle}}',
  LinkedContract: 'Le contrat lié est maintenant disponible en {{contractTitle}}',
  LinkedContract_other: 'Les contrats liés sont désormais disponibles en {{contractTitle}}',
  ContractHasBeenUnlinked: 'Le contrat a été délié',
  VerifiableOnEtherscan: 'Vérifiable sur etherscan',
  AddMore: 'Ajouter plus',
  Apply: 'Appliquer',
  InvalidRange: 'Plage non valide',
  AllResults: 'Tous les résultats',
  Hide: 'Masquer',
  RefineYourSearch: "Affiner votre recherche pour voir d'autres résultats",
  send: 'Envoyer',
  followers: 'Observateurs',
  unarchive: 'Désarchiver',
  'You can export':
    "Tu peux exporter ton dépôt de contrats. Une fois l'exportation terminée, nous t'enverrons le lien de téléchargement par e-mail.",
  export: 'Exporter',
  'Export has been started': "L'exportation a été lancée",
  exportIsDone: "Une fois l'exportation terminée, le lien te sera envoyé par e-mail ({{email}}).",
  'Add a title': 'Ajouter un titre',
  'Add a description': 'Ajouter une description',
  one_month: '1 mois',
  three_months: '3 mois',
  six_months: '6 mois',
  one_year: '1 an',
  two_years: '2 ans',
  one_time: 'Paiement unique',
  on_demand: 'À la demande',
  total: 'Total',
  other: 'Autres',
  financials: 'Finances',
  currency: 'Devise',
  periodicity: 'Périodicité',
  includes_all: 'Inclut tous les paiements du contrat sur la période sélectionnée.',
  term: 'Durée',
  Payment: 'Paiement',
  'Recurring or one-time payments': 'Paiements récurrents ou uniques',
  'Recurring payments': 'Paiements récurrents',
  'One-time fees': 'Frais uniques',
  'Add custom description': 'Ajouter une description personnalisée',
  'Edit custom description': 'Modifier la description personnalisée',
  'Custom description': 'Description personnalisée',
  'Select file': 'Sélectionner un fichier',
}
