import { useTheme } from 'styled-components'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createSelector } from 'reselect'
import { Button, Flex, Spacing, SvgImage, Typography } from '../../atoms'

import { initializeNectRedirect } from '../../../actions/account'
import Spinner from '../../atoms/Spinner'
import { selectAccount } from '../../../store/selectors'

const FactoryVerify = ({ className }) => {
  const { palette } = useTheme()
  const dispatch = useDispatch()

  const selectData = createSelector([selectAccount], (account) => ({ ...account }))

  const { nectRedirect } = useSelector(selectData)

  const onVerifyClick = useCallback(() => dispatch(initializeNectRedirect()), [dispatch])

  return (
    <div className={className}>
      <Typography variant="12 medium" i18n="verifyIdentity">
        Verify your identity
      </Typography>
      <Spacing size="12" />
      <Flex align="flex-start">
        <SvgImage icon="kyc_verify_identity" size="32" />
        <Spacing size="12" />
        <Typography variant="12" i18n="verifyIdentityDescription">
          To sign a contract with Verified Signature, you must go through an identity verification
          process.
        </Typography>
      </Flex>
      <Spacing size="12" />
      <Button
        background={palette.accent.success}
        iconAfter={nectRedirect ? undefined : 'kyc_right_arrow'}
        width="fit-content"
        i18n={nectRedirect ? undefined : 'Verify'}
        onClick={nectRedirect ? undefined : onVerifyClick}
      >
        {nectRedirect ? <Spinner animation="success" /> : 'Verify'}
      </Button>
    </div>
  )
}

export default FactoryVerify
