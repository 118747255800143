import styled, { createGlobalStyle } from 'styled-components'

import Elevation from '../Elevation'
import Typography from '../Typography'
import Tooltip from './Tooltip'
import Arrow from '../../../pages/Signup/Arrow'

const ElementTooltip = ({ disabled, children, boundary, i18n, options = {}, portal, ...props }) => {
  if (disabled) {
    return null
  }

  return (
    <Tooltip
      {...props}
      enabled
      portal={portal}
      type="default"
      data-test="tooltip_black"
      options={{
        strategy: 'fixed',
        ...options,
      }}
    >
      {children}
    </Tooltip>
  )
}

export default styled(ElementTooltip)``
