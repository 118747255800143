import styled from 'styled-components'

import { usePermissions } from '../../../hooks'
import MenuItem from './MenuItem'

const BillingSubMenuItems = () => {
  return (
    <>
      <SidebarSubItem
        offset={1}
        i18n="Overview"
        value="Overview"
        route="/company/settings/billing/overview"
        activeHover
      />
      <SidebarSubItem
        offset={1}
        i18n="BillingInfo"
        value="Billing info"
        route="/company/settings/billing/info"
        activeHover
      />
    </>
  )
}

const CompanySettingsSidebarPage = () => {
  const { isPermissionEnabled, PERMISSIONS } = usePermissions()
  const billingSubmenuRoute = 'company/settings/billing'
  const isBetaEnabled = isPermissionEnabled(PERMISSIONS.BETA_FEATURES)
  const { pathname } = window.location
  return (
    <Grid>
      <MenuContainer>
        <MenuItem
          i18n="BackToWorkspace"
          value="Back to workspace"
          icon="arrow-left-circle"
          route="/my-contracts"
          activeHover
        />
      </MenuContainer>
      <MenuContainer>
        <MenuItem
          i18n="General"
          value="General"
          icon="gear"
          route="/company/settings/general"
          activeHover
          isActive={() => pathname?.indexOf('company/settings/general') !== -1}
        />
        <MenuItem
          i18n="Members"
          value="Members"
          icon="person"
          route="/company/settings/members"
          data-test="link_sidebar_companySettings_members"
          isActive={() => pathname?.indexOf('company/settings/members') !== -1}
        />
        <MenuItem
          i18n="workspace:workspaces"
          value="Workspaces"
          icon="file-drawer"
          route="/company/settings/workspaces"
          data-test="link_sidebar_companySettings_workspaces"
          isActive={() => pathname?.indexOf('company/settings/workspaces') !== -1}
        />
        <MenuItem
          i18n="customfields:CustomFields"
          value="Custom fields"
          icon="line-above-rectangle-line-below"
          route="/company/settings/custom-fields"
          data-test="link_sidebar_companySettings_customFields"
          isActive={() => pathname?.indexOf('company/settings/custom-fields') !== -1}
        />
        <MenuItem
          i18n="export"
          value="export"
          icon="square-arrow-up"
          route="/company/settings/export"
          isActive={() => pathname?.indexOf('company/settings/export') !== -1}
        />
        {isBetaEnabled && (
          <>
            <MenuItem
              i18n="plans:plans"
              value="Plans"
              icon="sparkles"
              route="/company/settings/plans"
              activeHover
              isActive={() => pathname?.indexOf('company/settings/plans') !== -1}
            />
            <MenuItem
              i18n="Billing"
              value="Billing"
              icon="creditcard"
              route="/company/settings/billing"
              activeHover
              subMenuItems={<BillingSubMenuItems />}
              subMenuRoute={billingSubmenuRoute}
            />
            <MenuItem
              i18n="Workflow"
              value="Workflow"
              icon="chart-stacked-bar"
              route="/company/settings/workflow"
              activeHover
              isActive={() => pathname?.indexOf('company/settings/general') !== -1}
            />
          </>
        )}
      </MenuContainer>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 65px auto;
  height: 100%;
`

const MenuContainer = styled.div`
  padding: ${({ theme }) => theme.semantic.space['space-small']};
  border-bottom: 1px solid ${({ theme }) => theme.semantic.palette.border.border};
`

const SidebarSubItem = styled(MenuItem)`
  background-color: transparent;

  &.selected,
  &:hover,
  &.selected:hover {
    background-color: transparent;
  }
`

export default CompanySettingsSidebarPage
