import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'
import { Button, Flex } from '../components/atoms'

import { useHideSidebar } from '../hooks'
import Intro from '../components/templates/Intro'
import Grid from '../components/atoms/Grid'

const PageNotFound = () => {
  const navigate = useNavigate()
  useHideSidebar()

  return (
    <Grid>
      <Content>
        <Intro
          headerI18n="PageNotFound"
          image={<img src="/img/illustrations/page_not_found.svg" />}
          subHeaderI18n="ThePageCouldNotBeFound"
          key="page-not-found"
        >
          <Button
            i18n="GoToContracts"
            width="fit-content"
            data-test="button_404_redirect-contracts"
            onClick={() => navigate('/my-contracts')}
          />
        </Intro>
      </Content>
    </Grid>
  )
}

const Content = styled(Flex)`
  justify-content: center;
  grid-column: 1 / -1;
`

export default PageNotFound
