import React, { useCallback } from 'react'
import styled, { useTheme } from 'styled-components'

import BaseInput, { Wrapper } from './BaseInput'
import SvgMask from '../SvgMask'
import Typography from '../Typography'
import { FILELOADINGSTATES } from '../../../enums'

const icon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0001 14H12.6591C12.1873 15.3344 11.2589 16.4591 10.0381 17.1753C8.81727 17.8914 7.38258 18.1529 5.98759 17.9136C4.59259 17.6743 3.32713 16.9495 2.41484 15.8673C1.50256 14.7852 1.0022 13.4154 1.0022 12C1.0022 10.5846 1.50256 9.21483 2.41484 8.13269C3.32713 7.05055 4.59259 6.32576 5.98759 6.08642C7.38258 5.84708 8.81727 6.1086 10.0381 6.82475C11.2589 7.5409 12.1873 8.66558 12.6591 10H23.0001V14H21.0001V18H17.0001V14ZM7.00005 14C7.53048 14 8.03919 13.7893 8.41426 13.4142C8.78934 13.0392 9.00005 12.5304 9.00005 12C9.00005 11.4696 8.78934 10.9609 8.41426 10.5858C8.03919 10.2107 7.53048 10 7.00005 10C6.46962 10 5.96091 10.2107 5.58584 10.5858C5.21076 10.9609 5.00005 11.4696 5.00005 12C5.00005 12.5304 5.21076 13.0392 5.58584 13.4142C5.96091 13.7893 6.46962 14 7.00005 14Z"
      fill="#929BAE"
    />
  </svg>
)

const UploadInput = ({ onChange, name, validateFile, fileLoadingState, ...props }) => {
  const theme = useTheme()

  const onInnerChange = useCallback(
    (e) => {
      if (!validateFile(e.target.files)) {
        return undefined
      }
      return onChange(e)
    },
    [onChange, validateFile],
  )

  const messages = {
    placeholder: (
      <Typography
        variant="header 14px regular"
        i18n="LoadSecurityKey"
        color={theme.palette.type.tertiary}
      />
    ),
    accepted: (
      <div>
        <Typography
          variant="header 14px regular"
          i18n="fileAccepted"
          color={theme.palette.type.primary}
        />
        <SvgMask icon="checkmark" size={16} color={theme.palette.accent.success} />
      </div>
    ),
    declined: (
      <div>
        <Typography
          variant="header 14px regular"
          i18n="SecurityKey"
          color={theme.palette.type.primary}
        />
        <SvgMask icon="xmark-small" size={16} color={theme.palette.accent.warning} />
      </div>
    ),
  }

  return (
    <Wrapper
      accepted={fileLoadingState === FILELOADINGSTATES.ACCEPTED}
      $error={fileLoadingState === FILELOADINGSTATES.DECLINED}
    >
      <BaseInput
        accept=".ikf"
        type="file"
        onChange={onInnerChange}
        name={name}
        id={name}
        {...props}
      />
      <label htmlFor={name} data-test="input_file-upload-label">
        {messages[fileLoadingState]}
        <i>{icon}</i>
      </label>
      {fileLoadingState === FILELOADINGSTATES.DECLINED && (
        <Typography
          variant="body 10px medium"
          i18n="InvalidSecurityKey"
          color={theme.palette.accent.warning}
        />
      )}
    </Wrapper>
  )
}

export default styled(UploadInput)`
  display: none;
`
