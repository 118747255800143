import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Divider, Flex, Spacing, Spinner, TextInput, Typography } from '../../atoms'
import { getWorkspaces, setCheckWorkspaceSwitcher } from '../../../actions/workspaces'
import { callApi } from '../../../utils/callApi'
import { createNotification } from '../../../actions/notifications'
import { generateSlug } from '../../../pages/Workspaces/slugs'
import { useDispatchAsync, usePermissions } from '../../../hooks'
import RemoveWorkspace from '../../popups/RemoveWorkspace'

import { selectWorkspaces } from '../../../store/selectors'

const WorkspaceGeneralTab = ({ className, workspaceId, onCloseMainModal }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const navigate = useNavigate()

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    workspace: workspaces.allWorkspaces[workspaceId],
    companyWorkspaceId: workspaces.companyWorkspace?.id,
  }))

  const { workspace, companyWorkspaceId } = useSelector(selectData)

  const defaultWorkspaceName = t('General') // use only for company workspace
  const innerWorkspaceName = workspace?.attributes.title || defaultWorkspaceName
  const [workspaceTitle, setWorkspaceTitle] = useState(innerWorkspaceName)
  const [workspaceDeleteModal, setWorkspaceDeleteModal] = useState(false)
  const [savingAnimation, setSavingAnimation] = useState(false)
  const [workspaceCurrentRoute, setWorkspaceCurrentRoute] = useState(false)
  const [[workspaceRoute, folderRoute], setCurrentRoute] = useState([undefined, undefined])
  const isCompanyWorkspace = workspaceId === companyWorkspaceId
  const { isPermissionEnabled, PERMISSIONS } = usePermissions({ workspaceId })

  useEffect(() => {
    const { pathname } = window.location
    const folderSlug = pathname.split('/folders/')[1]
    const workspaceSlug = pathname.split('/folders/')[0]
    setCurrentRoute([workspaceSlug, folderSlug])
  }, [])

  useEffect(() => {
    const route = `/workspaces/${generateSlug(workspace)}`
    if (route !== workspaceCurrentRoute) setWorkspaceCurrentRoute(route)
  }, [workspaceCurrentRoute, workspace])

  const changeTitle = async () => {
    setSavingAnimation(true)
    try {
      dispatch(setCheckWorkspaceSwitcher({ checkWorkspaceSwitcher: false }))
      const resp = await callApi({
        endpoint: `workspaces/${workspaceId}`,
        method: 'PATCH',
        body: {
          data: {
            type: 'workspaces',
            attributes: {
              title: workspaceTitle,
            },
          },
        },
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      })
      await dispatchAsync(getWorkspaces, {})
      dispatch(
        createNotification({
          value: t('Saved'),
          description: t('WsInformationUpdated'),
          state: 'success',
        }),
      )
      if (workspaceCurrentRoute === workspaceRoute) {
        if (folderRoute) {
          const route = `/workspaces/${generateSlug(resp.data)}/folders/${folderRoute}`
          navigate(route)
        } else {
          const route = `/workspaces/${generateSlug(resp.data)}`
          navigate(route)
        }
      }
      setSavingAnimation(false)
      setTimeout(() => {
        dispatch(setCheckWorkspaceSwitcher({ checkWorkspaceSwitcher: true }))
      }, 5000)
    } catch (e) {
      dispatch(setCheckWorkspaceSwitcher({ checkWorkspaceSwitcher: true }))
      setSavingAnimation(false)
    }
  }

  const workspaceIsDeleted = async (event) => {
    dispatch(getWorkspaces())
    if (typeof onCloseMainModal === 'function') onCloseMainModal(event)
  }

  return (
    <div className={className} data-test="generalTab_workspaceModal">
      <Typography variant="16 medium" i18n="Name" />
      <Spacing size="24" />
      <Row>
        <TextInput
          value={workspaceTitle}
          onChange={(e) => {
            setWorkspaceTitle(e.target.value)
          }}
          readOnly={isCompanyWorkspace || !isPermissionEnabled(PERMISSIONS.WORKSPACE_UPDATE)}
          data-test="input_workspaceSettingsModal_generalTab_workspaceTitle"
        />
        <Spacing size="16" />
        <Button
          width="fit-content"
          i18n={savingAnimation ? undefined : 'Update'}
          disabled={
            workspaceTitle === innerWorkspaceName ||
            workspaceTitle.length === 0 ||
            isCompanyWorkspace
          }
          onClick={changeTitle}
          data-test="button_workspaceSettingsModal_generalTab_update"
        >
          {savingAnimation ? <Spinner /> : 'Save'}
        </Button>
      </Row>
      <Spacing size="32" />
      <Divider />
      <Spacing size="32" />
      <Typography variant="16 medium" i18n="workspace:delete">
        Delete workspace
      </Typography>
      <Spacing size="4" />
      <Typography variant="14" color="secondary" i18n="workspace:deleteDescription">
        Deleting this workspace will cause all workspace members to lose access. This cannot be
        undone.
      </Typography>
      <Spacing size="24" />
      <Button
        secondary
        width="fit-content"
        i18n="Delete"
        onClick={() => setWorkspaceDeleteModal(true)}
        disabled={isCompanyWorkspace || !isPermissionEnabled(PERMISSIONS.WORKSPACE_DELETE)}
        data-test="button_workspaceSettingsModal_generalTab_delete"
      />
      <RemoveWorkspace
        visible={workspaceDeleteModal}
        onClose={() => setWorkspaceDeleteModal(false)}
        workspaceTitle={workspaceTitle}
        workspaceId={workspaceId}
        confirmDelete={workspaceIsDeleted}
      />
    </div>
  )
}

const Row = styled(Flex)`
  align-items: baseline;
`

export default styled(WorkspaceGeneralTab)``
