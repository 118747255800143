import { createAsyncThunk } from '@reduxjs/toolkit'

import getDigitalAssetsFirstPage from './getDigitalAssetsFirstPage'

export default createAsyncThunk(
  'digitalassets/reload',
  async ({ tableId, archive, callback }, api) => {
    const { table, filters, workspaces, digitalAssets } = api.getState()
    const sort = table[tableId]?.sort?.request
    const selectedFilters = filters || []
    const activeWorkspaceId = workspaces.active
    const activeWorkspaceFolderId = workspaces.activeFolder

    api.dispatch(
      getDigitalAssetsFirstPage({
        sort,
        search: digitalAssets.searchValue,
        filters: selectedFilters,
        activeWorkspaceId,
        activeWorkspaceFolderId,
        archive,
      }),
    )
    api.extra.sendCallback(callback)
  },
)
