import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../utils/callApi'

export const applyOverlays = createAction('fields/apply', ({ overlays, documentId }) => {
  const overlaysData = overlays.map((o) => ({
    ...o,
    actions: undefined,
    canvas: undefined,
    onClick: undefined,
    onRemove: undefined,
    renderPage: undefined,
    renderer: undefined,
    theme: undefined,
    render: o.render
      ? { width: o.render.width, height: o.render.height, dataUrl: o.render.toDataURL() }
      : undefined,
  }))

  return { payload: { documentId, overlays: overlaysData } }
})

export const setFieldsDefinitions = createAction('custom-field/set')

export const changeCustomField = createAsyncThunk(
  'custom-field/change',
  async ({ callback, customFieldId, value, prop = 'value', type }, api) => {
    const body = {
      data: {
        type,
        attributes: {
          [prop]: value,
        },
      },
    }

    const result = await callApi({
      endpoint: `customfields/${customFieldId}`,
      method: 'PATCH',
      body,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    })

    api.extra.sendCallback(callback, result)
  },
)
