/* eslint-disable camelcase */
let tempAPI_URL
let tempbase_URL

if (localStorage.getItem('localhost_mode') !== 'true') {
  tempAPI_URL = process.env.REACT_APP_API_ROOT
    ? `${process.env.REACT_APP_API_ROOT}/ui-api`
    : '/ui-api'
  tempbase_URL = `${process.env.REACT_APP_API_ROOT}/`
} else {
  tempAPI_URL = process.env.REACT_APP_API_LOCALROOT
    ? `${process.env.REACT_APP_API_LOCALROOT}/ui-api`
    : '/ui-api'
  tempbase_URL = `${process.env.REACT_APP_API_LOCALROOT}/`
}

export const API_URL = tempAPI_URL
export const base_URL = tempbase_URL

export const accept = {
  jsonApi: 'application/vnd.api+json',
  appJson: 'application/json',
  octetStream: 'application/octet-stream',
}
