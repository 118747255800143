/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useCallback, useMemo, useRef, useState } from 'react'
import allSettled from 'promise.allsettled'
import styled, { useTheme } from 'styled-components'
import forEach from 'lodash/forEach'
import { useDropArea } from 'react-use'
import { useDispatch } from 'react-redux'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { useTranslation } from 'react-i18next'
import en_US from '../../../i18n/pdf-viewer-translates/en.json'
import de_DE from '../../../i18n/pdf-viewer-translates/de.json'
import fr_FR from '../../../i18n/pdf-viewer-translates/fr.json'
import { Divider, Flex, Spacing, Spinner, SvgMask, Typography } from '../../atoms'
import { getAllOverlays, registerOverlay, removeOverlay } from './PDFOverlay'
import { CustomFields, SignatureModal, TextModal } from './Overlays'
import { usePermissions } from '../../../hooks'
import { applyOverlays } from '../../../actions/customFields'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'

const workerUrl = new URL('pdfjs-dist/build/pdf.worker.min', import.meta.url).toString()
allSettled.shim()

const PDFViewer = ({
  className,
  preview,
  showFields,
  onLoad,
  document,
  defaultScale,
  renderPage,
}) => {
  const theme = useTheme()
  const { isPermissionEnabled, PERMISSIONS } = usePermissions()
  const dispatch = useDispatch()
  const [bond] = useDropArea({})
  const {
    i18n: { language },
  } = useTranslation()

  const lang = useMemo(() => {
    if (language === 'de') {
      return de_DE
    }
    if (language === 'fr') {
      return fr_FR
    }
    return en_US
  }, [language])

  const isBetaEnabled = isPermissionEnabled(PERMISSIONS.BETA_FEATURES)

  const [activeSignature, setActiveSignature] = useState(false)
  const [activeTextOverlay, setActiveTextOverlay] = useState(false)

  const ref = useRef()

  const signatureModalOpen = useCallback((e, overlay) => setActiveSignature(overlay), [])
  const signatureModalClose = useCallback(() => setActiveSignature(false), [])
  const textModalOpen = useCallback((e, overlay) => setActiveTextOverlay(overlay), [])
  const textModalClose = useCallback(() => setActiveTextOverlay(false), [])

  const overlayActions = useMemo(
    () => ({
      signatureModalOpen,
      signatureModalClose,
      removeOverlay,
      textModalOpen,
      textModalClose,
    }),
    [signatureModalClose, signatureModalOpen, textModalClose, textModalOpen],
  )

  const onDragStart = (dragStartEvent, OverlayContructor) => {
    const { nativeEvent } = dragStartEvent
    forEach(ref.current, (canvas, pageId) => {
      canvas.ondrop = (dropEvent) => {
        const { offsetX, offsetY } = dropEvent
        registerOverlay(
          OverlayContructor({
            canvas,
            pageId,
            offsetX: nativeEvent.offsetX ? offsetX - nativeEvent.offsetX : offsetX,
            offsetY,
            theme,
            actions: overlayActions,
            documentId: document.id,
          }),
        )
      }
    })
  }

  const base64ToBlob = (base64, contentType = 'application/pdf') => {
    const byteCharacters = atob(base64.split(',')[1] || base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const pdfData = useMemo(() => {
    if (preview) {
      if (typeof preview === 'string' && preview.includes('/static/media/')) {
        return preview
      }
      if (preview instanceof Blob) {
        return URL.createObjectURL(preview)
      }
      const pdfBlob = base64ToBlob(preview)
      return URL.createObjectURL(pdfBlob)
    }
    return null
  }, [preview])

  const transform = (slot) => ({
    ...slot,
    Open: () => null,
  })

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>,
  })

  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance

  const defaultRenderPage = (props) => {
    return (
      <>
        {props.canvasLayer.children}
        {props.annotationLayer.children}
        {props.textLayer.children}
      </>
    )
  }

  if (!preview || !pdfData) {
    return (
      <div style={{ display: 'flex' }}>
        <Spinner />
      </div>
    )
  }

  return (
    <div className={className} ref={ref} {...bond} data-test="pdfDocument">
      <Worker workerUrl={workerUrl}>
        <div style={{ height: '100%' }}>
          <Viewer
            fileUrl={pdfData}
            plugins={[defaultLayoutPluginInstance]}
            defaultScale={defaultScale}
            onDocumentLoad={(e) => {
              if (typeof onLoad === 'function') {
                onLoad(e)
              }
            }}
            renderPage={renderPage || defaultRenderPage}
            localization={lang}
          />
        </div>
      </Worker>
      {showFields && isBetaEnabled && <FieldsPanel onDragStart={onDragStart} />}

      <SignatureModal
        visible={!!activeSignature}
        onClose={signatureModalClose}
        onChange={(image) => {
          signatureModalClose()
          activeSignature.render = image
          activeSignature.renderPage()
          const allOverlays = getAllOverlays()
          const documentId = document.id
          dispatch(applyOverlays({ documentId, overlays: allOverlays }))
        }}
      />

      <TextModal
        visible={!!activeTextOverlay}
        onClose={textModalClose}
        onChange={(image) => {
          textModalClose()
          activeTextOverlay.render = image
          activeTextOverlay.renderPage()
        }}
      />
    </div>
  )
}

const BaseFieldsPanel = ({ className, onDragStart }) => {
  return (
    <Flex className={className}>
      <Typography variant="16 medium">Fields</Typography>
      <Spacing size="16" />
      <Divider />
      <Spacing size="8" />
      <FieldsContainer>
        {CustomFields.map((f) => (
          <Field
            key={f.type}
            i18n={f.i18n}
            icon={f.icon}
            constructor={f.factory}
            onDragStart={onDragStart}
          />
        ))}
      </FieldsContainer>
    </Flex>
  )
}

const FieldsContainer = styled(Flex)`
  width: 100%;
  margin: 0 -8px;
  box-sizing: border-box;
  z-index: 10;
`

const FieldsPanel = styled(BaseFieldsPanel)`
  position: fixed;
  right: 0;
  height: calc(100vh - 56px);
  width: 464px;
  top: 56px;
  padding: 24px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: baseline;
`

const BaseField = ({ i18n, onDragStart, icon, constructor, ...props }) => {
  const innerOnDragStart = useCallback(
    (e) => onDragStart(e, constructor),
    [constructor, onDragStart],
  )

  return (
    <Flex {...props} draggable onDragStart={innerOnDragStart}>
      <Flex>
        <SvgMask icon={icon} />
        <Spacing size="12" />
        <Typography i18n={i18n} variant="14 medium" />
      </Flex>
      <Spacing size="16" inline />
      <DragIcon />
    </Flex>
  )
}

const DragIcon = styled(SvgMask).attrs(() => ({ icon: 'dots-grid-2x3' }))`
  background-color: ${({ theme }) => theme.palette.type.tertiary};
  cursor: all-scroll;
`

const Field = styled(BaseField)`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.background.primary};
  box-sizing: border-box;
  justify-content: space-between;
  margin: 8px;

  &:hover {
    background: ${({ theme }) => theme.palette.background.primary};
  }
`

export default styled(PDFViewer)`
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  position: relative;

  .rpv-toolbar__left {
    margin-left: 45px;
  }
`
