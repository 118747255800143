import styled, { useTheme } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Elevation, Flex, Spacing, SvgMask, Tooltip, Typography } from '../../atoms'

const DEFAULT_VARIANT = 'body 13px medium'

const Grid = styled.div`
  display: grid;
  width: 100%;
  > ${Typography} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const Wrapper = ({ route, linkProps, ...props }) => {
  const active = typeof linkProps.isActive === 'function' ? linkProps.isActive() : false
  if (route) {
    return (
      <NavLink
        to={linkProps.to}
        {...props}
        className={`${props.className} ${active ? 'selected' : ''}`}
      />
    )
  }

  return <div {...props} className={`${props.className} ${active ? 'selected' : ''}`} />
}

const SidebarItem = ({
  variant,
  value,
  i18n,
  icon,
  iconSize,
  iconBackground,
  route,
  noHighlight,
  activeHover,
  isActive,
  children,
  subMenuItems,
  subMenuRoute,
  color,
  hoverBackground,
  leftContent,
  tooltip,
  ...props
}) => {
  const linkProps = {
    to: route,
    activeClassName: noHighlight ? '' : 'selected',
    isActive,
  }
  return (
    <>
      <Wrapper route={route} linkProps={linkProps} {...props}>
        <Flex content="space-between" width="100%">
          <Flex>
            {leftContent}
            {icon && (
              <SidebarIcon
                icon={icon}
                iconSize={iconSize}
                iconBackground={iconBackground}
                color={color}
              />
            )}
            <Grid>
              <Typography
                i18n={i18n}
                variant={variant || DEFAULT_VARIANT}
                title={tooltip ? value : undefined}
              >
                {value}
              </Typography>
            </Grid>
          </Flex>
          {children}
        </Flex>
      </Wrapper>
      {window.location.pathname.indexOf(subMenuRoute) !== -1 && (
        <SubMenuContainer>{subMenuItems}</SubMenuContainer>
      )}
    </>
  )
}

const SidebarIconBackground = styled.div`
  padding: 8px;
  background: ${({ $background }) => $background};
  border-radius: 4px;
`

const SubMenuContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`

const SidebarIcon = ({ icon, iconSize, iconBackground, color }) => {
  const { palette } = useTheme()

  let Icon

  if (typeof icon === 'string') {
    Icon = iconBackground ? (
      <SidebarIconBackground $background={iconBackground}>
        <SidebarSvg icon={icon} size={iconSize || 16} color={color || palette.background.white} />
      </SidebarIconBackground>
    ) : (
      <SidebarSvg icon={icon} size={iconSize || 24} color={color || palette.type.secondary} />
    )
  } else {
    Icon = icon
  }

  return (
    <>
      {Icon}
      <Spacing size={8} />
    </>
  )
}

const SidebarSvg = styled(SvgMask)``

export default styled(SidebarItem)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 4px;
  text-decoration: none;

  ${Typography} {
    color: ${({ theme, color }) => color || theme.palette.type.secondary};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.divider};

    ${Typography} {
      color: ${({ theme }) => theme.palette.theme.primary};
    }

    ${SidebarSvg} {
      background-color: ${({ theme, iconBackground }) =>
        iconBackground ? undefined : theme.palette.theme.primary};
    }
    ${SidebarIconBackground} {
      background: ${({ hoverBackground }) => hoverBackground || undefined};
    }
  }

  &.selected {
    background: ${({ theme }) => theme.palette.background.secondary};

    ${Typography} {
      color: ${({ theme }) => theme.palette.theme.primary};
    }

    ${SidebarSvg} {
      background-color: ${({ theme, iconBackground }) =>
        iconBackground ? undefined : theme.palette.theme.primary};
    }

    &:hover {
      background: ${({ theme, iconBackground }) =>
        iconBackground ? undefined : theme.palette.background.secondary};
    }
  }

  ${Tooltip} {
    ${Elevation} {
      padding: 0 4px;
      background: ${({ theme }) => theme.palette.type.primary};
      border-radius: 0;

      > ${Typography} {
        color: ${({ theme }) => theme.palette.background.white};
        position: relative;
      }
    }
  }
`
