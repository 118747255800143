import { createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../utils/callApi'

export const fetchUnconmirmedHints = createAsyncThunk(
  'hints/fetch',
  // eslint-disable-next-line default-param-last
  async ({ callback } = {}, api) => {
    const token = api.extra.getToken()
    const state = api.getState()

    const accountId = state.account.entity?.id

    if (!accountId) {
      throw new Error('Cannot access - Unconfirmed Hints')
    }

    const { included: hints } = await callApi({
      endpoint: `accounts/${accountId}?include=unconfirmedHints`,
      accessToken: token,
      accessName: 'Bearer',
    })

    api.extra.sendCallback(callback)

    return { hints }
  },
)

export const deleteUnconfirmedHint = createAsyncThunk(
  'hints/remove',
  async ({ hintId, callback }, api) => {
    const token = api.extra.getToken()
    const state = api.getState()

    const npId = state.account.currentUser.id
    const accountId = state.included.naturalpersons[npId]?.relationships?.account.data.id

    if (!accountId) {
      throw new Error('Cannot access - Unconfirmed Hints')
    }

    await callApi({
      endpoint: `accounts/${accountId}/relationships/unconfirmedHints`,
      accessToken: token,
      accessName: 'Bearer',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      body: { data: { type: 'hints', id: hintId } },
    })

    api.extra.sendCallback(callback)

    api.dispatch(fetchUnconmirmedHints())

    return { hintId }
  },
)
