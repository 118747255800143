import { createReducer } from '@reduxjs/toolkit'
import Bowser from 'bowser'

import { logout } from '../actions/logout'
import { fetchPartners } from '../actions/partners'

const initialState = { emails: [], naturalPersons: [], isLoading: false }
const browser = Bowser.getParser(window.navigator.userAgent)
const browserInfo = browser.getBrowser()

const getPartnerView = (p) => {
  if (browserInfo.name === 'Chrome') {
    return p.attributes.firstName && p.attributes.lastName
      ? `${p.attributes.firstName} ${p.attributes.lastName}`
      : undefined
  }

  return p.attributes.emailAddress
}
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(logout, () => {
      return {
        ...initialState, // Початковий стан
      }
    })
    .addCase(fetchPartners.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    .addCase(fetchPartners.fulfilled, (state, action) => {
      const { partners, userData } = action.payload
      const emails = partners.map((p) => ({
        value: p.attributes.emailAddress,
        view: getPartnerView(p),
      }))

      return {
        ...state,
        isLoading: false,
        naturalPersons: partners,
        members: userData,
        emails,
      }
    })
})

export default reducer
