import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import { BasePopup, Divider, Elevation, Flex, Spacing, Spinner, Typography } from '../atoms'
import { PersonaDetails } from './index'
import { ASSIGNEE_POPUPS_TYPE } from '../../enums/AssigneePopupsType'
import { useDispatchAsync } from '../../hooks'
import { getAssigneeForContract, getAssigneeForWorkspace } from '../../actions/digitalAssets'

const AssigneePopup = ({
  type,
  alreadyInvitedPersons,
  searchValue,
  onSelect,
  target,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()
  const [popperPlacement, setPopperPlacement] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [firstOpen, setFirstOpen] = useState(false)
  const [blockExists, setBlockExists] = useState(false)
  const [partnershipsUsersList, setPartnershipsUsersList] = useState([])
  const [companyUsersList, setCompanyUsersList] = useState([])
  const dispatchAsync = useDispatchAsync()

  const debouncedFetch = useRef(
    debounce(() => {
      setFirstOpen(true)
      setLoaded(false)
      setLoading(false)
      setPartnershipsUsersList([])
      setCompanyUsersList([])
    }, 500),
  )

  const getPersons = useCallback(() => {
    setLoading(true)
    const fetchPersons = async () => {
      const { persons, companyMembers } = await dispatchAsync(getAssigneeForContract, {
        alreadyInvitedUsers: alreadyInvitedPersons,
        searchValue,
      })

      setPartnershipsUsersList(persons)
      setCompanyUsersList(companyMembers)

      setLoaded(true)
      setLoading(false)
    }
    const fetchPersonsForWorkspace = async () => {
      const { persons, companyMembers } = await dispatchAsync(getAssigneeForWorkspace, {
        alreadyInvitedPersons,
        searchValue,
      })

      setPartnershipsUsersList(persons)
      setCompanyUsersList(companyMembers)

      setLoaded(true)
      setLoading(false)
    }
    if (type === ASSIGNEE_POPUPS_TYPE.INVITE_TO_CONTRACT) fetchPersons()
    if (type === ASSIGNEE_POPUPS_TYPE.INVITE_TO_WORKSPACE) fetchPersonsForWorkspace()
  }, [alreadyInvitedPersons, dispatchAsync, searchValue, type])

  useEffect(() => {
    debouncedFetch.current()
  }, [searchValue])

  useEffect(() => {
    if (!loaded && !loading && firstOpen) {
      getPersons()
    }
  }, [firstOpen, getPersons, loaded, loading])

  return (
    <div {...props}>
      <InvitePopup
        onClose={onClose}
        target={target}
        popperPlacement={
          !popperPlacement ? (placement) => setPopperPlacement(placement) : undefined
        }
        $popperPlacement={popperPlacement}
        options={{
          placement: 'bottom-start',
          strategy: 'fixed',
        }}
        spacing={4}
        autoMaxHeight={blockExists}
        hiddenY="hidden"
      >
        {partnershipsUsersList.length !== 0 || companyUsersList.length !== 0 ? (
          <Container $height={572}>
            {partnershipsUsersList.length > 0 ? (
              <>
                <Typography i18n="Contacts" color="tertiary" variant="body 12 regular">
                  Contacts
                </Typography>
                <Spacing size="8" />
                {partnershipsUsersList.map((user) => {
                  const { person, account } = user
                  const registeredUser = person.attributes.firstName && person.attributes.lastName
                  return (
                    <Fragment key={`assigneeUser_${person.id}`}>
                      <UserContainer
                        onClick={() => {
                          onSelect(person.attributes.emailAddress)
                          onClose()
                        }}
                      >
                        <PersonaDetails
                          firstName={
                            registeredUser ? person.attributes.firstName : t('pendingLoading')
                          }
                          lastName={person.attributes.lastName}
                          size="medium"
                          labels={[person.attributes.emailAddress]}
                          initials={
                            registeredUser
                              ? undefined
                              : person.attributes.emailAddress
                                  .replaceAll(/[^a-z]*/gi, '')
                                  .substring(0, 2)
                          }
                          personaId={`invited-list_${person.id}`}
                          seed={registeredUser ? person?.attributes?.emailAddress : undefined}
                          color={!registeredUser ? 16 : undefined}
                          verified={account?.attributes?.identVerified}
                        />
                      </UserContainer>
                      <Spacing size="4" />
                    </Fragment>
                  )
                })}
              </>
            ) : (
              ''
            )}
            {companyUsersList.length > 0 ? (
              <>
                {partnershipsUsersList.length > 0 && (
                  <>
                    <Divider />
                    <Spacing size="8" />
                  </>
                )}

                <Typography i18n="Company" color="tertiary" variant="body 12 regular">
                  Company
                </Typography>
                <Spacing size="8" />
                {companyUsersList.map((user) => {
                  const { person, account } = user
                  const registeredUser = person.attributes.firstName && person.attributes.lastName
                  return (
                    <Fragment key={`assigneeUser_${person.id}`}>
                      <UserContainer
                        onClick={() => {
                          onSelect(person.attributes.emailAddress)
                          onClose()
                        }}
                      >
                        <PersonaDetails
                          firstName={
                            registeredUser ? person.attributes.firstName : t('pendingLoading')
                          }
                          lastName={person.attributes.lastName}
                          size="medium"
                          labels={[person.attributes.emailAddress]}
                          initials={
                            registeredUser
                              ? undefined
                              : person.attributes.emailAddress
                                  .replaceAll(/[^a-z]*/gi, '')
                                  .substring(0, 2)
                          }
                          personaId={`invited-list_${person.id}`}
                          seed={registeredUser ? person?.attributes?.emailAddress : undefined}
                          color={!registeredUser ? 16 : undefined}
                          verified={account?.attributes?.identVerified}
                        />
                      </UserContainer>
                      <Spacing size="4" />
                    </Fragment>
                  )
                })}
              </>
            ) : (
              ''
            )}
            {!blockExists && setTimeout(() => setBlockExists(true), 1)}
          </Container>
        ) : (
          <>
            {blockExists && setBlockExists(false)}
            {loaded ? (
              <Flex direction="column" content="center">
                <Spacing size="16" />
                <Typography i18n="NoResults" color="tertiary" variant="body 12 regular">
                  No results
                </Typography>
                <Spacing size="16" />
              </Flex>
            ) : (
              <>
                <Spacing size="24" />
                <Spinner />
                <Spacing size="24" />
              </>
            )}
          </>
        )}
      </InvitePopup>
    </div>
  )
}

const InvitePopup = styled(BasePopup)`
  opacity: ${({ $popperPlacement }) => ($popperPlacement ? 1 : 0)};

  ${Elevation} {
    animation: opacity 0.3s cubic-bezier(0.2, 0, 0, 1) 0.5s;
  }
  &[data-popper-placement='top-start'] {
    ${Elevation} {
      margin-bottom: 6px;
    }
  }
`

const UserContainer = styled.div`
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.primary};
  }
`

const Container = styled.div`
  max-height: ${({ $height }) => ($height ? `${$height}px` : '265px')};
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  flex: auto;

  > ${Typography} {
    padding-left: 8px;
  }
`

export default styled(AssigneePopup)`
  position: relative;

  ${Elevation} {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 4px;
    width: ${({ width }) => `${width}px` || '393px'};
  }
`
