import styled, { useTheme } from 'styled-components'

import Typography from './Typography'
import Flex from './Flex'

const Toggle = ({ checked, disabled, onClick, label, labelI18n, className, ...props }) => {
  const theme = useTheme()

  return (
    <Flex className={className}>
      <div {...props} onClick={disabled ? undefined : onClick}>
        <ToggleCircle
          $checked={checked}
          data-test={checked ? 'toggle_checked' : 'toggle_unchecked'}
        />
      </div>
      {label && (
        <Typography
          variant="body-medium-02"
          i18n={labelI18n}
          color={
            disabled
              ? theme.semantic.palette.text['text-disabled']
              : theme.semantic.palette.text['text-secondary']
          }
        >
          {label}
        </Typography>
      )}
    </Flex>
  )
}

const ToggleCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 40px;

  position: relative;
  top: 2px;
  left: ${({ $checked }) => ($checked ? 14 : 2)}px;
  transition: left 0.2s ${({ theme }) => theme.animation.bezier};

  background: ${({ theme }) => theme.palette.background.white};
`

export default styled(Toggle)`
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
  > div {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 16px;
    border-radius: 40px;

    background: ${({ theme, checked, disabled }) => {
      if (disabled) {
        if (checked) return theme.component.toggle.surface['toggle-color-surface-switched-disabled']
        return theme.component.toggle.surface['toggle-color-surface-not-switched-disabled']
      }
      return checked
        ? theme.component.toggle.surface['toggle-color-surface-switched']
        : theme.component.toggle.surface['toggle-color-surface-not-switched']
    }};
    transition: background 0.2s ${({ theme }) => theme.animation.bezier};

    outline-color: ${({ theme: { palette }, disabled }) => {
      if (disabled) {
        return 'transparent'
      }
      return palette.theme.primary
    }};
    outline-offset: 2px;

    cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};

    &:hover {
      background: ${({ theme, disabled, checked }) => {
        if (disabled) return undefined
        if (checked) return theme.component.toggle.surface['toggle-color-surface-switched-hover']

        return theme.component.toggle.surface['toggle-color-surface-not-switched-hover']
      }};
    }
  }
`
