import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { createSelector } from 'reselect'
import { Flex } from '../../../atoms'
import FolderItem from './FolderItem'

import { useMatchPath } from '../../../../hooks'
import { DIGITALASSET_STATES } from '../../../../enums'
import { generateSlug } from '../../../../pages/Workspaces/slugs'
import { selectIncluded, selectWorkspaces } from '../../../../store/selectors'

const MAX_FOLDER_LEVEL = 6

const SubFolders = ({
  className,
  data,
  folderLevel = 0,
  workspaceId,
  location = null,
  unparentFolder,
}) => {
  const [expanded, setExpanded] = useState(false)

  const { id: digitalAssetId } = useMatchPath('/contract-details/:id')

  const selectData = createSelector([selectWorkspaces, selectIncluded], (workspaces, included) => ({
    ...workspaces,
    folders: workspaces.folders[workspaceId] || [],
    workspace: workspaces.allWorkspaces[workspaceId],
    activeDa: included.digitalassets[digitalAssetId],
    subFolders: workspaces.folders[data.id] || [],
  }))

  const {
    workspace,
    activeDa,
    active: activeWorkspaceId,
    activeFolder,
    subFolders,
  } = useSelector(selectData)

  const route = `/workspaces/${generateSlug(workspace)}/folders/${data.attributes.slug}`
  const haveFolder = useMemo(
    () => (location ? !!location.find((i) => i.id === data.id) : false),
    [data.id, location],
  )

  const sortedSubFolders = useMemo(() => {
    const staticSubFolders = [...subFolders]
    if (staticSubFolders.length > 0) {
      return staticSubFolders.sort((a, b) => {
        const nameA = a?.attributes?.name?.toUpperCase()
        const nameB = b?.attributes?.name?.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    }
    return staticSubFolders
  }, [subFolders])
  const { pathname } = window.location
  return (
    <Flex direction="column" width="100%" className={className}>
      <FolderItem
        data={data}
        unparentFolder={unparentFolder}
        workspaceId={workspaceId}
        folderId={data.id}
        more={sortedSubFolders.length > 0}
        onChange={(visible) => setExpanded(visible)}
        spacing={folderLevel}
        isActive={() =>
          activeWorkspaceId === workspace.id &&
          activeFolder === data.id &&
          (pathname?.indexOf(route) !== -1 ||
            (pathname?.indexOf('contract-') !== -1 &&
              activeDa?.attributes.state !== DIGITALASSET_STATES.ARCHIVED))
        }
        location={haveFolder && location}
        route={route}
        wsId={workspace.id}
      />
      {expanded && sortedSubFolders.length > 0 && (
        <Flex direction="column" width="100%">
          {sortedSubFolders.map((f) => (
            <SubFolders
              key={f.attributes.slug}
              data={f}
              folderLevel={folderLevel < MAX_FOLDER_LEVEL ? folderLevel + 1 : folderLevel}
              workspaceId={workspaceId}
              location={haveFolder ? location : null}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default SubFolders
