import { useSelector } from 'react-redux'
import { useBeforeunload } from 'react-beforeunload'
import { createSelector } from 'reselect'
import { selectApplication } from '../../store/selectors'

const UnloadLock = () => {
  const selectIsLocked = createSelector(
    [selectApplication],
    (application) => application.unloadLock.length > 0,
  )
  const isLocked = useSelector(selectIsLocked)
  useBeforeunload((event) => (isLocked ? event.preventDefault() : undefined))

  return null
}

export default UnloadLock
