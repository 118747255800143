import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { createPortal } from 'react-dom'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { createSelector } from 'reselect'
import { BaseInput, Button, Flex, Modal, Spacing, Spinner, ValidationError } from '../../../atoms'

import { useDispatchAsync } from '../../../../hooks'
import { getFolders, renameFolder, updateFolders } from '../../../../actions/workspaces'
import { createNotification } from '../../../../actions/notifications'
import { selectWorkspaces } from '../../../../store/selectors'

const FolderRenameModal = ({ visible, onClose, workspaceId, folderId, className, ...props }) => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const { t } = useTranslation()

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    folder: workspaces?.allFolders[folderId],
  }))
  const { folder } = useSelector(selectData)

  const [renameAnimation, setRenameAnimation] = useState(false)
  const [error, setError] = useState(false)
  const [newFolderTitle, setNewFolderTitle] = useState(folder?.attributes?.name)

  useEffect(() => {
    if (!visible) {
      setNewFolderTitle(folder?.attributes?.name)
      setError(false)
    }
  }, [folder?.attributes?.name, visible])

  const onRename = async (e) => {
    if (newFolderTitle.length < 1) {
      setError(true)
      return
    }
    setRenameAnimation(true)

    await dispatchAsync(renameFolder, { folderId, newFolderTitle })
    await dispatchAsync(updateFolders, { workspaceId })
    await dispatchAsync(getFolders, {})

    dispatch(
      createNotification({
        value: t('workspace:FolderNameUpdated'),
        description: newFolderTitle,
        state: 'success',
      }),
    )
    setRenameAnimation(false)

    if (typeof onClose === 'function') {
      onClose(e)
    }
  }

  return createPortal(
    <Modal
      headerI18n="workspace:renameFolder"
      width="360"
      visible={visible}
      onClose={onClose}
      type="dialog"
      buttons={
        <Button
          i18n={renameAnimation ? undefined : 'Rename'}
          width="100%"
          onClick={renameAnimation ? undefined : onRename}
          data-test="button_workspace_folderRenameModal_renameFolder"
          process={renameAnimation}
        >
          Rename
        </Button>
      }
      {...props}
    >
      <Flex>
        <BaseInput
          style={{ marginBottom: '0' }}
          autoFocus
          value={newFolderTitle}
          onChange={(e) => {
            setError(false)
            setNewFolderTitle(e.target.value)
          }}
          placeholder={t('workspace:FolderName')}
          $error={!!error}
          data-test="input_folderRenameModal_folderName"
        />
      </Flex>
      {error && <ValidationError offset="none" i18n="Required" />}
    </Modal>,
    document.body,
  )
}

export default styled(FolderRenameModal)``
