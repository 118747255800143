import { semantic } from './semantic'
import { colors } from './colors'
import { space } from './space'
import { radius } from './radius'

const defaultTheme = {
  palette: {
    type: {
      primary: '#1D1C21',
      secondary: '#5E5D65',
      tertiary: '#A9A7B4',
    },
    divider: '#E8E8EB',
    dividerShadow: '4px 0px 4px rgba(0, 0, 0, 0.04)',
    background: {
      white: '#FFFFFF',
      primary: '#F2F3F5',
      secondary: '#D3D5D8',
      tertiary: '#F7F8FA',
      hovered: '#7D7D7D',
      scroll: '#C2C2C2',
      track: '#E8E8E8',
    },
    theme: {
      primary: '#4059FF',
      dark: '#3950E5',
      light: '#E6EEFF',
      lighter: '#F2F7FF',
    },
    accent: {
      success: '#12B651',
      warning: '#F3374D',
      attention: '#F9B146',
      neutral: '#508BFF',
      successLight: 'rgba(18, 182, 81, 0.12)',
      warningLight: 'rgba(243, 55, 77, 0.2)',
      attentionLight: 'rgba(249, 177, 70, 0.2)',
      neutralLight: 'rgba(80, 139, 255, 0.2)',
      dragDropArea: 'rgba(255, 255, 255, 0.75)',
      secondDragDropArea: 'rgba(235, 166, 61, 0.2)',
      violet: '#9653E3',
      orange: '#F48340',
      amber: '#EBA330',
      seaGreen: '#29B8A5',
      green: '#4BB255',
      grey: '#EBECF0',
      darkGrey: '#696F80',
      black: '#2D2F33',
      graphite: '#71717A',
      approval: '#EB992F',
      review: '#217CA3',
      custom: '#626D7A',
    },
    custom: {
      reminder: '#673AB7',
    },
    brand: {
      blue: '#4272EB',
    },
    overlay: {
      background: 'rgba(146, 155, 174, 0.75)',
    },
    persona: {
      1: '#DF5F51',
      2: '#C74137',
      3: '#EB992F',
      4: '#E7AE2A',
      5: '#7BB82C',
      6: '#1DA364',
      7: '#27AC9A',
      8: '#217CA3',
      9: '#5181E0',
      10: '#4961AE',
      11: '#6651B8',
      12: '#79399E',
      13: '#A43B8F',
      14: '#B8425A',
      15: '#CC396A',
      16: '#626D7A',
    },
    avatar: [
      colors['additional-01'],
      colors['additional-02'],
      colors['additional-03'],
      colors['additional-04'],
      colors['additional-05'],
      colors['additional-06'],
      colors['additional-07'],
      colors['additional-08'],
      colors['additional-09'],
      colors['additional-10'],
      colors['additional-11'],
      colors['additional-12'],
      colors['additional-13'],
      colors['additional-14'],
      colors['additional-15'],
      colors['additional-16'],
    ],
  },
  component: {
    button: {
      surface: {
        'button-primary-color-surface': semantic.palette.surface['surface-brand'],
        'button-primary-color-surface-hover': semantic.palette.surface['surface-brand-hover'],
        'button-primary-color-surface-disabled': semantic.palette.surface['surface-brand-disabled'],
        'button-primary-critical-tone-color-surface': semantic.palette.surface['surface-critical'],
        'button-primary-critical-tone-color-surface-hover':
          semantic.palette.surface['surface-critical-hover'],
        'button-primary-critical-tone-color-surface-disabled':
          semantic.palette.surface['surface-critical-disabled'],
        'button-secondary-color-surface': semantic.palette.surface.surface,
        'button-secondary-color-surface-hover': semantic.palette.surface['surface-secondary-hover'],
        'button-secondary-color-surface-disabled': semantic.palette.surface['surface-disabled'],
        'button-tertiary-color-surface': semantic.palette.surface.surface,
        'button-tertiary-color-surface-hover': semantic.palette.surface['surface-secondary-hover'],
        'button-tertiary-color-surface-disabled': semantic.palette.surface['surface-disabled'],
        'button-icon-color-surface': semantic.palette.surface.surface,
        'button-icon-color-surface-hover': semantic.palette.surface['surface-secondary-hover'],
        'button-icon-color-background-disabled': semantic.palette.surface['surface-disabled'],
      },
      label: {
        'button-primary-color-label': semantic.palette.text['text-inverse'],
        'button-primary-color-label-hover': semantic.palette.text['text-inverse'],
        'button-primary-color-label-disabled': semantic.palette.text['text-inverse'],
        'button-primary-critical-tone-color-label': semantic.palette.text['text-inverse'],
        'button-primary-critical-tone-color-label-hover': semantic.palette.text['text-inverse'],
        'button-primary-critical-tone-color-label-disabled': semantic.palette.text['text-inverse'],
        'button-secondary-color-label': semantic.palette.text.text,
        'button-secondary-color-label-hover': semantic.palette.text.text,
        'button-secondary-color-label-disabled': semantic.palette.text['text-disabled'],
        'button-tertiary-color-label': semantic.palette.text['text-secondary'],
        'button-tertiary-color-label-hover': semantic.palette.text.text,
        'button-tertiary-color-label-disabled': semantic.palette.text['text-disabled'],
      },
      icon: {
        'button-primary-color-icon': semantic.palette.icon['icon-inverse'],
        'button-primary-color-icon-hover': semantic.palette.icon['icon-inverse'],
        'button-primary-color-icon-disabled': semantic.palette.icon['icon-inverse'],
        'button-primary-critical-tone-color-icon': semantic.palette.icon['icon-inverse'],
        'button-primary-critical-tone-color-icon-hover': semantic.palette.icon['icon-inverse'],
        'button-primary-critical-tone-color-icon-disabled': semantic.palette.icon['icon-inverse'],
        'button-secondary-color-icon': semantic.palette.icon.icon,
        'button-secondary-color-icon-hover': semantic.palette.icon.icon,
        'button-secondary-color-icon-disabled': semantic.palette.icon['icon-disabled'],
        'button-tertiary-color-icon': semantic.palette.icon['icon-secondary'],
        'button-tertiary-color-icon-hover': semantic.palette.icon.icon,
        'button-tertiary-color-icon-disabled': semantic.palette.icon['icon-disabled'],
        'button-icon-color-icon': semantic.palette.icon['icon-secondary'],
        'button-icon-color-icon-hover': semantic.palette.icon.icon,
        'button-icon-color-icon-disabled': semantic.palette.icon['icon-disabled'],
      },
      border: {
        'button-secondary-color-border': semantic.palette.border['border-secondary'],
        'button-secondary-color-border-hover': semantic.palette.border['border-secondary'],
        'button-secondary-color-border-disabled': semantic.palette.border.border,
      },
    },
    input: {
      surface: {
        'input-color-surface': semantic.palette.surface.surface,
        'input-color-background-disabled': semantic.palette.surface['surface-disabled'],
      },
      value: {
        'input-color-value': semantic.palette.text['text-tertiary'],
        'input-color-value-filled': semantic.palette.text.text,
        'input-color-value-disabled': semantic.palette.text['text-disabled'],
        'input-color-value-read-only': semantic.palette.text['text-secondary'],
      },
      icon: {
        'input-color-icon': semantic.palette.icon['icon-tertiary'],
        'input-color-icon-filled': semantic.palette.icon.icon,
        'input-color-icon-disabled': semantic.palette.icon['icon-disabled'],
        'input-color-icon-read-only': semantic.palette.icon['icon-secondary'],
      },
      border: {
        'input-color-border': semantic.palette.border['border-secondary'],
        'input-color-border-hover': semantic.palette.border['border-secondary-hover'],
        'input-color-border-active': semantic.palette.border['border-secondary-active'],
        'input-color-border-disabled': semantic.palette.border['border-tertiary'],
        'input-color-border-error': semantic.palette.border['border-critical'],
      },
    },
    card: {
      surface: {
        'card-color-surface': semantic.palette.surface.surface,
        'card-color-surface-hover': semantic.palette.surface['surface-hover'],
        'card-color-background-disabled': semantic.palette.surface['surface-disabled'],
      },
      border: {
        'card-color-border': semantic.palette.border['border-tertiary'],
        'card-color-border-hover': semantic.palette.border.border,
        'card-color-border-disabled': semantic.palette.border['border-tertiary'],
      },
    },
    checkbox: {
      surface: {
        'checkbox-color-surface-unchecked': semantic.palette.surface.surface,
        'checkbox-color-surface-unchecked-hover': semantic.palette.surface.surface,
        'checkbox-color-surface-unchecked-disabled': semantic.palette.surface['surface-disabled'],
        'checkbox-color-surface-checked': semantic.palette.surface['surface-brand'],
        'checkbox-color-surface-checked-hover': semantic.palette.surface['surface-brand-hover'],
        'checkbox-color-surface-checked-disabled':
          semantic.palette.surface['surface-brand-disabled'],
        'checkbox-color-surface-indeterminate': semantic.palette.surface['surface-brand'],
        'checkbox-color-surface-indeterminate-hover':
          semantic.palette.surface['surface-brand-hover'],
      },
      icon: {
        'checkbox-color-icon-checked': semantic.palette.icon['icon-inverse'],
        'checkbox-color-icon-checked-hover': semantic.palette.icon['icon-inverse'],
        'checkbox-color-icon-checked-disabled': semantic.palette.icon['icon-inverse'],
      },
      border: {
        'checkbox-color-border-uncheked': semantic.palette.border['border-secondary'],
        'checkbox-color-border-uncheked-hover': semantic.palette.border['border-secondary-hover'],
        'checkbox-color-border-uncheked-disabled': semantic.palette.border.border,
      },
    },
    radioButton: {
      surface: {
        'radio-button-color-surface-unchecked': semantic.palette.surface.surface,
        'radio-button-color-surface-unchecked-hover': semantic.palette.surface.surface,
        'radio-button-color-surface-unchecked-disabled':
          semantic.palette.surface['surface-disabled'],
        'radio-button-color-surface-checked': semantic.palette.surface['surface-brand'],
        'radio-button-color-surface-checked-hover': semantic.palette.surface['surface-brand-hover'],
        'radio-button-color-surface-checked-disabled':
          semantic.palette.surface['surface-brand-disabled'],
      },
      icon: {
        'radio-button-color-icon-checked': semantic.palette.icon['icon-inverse'],
        'radio-button-color-icon-checked-hover': semantic.palette.icon['icon-inverse'],
        'radio-button-color-icon-checked-disabled': semantic.palette.icon['icon-inverse'],
      },
      border: {
        'radio-button-color-color-border-uncheked': semantic.palette.border['border-secondary'],
        'radio-button-color-border-uncheked-hover':
          semantic.palette.border['border-secondary-hover'],
        'radio-button-color-border-uncheked-disabled': semantic.palette.border.border,
      },
    },
    toggle: {
      surface: {
        'toggle-color-surface-not-switched': colors['neutral-05'],
        'toggle-color-surface-not-switched-hover': semantic.palette.surface['surface-brand'],
        'toggle-color-surface-not-switched-disabled': colors['neutral-03'],
        'toggle-color-surface-switched': semantic.palette.surface['surface-brand'],
        'toggle-color-surface-switched-hover': semantic.palette.surface['surface-brand-hover'],
        'toggle-color-surface-switched-disabled':
          semantic.palette.surface['surface-brand-disabled'],
      },
      indicator: {
        'toggle-color-indicator-switched': semantic.palette.icon['icon-inverse'],
        'toggle-color-indicator-switched-hover': semantic.palette.icon['icon-inverse'],
        'toggle-color-indicator-switched-disabled': semantic.palette.icon['icon-inverse'],
      },
    },
    avatar: {
      surface: {
        'avatar-01-color-surface-default': colors['additional-01'],
        'avatar-02-color-surface-default': colors['additional-02'],
        'avatar-03-color-surface-default': colors['additional-03'],
        'avatar-04-color-surface-default': colors['additional-04'],
        'avatar-05-color-surface-default': colors['additional-05'],
        'avatar-06-color-surface-default': colors['additional-06'],
        'avatar-07-color-surface-default': colors['additional-07'],
        'avatar-08-color-surface-default': colors['additional-08'],
        'avatar-09-color-surface-default': colors['additional-09'],
        'avatar-10-color-surface-default': colors['additional-10'],
        'avatar-11-color-surface-default': colors['additional-11'],
        'avatar-12-color-surface-default': colors['additional-12'],
        'avatar-13-color-surface-default': colors['additional-13'],
        'avatar-14-color-surface-default': colors['additional-14'],
        'avatar-15-color-surface-default': colors['additional-15'],
        'avatar-16-color-surface-default': colors['additional-16'],
        'avatar-color-surface-unregistered': colors['neutral-04'],
      },
      text: {
        'avarar-color-text-default': semantic.palette.text['text-inverse'],
        'avarar-color-text-unregistered': semantic.palette.text['text-tertiary'],
      },
    },
  },
  typography: {
    header: `
      font-style: normal;
    `,
    body: `
      font-style: normal;
    `,
    medium: 'font-weight: 500;',
    regular: 'font-weight: normal;',
    bold: 'font-weight: 700;',

    '10px': 'font-size: 10px; line-height: 14px;',
    10: 'font-size: 10px; line-height: 14px;',

    '11px': 'font-size: 11px; line-height: 16px;',
    11: 'font-size: 11px; line-height: 16px;',

    '12px': 'font-size: 12px; line-height: 16px;',
    12: 'font-size: 12px; line-height: 16px;',

    '13px': 'font-size: 13px; line-height: 18px;',
    13: 'font-size: 13px; line-height: 18px;',

    '14px': 'font-size: 14px; line-height: 20px;',
    14: 'font-size: 14px; line-height: 20px;',

    '16px': 'font-size: 16px; line-height: 20px;',
    16: 'font-size: 16px; line-height: 20px;',

    '18px': 'font-size: 18px; line-height: 24px;',
    18: 'font-size: 18px; line-height: 24px;',

    '24px': 'font-size: 24px; line-height: 32px;',
    24: 'font-size: 24px; line-height: 32px;',

    '30px': 'font-size: 30px; line-height: 40px;',
    30: 'font-size: 30px; line-height: 40px;',

    uppercase: 'text-transform: uppercase;',
    lowercase: 'text-transform: lowercase;',

    'header-2x-large':
      'font-size: 30px; font-style: normal; font-weight: 700; line-height: 40px; letter-spacing: -0.63px;',
    'header-x-large':
      'font-size: 20px; font-style:normal; font-weight:500; line-height: 32px; letter-spacing: -0.34px;',
    'header-large':
      'font-size: 18px; font-style:normal; font-weight:500; line-height: 24px; letter-spacing: -0.025px;',
    'header-medium':
      'font-size: 16px; font-style:normal; font-weight:500; line-height: 24px; letter-spacing: -0.018px;',
    'header-small':
      'font-size: 11px; font-style:normal; font-weight:500; line-height: 16px; letter-spacing: 0.055px; text-transform: uppercase;',
    'body-large-01':
      'font-size: 13px; font-style:normal; font-weight:500; line-height: 20px; letter-spacing: -0.04px;',
    'body-large-02':
      'font-size: 13px; font-style:normal; font-weight:400; line-height: 20px; letter-spacing: -0.04px;',
    'body-large-03':
      'font-size: 13px; font-style:normal; font-weight:500; line-height: 16px; letter-spacing: -0.04px;',
    'body-medium-01': 'font-size: 12px; font-style:normal; font-weight:500; line-height: 16px;',
    'body-medium-02': 'font-size: 12px; font-style:normal; font-weight:400; line-height: 16px;',
    'body-small-01':
      'font-size: 11px; font-style:normal; font-weight:500; line-height: 16px; letter-spacing: 0.055px;',
    'body-small-02':
      'font-size: 11px; font-style:normal; font-weight:400; line-height: 16px; letter-spacing: 0.055px;',
    'body-x-small-01':
      'font-size: 8px; font-style:normal; font-weight:500; line-height: 12px; letter-spacing: 0.216px;',
    'body-x-small-02':
      'font-size: 8px; font-style:normal; font-weight:400; line-height: 12px; letter-spacing: 0.216px;',
  },
  animation: {
    bezier: 'cubic-bezier(0.2, 0, 0, 1) 0s',
  },
  space: {
    'button-primary-padding': semantic.space['space-2x-small'],
    'button-secondary-padding': semantic.space['space-2x-small'],
    'button-tertiary-padding': semantic.space['space-4x-small'],
    'button-icon-padding': semantic.space['space-4x-small'],
    'input-padding-horizontal': semantic.space['space-2x-small'],
    'input-padding-vertical': semantic.space['space-3x-small'],
    'input-gap': semantic.space['space-2x-small'],
    'card-padding': semantic.space['space-2x-small'],
    'card-gap': semantic.space['space-2x-small'],
    ...space,
  },
  radius: {
    'button-border-radius': semantic.radius['border-radius-small'],
    'input-border-radius': semantic.radius['border-radius-small'],
    'card-border-radius': semantic.radius['border-radius-medium'],
    'checkbox-standard-border-radius': semantic.radius['border-radius-small'],
    'checkbox-alternative-border-radius': semantic.radius['border-radius-full'],
    'radio-button-border-radius': semantic.radius['border-radius-full'],
    'toggle-border-radius': semantic.radius['border-radius-full'],
    ...radius,
  },
  shadow: {
    small: 'box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.05);',
    medium:
      'box-shadow: 0px 1.2px 2.4px 0px rgba(0, 0, 0, 0.04), 0px 7px 14px 0px rgba(0, 0, 0, 0.09);',
    large:
      'box-shadow: 0px 2.4px 4px 0px rgba(0, 0, 0, 0.05), 0px 12px 22px 0px rgba(0, 0, 0, 0.10);',
  },
  semantic,
  colors,
  defaultRadius: radius,
  grid: {
    large: {
      maxWidth: '848px',
      columnGap: '16px',
      gridTemplateColumns: 'repeat(12, 56px)',
    },
    medium: {
      maxWidth: '560px',
      columnGap: '16px',
      gridTemplateColumns: 'repeat(8, 56px)',
    },
    small: {
      maxWidth: '416px',
      columnGap: '16px',
      gridTemplateColumns: 'repeat(6, 56px)',
    },
    xsmall: {
      minWidth: '272px',
      columnGap: '16px',
      gridTemplateColumns: 'repeat(4, minmax(56px, 1fr))',
    },
  },
}

export default defaultTheme
