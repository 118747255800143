export default {
  en: {
    contractsGrouping: 'Group files in a contract',
    nameOfContract: 'Name of contract',
    selectMultipleFiles: 'Select multiple files to group them into a contract',
    importOptions: 'Import options',
    importOptionsDescription:
      'You can use simple import or smart import using AI profiles for intelligent file analysis and autofill basic and custom fields.',
    AIProfiles: 'AI profiles',
  },
  de: {
    contractsGrouping: 'Dateien in einem Vertrag gruppieren',
    nameOfContract: 'Vertragstitel',
    selectMultipleFiles: 'Mehrere Dateien auswählen, um sie in einem Vertrag zusammenzufassen',
    importOptions: 'Optionen für den Import',
    importOptionsDescription:
      'Du kannst den einfachen Import oder den intelligenten Import mit KI-Profilen für die intelligente Dateianalyse und das automatische Ausfüllen von Basis- und benutzerdefinierten Feldern verwenden.',
    AIProfiles: 'KI-Profile',
  },
  fr: {
    contractsGrouping: 'Regrouper des fichiers dans un contrat',
    nameOfContract: 'Nom du contrat',
    selectMultipleFiles: 'Sélectionner plusieurs fichiers pour les regrouper dans un contrat',
    importOptions: "Options d'importation",
    importOptionsDescription:
      "Vous pouvez utiliser l'importation simple ou l'importation intelligente à l'aide des profils IA pour une analyse intelligente des fichiers et un remplissage automatique des champs de base et des champs personnalisés.",
    AIProfiles: "Profils d'IA",
  },
}
