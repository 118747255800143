import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApiPagination } from '../utils/callApi'

export const getCompanies = createAsyncThunk(
  'admin/companies/fetch',
  // eslint-disable-next-line default-param-last
  async ({ callback } = {}, api) => {
    const token = api.extra.getToken()

    const { data } = await callApiPagination({
      endpoint: 'companies',
      method: 'GET',
      applicationJson: true,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/vnd.api+json',
    })

    api.extra.sendCallback(callback)

    return { companies: data }
  },
)
