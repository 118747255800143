export const space = {
  'space-0': '0',
  'space-025': '2px',
  'space-050': '4px',
  'space-075': '6px',
  'space-100': '8px',
  'space-150': '12px',
  'space-200': '16px',
  'space-300': '24px',
  'space-400': '32px',
  'space-500': '40px',
  'space-600': '48px',
  'space-800': '64px',
  'space-1000': '80px',
}
