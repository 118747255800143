import { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Button, Modal } from '../../atoms'
import { createNotification } from '../../../actions/notifications'
import { selectWorkspaces } from '../../../store/selectors'

const TransferOwnershipModal = ({ visible, onClose, userName, folderId }) => {
  const { palette } = useTheme()
  const dispatch = useDispatch()

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    folderTitle: workspaces.allFolders[folderId]?.attributes.name,
  }))
  const { folderTitle } = useSelector(selectData)

  const ownerName = 'TODO OWNER TODO'

  const onClick = () => {
    onClose()
    dispatch(
      createNotification({
        state: 'success',
        value: 'Ownership of the folder has been transferred',
        description: `${userName} became the new owner of the ${folderTitle} folder.`,
      }),
    )
  }
  return (
    <Modal
      visible={visible}
      header="Transfer folder Ownership?"
      subHeader={`${userName} will have full permissions for this folder and subfolders. ${ownerName} will still have access to this folder and subfolders.`}
      onClose={onClose}
      buttons={
        <>
          <Button i18n="cancel" width="fit-content" secondary onClick={onClose}>
            Cancel
          </Button>
          <Button width="fit-content" background={palette.accent.warning} onClick={onClick}>
            Transfer
          </Button>
        </>
      }
    />
  )
}

export default TransferOwnershipModal
