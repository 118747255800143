import { createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../utils/callApi'

export const getSharepointUser = createAsyncThunk(
  'sharepoint/get-tool-user',
  async ({ callback, daId }, api) => {
    const token = api.extra.getToken()

    const { naturalPersons = [] } = await callApi({
      endpoint: `digitalassets/${daId}/shareWith/MS_SHAREPOINT`,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/json',
    })

    api.extra.sendCallback(callback, naturalPersons)

    return { naturalPersons }
  },
)
