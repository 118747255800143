import { format, formatDistance as fnFormatDistance } from 'date-fns'
import { de, fr } from 'date-fns/locale'
import isDate from 'lodash/isDate'

import i18n from '../i18n'

// en should be undefined as it is default
const languages = { de, fr, en: undefined }

export default (date, frmt, options = {}) => {
  const { language } = i18n
  const dateToFormat = isDate(date) ? date : new Date(date)

  return format(dateToFormat, frmt, { ...options, locale: languages[language] })
}

export const toUTC = (date, convert) => {
  if (convert) {
    const direction = convert === true ? '+' : convert
    const userTimezoneOffset = date.getTimezoneOffset() * 60000
    if (direction === '+') {
      const dateWithTimezone = new Date(date.getTime() - userTimezoneOffset)
      return dateWithTimezone.toISOString()
    }
    const dateWithTimezone = new Date(date.getTime() + userTimezoneOffset)
    return dateWithTimezone.toISOString()
  }
  return date.toISOString()
}

export const toGoogleUTC = (date, convert) => {
  return toUTC(date, convert).replace(/[:-]/g, '').replace('.000', '')
}

export const formatDistance = (...args) => {
  const { language } = i18n
  return fnFormatDistance(...args, { addSuffix: true, locale: languages[language] })
}
