import { animated, useSpring } from '@react-spring/web'
import { useState } from 'react'
import { useMeasure } from 'react-use'
import styled, { useTheme } from 'styled-components'

import { Flex, ScrollContainer, Spacing, SvgMask, Typography } from '../../atoms'
import AccessTab from './AccessTab'

const GeneralAccess = ({ visible, maintainers, parentFolders, folderId, accessProfiles }) => {
  const { palette } = useTheme()

  const [expanded, setExpanded] = useState(false)

  const [ref, { height }] = useMeasure()
  const expand = useSpring({
    height: `${height}px`,
    overflow: 'hidden',
  })

  if (!visible) {
    return null
  }

  return (
    <animated.div style={expand}>
      <div ref={ref}>
        <Container data-test={`manageAccess_generalAccess_${expanded ? 'expanded' : 'hidden'}`}>
          <Trigger onClick={() => setExpanded(!expanded)}>
            <Flex align="flex-start" content="space-between">
              <div>
                <Typography i18n="workspace:GeneralAccess" variant="body 14 medium">
                  General access
                </Typography>
                <Spacing size="4" />
                <Typography
                  color="secondary"
                  variant="body 13 regular"
                  i18n="workspace:WorkspaceMaintainersAndOwners"
                >
                  Workspace maintainers and owners of parent folders also have access to this
                  folder. These users can manage folder and create contracts.
                </Typography>
              </div>
              <Spacing size="16" />
              <SvgMask
                color={palette.accent.graphite}
                icon={expanded ? 'chevron-small-up' : 'chevron-small-down'}
              />
            </Flex>
          </Trigger>
          <Spacing size={expanded ? 16 : 0} />
          {expanded ? (
            <div>
              <Typography
                color="secondary"
                variant="body 12 medium"
                i18n="workspace:WorkspaceMaintainers"
              >
                Workspace maintainers
              </Typography>
              <Spacing size="12" />
              <AccessTab
                members={maintainers}
                folderId={folderId}
                accessProfiles={accessProfiles}
                membersType="maintainers"
              />
              {parentFolders.length > 0 ? (
                <>
                  <Spacing size="16" />
                  <Typography
                    color="secondary"
                    variant="body 12 medium"
                    i18n="workspace:OwnersOfParentFolders"
                    data-test="typography_generalAccess_parentFolders"
                  >
                    Owners of parent folders
                  </Typography>
                  <Spacing size="12" />
                  <AccessTab
                    members={parentFolders}
                    folderId={folderId}
                    accessProfiles={accessProfiles}
                    membersType="parentFolders"
                  />
                </>
              ) : undefined}
            </div>
          ) : undefined}
        </Container>
      </div>
    </animated.div>
  )
}

const Trigger = styled.div`
  cursor: pointer;
`

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 6px;
  padding: 16px;

  ${ScrollContainer} {
    display: flex;
    margin-right: -16px;

    > div {
      display: block;
      width: 470px;
    }
  }
`

export default GeneralAccess
