import Bowser from 'bowser'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { createNotification } from '../../actions/notifications'

export const BrowserNotSupport = () => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  const browserInfo = browser.getBrowser()
  const browserVersion = browserInfo.version
  const mainBrowserVersion = parseInt(browserVersion.split('.')[0], 10)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    const isSupported =
      browserInfo.name === 'Chrome' ||
      browserInfo.name === 'Firefox' ||
      browserInfo.name === 'Chromium' ||
      (browserInfo.name === 'Microsoft Edge' && mainBrowserVersion >= 81) ||
      browserInfo.name === 'Safari'

    if (!isSupported) {
      dispatch(
        createNotification({
          value: t('wrongBrowser'),
          state: 'warning',
        }),
      )
    }
  }, [dispatch, t, browserInfo.name, mainBrowserVersion])

  return null
}
