export default {
  en: {
    AnalysisProcess: 'Analysis process…',
    AnalyzedByAI: 'Analyzed by AI',
    RemoveResult: 'Remove result',
    CanBeRestored: '(сan be restored)',
    SmartImport: 'Smart Import',
    ImportWithAIDataAnalysis: 'Autofill contract metadata using AI profiles',
    SmartImportResults: 'Smart Import results',
    SuggestionsBasedOnAIAnalysis: 'Suggestions based on AI analysis.',
    SmartImportResultsName: 'Smart Import results: {{fileName}}',
    SuggestionsBasedOnAIAnalysisPleaseDoubleCheck:
      'Suggestions based on AI analysis. Please double-check the results before adding them to the contract.',
    NoSuggestions: 'No suggestions.',
    RestoreResult: 'Restore result',
    AddResults: 'Add results',
    YouCanChangeTheSuggestion: 'You can change the suggestion',
    PDFOnly: '(PDF only)',
    aiProfileDescription: 'Select the AI profile to be used to analyse the imported contracts.',
  },
  de: {
    AnalysisProcess: 'Analyse-Prozess…',
    AnalyzedByAI: 'Analysiert durch KI ',
    RemoveResult: 'Ergebnis entfernen',
    CanBeRestored: '(kann wiederhergestellt werden)',
    SmartImport: 'Smart Import',
    ImportWithAIDataAnalysis: 'Automatisches Ausfüllen der Vertragsmetadaten mit KI-Profilen',
    SmartImportResults: 'Smart Import Ergebnisse',
    SuggestionsBasedOnAIAnalysis: 'Vorschläge basierend auf der KI-Analyse.',
    SmartImportResultsName: 'Smart Import Ergebnisse: {{fileName}}',
    SuggestionsBasedOnAIAnalysisPleaseDoubleCheck:
      'Vorschläge basierend auf der KI-Analyse. Bitte überprüfe die Ergebnisse, bevor du sie dem Vertrag hinzufügst. ',
    NoSuggestions: 'Keine Vorschläge',
    RestoreResult: 'Ergebnis wiederherstellen',
    AddResults: 'Ergebnisse hinzufügen',
    YouCanChangeTheSuggestion: 'Du kannst den Vorschlag anpassen.',
    PDFOnly: '(nur PDF)',
    aiProfileDescription: 'Wähle das KI-Profil zum Analysieren der importierten Verträge aus.',
  },
  fr: {
    AnalysisProcess: "Processus d'analyse…",
    AnalyzedByAI: "Analysé par l'IA",
    RemoveResult: 'Supprimer le résultat',
    CanBeRestored: '(сan be restored)',
    SmartImport: 'Importation intelligente',
    ImportWithAIDataAnalysis:
      "Remplir automatiquement les métadonnées des contrats à l'aide de profils d'intelligence artificielle.",
    SmartImportResults: 'Smart Import results',
    SuggestionsBasedOnAIAnalysis: 'Suggestions based on AI analysis.',
    SmartImportResultsName: "Résultats de l'importation intelligente: {{fileName}}",
    SuggestionsBasedOnAIAnalysisPleaseDoubleCheck:
      "Suggestions basées sur l'analyse de l'IA. Veuillez vérifier les résultats avant de les ajouter au contrat.",
    NoSuggestions: 'Aucune suggestion.',
    RestoreResult: 'Rétablir le résultat',
    AddResults: 'Ajouter des résultats',
    YouCanChangeTheSuggestion: 'Vous pouvez modifier la suggestion',
    PDFOnly: '(PDF only)',
    aiProfileDescription:
      'Sélectionnez le profil AI à utiliser pour analyser les contrats importés.',
  },
}
