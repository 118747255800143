import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/selectors'

const useAccountType = (type) => {
  const currentUser = useSelector(selectCurrentUser)
  const { userType } = currentUser || {}

  return [userType === type, currentUser]
}

export default useAccountType
