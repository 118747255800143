import styled from 'styled-components'
import { semantic } from '../../theme/semantic'

const SidebarDivider = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: 1px;
  background: ${({ theme, primary, color }) => {
    if (color) {
      return color
    }
    return primary ? theme.semantic.palette.border['border-tertiary'] : theme.palette.divider
  }};
`

export const VerticalDivider = styled.div`
  width: 1px;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  background: ${({ theme, primary, color }) => {
    if (color) {
      return color
    }
    return primary ? theme.palette.background.primary : theme.semantic.palette.border.border
  }};
`

export default SidebarDivider
