import { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'

import i18n from '../../../i18n'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_API_KEY

let stripePromise = null

const StripeContext = ({ children }) => {
  const { language } = i18n

  // lazy loading stripe assets only once
  const [stripeState, setStripeState] = useState(stripePromise)
  useEffect(() => {
    const lazyLoadStripe = async () => {
      if (!stripeState) {
        if (!stripePromise) {
          // note: Stripe methods should be loaded with async import to prevent Stripe to initialize
          //       itself on application loading. Stripe is making several HTTP requests and we
          //       do want them to happen only when needed
          const { loadStripe } = await import('@stripe/stripe-js')
          if (!STRIPE_KEY) return false
          stripePromise = loadStripe(STRIPE_KEY)
        }
        setStripeState(stripePromise)
        return true
      }
      return false
    }

    lazyLoadStripe()
  }, [stripeState])

  if (!stripeState) {
    return null
  }

  return (
    <Elements stripe={stripePromise} options={{ locale: language }}>
      {children}
    </Elements>
  )
}

export default StripeContext
