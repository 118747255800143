import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Typography from './Typography'

const Badge = ({ value, active = false, ...props }) => {
  return (
    <div {...props}>
      <Typography variant="body-medium-01">{value}</Typography>
    </div>
  )
}

Badge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
}

export default styled(Badge)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-full']};
  padding: 0 ${({ theme }) => theme.semantic.space['space-2x-small']};

  background: ${({ theme, active }) =>
    active
      ? theme.semantic.palette.surface['surface-brand-active']
      : theme.semantic.palette.surface['surface-tertiary']};

  &:hover {
    background: ${({ theme, active }) =>
      !active && theme.semantic.palette.surface['surface-brand']};
    > ${Typography} {
      color: ${({ theme, active }) => !active && theme.semantic.palette.text['text-inverse']};
    }
  }

  > ${Typography} {
    color: ${({ theme, active }) =>
      active
        ? theme.semantic.palette.text['text-inverse']
        : theme.semantic.palette.text['text-secondary']};
  }
`
