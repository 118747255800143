import { useEffect, useState, useCallback } from 'react'
import CountryListJson from './countryList.json'

import BaseInput from '../../atoms/Input/BaseInput'

const dataList = CountryListJson.map((data) => ({ value: data[0], view: data[0] }))

const countryHashMap = new Map()
const reverseCountryHashMap = new Map()
CountryListJson.forEach((data) => {
  const country = data[0]
  const code = data[1]

  countryHashMap.set(country.toUpperCase(), code)
  reverseCountryHashMap.set(code, country)
})

const CountryInput = ({ onChange, defaultValue, placeholder, ...props }) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(reverseCountryHashMap.get(defaultValue) || '')
  }, [defaultValue])

  const onInnerChange = useCallback(
    (e) => {
      setValue(e.target.value)

      // propagate the contry value if it was autofilled by Browser
      const country = e.target.value.replace(/\s+/g, ' ').trim()
      const countryToUpperCase = country.toUpperCase()
      if (countryHashMap.has(countryToUpperCase)) {
        onChange(countryHashMap.get(countryToUpperCase))
      }
    },
    [onChange],
  )

  const onInnerBlur = useCallback(
    (e) => {
      const country = e.target.value.replace(/\s+/g, ' ').trim()
      const countryToUpperCase = country.toUpperCase()
      if (countryHashMap.has(countryToUpperCase)) {
        setValue(country)
        onChange(countryHashMap.get(countryToUpperCase))
      } else {
        onChange('')
        setValue('')
      }
    },
    [onChange],
  )

  return (
    <BaseInput
      name="country"
      placeholder={placeholder}
      value={value}
      onChange={onInnerChange}
      onBlur={onInnerBlur}
      datalist={dataList}
      {...props}
    />
  )
}

export default CountryInput
