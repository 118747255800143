export default {
  en: {
    plans: 'Plans',
    choosePlan: "Choose the plan that's right for you",
    free: 'Free',
    basic: 'Basic',
    enterprise: 'Enterprise',
    freeDescription: 'For anyone who was invited to sign or view contracts',
    basicDescription: 'For teams that need automation tools to work with contracts',
    enterpriseDescription: 'For organizations that need more opportunities',
    perMonth: 'per month',
    currentPlan: 'Current plan',
    selectPlan: 'Select plan',
    free0: 'Single user',
    free1: 'Unlimited access to contracts',
    free2: 'Unlimited eSignatures',
    basic0: 'Up to {{count}} users',
    basic1: 'Unlimited contracts',
    basic2: 'Unlimited workspaces',
    enterprise0: 'Everything in {{plan}}, plus:',
    enterprise1: 'Sharepoint integration',
    enterprise2: 'Advanced branding',
    enterprise3: 'Premium support',
    needHelp: 'Need help? Talk to an expert and find the best plan for your company',
    contactUs: 'Contact us',
    contactUsDescription: 'To get a price estimate, please contact our Sales',
    contractSales: 'Contract Sales',
    UpgradeCompanyToPlan: 'Upgrade {{company}} to {{plan}}',
    AddYourBillingDetails: 'Add your billing details to continue',
    AddBillingDetails: 'Add billing details',
    BilledMonthly: 'Billed monthly',
    summary0: '€{{count}} x 1 month',
    summary1: 'Sales tax',
    summary2: 'Due today',
    Upgrade: 'Upgrade',
    ByProvidingYourPaymentInformation:
      'By providing your payment information and confirming this payment, you authorise (A) key2contract GmbH and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.',
    BackToPlans: 'Back to plans',
    PaymentDetails: 'Payment details',
  },
  de: {
    plans: 'Pläne',
    choosePlan: 'Wähle den passenden Tarif für dich aus.',
    free: 'Free',
    basic: 'Basic',
    enterprise: 'Enterprise',
    freeDescription: 'Für alle, die eingeladen wurden, Verträge zu unterzeichnen oder zu lesen.',
    basicDescription: 'Für Teams, die Automatisierungstools für die Arbeit mit Verträgen benötigen',
    enterpriseDescription: 'Für Organisationen, die mehr Möglichkeiten benötigen',
    perMonth: 'pro Monat',
    currentPlan: 'Aktueller Plan',
    selectPlan: 'Plan auswählen',
    free0: 'Einzelnutzer',
    free1: 'Unbegrenzter Zugang zu Verträgen',
    free2: 'Unbegrenzte eSignaturen',
    basic0: 'Bis zu {{count}} Nutzer',
    basic1: 'Unbegrenzte Verträge',
    basic2: 'Unbegrenzte Arbeitsbereiche',
    enterprise0: 'Alles in {{plan}}, plus:',
    enterprise1: 'Sharepoint-Integration',
    enterprise2: 'Erweitertes Branding',
    enterprise3: 'Premium Support',
    needHelp:
      'Brauchst du Hilfe? Sprich mit einem Experten und finde den besten Plan für dein Unternehmen',
    contactUs: ' Kontaktiere uns',
    contactUsDescription: 'Um ein Preisangebot zu erhalten, kontaktierе bitte unseren Vertrieb',
    contractSales: 'Vertrieb kontaktieren',
    UpgradeCompanyToPlan: 'Upgrade {{company}} auf {{plan}}',
    AddYourBillingDetails: 'Füge deine Rechnungsdetails hinzu, um fortzufahren',
    AddBillingDetails: 'Rechnungsdetails hinzufügen',
    BilledMonthly: 'Monatlich fällig',
    summary0: '€{{count}} x 1 Monat',
    summary1: 'Umsatzsteuer',
    summary2: 'Heute fällig',
    Upgrade: 'Upgrade',
    ByProvidingYourPaymentInformation:
      'Mit der Angabe deiner Zahlungsinformationen und der Bestätigung dieser Zahlung ermächtigst du (A) key2contract GmbH und Stripe, unseren Zahlungsdienstleister, Anweisungen an deine Bank zu senden, dein Konto zu belasten, und (B) deine Bank, dein Konto gemäß diesen Anweisungen zu belasten. Im Rahmen deiner Rechte hast du einen Anspruch auf Erstattung von deiner Bank gemäß den Bedingungen deines Vertrages mit deiner Bank. Die Erstattung muss innerhalb von 8 Wochen ab dem Datum der Abbuchung von deinem Konto beantragt werden. Deine Rechte werden in einer Erklärung erläutert, die du bei deiner Bank erhalten kannst. Du erklärst dich damit einverstanden, dass du über künftige Abbuchungen bis zu 2 Tage im Voraus benachrichtigt wirst.',
    BackToPlans: 'Zurück zu den Plänen',
    PaymentDetails: 'Abrechnungsdetails',
  },
  fr: {
    plans: 'Plans',
    choosePlan: 'Choisissez le plan qui vous convient',
    free: 'Libre',
    basic: 'De Base',
    enterprise: 'Enterprise',
    freeDescription: 'Pour toute personne invitée à signer ou à consulter des contrats',
    basicDescription:
      "Pour les équipes qui ont besoin d'outils d'automatisation pour travailler avec des contrats",
    enterpriseDescription: "Pour les organisations qui ont besoin de plus d'opportunités",
    perMonth: 'par mois',
    currentPlan: 'plan actuel',
    selectPlan: 'choisir un plan',
    free0: 'Utilisateur unique',
    free1: 'Accès illimité aux contrats',
    free2: 'Signatures électroniques illimitées',
    basic0: "Jusqu'à  {{count}} utilisateurs",
    basic1: 'Contrats illimités',
    basic2: 'Espaces de travail illimités',
    enterprise0: 'Tout ce qui se trouve dans {{plan}}, plus:',
    enterprise1: 'Intégration de Sharepoint',
    enterprise2: 'Marque avancée',
    enterprise3: 'Support Premium',
    needHelp:
      "Besoin d'aide ? Parlez à un expert et trouvez le meilleur plan pour votre entreprise",
    contactUs: 'Contactez nous',
    contactUsDescription: 'Pour obtenir un devis, veuillez contacter notre service des ventes.',
    contractSales: 'Ventes contractuelles',
    UpgradeCompanyToPlan: 'Passer de {{company}} à {{plan}}',
    AddYourBillingDetails: 'Ajoutez vos coordonnées de facturation pour continuer',
    AddBillingDetails: 'Ajouter des détails de facturation',
    BilledMonthly: 'Facturation mensuelle',
    summary0: '{{count}}€ x 1 mois',
    summary1: 'Taxe de vente',
    summary2: "A remettre aujourd'hui",
    Upgrade: 'Mise à niveau',
    ByProvidingYourPaymentInformation:
      "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) key2contract GmbH et Stripe, notre fournisseur de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous pouvez prétendre à un remboursement de la part de votre banque selon les termes et conditions de votre accord avec votre banque. Le remboursement doit être demandé dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications pour les débits futurs jusqu'à 2 jours avant qu'ils n'aient lieu.",
    BackToPlans: 'Retour aux plans',
    PaymentDetails: 'Modalités de paiement',
  },
}
