/* eslint-disable no-underscore-dangle */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../utils/callApi'

export { default as login } from './accounts/login'

export const changeLanguage = createAction('account/language')

export const getMyAccount = createAsyncThunk('account/get', async ({ id, callback }, api) => {
  const {
    data: { relationships },
  } = await callApi({
    endpoint: `naturalpersons/${id}`,
  })

  const accountId = relationships.account.data.id

  const { data, included = [] } = await callApi({
    endpoint: `accounts/${accountId}?include=identState`,
  })

  const identState = included.find((i) => i.type === 'identstates')
  const result = { account: data.attributes, identState, accountId }

  api.extra.sendCallback(callback, result)

  return result
})

const isTestEnvironment = process.env.REACT_APP_API_STAGE !== 'prod'

export const initializeNectRedirect = createAsyncThunk('account/nect/start', async (args, api) => {
  const mockType =
    window.__kycMockType || window.__kycMockType === 0
      ? window.__kycMockType
      : null /* null, 0 ... 13 */

  if (mockType !== null) {
    // eslint-disable-next-line no-console
    console.warn(
      `Warning! mockType should be NULL instead of ${mockType}. Use non-NULL values only for debugging`,
    )
  }

  const endpoint =
    isTestEnvironment && mockType !== null
      ? `selfident/start?mockType=${mockType}`
      : 'selfident/start'

  const response = await callApi({
    endpoint,
    method: 'PUT',
  })

  const { caseUUID, jumpPageUrl } = response

  window.location.href = `${jumpPageUrl}?case_uuid=${caseUUID}`

  return {}
})

// eslint-disable-next-line default-param-last
export const getMyDevices = createAsyncThunk('account/get/devices', async (payload = {}, api) => {
  const result = await callApi({
    endpoint: `secondfactors`,
  })

  api.extra.sendCallback(payload.callback, result)

  return { devices: result.data }
})

export const setCompanyMembership = createAction('account/company/membership')

export const removePending2FA = createAsyncThunk(
  'account/delete/device',
  async ({ id, passwordHash, callback }, api) => {
    try {
      await callApi({
        endpoint: `secondfactors/${id}`,
        disableNotifications: true,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'X-INHUBBER-SECRET': passwordHash,
        },
      })
      api.dispatch(getMyDevices())
      api.extra.sendCallback(callback)
    } catch (e) {
      api.extra.sendCallback(callback, e)
    }
  },
)
export const setExtending = createAsyncThunk(
  'account/setExtending',
  async ({ extendingName, extendingState, callback }, api) => {
    const { extending } = api.getState().account
    return { ...extending, [extendingName]: extendingState }
  },
)
