import styled, { useTheme } from 'styled-components'

import { Button, Flex, SvgMask, Typography } from '../atoms'

const Banner = ({
  icon,
  headerI18n,
  header,
  subHeaderI18n,
  subHeader,
  children,
  className,
  onClose,
  tone,
  headerVariant,
  buttons,
  ...props
}) => {
  const theme = useTheme()

  return (
    <div className={className} {...props}>
      <Flex gap={theme.semantic.space['space-2x-small']} align="flex-start" width="100%">
        <HeaderContainer gap={theme.semantic.space['space-2x-small']} align="flex-start">
          <SvgMask
            icon={icon || 'info-circle'}
            color={theme.semantic.palette.icon[`icon-${tone || 'info'}`]}
            style={{ marginTop: '2px' }}
          />
          <Flex direction="column" align="flex-start" gap={theme.semantic.space['space-4x-small']}>
            <Typography i18n={headerI18n} variant={headerVariant || 'body-large-01'}>
              {header}
            </Typography>
            {(subHeaderI18n || subHeader) && (
              <Typography i18n={subHeaderI18n} variant="body-large-02">
                {subHeader}
              </Typography>
            )}
          </Flex>
        </HeaderContainer>
        {buttons}
        {typeof onClose === 'function' ? (
          <Button width="fit-content" onClick={onClose} type="icon" icon="xmark-small" />
        ) : undefined}
      </Flex>
      {children && <Actions align="flex-start">{children}</Actions>}
    </div>
  )
}

const HeaderContainer = styled(Flex)`
  flex: auto;
`

const Actions = styled(Flex)`
  padding-left: ${({ theme }) => theme.semantic.space['space-medium']};
`

export default styled(Banner)`
  background-color: ${({ theme, tone, background }) =>
    background || theme.semantic.palette.surface[`surface-${tone || 'info'}-secondary`]};
  padding: ${({ theme, subHeaderI18n, subHeader, buttons, children }) =>
    subHeaderI18n || subHeader || buttons || children
      ? theme.semantic.space['space-small']
      : theme.semantic.space['space-2x-small']};
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-medium']};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.semantic.space['space-small']};
  width: 100%;
`
