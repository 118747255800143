import { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Spacing } from '../../atoms'
import FactoryVerify from './FactoryVerify'

import { getMyAccount } from '../../../actions/account'
import { identStateSelector, selectCurrentUser } from '../../../store/selectors'

const FooterInformation = ({ className }) => {
  const dispatch = useDispatch()

  const { id } = useSelector(selectCurrentUser)
  const identState = useSelector(identStateSelector)

  useEffect(() => {
    if (!id) {
      return
    }

    dispatch(getMyAccount({ id }))
  }, [dispatch, id])
  const Components = useMemo(
    () => [
      {
        condition: () =>
          identState?.attributes.value === 'requested' ||
          identState?.attributes.value === 'pending' ||
          identState?.attributes.value === 'failed_restartable',
        factory: FactoryVerify,
      },
    ],
    [identState],
  )

  const componentsToRender = useMemo(() => Components.filter((c) => c.condition()), [Components])
  const visible = componentsToRender.length > 0

  if (!visible) {
    return null
  }

  const component = componentsToRender[0]

  return (
    <>
      <div className={className}>
        <component.factory />
      </div>
      <Spacing size="8" />
    </>
  )
}

export default styled(FooterInformation)`
  width: 100%;
  min-height: 32px;

  border-radius: 8px;
  box-sizing: border-box;
  padding: 12px;
  background: ${({ theme }) => theme.palette.background.white};
`
