import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Button from './Button'
import SvgMask from './SvgMask'
import Typography from './Typography'

const BaseReminder = ({ amount, timeUnit, className, onClick, active }) => {
  const { t } = useTranslation()
  const isBefore = amount < 1

  const text = `${Math.abs(amount)} ${t(timeUnit, { count: Number(amount) })} ${t(
    isBefore ? 'before' : 'after',
  )}`

  return (
    <Button
      className={className}
      tertiary
      width="fit-width"
      icon="clock"
      onClick={onClick}
      active={active}
      data-test="button_tabEvents_reminder_activeReminder"
    >
      {text}
    </Button>
  )
}

export default styled(BaseReminder)`
  border: 1px solid ${({ theme }) => theme.palette.custom.reminder};
  height: 28px;
  min-height: 28px;
  padding: 6px 8px;
  margin: 4px;

  ${Typography} {
    color: ${({ theme }) => theme.palette.custom.reminder};
  }

  ${SvgMask} {
    background-color: ${({ theme }) => theme.palette.custom.reminder};
  }

  &:hover {
    ${Typography} {
      color: ${({ theme }) => theme.palette.custom.reminder};
    }

    ${SvgMask} {
      background-color: ${({ theme }) => theme.palette.custom.reminder};
    }
  }
`
