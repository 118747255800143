import { DATE_FILTERS } from '../enums'

export default {
  en: {
    home: 'Home',
    totalContracts: 'Total contracts',
    requests: 'Requests',
    upcomingEvents: 'Upcoming Events',
    goodNight: 'Good night, {{firstName}}',
    goodMorning: 'Good morning, {{firstName}}',
    goodAfternoon: 'Good afternoon, {{firstName}}',
    goodEvening: 'Good evening, {{firstName}}',
    expireSoon: 'Expire Soon',
    [DATE_FILTERS.ALL]: 'All periods',
    [DATE_FILTERS.DAYS7]: 'Next 7 days',
    [DATE_FILTERS.DAYS30]: 'Next 30 days',
    [DATE_FILTERS.MONTHS3]: 'Next 3 months',
    [DATE_FILTERS.MONTHS6]: 'Next 6 months',
    [DATE_FILTERS.YEAR_NEXT]: 'Next year',
    noEvents: 'No events coming up',
    noContracts: 'No contracts expire soon',
    showMore: 'Show more',
    identityVerified: 'Identity verified',
    identityNotVerified: 'Identity not verified',
    identityVerifiedLong: 'Your identity has been verified.',
    identityNotVerifiedLong:
      'There was a problem with your identity verification process. Please try again.',
    continueWork: 'Continue work',
    myTasks: 'My tasks',
    myTasks_one: 'My tasks ({{count}})',
    myTasks_other: 'My tasks ({{count}})',
  },
  de: {
    home: 'Home',
    totalContracts: 'Verträge insgesamt',
    requests: 'Anfragen',
    upcomingEvents: 'Bevorstehende Termine',
    goodNight: 'Gute Nacht, {{firstName}}',
    goodMorning: 'Guten Morgen, {{firstName}}',
    goodAfternoon: 'Guten Tag, {{firstName}}',
    goodEvening: 'Guten Abend, {{firstName}}',
    expireSoon: 'Laufen bald aus',
    [DATE_FILTERS.ALL]: 'Alle Zeiträume',
    [DATE_FILTERS.DAYS7]: 'Nächste 7 Tage',
    [DATE_FILTERS.DAYS30]: 'Nächste 30 Tage',
    [DATE_FILTERS.MONTHS3]: 'Nächste 3 Monate',
    [DATE_FILTERS.MONTHS6]: 'Nächste 6 Monate',
    [DATE_FILTERS.YEAR_NEXT]: 'Nächstes Jahr',
    noEvents: 'Keine Termine stehen bevor',
    noContracts: 'Keine bald auslaufenden Verträge',
    showMore: 'Mehr zeigen',
    identityVerified: 'Identität bestätigt',
    identityNotVerified: 'Identität nicht bestätigt',
    identityVerifiedLong: 'Deine Identität wurde verifiziert.',
    identityNotVerifiedLong:
      'Es gab ein Problem bei der Prüfung deiner Identität. Bitte versuche es erneut.',
    continueWork: 'Arbeit fortsetzen',
    myTasks: 'Meine Aufgaben',
    myTasks_one: 'Meine Aufgaben ({{count}})',
    myTasks_other: 'Meine Aufgaben ({{count}})',
  },
  fr: {
    home: "Page d'accueil",
    totalContracts: 'Total des contrats',
    requests: 'Demandes',
    upcomingEvents: 'Événements À Venir',
    goodNight: 'Bonne nuit, {{firstName}}',
    goodMorning: 'Bonjour, {{firstName}}',
    goodAfternoon: 'Bon aprés-midi, {{firstName}}',
    goodEvening: 'Bonne soirée, {{firstName}}',
    expireSoon: 'Expire Bientôt',
    [DATE_FILTERS.ALL]: 'Toutes les périodes',
    [DATE_FILTERS.DAYS7]: 'Les 7 prochains jours',
    [DATE_FILTERS.DAYS30]: 'Les 30 prochains jours',
    [DATE_FILTERS.MONTHS3]: 'Les 3 prochains mois',
    [DATE_FILTERS.MONTHS6]: 'Les 6 prochains mois',
    [DATE_FILTERS.YEAR_NEXT]: "L'année prochaine",
    noEvents: 'Aucun événement à venir',
    noContracts: "Aucun contrat n'expire bientôt",
    showMore: 'En savoir plus',
    identityVerified: 'Identité vérifiée',
    identityNotVerified: 'Identité non vérifiée',
    identityVerifiedLong: 'Votre identité a été vérifiée.',
    identityNotVerifiedLong:
      'Il y a eu un problème lors de la vérification de votre identité. Veuillez réessayer.',
    continueWork: 'Poursuivre le travail',
    myTasks: 'Mes tâches',
    myTasks_one: 'Mes tâches {{count}}',
    myTasks_other: 'Mes tâches {{count}}',
  },
}
