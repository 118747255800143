import styled from 'styled-components'
import { Container, Row } from 'react-grid-system'

const PageContainer = styled(Container).attrs(() => ({ fluid: true }))`
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
`

export const VerticalRow = styled(Row)`
  height: 100%;
  flex-wrap: initial !important;
`

export const HorizontalRow = styled(Row)`
  > div:first-child {
    flex: auto;
  }
`

export default PageContainer
