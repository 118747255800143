import { createGlobalStyle, useTheme } from 'styled-components'

import { ElementTooltip, Elevation, Flex, Spacing, SvgMask, Tooltip, Typography } from '../atoms'

import { SMARTIMPORTSTATES } from '../../enums'
import Arrow from '../../pages/Signup/Arrow'

const AIImportLabel = ({ state, children }) => {
  const theme = useTheme()

  if (state === SMARTIMPORTSTATES.PENDING) {
    return (
      <ElementTooltip
        portal
        tooltip={
          <Typography variant="12 medium" i18n="smart:AnalysisProcess">
            Analysis process…
          </Typography>
        }
      >
        {children}
      </ElementTooltip>
    )
  }
  return (
    <>
      <ElementTooltipStyle />
      <Flex data-test={`aIImportLabel_${state}`}>
        <Tooltip
          portal
          enabled
          width="272px"
          type="analyzedByAI"
          portalClassName="aIImportTooltip"
          options={{
            placement: 'top',
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: ({ placement }) => {
                    if (placement === 'bottom') {
                      return [0, 4]
                    }
                    return [0, 12]
                  },
                },
              },
            ],
          }}
          tooltip={
            <Flex
              direction="column"
              align="flex-start"
              gap={theme.semantic.space['space-2x-small']}
            >
              <Typography i18n="smart:AnalyzedByAI" variant="14 medium" color="primary" />
              <Flex gap={theme.semantic.space['space-2x-small']}>
                <div>
                  <img src="/img/icons/icons16px/smart_import_big.svg" />
                </div>
                <Flex direction="column" align="flex-start">
                  <Typography
                    i18n="ThisContractWasImported"
                    variant="12"
                    color="primary"
                    space="normal"
                  />
                </Flex>
              </Flex>
            </Flex>
          }
        >
          {children}
        </Tooltip>
      </Flex>
    </>
  )
}

const ElementTooltipStyle = createGlobalStyle`
  .aIImportTooltip {
    ${SvgMask} {
      cursor: pointer;
    }
  }
`

export default AIImportLabel
