export default {
  en: {
    participants: 'Participants',
    signers: 'Signers',
    documentSet: 'Document set',
    viewers: 'Viewers',
    signersHint: 'Sign document sets with an e-signature',
    viewersHint: 'Can view the contract',
    signer: 'Signer',
    verifiedSigner: 'Verified signer',
    verifiedSignerHint: 'Sign document sets with an identity proof e-signature',
    viewer: 'Viewer',
    removeInvitation: 'Remove invitation',
    inviteParticipants: 'Invite participants',
  },
  de: {
    participants: 'Teilnehmer',
    signers: 'Unterzeichner',
    documentSet: 'Dokumentenpaket',
    viewers: 'Betrachter',
    signersHint: 'Unterzeichnen Dokumentenpakete mit E-Signatur',
    viewersHint: 'Können den Vertrag sehen',
    signer: 'Unterzeichner',
    verifiedSigner: 'Verifizierter Unterzeichner',
    verifiedSignerHint: 'Unterzeichnen Dokumentenpakete mit einer identitätsgeprüften E-Signatur',
    viewer: 'Betrachter',
    removeInvitation: 'Einladung entfernen',
    inviteParticipants: 'Teilnehmer einladen',
  },
  fr: {
    participants: 'Participants',
    signers: 'Signataires',
    documentSet: 'Ensemble de Documents',
    viewers: 'Spectateurs',
    signersHint: "Signer l'ensemble de documents avec une e-signature",
    viewersHint: 'Peut voir le contrat',
    signer: 'Signataire',
    verifiedSigner: 'Signataire verifié',
    verifiedSignerHint: "Signer l'ensemble de documents avec une identité prouver en e-signature",
    viewer: 'Spectateur',
    removeInvitation: "Supprimer l'invitation",
    inviteParticipants: 'Inviter participants',
  },
}
