import Signature from './Signature'
import Text from './Text'

export { default as Signature } from './Signature'
export { default as Text } from './Text'

export { default as SignatureModal } from './SignatureModal'
export { default as TextModal } from './TextModal'

export * from './const'

export const CustomFields = [
  { type: 'signature', factory: Signature, i18n: 'signup:signature', icon: 'signature' },
  { type: 'text', factory: Text, i18n: 'signup:text', icon: 'typing' },
]
