import { createAsyncThunk } from '@reduxjs/toolkit'
import ecies from 'eth-ecies'

import { randomKey } from '../../services/keyCreationService'
import { callApi } from '../../utils/callApi'
import { updateProofs } from '../../services/proofOutdated/updateProofs'

const createDigitalAsset = createAsyncThunk(
  'digitalassets/create',
  async ({ callback, title, workspaceId, workspaceFolderId, signatureType }, api) => {
    try {
      const address = localStorage.getItem('user_address')
      const userpass = localStorage.getItem('user_secret')
      const userPublicKey = Buffer.from(
        JSON.parse(localStorage.getItem('user_publicKey')).data,
        'hex',
      )

      const { workspaces } = api.getState()
      const { companyWorkspace } = workspaces
      const isCompanyWorkspaceAvailable = !!companyWorkspace?.attributes.publicKey
      const otherWorkspaceId = workspaceId || workspaceId === null
      const activeFolderId = otherWorkspaceId ? workspaceFolderId : workspaces.activeFolder
      const activeWorkspaceId = otherWorkspaceId ? workspaceId : workspaces.active

      let wsEncrDS = null
      const daSecret = randomKey()

      if (isCompanyWorkspaceAvailable) {
        const wsPublicKey = Buffer.from(companyWorkspace.attributes.publicKey, 'hex')
        wsEncrDS = ecies.encrypt(wsPublicKey, daSecret).toString('base64')
      }

      const encrDS = ecies.encrypt(userPublicKey, daSecret).toString('base64')
      const payload = {
        data: {
          type: 'digitalassets',
          attributes: {
            title,
            description: '',
            startDate: '',
            expiryDate: '',
            encryptionPayload: {
              owner: { encrDS },
              companyWorkspace: isCompanyWorkspaceAvailable ? { encrDS: wsEncrDS } : undefined,
            },
            ...(signatureType ? { signatureType } : {}),
          },
          relationships: {
            tags: {
              data: [],
            },
          },
        },
      }

      const digitalAsset = await callApi({
        endpoint: 'digitalassets',
        method: 'POST',
        applicationJson: true,
        body: payload,
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      })

      await new Promise((r) => {
        updateProofs({
          data: digitalAsset,
          address,
          userpass,
          callback: () => r(),
        })
      })

      if (activeWorkspaceId) {
        const {
          workspaces: { allWorkspaces },
        } = api.getState()
        const workspace = allWorkspaces[activeWorkspaceId]

        const wsPublicKey = Buffer.from(workspace.attributes.publicKey, 'hex')
        wsEncrDS = ecies.encrypt(wsPublicKey, daSecret).toString('base64')

        const sharePayload = {
          type: 'workspaceaccessgrants',
          attributes: {
            encrDS: wsEncrDS,
          },
          relationships: {
            digitalAsset: { data: { type: 'digitalassets', id: digitalAsset.data.id } },
            grantedTo: { data: { type: 'workspaces', id: activeWorkspaceId } },
            folder: { data: { type: 'folders', id: activeFolderId } },
          },
        }

        await callApi({
          endpoint: 'workspaceaccessgrants',
          method: 'POST',
          applicationJson: true,
          body: { data: sharePayload },
          headers: {
            'Content-Type': 'application/vnd.api+json',
          },
        })
      }

      api.extra.sendCallback(callback, [digitalAsset])
    } catch (e) {
      callback(null, e)
    }
  },
)

export default createDigitalAsset
