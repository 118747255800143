import styled from 'styled-components'

import { useMeasure } from 'react-use'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mobileEnabledSelector } from '../../store/selectors'
import { mobileEnabledSidebar } from '../../actions/digitalAssets'

const LeftContentGrid = ({ children, className }) => {
  const [ref, { width }] = useMeasure()
  const dispatch = useDispatch()

  const mobileEnabled = useSelector(mobileEnabledSelector)

  const bodyWidth = document.body.offsetWidth

  useEffect(() => {
    if (bodyWidth < 784 && width !== 0 && !mobileEnabled) {
      dispatch(mobileEnabledSidebar(true))
    }
    if (bodyWidth > 783 && mobileEnabled) {
      dispatch(mobileEnabledSidebar(false))
    }
  }, [bodyWidth, dispatch, mobileEnabled, width])

  const style = useMemo(() => {
    if (width < 416 && width !== 0) return { gridTemplateColumns: 'repeat(2, minmax(56px, 1fr))' }
    if (width < 562 && width > 415) return { gridTemplateColumns: 'repeat(4, minmax(56px, 1fr))' }
    if (width < 849 && width > 559) return { gridTemplateColumns: 'repeat(8, minmax(56px, 1fr))' }
    return { gridTemplateColumns: 'repeat(12, 56px)' }
  }, [width])

  return (
    <Container className={className}>
      <div>
        <div ref={ref}>
          <div style={{ ...style }}>
            <div>{children}</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default styled(LeftContentGrid)``

const Container = styled.div`
  display: flex;
  flex: auto;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  > div {
    display: flex;
    min-height: 100%;
    align-items: stretch;
    > div {
      flex-grow: 1;
      flex-shrink: 0;
      height: fit-content;
      border-right: ${({ theme }) => `1px solid ${theme.palette.divider}`};
      width: calc(100% - 1px);
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      padding: ${({ theme }) =>
        `${theme.semantic.space['space-x-large']} ${theme.semantic.space['space-medium']}`};
      > div {
        flex: auto;
        width: 100%;
        margin-right: -1px;
        padding-right: 1px;
        grid-column-gap: 16px;
        display: inline-grid;
        justify-content: center;
        min-height: 100%;
        > div {
          display: flex;
          flex-direction: column;
          grid-column: 1 / -1;
          align-items: stretch;
        }
      }
    }
  }
`
