import { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom'
import { Persona, SvgMask } from '../../atoms'
import { PersonaDetails } from '../../molecules'
import ProfileMenu from './ProfileMenu'

import FooterInformation from './FooterInformation'
import SidebarContentSelector from './SidebarContentSelector'
import {
  companySelector,
  identVerifiedSelector,
  navigationEnabledSelector,
  selectCurrentUser,
} from '../../../store/selectors'
import MenuItem from './MenuItem'
import { enableNavigation } from '../../../actions/application'

const WIDTH = 272

const Sidebar = ({ disabled, className, ...props }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()

  const [menuVisible, setMenuVisible] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [selectorState, setSelectorState] = useState({})

  const currentUser = useSelector(selectCurrentUser)
  const company = useSelector(companySelector)
  const identVerified = useSelector(identVerifiedSelector)
  const navigationEnabled = useSelector(navigationEnabledSelector)

  const { firstName = '', lastName = '', emailAddress } = currentUser || {}
  const companyName = company?.attributes?.name

  const onSelectorChange = useCallback((state) => setSelectorState(state), [])

  useEffect(() => {
    dispatch(enableNavigation(false))
  }, [dispatch, location])

  const media = useMemo(() => {
    if (
      location.pathname?.indexOf('contract-new/') !== -1 ||
      location.pathname?.indexOf('contract-details/') !== -1
    ) {
      return '1055px'
    }
    if (
      location.pathname?.indexOf('workspaces/') !== -1 ||
      location.pathname?.indexOf('my-contracts') !== -1 ||
      location.pathname?.indexOf('archive/') !== -1
    ) {
      return '880px'
    }
    return '591px'
  }, [location.pathname])

  if (disabled) {
    return null
  }

  return (
    <>
      <Animation
        className={className}
        {...props}
        id="sidebar"
        navigationEnabled={navigationEnabled}
        media={media}
      >
        <Container identVerified={identVerified} media={media}>
          <SidebarContentSelector
            onChange={onSelectorChange}
            data-test="sidebar_sidebarContentSelector"
          />
          <SidebarFooter>
            <FooterInformation />
            <div ref={setReferenceElement} data-test="button_sidebar_profile_menu">
              <SidebarPersonaDetails
                isActive={() => menuVisible}
                onClick={() => setMenuVisible(!menuVisible)}
                subLabel={companyName && [companyName]}
                value={`${firstName} ${lastName}`}
                icon={
                  <Persona
                    size="medium"
                    firstName={firstName}
                    lastName={lastName}
                    seed={emailAddress}
                    verified={identVerified}
                  />
                }
              >
                <SvgMask
                  icon="chevron-small-up-chevron-small-down"
                  color={theme.semantic.palette.icon['icon-secondary']}
                />
              </SidebarPersonaDetails>
            </div>
            <ProfileMenu
              visible={menuVisible}
              target={referenceElement}
              onClose={() => setMenuVisible(false)}
              onLogOut={() => setMenuVisible(false)}
            />
          </SidebarFooter>
        </Container>
      </Animation>
      <Overlay
        navigationEnabled={navigationEnabled}
        onClick={() => dispatch(enableNavigation(false))}
        media={media}
      />
    </>
  )
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  background: ${({ theme }) => theme.colors['neutral-06']};
  opacity: 0.3;
  display: none;

  @media (max-width: ${({ media }) => media}) {
    display: ${({ navigationEnabled }) => (navigationEnabled ? 'block' : 'none')};
  }
`

const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.semantic.space['space-small']};
`

const SidebarPersonaDetails = styled(MenuItem)`
  z-index: 21;
  position: relative;
`

const Container = styled.div`
  width: ${WIDTH}px;
  min-width: ${WIDTH}px;
  overflow: hidden;
  background: ${({ theme }) => theme.semantic.palette.surface['surface-secondary']};
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 16px;
  position: relative;
  grid-auto-rows: ${({ identVerified }) => (identVerified ? '40px auto auto' : '40px auto 80px')};

  &:after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: ${({ theme }) => theme.semantic.palette.border.border};
    content: '';
  }
  > div {
    grid-column: 1 / -1;
  }

  ${ProfileMenu} {
    ${PersonaDetails} {
      cursor: default;
    }
  }

  @media (max-width: ${({ media }) => media}) {
    grid-auto-rows: ${({ identVerified }) =>
      identVerified ? '40px 40px auto auto' : '40px 40px auto 80px'};
  }
`

const Animation = styled.div`
  display: flex;
  align-content: stretch;
  justify-content: stretch;

  @media (max-width: ${({ media }) => media}) {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1001;
    left: ${({ navigationEnabled }) => (navigationEnabled ? 0 : `-${WIDTH}px`)};
    transition: left 250ms ease-out;

    ${Container} {
      grid-auto-rows: ${({ identVerified }) =>
        identVerified ? '40px 40px auto auto' : '40px 40px auto 80px'};
    }
  }
`

export default styled(Sidebar)``
