import React from 'react'
import styled, { useTheme } from 'styled-components'
import Typography from '../Typography'
import Spacing from '../Spacing'
import SvgMask from '../SvgMask'

const DEFAULT_VARIANT = 'body 14px regular'

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: ${({ $width }) => $width}px;

  > div:first-child {
    display: flex;
    flex-direction: column;
  }
`

const renderContent = ({
  children,
  value,
  i18n,
  variant,
  descriptionVisible,
  description,
  descriptionI18n,
}) => {
  if (children) {
    return children
  }

  return (
    <div>
      <Typography i18n={i18n} variant={variant || DEFAULT_VARIANT}>
        {value}
      </Typography>
      {descriptionVisible && (
        <Typography i18n={descriptionI18n} variant="10 regular">
          {description}
        </Typography>
      )}
    </div>
  )
}

const ModalItem = ({
  children,
  icon,
  iconAfter,
  iconColor,
  variant,
  i18n,
  value,
  selected,
  secondary,
  description,
  descriptionI18n,
  width,
  disabled,
  ...props
}) => {
  const { palette } = useTheme()

  const descriptionVisible = description || descriptionI18n

  return (
    <div {...props} disabled={disabled}>
      {icon && (
        <>
          <SvgMask icon={icon} />
          <Spacing size={12} />
        </>
      )}
      <LabelContainer $width={width || 184}>
        {renderContent({
          children,
          value,
          i18n,
          variant,
          descriptionVisible,
          description,
          descriptionI18n,
        })}
        {selected && (
          <>
            <Spacing size={8} />
            <SvgMask
              icon="checkmark"
              data-test="image_modal-item_check"
              color={palette.accent.success}
            />
          </>
        )}
      </LabelContainer>
      {iconAfter && (
        <>
          <Spacing size={12} />
          <SvgMask icon={iconAfter} />
        </>
      )}
    </div>
  )
}

const getColor = ({ theme, secondary, selected, iconColor }, target) => {
  const { palette } = theme

  if (iconColor && target === 'icon') {
    return iconColor
  }

  if (selected) {
    return palette.theme.primary
  }

  if (secondary) {
    return palette.type.secondary
  }

  return palette.type.primary
}

export default styled(ModalItem)`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 6px 8px;
  border-radius: 4px;
  text-decoration: none;
  box-sizing: border-box;

  ${Typography} {
    color: ${(props) => getColor(props)};
    color: ${({ theme, disabled }) => disabled && theme.palette.type.tertiary};
  }
  ${SvgMask} {
    background-color: ${(props) => getColor(props, 'icon')};
  }

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.palette.divider};
    color: ${({ theme, disabled }) => !disabled && theme.palette.theme.primary};

    ${Typography} {
      color: ${({ theme, disabled }) => !disabled && theme.palette.theme.primary};
    }

    ${SvgMask} {
      background-color: ${({ theme, iconColor }) => iconColor || theme.palette.theme.primary};
    }
  }
`
