export default {
  en: {
    CustomFields: 'Custom fields',
    SearchCustomFields: 'Search custom fields…',
    NoCustomFields: 'No custom fields',
    RemoveCustomField: 'Remove “{{title}}” custom field?',
    AreYouSure: 'Are you sure? The data entered in the custom field will be lost.',
    Remove: 'Remove',
    AddField: 'Add field',
    addedFields: '{{count}} added fields',
    CreateCustomField: 'Create custom field',
    FieldType: 'Field type',
    SingleLineText: 'Single line text',
    SingleLineTextDesc: 'A single line of text.',
    SingleSelect: 'Single select',
    SingleSelectDesc: 'List of predefined options where only one can be selected.',
    Name: 'Name',
    FieldName: 'Field name',
    RequiredField: 'Required field.',
    Description: 'Description',
    DescribeThisField: 'Describe this field, use case, …',
    FieldProperties: 'Field properties',
    Required: 'Required',
    MakeTheFieldRequired:
      'Make the field required. If a required field is empty, the contract cannot be saved or updated.',
    Languages: 'Languages',
    SetUpTranslations: 'Set up translations for the field.',
    Manage: 'Manage',
    CustomFieldHasBeenCreated: 'Custom field has been created',
    EditCustomField: 'Edit custom field',
    CreateAnotherField: 'Create another field',
    CustomFieldHasBeenUpdated: 'Custom field has been updated',
    ManageLanguages: 'Manage languages',
    Language: 'Language',
    CustomFieldHasBeenDeleted: 'Custom field has been deleted',
    edit: 'Edit',
    Options: 'Options',
    AZ: 'A-Z',
    ZA: 'Z-A',
    MinimumOption: 'Minimum of 1 option is required.',
    EnterOptionsOne: 'Enter options one at a time or write multiple options on separate lines.',
    LongText: 'Long text',
    LongTextDescription: 'A long text field that can span multiple lines.',
    Date: 'Date',
    DateDescription: 'Date selected from a date picker.',
    Number: 'Number',
    NumberDescription: 'Numerical data.',
    AddedLanguages: 'Added languages',
    CFDPageDescription:
      'Use custom fields to add more contract metadata. These custom fields will be added to all company contracts',
    type: 'TYPE',
    TheDescription: 'The description will be displayed next to the field name.',
    DeleteDate: 'Delete date?',
    DeleteDateDescription:
      'Are you sure? This action will delete the linked event. You cannot undo this action.',
  },
  de: {
    CustomFields: 'Benutzerdefinierte Felder',
    SearchCustomFields: 'Benutzerdefinierte Felder durchsuchen…',
    NoCustomFields: 'Keine benutzerdefinierten Felder',
    RemoveCustomField: 'Benutzerdefiniertes Feld “{{title}}” löschen?',
    AreYouSure:
      'Bist du dir sicher? Die in das benutzerdefinierte Feld eingegebenen Daten gehen verloren.',
    Remove: 'Löschen',
    AddField: 'Feld hinzufügen',
    addedFields: '{{count}} Felder hinzugefügt',
    CreateCustomField: 'Benutzerdefiniertes Feld erstellen',
    FieldType: 'Feldtyp',
    SingleLineText: 'Einzeiliger Text',
    SingleLineTextDesc: 'Eine einzelne Textzeile.',
    SingleSelect: 'Einfachauswahl',
    SingleSelectDesc:
      'Liste mit vordefinierten Optionen, von denen nur eine ausgewählt werden kann.',
    Name: 'Name',
    FieldName: 'Feldname',
    RequiredField: 'Erforderliches Feld.',
    Description: 'Beschreibung',
    DescribeThisField: 'Beschreibe dieses Feld, Anwendungsfall, …',
    FieldProperties: 'Feldeigenschaften',
    Required: 'Erforderlich',
    MakeTheFieldRequired:
      'Setze das Feld als erforderlich. Wenn ein Pflichtfeld leer ist, kann der Vertrag nicht gespeichert oder aktualisiert werden.',
    Languages: 'Sprachen',
    SetUpTranslations: 'Richte Übersetzungen für das Feld ein.',
    Manage: 'Verwalten',
    CustomFieldHasBeenCreated: 'Benutzerdefiniertes Feld wurde erstellt',
    EditCustomField: 'Benutzerdefiniertes Feld bearbeiten',
    CreateAnotherField: 'Weiteres Feld erstellen',
    CustomFieldHasBeenUpdated: 'Benutzerdefiniertes Feld wurde aktualisiert',
    ManageLanguages: 'Sprachen verwalten',
    Language: 'Sprachen',
    CustomFieldHasBeenDeleted: 'Benutzerdefiniertes Feld wurde gelöscht',
    edit: 'Bearbeiten',
    Options: 'Optionen',
    AZ: 'A-Z',
    ZA: 'Z-A',
    MinimumOption: 'Es ist Mindestens eine option erforderlich.',
    EnterOptionsOne:
      'Gebe die Optionen einzeln ein oder schreibe mehrere Optionen in separate Zeilen.',
    LongText: 'Langer Text',
    LongTextDescription: 'Ein Langtextfeld, das sich über mehrere Zeilen erstrecken kann.',
    Date: 'Datum',
    DateDescription: 'Datum, das aus einer Kalenderauswahl ausgewählt wurde.',
    Number: 'Zahl',
    NumberDescription: 'Zahlenangaben.',
    AddedLanguages: 'Sprachen hinzugefügt',
    CFDPageDescription:
      'Verwenden Sie benutzerdefinierte Felder, um weitere Vertrags-Metadaten hinzuzufügen. Diese benutzerdefinierten Felder werden zu allen Unternehmensverträgen hinzugefügt.',
    type: 'TYP',
    TheDescription: 'Die Beschreibung wird neben dem Feldnamen angezeigt.',
    DeleteDate: 'Datum löschen?',
    DeleteDateDescription:
      'Bist du dir sicher? Dadurch wird der verknüpfte Termin gelöscht. Du kannst diese Aktion nicht rückgängig machen.',
  },
  fr: {
    CustomFields: 'Champs personnalisés',
    SearchCustomFields: 'Recherche de champs personnalisés…',
    NoCustomFields: 'Pas de champs personnalisés',
    RemoveCustomField: 'Supprimer “{{title}}” un champ personnalisé?',
    AreYouSure: 'Êtes-vous sûr? Les données saisies dans le champ personnalisé seront perdues.',
    Remove: 'Supprimer',
    AddField: 'Ajouter un champ',
    addedFields: '{{count}} champs ajoutés',
    CreateCustomField: 'Créer un champ personnalisé',
    FieldType: 'Type de champ',
    SingleLineText: "Texte d'une seule ligne",
    SingleLineTextDesc: 'Une seule ligne de texte.',
    SingleSelect: 'Sélection unique',
    SingleSelectDesc: "Liste d'options prédéfines dont une seule peut être sélectionnée.",
    Name: 'Nom',
    FieldName: 'Nom du champ',
    RequiredField: 'Champ obligatoire.',
    Description: 'Description',
    DescribeThisField: "Décrire ce champ, cas d'utilisation, …",
    FieldProperties: 'Propriétés du champ',
    Required: 'Exigée',
    MakeTheFieldRequired:
      'Rendre le champ obligatoire. Si un champ obligatoire est vide, le contrat ne peut pas être enregistré ou mis à jour.',
    Languages: 'Langues',
    SetUpTranslations: 'Établir une traduction pour ce champ.',
    Manage: 'Gérer',
    CustomFieldHasBeenCreated: 'Le champ personnalisé a été créé',
    EditCustomField: 'Éditer un champ personnalisé',
    CreateAnotherField: 'Créer un autre champ',
    CustomFieldHasBeenUpdated: 'Le champ informatique a été mis à jour',
    ManageLanguages: 'Gérer les langues',
    Language: 'Langue',
    CustomFieldHasBeenDeleted: 'Le champ informatique a été supprimé',
    edit: 'Éditer',
    Options: 'Options',
    AZ: 'A-Z',
    ZA: 'Z-A',
    MinimumOption: "Un minimum d'une option est requis.",
    EnterOptionsOne:
      'Saisir les options les unes à la fois ou écrire plusieurs options sur des lignes séparées.',
    LongText: 'Texte long',
    LongTextDescription: "Un champ de texte long qui peut s'étendre sur plusieurs lignes.",
    Date: 'Date',
    DateDescription: "Date sélectionnée à partir d'un sélecteur de date.",
    Number: 'Nombre',
    NumberDescription: 'Données numériques.',
    AddedLanguages: 'Langue ajoutée',
    CFDPageDescription:
      'Utilisez les champs personnalisés pour ajouter plus de métadonnées sur les contrats. Ces champs personnalisés seront ajoutés à tous les contrats denterprise.',
    type: 'TYPE',
    TheDescription: "La description s'affiche à côté du nom du champ.",
    DeleteDate: 'Supprimer la date?',
    DeleteDateDescription:
      "Êtes-vouz sûr? Cette action supprimera l'événement lié. Vous ne pouvez pas annuler cette action.",
  },
}
