import React from 'react'
import styled from 'styled-components'
import Color from 'color'

import { Button, Flex, Spacing, SvgMask, Typography } from '../atoms'

const MessageBar = ({ icon, color, i18n, onOkClick, variant, ...props }) => {
  return (
    <Flex content="start" {...props}>
      <SvgMask icon={icon} color={color} />
      <Spacing size="8" />
      <Typography i18n={i18n} variant={variant || '13'} />
      {typeof onOkClick === 'function' ? (
        <>
          <Spacing size="20" />
          <Button tertiary i18n="Ok" width="fit-content" onClick={onOkClick} />
        </>
      ) : undefined}
    </Flex>
  )
}

export default styled(MessageBar)`
  background-color: ${({ color }) => Color(color).alpha(0.2)};
  padding: 7px 24px;
  width: ${({ width }) => width || ''};
  box-sizing: border-box;

  ${SvgMask} {
    background-color: ${({ color }) => Color(color).alpha(1)};
  }

  ${Button} {
    margin-left: auto;
    height: auto;
    min-height: auto;

    &:hover {
      background-color: transparent;
    }
  }
`
