import { createReducer } from '@reduxjs/toolkit'

import { getCompanies } from '../actions/admin'

const initialState = { companies: [] }

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(getCompanies.fulfilled, (state, action) => {
    return {
      ...state,
      companies: [...action.payload.companies],
    }
  })
})

export default reducer
