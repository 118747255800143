import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import dispatchThen from '../store/dispatchThen'

// * Hook to control redux action dispatch with async/await
const useDispatchAsync = () => {
  const dispatch = useDispatch()

  const hook = useCallback(
    (action, payload = {}) => {
      if (!action.typePrefix) {
        if (typeof action === 'object' && action.type && action.payload) {
          // looks like this is generic sync action
          return dispatch(action)
        }

        // * something wrong with the action
        // likelly you have passed the action as default redux action
        // as single function argument
        // ie: dispatch(action(payload)) instead of
        // * await dispatch(action, payload)

        throw new Error('Unexpected error with async redux action')
      }
      return dispatchThen(dispatch, action, payload)
    },
    [dispatch],
  )

  return hook
}

export default useDispatchAsync
