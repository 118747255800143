import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import defaultTheme from '../../../theme'
import { semantic } from '../../../theme/semantic'

const variants = defaultTheme.typography

const DEFAULT_PROPS = {}

const Typography = ({
  i18n,
  i18nProps = DEFAULT_PROPS,
  variant = '',
  children,
  color,
  nowrap,
  ...props
}) => {
  const { t } = useTranslation()

  const usedVariants = variant.toLowerCase().split(' ')
  if (usedVariants.filter((v) => !variants[v]).length > 0) {
    throw new TypeError(`Invalid typography variant: ${variant}`)
  }

  const renderValue = i18n ? t(i18n, i18nProps) : children

  return <span {...props}>{renderValue || children}</span>
}

Typography.propTypes = {
  color: PropTypes.string,
  i18n: PropTypes.any,
  variant: PropTypes.string,
  nowrap: PropTypes.bool,
}

const getColor = (theme, color) => {
  if (!color || color === 'primary') {
    return theme.semantic.palette.text.text
  }

  if (color === 'secondary') {
    return theme.semantic.palette.text['text-secondary']
  }

  if (color === 'tertiary') {
    return theme.palette.type.tertiary
  }

  if (color === 'white') {
    return theme.palette.background.white
  }

  if (color === 'brand') {
    return theme.semantic.palette.text['text-brand']
  }

  if (color === 'warning') {
    return theme.palette.accent.warning
  }

  if (color === 'disabled') {
    return theme.semantic.palette.text['text-disabled']
  }

  if (color) {
    return color
  }

  return theme.palette.type.primary
}

export default styled(Typography)`
  font-family:
    Inter,
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme, color }) => getColor(theme, color)};
  cursor: ${({ cursor }) => cursor || 'inherit'};
  white-space: ${({ space }) => space || undefined};
  display: inline-block;
  word-break: ${({ word }) => word || undefined};
  -webkit-font-smoothing: antialiased;
  text-align: ${({ align }) => align || undefined};
  
  ${({ nowrap }) =>
    nowrap &&
    `text-overflow: ellipsis;
     white-space: nowrap;
      overflow: hidden;`}}

  &:first-letter {
    text-transform: ${({ firstletter }) => firstletter || undefined};
  }

  ${({ variant = '', theme }) =>
    variant
      .split(' ')
      .map((v) => theme.typography[v])
      .join('\n')}
`
