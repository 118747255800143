import { createReducer } from '@reduxjs/toolkit'
import { loadInitialReducerState } from '../store/persist'
import { changeFilters } from '../actions/filters'

const initialState = {
  data: {},
  ...loadInitialReducerState('filters', 'inhubber-redux'),
}
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(changeFilters.fulfilled, (state, action) => {
    const { attributes, workspaceId } = action.payload
    return {
      ...state,
      data: { ...state.data, [workspaceId]: { ...attributes } },
    }
  })
})

export default reducer
