import { callApiPagination } from '../../../utils/callApi'
import { setFieldsDefinitions } from '../../../actions/customFields'
import store from '../../../store'
import { updateIncluded } from '../../../actions/included'

const getCustomFieldsDefinitions = async (companyId) => {
  const response = await callApiPagination({
    endpoint: `companies/${companyId}/customFieldDefinitions`,
  })

  const { data: customFieldDefinitions = [] } = response

  store.dispatch(setFieldsDefinitions(customFieldDefinitions))
  store.dispatch(updateIncluded(customFieldDefinitions))
}

export default getCustomFieldsDefinitions
