import styled, { useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import Flex from './Flex'
import Typography from './Typography'
import SvgMask from './SvgMask'

const RadioButton = ({
  label,
  labelI18n,
  value,
  onChange,
  className,
  checked,
  name,
  alternative,
  ...props
}) => {
  const theme = useTheme()

  const onInnerChange = useCallback(
    (e) => {
      if (typeof onChange === 'function') {
        onChange(e, value)
      }
    },
    [onChange, value],
  )

  return (
    <Flex className={className} data-test={`radio_${name}_${value}`}>
      <InputContainer>
        <input
          type="radio"
          value={value}
          onChange={onInnerChange}
          id={value}
          checked={checked}
          data-test={`input_radio_${checked ? 'checked' : 'unchecked'}`}
          name={name}
          {...props}
        />
        {checked && alternative && (
          <SvgMask
            size={12}
            color={theme.component.radioButton.icon['radio-button-color-icon-checked']}
            icon="checkmark"
          />
        )}
        {checked && !alternative && <CheckIcon />}
      </InputContainer>
      {(labelI18n || label) && (
        <label htmlFor={value}>
          <Typography i18n={labelI18n} variant="body-medium-02">
            {label}
          </Typography>
        </label>
      )}
    </Flex>
  )
}

const CheckIcon = styled.div`
  position: absolute;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  top: 6px;
  left: 6px;
  background: ${({ theme }) => theme.colors['neutral-01']};
`

const InputContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  ${SvgMask} {
    position: absolute;
    top: 2px;
    left: 2px;
  }
`

RadioButton.propTypes = {
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  labelI18n: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default styled(RadioButton)`
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
  label {
    display: flex;
  }
  &:hover {
    input:checked {
      background-color: ${({ disabled, theme }) =>
        !disabled &&
        theme.component.radioButton.surface['radio-button-color-surface-checked-hover']};
    }
  }

  input {
    margin: 0;
    width: 16px;
    height: 16px;
    border: 1px solid
      ${({ theme }) =>
        theme.component.radioButton.border['radio-button-color-color-border-uncheked']};
    border-radius: ${({ theme }) => theme.radius['radio-button-border-radius']};
    appearance: none;
    &:checked {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: ${({ theme }) =>
        theme.component.radioButton.surface['radio-button-color-surface-checked']};
    }
    &:enabled {
      &:hover {
        border: 1px solid
          ${({ theme }) =>
            theme.component.radioButton.border['radio-button-color-border-uncheked-hover']};
      }
      &:checked {
        border: none;
      }
    }
    &:disabled {
      border: 1px solid
        ${({ theme }) =>
          theme.component.radioButton.border['radio-button-color-border-uncheked-disabled']};
      background-color: ${({ theme }) =>
        theme.component.radioButton.surface['radio-button-color-surface-unchecked-disabled']};
      &:checked {
        border: none;
        background-color: ${({ theme }) =>
          theme.component.radioButton.surface['radio-button-color-surface-checked-disabled']};
      }
    }
  }
`
