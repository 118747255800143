import styled from 'styled-components'

const Toolbar = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.semantic.space['space-small']};
  padding: ${({ theme }) =>
    `${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-medium']}`};
  justify-content: ${({ content }) => content || 'space-between'};
  border-bottom: 1px solid ${({ theme }) => theme.semantic.palette.border.border};
  box-sizing: border-box;
  background: ${({ theme }) => theme.semantic.palette.surface.surface};
`

export default Toolbar
