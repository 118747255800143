function proofOutdated(json) {
  const Outdated = []
  if (!json) {
    return Outdated
  }
  const sort = (newJson) => {
    if (Array.isArray(newJson.data)) {
      newJson.data.map((item) => sort(item))
    } else {
      const obj = newJson.data || newJson
      const { id } = obj
      const { type } = obj
      if (obj.attributes !== undefined) {
        const { attributes } = obj
        const { proofOutdated: newProofOutdated } = attributes
        const { hash } = attributes

        if (newProofOutdated) {
          Outdated.push({ id, type, hash })
        }
      }

      if (obj.meta !== undefined) {
        const { meta } = obj
        if (meta.affectedModels !== undefined && Array.isArray(meta.affectedModels)) {
          meta.affectedModels.forEach((affectedModel) => Outdated.push(affectedModel))
        }
      }
    }
  }

  if (Array.isArray(json)) {
    json.map((item) => sort(item))
  } else {
    sort(json)
  }

  return Outdated
}

export default proofOutdated
