export default {
  Required: 'Required field',
  WrongCredentials: 'Wrong credentials',
  WrongPassword: 'Wrong password',
  DuringRegistration: 'Unexpected error occurred. Please contact our support: support@inhubber.com',
  AlreadyRegistered: 'User with this email address already registered.',
  RegistrationNotPossible: 'Registration not possible',
  alreadyInvited: 'Person with this email address is already invited',
  RightNow:
    'Right now, this contract is being signed by another person. Please try again in one minute.',
  MaxFileSize: 'Max file size 50 MB',
  UserWith: 'User with this e-mail is already registered',
  PleaseConfirm: 'Please confirm your account using activation link first',
  PleaseUse: 'please use activation link in the invitation e-mail',
  UnexpectedError: 'An unexpected error occurred. Please try again later',
  ConnectionError:
    'Could not connect to the service. Please ensure that you are connected to the internet and that your firewall permits all the required connections',
  FirstName: 'First name',
  Surname: 'Last name',
  RepeatPassword: 'Repeat password',
  wrongBrowser:
    'At the moment our application is only available for Mozilla Firefox, latest Microsoft Edge and Google Chrome browsers. We ask for your understanding.',
  enter_valid: 'Invited person email',
  admin: 'Admin',
  InvalidSecurityKey: 'Invalid Security Key',
  search: 'Search',
  Select_date: 'Select a date',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
  Today: 'Today',
  New_Contract: 'New Contract',
  Document: 'Document',
  InvalidDateFormat: 'Invalid date format',
  existsAccountType: 'The selected account type exists for the user',
  yourSession: 'Your session has expired. Please log in again',
  DraftSaved: 'All changes will be saved automatically',
  ContractCreated: 'Contract successfully created',
  dateOfBirth: 'Date of birth',
  placeOfBirth: 'Place of birth',
}
