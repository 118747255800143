import styled, { useTheme } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'

import { Button, Flex, ScrollContainer } from '../../atoms'
import DefaultSidebarPage from './DefaultSidebarPage'
import CompanySettingsSidebarPage from './CompanySettingsSidebarPage'
import PlaygroundSidebarPage from './PlaygroundSidebarPage'
import Search from './Search'
import getDefaultRoute from '../../../router/getDefaultRoute'
import { selectUserType } from '../../../store/selectors'
import MenuItem from './MenuItem'
import { enableNavigation } from '../../../actions/application'
import Help from './Help'

const SidebarContentSelector = ({ onChange, className }) => {
  const location = useLocation()
  const [searchVisible, setSearchVisible] = useState(false)
  const dispatch = useDispatch()

  const state = useMemo(() => {
    if (location.pathname.indexOf('company/settings') !== -1) {
      return { content: <CompanySettingsSidebarPage />, searchEnabled: false }
    }
    // or we can use location.pathname === '/playground'
    if (/^\/playground(\/|$)/.test(location.pathname)) {
      return { content: <PlaygroundSidebarPage />, searchEnabled: false }
    }
    return { content: <DefaultSidebarPage />, searchEnabled: true }
  }, [location.pathname])
  const { content } = state

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [onChange, state])

  const userType = useSelector(selectUserType)

  const media = useMemo(() => {
    if (
      location.pathname?.indexOf('contract-new/') !== -1 ||
      location.pathname?.indexOf('contract-details/') !== -1
    ) {
      return '1055px'
    }
    if (
      location.pathname?.indexOf('workspaces/') !== -1 ||
      location.pathname?.indexOf('my-contracts') !== -1 ||
      location.pathname?.indexOf('archive/') !== -1
    ) {
      return '880px'
    }
    return '591px'
  }, [location.pathname])

  return (
    <>
      <SidebarAction media={media}>
        <Button type="icon" icon="sidebar-left" onClick={() => dispatch(enableNavigation(false))} />
      </SidebarAction>
      <SidebarLogo>
        <Flex content="space-between">
          <Link style={{ display: 'flex' }} to={getDefaultRoute(userType)} data-test="img_sidebar">
            <Logotype />
          </Link>
          <Help />
        </Flex>
      </SidebarLogo>
      <ScrollContainer>{content}</ScrollContainer>

      <Search visible={searchVisible} onClose={() => setSearchVisible(false)} />
    </>
  )
}

const SidebarLogo = styled.div`
  align-self: baseline;
  padding: ${({ theme }) =>
    `${theme.semantic.space['space-small']} ${theme.semantic.space['space-small']} 0 ${theme.semantic.space['space-small']}`};
`

const Logotype = () => {
  const theme = useTheme()

  const fill = theme.semantic.palette.icon['icon-brand']

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.2429 6.44816C23.5944 6.09669 23.5944 5.52684 23.2429 5.17537L19.4246 1.357C19.0731 1.00552 18.5032 1.00552 18.1518 1.35699L12.6363 6.87243C12.2849 7.2239 12.2849 7.79375 12.6363 8.14522L15.5547 11.0636C15.7235 11.2324 15.9524 11.3272 16.1911 11.3272L17.9911 11.3272C18.2298 11.3272 18.4587 11.2324 18.6275 11.0636L23.2429 6.44816Z"
        fill={fill}
      />
      <path
        d="M23.2429 17.5518C23.5944 17.9033 23.5944 18.4732 23.2429 18.8246L19.4246 22.643C19.0731 22.9945 18.5032 22.9945 18.1518 22.643L12.6363 17.1276C12.2849 16.7761 12.2849 16.2063 12.6363 15.8548L15.5547 12.9364C15.7235 12.7676 15.9524 12.6728 16.1911 12.6728L17.9911 12.6728C18.2298 12.6728 18.4587 12.7676 18.6275 12.9364L23.2429 17.5518Z"
        fill={fill}
      />
      <path
        d="M0.757123 17.5518C0.405652 17.9033 0.405652 18.4732 0.757124 18.8246L4.5755 22.643C4.92697 22.9945 5.49682 22.9945 5.84829 22.643L11.3637 17.1276C11.7152 16.7761 11.7152 16.2063 11.3637 15.8548L8.44535 12.9364C8.27657 12.7676 8.04765 12.6728 7.80895 12.6728L6.00895 12.6728C5.77026 12.6728 5.54134 12.7676 5.37256 12.9364L0.757123 17.5518Z"
        fill={fill}
      />
      <path
        d="M0.757123 6.44816C0.405652 6.09669 0.405652 5.52684 0.757124 5.17537L4.5755 1.357C4.92697 1.00552 5.49682 1.00552 5.84829 1.35699L11.3637 6.87243C11.7152 7.2239 11.7152 7.79375 11.3637 8.14522L8.44535 11.0636C8.27657 11.2324 8.04765 11.3272 7.80895 11.3272L6.00895 11.3272C5.77026 11.3272 5.54134 11.2324 5.37256 11.0636L0.757123 6.44816Z"
        fill={fill}
      />
    </svg>
  )
}

const SidebarAction = styled.div`
  padding: ${({ theme }) =>
    `${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-small']}`};
  display: none;

  @media (max-width: ${({ media }) => media}) {
    display: flex;
    align-items: center;
    align-self: stretch;
  }
`

export default styled(SidebarContentSelector)`
  ${MenuItem} {
    min-width: 256px;
  }
`
