import light, { post$ } from '@parity/light.js'
import { systemAddress } from '../utils/constans'
import { web3connect } from './createWeb3Connectin'
import { createParityConnection } from './provider'
import { printf } from '../hooks/printf'

class BlockChainService {
  constructor(props = {}) {
    this.createTransaction = this.createTransaction.bind(this)
    this.password = props.password
    this.address = props.address
    // this.web3 = web3connect();
  }

  createTransaction(...args) {
    const [value, resultfun, _proofOutdated, onConfirm] = args
    const { provider } = createParityConnection()

    light.setProvider(provider)
    web3connect((web3) => {
      if (!provider.isConnected || provider.isConnecting) {
        setTimeout(() => this.createTransaction(...args), 1000)
        // eslint-disable-next-line no-console
        printf(() => console.log('retry createTransaction after timeout'))

        return
      }

      post$(
        {
          from: this.address,
          to: systemAddress,
          data: web3.utils.toHex({ value }),
        },
        {
          passphrase: this.password,
        },
      ).subscribe((result) => {
        // eslint-disable-next-line no-console
        printf(() => console.log(result))

        if (result.sent) {
          resultfun(result, _proofOutdated)
        }

        if (result.confirmed && typeof onConfirm === 'function') {
          onConfirm(result, _proofOutdated)
        }
      })
    })
  }
}

export default BlockChainService
