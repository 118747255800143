import styled from 'styled-components'

import BaseInput from './Input/BaseInput'
import Flex from './Flex'

const ActionInput = ({ className, children, error, ...props }) => {
  return (
    <BaseActionInput $error={error} className={className}>
      <BaseInput {...props} />
      {children}
    </BaseActionInput>
  )
}

const BaseActionInput = styled(Flex)`
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid
    ${({ $error, theme }) => ($error ? theme.palette.accent.warning : theme.palette.divider)};

  :focus-within {
    border: 1px solid ${({ theme }) => theme.palette.theme.primary};
  }

  :hover {
    border: 1px solid
      ${({ $error, theme: { palette }, disabled }) => {
        if (disabled) {
          return palette.divider
        }
        return $error ? palette.accent.warning : palette.theme.primary
      }};
  }

  input {
    padding: 0 8px 0 12px;
    margin-bottom: 0;
    height: 38px;
  }

  input,
  input:hover,
  input:focus {
    border: none;
  }
`

export default ActionInput
