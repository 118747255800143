import styled from 'styled-components'

import MenuGroup from './MenuGroup'
import { MenuGroupStyle } from './MenuGroupStyle'
import Flex from '../Flex'
import TextInput from '../Input/TextInput'
import Elevation from '../Elevation'

const ActionList = ({ data, children, width, content, action, className }) => {
  if (data.length === 0) {
    return <Flex className={className}>{children}</Flex>
  }

  return (
    <div className={className}>
      {children && <MenuGroupStyle>{children}</MenuGroupStyle>}
      <Flex direction="column" align="flex-start" self="stretch">
        {content && <MenuGroupStyle>{content}</MenuGroupStyle>}
        {data.map((group) => (
          <MenuGroup key={group.id} data={group} width={width} />
        ))}
        {action && <MenuGroupStyle>{action}</MenuGroupStyle>}
      </Flex>
    </div>
  )
}
export default styled(ActionList)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: ${({ width }) => width};
  overflow: auto;
  height: ${({ height }) => height};

  ${TextInput} {
    border: none;
  }
`
