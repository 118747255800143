import styled, { useTheme } from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, Persona, RecognizeLinks, SvgMask, TextArea, Typography } from '../atoms'
import ActivityDate from './Activity/ActivityDate'
import PersonaTooltip from './PersonaTooltip'

export const Comment = ({
  person,
  dataId,
  dateObj,
  content,
  remove,
  note,
  update,
  documents,
  className,
}) => {
  const messageBlock = useRef()
  const theme = useTheme()

  const [textareaVisible, setTextareaVisible] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!messageBlock?.current) {
      return undefined
    }
    const turnOffHandler = (e) => {
      if (!messageBlock.current.contains(e.target)) {
        if (value === '') setTextareaVisible(false)
      } else {
        const button = messageBlock.current.getElementsByTagName('button')[0]
        if (!button.contains(e.target)) {
          const textarea = messageBlock.current.getElementsByTagName('textarea')[0]
          textarea.focus()
        }
      }
    }

    if (textareaVisible) {
      window.addEventListener('mouseup', turnOffHandler)
    }

    return () => {
      window.removeEventListener('mouseup', turnOffHandler)
    }
  }, [textareaVisible, value])

  return (
    <Flex
      align="flex-start"
      width="100%"
      className={className}
      gap={theme.semantic.space['space-2x-small']}
    >
      {person?.attributes && (
        <PersonaTooltip
          firstName={person.attributes.firstName}
          lastName={person.attributes.lastName}
          size="medium"
          labels={[person.attributes.emailAddress]}
          seed={person.attributes.emailAddress}
          width="fit-content"
          emailAddress={person.attributes.emailAddress}
          placement="bottom-start"
          strategy="fixed"
        />
      )}
      {textareaVisible ? (
        <MessageContainer
          $textareaVisible={textareaVisible}
          direction="column"
          align="flex-start"
          ref={messageBlock}
        >
          <TextArea
            autoFocus
            value={value}
            onChange={(e) => setValue(e.target.value)}
            withoutBorder
          />
          <Flex content="space-between" width="100%">
            <Flex>
              <Button icon="paperclip" type="icon" />
            </Flex>
            <Flex gap={theme.semantic.space['space-2x-small']}>
              <Button
                type="secondary"
                i18n="cancel"
                onClick={() => {
                  setTextareaVisible(false)
                }}
              />
              <Button i18n="Save" />
            </Flex>
          </Flex>
        </MessageContainer>
      ) : (
        <MessageContainer direction="column" align="flex-start" data-id={dataId}>
          <Flex gap={theme.semantic.space['space-2x-small']}>
            {typeof note === 'function' && (
              <SvgMask color={theme.semantic.palette.icon['icon-tertiary']} icon="note-checkmark" />
            )}
            <Flex>
              <Typography variant="body-large-01">
                {person?.attributes?.firstName} {person?.attributes?.lastName}
              </Typography>
              <Typography variant="body-large-02" color="secondary">
                ・
              </Typography>
              <ActivityDate
                date={dateObj}
                variant="body-large-02"
                color={theme.semantic.palette.text['text-secondary']}
                inline
              />
            </Flex>
          </Flex>
          <RecognizeLinks
            title={content}
            variant="body-large-02"
            onClick={() => {
              if (typeof update === 'function') setTextareaVisible(true)
            }}
          />
          {documents && documents}
          {typeof remove === 'function' && <RemoveButton icon="trash" type="icon" />}
        </MessageContainer>
      )}
    </Flex>
  )
}

const RemoveButton = styled(Button)`
  position: absolute;
  right: 8px;
  top: 8px;
`

const MessageContainer = styled(Flex)`
  padding: ${({ theme }) => theme.semantic.space['space-small']};
  border-radius: ${({ theme }) => theme.semantic.space['space-4x-small']};
  border: 1px solid ${({ theme }) => theme.semantic.palette.border['border-tertiary']};
  flex: auto;
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
  position: relative;
  &:focus-within {
    border: ${({ theme, $textareaVisible }) =>
      $textareaVisible && `1px solid ${theme.semantic.palette.border['border-brand']}`};
  }
  &:hover {
    border: ${({ theme, $textareaVisible }) =>
      $textareaVisible && `1px solid ${theme.semantic.palette.border['border-brand']}`};
  }

  ${TextArea} {
    max-height: 130px;
    overflow: auto;
    margin: -1px 0;

    textarea {
      border: none;
      padding: 0;
    }
    &:hover {
      textarea {
        border: none;
        background-color: transparent;
      }
    }
  }
  ${Button} {
    &:disabled {
      pointer-events: none;
    }
  }
`

export default styled(Comment)`
  ${Persona} {
    margin-top: 4px;
  }

  ${RecognizeLinks} ${Typography} {
    white-space: pre-wrap;
  }
`
