import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import keythereum from 'keythereum'
import Wallet from 'ethereumjs-wallet'
import ecies from 'eth-ecies'
import sortBy from 'lodash/sortBy'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import compact from 'lodash/compact'

import { callApi, callApiPagination } from '../utils/callApi'
import { keythereumParams } from '../services/keyCreationService'
import { updateIncluded } from './included'
import { BACKEND_PERMISSIONS, WORKSPACE_ROLES } from '../enums'

// eslint-disable-next-line default-param-last
export const getWorkspaces = createAsyncThunk('workspaces/get', async (payload = {}, api) => {
  const workspaces = await callApiPagination({
    endpoint: 'workspaces?include=members.grantedToAccount.ownedBy',
  })
  api.dispatch(updateIncluded(workspaces.included))
  api.dispatch(updateIncluded(workspaces.data))

  const wsOrder = sortBy(workspaces.data, 'attributes.created').map((ws) => ws.id)

  // get also roles for all workspaces
  const userAccount = api.getState().account.entity

  const response = await callApiPagination({
    endpoint: `workspaces?include=members,folders&filter[workspacememberships][grantedToAccount.id]=${userAccount.id}&sort=created`,
  })

  const workspaceMemberships = (response.included || []).filter(
    (m) => m.type === 'workspacememberships',
  )
  const folders = (response.included || []).filter((m) => m.type === 'folders')
  const foldersOrder = folders.map((f) => f.id)

  const membershipMap = keyBy(workspaceMemberships || [], 'relationships.workspace.data.id')
  const wsMembership = response.data.map((ws) => ({
    ...ws.attributes,
    ...membershipMap[ws.id]?.attributes,
    workspaceId: ws.id,
    membershipId: membershipMap[ws.id]?.id,
    accountId: userAccount.id,
  }))
  const wsMembershipMap = keyBy(wsMembership, 'workspaceId')

  api.extra.sendCallback(payload.callback, workspaces)
  return {
    workspaces: response.data,
    order: wsOrder,
    membership: wsMembershipMap,
    folders,
    canCreateNewWorkspaces: !!(workspaces?.meta?.userPermissions || []).find(
      (p) => p === BACKEND_PERMISSIONS.WORKSPACES.CREATE_WORKSPACE,
    ),
    foldersOrder,
  }
})

export const companyMembersData = (companyMembers) => {
  const includedMap = keyBy(companyMembers.included, 'id')
  const userData = companyMembers.data
    .filter((companymembership) => {
      const account = includedMap[companymembership.relationships.grantedToAccount.data.id]
      const naturalperson = includedMap[account.relationships.ownedBy.data.id]

      return naturalperson.attributes.reservation === false
    })
    .map((companymembership) => {
      const account = includedMap[companymembership.relationships.grantedToAccount.data.id]
      const naturalperson = includedMap[account.relationships.ownedBy.data.id]

      return {
        publicKey: account.attributes.publicKey,
        avatarColor: account.attributes.avatarColor,
        email: naturalperson.attributes.emailAddress,
        firstName: naturalperson.attributes.firstName,
        lastName: naturalperson.attributes.lastName,
        reservation: naturalperson.attributes.reservation,
        id: naturalperson.id,
      }
    })

  return { userData }
}

export const fetchCompanyMembers = createAsyncThunk(
  'workspace/getMembers/fetch',
  async ({ companyId }, api) => {
    const isShareWithExist = api.getState().workspaces.members

    if (isShareWithExist.length > 0) {
      return { members: isShareWithExist }
    }
    if (!companyId) {
      return { members: [] }
    }

    const companyMembers = await callApi({
      endpoint: `companies/${companyId}/members?include=grantedToAccount.ownedBy&sort=grantedToAccount.ownedBy.emailAddress,grantedToAccount.ownedBy.firstName,grantedToAccount.ownedBy.lastName`,
    })

    const { userData } = companyMembersData(companyMembers)
    api.dispatch(setCompanyMembers(userData || []))
    let nextLink = companyMembers.links?.next
    while (nextLink) {
      // eslint-disable-next-line no-await-in-loop
      const nextResponse = await callApi({ endpoint: nextLink.split('ui-api/')[1] })

      const result = {
        data: [...nextResponse.data],
        included: [...nextResponse.included],
        meta: nextResponse.meta,
        links: nextResponse.links,
      }

      const { userData: nextUserData } = companyMembersData(result)
      api.dispatch(setCompanyMembers(nextUserData || []))
      nextLink = nextResponse.links?.next
    }

    return {}
  },
)

export const createWorkspace = createAsyncThunk(
  'workspace/create',
  async ({ title, callback }, api) => {
    const userPublicKey = Buffer.from(
      JSON.parse(localStorage.getItem('user_publicKey')).data,
      'hex',
    )
    const dk = keythereum.create(keythereumParams)
    const wallet = Wallet.fromPrivateKey(dk.privateKey)
    const publicKeyString = wallet.getPublicKeyString().replace('0x', '')
    const privateKey = wallet.getPrivateKey()
    const encrPrivateKey = ecies.encrypt(userPublicKey, privateKey).toString('hex')

    const workspace = await callApi({
      endpoint: 'workspaces',
      method: 'POST',
      body: {
        data: {
          type: 'workspaces',
          attributes: {
            title,
            encrData: {
              publicKey: publicKeyString,
              encrWsPrivateKey: encrPrivateKey,
            },
          },
        },
      },
    })

    await api.extra.dispatchAsync(api.dispatch, inviteAdminsToWorkspace, {
      workspace: workspace.data.id,
    })

    api.dispatch(getWorkspaces())
    api.extra.sendCallback(callback)

    return {}
  },
)

export const getWorkspaceAccessGrants = createAsyncThunk(
  'workspace/WorkspaceAccessgrants',
  async ({ callback, digitalAssetIds }, api) => {
    const { data, included } = await callApiPagination({
      endpoint: `digitalassets?filter=${encodeURIComponent(
        `{"id": [${digitalAssetIds.map((id) => `"${id}"`).join(', ')}]}`,
      )}&include=workspaceAccessGrant,encrData`,
    })

    api.extra.sendCallback(callback, { data, included })

    return {} // ?: not stored to redux store ??
  },
)

export const inviteUserToWorkspace = createAsyncThunk(
  'workspace/inviteUserToWorkspace',
  async (
    { workspaceId, role, encrPrivateKey, accountId, addAccessToFolders, jwt, callback },
    api,
  ) => {
    const body = {
      data: {
        type: 'workspacememberships',
        attributes: {
          role,
          encrWsPrivateKey: encrPrivateKey,
          ...(typeof addAccessToFolders === 'boolean' ? { addAccessToFolders } : {}),
        },
        relationships: {
          grantedToAccount: {
            data: { type: 'accounts', id: accountId },
          },
          workspace: {
            data: { type: 'workspaces', id: workspaceId },
          },
        },
      },
    }

    const result = await callApi({
      endpoint: `workspaces/${workspaceId}/members`,
      method: 'POST',
      accessToken: jwt,
      body,
    })

    api.extra.sendCallback(callback, result)
    return {}
  },
)

export const inviteAdminsToWorkspace = createAsyncThunk(
  'workspace/inviteAdmins',

  async ({ workspace, callback }, api) => {
    const state = api.getState()
    const role = WORKSPACE_ROLES.ADMIN

    const { company, entity: currentUserAccount } = state.account
    const members = await callApi({
      endpoint: `workspaces/${workspace}/members?include=grantedToAccount.ownedBy`,
    })
    const companyAdmins = await callApi({
      endpoint: `companies/${company.id}/members?include=grantedToAccount.ownedBy&filter[role]=admin`,
    })

    const accounts = (companyAdmins.data || [])
      .filter(
        (i) => !members.included.find((m) => i.relationships.grantedToAccount.data.id === m.id),
      )
      .map((admin) =>
        companyAdmins.included.find((i) => i.id === admin.relationships?.grantedToAccount?.data.id),
      )
    const currentUserWorkspaceMembership = members.data.find(
      (m) => m.relationships.grantedToAccount.data.id === currentUserAccount.id,
    )

    const { encrWsPrivateKey } = currentUserWorkspaceMembership.attributes
    const userPrivateKey = Buffer.from(
      JSON.parse(localStorage.getItem('user_privateKey')).data,
      'hex',
    )
    const wsPrivateKey = ecies.decrypt(userPrivateKey, Buffer.from(encrWsPrivateKey, 'hex'))

    for (let i = 0; i < accounts.length; i++) {
      if (!accounts[i].attributes.publicKey) {
        // admin account wasn't created yet
        // eslint-disable-next-line no-continue
        continue
      }

      const encrPrivateKey = ecies
        .encrypt(Buffer.from(accounts[i].attributes.publicKey, 'hex'), wsPrivateKey)
        .toString('hex')
      // eslint-disable-next-line no-await-in-loop

      api.dispatch(
        inviteUserToWorkspace({
          workspaceId: workspace,
          accountId: accounts[i].id,
          role,
          encrPrivateKey,
        }),
      )
    }
    api.extra.sendCallback(callback)
    return {}
  },
)

export const updateFolders = createAsyncThunk(
  'workspace/folder/update',
  async ({ workspaceId, callback }, api) => {
    const { folders, allFolders } = api.getState().workspaces
    const response = await callApiPagination({
      endpoint: `workspaces/${workspaceId}/folders`,
    })

    const rootLevelFolders = response.data.filter((f) => f.relationships.parent.data === null)
    const nonRootFolders = response.data.filter((f) => f.relationships.parent.data !== null)
    const filteredFolders = compact(
      map(folders, (f) => {
        const filtered = f.filter((fo) => fo.relationships.workspace.data.id !== workspaceId)
        return filtered.length > 0 ? filtered : undefined
      }),
    )

    const filteredAllFolders = {
      ...compact(
        map(allFolders, (f) => {
          return f.relationships.workspace.data.id !== workspaceId ? f : undefined
        }),
      ),
    }

    const rootLevelOtherFoldersArray = compact(
      filteredFolders.map((f) => {
        const filtered = f.filter((fo) => fo.relationships.parent.data === null)
        return filtered.length > 0 ? filtered : undefined
      }),
    )
    const nonRootOtherFoldersArray = compact(
      filteredFolders.map((f) => {
        const filtered = f.filter((fo) => fo.relationships.parent.data !== null)
        return filtered.length > 0 ? filtered : undefined
      }),
    )

    const rootLevelOtherFolders = {}
    const nonRootOtherFolders = {}

    rootLevelOtherFoldersArray.forEach((e) => {
      rootLevelOtherFolders[e[0].relationships.workspace.data.id] = e
    })

    nonRootOtherFoldersArray.forEach((e) => {
      nonRootOtherFolders[e[0].relationships.parent.data.id] = e
    })

    const newAllFolders = {
      ...keyBy(response.data, 'id'),
      ...keyBy(filteredAllFolders, 'id'),
    }

    const result = {
      folders: {
        [workspaceId]: rootLevelFolders,
        ...groupBy(nonRootFolders, 'relationships.parent.data.id'),
        ...rootLevelOtherFolders,
        ...nonRootOtherFolders,
      },
      allFolders: newAllFolders,
    }
    api.extra.sendCallback(callback, result)

    return result
  },
)

export const renameFolder = createAsyncThunk(
  'workspace/folder/rename',
  async ({ folderId, newFolderTitle, callback }, api) => {
    const response = await callApi({
      endpoint: `folders/${folderId}`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      body: {
        data: {
          type: 'folders',
          attributes: {
            name: newFolderTitle,
          },
        },
      },
    })

    api.extra.sendCallback(callback)

    return response.data
  },
)

export const deleteFolder = createAsyncThunk(
  'workspace/folder/delete',
  async ({ folderId, callback }, api) => {
    const parentId = api.getState().workspaces.allFolders[folderId].relationships.parent.data?.id
    const parentFolder = api.getState().workspaces?.folders[parentId]

    const response = await callApi({
      endpoint: `folders/${folderId}`,
      method: 'DELETE',
    })

    const result = parentId
      ? { [parentId]: parentFolder.filter((f) => f.id !== folderId) }
      : undefined

    api.extra.sendCallback(callback)

    return result
  },
)

export const createFolder = createAsyncThunk(
  'workspace/folder/create',
  async ({ workspaceId, parentFolderId, folderTitle, inheritPermissions, callback }, api) => {
    const parent = parentFolderId ? { data: { type: 'folders', id: parentFolderId } } : {}

    const response = await callApi({
      endpoint: 'folders',
      method: 'POST',
      body: {
        data: {
          type: 'folders',
          attributes: {
            name: folderTitle,
            inheritPermissions,
          },
          relationships: {
            workspace: {
              data: { type: 'workspaces', id: workspaceId },
            },
            parent,
          },
        },
      },
    })
    api.dispatch(getWorkspaces())
    api.extra.sendCallback(callback)

    return { ...response.data }
  },
)

// eslint-disable-next-line default-param-last
export const getFolders = createAsyncThunk('workspace/folder/get', async (payload = {}, api) => {
  const result = await callApiPagination({ endpoint: `folders` })
  const foldersOrder = result.data.map((f) => f.id)

  api.extra.sendCallback(payload.callback, result)

  return { foldersOrder }
})

export const getFolderPermissions = createAsyncThunk(
  'workspace/folderPermissions/get',
  async ({ folderId, callback }, api) => {
    const folderPermissions = await callApiPagination({
      endpoint: `folders/${folderId}/permissions?include=accessProfile,grantedToAccount.ownedBy`,
      method: 'GET',
    })
    api.extra.sendCallback(callback, folderPermissions)
    api.dispatch(updateIncluded(folderPermissions.included))
    return { folderPermissions }
  },
)

export const updateWorkspace = createAsyncThunk(
  'workspace/update',
  async ({ wsId, callback }, api) => {
    const response = await callApi({
      endpoint: `workspaces/${wsId}?include=members.grantedToAccount.ownedBy`,
      method: 'GET',
    })
    api.extra.sendCallback(callback, response)
    api.dispatch(updateIncluded(response.included))
    api.dispatch(updateIncluded([response.data]))
    return { response }
  },
)

export const getShareTo = createAsyncThunk('workspace/getShareTo', async ({ callback }, api) => {
  const response = await callApi({
    endpoint: `digitalassets/shareTo`,
    headers: {
      Accept: 'application/json',
    },
  })
  api.extra.sendCallback(callback, response)
  return response
})

export const getCompanyWorkspaceFullfiled = createAction('workspace/company/fullfiled')
export const setActiveWorkspace = createAction('workspace/active')
export const setUpdateWorkspaceMembers = createAction('workspace/UpdateWorkspaceMembers')
export const setActiveWorkspaceFailure = createAction('workspace/active/failure')
export const disablePermissionModal = createAction('workspace/permission-modal/disable')
export const setCheckWorkspaceSwitcher = createAction('workspace/CheckWorkspaceSwitcher')

export const setCompanyMembers = createAction('workspace/setCompanyMembers')

export const checkAdminAccess = (userRole) => {
  return userRole === WORKSPACE_ROLES.ADMIN || userRole === WORKSPACE_ROLES.ADMIN_COMPANY
}
