export default {
  en: {
    templates: 'Templates',
    createNewTemplate: 'Create new Template',
    newTemplate: 'New Template',
    createContract: 'Create Сontract',
    deleteTemplate: 'Delete Template',
    deleteTemplateConfirm: 'Are you sure you want to delete the Template "{{title}}"?',
  },
  de: {
    templates: 'Vorlagen',
    createNewTemplate: 'Neue Vorlage erstellen',
    newTemplate: 'Neue Vorlage',
    createContract: 'Vertrag erstellen',
    deleteTemplate: 'Vorlage löschen',
    deleteTemplateConfirm: 'Bist du sicher, dass du die Vorlage "{{title}}" löschen möchtest?',
  },
  fr: {
    templates: 'Modéles',
    createNewTemplate: 'Créer un nouveau modèle',
    newTemplate: 'Nouveau modèle',
    createContract: 'Créer un contrat',
    deleteTemplate: 'Supprimer le modèle',
    deleteTemplateConfirm: 'Êtes-vous sûr de vouloir supprimer le modèle? "{{title}}"?',
  },
}
