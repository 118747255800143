/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit'

import {
  deleteDigitalAsset,
  digitalAssetInviteLock,
  enableDesktopSidebar,
  fetchDigitalAssetsDetails,
  fetchDigitalAssetShareWith,
  filterDigitalAssetsByStatus,
  getDigitalAssetsFirstPage,
  mobileEnabledSidebar,
  mobileSidebarEnabled,
  patchDigitalAsset,
  patchDigitalAssetList,
  patchDigitalAssetSync,
  removeDocumentFromDeleteList,
  removeLoading,
  resetUploadDocumentFlag,
  setActiveDigitalAsset,
  setDocumentOpen,
  setDocumentsDelete,
  setDocumentsSortBy,
  setDocumentUpdate,
  setSelectedDigitalAssets,
  setUpdateSelected,
  storeFulltextSearchIndex,
  toggleDateChangeGracePeriod,
  tryLazyLoadDigitalAssetsNextPage,
  updateLabelsCol,
} from '../actions/digitalAssets'
import { patchBcTxId } from '../actions/blockchain'
import { deleteReminder, fetchEvents } from '../actions/events'
import { signDigitalAsset } from '../actions/sign'
import { logout } from '../actions/logout'
import { loadInitialReducerState } from '../store/persist'
import {
  setContainerUploadDocs,
  setSelectTab,
  setUploadDocs,
  uploadDocument,
} from '../actions/documents'
import { digitalAssetsSearchValue } from '../actions/digitalAssets/getDigitalAssetsFirstPage'

const initialState = {
  data: [],
  contractsLoading: false,
  contractsLoadingMore: false,
  details: {},
  shareWith: null,
  inviteLock: false,
  meta: {},
  documentsSortBy: 'created',
  dateGracePeriod: false,
  selected: {},
  selectedCount: 0,
  updateSelected: false,
  uploadingDocument: false,
  fulltextSearchIndex: [],
  searchValue: '',
  documentOpen: {},
  documentUpdate: false,
  selectTab: '',
  uploadDocs: [],
  uploadContainerDocs: {},
  documentsDelete: [],
  mobileSidebar: false,
  desktopSidebar: true,
  mobileEnabled: false,

  ...loadInitialReducerState('digitalAssets', 'inhubber-redux'),
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(digitalAssetsSearchValue.fulfilled, (state, action) => {
      return {
        ...state,
        searchValue: action.payload.value,
      }
    })
    .addCase(removeLoading, (state) => {
      return {
        ...state,
        contractsLoading: false,
      }
    })
    .addCase(getDigitalAssetsFirstPage.pending, (state) => {
      return {
        ...state,
        contractsLoading: true,
      }
    })
    .addCase(getDigitalAssetsFirstPage.fulfilled, (state, action) => {
      const { contracts, lastFilter, meta } = action.payload
      return {
        ...state,
        data: contracts,
        contractsLoading: false,
        lastFilter,
        meta,
      }
    })
    .addCase(getDigitalAssetsFirstPage.rejected, (state, action) => {
      return {
        ...state,
        contractsLoading: action.error.name === 'AbortError',
      }
    })
    .addCase(filterDigitalAssetsByStatus.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload.contracts,
      }
    })
    .addCase(tryLazyLoadDigitalAssetsNextPage.pending, (state) => {
      return {
        ...state,
        contractsLoadingMore: true,
      }
    })
    .addCase(tryLazyLoadDigitalAssetsNextPage.rejected, (state) => {
      return {
        ...state,
        contractsLoadingMore: false,
      }
    })
    .addCase(tryLazyLoadDigitalAssetsNextPage.fulfilled, (state, action) => {
      if (action.payload.contracts.length === 0) {
        state.contractsLoadingMore = false
        return state
      }
      return {
        ...state,
        data: [...state.data, ...action.payload.contracts],
        meta: action.payload.meta,
        contractsLoadingMore: false,
      }
    })
    .addCase(deleteDigitalAsset.fulfilled, (state, action) => {
      return {
        ...state,
        data: state.data.filter((da) => da.id !== action.payload.daId),
      }
    })
    .addCase(patchDigitalAssetList.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload.data,
      }
    })
    .addCase(fetchDigitalAssetsDetails.fulfilled, (state, action) => {
      const { id, asset } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          [id]: {
            ...state.details[id],
            asset: { ...asset, reduxUpdatedAt: new Date().toISOString() },
            latestId: id,
          },
        },
      }
    })
    .addCase(setActiveDigitalAsset, (state, action) => {
      return {
        ...state,
        details: { ...state.details, latestId: action.payload },
      }
    })
    .addCase(fetchDigitalAssetsDetails.rejected, (state, action) => {
      const { id } = action.meta.arg
      if (action?.error?.name === 'AbortError') {
        return { ...state }
      }
      return {
        ...state,
        details: {
          ...state.details,
          [id]: { asset: { id, request: 'rejected' } },
        },
      }
    })
    .addCase(fetchEvents.fulfilled, (state, action) => {
      const { id, events } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          [id]: { ...state.details[id], events },
        },
      }
    })
    .addCase(deleteReminder.fulfilled, (state, action) => {
      const { reminderId, eventId, daId } = action.payload
      const reminders = state.details[daId]?.events.data.find((e) => e.id === eventId)
        ?.relationships.reminders.data
      if (reminders) {
        state.details[daId].events.data.find((e) => e.id === eventId).relationships.reminders.data =
          reminders.filter((r) => r.id !== reminderId)
      }
      return state
    })
    .addCase(patchDigitalAssetSync, (state, action) => {
      const { attributes, id, relationships } = action.payload
      state.details[id].asset.attributes = attributes
      state.details[id].asset.relationships = {
        ...state.details[id].asset.relationships,
        ...relationships,
      }
      return state
    })
    .addCase(patchDigitalAsset.fulfilled, (state, action) => {
      const { attributes, id } = action.payload
      state.details[id].asset.attributes = attributes
      return state
    })
    .addCase(updateLabelsCol.fulfilled, (state, action) => {
      const { daId, col } = action.payload
      const data = state.data.map((item) => {
        if (item.id === daId) {
          return {
            ...item,
            col,
          }
        }
        return item
      })
      return { ...state, data }
    })
    .addCase(fetchDigitalAssetShareWith.fulfilled, (state, action) => {
      return {
        ...state,
        shareWith: action.payload,
      }
    })
    .addCase(digitalAssetInviteLock, (state, action) => {
      return {
        ...state,
        inviteLock: action.payload.locked,
      }
    })
    .addCase(signDigitalAsset.fulfilled, (state, action) => {
      const { daId, attributes } = action.payload
      const da = state.data.find((asset) => asset.id === daId)
      if (state.selected[daId]) {
        delete state.selected[daId]
        state.selectedCount = Object.keys(state.selected)?.length || 0
      }
      if (!da) {
        return state
      }
      da.attributes = attributes
      return state
    })
    .addCase(logout, () => {
      return { ...initialState }
    })
    .addCase(patchBcTxId, (state, action) => {
      const { digitalassets, documentsmetadata } = action.payload
      if (digitalassets && digitalassets.data.length > 0) {
        digitalassets.data.forEach((da) => {
          if (state.details[da.id]) {
            state.details[da.id].asset.attributes = da.attributes
          }
        })
      }
      if (documentsmetadata && documentsmetadata.data.length > 0) {
        documentsmetadata.data.forEach((patchedDoc) => {
          const daId = patchedDoc.relationships.digitalAsset.data.id
          if (state.details[daId] && state.details[daId].asset.relationships.documents.data) {
            state.details[daId].asset.relationships.documents.data.forEach((doc, index) => {
              if (doc.id === patchedDoc.id) {
                state.details[daId].asset.relationships.documents.data[index].attributes =
                  patchedDoc.attributes
              }
            })
          }
        })
      }
      return state
    })
    .addCase(setDocumentsSortBy, (state, action) => {
      return {
        ...state,
        documentsSortBy: action.payload,
      }
    })
    .addCase(setSelectedDigitalAssets, (state, action) => {
      const { selected, updateSelected } = action.payload
      return {
        ...state,
        selected,
        selectedCount: Object.keys(selected)?.length || 0,
        updateSelected: updateSelected || false,
      }
    })
    .addCase(setUpdateSelected, (state, action) => {
      return {
        ...state,
        updateSelected: action.payload,
      }
    })
    .addCase(toggleDateChangeGracePeriod, (state, action) => {
      return {
        ...state,
        dateGracePeriod: !!action.payload,
      }
    })
    .addCase(uploadDocument.pending, (state) => {
      return {
        ...state,
        uploadingDocument: true,
      }
    })
    .addCase(resetUploadDocumentFlag, (state) => {
      return {
        ...state,
        uploadingDocument: false,
      }
    })
    .addCase(uploadDocument.rejected, (state) => {
      return {
        ...state,
        uploadingDocument: false,
      }
    })
    .addCase(storeFulltextSearchIndex.type, (state, action) => {
      return {
        ...state,
        fulltextSearchIndex: [...state.fulltextSearchIndex, action.payload],
      }
    })
    .addCase(setDocumentOpen, (state, action) => {
      return {
        ...state,
        documentOpen: { [action.payload.daId]: { ...action.payload } },
      }
    })
    .addCase(setDocumentsDelete, (state, action) => {
      return {
        ...state,
        documentsDelete: [...state.documentsDelete, action.payload],
      }
    })
    .addCase(removeDocumentFromDeleteList, (state, action) => {
      return {
        ...state,
        documentsDelete: state.documentsDelete.filter((e) => e.taskId !== action.payload.taskId),
      }
    })

    .addCase(setDocumentUpdate, (state, action) => {
      return {
        ...state,
        documentUpdate: action.payload,
      }
    })
    .addCase(setSelectTab, (state, action) => {
      return {
        ...state,
        selectTab: action.payload,
      }
    })
    .addCase(setUploadDocs.fulfilled, (state, action) => {
      return {
        ...state,
        uploadDocs: action.payload,
      }
    })
    .addCase(setContainerUploadDocs.fulfilled, (state, action) => {
      return {
        ...state,
        uploadContainerDocs: action.payload,
      }
    })
    .addCase(enableDesktopSidebar, (state, action) => {
      return {
        ...state,
        desktopSidebar: !!action.payload,
      }
    })
    .addCase(mobileSidebarEnabled, (state, action) => {
      return {
        ...state,
        mobileSidebar: !!action.payload,
      }
    })
    .addCase(mobileEnabledSidebar, (state, action) => {
      return {
        ...state,
        mobileEnabled: !!action.payload,
      }
    })
})
export default reducer
