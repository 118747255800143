import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { toggleSidebar } from '../actions/application'

const useHideSidebar = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(toggleSidebar({ state: false }))
    return () => dispatch(toggleSidebar({ state: true }))
  }, [dispatch])
}

export default useHideSidebar
