import { useState, useMemo, useEffect, Fragment } from 'react'
import styled from 'styled-components'

import { Modal, Spacing } from '../../atoms'
import Menu, { MenuItem } from './Menu'

const SettingsModal = ({
  defaultTab,
  menuItems,
  components,
  className,
  visible,
  onClose,
  params = {},
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab)

  const PageComponent = useMemo(() => components[selectedTab], [components, selectedTab])

  // reset selected tab to default
  useEffect(() => {
    if (visible) {
      setSelectedTab(defaultTab)
    }
  }, [defaultTab, visible])

  const menuItemsCollection = useMemo(
    () =>
      menuItems.map((i) => (
        <Fragment key={i.id}>
          <MenuItem
            i18n={i.i18n}
            selected={selectedTab === i.id}
            onClick={() => setSelectedTab(i.id)}
            data-test={`button_workspace_settingsModal_${i.id}`}
          />
          <Spacing size="4" />
        </Fragment>
      )),
    [menuItems, selectedTab],
  )

  return (
    <Modal visible={visible} onClose={onClose} width="840" {...props}>
      <div className={className}>
        <Menu>{menuItemsCollection}</Menu>
        <Body>
          <div>
            <PageComponent {...params} />
          </div>
        </Body>
      </div>
      <Spacing size="24" />
    </Modal>
  )
}

const Body = styled.div`
  padding-left: 24px;
  overflow-y: auto;
  padding-right: 8px;
  height: 581px;
  max-height: 70vh;
  > div {
    width: 595px;
    box-sizing: border-box;
  }
`

export default styled(SettingsModal)`
  display: grid;
  grid-template-columns: 196px auto;
`
