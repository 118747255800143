/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { createEditor } from 'slate'
import { Editable, Slate, withReact } from 'slate-react'
import { useSelector } from 'react-redux'

import { createSelector } from 'reselect'
import { Persona, Spacing, Typography } from '../../atoms'
import Leaf from '../../organisms/CommentsEditor/Leaf' // TODO: resolve this dependency; molecules should not import organisms
import ActivityDate from './ActivityDate'
import { selectIncluded } from '../../../store/selectors'
import { DEFAULT_TEXT_VALUE } from '../../organisms/CommentsEditor'

const Comment = ({ value = DEFAULT_TEXT_VALUE, authorId, date, className }) => {
  const editor = useMemo(() => withReact(createEditor()), [])

  const renderLeaf = useCallback((p) => <Leaf {...p} />, [])
  const selectData = createSelector([selectIncluded], (included) => {
    return { author: included.naturalpersons[authorId] }
  })
  const { author } = useSelector(selectData)

  if (!author) {
    return null
  }

  return (
    <>
      <div className={className}>
        <Persona
          firstName={author.attributes.firstName}
          lastName={author.attributes.lastName}
          size="medium"
          seed={author.attributes.emailAddress}
        />
        <Spacing size="12" />
        <div>
          <div>
            <Typography variant="13 medium">
              {author.attributes.firstName} {author.attributes.lastName}
            </Typography>
            <Spacing size="8" inline />
            <ActivityDate date={date} inline />
          </div>
          <Typography variant="13 regular">
            <Slate editor={editor} initialValue={value} value={value}>
              <Editable readOnly renderLeaf={renderLeaf} />
            </Slate>
          </Typography>
        </div>
      </div>
      <Spacing size="16" />
    </>
  )
}

export default styled(Comment)`
  display: flex;

  ${Persona} {
    margin-top: 2px;
    margin-bottom: 2px;
  }
`
