import styled from 'styled-components'
import { createSelector } from 'reselect'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../atoms/Modal/Modal'
import { Button, Elevation, Flex, Spacing, TextInput } from '../atoms'
import Typography from '../atoms/Typography'

import { selectAccount } from '../../store/selectors'

const SetUpAuthenticationModal = ({ onClose, visible, authSecret, onActivate, codeError }) => {
  const { t } = useTranslation()
  const [authCode, setAuthCode] = useState('')

  useEffect(() => {
    if (visible) {
      setAuthCode('')
    }
  }, [visible, setAuthCode])

  const selectData = createSelector([selectAccount], (account) => ({
    userEmail: account?.currentUser.emailAddress,
  }))
  const { userEmail } = useSelector(selectData)

  return (
    <Container>
      <Modal
        headerI18n="SetUpAuthenticationApp"
        onClose={onClose}
        visible={visible}
        type="oldModal"
        buttons={
          <Button
            i18n="Activate"
            disabled={!(authCode.length > 0)}
            onClick={
              authCode.length > 0
                ? () => {
                    onActivate(authCode)
                  }
                : undefined
            }
            data-test="button_setUpAuthenticationModal_activate"
          >
            Activate
          </Button>
        }
        data-test="modal_securityAndLogin_setUpAuthenticationModal"
      >
        <Spacing size={24} />
        <Typography variant="14 medium" color="primary" i18n="ScanQRCode">
          1. Scan this QR code with your authenticaition app
        </Typography>
        <Spacing size={4} />
        <Typography variant="13 regular" color="secondary" i18n="weRecommendGoogleAuthenticator">
          we recommend Google Authenticator
        </Typography>
        <QRContainer>
          <Spacing size={32} />
          <QRCode value={`otpauth://totp/${userEmail}?secret=${authSecret}&issuer=INHUBBER`} />
          <Spacing size={32} />
        </QRContainer>
        <GrayBlock>
          <Typography variant="13 regular" color="secondary" i18n="YourAuthenticationApp">
            Or enter this code into your authentication app
          </Typography>
          <Spacing size={4} />
          <Typography
            variant="14 medium"
            color="primary"
            data-test="typography_securityAndLogin_authSecret"
          >
            {authSecret}
          </Typography>
        </GrayBlock>
        <Spacing size={24} />
        <Typography variant="14 medium" color="primary" i18n="EnterCodeGenerated">
          Enter the 6-digit code generated by app
        </Typography>
        <Spacing size={16} />
        <TextInput
          type="text"
          placeholder={t('Code')}
          onChange={(e) => setAuthCode(e.target.value)}
          value={authCode}
          error={codeError}
          validator={() => {
            return codeError ? t('incorrectAuthenticationCode') : ''
          }}
          data-test="input_setUpAuthenticationModal_code"
        />
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  ${Elevation} {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
  }
`

const QRContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const GrayBlock = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.background.tertiary};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
`

export default SetUpAuthenticationModal
