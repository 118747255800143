import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { Divider } from '../../../atoms'
import FolderCreateModal from './FolderCreateModal'
import NewFolderButton from './NewFolderButton'
import FolderContainer from './FolderContainer'
import SubFolders from './SubFolders'
import FolderItem from './FolderItem'

import { updateFolders } from '../../../../actions/workspaces'
import { useDispatchAsync, usePermissions } from '../../../../hooks'
import {
  getActiveFolder,
  getAllFolders,
  getFolders,
  selectWorkspaces,
} from '../../../../store/selectors'

const Folders = ({ visible, disabled, workspaceId }) => {
  const dispatchAsync = useDispatchAsync()

  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const { isPermissionEnabled, PERMISSIONS } = usePermissions({ workspaceId })
  const canCreateFolder = isPermissionEnabled(PERMISSIONS.WORKSPACE_MANAGE_FOLDERS)

  const folders = useSelector(getFolders)
  const activeFolder = useSelector(getActiveFolder)
  const allFolders = useSelector(getAllFolders)

  const selectData = createSelector([selectWorkspaces], (workspaces) => ({
    location: activeFolder
      ? workspaces.allFolders[activeFolder]?.relationships.location.data
      : null,
    wsFolder: workspaces.allWorkspaces[workspaceId].relationships.folders.data,
  }))

  const { location, wsFolder } = useSelector(selectData)

  const workspaceFolders = useMemo(() => {
    return Object.entries(folders)
      .map(([key, value]) => {
        if (key === workspaceId) {
          return value
        }
        return value
          .map((item) => {
            const wsId = item.relationships.workspace.data.id
            const parentId = item.relationships.parent.data?.id
            if (wsId === workspaceId && parentId && !allFolders[parentId]) {
              return { ...item, unparentFolder: true }
            }
            return null
          })
          .filter((t) => t?.attributes)
      })
      .filter((t) => t.length)
      .flat(1)
      .sort((a, b) => {
        const nameA = a?.attributes?.name?.toUpperCase()
        const nameB = b?.attributes?.name?.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
  }, [allFolders, folders, workspaceId])

  const haveFolder = useMemo(
    () => !!wsFolder.find((i) => i.id === activeFolder),
    [activeFolder, wsFolder],
  )

  useEffect(() => {
    if (!visible) {
      setLoaded(false)
    }
  }, [visible])

  useEffect(() => {
    const updateData = async () => {
      setLoading(true)
      const result = await dispatchAsync(updateFolders, { workspaceId })
      setLoading(false)
      setLoaded(true)
    }

    if (visible && workspaceId && !loaded) {
      updateData()
    }
  }, [dispatchAsync, loaded, visible, workspaceId])

  if (disabled || (!canCreateFolder && workspaceFolders.length === 0) || !visible) {
    return <FolderContainer />
  }

  if (loading) {
    return (
      <FolderContainer visible={visible}>
        <FolderItem loading />
      </FolderContainer>
    )
  }

  return (
    <>
      <FolderContainer visible={visible}>
        {workspaceFolders.map((f) => (
          <SubFolders
            data={f}
            unparentFolder={f?.unparentFolder}
            key={f.attributes.slug}
            workspaceId={workspaceId}
            location={haveFolder ? location : null}
          />
        ))}
        <NewFolderButton visible={canCreateFolder} onClick={() => setModalVisible(true)} />
      </FolderContainer>

      <FolderCreateModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        workspaceId={workspaceId}
        mainFolder
      />
    </>
  )
}

export default styled(Folders)`
  ${FolderContainer} {
    position: relative;
  }
`
