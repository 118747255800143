export const DIGITALASSET_STATES = Object.freeze({
  ARCHIVED: 'archived',
  ACTIVE: 'active',
  PENDING: 'pending',
  DRAFT: 'draft',
  EXPIRED: 'expired',
  SIGNED: 'signed',
})

export const DIGITALASSETS_WORKFLOWS = Object.freeze({
  SIGNING_EXTERNALS_FIRST: 'SIGNING_EXTERNALS_FIRST',
})

export const OVERVIEW_FIELDS = Object.freeze({
  STATE: 'state',
  CREATED: 'created',
  START_DATE: 'startDate',
  EXPIRY_DATE: 'expiryDate',
  LABELS: 'tags',
  VALUE: 'value.monetaryAmount.amount',
  OWNER: 'ownedBy.lastName,ownedBy.firstName',
})

export const DIGITALASSETS_SIGNATURE_TYPE = Object.freeze({
  QES: 'qes',
})
