/* eslint-disable no-return-assign */

function importAll(r) {
  const rootReducer = {}
  r.keys()
    .filter((key) => key.indexOf('index') === -1)
    .forEach((key) => (rootReducer[key.replace('./', '').replace('.js', '')] = r(key).default))

  return rootReducer
}

export default importAll(require.context('./', true, /\.js$/))
// At build-time rootReducer will be populated with all required modules.
