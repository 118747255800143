import { useMemo } from 'react'
import compact from 'lodash/compact'

import ProfilePage from './ProfilePage'
import SecurityPage from './SecurityPage'
import SettingsModal from '../SettingsModal'

import PROFILE_TABS from '../../../enums/profileTabs'

const Components = {
  [PROFILE_TABS.PROFILE]: ProfilePage,
  [PROFILE_TABS.SECURITY]: SecurityPage,
}

const ProfileModal = ({ visible, defaultTab = PROFILE_TABS.PROFILE, onClose }) => {
  const menuItems = useMemo(
    () =>
      compact([
        { id: PROFILE_TABS.PROFILE, i18n: 'Profile' },
        { id: PROFILE_TABS.SECURITY, i18n: 'sidebar:securityAndLogin' },
      ]),
    [],
  )

  return (
    <SettingsModal
      defaultTab={defaultTab}
      menuItems={menuItems}
      components={Components}
      visible={visible}
      onClose={onClose}
      headerI18n="sidebar:profileSettings"
    />
  )
}

export default ProfileModal
