import { lazy } from 'react'
import { Route } from 'react-router-dom'
import PageNotFound from '../pages/PageNotFound'
import PrivateRoute from './PrivateRoute'

export const LoginPage = lazy(() => import(/* webpackChunkName: "login" */ '../pages/Login'))
export const SignupPage = lazy(() => import(/* webpackChunkName: "signup" */ '../pages/Signup'))
export const RecoveryPage = lazy(
  () => import(/* webpackChunkName: "recovery" */ '../pages/Recovery'),
)
export const ContractDetailsPage = lazy(
  () => import(/* webpackChunkName: "contract" */ '../pages/ContractDetails'),
)
export const ProofOfContractPage = lazy(
  () => import(/* webpackChunkName: "contract" */ '../pages/ContractDetails/ProofOfContract'),
)
const ContractRedirectPage = lazy(
  () => import(/* webpackChunkName: "contract" */ '../pages/Contracts/RedirectPage'),
)
const AccountMigrationPage = lazy(
  () => import(/* webpackChunkName: "account-migration" */ '../pages/Migration/AccountMigration'),
)
const HomePage = lazy(() => import(/* webpackChunkName: "home" */ '../pages/Home'))

export const AdminPage = lazy(() => import(/* webpackChunkName: "admin" */ '../pages/Admin'))

const CompanyGeneralSettingsPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/General'),
)

const CompanyPlansPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/Plans'),
)

const CompanyMembers = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/Members'),
)

const CustomFieldsPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/CustomFields'),
)

const ExportPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/Export'),
)

const CompanyWorkspacesPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/Workspaces'),
)

const CompanyBillingInfoSettingsPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/Billing/Info'),
)

const CompanyBillingOverviewSettingsPage = lazy(
  () =>
    import(/* webpackChunkName: "companysettings" */ '../pages/Company/Settings/Billing/Overview'),
)

const CompanyUpgradeBasicPage = lazy(
  () => import(/* webpackChunkName: "companysettings" */ '../pages/Company/Upgrade/Basic'),
)

const WorkspacesWrapperPage = lazy(
  () => import(/* webpackChunkName: "contracts" */ '../pages/Workspaces'),
)

// all pages (and their dependencies) are going to be loaded on demand
const privatePages = [
  {
    route: '/my-contracts',
    page: lazy(() => import(/* webpackChunkName: "contracts" */ '../pages/Contracts')),
  },
  {
    route: [
      '/contracts/:id',
      '/contracts/:id/dos/event/:eventId/reminder/:reminderId',
      '/contracts/:id/dos/event/:eventId',
      '/contracts/:id/tasks',
      '/contracts/:id/tasks/:taskId',
      '/contracts/:id/tasks/:taskId/comments/:commentId',
      '/contracts/:id/dos/tasks',
      '/contracts/:id/dos/tasks/:taskId',
      '/contracts/:id/dos/tasks/:taskId/comments/:commentId',
    ],
    page: ContractRedirectPage,
  },
  {
    route: [
      '/contract-details/:id',
      '/contract-details/:id/dos/event/:eventId/reminder/:reminderId',
      '/contract-details/:id/dos/event/:eventId',
      '/contract-details/:id/tasks',
      '/contract-details/:id/tasks/:taskId',
      '/contract-details/:id/tasks/:taskId/comments/:commentId',
      '/contract-details/:id/dos/tasks/',
      '/contract-details/:id/dos/tasks/:taskId',
      '/contract-details/:id/dos/tasks/:taskId/comments/:commentId',

      '/contract-new/:id',
      '/contract-new/:id/dos/event/:eventId',
      '/contract-new/:id/dos/event/:eventId/reminder/:reminderId',
      '/contract-new/:id/tasks',
      '/contract-new/:id/tasks/:taskId',
      '/contract-new/:id/tasks/:taskId/comments/:commentId',
      '/contract-new/:id/dos/tasks',
      '/contract-new/:id/dos/tasks/:taskId',
      '/contract-new/:id/dos/tasks/:taskId/comments/:commentId',
    ],
    page: ContractDetailsPage,
  },
  {
    route: '/contract-details/:id/proof-of-signature',
    page: ProofOfContractPage,
  },
  { route: '/account-migration', page: AccountMigrationPage },
  {
    route: '/templates',
    page: lazy(() => import(/* webpackChunkName: "templates" */ '../pages/Templates')),
  },
  {
    route: '/templates/new',
    page: lazy(() => import(/* webpackChunkName: "templates" */ '../pages/Playground/pdf')),
  },
  {
    route: '/templates/:id',
    page: lazy(() => import(/* webpackChunkName: "templates" */ '../pages/Playground/pdf')),
  },

  {
    route: ['/workspaces/:slug', '/workspaces/:slug/folders/:folderSlug'],
    page: WorkspacesWrapperPage,
  },
  {
    route: '/archive',
    page: lazy(() => import(/* webpackChunkName: "contracts" */ '../pages/Archive')),
  },

  { route: ['/home', '/selfident/completed'], page: HomePage },
  { route: '/company/settings/general', page: CompanyGeneralSettingsPage },
  { route: '/company/settings/plans', page: CompanyPlansPage },
  { route: '/company/settings/members', page: CompanyMembers },
  { route: '/company/settings/workspaces', page: CompanyWorkspacesPage },
  { route: '/company/settings/custom-fields', page: CustomFieldsPage },
  { route: '/company/settings/export', page: ExportPage },
  { route: '/company/settings/billing/info', page: CompanyBillingInfoSettingsPage },
  { route: '/company/settings/billing/overview', page: CompanyBillingOverviewSettingsPage },

  { route: '/company/upgrade/basic', page: CompanyUpgradeBasicPage },

  {
    route: '/company/settings/workflow',
    page: lazy(
      () => import(/* webpackChunkName: "workflow" */ '../pages/Company/Settings/Workflow'),
    ),
  },
  {
    route: '/company/settings/workflow/:id',
    page: lazy(
      () =>
        import(
          /* webpackChunkName: "workflow" */ '../pages/Company/Settings/Workflow/WorkflowEditor'
        ),
    ),
  },

  {
    route: '/extended-search/:query',
    page: lazy(() => import(/* webpackChunkName: "contract" */ '../pages/FullTextSearch')),
  },

  {
    route: '/playground',
    page: lazy(() => import(/* webpackChunkName: "playground" */ '../pages/Playground/_Blank')),
  },
  {
    route: '/playground/atoms/radio',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Atoms/RadioButton'),
    ),
  },
  {
    route: '/playground/molecules/actionButton',
    page: lazy(
      () =>
        import(/* webpackChunkName: "playground" */ '../pages/Playground/Molecules/ActionButton'),
    ),
  },
  {
    route: '/playground/molecules/newComponents',
    page: lazy(
      () =>
        import(/* webpackChunkName: "playground" */ '../pages/Playground/Molecules/NewComponents'),
    ),
  },
  {
    route: '/playground/organisms/newOrganisms',
    page: lazy(
      () =>
        import(/* webpackChunkName: "playground" */ '../pages/Playground/Organisms/NewOrganisms'),
    ),
  },
  {
    route: '/playground/molecules/actionIcon',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Molecules/ActionIcon'),
    ),
  },
  {
    route: '/playground/molecules/assigneeInput',
    page: lazy(
      () =>
        import(/* webpackChunkName: "playground" */ '../pages/Playground/Molecules/AssigneeInput'),
    ),
  },
  {
    route: '/playground/organisms/taskCard',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Organisms/TaskCard'),
    ),
  },
  {
    route: '/playground/organisms/testAntialiased',
    page: lazy(
      () =>
        import(
          /* webpackChunkName: "playground" */ '../pages/Playground/Organisms/TestAntialiased'
        ),
    ),
  },
  {
    route: '/playground/foundations/icons',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Foundations/Icons'),
    ),
  },
  {
    route: '/playground/foundations/typography',
    page: lazy(
      () =>
        import(/* webpackChunkName: "playground" */ '../pages/Playground/Foundations/Typography'),
    ),
  },
  {
    route: '/playground/atoms/button',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Atoms/Button'),
    ),
  },
  {
    route: '/playground/tokens/colors',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Tokens/Colors'),
    ),
  },
  {
    route: '/playground/foundations/shadows',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Foundations/Shadows'),
    ),
  },
  {
    route: '/playground/tokens/space',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Tokens/Space'),
    ),
  },
  {
    route: '/playground/tokens/radius',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/Tokens/Radius'),
    ),
  },
  {
    route: '/playground/actionList',
    page: lazy(
      () => import(/* webpackChunkName: "playground" */ '../pages/Playground/ActionListComponent'),
    ),
  },

  { route: '/404', page: PageNotFound },
]

const renderRouteList = () => {
  return privatePages.map((mapping) => {
    const route = Array.isArray(mapping.route) ? mapping.route : [mapping.route]
    return route.map((r) => (
      <Route
        path={r}
        key={mapping.route}
        element={
          <PrivateRoute>
            <mapping.page />
          </PrivateRoute>
        }
      />
    ))
  })
}

export default renderRouteList
