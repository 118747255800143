import { createAsyncThunk } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'

import { callApi, callApiPagination } from '../utils/callApi'
import { updateIncluded } from './included'

const fetchPartnersStep = async (token, link) => {
  const { data, included } = await callApiPagination({
    endpoint: link,
    method: 'GET',
    applicationJson: true,
    accessToken: token,
    accessName: 'Bearer',
    acceptType: 'application/vnd.api+json',
  })

  const personsMap = new Map()
  included.forEach((i) => personsMap.set(i.id, i))
  const result = data
    .map((d) => personsMap.get(d.relationships.partner.data.id))
    .filter((d) => d !== undefined)

  return { result, included }
}

export const fetchPartners = createAsyncThunk('partners/fetch', async ({ npId, callback }, api) => {
  const token = api.extra.getToken()
  const response = await fetchPartnersStep(
    token,
    `partnerships?include=partner.account&filter[issuer.id]=${npId}&sort=partner.emailAddress`,
  )

  api.extra.sendCallback(callback)

  // filter partners without email
  const partners = response.result.filter((partner) => {
    return partner.attributes.emailAddress
  })

  const includedMap = keyBy(response.included, 'id')
  const userData = partners.map((naturalperson) => {
    const account = includedMap[naturalperson.relationships.account.data.id]

    return {
      publicKey: account.attributes.publicKey,
      avatarColor: account.attributes.avatarColor,
      email: naturalperson.attributes.emailAddress,
      firstName: naturalperson.attributes.firstName,
      lastName: naturalperson.attributes.lastName,
      reservation: naturalperson.attributes.reservation,
      id: naturalperson.id,
    }
  })

  return { partners, userData }
})
