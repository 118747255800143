import React, { useEffect, useMemo, useState } from 'react'
import styled, { createGlobalStyle, keyframes, useTheme } from 'styled-components'
import { createPortal } from 'react-dom'

import BasePopup from './BasePopup'
import Flex from '../Flex'
import Elevation from '../Elevation'
import Modal from './Modal'

const BaseTooltip = ({
  enabled,
  children,
  tooltip,
  className,
  trigger = 'hover',
  options = {},
  hideOnItemClick,
  isAnimated,
  onVisibilityChange,
  portal,
  portalClassName,
  closeTooltip,
  canUpdate,
  autoHeight,
  subTooltip,
  type,
  position,
  arrowDark,
  keyTooltip,
  style,
  width,
  direction,
  ...props
}) => {
  const theme = useTheme()
  const [target, setTarget] = useState(null)
  const [hiddenTooltip, setHiddenTooltip] = useState(true)
  const [mouseOnTarget, setMouseOnTarget] = useState(false)
  const [mouseOnTooltip, setMouseOnTooltip] = useState(false)
  const [hideTooltip, setHideTooltip] = useState(false)

  useEffect(() => {
    if (typeof onVisibilityChange === 'function') {
      onVisibilityChange({ visible: !hiddenTooltip })
    }
  }, [hiddenTooltip, onVisibilityChange])

  useEffect(() => {
    if (closeTooltip) setHiddenTooltip(true)
  }, [closeTooltip])

  useEffect(() => {
    let timeoutId

    if (subTooltip) {
      if (!mouseOnTooltip && !mouseOnTarget) {
        timeoutId = setTimeout(() => {
          setHideTooltip(true)
        }, 500)
      } else {
        setHideTooltip(false)
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [mouseOnTarget, mouseOnTooltip, subTooltip, hideTooltip])

  useEffect(() => {
    if (hideTooltip) {
      setHiddenTooltip(!mouseOnTooltip && !mouseOnTarget)
    }
  }, [hideTooltip, mouseOnTarget, mouseOnTooltip])

  const tooltipedChildren = useMemo(() => {
    let handlers = {}

    if (trigger === 'hover') {
      if (subTooltip) {
        handlers = {
          ...handlers,
          onMouseEnter: () => {
            setHiddenTooltip(false)
            setMouseOnTarget(true)
          },
          onMouseLeave: () => setMouseOnTarget(false),
        }
      } else {
        handlers = {
          ...handlers,
          onMouseEnter: () => setHiddenTooltip(false),
          onMouseLeave: () => setHiddenTooltip(true),
        }
      }
    }

    if (trigger === 'click') {
      handlers = {
        ...handlers,
        onClick: () => setHiddenTooltip(false),
      }
    }

    return React.cloneElement(children, handlers)
  }, [children, subTooltip, trigger])

  // apply close onClick on every click inside the tooltip
  // used when there is several ModalItems inside the tooltip
  const modifiedTooltip = useMemo(() => {
    if (hideOnItemClick) {
      return React.cloneElement(tooltip, {
        onClick: trigger === 'click' ? () => setHiddenTooltip(true) : undefined,
      })
    }
    if (subTooltip) {
      return React.cloneElement(tooltip, {
        onMouseEnter: () => {
          setMouseOnTooltip(true)
          setMouseOnTarget(false)
        },
        onMouseLeave: () => setMouseOnTooltip(false),
      })
    }
    return tooltip
  }, [hideOnItemClick, subTooltip, tooltip, trigger])

  const renderOptions = useMemo(() => {
    if (position === 'top') {
      return {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      }
    }
    if (position === 'top-start') {
      return {
        placement: 'top-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-8, 4],
            },
          },
        ],
      }
    }
    if (position === 'top-end') {
      return {
        placement: 'top-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [8, 4],
            },
          },
        ],
      }
    }
    if (position === 'bottom-end') {
      return {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      }
    }
    return {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 4],
          },
        },
      ],
    }
  }, [position])

  const modal = useMemo(() => {
    if (portal) {
      return createPortal(
        <TooltipModal
          overlay={trigger === 'click'}
          onClose={() => setHiddenTooltip(true)}
          hidden={hiddenTooltip}
          target={target}
          options={{ ...renderOptions, ...options }}
          isAnimated={isAnimated}
          width={width}
          className={
            portalClassName
              ? `${portalClassName} elementTooltip ${style}`
              : `elementTooltip ${style}`
          }
          canUpdate={canUpdate}
          autoHeight={autoHeight}
          {...props}
        >
          <Flex className="modalContainer" direction="column">
            <ArrowTooltip dark={arrowDark} />
            <Flex>
              <Flex direction={direction}>{modifiedTooltip}</Flex>
              {keyTooltip && <KeyContainer>{keyTooltip}</KeyContainer>}
            </Flex>
          </Flex>
        </TooltipModal>,
        document.body,
      )
    }
    return (
      <TooltipModal
        overlay={trigger === 'click'}
        onClose={() => setHiddenTooltip(true)}
        hidden={hiddenTooltip}
        target={target}
        options={{ ...renderOptions, ...options }}
        isAnimated={isAnimated}
        canUpdate={canUpdate}
        autoHeight={autoHeight}
        className={`elementTooltip ${style}`}
        width={width}
        {...props}
      >
        <Flex className="modalContainer" direction="column">
          <ArrowTooltip dark={arrowDark} />
          <Flex gap={theme.semantic.space['space-2x-small']}>
            <Flex direction={direction}>{modifiedTooltip}</Flex>
            {keyTooltip && <KeyContainer className="keyContainer">{keyTooltip}</KeyContainer>}
          </Flex>
        </Flex>
      </TooltipModal>
    )
  }, [
    arrowDark,
    autoHeight,
    canUpdate,
    direction,
    hiddenTooltip,
    isAnimated,
    keyTooltip,
    modifiedTooltip,
    options,
    portal,
    portalClassName,
    props,
    renderOptions,
    style,
    target,
    theme.semantic.space,
    trigger,
    width,
  ])

  if (!enabled) {
    return children
  }

  return (
    <div ref={setTarget} className={className}>
      {modal}
      {tooltipedChildren}
    </div>
  )
}

const KeyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.semantic.space['space-5x-small']};
  background: ${({ theme }) => theme.semantic.palette.surface.surface};
  border-radius: ${({ theme }) => theme.defaultRadius['radius-025']};

  span {
    min-width: 12px;
    min-height: 12px;
    text-align: center;
  }
`

const Tooltip = ({ type, ...props }) => {
  const style = useMemo(() => {
    if (type === 'default') {
      return 'defaultTooltip'
    }
    if (type === 'hotKeys') {
      return 'hotKeys'
    }
    if (type === 'contractLabels') {
      return 'contractLabels'
    }
    if (type === 'analyzedByAI') {
      return 'analyzedByAI'
    }
    if (type === 'predefinedSignatureWorkflow') {
      return 'predefinedSignatureWF'
    }
    if (type === 'personaInfo') {
      return 'personaInfo'
    }
    return 'contractLabels'
  }, [type])

  return (
    <BaseTooltip style={style} arrowDark={type === 'default' || type === 'hotKeys'} {...props} />
  )
}

export const ArrowTooltip = styled.div`
  background-color: ${({ theme, dark }) =>
    dark
      ? theme.semantic.palette.surface['surface-invert']
      : theme.semantic.palette.surface.surface};
  mask-image: url('/img/icons/icons16px/center.svg');
  width: 16px;
  height: 8px;
  position: absolute;
`

const show = keyframes`
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
`

const TooltipModal = styled(BasePopup)``

export const TooltipStyling = createGlobalStyle`
 .elementTooltip, ${Modal} ${TooltipModal} {
    animation: 0.5s ${show} ease;
   

    ${Elevation} {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      animation: none;
      ${({ theme }) => theme.shadow.medium};
    }

    &[data-popper-placement*='bottom'] {
      padding-top: 4px;

      ${ArrowTooltip} {
        top: 0;
        transform: rotate(180deg);
      }
    }

   &[data-popper-placement*='start'] {
     ${ArrowTooltip} {
       left: 8px;
     }
   }

   &[data-popper-placement*='end'] {
     ${ArrowTooltip} {
       right: 8px;
     }
   }

    &[data-popper-placement*='top']{
      padding-bottom: 8px;

      ${ArrowTooltip} {
        bottom: 0;
      }
    }
  }

  .contractLabels {
    ${Elevation} {
      background: ${({ theme }) => theme.semantic.palette.surface.surface};
    }
    .modalContainer {
      > ${Flex} {
        background: ${({ theme }) => theme.semantic.palette.surface.surface};
        padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
        border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
        gap: ${({ theme }) => theme.semantic.space['space-4x-small']};
      }
    }
  }

  .defaultTooltip {
    ${Elevation} {
      max-width: 272px;
    }
    .modalContainer {

      > ${Flex} {
        background: ${({ theme }) => theme.semantic.palette.surface['surface-invert']};
        padding: ${({ theme }) =>
          `${theme.semantic.space['space-4x-small']} ${theme.semantic.space['space-2x-small']}`};
        border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
        box-shadow: none;

        > ${Flex}:first-child span {
          ${({ theme }) => theme.typography['body-medium-02']};
          color: ${({ theme }) => theme.colors['neutral-01']};
        }
      }
    }
  }

  .hotKeys {
    ${Elevation} {
      max-width: 272px;
    }
   .modalContainer {
      span {
        ${({ theme }) => theme.typography['body-medium-02']};
        color: ${({ theme }) => theme.colors['neutral-01']};
      }

      > ${Flex} {
        background: ${({ theme }) => theme.semantic.palette.surface['surface-invert']};
        padding: ${({ theme }) =>
          `${theme.semantic.space['space-4x-small']} ${theme.semantic.space['space-2x-small']}`};
        border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
        gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
        box-shadow: none;

        ${KeyContainer} {
          span {
            ${({ theme }) => theme.typography['body-x-small-01']};
            color: ${({ theme }) => theme.semantic.palette.text['text-secondary']};
          }
        }
      }
    }
  }

  .analyzedByAI {
    .modalContainer {
      > ${Flex} {
        flex-direction: column;
        background: ${({ theme }) => theme.semantic.palette.surface.surface};
        padding: ${({ theme }) => theme.semantic.space['space-small']};
        border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
        gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
        ${({ theme }) => theme.shadow.medium};
      }
    }
  }

  .predefinedSignatureWF {
    .modalContainer {
      > ${Flex} {
        flex-direction: column;
        background: ${({ theme }) => theme.semantic.palette.surface.surface};
        padding: ${({ theme }) => theme.semantic.space['space-small']};
        border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
        gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
        ${({ theme }) => theme.shadow.medium};
      }
    }
  }

  .personaInfo {
    .modalContainer {
      > ${Flex} {
        width: 272px;
        flex-direction: column;
        background: ${({ theme }) => theme.semantic.palette.surface.surface};
        padding: ${({ theme }) => theme.semantic.space['space-small']};
        border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
        gap: ${({ theme }) => theme.semantic.space['space-small']};
        ${({ theme }) => theme.shadow.medium};
        > div {
          width: 100%;
        }
      }
    }
  }`

export default styled(Tooltip)``
