import styled from 'styled-components'

const shadows = {
  small: '0px 7px 14px rgba(0, 0, 0, 0.09), 0px 1.2px 2.4px rgba(0, 0, 0, 0.035)',
  medium: '0px 12px 22px rgba(0, 0, 0, 0.1), 0px 2.4px 4px rgba(0, 0, 0, 0.054)',
  big: '0px 14px 27px rgba(0, 0, 0, 0.12), 0px 3.4px 5px rgba(0, 0, 0, 0.034)',
  large: '0px 2.4px 4px 0px rgba(0, 0, 0, 0.05); 0px 12px 22px 0px rgba(0, 0, 0, 0.10)',
}

const radius = {
  modal: 'border-radius-large',
}

const Elevation = styled.div`
  background: ${({ theme }) => theme.palette.background.white};
  box-shadow: ${({ variant }) => shadows[variant]};
  border-radius: ${({ theme, type }) => (type ? theme.semantic.radius[radius[type]] : '4px')};
  box-sizing: border-box;
  padding: 8px;
  width: ${({ width }) => shadows[width]};
`

export default Elevation
