import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

let globalHistory = null
let globalLocation = null

// in cases when we need to access the history object not from the react component
// this is the only reliable solution (in times of react-router v6)
export const getNavigate = () => globalHistory
export const getLocation = () => globalLocation

export const HistorySpy = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    globalLocation = location
  }, [location])

  useEffect(() => {
    globalHistory = navigate
  }, [navigate])

  return <div data-role="history-spy" />
}
