import { createReducer } from '@reduxjs/toolkit'

import {
  changeExpiredSoonFilter,
  getRequestsFilter,
  prepareHomeData,
  setRequestsExpand,
  syncHomeDataUpdate,
  updateDashboardsData,
} from '../actions/home'
import { logout } from '../actions/logout'
import { DATE_FILTERS, LOADING } from '../enums'
import { loadInitialReducerState } from '../store/persist'

const initialData = {
  active: LOADING,
  pending: LOADING,
  draft: LOADING,
  expired: LOADING,
  totalContracts: LOADING,
  signed: LOADING,
  requests: LOADING,
  events: LOADING,
  tasks: LOADING,
  expireSoon: LOADING,
  archived: LOADING,
  requestsIsExpanded: true,
  requestsFilter: getRequestsFilter(DATE_FILTERS.DAYS7),

  ...loadInitialReducerState('home', 'inhubber-redux'),
}
const reducer = createReducer(initialData, (builder) => {
  builder
    .addCase(prepareHomeData.pending, (state) => {
      return {
        ...state,
        requests: LOADING,
        active: LOADING,
        pending: LOADING,
        draft: LOADING,
        expired: LOADING,
        totalContracts: LOADING,
        archived: LOADING,
      }
    })
    .addCase(updateDashboardsData.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(syncHomeDataUpdate, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(changeExpiredSoonFilter.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(setRequestsExpand, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(logout, () => ({ ...initialData }))
})

export default reducer
