import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Button, Flex, Spinner } from '../../../atoms'
import WorkspaceDropdown from '../../Workspaces/WorkspaceDropdown'

import { setActiveWorkspace } from '../../../../actions/workspaces'
import { usePermissions } from '../../../../hooks'
import MenuItem from '../MenuItem'

const FolderItem = ({
  data,
  wsId,
  selected,
  workspaceId,
  folderId,
  more,
  onChange,
  spacing,
  location,
  unparentFolder,
  loading,
  className,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const dispatch = useDispatch()

  const { isPermissionEnabled, PERMISSIONS } = usePermissions({ workspaceId, folderId })
  const hasAccess =
    isPermissionEnabled(PERMISSIONS.FOLDER_ADD_SUBFOLDERS) ||
    isPermissionEnabled(PERMISSIONS.FOLDER_UPDATE_METADATA) ||
    isPermissionEnabled(PERMISSIONS.FOLDER_REMOVE_FOLDER)

  const dropdownIcon = expanded ? 'chevron-small-down' : 'chevron-small-right'

  const onClick = useCallback(() => {
    dispatch(setActiveWorkspace({ id: wsId, folderId: data?.id }))
  }, [data?.id, dispatch, wsId])

  const onExpandClick = useCallback(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      setExpanded(!expanded)
      setFirstLoad(false)
      if (typeof onChange === 'function') {
        onChange(!expanded)
      }
    },
    [expanded, onChange],
  )

  const onMoreClick = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  useEffect(() => {
    if (location && !expanded && firstLoad) {
      setExpanded(true)
      onChange(!expanded)
      setFirstLoad(false)
    }
  }, [expanded, firstLoad, location, onChange])

  if (loading)
    return (
      <LoadingContainer content="center">
        <Flex>
          <Spinner />
        </Flex>
      </LoadingContainer>
    )

  return (
    <MenuItem
      value={data?.attributes.name}
      icon={!unparentFolder ? 'folder' : 'folder-person'}
      onClick={onClick}
      offsetIconClick={onExpandClick}
      offsetIcon={more && dropdownIcon}
      offset={spacing + 1}
      tooltip
      data-test={`link_sidebar_workspace_folder-${data?.id}${more && '-canExpand'}`}
      className={className}
      {...props}
    >
      <WorkspaceDropdown
        workspaceId={workspaceId}
        folderId={folderId}
        enabled={hasAccess}
        onClick={onMoreClick}
        overlay
      />
    </MenuItem>
  )
}

const LoadingContainer = styled(Flex)`
  background: ${({ theme }) => theme.semantic.palette.surface['surface-secondary']};
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
  padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
  width: 100%;
  box-sizing: border-box;
`

export default styled(FolderItem)`
  ${WorkspaceDropdown} {
    margin: -4px 0;
    display: none;

    ${Button} {
      &:hover {
        background: transparent;
      }
    }
  }

  &:hover {
    ${WorkspaceDropdown} {
      display: flex;
    }
  }
`
