/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
import { createAsyncThunk } from '@reduxjs/toolkit'

import { callApi } from '../utils/callApi'
import { web3connect } from '../services/createWeb3Connectin'
import proofOutdated from '../services/proofOutdated/proofOutdated'
import filterResult from '../services/proofOutdated/filter_result'
import BlockChainService from '../services/blockchainService'
import { sendresult } from '../services/proofOutdated/sendresult'
import asyncify from '../runtime/asyncify'
import i18n from '../i18n'

import { createNotification } from './notifications'
import { validateAndFormatEmail } from '../services/functions/validateAndFormatEmail'
import documentType from '../hooks/documentType'

const signDocument = async ({ docId }) => {
  const metadata = await callApi({ endpoint: `documentsmetadata/${docId}` })

  const docHashback = metadata.data.attributes.documentHash

  const [web3] = await asyncify(web3connect)

  const userAddress = localStorage.getItem('user_address')
  const password = localStorage.getItem('user_secret')

  const [unlockAccountErr] = await asyncify(
    web3.eth.personal.unlockAccount,
    userAddress,
    password,
    null,
  )

  if (!(!unlockAccountErr || unlockAccountErr === false)) {
    throw new Error('UnexpectedError')
  }

  const [signErr, signRes] = await asyncify(web3.eth.sign, docHashback, userAddress)
  if (!(!signErr || signErr === false)) {
    throw new Error('UnexpectedError')
  }

  const result = {
    data: {
      type: 'signatures',
      attributes: {
        signatureValue: signRes,
      },
      relationships: {
        document: {
          data: {
            id: docId,
            type: 'documentsmetadata',
          },
        },
      },
    },
  }

  const postSignatureResponse = await callApi({
    endpoint: `signatures`,
    method: 'POST',
    applicationJson: true,
    body: result,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  })

  return postSignatureResponse
}

export const signDigitalAsset = createAsyncThunk(
  'digitalasset/sign',
  async ({ daId, docId, callback }, api) => {
    const digitalAsset = await callApi({
      endpoint: `digitalassets/${daId}?include=documents.signatures`,
    })
    const { included } = digitalAsset
    const documentIds = included.filter(
      (f) =>
        f.attributes.type === documentType.e_signature &&
        f.attributes.state === 'active' &&
        f.id === docId,
    )[0]

    try {
      const signature = await signDocument({ docId: documentIds.id })
      await updateProofs(signature)
    } catch (e) {
      const message = e?.message || 'UnexpectedError'
      const type = 'warning'
      // eslint-disable-next-line no-console
      console.error(e)
      api.dispatch(
        createNotification({
          value: i18n.t(message),
          state: type,
        }),
      )
    }

    const {
      data: { attributes },
    } = await callApi({
      endpoint: `digitalassets/${daId}?include=documents.signatures`,
    })

    // wait for redux set all data after return
    setTimeout(() => api.extra.sendCallback(callback), 42)

    return { daId, attributes }
  },
)

const updateProofs = async (proofs) => {
  const proof = proofOutdated(proofs)
  const _proofOutdated = filterResult(proof)
  if (_proofOutdated.length) {
    const userAddress = localStorage.getItem('user_address')
    const password = localStorage.getItem('user_secret')
    const bc = new BlockChainService({
      address: userAddress,
      password,
    })

    // eslint-disable-next-line camelcase
    const _proofOutdated_string = JSON.stringify(_proofOutdated)

    return new Promise((resolve) => {
      bc.createTransaction(
        _proofOutdated_string,
        // eslint-disable-next-line no-shadow
        (result, _proofOutdated) => {
          sendresult({
            result,
            _proofOutdated,
            finishFunc: () => {
              resolve()
            },
          })
        },
        _proofOutdated,
      )
    })
  }

  return null
}

export const getCanSign = async (emailAddress) => {
  const formattedEmail = validateAndFormatEmail(emailAddress)
  const { canSign } = await callApi({
    endpoint: `naturalpersons/${formattedEmail}/canSign`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })
  return canSign
}
