import React, { useCallback, useEffect, useState } from 'react'

import styled from 'styled-components'
import BaseInput from './BaseInput'

import { useInnerRef } from '../../../hooks'
import LabelField from '../LabelField'
import Flex from '../Flex'

const getInnerType = (type, showPassword) => {
  if (type !== 'password') {
    return type
  }

  return showPassword ? 'text' : 'password'
}

const TextInput = ({
  type,
  validator,
  validatorTrigger = 'change',
  transformation,
  i18n,
  variant,
  innerRef,
  value,
  onChange,
  onBlur,
  onFocus,
  onChangePasswordState,
  view,
  error,
  labelI18n,
  label,
  sideLabel,
  templateColumn,
  description,
  required,
  helpText,
  helpTextI18n,
  readOnly,
  width,
  children,
  disabled,
  containerWidth,
  borderless,
  className,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [validatorMessage, setValidatorMessage] = useState('')
  const innerType = getInnerType(type, showPassword)
  const passwordIconSrc = !showPassword ? '/img/icon_password.svg' : '/img/icon_password_off.svg'

  useInnerRef(
    () => ({
      validate: () => setValidatorMessage(validator ? validator(value) : ''),
      togglePassword: (state) => setShowPassword(state),
    }),
    innerRef,
  )

  useEffect(() => {
    if (error === true) {
      setValidatorMessage(validator ? validator(value) : '')
    } else if (error === false) {
      setValidatorMessage('')
    }
  }, [error, validator, value])

  const labelProps = {
    labelI18n,
    label,
    error: error || !!validatorMessage,
    errorMessageI18n: validatorMessage,
    sideLabel,
    templateColumn,
    description,
    required,
    helpText,
    helpTextI18n,
    width: containerWidth,
  }

  const onInnerChange = useCallback(
    (e) => {
      const newValue =
        typeof transformation === 'function' ? transformation(e.target.value) : e.target.value

      if (validatorTrigger === 'change') {
        setValidatorMessage(validator ? validator(newValue) : '')
      }
      if (validatorTrigger === 'blur') {
        setValidatorMessage('')
      }
      if (typeof onChange === 'function') {
        e.target.value = newValue
        onChange(e)
      }
    },
    [transformation, validatorTrigger, onChange, validator],
  )

  const innerOnBlur = useCallback(
    (e) => {
      if (validatorTrigger === 'blur') {
        setValidatorMessage(validator ? validator(e.target.value) : '')
      }
      if (typeof onBlur === 'function') {
        onBlur(e)
      }
    },
    [validatorTrigger, onBlur, validator],
  )

  const onClickPasswordState = useCallback(() => {
    setShowPassword(!showPassword)
    if (typeof onChangePasswordState === 'function') {
      onChangePasswordState(!showPassword)
    }
  }, [onChangePasswordState, showPassword])

  let Container = BaseInput

  if (view === 'card') {
    Container = CardInput
  }

  const innerOnFocus = useCallback(() => {
    if (typeof onFocus === 'function') {
      onFocus()
    }
  }, [onFocus])

  return (
    <LabelField {...labelProps} className={className}>
      <InputContainer
        disabled={disabled}
        readOnly={readOnly}
        $error={!!validatorMessage || error}
        width={width}
        $borderless={borderless}
      >
        <Container
          type={innerType}
          onChange={onInnerChange}
          onBlur={innerOnBlur}
          onFocus={innerOnFocus}
          $error={!!validatorMessage || error}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          {...props}
        />
        {type === 'password' && (
          <img
            src={passwordIconSrc}
            onClick={onClickPasswordState}
            data-test={`icon_password-${showPassword ? 'visible' : 'hidden'}`}
          />
        )}
        {children}
      </InputContainer>
    </LabelField>
  )
}

export const CardInput = styled(BaseInput)`
  &:not(:focus) {
    font-weight: 500;
    border: 1px solid transparent;
    &:hover {
      background-color: ${({ theme }) => theme.palette.background.primary};
      border: 1px solid transparent;
      cursor: pointer;
    }
  }
`

const InputContainer = styled(Flex)`
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.radius['input-border-radius']};
  background-color: ${({ theme, disabled, readOnly }) =>
    disabled || readOnly
      ? theme.component.input.surface['input-color-background-disabled']
      : theme.component.input.surface['input-color-surface']};
  width: ${({ width }) => width || '100%'};
  padding: ${({ theme }) => `0 ${theme.space['input-padding-horizontal']}`};
  gap: ${({ theme }) => theme.space['input-gap']};
  border: ${({ $error, theme, disabled, readOnly, $borderless }) => {
    if ($borderless) return undefined
    if (disabled || readOnly) {
      return `1px solid ${theme.component.input.border['input-color-border-disabled']}`
    }
    if ($error) {
      return `1px solid ${theme.component.input.border['input-color-border-error']}`
    }
    return `1px solid ${theme.component.input.border['input-color-border']}`
  }};
  :hover {
    border: ${({ $error, theme, disabled, readOnly, $borderless }) => {
      if ($borderless) return undefined
      if (disabled || readOnly) {
        return `1px solid ${theme.component.input.border['input-color-border-disabled']}`
      }
      return $error
        ? `1px solid ${theme.component.input.border['input-color-border-error']}`
        : `1px solid ${theme.component.input.border['input-color-border-hover']}`
    }};
  }

  img {
    cursor: pointer;
  }

  :focus,
  :focus-within {
    border: ${({ theme, readOnly, $borderless }) =>
      !readOnly &&
      !$borderless &&
      `1px solid ${theme.component.input.border['input-color-border-active']}`};
  }
`

export default styled(TextInput)`
  input,
  input:hover,
  input:focus {
    border: none;
    background: transparent;
    padding: ${({ theme }) => `${theme.space['input-padding-vertical']} 0`};
  }
`
