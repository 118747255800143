export default {
  en: {
    standardContract: 'Standard contract',
    standardContractHint: 'Can be signed using the Inhubber Electronic Signature',
    qESContract: 'QES contract',
    qESContractHint:
      'Can be signed using a Qualified Electronic Signature of same legal power as a hand-written signature',
  },
  de: {
    standardContract: 'Standardvertrag',
    standardContractHint: 'Kann mit der elektronischen Signatur von Inhubber unterzeichnet werden',
    qESContract: 'QES Vertrag',
    qESContractHint:
      'Kann mit einer Qualifizierten Elektronischen Signatur unterzeichnet werden, die dieselbe Rechtskraft hat wie eine handschriftliche Unterschrift',
  },
  fr: {
    standardContract: 'Contrat Standart',
    standardContractHint: "Peut être signé en utilisant la signature electronique d'Inhbber",
    qESContract: 'Contrat QES',
    qESContractHint:
      "Peut être signé en utilisant une signature électronique ayant le même pouvoir légal qu'une signature manuelle",
  },
}
