const container = new Map()

export const setEncryptionPair = (publicKey, privateKey) => {
  const key = typeof publicKey === 'string' ? publicKey : publicKey.toString('hex')
  const value = typeof privateKey === 'string' ? privateKey : privateKey.toString('hex')

  container.set(key, value)
}

export const getPrivateKey = (publicKey) => {
  const key = typeof publicKey === 'string' ? publicKey : publicKey.toString('hex')
  return container.get(key)
}

export const getEncryptionPairCount = () => {
  return container.size
}

export const resetEncryptionSharingMap = () => container.clear()
