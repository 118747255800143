export default {
  Required: 'Champ requis',
  WrongCredentials: 'Mauvais titre de compétence',
  WrongPassword: 'Mauvais mot de passe',
  DuringRegistration:
    "Un erreur inattendue s'est produite. veuillez contacter notre service d'assistance : support@inhubber.com",
  AlreadyRegistered: "L'utilisateur avec cette adresse e-mail est déjà enregistré.",
  RegistrationNotPossible: 'Registration not possible',
  alreadyInvited: 'La personne corresponant à cet email sera invité',
  RightNow:
    "En ce moment, ce contrat est en train d'être signé par une autre personne. Veuillez réessayer dans une minute.",
  MaxFileSize: 'La taille maximalle du fischier est de 50 MB',
  UserWith: "L'utilisateur avec cet e-mail est déjà enregistré",
  PleaseConfirm: "Veuillez d'abord confirmer votre compte en utilisant le lien d'activation",
  PleaseUse: "veuillez utiliser le lien d'activation figurant dans l'e-mail d'invitation",
  UnexpectedError: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard",
  ConnectionError:
    "Impossible de se connecter au service. Veuillez vous assurer que vous êtes connecté à l'internet et que votre pare-feu autorise toutes les connexions nécessaires.",
  FirstName: 'Prénom',
  Surname: 'Nom',
  RepeatPassword: 'Répéter le mot de passe',
  wrongBrowser:
    "Pour l'instant, notre application n'est disponible que pour les navigateurs Mozilla Firefox, Microsoft Edge et Google Chrome. Nous vous demandons de faire preuve de compréhension.",
  enter_valid: 'Email de la personne invitée',
  admin: 'Admin',
  InvalidSecurityKey: 'Clé de sécurité non valid',
  search: 'Rechercher',
  Select_date: 'Choisir une date',
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May: 'Mai',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Decembre',
  Jan: 'Jan',
  Feb: 'Fev',
  Mar: 'Mar',
  Apr: 'Avr',
  Jun: 'Juin',
  Jul: 'Juil',
  Aug: 'Août',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  Sunday: 'dimanche',
  Monday: 'lundi',
  Tuesday: 'mardi',
  Wednesday: 'mercredi',
  Thursday: 'jeudi',
  Friday: 'venedredi',
  Saturday: 'samedi',
  MON: 'LUN',
  TUE: 'MAR',
  WED: 'MER',
  THU: 'JEU',
  FRI: 'VEN',
  SAT: 'SAM',
  SUN: 'DIM',
  Today: "Aujourd'hui",
  New_Contract: 'Nouveau Contrat',
  Document: 'Document',
  InvalidDateFormat: 'Format de la date est invalide',
  existsAccountType: "Le type de compte sélectionné existe pour l'utilisateur",
  yourSession: 'Votre session a expiré. Veuillez vous reconnecter',
  DraftSaved: 'Toutes les modifications seront enregistrées automatiquement',
  ContractCreated: 'Contrat créé avec succès',
  dateOfBirth: 'Date de naissance',
  placeOfBirth: 'Lieu de naissance',
}
