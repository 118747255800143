import styled from 'styled-components'

import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import Flex from './Flex'
import {
  desktopSidebarEnabledSelector,
  mobileEnabledSelector,
  mobileSidebarEnabledSelector,
} from '../../store/selectors'

const SidebarGrid = ({ children, ...props }) => {
  const mobileEnabled = useSelector(mobileEnabledSelector)
  const desktopSidebar = useSelector(desktopSidebarEnabledSelector)
  const mobileSidebar = useSelector(mobileSidebarEnabledSelector)

  const sidebarEnabled = useMemo(() => {
    return mobileEnabled ? mobileSidebar : desktopSidebar
  }, [desktopSidebar, mobileEnabled, mobileSidebar])

  return (
    <Container $sidebarEnabled={sidebarEnabled} {...props}>
      <Flex align="stretch" direction="column">
        {children}
      </Flex>
    </Container>
  )
}

const Container = styled(Flex)`
  width: ${({ $sidebarEnabled }) => (!$sidebarEnabled ? '0' : undefined)};
  overflow: ${({ $sidebarEnabled }) => (!$sidebarEnabled ? 'hidden' : undefined)};
`

export default styled(SidebarGrid)`
  transition: width 250ms ease-out;
  align-items: stretch;
  > div {
    width: 464px;
    min-width: 464px;
    box-sizing: border-box;
    border-left: 1px solid ${({ theme }) => theme.semantic.palette.border.border};
  }

  @media (max-width: 783px) {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: ${({ theme }) => theme.semantic.palette.surface.surface};
    z-index: 11;
    > div {
      width: auto;
      max-width: 464px;
      min-width: auto;
    }
  }

  @media (max-width: 464px) {
    left: 0;
    > div {
      width: 100%;
    }
  }
`
