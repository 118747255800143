import { useSelected, useFocused } from 'slate-react'
import styled from 'styled-components'

const Element = (props) => {
  const { attributes, children, element } = props

  switch (element.type) {
    case 'image':
      return <Image {...props} />
    default:
      return <p {...attributes}>{children}</p>
  }
}

export default Element

const BaseImage = ({ attributes, children, element, className }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes} className={className}>
      <div contentEditable={false}>
        <img
          src={element.url}
          style={{ boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}` }}
        />
      </div>
      {children}
    </div>
  )
}

const Image = styled(BaseImage)`
  img {
    display: block;
    max-width: 100%;
    max-height: 20em;
  }
`
