import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ElementTooltip, SvgMask, Typography } from '../atoms'
import { TASKS_STATE } from '../../enums'

const ActionButton = ({ labelsI18n = {}, tooltipI18n, taskState, ...props }) => {
  if (tooltipI18n) {
    return (
      <ElementTooltip
        tooltip={
          <Typography variant="12 medium" i18n={tooltipI18n}>
            {tooltipI18n}
          </Typography>
        }
        options={{
          placement: 'top',
        }}
      >
        <Button width="fit-content" i18n={labelsI18n[taskState]} {...props} />
      </ElementTooltip>
    )
  }
  return <Button width="fit-content" i18n={labelsI18n[taskState]} {...props} />
}

ActionButton.propTypes = {
  taskState: PropTypes.string,
  labelsI18n: PropTypes.object,
}

const actionColors = {
  [TASKS_STATE.OPEN]: {
    background: ({ theme }) => theme.palette.background.white,
    color: ({ theme }) => theme.palette.type.primary,
  },
  [TASKS_STATE.PARTIAL]: {
    background: ({ theme }) => theme.palette.accent.successLight,
    color: ({ theme }) => theme.palette.accent.success,
  },
  [TASKS_STATE.COMPLETE]: {
    background: ({ theme }) => theme.palette.accent.success,
    color: ({ theme }) => theme.palette.background.white,
  },
}

export default styled(ActionButton)`
  background: ${({ taskState }) => actionColors[taskState].background};
  border: 1px solid ${({ theme, taskState }) =>
    taskState !== TASKS_STATE.OPEN ? theme.palette.accent.success : theme.palette.divider};

  &:hover {
    background: ${({ theme, taskState }) =>
      taskState === TASKS_STATE.OPEN
        ? theme.palette.accent.successLight
        : actionColors[taskState].background};
    border: 1px solid ${({ theme }) => theme.palette.accent.success};

    > ${Typography} {
      color: ${({ theme, taskState }) =>
        taskState === TASKS_STATE.OPEN
          ? theme.palette.accent.success
          : actionColors[taskState].color};
    };

    ${SvgMask} {
      background-color: ${({ theme, taskState }) =>
        taskState === TASKS_STATE.OPEN
          ? theme.palette.accent.success
          : actionColors[taskState].color};
    };
  }
}

> ${Typography} {
  color: ${({ taskState }) => actionColors[taskState].color};
}

${SvgMask} {
  background-color: ${({ taskState }) => actionColors[taskState].color};
}
`
