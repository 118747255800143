/* eslint-disable react/display-name */
import compact from 'lodash/compact'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import ActivityTypes from './ActivityTypes'
import { Typography } from '../../atoms'

import format from '../../../utils/formatDate'

export { default as ActivityTypes } from './ActivityTypes'

const getPersonName = ({ firstName, lastName, emailAddress }) => {
  if (lastName && firstName) {
    return `${firstName} ${lastName}`
  }

  return emailAddress
}

export const generateFromCreatedBy = ({ createdBy, created }) => {
  const person = createdBy.attributes
  const date = new Date(created)

  return {
    id: createdBy.id,
    date,
    timestamp: created,
    type: ActivityTypes.CONTRACT_CREATED,
    group: format(date, 'dd MMMM yyyy'),
    view: () => (
      <div>
        <ActionTypography variant="body 13 regular">
          <Trans
            i18nKey={`activity:${ActivityTypes.CONTRACT_CREATED}`}
            values={{ userName: getPersonName(person) }}
            components={{ span: <span /> }}
          />
        </ActionTypography>
      </div>
    ),
  }
}

export const generateFromAccessGrants = ({
  accessGrants = [],
  naturalPersons = [],
  sharepointEmails = [],
  createdById,
}) => {
  return compact(
    accessGrants.map(({ attributes: { type, created }, id }, i) => {
      const person = naturalPersons[i]?.attributes || {}
      const createdBy = naturalPersons[i]?.id === createdById

      if (sharepointEmails.indexOf(person.emailAddress) !== -1) {
        return undefined
      }

      if ((type === 'view' || type === 'own') && !createdBy) {
        const date = new Date(created)

        return {
          id,
          date,
          timestamp: created,
          type: ActivityTypes.INVITED_VIEW,
          group: format(date, 'dd MMMM yyyy'),
          view: () => (
            <div>
              <ActionTypography variant="body 13 regular">
                <Trans
                  i18nKey={`activity:${ActivityTypes.INVITED_VIEW}`}
                  values={{ userName: getPersonName(person) }}
                  components={{ span: <span /> }}
                />
              </ActionTypography>
            </div>
          ),
        }
      }

      if (type === 'sign') {
        const date = new Date(created)
        return {
          id,
          date,
          timestamp: created,
          type: ActivityTypes.INVITED_SIGN,
          group: format(date, 'dd MMMM yyyy'),
          view: () => (
            <div>
              <ActionTypography variant="body 13 regular">
                <Trans
                  i18nKey={`activity:${ActivityTypes.INVITED_SIGN}`}
                  values={{ userName: getPersonName(person) }}
                  components={{ span: <span /> }}
                />
              </ActionTypography>
            </div>
          ),
        }
      }

      return undefined
    }),
  )
}

export const generateFromTemplate = ({
  activities = [],
  activitiesPersons = [],
  documentsmetadata,
}) => {
  return compact(
    activities.map((data, i) => {
      const {
        attributes: { type, executedAt, payload, resourceId, attribute, resourceType },
        relationships: { executor },
        id,
      } = data
      const executorId = executor.data.id
      const person = compact(activitiesPersons).find(({ id }) => id === executorId)
      const document = documentsmetadata[resourceId]
      const newValue = payload?.newValue?.label
      const oldValue = payload?.oldValue?.label
      const documentTitle = document?.attributes.name

      const templateType =
        type === 'change' && resourceType === 'digitalassets' ? 'change_owner' : type

      if (person?.attributes) {
        const date = new Date(executedAt)
        return {
          id,
          date,
          timestamp: executedAt,
          type,
          group: format(date, 'dd MMMM yyyy'),
          attributeType: attribute,
          view: () => (
            <div>
              <ActionTypography variant="body-large-02">
                <Trans
                  i18nKey={`activity:${templateType}`}
                  values={{
                    userName: getPersonName(person.attributes),
                    newValue,
                    oldValue,
                    resourceLabelPath: documentTitle,
                    attribute,
                    resourceType,
                    context: documentTitle && 'title',
                  }}
                  components={{ span: <span /> }}
                />
              </ActionTypography>
            </div>
          ),
        }
      }
      return undefined
    }),
  )
}

const ActionTypography = styled(Typography)`
  span {
    ${({ theme }) => theme.typography['body-large-01']};
    display: inline;
  }
`
