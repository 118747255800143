import styled from 'styled-components'

import Flex from './Flex'

const MainContainerGrid = ({ children, className }) => {
  return (
    <Flex className={className}>
      <Flex>{children}</Flex>
    </Flex>
  )
}

export default styled(MainContainerGrid)`
  grid-row: ${({ $gridRow }) => $gridRow || 4};
  grid-column: 1 / -1;
  align-items: stretch;
  overflow-y: ${({ $fullContent }) => ($fullContent ? 'hidden' : 'auto')};
  > div {
    flex: auto;
    align-items: stretch;
    position: relative;
  }
`
