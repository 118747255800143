export default {
  en: {
    manageName: 'Manage your company name',
    companyName: 'Company name',
    companyLanguage: 'Company language',
    selectDefaultLanguage: 'Select the default company language',
    CompanyInfo: 'Company info',
    ThisInformationWillBeIncluded:
      'This information will be included on all billing invoices on your account.',
    phoneNumber: 'Phone number',
    StreetAddress: 'Street address',
    SuiteUnit: 'Suite / Unit',
    City: 'City',
    State: 'State',
    Postal: 'Postal code',
    Country: 'Country',
    chooseAnOption: 'Choose an option…',
    paymentMethods: 'Payment methods',
    paymentMethodsDescription:
      "New payment methods won't be used automatically to pay for your subscriptions. You will be able to choose a payment method for future payments.",
    billingEmail: 'Billing email',
    IBANNumber: 'IBAN number',
    paymentNameError: "You'll need to include a name.",
    paymentEmailError: "You'll need to include an email.",
    paymentIBANError: 'This IBAN number seems to be incomplete.',
    inviteMembers: 'Invite members',
    alreadyMember: 'Already a member.',
    emailDomainsInvitedUsers:
      'Email domains of invited users must match one of the company domains.',
    inviteSent: 'Invite sent',
    invitedUserWillBeNotified: 'Invited user will be notified by email to join {{companyName}}.',
    inviteResend: 'Invite resend',
    invitationHasBeenResent: 'Invitation for {{email}} has been resent.',
  },
  de: {
    manageName: 'Firmennamen verwalten',
    companyName: 'Firmenname',
    companyLanguage: 'Firmensprache',
    selectDefaultLanguage: 'Standard-Firmensprache festlegen',
    CompanyInfo: 'Information zum Unternehmen',
    ThisInformationWillBeIncluded:
      'Diese Information wird auf allen Rechnungen deines Accounts erscheinen.',
    phoneNumber: 'Telefonnummer ',
    StreetAddress: 'Straßenadresse',
    SuiteUnit: 'Suite / Einheit',
    City: 'Stadt',
    State: 'Bundesland',
    Postal: 'Postleitzahl',
    Country: 'Land',
    chooseAnOption: 'Wähle eine Option aus…',
    paymentMethods: 'Zahlungsmöglichkeiten',
    paymentMethodsDescription:
      'Neue Zahlungsmöglichkeiten werden nicht automatisch für die Bezahlung deines Abonnements verwendet. Du wirst in der Lage sein, eine Bezahlmethode für zukünftige Zahlungen zu wählen.',
    billingEmail: 'E-Mail für die Rechnungsstellung',
    IBANNumber: 'IBAN-Nummer',
    paymentNameError: 'Du musst einen Namen angeben.',
    paymentEmailError: 'Du musst eine E-Mail angeben.',
    paymentIBANError: 'Diese IBAN-Nummer scheint unvollständig zu sein.',
    inviteMembers: 'Mitglieder einladen',
    alreadyMember: 'Bereits Mitglied.',
    emailDomainsInvitedUsers:
      'Die E-Mail-Domänen der eingeladenen Benutzer müssen mit einer der Unternehmensdomänen übereinstimmen.',
    inviteSent: 'Einladung versendet',
    invitedUserWillBeNotified:
      'Eingeladener Benutzer wird per E-Mail benachrichtigt, {{companyName}} beizutreten.',
    inviteResend: 'Einladung erneut versendet',
    invitationHasBeenResent: 'Einladung für {{email}} wurde erneut verschickt.',
  },
  fr: {
    manageName: 'Gérer le nom de votre enterprise',
    companyName: "Nom de l'enterprise",
    companyLanguage: "Langue de l'enterprise",
    selectDefaultLanguage: "Sélectionner la langue par défaut de l'enterprise",
    CompanyInfo: "Informations sur l'enterprise",
    ThisInformationWillBeIncluded:
      'Ces informations figureront sur toutes les factures de votre compte.',
    phoneNumber: 'Numéro de téléphone',
    StreetAddress: 'Adresse postale',
    SuiteUnit: 'Suite / Unité',
    City: 'Ville',
    State: 'État',
    Postal: 'Code postale',
    Country: 'Pays',
    paymentMethods: 'modes de paiement',
    paymentMethodsDescription:
      'Les nouveaux modes de paiement ne seront pas utilisés automatiquement pour payer vos abonnements. Vous pourrez choisir un mode de paiement pour les paiements futurs.',
    IBANNumber: 'Numéro IBAN',
    paymentNameError: 'Vous devez inclure un nom.',
    paymentEmailError: 'Vous devez inclure un email...',
    paymentIBANError: 'Ce numéro IBAN semble incomplet.',
    chooseAnOption: 'Choisir une option...',
    billingEmail: 'Courriel de facturation',
  },
}
