const getValueLength = (value) => {
  if (typeof value === 'string') {
    return value.length
  }

  if (Array.isArray(value)) {
    return value.map((v) => getValueLength(v)).reduce((a, b) => a + b, 0)
  }

  if (typeof value === 'object') {
    if (value.children) {
      return getValueLength(value.children)
    }
    return value.text ? value.text.length : 0
  }

  return 0
}

export default getValueLength
