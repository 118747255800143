import styled, { useTheme } from 'styled-components'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { Persona, Typography, Spacing, Flex, SvgMask, ElementTooltip } from '../atoms'
import VerifiedIcon from './VerifiedIcon'

const dictionaries = {
  primaryLabelSize: {
    xsmall: 'body-small-01',
    small: 'body-medium-01',
    medium: 'body-large-01',
    big: 'header-medium',
  },
  secondaryLabelSize: {
    xsmall: 'body-small-01',
    small: 'body-small-02',
    medium: 'body-medium-02',
    big: 'body-large-02',
  },
  tetriaryLabelSize: {
    xsmall: 'body-small-01',
    small: 'body-small-02',
    medium: 'body-medium-02',
    big: 'body-large-02',
  },
}

const getDictionary = (name) => {
  return (size) => {
    const result = dictionaries[name][size]

    if (result) {
      return result
    }

    throw new Error(`Invalid persona size: ${size}`)
  }
}

const getPrimaryLabelSize = getDictionary('primaryLabelSize')
const getSecondaryLabelSize = getDictionary('secondaryLabelSize')
const getTetriaryLabelSize = getDictionary('tetriaryLabelSize')

const LabelsContainer = styled(Flex)`
  width: inherit;
  overflow: hidden;

  > ${Typography} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: ${({ $template }) => $template};
  align-items: center;
  > ${Typography} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const PersonaDetails = ({
  size,
  color,
  firstName,
  lastName,
  initials,
  owner,
  labels = [],
  active,
  i18n,
  personaId,
  seed,
  verified,
  verifiedPosition,
  info,
  disabled,
  ...props
}) => {
  const { t } = useTranslation()

  const personaProps = {
    size,
    color,
    firstName,
    lastName,
    owner,
    initials: initials || '',
    seed,
    verified: verifiedPosition === 'persona' ? verified : null,
    label: labels[0],
  }

  const gridRows = useMemo(
    () =>
      [
        '1fr',
        verified && verifiedPosition !== 'persona' ? '5px 16px' : undefined,
        info ? '4px 16px' : undefined,
      ].join(' '),
    [info, verified, verifiedPosition],
  )

  return (
    <div {...props} data-test={`personaDetails${personaId ? `_${personaId}` : ''}`}>
      <div>
        <Persona {...personaProps} />
        <LabelsContainer direction="column" align="flex-start">
          <Grid $template={gridRows}>
            <Typography
              as="div"
              variant={getPrimaryLabelSize(size)}
              data-test="personaDetails_name"
              color={disabled ? 'disabled' : 'primary'}
            >
              {!lastName ? t('pendingLoading') : `${firstName} ${lastName}`}
            </Typography>
            {verified && verifiedPosition !== 'persona' && (
              <>
                <Spacing size={5} />
                <VerifiedIcon />
              </>
            )}
            {info && (
              <>
                <Spacing size={4} />
                <OwnerInfo info={info} />
              </>
            )}
          </Grid>
          {size !== 'xsmall' && (
            <Grid $template="1fr">
              {(labels || []).map((label, i) => (
                <Typography
                  as="div"
                  variant={i === 0 ? getSecondaryLabelSize(size) : getTetriaryLabelSize(size)}
                  key={label}
                  color={disabled ? 'disabled' : 'secondary'}
                  i18n={i18n}
                >
                  {label}
                </Typography>
              ))}
            </Grid>
          )}
        </LabelsContainer>
      </div>
    </div>
  )
}

const OwnerInfo = ({ info }) => {
  const { palette } = useTheme()

  return (
    <div>
      <ElementTooltip
        enabled
        tooltip={<Typography variant="12 medium">{info}</Typography>}
        options={{
          placement: 'top',
          strategy: 'fixed',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 12],
              },
            },
          ],
        }}
      >
        <SvgMask icon="info-circle" color={palette.type.secondary} />
      </ElementTooltip>
    </div>
  )
}

export default styled(PersonaDetails)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;

  > div {
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
    gap: ${({ theme, size }) =>
      size === 'xsmall'
        ? theme.semantic.space['space-4x-small']
        : theme.semantic.space['space-2x-small']};
  }

  background: ${({ active, theme }) => (active ? theme.palette.divider : 'inherit')};
`
