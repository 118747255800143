import documentType from '../hooks/documentType'

export default {
  en: {
    digitalassets: 'contract',
    documents: 'document',
    documentsmetadata: 'document',
    type: 'type',
    [documentType.e_signature]: 'E-signature',
    [documentType.signed_offline]: 'Signed offline',
    [documentType.other]: 'Other',
    ownedBy: 'owner',
  },

  de: {
    digitalassets: 'Vertrag',
    documents: 'Dokument',
    documentsmetadata: 'Dokument',
    type: 'Typ',
    [documentType.e_signature]: 'E-Signatur',
    [documentType.signed_offline]: 'Offline signiert',
    [documentType.other]: 'Anderes',
    ownedBy: 'Inhaber',
  },
  fr: {
    digitalassets: 'contrat',
    documents: 'document',
    documentsmetadata: 'document',
    type: 'type',
    [documentType.e_signature]: 'E-Signature',
    [documentType.signed_offline]: 'Signé offline',
    [documentType.other]: 'Autre',
    ownedBy: 'propriètaire',
  },
}
