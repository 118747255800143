import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import Color from 'color'

import SvgMask from './SvgMask'
import Typography from './Typography'
import Spinner from './Spinner'
import Flex from './Flex'
import BasePopup from './Modal/BasePopup'

const BaseButton = ({
  i18n,
  icon,
  iconAfter,
  iconSize,
  variant,
  children,
  plain,
  active,
  disabled,
  tone,
  process,
  btnType,
  ...props
}) => {
  if (process) {
    return (
      <StyledButton
        {...props}
        process={process}
        type="button"
        disabled={disabled === 'fake' ? undefined : disabled}
      >
        <Spinner animation={tone === 'critical' ? 'critical' : undefined} />
      </StyledButton>
    )
  }
  if (plain) {
    return (
      <StyledButton {...props} type="button" disabled={disabled === 'fake' ? undefined : disabled}>
        {children}
      </StyledButton>
    )
  }

  return (
    <StyledButton
      type={btnType || 'button'}
      {...props}
      disabled={disabled === 'fake' ? undefined : disabled}
    >
      {icon && <SvgMask icon={icon} size={iconSize} />}
      {(i18n || children) && (
        <Typography i18n={i18n} variant={variant || 'body-large-03'}>
          {children}
        </Typography>
      )}
      {iconAfter && <SvgMask icon={iconAfter} size={iconSize} />}
    </StyledButton>
  )
}

BaseButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.any,
  i18n: PropTypes.string,
  icon: PropTypes.string,
  iconAfter: PropTypes.string,
  iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  plain: PropTypes.bool,
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tone: PropTypes.string,
  process: PropTypes.bool,
}

const setWidth = (width) => {
  if (!width) {
    return '100%'
  }
  return typeof width === 'number' ? `${width}px` : width
}

const Button = ({ secondary, tertiary, iconButton, type, ...props }) => {
  let Container = PrimaryButton

  if (type === 'secondary' || secondary) {
    Container = SecondaryButton
  } else if (type === 'split') {
    Container = SplitButton
  } else if (type === 'tertiary' || tertiary) {
    Container = TertiaryButton
  } else if (type === 'icon' || iconButton) {
    Container = IconButton
  }

  return <Container {...props} />
}

const StyledButton = styled.button`
  font-family:
    Inter,
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  box-sizing: border-box;
  position: relative;
  border: none;
  outline: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => setWidth(width)};
  border-radius: ${({ theme }) => theme.radius['button-border-radius']};
  padding: ${({ theme }) => theme.space['button-primary-padding']};
  transition: background 300ms ease-out;
  pointer-events: ${({ disabled, process }) => (disabled || process) && 'none'};

  ${Typography} {
    padding: 0 ${({ theme }) => theme.semantic.space['space-2x-small']};
  }
`

const PrimaryButton = styled(BaseButton)`
  background: ${({ theme, disabled, tone, background }) => {
    if (tone === 'critical') {
      if (disabled) {
        return theme.component.button.surface['button-primary-critical-tone-color-surface-disabled']
      }
      return theme.component.button.surface['button-primary-critical-tone-color-surface']
    }
    if (disabled) {
      if (background) {
        return Color(background).lighten(0.4)
      }
      return theme.component.button.surface['button-primary-color-surface-disabled']
    }
    if (background) {
      return background
    }
    return theme.component.button.surface['button-primary-color-surface']
  }};

  &:hover {
    background: ${({ theme, tone, background }) => {
      if (background) {
        return Color(background).darken(0.07)
      }

      if (tone === 'critical')
        return theme.component.button.surface['button-primary-critical-tone-color-surface-hover']
      return theme.component.button.surface['button-primary-color-surface-hover']
    }};

    cursor: ${({ disabled }) => !disabled && 'pointer'};
  }

  ${Typography} {
    color: ${({ theme }) => theme.component.button.label['button-primary-color-label']};
  }

  ${SvgMask} {
    background-color: ${({ theme }) => theme.component.button.icon['button-primary-color-icon']};
  }
`

export const SecondaryButton = styled(Button)`
  background: ${({ theme, disabled }) =>
    disabled
      ? theme.component.button.surface['button-secondary-color-surface-disabled']
      : theme.component.button.surface['button-secondary-color-surface']};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled
          ? theme.component.button.border['button-secondary-color-border-disabled']
          : theme.component.button.border['button-secondary-color-border']};
  }

  &:hover {
    background: ${({ theme }) =>
      theme.component.button.surface['button-secondary-color-surface-hover']};
    &::after {
      border: ${({ theme, disabled }) =>
        !disabled &&
        `1px solid ${theme.component.button.border['button-secondary-color-border-hover']}`};
    }
  }

  ${Typography} {
    color: ${({ theme }) => theme.component.button.label['button-secondary-color-label']};
  }

  ${SvgMask} {
    background-color: ${({ theme }) => theme.component.button.icon['button-secondary-color-icon']};
  }
`

export const SplitButton = styled(
  ({
    additionalDisabled,
    mainDisabled,
    disabled,
    icon,
    process,
    additionalIcon,
    placement,
    overlay,
    additionalOnClick,
    onClick,
    onOpen,
    className,
    dataTest,
    menu,
    ...props
  }) => {
    const [visible, setVisible] = useState(false)
    const [target, setTarget] = useState(null)

    const openDropDown = () => {
      if (!visible && typeof onOpen === 'function') onOpen()
      setVisible(!visible)
    }

    if (process) {
      return <SecondaryButton process={process} {...props} />
    }
    return (
      <div ref={setTarget} className={className} data-test={dataTest}>
        <Flex>
          <BaseButton
            disabled={disabled || additionalDisabled}
            onClick={onClick}
            icon={additionalIcon}
            {...props}
          />
          <BaseButton
            disabled={disabled || mainDisabled}
            icon={icon}
            onClick={
              disabled
                ? undefined
                : (e) => {
                    e.stopPropagation()
                    openDropDown()
                  }
            }
          />
        </Flex>
        {visible && (
          <BasePopup
            target={target}
            options={{ placement: placement || 'bottom-end', strategy: 'fixed' }}
            spacing={4}
            overlay={overlay === undefined ? true : overlay}
            onClose={() => setVisible(false)}
            onClick={() => setVisible(false)}
            closeOnClick
          >
            {menu}
          </BasePopup>
        )}
      </div>
    )
  },
)`
  border-radius: ${({ theme }) => theme.radius['button-border-radius']};
  box-sizing: border-box;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled
          ? theme.component.button.border['button-secondary-color-border-disabled']
          : theme.component.button.border['button-secondary-color-border']};
    pointer-events: none;
  }
  &:hover {
    &::after {
      border: ${({ theme, disabled }) =>
        !disabled &&
        `1px solid ${theme.component.button.border['button-secondary-color-border-hover']}`};
    }
  }
  button {
    border-radius: 0;
    &:first-child {
      background: ${({ theme, disabled, additionalDisabled }) =>
        disabled || additionalDisabled
          ? theme.component.button.surface['button-secondary-color-surface-disabled']
          : theme.component.button.surface['button-secondary-color-surface']};
      :after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-right: 1px solid
          ${({ theme, disabled }) =>
            disabled
              ? theme.component.button.border['button-secondary-color-border-disabled']
              : theme.component.button.border['button-secondary-color-border']};
      }
    }
    &:last-child {
      background: ${({ theme, disabled, mainDisabled }) =>
        disabled || mainDisabled
          ? theme.component.button.surface['button-secondary-color-surface-disabled']
          : theme.component.button.surface['button-secondary-color-surface']};
    }
    &:hover {
      cursor: pointer;
      background: ${({ theme }) =>
        theme.component.button.surface['button-secondary-color-surface-hover']};
    }
  }
`

export const TertiaryButton = styled(Button)`
  padding: ${({ theme }) => theme.space['button-tertiary-padding']};
  background: transparent;

  &:hover {
    background: ${({ theme }) =>
      theme.component.button.surface['button-tertiary-color-surface-hover']};
    ${Typography} {
      color: ${({ theme }) => theme.component.button.label['button-tertiary-color-label-hover']};
    }

    ${SvgMask} {
      background-color: ${({ theme }) =>
        theme.component.button.icon['button-tertiary-color-icon-hover']};
    }
  }

  ${Typography} {
    padding: 0 ${({ theme }) => theme.semantic.space['space-4x-small']};
    color: ${({ theme }) => theme.component.button.label['button-tertiary-color-label']};
  }

  ${SvgMask} {
    background-color: ${({ theme, disabled }) =>
      disabled
        ? theme.component.button.icon['button-tertiary-color-icon-disabled']
        : theme.component.button.icon['button-tertiary-color-icon']};
  }
`

export const IconButton = styled(Button)`
  padding: ${({ theme }) => theme.space['button-icon-padding']};
  background: ${({ theme, selected }) =>
    selected ? theme.component.button.surface['button-icon-color-surface-hover'] : 'transparent'};

  ${SvgMask} {
    background-color: ${({ theme, disabled, selected }) => {
      if (disabled) return theme.component.button.icon['button-icon-color-icon-disabled']
      if (selected) return theme.component.button.icon['button-icon-color-icon-hover']
      return theme.component.button.icon['button-icon-color-icon']
    }};
  }
  width: fit-content;

  ${Typography} {
    display: none;
  }

  &:hover {
    background: ${({ theme }) => theme.component.button.surface['button-icon-color-surface-hover']};

    ${SvgMask} {
      background-color: ${({ theme }) =>
        theme.component.button.icon['button-icon-color-icon-hover']};
    }
  }
`

export default styled(Button)``
