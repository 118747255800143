import styled from 'styled-components'

import { RecognizeLinks, TextArea, TextInput } from '../atoms'

const RecognizeLinksInput = ({ value, textArea, className, ...props }) => {
  if (textArea) {
    return (
      <TextAreaContainer className={className}>
        <TextArea value={value} {...props} />
        <RecognizeLinks title={value} />
      </TextAreaContainer>
    )
  }

  return (
    <div className={className}>
      <TextInput value={value} {...props}>
        <RecognizeLinks title={value} />
      </TextInput>
    </div>
  )
}

const TextAreaContainer = styled.div`
  ${RecognizeLinks} {
    padding: 0 9px;
    top: 7px;
    bottom: 0;
    right: 0;
    line-height: 20px;
    > * {
      line-height: 20px;
    }
  }
  &:not(:focus-within) {
    color: transparent;
    ${RecognizeLinks} {
      display: block;
      opacity: 1;
    }
  }
`

export default styled(RecognizeLinksInput)`
  position: relative;
  margin: 0;
  box-sizing: border-box;

  ${RecognizeLinks} {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    left: 0;
    border-radius: 4px;
    display: none;
    white-space: pre-wrap;

    a {
      pointer-events: all;
    }
  }

  input {
    margin-bottom: 0;
  }

  input + ${RecognizeLinks} {
    padding: 0 9px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 20px);
  }

  input:not(:focus),
  textarea:not(:focus) {
    color: transparent;
    + ${RecognizeLinks} {
      display: block;
      opacity: 1;
    }
  }
`
