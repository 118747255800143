export default {
  en: {
    signatures: 'Signatures',
    name: 'NAME (FIRST, LAST):',
    date: 'DATE AND TIME (UTC):',
    digitalSignature: 'DIGITAL SIGNATURE:',
    blockchainTransaction: 'BLOCKCHAIN TRANSACTION:',
    email: 'E-MAIL ADDRESS:',
  },
  de: {
    signatures: 'Unterschriften',
    name: 'VOR- UND NACHNAME:',
    date: 'ZEITSTEMPEL (UTC):',
    digitalSignature: 'DIGITALE SIGNATUR:',
    blockchainTransaction: 'BLOCKCHAIN TRANSAKTION:',
    email: 'E-MAIL-ADRESSE:',
  },
  fr: {
    signatures: 'Signatures',
    name: 'NOM (PRÉNOM, NOM DE FAMILLE):',
    date: 'DATE ET HEURE (UTC):',
    digitalSignature: 'SIGNATURE NUMÉRIQUE:',
    blockchainTransaction: 'TRANSACTION BLOCKCHAIN:',
    email: 'ADRESSE E-MAIL:',
  },
}
