/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit'

import { updateIncluded } from '../actions/included'
import { patchDigitalAsset } from '../actions/digitalAssets'

export { updateIncluded } from '../actions/included'

const reducer = createReducer(
  {
    data: {},
    digitalassets: {},
    documentsmetadata: {},
    accessgrants: {},
    accounts: {},
    customfields: {},
    naturalpersons: {},
    signatures: {},
    workspaces: {},
    workspaceaccessgrants: {},
    workspacememberships: {},
    eventreminders: {},
    tasks: {},
    folders: {},
    tags: {},
    activities: {},
  },
  (builder) => {
    builder
      .addCase(updateIncluded, (state, action) => {
        const { payload = [] } = action
        payload.forEach((included) => {
          if (included?.type) {
            if (included.type.includes('customfield') || included.type.includes('smartfield')) {
              state.customfields[included.id] = included
            } else if (included.type.includes('tasks')) {
              state.tasks[included.id] = included
            } else {
              if (!state[included.type]) {
                state[included.type] = {}
              }
              state[included.type][included.id] = included
            }
          } else {
            state.data[included.id] = included
          }
        })
      })
      .addCase(patchDigitalAsset.fulfilled, (state, action) => {
        const { payload } = action
        const updatedData = {
          ...state.data,
          [payload.id]: { ...state.data[payload.id], attributes: payload.attributes },
        }

        return { ...state, data: updatedData }
      })
  },
)

export default reducer
