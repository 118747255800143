import { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import {
  Modal,
  Spacing,
  Typography,
  Flex,
  WorkspaceIcon,
  BaseInput,
  Button,
  Spinner,
  ValidationError,
} from '../../atoms'

import { useDispatchAsync } from '../../../hooks'
import { createWorkspace, getWorkspaces } from '../../../actions/workspaces'

const WorkspaceCreateModal = ({ visible, onClose, ...props }) => {
  const { palette } = useTheme()
  const { t } = useTranslation()
  const dispatchAsync = useDispatchAsync()
  const dispatch = useDispatch()

  // we use this color as default since we cannot calculate
  // real color from workspaceId at this moment
  const DEFAULT_WS_COLOR = palette.avatar[15]

  const [wsName, setWsName] = useState('')
  const [error, setError] = useState(null)
  const [isCreating, setIsCreating] = useState(false)

  const onChange = useCallback((e) => {
    setWsName(e.target.value)
    setError(null)
  }, [])

  const onSubmit = useCallback(async () => {
    const workspaceName = (wsName || '').trim()

    if (!workspaceName) {
      setError('workspace:workspaceNameRequired')
      return
    }

    setIsCreating(true)
    try {
      await dispatchAsync(createWorkspace, { title: workspaceName })
      setIsCreating(false)
      dispatch(getWorkspaces())
      if (typeof onClose === 'function') {
        onClose()
      }
    } catch (err) {
      setIsCreating(false)
    }
  }, [dispatchAsync, onClose, wsName, dispatch])

  useEffect(() => {
    if (!visible) {
      setWsName('')
      setError(null)
    }
  }, [visible])

  return createPortal(
    <Modal
      headerI18n="workspace:createWorkspace"
      width="360"
      visible={visible}
      onClose={onClose}
      buttons={
        <Button
          i18n={isCreating ? '' : 'Create'}
          onClick={isCreating ? undefined : onSubmit}
          width="100%"
          data-test="button_customWorkspace_modal_create"
          process={isCreating}
        >
          Create
        </Button>
      }
      type="oldModal"
      {...props}
    >
      <Spacing size="4" />
      <Typography variant="13" color="secondary" i18n="workspace:workspaceDescription">
        Organize your contracts by groups or teams in one place.
      </Typography>
      <Spacing size="24" />
      <Flex>
        <WorkspaceIcon color={DEFAULT_WS_COLOR} />
        <Spacing size="8" />
        <BaseInput
          autoFocus
          value={wsName}
          onChange={onChange}
          placeholder={t('workspace:workspaceName')}
          $error={!!error}
          data-test="input_customWorkspace_modal_workspaceName"
        />
      </Flex>
      {error && <ValidationError offset="none" i18n={error} />}
    </Modal>,
    document.body,
  )
}

export default styled(WorkspaceCreateModal)`
  ${BaseInput} {
    margin: 0;
  }

  ${ValidationError} {
    margin-left: 37px;
  }
`
