import styled, { useTheme } from 'styled-components'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex, Spacing, SvgMask, Typography } from '../atoms'

const ListItem = ({
  titleOnClick,
  containerOnClick,
  divider,
  title,
  titleI18n,
  label,
  children,
  link,
  onClick,
  labels,
  iconLabel,
  beforeIcon,
  small,
  info,
  labelTitle,
  offset,
  dragged,
  className,
  ...props
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <div className={className} onClick={onClick} {...props}>
      {offset > 0 && Array.from({ length: offset }, (_, index) => <Offset key={index} />)}
      {beforeIcon && (
        <SvgImageContainer content="center" $small={small} $dragged={dragged}>
          {beforeIcon}
        </SvgImageContainer>
      )}
      <DocumentContainer
        content="space-between"
        width="100%"
        $divider={divider}
        onClick={containerOnClick}
      >
        {(title || titleI18n || labels) && (
          <ContractTitle
            title={(titleI18n && t(titleI18n)) || title || labels}
            direction="column"
            align="flex-start"
          >
            {link ? (
              <Link to={link}>
                <Grid>
                  <Title i18n={titleI18n} data-test="typography_link_contracts_title">
                    {title}
                  </Title>
                  {labelTitle && (
                    <>
                      <Spacing size="4" />
                      {labelTitle}
                    </>
                  )}
                </Grid>
              </Link>
            ) : (
              <Grid>
                <Title
                  i18n={titleI18n}
                  onClick={(e) => {
                    if (typeof titleOnClick === 'function') titleOnClick(e)
                  }}
                  data-test="typography_contract_document_openDocument"
                >
                  {title}
                </Title>
                {labelTitle && (
                  <>
                    <Spacing size="4" />
                    {labelTitle}
                  </>
                )}
              </Grid>
            )}
            {iconLabel || label ? (
              <Grid>
                {iconLabel && (
                  <SvgMask icon={iconLabel} color={theme.semantic.palette.icon['icon-tertiary']} />
                )}
                <Typography variant="body-medium-02" color="secondary">
                  {label}
                </Typography>
                {labels}
              </Grid>
            ) : (
              labels
            )}
          </ContractTitle>
        )}
        {children}
      </DocumentContainer>
    </div>
  )
}

const SvgImageContainer = styled(Flex)`
  height: ${({ $small }) => ($small ? '48px' : '64px')};
  width: ${({ $small }) => ($small ? '48px' : '64px')};
  min-width: ${({ $small }) => ($small ? '48px' : '64px')};

  > ${SvgMask} {
    background-color: ${({ $dragged, theme }) => {
      if ($dragged) {
        return theme.semantic.palette.icon.icon
      }
      return theme.semantic.palette.icon['icon-secondary']
    }};
  }
`

const ContractTitle = styled(Flex)`
  flex: auto;
`

export const DocumentContainer = styled(Flex)`
  gap: ${({ theme }) => theme.semantic.space['space-small']};
  box-sizing: border-box;
  position: relative;

  &:after {
    content: '';
    left: 0;
    right: 0;
    height: 1px;
    bottom: 0;
    position: absolute;
    background: ${({ theme, $divider }) =>
      $divider && theme.semantic.palette.border['border-tertiary']};
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 4px auto;
  grid-template-rows: 1fr;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const Title = styled(Typography).attrs(() => ({ variant: 'body-large-01' }))`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`

const Offset = styled.div`
  width: 16px;
`

export default styled(ListItem)`
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: ${({ theme }) => theme.semantic.space['space-small']};
  padding-left: ${({ theme, beforeIcon }) => !beforeIcon && theme.semantic.space['space-small']};
  background: ${({ theme, selected }) =>
    selected ? theme.semantic.palette.surface['surface-selected'] : undefined};
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  height: ${({ small, beforeIcon }) => {
    if (!beforeIcon) {
      if (small) {
        return '48px'
      }
      return '64px'
    }
    return undefined
  }};

  &:hover {
    background: ${({ theme, selected, info }) =>
      !selected && !info && theme.component.card.surface['card-color-surface-hover']};
  }
`
