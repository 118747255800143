import { useEffect, useState } from 'react'
import { useMeasure } from 'react-use'
import styled from 'styled-components'
import { animated, useSpring } from '@react-spring/web'

import { Flex } from '../../../atoms'

const FolderContainer = ({ children, visible, ...props }) => {
  const [contentHeight, setContentHeight] = useState('0px')
  const [ref, { height }] = useMeasure()
  const expand = useSpring({
    config: { duration: 100 },
    height: visible ? `${contentHeight}px` : '0px',
  })
  useEffect(() => {
    setContentHeight(height)
  }, [height])

  return (
    <animated.div style={expand} {...props}>
      <div ref={ref}>
        <Flex direction="column">{children}</Flex>
      </div>
    </animated.div>
  )
}
export default styled(FolderContainer)`
  overflow: hidden;
  transition: height 0.2s ease-in;
  transform-origin: top;
  flex: auto;
  padding: ${({ theme, visible }) => visible && theme.semantic.space['space-2x-small']} 0;
  > div {
    width: 100%;
  }
`
