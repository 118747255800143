export default {
  PDF: 'pdf',
  TXT: 'txt',
  DOC: 'doc',
  DOCX: 'docx',
  RTF: 'rtf',
  CSV: 'csv',
  XLS: 'xls',
  JPG: 'jpg',
  JPEG: 'jpeg',
  PNG: 'png',
  BMP: 'bmp',
  SVG: 'svg',
  TIFF: 'tiff',
  GIF: 'gif',
  MP4: 'mp4',
  AVI: 'avi',
  MP3: 'mp3',
  WMA: 'wma',
  ITF: 'itf',
}
