/* eslint-disable no-console */
import ecies from 'eth-ecies'

import { callApi } from '../../../utils/callApi'
import { printf } from '../../../hooks/printf'

let alreadyStarted = false

const checkAbortMigration = (value, reason) => {
  if (!value) {
    console.error(`Company Workspace Migration: ${reason}`)
    return true
  }

  return false
}

const doMigrate = async ({ companyWorkspace, wsPublicKey, contracts }) => {
  const iterateContracts = () => {
    if (contracts.length > 0) {
      setTimeout(() => doMigrate({ companyWorkspace, wsPublicKey, contracts }), 942)
    } else {
      printf(() => console.log(`Company Workspace Migration: no contracts left`))
    }
  }

  let userPrivateKey
  try {
    userPrivateKey = Buffer.from(JSON.parse(localStorage.getItem('user_privateKey')).data, 'hex')
  } catch (e) {
    // user logged out during the migration, abort
    return
  }

  const contract = contracts.shift()
  const { daId, encrDS } = contract

  if (!encrDS) {
    // something wrong with digital asset, skip it
    printf(() =>
      console.log(
        `Company Workspace Migration: something wrong with daId=${daId}. Report it if you see this message.`,
      ),
    )

    iterateContracts()
    return
  }

  const encrDSBuffer = Buffer.from(encrDS, 'base64')

  if (
    checkAbortMigration(userPrivateKey, 'user private key should not be empty') ||
    checkAbortMigration(wsPublicKey, 'workspace public key should not be empty') ||
    checkAbortMigration(encrDS, 'encrypted document secret should not be empty')
  ) {
    console.error('Company Workspace Migration: aborted')
    return
  }

  const daSecret = ecies.decrypt(userPrivateKey, encrDSBuffer)
  const wsEncrDS = ecies.encrypt(Buffer.from(wsPublicKey, 'hex'), daSecret).toString('base64')

  try {
    await callApi({
      endpoint: 'workspaceaccessgrants',
      method: 'POST',
      disableNotifications: true,
      body: {
        data: {
          type: 'workspaceaccessgrants',
          attributes: {
            encrDS: wsEncrDS,
          },
          relationships: {
            digitalAsset: { data: { type: 'digitalassets', id: daId } },
            grantedTo: { data: { type: 'workspaces', id: companyWorkspace.id } },
          },
        },
      },
    })
  } catch (e) {
    // suppress errors
    console.error(e)
  }
  printf(() =>
    console.log(
      `Company Workspace Migration: contract(id=${daId}) was shared with company workspace(id=${companyWorkspace.id})`,
    ),
  )

  iterateContracts()
}

const migrateToCompanyWorkspace = async ({ companyWorkspace }) => {
  if (alreadyStarted) {
    // skip
    return
  }

  alreadyStarted = true

  const {
    attributes: { publicKey: wsPublicKey },
  } = companyWorkspace

  try {
    const contracts = (await callApi({ endpoint: 'companyWorkspace/missingDigitalAssets' })) || []
    if (contracts.length > 0) {
      await doMigrate({ companyWorkspace, wsPublicKey, contracts })
    }
  } catch (e) {
    printf(() =>
      console.log('Probably you see this with tests(which is fine). Report if spotted on dev/prod'),
    )

    console.error(e)
  }
}

export default migrateToCompanyWorkspace
