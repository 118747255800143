import { colors } from './colors'
import { radius } from './radius'
import { space } from './space'

export const semantic = {
  palette: {
    background: { 'background-base': colors['neutral-01'] },
    surface: {
      surface: colors['neutral-01'],
      'surface-hover': colors['neutral-02'],
      'surface-active': colors['neutral-03'],
      'surface-selected': colors['neutral-03'],
      'surface-secondary': colors['neutral-02'],
      'surface-secondary-hover': colors['neutral-03'],
      'surface-secondary-active': colors['neutral-04'],
      'surface-secondary-selected': colors['neutral-04'],
      'surface-tertiary': colors['neutral-03'],
      'surface-tertiary-hover': colors['neutral-04'],
      'surface-tertiary-active': colors['neutral-05'],
      'surface-disabled': colors['neutral-02'],
      'surface-invert': colors['neutral-08'],
      'surface-brand': colors['brand-02'],
      'surface-brand-hover': colors['brand-03'],
      'surface-brand-active': colors['brand-03'],
      'surface-brand-selected': colors['brand-03'],
      'surface-brand-disabled': colors['brand-01'],
      'surface-info': colors['info-03'],
      'surface-info-secondary': colors['info-01'],
      'surface-success': colors['success-03'],
      'surface-success-secondary': colors['success-01'],
      'surface-warning': colors['warning-03'],
      'surface-warning-secondary': colors['warning-01'],
      'surface-critical': colors['critical-03'],
      'surface-critical-hover': colors['critical-04'],
      'surface-critical-disabled': colors['critical-02'],
      'surface-critical-secondary': colors['critical-01'],
    },
    text: {
      text: colors['neutral-08'],
      'text-secondary': colors['neutral-07'],
      'text-tertiary': colors['neutral-06'],
      'text-disabled': colors['neutral-06'],
      'text-inverse': colors['neutral-01'],
      'text-brand': colors['brand-02'],
      'text-brand-hover': colors['brand-03'],
      'text-brand-disabled': colors['brand-01'],
      'text-info': colors['info-04'],
      'text-success': colors['success-04'],
      'text-warning': colors['warning-04'],
      'text-critical': colors['critical-04'],
    },
    icon: {
      icon: colors['neutral-08'],
      'icon-secondary': colors['neutral-07'],
      'icon-tertiary': colors['neutral-06'],
      'icon-disabled': colors['neutral-06'],
      'icon-inverse': colors['neutral-01'],
      'icon-brand': colors['brand-02'],
      'icon-brand-hover': colors['brand-03'],
      'icon-brand-disabled': colors['brand-01'],
      'icon-info': colors['info-04'],
      'icon-success': colors['success-04'],
      'icon-warning': colors['warning-04'],
      'icon-critical': colors['critical-04'],
    },
    border: {
      border: colors['neutral-04'],
      'border-secondary': colors['neutral-05'],
      'border-secondary-hover': colors['brand-02'],
      'border-secondary-active': colors['brand-02'],
      'border-tertiary': colors['neutral-03'],
      'border-invert': colors['neutral-01'],
      'border-contrast': colors['neutral-08'],
      'border-brand': colors['brand-02'],
      'border-info': colors['info-03'],
      'border-success': colors['success-03'],
      'border-warning': colors['warning-03'],
      'border-critical': colors['critical-03'],
    },
  },
  space: {
    'space-none': space['space-0'],
    'space-5x-small': space['space-025'],
    'space-4x-small': space['space-050'],
    'space-3x-small': space['space-075'],
    'space-2x-small': space['space-100'],
    'space-x-small': space['space-150'],
    'space-small': space['space-200'],
    'space-medium': space['space-300'],
    'space-large': space['space-400'],
    'space-x-large': space['space-500'],
    'space-2x-large': space['space-600'],
    'space-3x-large': space['space-800'],
    'space-4x-large': space['space-1000'],
  },
  radius: {
    'border-radius-zero': radius['radius-0'],
    'border-radius-x-small': radius['radius-025'],
    'border-radius-small': radius['radius-050'],
    'border-radius-medium': radius['radius-075'],
    'border-radius-large': radius['radius-100'],
    'border-radius-full': radius['radius-full'],
  },
}
