import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

const handleAutosize = (e) => {
  const input = e.target
  const div = document.createElement('div')
  div.innerHTML = input.value
  div.style.display = 'inline-block'
  div.style.fontSize = '18px'
  div.style.fontWeight = '500'
  document.body.appendChild(div)
  const { width } = div.getBoundingClientRect()
  document.body.removeChild(div)

  input.style.width = `${width + 11}px`
}

const ClickSwitchState = ({
  resize,
  forceEditor,
  onChange,
  onBlur,
  element,
  children,
  onActive,
  ...props
}) => {
  const [clicked, setClicked] = useState(false)
  const ref = useRef()

  // trigger onActive once clicked is clicked
  useEffect(() => {
    if (onActive) {
      onActive(clicked)
    }
  }, [clicked, onActive])

  const modifiedChildren = React.cloneElement(children, { onClick: () => setClicked(true) })
  const modifiedElement = React.cloneElement(element, {
    onBlur: (e) => {
      setClicked(false)
      if (onBlur) {
        onBlur(e)
      }
    },
    onFocus: resize ? handleAutosize : undefined,
    onChange: (e) => {
      if (resize) {
        handleAutosize(e)
      }
      if (onChange) {
        onChange(e)
      }
    },
  })

  useEffect(() => {
    if (clicked) {
      const { current } = ref
      current?.querySelector('input').focus()
    }
  }, [clicked])

  if (forceEditor) {
    // for tests
    return (
      <div ref={ref} {...props}>
        {modifiedElement}
      </div>
    )
  }

  return (
    <div ref={ref} {...props}>
      {clicked ? modifiedElement : modifiedChildren}
    </div>
  )
}
export default styled(ClickSwitchState)``
