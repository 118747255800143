/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Typography from './Typography'
import Spacing from './Spacing'

const isOldStyled = (type) => type === 'old'

const Progress = ({ type = 'regular', i18n, label, current = 0, max = 1, ...props }) => {
  if (max === 0) {
    return null
  }

  return (
    <div {...props}>
      <div>
        <Typography i18n={i18n} variant={`body 13 ${isOldStyled(type) ? 'regular' : 'medium'}`}>
          {label}
        </Typography>

        <Typography variant="body 13 regular">
          {isOldStyled(type) ? `${Math.round(current)}%` : `${current}/${max}`}
        </Typography>
      </div>
      <Spacing size="8" />
      <div>
        {Array.from(Array(max)).map((skip, progress) => (
          <ProgressSegment $type={type} $pending={progress < current} key={progress} />
        ))}
      </div>
    </div>
  )
}

Progress.propTypes = {
  current: PropTypes.number,
  i18n: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.number,
  type: PropTypes.oneOf(['old', 'regular']),
}

const ProgressSegment = styled.div`
  height: 6px;
  width: 100%;
  margin-right: ${({ $type }) => (isOldStyled($type) ? 0 : '2px')};
  background-color: ${({ $pending, theme: { palette } }) =>
    $pending ? palette.accent.success : palette.divider};

  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    margin-right: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`

export default styled(Progress)`
  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Typography}:last-child {
      color: ${({ theme }) => theme.palette.type.secondary};
    }
  }

  > div:last-child {
    display: flex;
  }
`
