import styled from 'styled-components'

import { TextInput } from '../../atoms'

const ProfileInput = styled(TextInput)`
  input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export default ProfileInput
