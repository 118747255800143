import { useCallback } from 'react'
import styled from 'styled-components'

import BaseInput from './BaseInput'

const NumberInput = ({ value, defaultValue, allowZero, allowNegative, onChange, ...props }) => {
  const numberInputOnChange = useCallback(
    (e) => {
      // eslint-disable-next-line no-shadow
      const { value } = e.target

      if (value < 0 && !allowNegative) {
        return
      }

      // eslint-disable-next-line eqeqeq
      if (value == 0 && !allowZero) {
        return
      }

      if (typeof onChange === 'function') {
        onChange(e)
      }
    },
    [allowNegative, allowZero, onChange],
  )

  return (
    <BaseInput
      {...props}
      type="number"
      onChange={numberInputOnChange}
      value={value}
      defaultValue={defaultValue}
    />
  )
}

export default styled(NumberInput)`
  padding-right: 0;
`
