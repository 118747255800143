import { useCallback } from 'react'
import { useScreenClass } from 'react-grid-system'

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

const reference = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  xxl: 6,
}

const useResponsive = () => {
  const screenClass = useScreenClass()

  const isGreaterOrEqualThan = useCallback(
    (value) => {
      if (breakpoints.indexOf(value) === -1) {
        // value isn't in the list of breackpoints
        throw new TypeError('value should be on of xs, sm, md, lg, xl, xxl')
      }
      return reference[value] <= reference[screenClass]
    },
    [screenClass],
  )

  const isGreaterThan = useCallback(
    (value) => {
      if (breakpoints.indexOf(value) === -1) {
        // value isn't in the list of breackpoints
        throw new TypeError('value should be on of xs, sm, md, lg, xl, xxl')
      }
      return reference[value] > reference[screenClass]
    },
    [screenClass],
  )

  const isLessOrEqualThan = useCallback(
    (value) => {
      if (breakpoints.indexOf(value) === -1) {
        // value isn't in the list of breackpoints
        throw new TypeError('value should be on of xs, sm, md, lg, xl, xxl')
      }
      return reference[value] >= reference[screenClass]
    },
    [screenClass],
  )

  const isLessThan = useCallback(
    (value) => {
      if (breakpoints.indexOf(value) === -1) {
        // value isn't in the list of breackpoints
        throw new TypeError('value should be on of xs, sm, md, lg, xl, xxl')
      }
      return reference[value] > reference[screenClass]
    },
    [screenClass],
  )

  return { screenClass, isGreaterOrEqualThan, isLessOrEqualThan, isGreaterThan, isLessThan }
}

export default useResponsive
