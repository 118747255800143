import styled from 'styled-components'

const getTopPosition = (placement) => {
  if (!placement || placement === 'bottom') {
    return `calc(50% - 1px)`
  }
  if (placement === 'top') {
    return `calc(100% - 16px)`
  }
  throw new TypeError(`Invalid placement property: ${placement}`)
}

const BaseArrow = ({ placement, ...props }) => <div {...props} />

const Arrow = styled(BaseArrow)`
  position: absolute;
  width: 8px;
  height: 8px;
  background: white;
  left: -4px;
  top: ${({ placement }) => getTopPosition(placement)};
  transform: rotate(45deg);
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.primary};
  border-left: 1px solid ${({ theme }) => theme.palette.background.primary};
`

export default Arrow
