import styled, { useTheme } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Elevation, Flex, SvgMask, Tooltip, Typography } from '../../atoms'

const DEFAULT_VARIANT = 'body-large-03'

const Wrapper = ({ route, linkProps, ...props }) => {
  const active = typeof linkProps.isActive === 'function' ? linkProps.isActive() : false
  if (route) {
    return (
      <NavLink
        to={linkProps.to}
        {...props}
        className={`${props.className} ${active ? 'selected' : ''}`}
      />
    )
  }

  return <div {...props} className={`${props.className} ${active ? 'selected' : ''}`} />
}

const MenuItem = ({
  variant,
  value,
  i18n,
  subLabel,
  icon,
  iconSize,
  iconBackground,
  route,
  noHighlight,
  activeHover,
  isActive,
  children,
  subMenuItems,
  subMenuRoute,
  color,
  hoverBackground,
  iconHoverBackground,
  leftContent,
  tooltip,
  offset = 0,
  offsetIcon,
  offsetIconClick,
  ...props
}) => {
  const theme = useTheme()

  const linkProps = {
    to: route,
    activeClassName: noHighlight ? '' : 'selected',
    isActive,
  }
  const renderToOffset = icon ? offset : offset + 1

  return (
    <>
      <Wrapper route={route} linkProps={linkProps} {...props}>
        {leftContent}
        <Flex>
          {renderToOffset > 0 &&
            Array.from({ length: renderToOffset }, (_, index) => <Offset key={index} />)}
          {offsetIcon && (
            <OffsetSvg
              content="center"
              onClick={offsetIconClick}
              data-test="button_sidebar_workspace_expand"
            >
              <SidebarSvg icon={offsetIcon} color={theme.semantic.palette.icon['icon-secondary']} />
            </OffsetSvg>
          )}
          <SidebarIcon
            icon={icon}
            iconSize={iconSize}
            iconBackground={iconBackground}
            color={color}
          />
        </Flex>
        <LabelsContainer direction="column" align="flex-start">
          <Grid>
            <Label
              i18n={i18n}
              variant={variant || DEFAULT_VARIANT}
              title={tooltip ? value : undefined}
            >
              {value}
            </Label>
          </Grid>
          {subLabel && (
            <Grid>
              <Typography
                variant="body-medium-02"
                color={theme.semantic.palette.text['text-secondary']}
              >
                {subLabel}
              </Typography>
            </Grid>
          )}
        </LabelsContainer>
        {children}
      </Wrapper>
      {window.location.pathname.indexOf(subMenuRoute) !== -1 && (
        <SubMenuContainer>{subMenuItems}</SubMenuContainer>
      )}
    </>
  )
}

const Label = styled(Typography)``

const SidebarIconBackground = styled.div`
  padding: 8px;
  background: ${({ $background }) => $background};
  border-radius: 4px;
`

const SubMenuContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
`

const SidebarIcon = ({ icon, iconSize, iconBackground, color }) => {
  const theme = useTheme()

  let Icon

  if (typeof icon === 'string') {
    Icon = iconBackground ? (
      <SidebarIconBackground $background={iconBackground}>
        <SidebarSvg
          icon={icon}
          size={iconSize || 16}
          color={color || theme.palette.background.white}
        />
      </SidebarIconBackground>
    ) : (
      <SidebarSvg
        icon={icon}
        size={iconSize || 16}
        color={color || theme.semantic.palette.icon['icon-secondary']}
      />
    )
  } else {
    Icon = icon
  }

  return Icon
}

const Offset = styled.div`
  width: 16px;
  height: 16px;
`

const SidebarSvg = styled(SvgMask)``

const OffsetSvg = styled(Flex)`
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin: -4px 0 -4px -24px;
  flex: 24px;
`

const LabelsContainer = styled(Flex)`
  width: inherit;
  overflow: hidden;

  > ${Typography} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr;
  align-items: center;
  > ${Typography} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export default styled(MenuItem)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.semantic.space['space-2x-small']};
  gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};
  text-decoration: none;
  width: 100%;
  background: ${({ theme }) => theme.semantic.palette.surface['surface-secondary']};
  box-sizing: border-box;

  ${Label} {
    color: ${({ theme, color }) => color || theme.semantic.palette.text['text-secondary']};
  }

  &:hover {
    background: ${({ theme }) => theme.semantic.palette.surface['surface-secondary-hover']};

    ${Label} {
      color: ${({ theme }) => theme.semantic.palette.text.text};
    }

    ${SidebarSvg} {
      background-color: ${({ theme, iconBackground, iconHoverBackground }) => {
        if (iconHoverBackground) return iconHoverBackground
        if (iconBackground) return undefined
        return theme.semantic.palette.icon.icon
      }};
    }
    ${SidebarIconBackground} {
      background: ${({ hoverBackground }) => hoverBackground || undefined};
    }
  }

  &.selected {
    background: ${({ theme }) => theme.semantic.palette.surface['surface-secondary-active']};

    ${Label} {
      color: ${({ theme }) => theme.semantic.palette.text.text};
    }

    ${SidebarSvg} {
      background-color: ${({ theme, iconBackground }) =>
        iconBackground ? undefined : theme.semantic.palette.icon.icon};
    }

    &:hover {
      background: ${({ theme, iconBackground }) =>
        iconBackground ? undefined : theme.semantic.palette.surface['surface-secondary-active']};
    }
  }
`
