import { createReducer } from '@reduxjs/toolkit'

import { applyOverlays, setFieldsDefinitions } from '../actions/customFields'
import { loadInitialReducerState } from '../store/persist'

const initialState = {
  data: {},
  definitions: [],
  ...loadInitialReducerState('customFields', 'inhubber-redux'),
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(applyOverlays, (state, action) => {
      const { documentId, overlays } = action.payload
      const newData = { ...state.data, [documentId]: overlays }
      return {
        ...state,
        data: newData,
      }
    })
    .addCase(setFieldsDefinitions.type, (state, action) => {
      const payload = action.payload || []
      return {
        ...state,
        definitions: payload,
      }
    })
})

export default reducer
