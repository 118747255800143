import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'

import Spacing from './Spacing'
import Typography from './Typography'
import ElementTooltip from './Modal/ElementTooltip'
import SvgMask from './SvgMask'
import Flex from './Flex'

const LabelField = ({
  labelI18n,
  label,
  error,
  errorMessage,
  errorMessageI18n,
  sideLabel,
  templateColumn,
  description,
  required,
  helpText,
  helpTextI18n,
  width,
  children,
  className,
}) => {
  const theme = useTheme()

  return (
    <Container
      sideLabel={sideLabel}
      width={width}
      templateColumn={templateColumn}
      className={className}
    >
      {(labelI18n || label) && (
        <Label sideLabel={sideLabel}>
          <ValueTypography required={required}>
            <Typography
              variant="body-medium-01"
              i18n={labelI18n}
              color={theme.semantic.palette.text['text-secondary']}
            >
              {label}
            </Typography>
            {required && (
              <>
                <Spacing size="4" />
                <Typography
                  variant="body-medium-01"
                  color={theme.semantic.palette.text['text-critical']}
                >
                  *
                </Typography>
              </>
            )}
          </ValueTypography>
          {description && (
            <ElementTooltip
              tooltip={
                <DescriptionTypography
                  word="break-word"
                  space="pre-wrap"
                  color="secondary"
                  variant="body 12 regular"
                >
                  {description}
                </DescriptionTypography>
              }
              options={{
                placement: 'top',
              }}
            >
              <SvgMask icon="info-circle" color={theme.semantic.palette.icon['icon-tertiary']} />
            </ElementTooltip>
          )}
        </Label>
      )}
      <Flex direction="column" align="flex-start">
        {children}
        {(helpText || helpTextI18n) && (
          <Typography
            variant="body-small-02"
            i18n={helpTextI18n}
            color={theme.semantic.palette.text['text-tertiary']}
          >
            {helpText}
          </Typography>
        )}
        {error && (errorMessage || errorMessageI18n) && (
          <Typography
            variant="body-small-02"
            i18n={errorMessageI18n}
            color={theme.semantic.palette.text['text-critical']}
            data-test="typography_text-input-validation"
          >
            {errorMessage}
          </Typography>
        )}
      </Flex>
    </Container>
  )
}

const DescriptionTypography = styled(Typography)`
  min-width: 20px;
`

LabelField.propTypes = {
  children: PropTypes.any,
  labelI18n: PropTypes.string,
  error: PropTypes.bool,
}

const Label = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.semantic.space['space-4x-small']};
  padding: ${({ theme, sideLabel }) => sideLabel && theme.semantic.space['space-2x-small']} 0;
  ${Typography} {
    display: inline;
  }
`

const Container = styled.div`
  display: ${({ sideLabel }) => (sideLabel ? 'grid' : 'flex')};
  gap: ${({ theme, sideLabel }) =>
    sideLabel ? theme.semantic.space['space-small'] : theme.semantic.space['space-4x-small']};
  flex-direction: ${({ sideLabel }) => !sideLabel && 'column'};
  grid-template-columns: ${({ sideLabel, templateColumn }) =>
    sideLabel && (templateColumn || '128px auto')};
  width: ${({ width }) => width || '100%'};
  > div {
    gap: ${({ theme }) => theme.semantic.space['space-4x-small']};
  }
`

const ValueTypography = styled.div`
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  ${Typography} {
    display: inline-block;
    text-align: left;
  }
  ${Spacing} {
    display: inline;
  }
`

export default LabelField
