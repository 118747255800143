const PERMISSIONS = Object.freeze({
  CONTRACT_EDITABLE: 'contract-editable',
  CONTRACT_SMARTIMPORT_ENABLED: 'contract-smartimport-enabled',
  CONTRACT_COMMENTS_ENABLED: 'contract-comments-enabled',

  CONTRACTS_CREATE: 'contracts-create',
  CONTRACT_COPY: 'copy',
  CONTRACT_UNARCHIVE: 'unarchive',
  UPLOAD_E_SIGNATURE: 'upload_e_signature',
  UPLOAD_SIGNED_OFFLINE: 'upload_signed_offline',
  UPLOAD_OTHER: 'upload_other',
  UPLOAD_DOCUMENTS: 'upload_documents',
  INVITE_E_SIGNATURE: 'invite_e_signature',

  SHAREPOINT_ENABLED: 'sharepoint-enabled',

  FEATURES_ANALYSE_QNA: 'features-analyse-qna', // new AI analyse
  FEATURES_ANALYSE_DOCUMENTS: 'features-analyse-documents', // old AI analyse

  USER_ISSUER: 'user-type-issuer',
  USER_BASIC: 'user-type-basic',
  USER_INHUBBER_ADMIN: 'user-type-inhubber-admin',

  COMPANY_ADMIN: 'company-admin',

  BETA_FEATURES: 'beta-features-enabled',

  WORKSPACE_MAINTAINER: 'workspace-maintainer',

  DIGITALASSET_CREATED_BY_COMPANY: 'digitalasset-created-by-company',

  INVITE_FOR_QES: 'invite-for-qes',

  WORKSPACES_CREATE: 'workspaces-create',
  MANAGE_LINKS: 'manage_links',

  WORKSPACE_UPDATE: 'workspace-update',
  WORKSPACE_DELETE: 'workspace-delete',
  WORKSPACE_MANAGE_FOLDERS: 'workspace-manage-folders',
  WORKSPACE_MANAGE_MEMBERS: 'workspace-manage-members',

  FOLDER_REMOVE_CONTRACTS: 'folder-remove-contracts',
  FOLDER_UPDATE_METADATA: 'folder-update-metadata',
  FOLDER_CHANGE_PERMISSIONS: 'folder-change-permissions',
  FOLDER_ADD_SUBFOLDERS: 'folder-add-subfolders',
  FOLDER_ACCESS: 'folder-access',
  FOLDER_ADD_CONTRACTS: 'folder-add-contracts',
  FOLDER_REMOVE_FOLDER: 'folder-remove-folder',
})

// these permission are returned by backend in meta property and them integrated into
// the frontend within usePermissions hook
export const BACKEND_PERMISSIONS = {
  WORKSPACES: {
    CREATE_WORKSPACE: 'create', // from GET /workspaces call

    // from GET /workspaces/:id call
    UPDATE: 'update',
    DELETE: 'delete',
    MANAGE_FOLDERS: 'manage_folders',
    MANAGE_MEMBERS: 'manage_members',
    CREATE_CONTRACTS: 'create_contracts',
  },
  FOLDERS: {
    FOLDER_REMOVE_CONTRACTS: 'remove_contracts',
    FOLDER_UPDATE_METADATA: 'update_metadata',
    FOLDER_CHANGE_PERMISSIONS: 'change_permissions',
    FOLDER_ADD_SUBFOLDERS: 'add_subfolders',
    FOLDER_ACCESS: 'access',
    FOLDER_ADD_CONTRACTS: 'add_contracts',
    FOLDER_REMOVE_FOLDER: 'remove_folder',
  },
  DIGITALASSET: {
    CONTRACT_COPY: 'copy',
    CONTRACT_UNARCHIVE: 'unarchive',
    UPLOAD_E_SIGNATURE: 'upload_e_signature',
    UPLOAD_SIGNED_OFFLINE: 'upload_signed_offline',
    UPLOAD_OTHER: 'upload_other',
    INVITE_E_SIGNATURE: 'invite_e_signature',
  },
}

export default PERMISSIONS
