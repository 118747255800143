export const WORKSPACE_ROLES = {
  ADMIN: 'maintainer',
  VIEWER: 'viewer',
  EDITOR: 'editor',
  MEMBER: 'employee',
  ADMIN_COMPANY: 'admin',
  CREATOR: 'creator',
}

export const DEFAULT_WORKSPACE_COLOR = 9
