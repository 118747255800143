import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { selectDocuments } from '../../../store/selectors'

const FieldLeaf = ({ children, id, ...props }) => {
  const leafRef = useRef()

  const selectData = createSelector([selectDocuments], (documents) => ({
    focusedField: documents.focused,
  }))
  const { focusedField } = useSelector(selectData)

  useEffect(() => {
    if (focusedField === id) {
      leafRef?.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [focusedField, id])

  if (focusedField === id) {
    return (
      <FocusedField ref={leafRef} {...props}>
        {children}
      </FocusedField>
    )
  }

  return (
    <DefaultField ref={leafRef} {...props}>
      {children}
    </DefaultField>
  )
}

const DefaultField = styled.span`
  background-color: #fdb185;
`

const FocusedField = styled.span`
  background-color: #59f399;
`

export default FieldLeaf
