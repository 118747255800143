import { matchPath } from 'react-router-dom'

const useMatchPaths = (paths) => {
  return paths
    .map((path) => matchPath({ path, exact: false, strict: false }, window.location.pathname))
    .reduce((a, b) => ({ ...a?.params, ...b?.params }), {})
}

export const useMatchPath = (path) =>
  matchPath({ path, exact: false, strict: false }, window.location.pathname)?.params || {}

export default useMatchPaths
