/* eslint-disable no-param-reassign */
import { createAsyncThunk } from '@reduxjs/toolkit'
import compact from 'lodash/compact'

import { getFilterTarget } from '../filters'
import { callApi } from '../../utils/callApi'
import { updateIncluded } from '../included'
import fetchFilestream from '../../utils/callApi/fetchFilestream'

const generateEndpoint = ({
  filters,
  workspaceId,
  folderId,
  filterWorkspaceId,
  filterFolderId,
  sort,
  search,
  archive,
  withoutInclude = false,
  noLimit = false,
}) => {
  const filterTargetId = getFilterTarget({
    activeWorkspaceId: workspaceId || filterWorkspaceId,
    activeWorkspaceFolderId: folderId || filterFolderId,
    locationPathName: window.location.pathname,
  })
  const tagsId = filters.data[filterTargetId]?.byTags?.map((t) => t.id) || []
  const ownersId = filters.data[filterTargetId]?.byOwners?.map((t) => t.id) || []
  const workspacesId = filters.data[filterTargetId]?.byWorkspaces?.map((t) => t) || []

  const encodedSearchValue =
    search?.length > 0 && search.replace(/\\/g, '\\\\').replace(/"/g, '\\"')

  const jsonFiltersArray = [
    search &&
      `{"OR": [{"LIKE": {"title": "%${encodedSearchValue}%"}}, {"LIKE": {"description": "%${encodedSearchValue}%"}}, {"LIKE": {"customFields": {"stringValue":"%${encodedSearchValue}%"}}}]}`,
    ...tagsId.map((id) => JSON.stringify({ tags: { id } })),
  ].filter((x) => x !== '')

  const requestParams = compact([
    !withoutInclude
      ? `include=ownedBy.account,tags,workspaceAccessGrant.grantedTo,folder,ftsResults`
      : '',
    sort ? `sort=${sort}` : 'sort=-created',
    tagsId.length > 0 ? 'filter[tags.type]=label' : '',
    jsonFiltersArray.length > 1
      ? `filter=${encodeURIComponent(`{"AND":[${jsonFiltersArray}]}`)}`
      : jsonFiltersArray.length > 0 && `filter=${encodeURIComponent(`${jsonFiltersArray}`)}`,
    archive ? `filter[state]=ARCHIVED` : `filter[state][NEQ]=ARCHIVED`,
    filters.data[filterTargetId]?.byStatus?.length > 0 &&
      `filter[state]=${filters.data[filterTargetId].byStatus.map((item) => item)}`,
    filters.data[filterTargetId]?.byStartDate?.le &&
      `filter[startDate][LE]=${filters.data[filterTargetId]?.byStartDate?.le}`,
    filters.data[filterTargetId]?.byStartDate?.ge &&
      `filter[startDate][GE]=${filters.data[filterTargetId]?.byStartDate?.ge}`,
    filters.data[filterTargetId]?.byExpiryDate?.le &&
      `filter[expiryDate][LE]=${filters.data[filterTargetId]?.byExpiryDate?.le}`,
    filters.data[filterTargetId]?.byExpiryDate?.ge &&
      `filter[expiryDate][GE]=${filters.data[filterTargetId]?.byExpiryDate?.ge}`,
    filters.data[filterTargetId]?.byCreatedOn?.le &&
      `filter[created][LE]=${filters.data[filterTargetId]?.byCreatedOn?.le}`,
    filters.data[filterTargetId]?.byCreatedOn?.ge &&
      `filter[created][GE]=${filters.data[filterTargetId]?.byCreatedOn?.ge}`,
    filters.data[filterTargetId]?.byValue?.le &&
      `filter[value.monetaryAmount.amount][LE]=${filters.data[filterTargetId]?.byValue?.le}`,
    filters.data[filterTargetId]?.byValue?.ge &&
      `filter[value.monetaryAmount.amount][GE]=${filters.data[filterTargetId]?.byValue?.ge}`,
    ownersId.length > 0 && `filter[ownedBy.id]=${ownersId}`,
    workspacesId.length > 0 && `filter[workspaceAccessGrant.grantedTo.id]=${workspacesId}`,
    filterFolderId ? `filter[folder.id]=${filterFolderId}` : '',
    filterWorkspaceId ? `filter[workspaceAccessGrant.grantedTo.id]=${filterWorkspaceId}` : '',
    !noLimit ? 'page[limit]=20' : '',
  ])

  const requestParamsString = requestParams.join('&')

  if (workspaceId) {
    return folderId
      ? {
          endpoint: `folders/${folderId}/digitalassets?${requestParamsString}`,
          requestParamsString,
        }
      : {
          endpoint: `workspaces/${workspaceId}/digitalassets?${requestParamsString}`,
          requestParamsString,
        }
  }

  return { endpoint: `digitalassets?${requestParamsString}`, requestParamsString }
}

export default createAsyncThunk(
  'digitalassets/get',
  async ({ activeWorkspaceId, activeWorkspaceFolderId, callback, ...restOptions }, api) => {
    const signal = api.extra.signal.registerAndAbortSignal('contracts-fetch')

    const { endpoint, requestParamsString } = generateEndpoint({
      workspaceId: activeWorkspaceId,
      folderId: activeWorkspaceFolderId,
      ...restOptions,
    })

    if (restOptions.search === null) {
      api.dispatch(digitalAssetsSearchValue({ value: '' }))
      return {
        contracts: [],
        lastFilter: requestParamsString,
        meta: {},
      }
    }

    const contractsResponse = await callApi({
      endpoint,
      signal,
    })

    const contracts = contractsResponse.data

    api.dispatch(updateIncluded(contractsResponse.included))
    api.dispatch(updateIncluded(contracts))

    api.extra.sendCallback(callback, contractsResponse)

    return {
      contracts,
      lastFilter: requestParamsString,
      meta: { links: contractsResponse.links },
    }
  },
)

export const getExportCSV = createAsyncThunk(
  'digitalassets/getExportCSV',
  async ({ activeWorkspaceId, activeWorkspaceFolderId, callback, ...restOptions }, api) => {
    const { endpoint } = generateEndpoint({
      filterWorkspaceId: activeWorkspaceId,
      filterFolderId: activeWorkspaceFolderId,
      withoutInclude: true,
      noLimit: true,
      ...restOptions,
    })

    const token = api.extra.getToken()

    const result = await fetchFilestream({
      endpoint,
      method: 'GET',
      accessToken: token,
      headers: {
        Accept: 'application/octet-stream',
      },
    })

    api.extra.sendCallback(callback, result)

    return { result }
  },
)

export const digitalAssetsSearchValue = createAsyncThunk(
  'digitalassets/setSearchValue',
  async ({ value, callback }, api) => {
    return { value }
  },
)
