/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import { API_URL } from './const'
import { createNotification } from '../../reducers/notifications'
import i18n from '../../i18n'

function fetchFilestream({
  endpoint,
  direct,
  method = 'GET',
  headers = '',
  accessToken,
  accessName = 'Bearer',
  acceptType,
}) {
  const { dispatch } = window.reduxStore
  const urlPath = direct || `${API_URL}/${endpoint}`
  const heads = !direct
    ? {
        headers: {
          Authorization: accessToken ? `${accessName} ${accessToken}` : '',
          Accept: acceptType || '*/*',
          ...headers,
        },
      }
    : ''

  return fetch(urlPath, {
    ...heads,
    method,
  })
    .then((response) => response.text().then((text) => ({ text, response })))
    .then(({ text, response }) => (response.ok ? text : Promise.reject({ text, response })))
    .catch((error) => {
      if (error == 'TypeError: Failed to fetch' || error?.errors[0]?.status == 502) {
        dispatch(createNotification({ value: i18n.t('ConnectionError'), state: 'warning' }))
      }
      return Promise.reject(error)
    })
}

export default fetchFilestream
