/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { useRef } from 'react'
import { Transforms } from 'slate'
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'
import { useSlate } from 'slate-react'
import styled from 'styled-components'

import { Button } from '../../atoms'

const withImages = (editor) => {
  const { insertData, isVoid } = editor

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', async () => {
            const url = reader.result
            const { height, width } = await getImageSize(url)
            insertImage(editor, url, { width, height })
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertImage = (editor, url, options = {}) => {
  const text = { text: '' }
  const image = { type: 'image', url, children: [text], options }
  Transforms.insertNodes(editor, image)
  Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] })
}

export const isImageUrl = (url) => {
  if (!url || !isUrl(url)) {
    return false
  }
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

const getImageSize = async (data) => {
  const image = new Image()
  image.src = data

  return new Promise((res) => {
    image.onload = (event) => {
      const { height, width } = event.target
      res({ height, width })
    }
  })
}

const BaseInsertImageButton = ({ className, editor }) => {
  // ! need a refactoring to be usable within the comments
  // const editor = useSlate()
  const inputRef = useRef()

  const onChange = (e) => {
    const file = e.target.files[0]

    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = async () => {
      const base64data = reader.result
      const { height, width } = await getImageSize(base64data)
      insertImage(editor, base64data, { width, height })
    }
  }

  return (
    <div className={className}>
      <Button
        tertiary
        width="fit-content"
        onMouseDown={() => inputRef?.current.click()}
        icon="image"
      />
      <input type="file" ref={inputRef} onChange={onChange} />
    </div>
  )
}

export const InsertImageButton = styled(BaseInsertImageButton)`
  > input {
    display: none;
  }
`

export default withImages
