/* eslint-disable import/no-extraneous-dependencies */
import { createGlobalStyle } from 'styled-components'

export const CalendarStyling = createGlobalStyle`
  .ms-DatePicker {
    padding: ${({ theme }) => theme.semantic.space['space-small']};
    font-family: Inter, Arial, Helvetica Neue, Helvetica, sans-serif;
    box-sizing: border-box;
    background: ${({ theme }) => theme.semantic.palette.surface.surface};
    width: initial;
    gap: ${({ theme }) => theme.semantic.space['space-small']};
    position: relative;
  }

  .liveRegion-98 {
    display: none;
  }

  .divider-95 {
    border-color: ${({ theme }) => theme.semantic.palette.border['border-tertiary']};
  }

  .root-99 {
    padding: 0;
    width: auto;
    gap: ${({ theme }) => theme.semantic.space['space-small']};
    display: flex;
    flex-direction: column;
  }

  .header-100 {
    line-height: initial;
    height: auto;
  }

  .monthAndYear-101, .currentItemButton-133, .currentItemButton-148 {
    line-height: initial;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .monthAndYear-101 span, .currentItemButton-133 span, .currentItemButton-148 span {
    ${({ theme }) => theme.typography['body-large-01']};
    color: ${({ theme }) => theme.semantic.palette.text.text};
  }

  .goTodayButton-97 {
    position: absolute;
    border-radius: ${({ theme }) => theme.radius['button-border-radius']};
    padding: ${({ theme }) =>
      `${theme.space['button-tertiary-padding']} calc(${theme.space['button-tertiary-padding']} + ${theme.semantic.space['space-4x-small']})`};
    transition: background 300ms ease-out;
    background: transparent;
    color: ${({ theme }) => theme.component.button.label['button-tertiary-color-label']};
    ${({ theme }) => theme.typography['body-medium-01']};
    top: 16px;
    margin: 0;
    height: 24px;
    box-sizing: border-box;
    bottom: auto;

    &:hover {
      background: ${({ theme }) => theme.component.button.surface['button-tertiary-color-surface-hover']};
      color: ${({ theme }) => theme.component.button.label['button-tertiary-color-label-hover']};
    }

    &:disabled {
      color: ${({ theme }) => theme.component.button.label['button-tertiary-color-label-disabled']};
    }
  }

  .headerIconButton-103, .navigationButton-135 {
    box-sizing: border-box;
    width: auto;
    height: auto;
    min-width: initial;
    min-height: initial;
    padding: ${({ theme }) => theme.space['button-icon-padding']};
    background: transparent;
    line-height: 16px;
    border-radius: ${({ theme }) => theme.radius['button-border-radius']};
    transition: background 300ms ease-out;

    &:active {
      background-color: transparent;
    }

    :after {
      content: '';
      width: 16px;
      height: 16px;
      display: block;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: ${({ theme }) => theme.component.button.icon['button-icon-color-icon']};
    }

    &:hover {
      background: ${({ theme }) => theme.component.button.surface['button-icon-color-surface-hover']};

      :after {
        background-color: ${({ theme }) => theme.component.button.icon['button-icon-color-icon-hover']};
      }
    }

    i {
      display: none;
    }
  }

  .headerIconButton-103:first-child, .navigationButton-135:first-child {
    &:after {
      mask-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3502 3.64643C10.5499 3.84168 10.5499 4.15827 10.3503 4.35354L6.62161 8L10.3503 11.6465C10.5499 11.8417 10.5499 12.1583 10.3502 12.3536C10.1505 12.5488 9.82678 12.5488 9.62711 12.3535L5.72992 8.54225C5.72983 8.54216 5.73001 8.54234 5.72992 8.54225C5.65716 8.47113 5.59916 8.38643 5.55976 8.29351C5.5203 8.20046 5.5 8.10072 5.5 8C5.5 7.89928 5.52031 7.79954 5.55976 7.70649C5.59917 7.61356 5.6569 7.52912 5.72967 7.458C5.72975 7.45792 5.72958 7.45808 5.72967 7.458L9.62711 3.64646C9.82678 3.45119 10.1505 3.45118 10.3502 3.64643ZM6.45252 8.16536C6.45261 8.16527 6.45243 8.16545 6.45252 8.16536V8.16536Z' fill='%235D677A'/%3E%3C/svg%3E");
    }
  }

  .headerIconButton-103:last-child, .navigationButton-135:last-child {
    &:after {
      mask-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.64978 3.64643C5.45009 3.84168 5.45007 4.15827 5.64974 4.35354L9.37839 8L5.64974 11.6465C5.45007 11.8417 5.45009 12.1583 5.64978 12.3536C5.84947 12.5488 6.17322 12.5488 6.37289 12.3535L10.2701 8.54225C10.2702 8.54216 10.27 8.54234 10.2701 8.54225C10.3428 8.47113 10.4008 8.38643 10.4402 8.29351C10.4797 8.20046 10.5 8.10072 10.5 8C10.5 7.89928 10.4797 7.79954 10.4402 7.70649C10.4008 7.61356 10.3431 7.52912 10.2703 7.458C10.2702 7.45792 10.2704 7.45808 10.2703 7.458L6.37289 3.64646C6.17322 3.45119 5.84947 3.45118 5.64978 3.64643ZM9.54748 8.16536C9.54739 8.16527 9.54757 8.16545 9.54748 8.16536V8.16536Z' fill='%235D677A'/%3E%3C/svg%3E");
    }
  }

  .ms-FocusZone {
    padding: 0;
  }

  .firstTransitionWeek-119, .lastTransitionWeek-120 {
    display: none;
  }

  .table-109 {
    padding: 0;
    width: auto;
    margin: 0;

    tbody {
      gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
      display: flex;
      flex-direction: column;
      align-items: center;

      tr:first-child {
        padding-bottom: ${({ theme }) => theme.semantic.space['space-2x-small']};
        border-bottom: 1px solid ${({ theme }) => theme.semantic.palette.border['border-tertiary']};
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.semantic.space['space-2x-small']};

        th {
          width: auto;
          height: auto;
          display: flex;
          padding: 1px 2px 1px 3px;
          justify-content: center;
          align-items: center;
          color: ${({ theme }) => theme.semantic.palette.text['text-tertiary']};
          ${({ theme }) => theme.typography['header-small']};
        }
      }

      tr:nth-child(n + 1) {
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
        animation: none;

        td {
          border-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 32px;
          height: 32px;
          line-height: initial;

          &:before {
            border: none;
          }
        }

        button {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: initial;
          border: none;
          padding: 6px 8px;
          box-sizing: border-box;
          border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};

          span {
            color: ${({ theme }) => theme.semantic.palette.text.text};
            ${({ theme }) => theme.typography['body-large-02']};
            height: auto;
          }
        }

        .dayOutsideNavigatedMonth-116 {
          span {
            color: ${({ theme }) => theme.semantic.palette.text['text-disabled']};
          }
        }

        .ms-CalendarDay-hoverStyle {
          border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};

          button {
            background-color: ${({ theme }) => theme.component.button.surface['button-icon-color-surface-hover']};
          }
        }

        .ms-CalendarDay-daySelected {
          button {
            background-color: ${({ theme }) => theme.semantic.palette.surface['surface-brand']};
          }

          span {
            color: #fff; // var(--Colors-text-text-invert, #FFF)
          }
        }

        .dayIsToday-118 {
          span {
            color: ${({ theme }) => theme.semantic.palette.text.text};
          }
        }

        .ms-CalendarDay-dayIsToday {
          background-color: ${({ theme }) => theme.semantic.palette.surface['surface-active']} !important;
        }
      }
    }
  }

  .root-131 {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.space['space-200']};
    width: auto;
  }

  .headerContainer-132 {
    justify-content: space-between;
    width: 100%;
  }

  .navigationButtonsContainer-134 {
    gap: ${({ theme }) => theme.semantic.space['space-4x-small']};
  }

  .gridContainer-136 {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.semantic.space['space-2x-large']};

    > div {
      margin: 0;
      animation: none;
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.semantic.space['space-2x-large']};
    }
  }

  .itemButton-138 {
    position: relative;
    width: auto;
    height: auto;
    min-width: initial;
    min-height: initial;
    line-height: initial;;
    padding: 6px 5px;
    margin: 0;
    ${({ theme }) => theme.typography['body-large-01']};
    color: ${({ theme }) => theme.semantic.palette.text.text};
    border-radius: ${({ theme }) => theme.semantic.radius['border-radius-small']};

    &:hover {
      background-color: ${({ theme }) => theme.component.button.surface['button-icon-color-surface-hover']};
    }
  }

  .current-139 {
    background-color: ${({ theme }) => theme.semantic.palette.surface['surface-active']};
  }
`
