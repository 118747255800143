/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Divider, Spacing } from '../../atoms'
import Tab from './Tab'
import { setSelectTab } from '../../../actions/documents'
import { selectDigitalAssets } from '../../../store/selectors'
import BaseTabs from './BaseTabs'
import SegmentControl from './SegmentControl'
import Segment from './Segment'

const getFirstEnabledTab = (config = []) => {
  return config.find((tab) => tab.enabled)
}

const Tabs = ({
  firstSelectDelay,
  config = [],
  onTabChange,
  onTabClick,
  fitted,
  segment,
  ...props
}) => {
  const containerRef = useRef()
  const [refs, setRef] = useState({})
  const [selected, setSelected] = useState(firstSelectDelay || getFirstEnabledTab(config).id)
  const [[width, x], setIndicator] = useState([0, 0])
  const selectedTab = config.find((el) => el.id === selected)
  const dispatch = useDispatch()

  const selectData = createSelector([selectDigitalAssets], (digitalAssets) => ({
    uploadingDocument: digitalAssets?.uploadingDocument,
    selectTab: digitalAssets?.selectTab,
  }))

  const { uploadingDocument, selectTab } = useSelector(selectData)

  let Container = BaseTabs

  if (segment) {
    Container = SegmentControl
  }

  useEffect(() => {
    if (selectTab) {
      const finded = config.find((e) => e.id === selectTab)
      if (finded) {
        setSelected(selectTab)
        dispatch(setSelectTab(''))
      }
    }
  }, [config, dispatch, selectTab])

  useEffect(() => {
    if (typeof onTabChange === 'function') {
      onTabChange(selected)
    }
  }, [onTabChange, selected])

  const registerRef = (id, ref) => setRef((state) => ({ ...state, [id]: ref }))

  // dispose refs on unmount
  useEffect(() => () => setRef({}), [])

  // select first enabled Tab if selected one become disabled
  useEffect(() => {
    if (selectedTab && !selectedTab.enabled) {
      setSelected(getFirstEnabledTab(config).id)
    }

    if (firstSelectDelay && !selected) {
      setTimeout(() => setSelected(getFirstEnabledTab(config).id), firstSelectDelay)
    }
  }, [config, selected, selectedTab, firstSelectDelay])

  const counters = useMemo(() => {
    return config.filter((el) => el.enabled).map((el) => el.counter)
  }, [config])

  useEffect(() => {
    if (!selected || !refs[selected]?.current) {
      return
    }

    const { width, x } = refs[selected].current.getBoundingClientRect()
    const { x: containerX } = containerRef.current.getBoundingClientRect()
    setIndicator([width, x - containerX])
  }, [refs, selected, containerRef, counters])

  const tabContent = selectedTab ? selectedTab.content : null

  return (
    <>
      <Container fitted={fitted} {...props} ref={containerRef}>
        {config
          .filter((el) => el.enabled)
          .map((el) => {
            if (segment)
              return (
                <Segment
                  value={el.value}
                  key={el.id}
                  id={el.id}
                  selected={el.id === selected}
                  disabled={el.disabled}
                  icon={el.icon}
                  onMount={registerRef}
                  onClick={() => {
                    if (uploadingDocument) {
                      return null
                    }
                    setSelected(el.id)
                    if (typeof onTabClick === 'function') {
                      onTabClick(el.id)
                    }
                    return true
                  }}
                />
              )
            return (
              <Tab
                value={el.value}
                counter={el.counter}
                key={el.id}
                id={el.id}
                selected={el.id === selected}
                disabled={el.disabled}
                onMount={registerRef}
                onClick={() => {
                  if (uploadingDocument) {
                    return null
                  }
                  setSelected(el.id)
                  if (typeof onTabClick === 'function') {
                    onTabClick(el.id)
                  }
                  return true
                }}
                fitted={fitted}
                textOverflow={el.textOverflow}
              />
            )
          })}
        {!segment && <Indicator $width={width} $x={x} />}
      </Container>
      {tabContent}
    </>
  )
}

const Indicator = styled.div`
  height: 2px;
  position: absolute;
  width: ${({ $width }) => $width}px;
  background: ${({ theme }) => theme.semantic.palette.border['border-contrast']};
  bottom: 0;
  left: ${({ $x }) => $x}px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  z-index: 1;
`

export default styled(Tabs)``
