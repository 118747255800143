/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'

import { Navigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { Spinner } from '../components/atoms'
import { selectAccount, selectApplication } from '../store/selectors'

const PrivateRoute = ({ children, ...props }) => {
  const { pathname } = window.location
  if (pathname !== '/login') localStorage.setItem('redirectTo', window.location.pathname)
  const selectCurrentUserAndEncryptionLoading = createSelector(
    [selectAccount, selectApplication],
    (account, application) => ({
      currentUser: account.currentUser,
      encryptionLoading: application.encryptionLoading,
    }),
  )

  const { currentUser, encryptionLoading } = useSelector(selectCurrentUserAndEncryptionLoading)

  // we need to wait 1 tick for account information to be set in redux store
  const [firstTick, setFirstTick] = useState(true)
  useEffect(() => {
    if (firstTick) {
      setFirstTick(false)
    }
  }, [firstTick])

  if (firstTick) {
    return <Spinner />
  }

  if (currentUser) {
    if (encryptionLoading) {
      return <Spinner />
    }
    return children
  }

  if (!currentUser && !firstTick) {
    return <Navigate to="/login" />
  }

  return <Spinner />
}

export default PrivateRoute
