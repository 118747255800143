import { useCallback, useEffect, useState } from 'react'
import compact from 'lodash/compact'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import find from 'lodash/find'

import TextInput from './TextInput'

const localeConfig = {
  en: {
    id: 'en-GB',
    delimeter: ',',
    delimeterRegex: /,/g,
    digitsDelimeter: '.',
  },
  de: {
    id: 'de-DE',
    delimeter: '.',
    delimeterRegex: /\./g,
    digitsDelimeter: ',',
  },
  fr: {
    id: 'fr-FR',
    delimeter: '.',
    delimeterRegex: /\./g,
    digitsDelimeter: ',',
  },
}

const getNumberOfChactersInString = (str, chr) => str.split('').filter((c) => c === chr).length

const FormatNumberInput = ({
  prefix,
  suffix,
  defaultValue,
  onChange,
  max,
  min,
  canBeEmpty,
  ...props
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const [lastLang, setLastLang] = useState(language)
  const preformatValue = useCallback(
    (value = '') =>
      new Intl.NumberFormat(localeConfig[language].id).format(
        Number.parseFloat(value.replace(',', '.').replace('-', ''), 10),
      ),
    [language],
  )

  const formatValue = useCallback(
    (value = '', options = {}) => {
      const values = compact([
        prefix,
        prefix ? ' ' : undefined,
        options?.negative ? '-' : undefined,
        preformatValue(value),
        options?.suffix,
        suffix ? ' ' : undefined,
        suffix,
      ])
      return values.join('')
    },
    [prefix, preformatValue, suffix],
  )

  const setValueFunc = useCallback(
    (newValue) => {
      return newValue === '' && canBeEmpty
        ? ''
        : formatValue(`${newValue}`, {
            negative: `${newValue}`.indexOf('-') !== -1,
          })
    },
    [canBeEmpty, formatValue],
  )

  const [value, setValue] = useState(setValueFunc(defaultValue))

  useEffect(() => {
    if (defaultValue !== value && (value === '' || value === '-')) {
      setValue(setValueFunc(defaultValue))
    }
  }, [defaultValue, setValueFunc, value])

  useEffect(() => {
    if (defaultValue !== value && defaultValue === '') {
      setValue(setValueFunc(defaultValue))
    }
  }, [defaultValue, setValueFunc, value])

  const onInnerChange = useCallback(
    (e) => {
      let newValue = e.target.value
        .replace(suffix, '')
        .replace(prefix, '')
        .replace(localeConfig[lastLang].delimeterRegex, '')
        .trim()
      if (lastLang !== language) setLastLang(language)

      const isValueNegative = newValue.indexOf('-') !== -1

      if (isValueNegative) {
        newValue = newValue.replace('-', '')
      }

      const valueAsNumber = Number.parseFloat(newValue)
      if (valueAsNumber > max || valueAsNumber < min) {
        return
      }

      if (getNumberOfChactersInString(newValue, localeConfig[language].digitsDelimeter) > 1) {
        // should not be 2 delimeters in one string
        return
      }

      if (newValue[newValue.length - 1] === localeConfig[language].digitsDelimeter) {
        setValue(
          formatValue(newValue, {
            suffix: localeConfig[language].digitsDelimeter,
            negative: isValueNegative,
          }),
        )
        return
      }

      if (
        newValue[newValue.length - 2] === localeConfig[language].digitsDelimeter &&
        newValue[newValue.length - 1] === '0'
      ) {
        setValue(
          formatValue(newValue, {
            suffix: `${localeConfig[language].digitsDelimeter}0`,
            negative: isValueNegative,
          }),
        )
        return
      }

      if (
        newValue[newValue.length - 3] === localeConfig[language].digitsDelimeter &&
        newValue[newValue.length - 2] === '0' &&
        newValue[newValue.length - 1] === '0'
      ) {
        setValue(
          formatValue(newValue, {
            suffix: `${localeConfig[language].digitsDelimeter}00`,
            negative: isValueNegative,
          }),
        )
        return
      }

      if (Number.isNaN(newValue) || !Number.parseFloat(newValue)) {
        if (canBeEmpty && newValue !== '0') {
          newValue = ''
        } else {
          newValue = '0'
        }
      }

      let valueResult = formatValue(newValue, { negative: isValueNegative })

      if (newValue === '') {
        if (isValueNegative) {
          valueResult = '-'
        } else {
          valueResult = ''
        }
      }
      setValue(valueResult)

      onChange(
        valueResult
          .replace(prefix, '')
          .replace(localeConfig[language].delimeterRegex, '')
          .replace(/,/, '.')
          .trim(),
      )
    },
    [canBeEmpty, formatValue, language, lastLang, max, min, onChange, prefix, suffix],
  )

  useEffect(() => {
    if (lastLang !== language) {
      onInnerChange({ target: { value } })
    }
  }, [language, lastLang, onInnerChange, value])

  // lets take a look at delimeters for other locales as well
  const onKeyUp = useCallback(
    (e) => {
      const { key } = e

      if (find(localeConfig, (c, lang) => c.digitsDelimeter === key && lang !== language)) {
        onInnerChange({
          target: { value: e.target.value + localeConfig[language].digitsDelimeter },
        })
      }
    },
    [language, onInnerChange],
  )

  return <TextInput value={value} {...props} onChange={onInnerChange} onKeyUp={onKeyUp} />
}

export default styled(FormatNumberInput)`
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'inherit')};
`
