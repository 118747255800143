import { WORKSPACE_ROLES } from '../enums'
import { FOLDER_ACCESS_PROFILES } from '../enums/folderAccessProfiles'

export default {
  en: {
    workspace: 'Workspace',
    workspaces: 'Workspaces',
    defaultWorkspace: 'My contracts',
    import: 'Import',
    export: 'Export',
    importExport: 'Import / Export',
    SelectWorkspace: 'Select the workspace or folder where the contracts should be imported into.',
    basicImportDescription: 'Simple and fast import of contracts',
    importing: 'Importing...',
    importCompleted: 'Import of contracts is complete',
    importCompletedDescription: 'Contracts are placed in the “{{workspace}}”.',
    importErrorFiles: '1 contract was not imported',
    importErrorFiles_other: '{{count}} contracts were not imported',
    completed: 'Completed',
    [WORKSPACE_ROLES.ADMIN]: 'Maintainer',
    [`${WORKSPACE_ROLES.ADMIN}Desc`]: 'Can manage workspace settings.',
    [WORKSPACE_ROLES.VIEWER]: 'Viewer',
    [`${WORKSPACE_ROLES.VIEWER}Desc`]: 'Can view contracts.',
    [WORKSPACE_ROLES.EDITOR]: 'Editor',
    [`${WORKSPACE_ROLES.EDITOR}Desc`]: 'Can create and edit contracts.',
    [WORKSPACE_ROLES.MEMBER]: 'Employee',
    [WORKSPACE_ROLES.ADMIN_COMPANY]: 'Admin',
    [WORKSPACE_ROLES.CREATOR]: 'Creator',
    restrictModalHeader: "You don't have access to this place",
    restrictModalDescription: 'Contact your administrator to get access.',
    delete: 'Delete workspace',
    deleteDescription:
      'Deleting this workspace will cause all workspace members to lose access. This cannot be undone.',
    createWorkspace: 'Create workspace',
    workspaceName: 'Workspace name',
    workspaceDescription: 'Organize your contracts by groups or teams in one place.',
    workspaceNameRequired: 'Name is required',
    AddMoreFiles: 'Add more files',
    UploadDetails: 'Upload details',
    RemoveFromWorkspace: 'Remove from workspace',
    DeleteWorkspace: 'Delete workspace?',
    DeletingThisWorkspace:
      'Deleting this workspace will cause all members to lose access to it. Contracts from this workspace will continue to appear in "My contracts" for each contract participant. This cannot be undone.',
    WillRemoveWorkspaceAccess: 'Will remove workspace access.',
    DragAndDropFilesToImportOr: 'Drag and drop files to import, or',
    UseFoldersToCreateContracts: 'Use folders to create contracts \nwith multiple documents.',
    browse: 'browse',
    DragAndDropFilesDirectly:
      'Drag and drop files directly into workspace to quickly import contracts.',
    DirectImport: 'Direct import',
    UseFolders: 'Use folders 📁 to create contracts with multiple documents 📑.',
    predefinedSignFlowTitle: 'Predefined signature workflow',
    predefinedSignFlowDescription:
      'This contract follows a predefined signature workflow. The contract issuers can only sign after all external parties have signed.',
    predefinedSignFlowDescriptionShort:
      'You will be able to sign, as soon as all external parties have signed. You will get a notification.',
    customizeFields: 'Customize Fields',
    customizeFieldsDescription1: 'Customize which fields will be displayed in the Contract Table.',
    customizeFieldsDescription2:
      'When the field is disabled, it’s still part of a contract and is displayed in the contract details.',
    NewWorkspace: 'New workspace',
    NewFolder: 'New folder',
    CreateNewFolder: 'Create new folder',
    CreateNewSubFolder: 'Create new subfolder',
    FolderName: 'Folder name',
    FolderDeleted: 'Folder deleted',
    deleteFolder: 'Delete folder?',
    FolderCreated: 'Folder created',
    Destination: 'Destination',
    Next: 'Next',
    ChangeRole: 'Change role?',
    ChangeRoleDesc:
      'All permissions of this user will be overridden with the new role. Ownerships will be preserved.',
    HasFullAccess: 'Has full access to the entire company. Can create workspaces.',
    CanCreateAndSign: 'Can create and sign contracts.',
    creator: 'Creator',
    CanOnlyCreateContracts: 'Can only create contracts.',
    YouCanChangeCompanyRole:
      'You can change company role after user has finished the registration.',
    ChangeRoleToAdmin: 'Change role to Admin?',
    ChangeRoleToAdminDescription:
      'This user will get full permissions for company management, company workspaces and see all contracts.',
    FolderNameUpdated: 'Folder name updated',
    ToWorkspace: 'To workspace',
    GrantAccessToWorkspace: 'Grant access to workspace.',
    ToWorkspaceAndFolders: 'To workspace and folders',
    GrantAccessToWorkspaceAndExistingFolders: 'Grant access to workspace and existing folders.',
    createMainFolderCheckbox: 'Add all members of the workspace to this folder.',
    createSubFolderCheckbox: 'Add all persons who have access to the parent folder to this folder.',
    AreYouSureAllSubFolders:
      'Are you sure? All subfolders will be deleted and workspace members will lose access to contracts inside. These contracts will still be available in "My contracts" for each contract participant.',
    willNotBeAbleToManageCompany:
      '{{name}} will not be able to manage company, will loose access to all workspaces and will see only personally shared contracts.',
    SelectNewOwnerForFolders: 'Select a new owner for the folders {{name}} owned.',
    RemoveAdminRoleNew: 'Remove Admin role?',
    RemoveFromWorkspaceNew: 'Remove from workspace?',
    UserWillLoseAccessToWorkspace:
      '{{userName}} will lose access to the "{{wsTitle}}" workspace. Workspace contracts in which {{firstName}} participates will be available in "My contracts".',
    WorkspaceAccessRemoved: 'Workspace access removed',
    UserHasLostAccessToWorkspace: '{{userName}}  has lost access to the "{{wsTitle}}" workspace.',
    ManageAccess: 'Manage access',
    SearchPersons: 'Search persons',
    WithAccess: 'With access',
    WithoutAccess: 'Without access',
    Owner: 'Owner',
    Editor: 'Editor',
    Viewer: 'Viewer',
    NoAccess: 'No access',
    GeneralAccess: 'General access',
    WorkspaceMaintainersAndOwners:
      'Workspace maintainers and owners of parent folders also have access to this folder. These users can manage folder and create contracts.',
    WorkspaceMaintainers: 'Workspace maintainers',
    OwnersOfParentFolders: 'Owners of parent folders',
    CanManageThisFolder: 'Can manage this folder and create contracts in it.',
    CanViewContracts: 'Can view contracts in this folder.',
    RemoveAccessToThisFolder: 'Remove access to this folder.',
    ChangeToEditor: 'Change to Editor?',
    ChangeToViewer: 'Change to Viewer?',
    YouCanChangePermissions:
      'You can change permissions of {{userName}} for this folder and all subfolders, including those currently not accessible. Folder ownerships will be preserved.',
    ChangePermissionsFor: 'Change permissions for',
    OnlyThisFolder: 'Only this folder',
    ThisFolderAndSubfoldersAccessible: 'This folder and subfolders accessible to {{firstName}}',
    ThisFolderAndAllSubfolders:
      'This folder and all subfolders, including those not accessible to {{firstName}}',
    RemoveAccess: 'Remove access?',
    YouCanRemoveAccessOfName:
      'You can remove access of {{userName}} for this folder and additionaly for all subfolders. Folder ownerships will be preserved.',
    RemoveAccessTo: 'Remove access to',
    ThisFolderAndSubfolders: 'This folder and subfolders',
    FolderAccessRemoved: 'Folder access removed',
    userNameHasLostAccessToTheFolderName:
      '{{userName}} has lost access to the “{{folderTitle}}” folder.',
    [FOLDER_ACCESS_PROFILES.EDITOR]: 'Editor',
    [FOLDER_ACCESS_PROFILES.VIEWER]: 'Viewer',
    [FOLDER_ACCESS_PROFILES.OWNER]: 'Owner',
    Columns: 'Columns',
    renameFolder: 'Rename folder',
  },
  de: {
    workspace: 'Arbeitsbereich',
    workspaces: 'Arbeitsbereiche',
    defaultWorkspace: 'Meine Verträge',
    import: 'Import',
    export: 'Export',
    importExport: 'Import / Export',
    SelectWorkspace:
      'Wähle den Arbeitsbereich oder Ordner, in den die Verträge importiert werden sollen.',
    basicImportDescription: 'Einfacher und schneller Import von Verträgen',
    importing: 'Importieren...',
    importCompleted: 'Import von Verträgen ist abgeschlossen',
    importCompletedDescription: 'Verträge sind im {{workspace}} abgelegt.',
    importErrorFiles: '1 Vertrag wurde nicht importiert',
    importErrorFiles_other: '{{count}} Verträge wurden nicht importiert',
    completed: 'Abgeschlossen',
    [WORKSPACE_ROLES.ADMIN]: 'Verwalter',
    [`${WORKSPACE_ROLES.ADMIN}Desc`]: 'Können Einstellungen des Arbeitsbereichs verwalten.',
    [WORKSPACE_ROLES.VIEWER]: 'Betrachter',
    [`${WORKSPACE_ROLES.VIEWER}Desc`]: 'Können Verträge einsehen.',
    [WORKSPACE_ROLES.EDITOR]: 'Ersteller',
    [`${WORKSPACE_ROLES.EDITOR}Desc`]: 'Können Verträge erstellen und bearbeiten.',
    [WORKSPACE_ROLES.MEMBER]: 'Mitarbeiter',
    [WORKSPACE_ROLES.ADMIN_COMPANY]: 'Admin',
    [WORKSPACE_ROLES.CREATOR]: 'Ersteller',
    restrictModalHeader: 'Du hast keinen Zugriff auf diesen Ort',
    restrictModalDescription: 'Wende dich an den Administrator, um Zugang zu erhalten.',
    delete: 'Arbeitsbereich löschen',
    deleteDescription:
      'Das Löschen dieses Arbeitsbereichs hat zur Folge, dass alle Nutzer des Arbeitsbereichs den Zugriff dazu verlieren. Dies kann nicht rückgängig gemacht werden.',
    createWorkspace: 'Arbeitsbereich erstellen',
    workspaceName: 'Name des Arbeitsbereichs',
    workspaceDescription:
      'Organisiere deine Verträge nach Gruppen oder Teams und teile sie an einem Ort.',
    workspaceNameRequired: 'Name ist erforderlich',
    AddMoreFiles: 'Weitere Dateien hinzufügen',
    UploadDetails: 'Hochladedetails',
    RemoveFromWorkspace: 'Aus dem Arbeitsbereich löschen',
    DeleteWorkspace: 'Arbeitsbereich löschen?',
    DeletingThisWorkspace:
      'Wenn du diesen Arbeitsbereich löschst, verlieren alle Mitglieder den Zugang zu diesem Arbeitsbereich. Verträge aus diesem Arbeitsbereich werden weiterhin in "Meine Verträge" für jeden Vertragsteilnehmer sichtbar sein. Dies kann nicht rückgängig gemacht werden.',
    WillRemoveWorkspaceAccess: 'Entfernt den Zugang zum Arbeitsbereich.',
    DragAndDropFilesToImportOr: 'Drag & Drop von Dateien zum Importieren oder',
    UseFoldersToCreateContracts:
      'Benutze Ordner, um Verträge mit mehreren \nDokumenten zu erstellen',
    browse: 'Durchsuchen',
    DragAndDropFilesDirectly:
      'Füge Dateien per Drag & Drop direkt in den Arbeitsbereich ein, um Verträge schnell zu importieren.',
    DirectImport: 'Direktimport',
    UseFolders: 'Wähle Ordner 📁, um Verträge mit mehreren Dokumenten zu erstellen 📑.',
    predefinedSignFlowTitle: 'Vordefinierter Signatur-Workflow',
    predefinedSignFlowDescription:
      'Dieser Vertrag folgt einem vordefinierten Signatur-Workflow. Die Vertragsersteller können erst unterschreiben, nachdem alle externen Parteien unterzeichnet haben.',
    predefinedSignFlowDescriptionShort:
      'Sie können unterschreiben, sobald alle externen Parteien unterzeichnet haben. Sie erhalten eine Benachrichtigung.',
    customizeFields: 'Felder anpassen',
    customizeFieldsDescription1:
      'Lege fest, welche Felder in der Vertragstabelle angezeigt werden.',
    customizeFieldsDescription2:
      'Wenn das Feld deaktiviert ist, ist es dennoch Teil eines Vertrags und wird in den Vertragsdetails angezeigt.',
    NewWorkspace: 'Neuer Arbeitsbereich',
    NewFolder: 'Neuer Ordner',
    CreateNewFolder: 'Neuen Ordner erstellen',
    CreateNewSubFolder: 'Neuen Unterordner erstellen',
    FolderName: 'Ordnername',
    FolderDeleted: 'Ordner gelöscht',
    deleteFolder: 'Ordner löschen?',
    FolderCreated: 'Ordner erstellt',
    Destination: 'Ziel',
    Next: 'Weiter',
    ChangeRole: 'Rolle ändern?',
    ChangeRoleDesc:
      'Die neue Rolle überschreibt alle Berechtigungen dieses Nutzers. Inhaberrechte bleiben erhalten.',
    HasFullAccess:
      'Hat vollen Zugriff auf das gesamte Unternehmen. Kann Arbeitsbereiche erstellen.',
    CanCreateAndSign: 'Kann Verträge erstellen und unterzeichnen.',
    creator: 'Ersteller',
    CanOnlyCreateContracts: 'Kann nur Verträge erstellen.',
    YouCanChangeCompanyRole:
      'Du kannst die Unternehmensrolle ändern, nachdem der Benutzer die Registrierung abgeschlossen hat.',
    ChangeRoleToAdmin: 'Rolle auf Admin ändern?',
    ChangeRoleToAdminDescription:
      'Dieser Benutzer erhält volle Zugriffsrechte für die Unternehmensverwaltung, Arbeitsbereiche des Unternehmens und kann alle Verträge einsehen.',
    FolderNameUpdated: 'Ordner umbenannt',
    ToWorkspace: 'Zum Arbeitsbereich',
    GrantAccessToWorkspace: 'Zugriff auf Arbeitsbereich gewähren.',
    ToWorkspaceAndFolders: 'Zum Arbeitsbereich und Ordnern',
    GrantAccessToWorkspaceAndExistingFolders:
      'Zugriff auf Arbeitsbereich und vorhandene Ordner gewähren.',
    createMainFolderCheckbox: 'Alle Mitglieder des Arbeitsbereichs zu diesem Ordner hinzufügen.',
    createSubFolderCheckbox:
      'Alle Personen, die Zugriff auf den übergeordneten Ordner haben, zu diesem Ordner hinzufügen.',
    AreYouSureAllSubFolders:
      'Bist du dir sicher? Alle Unterordner werden gelöscht und Arbeitsbereichsmitglieder werden Zugriff auf die darin enthaltenen Verträge verlieren. Diese Verträge werden weiterhin für jeden Vertragsteilnehmer unter "Meine Verträge" verfügbar sein.',
    willNotBeAbleToManageCompany:
      '{{name}} wird nicht in der Lage sein, das Unternehmen zu verwalten, verliert den Zugriff auf alle Arbeitsbereiche und wird nur persönlich freigegebene Verträge sehen.',
    SelectNewOwnerForFolders: 'Wähle einen neuen Besitzer für die Ordner, die {{name}} gehörten.',
    RemoveAdminRoleNew: 'Admin-Rolle entfernen?',
    RemoveFromWorkspaceNew: 'Aus dem Arbeitsbereich löschen?',
    UserWillLoseAccessToWorkspace:
      '{{userName}} wird Zugriff auf den Arbeitsbereich "{{wsTitle}}" verlieren. Arbeitsbereichsverträge, an denen {{firstName}} mitwirkt, werden unter "Meine Verträge" verfügbar sein.',
    WorkspaceAccessRemoved: 'Arbeitsbereichszugriff entfernt',
    UserHasLostAccessToWorkspace:
      '{{userName}} hat den Zugriff auf den Arbeitsbereich "{{wsTitle}}" verloren.',
    ManageAccess: 'Zugriff verwalten',
    SearchPersons: 'Nach Personen suchen',
    WithAccess: 'Mit Zugriff',
    WithoutAccess: 'Ohne Zugriff',
    Owner: 'Inhaber',
    Editor: 'Editor',
    Viewer: 'Betrachter',
    NoAccess: 'Kein Zugriff',
    GeneralAccess: 'Genereller Zugriff',
    WorkspaceMaintainersAndOwners:
      'Arbeitsbereichsverwalter und Besitzer von übergeordneten Ordnern haben auch Zugriff auf diesen Ordner. Diese Benutzer können Ordner verwalten und Verträge erstellen.',
    WorkspaceMaintainers: 'Arbeitsbereichsverwalter',
    OwnersOfParentFolders: 'Besitzer von übergeordneten Ordnern',
    CanManageThisFolder: 'Kann diesen Ordner verwalten und Verträge darin erstellen.',
    CanViewContracts: 'Kann Verträge in diesem Order sehen.',
    RemoveAccessToThisFolder: 'Zugriff auf diesen Ordner entfernen.',
    ChangeToEditor: 'Auf Editor ändern?',
    ChangeToViewer: 'Auf Betrachter ändern?',
    YouCanChangePermissions:
      'Du kannst die Berechtigungen von {{userName}} für diesen Ordner und alle Unterordner ändern, auch für jene, auf die derzeit kein Zugriff besteht. Die Besitzrechte an den Ordnern bleiben erhalten.',
    ChangePermissionsFor: 'Berechtigungen ändern für',
    OnlyThisFolder: 'Nur diesen Ordner',
    ThisFolderAndSubfoldersAccessible:
      'Diesen Ordner und die für {{firstName}} zugänglichen Unterordner',
    ThisFolderAndAllSubfolders:
      'Diesen Ordner und alle Unterordner, inklusive für {{firstName}} nicht zugängliche Ordner',
    RemoveAccess: 'Zugriff entfernen?',
    YouCanRemoveAccessOfName:
      'Du kannst den Zugriff von {{userName}} für diesen Ordner und zusätzlich für alle Unterordner entfernen. Die Besitzrechte an den Ordnern bleiben erhalten.',
    RemoveAccessTo: 'Zugriff entfernen für',
    ThisFolderAndSubfolders: 'Diesen Ordner und Unterordner',
    FolderAccessRemoved: 'Ordnerzugriff entfernt',
    userNameHasLostAccessToTheFolderName:
      '{{userName}} hat den Zugriff auf den Ordner "{{folderTitle}}" verloren.',
    [FOLDER_ACCESS_PROFILES.EDITOR]: 'Editor',
    [FOLDER_ACCESS_PROFILES.VIEWER]: 'Betrachter',
    [FOLDER_ACCESS_PROFILES.OWNER]: 'Inhaber',
    Columns: 'Spalten',
    renameFolder: 'Ordner umbenennen',
  },
  fr: {
    workspace: 'Espace de travail',
    workspaces: 'Espaces de travail',
    defaultWorkspace: 'Mes contrats',
    import: 'Importation',
    export: 'Exportation',
    importExport: 'Importation / Exportation',
    SelectWorkspace:
      "Sélectionnez l'espace de travail ou le dossier dans lequel les contrats doivent être importés",
    basicImportDescription: 'Importation simple et rapide des contrats',
    importing: 'Importation...',
    importCompleted: "L'importation des contrats est terminée",
    importCompletedDescription: 'Les contrats sont placés dans le “{{workspace}}”.',
    importErrorFiles: "1 contrat n'a pas été importé",
    importErrorFiles_other: "{{count}} contrats n'ont pas été importés",
    completed: 'Terminé',
    [WORKSPACE_ROLES.ADMIN]: 'Gestionnaire',
    [`${WORKSPACE_ROLES.ADMIN}Desc`]: "Peut gérer les paramètres de l'espace de travail.",
    [WORKSPACE_ROLES.VIEWER]: 'Spectateur',
    [`${WORKSPACE_ROLES.VIEWER}Desc`]: 'Peut consulter les contrats.',
    [WORKSPACE_ROLES.EDITOR]: 'Éditeur',
    [`${WORKSPACE_ROLES.EDITOR}Desc`]: 'Peut créer et modifier des contrats.',
    [WORKSPACE_ROLES.MEMBER]: 'Employé',
    [WORKSPACE_ROLES.ADMIN_COMPANY]: 'Administrateur',
    [WORKSPACE_ROLES.CREATOR]: 'Créateur',
    restrictModalHeader: "Vous n'avez pas accès à cet endroit",
    restrictModalDescription: "Contactez votre administrateur pour obtenir l'accès.",
    delete: "Supprimer l'espace de travail",
    deleteDescription:
      "La suppression de cet espace de travail entraîne la perte d'accès pour tous les membres de l'espace de travail. Il n'est pas possible d'annuler cette suppression.",
    createWorkspace: 'Créer un espace de travail',
    workspaceName: "Nom de l'espace de travail",
    workspaceDescription: 'Organisez vos contrats par groupes ou équipes en un seul endroit.',
    workspaceNameRequired: 'Le nom est obligatoire',
    AddMoreFiles: 'Ajouter des fichiers',
    UploadDetails: 'Télécharger les détails',
    RemoveFromWorkspace: "Supprimer de l'espace de travail",
    DeleteWorkspace: "Supprimer l'espace de travail?",
    DeletingThisWorkspace:
      "En supprimant cet espace de travail, tous les membres n'y auront plus accès. Les contrats de cet espace de travail continueront d'apparaître dans Mes contrats pour chaque participant au contrat. Il n'est pas possible de revenir en arrière.",
    WillRemoveWorkspaceAccess: "Supprime l'accès à l'espace de travail.",
    DragAndDropFilesToImportOr: 'Glisser-déposer les fichiers à importer, ou',
    UseFoldersToCreateContracts:
      'Utiliser des dossiers pour créer des contrats \nwith plusieurs documents.',
    browse: 'chercher',
    DragAndDropFilesDirectly:
      "Glisser-déposer des fichiers directement dans l'espace de travail pour importer rapidement des contrats.",
    DirectImport: 'Importation directe',
    UseFolders: 'Utiliser des dossiers 📁 pour créer des contrats avec plusieurs documents 📑.',
    predefinedSignFlowTitle: 'Processus de signature prédéfini',
    predefinedSignFlowDescription:
      "Ce contrat suit un processus de signature prédéfini. Les émetteurs du contrat ne peuvent signer qu'une fois que toutes les parties externes ont signé.",
    predefinedSignFlowDescriptionShort:
      'Vous pourrez signer dès que toutes les parties externes auront signé. Vous recevrez une notification.',
    customizeFields: 'Personnaliser les champs',
    customizeFieldsDescription1:
      'Personnaliser les champs qui seront affichés dans le tableau des contrats.',
    customizeFieldsDescription2:
      'Lorsque le champ est désactivé, il fait toujours partie du contrat et est affiché dans les détails du contrat.',
    NewWorkspace: 'Nouvel espace de travail',
    NewFolder: 'Nouveau dossier',
    CreateNewFolder: 'Créer un nouveau dossier',
    CreateNewSubFolder: 'Créer un nouveau sous-dossier',
    FolderName: 'Nom du dossier',
    FolderDeleted: 'Dossier supprimé',
    deleteFolder: 'Supprimer le dossier?',
    FolderCreated: 'Dossier créé',
    Destination: 'Destination',
    Next: 'Suivant',
    ChangeRole: 'Changer de rôle?',
    ChangeRoleDesc:
      'Toutes les autorisations de cet utilisateur seront remplacées par le nouveau rôle. Les droits de propriété seront conservés.',
    HasFullAccess:
      "A un accès complet à l'ensemble de l'entreprise. Peut créer des espaces de travail.",
    CanCreateAndSign: 'Peut créer et signer des contrats.',
    creator: 'Createur',
    CanOnlyCreateContracts: 'Ne peut créer que des contrats.',
    YouCanChangeCompanyRole:
      "Vous pouvez modifier le rôle de l'entreprise une fois que l'utilisateur a terminé l'enregistrement.",
    ChangeRoleToAdmin: 'Modifier le rôle en administeur?',
    ChangeRoleToAdminDescription:
      "Cet utilisateur aura toutes les permissions pour la gestion de l'entreprise, les espaces de travail de l'entreprise et la visualisation de tous les contrats.",
    FolderNameUpdated: 'Mise à jour du nom du dossier',
    ToWorkspace: "Vers l'espace de travail",
    GrantAccessToWorkspace: "Accorder l'accès à l'espace de travail.",
    ToWorkspaceAndFolders: "Vers l'espace de travail et les dossiers",
    GrantAccessToWorkspaceAndExistingFolders:
      "Autoriser l'accès à l'espace de travail et aux dossiers existants.",
    createMainFolderCheckbox: "Ajouter tous les membres de l'espace de travail à ce dossier.",
    createSubFolderCheckbox:
      'Ajouter à ce dossier toutes les personnes qui ont accès au dossier parent.',
    AreYouSureAllSubFolders:
      "En êtes-vous sûr ? Tous les sous-dossiers seront supprimés et les membres de l'espace de travail n'auront plus accès aux contrats qui s'y trouvent. Ces contrats seront toujours disponibles dans Mes contrats pour chaque participant au contrat.",
    willNotBeAbleToManageCompany:
      "{{name}} ne pourra pas gérer l'entreprise, perdra l'accès à tous les espaces de travail et ne verra que les contrats partagés personnellement.",
    SelectNewOwnerForFolders:
      'Sélectionner un nouveau propriétaire pour les dossiers {{name}} possédés.',
    RemoveAdminRoleNew: "Supprimer le rôle d'administrateur?",
    RemoveFromWorkspaceNew: "Supprimer de l'espace de travail?",
    UserWillLoseAccessToWorkspace:
      "{{userName}} Supprimer de l'espace de travail'{{wsTitle}}' espace de travail. Les contrats d'espace de travail dans lesquels {{firstName}} sera disponible en ligne à l'adresse suivante Mes Contrats.",
    WorkspaceAccessRemoved: "Suppression de l'accès à l'espace de travail",
    UserHasLostAccessToWorkspace:
      "{{userName}} a perdu l'accès à la '{{wsTitle}}' espace de travail.",
    ManageAccess: "Gérer l'accès",
    SearchPersons: 'Personnes recherchées',
    WithAccess: "Avec l'accès",
    WithoutAccess: 'Sans accès',
    Owner: 'Propriétaire',
    Editor: 'Éditeur',
    Viewer: 'Visionneuse',
    NoAccess: "Pas d'accès",
    GeneralAccess: 'Accès général',
    WorkspaceMaintainersAndOwners:
      "Les responsables de l'espace de travail et les propriétaires des dossiers parents ont également accès à ce dossier. Ces utilisateurs peuvent gérer le dossier et créer des contrats.",
    WorkspaceMaintainers: "Responsables de l'espace de travail",
    OwnersOfParentFolders: 'Propriétaires des dossiers parents',
    CanManageThisFolder: 'Peut gérer ce dossier et y créer des contsrat.',
    CanViewContracts: 'Possibilité de consulter les contrats dans ce dossier.',
    RemoveAccessToThisFolder: "Supprimer l'accès à ce dossier.",
    ChangeToEditor: 'Changement de rédacteur?',
    ChangeToViewer: 'Passer à la visionneuse?',
    YouCanChangePermissions:
      'Vous pouvez modifier les autorisations de {{userName}}  pour ce dossier et tous les sous-dossiers, y compris ceux qui ne sont pas accessibles actuellement. Les propriétaires des dossiers seront conservés.',
    ChangePermissionsFor: 'Modifier les autorisations pour',
    OnlyThisFolder: 'Seul ce dossier',
    ThisFolderAndSubfoldersAccessible:
      'Ce dossier et les sous-dossiers accessibles aux {{firstName}}',
    ThisFolderAndAllSubfolders:
      'Ce dossier et tous les sous-dossiers, y compris ceux qui ne sont pas accessibles aux {{firstName}}',
    RemoveAccess: "Supprimer l'accès?",
    YouCanRemoveAccessOfName:
      "Vous pouvez supprimer l'accès à {{userName}} pour ce dossier et pour tous les sous-dossiers. Les propriétaires des dossiers seront préservés.",
    RemoveAccessTo: "Supprimer l'accès à",
    ThisFolderAndSubfolders: 'Ce dossier et ses sous-dossiers',
    FolderAccessRemoved: "Suppression de l'accès aux dossiers",
    userNameHasLostAccessToTheFolderName:
      "{{userName}} a perdu l'accès à la '{{folderTitle}}' dossier.",
    [FOLDER_ACCESS_PROFILES.EDITOR]: 'Editeur',
    [FOLDER_ACCESS_PROFILES.VIEWER]: 'Visionneuse',
    [FOLDER_ACCESS_PROFILES.OWNER]: 'Propriétaire',
    Columns: 'Colonnes',
    renameFolder: 'Renommé le dossier',
  },
}
