export const TASK_ASSIGNEE_STATE = {
  OPEN: 'open',
  COMPLETE: 'complete',
}

export const TASKS_STATE = {
  OPEN: 'open',
  COMPLETE: 'complete',
  PARTIAL: 'partially_complete',
}
