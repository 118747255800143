/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import isDate from 'lodash/isDate'

import ecies from 'eth-ecies'
import AesServices from '../services/aesServices'
import ShaService from '../services/shaService'
import getDocumentSecret from '../services/functions/getDocumentSecret'
import { callApi, uploadFile } from '../utils/callApi'
import { toUTC } from '../utils/formatDate'
import { updateProofs } from '../services/proofOutdated/updateProofs'
import { updateIncluded } from './included'
import { fetchDigitalAssetsDetails } from './digitalAssets'
import { FEATURES } from '../enums'

const fileHash = (fileBase64, daSecret) => {
  try {
    const shaService = new ShaService({ value: fileBase64 }).encrypt()
    const aesServEn = new AesServices({ file: fileBase64, key: daSecret }).encryptFile()
    return { shaService, aesServEn }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  return null
}

export const uploadDocument = createAsyncThunk(
  'digitalAsset/document/upload',
  async ({ file, e, digitalAsset, uploadProgress, docType, refersToId, callback }, api) => {
    try {
      const address = localStorage.getItem('user_address')
      const userpass = localStorage.getItem('user_secret')
      const token = api.extra.getToken()
      const { account } = api.getState()

      const { features } = account.currentUser
      // some time to update the UI before CPU is going to be locked by hashing and encryption
      await new Promise((r) => {
        setTimeout(r, 42)
      })

      const daId = digitalAsset.id
      const docSecret = await getDocumentSecret({ daId })

      const date = file.lastModifiedDate || new Date(file.lastModified)
      const fHashs = fileHash(e.target.result, docSecret)
      const filename = file.name.replace(file.extension, file.extension.toLowerCase())

      const metadata = {
        [fHashs.shaService]: {
          name: filename,
          size: file.size,
          modified: isDate(date) ? toUTC(date) : undefined,
          documentHash: fHashs.shaService,
          refersToId,
        },
      }
      const fileHashData = {
        name: fHashs.shaService,
        data: fHashs.aesServEn,
      }
      const sourceDaSecret = docSecret.toString('utf-8')
      const apiPublicKey = Buffer.from(process.env.REACT_APP_API_PUBLIC_KEY, 'hex')
      const sourceEncrDs = ecies.encrypt(apiPublicKey, sourceDaSecret).toString('base64')

      const formData = new FormData()
      formData.append('metadata', JSON.stringify(metadata))
      formData.append(`${fileHashData.name}`, fileHashData.data)
      if (features.includes(FEATURES.FulltextSearch)) {
        formData.append('searchindexpayload', JSON.stringify({ encrDocSecret: sourceEncrDs }))
      }

      const formDataSize = new Blob([...formData]).size

      const response = await uploadFile({
        endpoint: `digitalassets/${daId}/documents`,
        method: 'POST',
        applicationJson: false,
        disableDefHeaders: true,
        body: formData,
        accessToken: token,
        acceptType: 'application/vnd.api+json',
        accessName: 'Bearer',
        uploadProgress: (uploadedFileSize) => {
          const loaded = Math.round((uploadedFileSize / formDataSize) * 100)
          if (uploadProgress) uploadProgress(loaded)
        },
      })
      await updateProofs({
        data: response,
        address,
        userpass,
      })
      if (docType) {
        await api.extra.dispatchAsync(api.dispatch, changeDocType, {
          documentId: response.data[0].id,
          docType,
        })
      }
      api.extra.sendCallback(callback, response)
    } catch (e) {
      callback(null, e)
    }
  },
)

export const changeDocType = createAsyncThunk(
  'digitalAsset/document/change/type',
  async ({ docType, documentId, daId, callback }, api) => {
    const address = localStorage.getItem('user_address')
    const userpass = localStorage.getItem('user_secret')
    const json = {
      data: {
        type: 'documentsmetadata',
        attributes: {
          type: docType, // ADDED; either of ("e_signature","signed_offline", "other")
        },
      },
    }

    const response = await callApi({
      endpoint: `documentsmetadata/${documentId}`,
      method: 'PATCH',
      applicationJson: true,
      acceptType: 'application/vnd.api+json',
      body: json,
    })

    await updateProofs({
      data: response,
      address,
      userpass,
    })
    api.extra.sendCallback(callback, response)
    api.dispatch(
      updateIncluded(response?.included ? [response.data, ...response.included] : [response.data]),
    )
    if (daId) api.dispatch(fetchDigitalAssetsDetails({ id: daId }))
  },
)

export const removeDocument = createAsyncThunk(
  'digitalAsset/document/remove',
  async ({ digitalAssetId, documentId, callback }, api) => {
    const token = api.extra.getToken()

    const response = await callApi({
      endpoint: `digitalassets/${digitalAssetId}/documents/${documentId}`,
      method: 'DELETE',
      applicationJson: true,
      accessToken: token,
      accessName: 'Bearer',
      acceptType: 'application/json',
    })

    updateProofs({
      data: response,
      callback: () => api.extra.sendCallback(callback, response),
    })
  },
)

export const selectCustomField = createAction('document/field/selection')

export const setSelectTab = createAction('digitalAsset/selectTab')

export const setUploadDocs = createAsyncThunk(
  'digitalAsset/uploadDocs',
  async ({ uploadDocs, callback }, api) => {
    return uploadDocs
  },
)
export const setContainerUploadDocs = createAsyncThunk(
  'digitalAsset/uploadContainerDocs',
  async ({ uploadDocs, callback }, api) => {
    return uploadDocs
  },
)
