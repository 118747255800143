import styled from 'styled-components'

const DEFAULT_SIZE = 16
const BaseSvgMask = ({ icon, color, size, width, height, object, ...props }) => (
  <div data-test={`svgmask-icon-${icon}`} {...props} />
)

const SvgMask = styled(BaseSvgMask)`
  width: ${({ size, width }) => width || size || DEFAULT_SIZE}px;
  min-width: ${({ size, width }) => width || size || DEFAULT_SIZE}px;
  height: ${({ size, height }) => height || size || DEFAULT_SIZE}px;
  min-height: ${({ size, height }) => height || size || DEFAULT_SIZE}px;
  mask-image: url(${({ icon, size, object }) => {
    if (object) return `/img/icons/objects/${icon}.svg`
    if (size === 12) return `/img/icons/icons12px/${icon}.svg`
    return `/img/icons/icons16px/${icon}.svg`
  }});
  background-color: ${({ theme, color }) => color || theme.semantic.palette.icon.icon};
  mask-size: contain;
  cursor: ${({ cursor }) => cursor || undefined};
  mask-repeat: no-repeat;
  background-position: 50% 50%;
`

export default SvgMask

export const SvgImage = styled(BaseSvgMask)`
  width: ${({ width, size }) => width || size || DEFAULT_SIZE}px;
  min-width: ${({ width, size }) => width || size || DEFAULT_SIZE}px;
  height: ${({ height, size }) => height || size || DEFAULT_SIZE}px;
  min-height: ${({ height, size }) => height || size || DEFAULT_SIZE}px;
  background: url(${({ icon, size, object }) => {
    if (object) return `/img/icons/objects/${icon}.svg`
    if (size === 12) return `/img/icons/icons12px/${icon}.svg`
    return `/img/icons/icons16px/${icon}.svg`
  }});
  mask-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`
