import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { BasePopup, Button, Divider, Flex, Spacing } from '../../atoms'
import Typography from '../../atoms/Typography'
import MenuItem from './MenuItem'
import { selectApplication } from '../../../store/selectors'

const Help = () => {
  const theme = useTheme()
  const [target, setTarget] = useState(null)
  const [visible, setVisible] = useState(false)
  const {
    i18n: { language },
  } = useTranslation()
  const [newsCounter, setNewsCounter] = useState(0)
  const selectUserData = createSelector([selectApplication], (application) => ({
    produktlyInit: application.produktlyInit,
  }))

  const { produktlyInit } = useSelector(selectUserData)

  const changelogId = useMemo(() => {
    const langId = {
      en: 275,
      de: 146,
      fr: 276,
    }
    return langId[language] || 275
  }, [language])

  const getData = useCallback(async () => {
    const resp = await window.Produktly.getChangelogUnreadCount({ id: changelogId })
    setNewsCounter(resp)
  }, [changelogId])

  const openNews = async () => {
    await window.Produktly.openChangelog({ id: changelogId })
  }

  useEffect(() => {
    if (produktlyInit) {
      getData()
    }
  }, [getData, produktlyInit])

  return (
    <div ref={setTarget} data-support="help_section">
      <div style={{ position: 'relative' }}>
        {newsCounter > 0 && <HasUpdate />}
        <Button type="icon" icon="question-circle" onClick={() => setVisible(!visible)} />
      </div>
      {visible && (
        <BasePopup
          overlay
          target={target}
          options={{ placement: 'bottom-end', strategy: 'fixed' }}
          onClose={() => setVisible(false)}
          onClick={() => setVisible(false)}
          closeOnClick
        >
          <Flex gap={theme.space['space-050']} align="left" direction="column" width="100%">
            <Flex>
              <Spacing size={8} />
              <Typography
                style={{ textTransform: 'uppercase' }}
                i18n="sidebar:help"
                variant="body-small-01"
                color={theme.semantic.palette.text['text-disabled']}
              >
                help
              </Typography>
            </Flex>

            <Flex align="left" style={{ minWidth: '272px' }} direction="column">
              <ListItem
                i18n="sidebar:guide"
                value="User guide ↗"
                icon="book"
                route="https://help.inhubber.com"
                activeHover
                target="_blank"
              />
              <Spacing size={4} />
              <ListItem
                i18n="sidebar:WhatsNew"
                value="What’s new?"
                icon="list-text"
                activeHover
                onClick={() => {
                  openNews()
                  setNewsCounter(0)
                }}
              >
                {newsCounter > 0 && (
                  <UpdateCounter>
                    <Typography variant="body-medium-01" color={theme.palette.background.white}>
                      {newsCounter}
                    </Typography>
                  </UpdateCounter>
                )}
              </ListItem>
              <Spacing size={4} />
              <Divider />
              <Spacing size={4} />
              <ListItem
                i18n="sidebar:ContactUs"
                value="Contact us ↗"
                icon="bubble"
                route="mailto:support@inhubber.com"
                activeHover
                target="_blank"
              />
              <ListItem
                i18n="sidebar:WebSite"
                value="Web site ↗"
                icon="website"
                route="https://inhubber.com"
                activeHover
                target="_blank"
              />
            </Flex>
          </Flex>
        </BasePopup>
      )}
    </div>
  )
}

const HasUpdate = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-full']};
  background: ${({ theme }) => theme.semantic.palette.surface['surface-critical']};
  width: 8px;
  height: 8px;
  z-index: 999;
`

const UpdateCounter = styled(Flex)`
  border-radius: ${({ theme }) => theme.semantic.radius['border-radius-full']};
  background: ${({ theme }) => theme.semantic.palette.surface['surface-critical']};
  z-index: 999;
  padding: 0 ${({ theme }) => theme.semantic.space['space-2x-small']};
`

const ListItem = styled(MenuItem)`
  background: ${({ theme }) => theme.semantic.palette.surface.surface};

  &:hover {
    background: ${({ theme }) => theme.semantic.palette.surface['surface-hover']};
  }
`

export default Help
