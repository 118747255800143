import { createReducer } from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'

import { COMPANY_SUBSCRIPTION } from '../enums'
import {
  fetchCompanySubscription,
  setCompanyAdmins,
  setCompanyRoles,
  setCompanySettings,
  updateCompanyRoles,
} from '../actions/company'

const initialState = {
  admins: [],
  subscription: COMPANY_SUBSCRIPTION.SUBSCRIPTION_NOT_LOADED,
  settings: [],
  roles: {},
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCompanyAdmins, (state, action) => {
      return {
        ...state,
        admins: action.payload.data,
      }
    })
    .addCase(fetchCompanySubscription.fulfilled, (state, action) => {
      return {
        ...state,
        subscription: action.payload.subscription,
      }
    })
    .addCase(setCompanySettings, (state, action) => {
      return {
        ...state,
        settings: action.payload.data,
      }
    })
    .addCase(setCompanyRoles, (state, action) => {
      const newRoles = keyBy([...action.payload.data], 'id')
      return {
        ...state,
        roles: { ...state.roles, ...newRoles },
      }
    })
    .addCase(updateCompanyRoles, (state, action) => {
      const newRoles = keyBy([action.payload], 'id')
      return {
        ...state,
        roles: { ...state.roles, ...newRoles },
      }
    })
})

export default reducer
