export default {
  en: {
    document: '{{type}} Document',
    txt: 'Plain Text Document',
    word: 'Word Document',
    excel: 'Excel Document',
    image: '{{type}} Image',
    jpeg: 'JPEG Image',
    movie: '{{type}} Movie',
    audio: '{{type}} Audio',
    default: 'Document',
    ikf: 'Inhubber Template',
  },
  de: {
    document: '{{type}} Dokument',
    txt: 'Nur-Text-Dokument',
    word: 'Word Dokument',
    excel: 'Excel Dokument',
    image: '{{type}} Bild',
    jpeg: 'JPEG Bild',
    movie: '{{type}} Film',
    audio: '{{type}} Audio',
    default: 'Dokument',
    ikf: 'Inhubber Template',
  },
  fr: {
    document: '{{type}} Document',
    txt: 'Document Texte',
    word: 'Document Word',
    excel: 'Document Excel',
    image: '{{type}} Image',
    jpeg: 'Image JPEG',
    movie: '{{type}} Film',
    audio: '{{type}} Audio',
    default: 'Document',
    ikf: 'Inhubber Modéle',
  },
}
