import callApi from './callApi'

const callApiPagination = async (callOptions) => {
  const response = await callApi(callOptions)

  let result = { ...response }
  let nextLink = result.links?.next
  while (nextLink) {
    // eslint-disable-next-line no-await-in-loop
    const nextResponse = await callApi({ endpoint: nextLink.split('ui-api/')[1] })
    const included = nextResponse.included
      ? [...result.included, ...nextResponse.included]
      : result.included || []
    result = {
      data: [...result.data, ...nextResponse.data],
      included,
      meta: nextResponse.meta,
      links: nextResponse.links,
    }
    nextLink = nextResponse.links?.next
  }

  return result
}

export default callApiPagination
