import { useRef, useEffect, useState, useCallback } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  Spacing,
  Flex,
  Typography,
  Modal,
  SvgMask,
  Divider,
  Button,
  TextInput,
} from '../../../atoms'

const TextModal = ({ visible, onChange, ...props }) => {
  const [value, setValue] = useState('')
  const { palette } = useTheme()

  const onValueChange = useCallback((e) => setValue(e.target.value), [])
  const onAdd = useCallback(() => {
    if (typeof onChange !== 'function') {
      return
    }

    const FONT_SIZE = 48

    const canvas = window.document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    ctx.fillStyle = 'black'
    ctx.font = `${FONT_SIZE}px Inter`

    const metrics = ctx.measureText(value)

    canvas.width = metrics.width
    canvas.height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent

    ctx.fillStyle = 'black'
    ctx.font = `${FONT_SIZE}px Inter`

    ctx.fillText(value, 0, metrics.actualBoundingBoxAscent)

    onChange(canvas)
  }, [onChange, value])

  useEffect(() => {
    setValue('')
  }, [visible])

  return (
    <Modal
      visible={visible}
      header="Add your Text"
      buttons={
        <Button disabled={!value} onClick={value ? onAdd : undefined}>
          Add text
        </Button>
      }
      {...props}
    >
      <Spacing size="16" />
      <TextInput value={value} onChange={onValueChange} />
    </Modal>
  )
}

export default TextModal
