import styled from 'styled-components'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Elevation, Flex, Spacing, TextInput } from '../atoms'
import Modal from '../atoms/Modal/Modal'
import ShaService from '../../services/shaService'

const DeleteDeviceModal = ({ onClose, visible, onContinue, passwordError, deviceName }) => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const isButtonEnabled = useMemo(() => password, [password])
  useEffect(() => {
    if (visible) {
      setPassword('')
    }
  }, [visible, setPassword])

  const onInnerContinue = (e) => {
    const passwordHash = new ShaService({ value: password }).encrypt()
    if (typeof onContinue === 'function') {
      onContinue(e, passwordHash)
    }
  }
  return (
    <Container>
      <Modal
        header={t('DeleteDevice', { deviceName })}
        subHeaderI18n="passwordtodelete"
        onClose={onClose}
        visible={visible}
        type="oldModal"
        buttons={
          <>
            <Button i18n="cancel" secondary onClick={onClose}>
              Cancel
            </Button>
            <Button
              i18n="Delete"
              disabled={!isButtonEnabled}
              onClick={isButtonEnabled ? onInnerContinue : undefined}
              data-test="button_securityAndLogin_deleteDeviceModal_delete"
            >
              Delete
            </Button>
          </>
        }
        data-test="modal_securityAndLogin_deleteDeviceModal"
      >
        <Spacing size={24} />
        <TextInput
          type="password"
          placeholder={t('Password')}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          autoComplete="new-password"
          error={passwordError}
          validator={() => {
            return passwordError ? t('WrongPassword') : ''
          }}
          data-test="input_securityAndLogin_deleteDeviceModal_password"
        />
      </Modal>
    </Container>
  )
}
const Container = styled.div`
  ${Elevation} {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
  }
`

export default DeleteDeviceModal
