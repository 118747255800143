import styled from 'styled-components'

import { Link } from 'react-router-dom'
import { Flex, Typography } from '../atoms'

const Card = ({
  titleOnClick,
  title,
  label,
  children,
  disabled,
  link,
  onClick,
  labels,
  beforeIcon,
  className,
}) => {
  return (
    <Flex className={className} onClick={onClick}>
      <Flex content="center">{beforeIcon}</Flex>
      <DocumentContainer content="space-between" width="100%">
        <ContractTitle direction="column" align="flex-start" title={title}>
          {link ? (
            <Link to={link}>
              <Grid>
                <Title
                  color={disabled ? 'disabled' : 'primary'}
                  data-test="typography_link_contracts_title"
                >
                  {title}
                </Title>
              </Grid>
            </Link>
          ) : (
            <Grid>
              <Title
                color={disabled ? 'disabled' : 'primary'}
                onClick={(e) => {
                  if (!disabled && typeof titleOnClick === 'function') titleOnClick(e)
                }}
                data-test="typography_contract_document_openDocument"
              >
                {title}
              </Title>
            </Grid>
          )}
          <Grid>
            <Typography variant="body-medium-02" color={disabled ? 'disabled' : 'secondary'}>
              {label}
            </Typography>
            {labels}
          </Grid>
        </ContractTitle>
        {children}
      </DocumentContainer>
    </Flex>
  )
}

const ContractTitle = styled(Flex)`
  flex: auto;
`

export const DocumentContainer = styled(Flex)`
  gap: ${({ theme }) => theme.space['card-gap']};
  box-sizing: border-box;
`

const Grid = styled.div`
  display: grid;

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const Title = styled(Typography).attrs(() => ({ variant: 'body-large-01' }))`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`

export default styled(Card)`
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.space['card-padding']};
  gap: ${({ theme }) => theme.space['card-gap']};
  border-radius: ${({ theme }) => theme.radius['card-border-radius']};
  border: ${({ theme, disabled }) => {
    if (disabled) {
      return `1px solid ${theme.component.card.border['card-color-border-disabled']}`
    }
    return `1px solid ${theme.component.card.border['card-color-border']}`
  }};
  background: ${({ theme, disabled }) => {
    if (disabled) {
      return theme.component.card.border['card-color-surface-disabled']
    }
    return theme.component.card.surface['card-color-surface']
  }};
  ${({ theme, divider }) => !divider && theme.shadow.small};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  &:hover {
    background: ${({ theme }) => theme.component.card.surface['card-color-surface-hover']};
    border-radius: ${({ theme }) => theme.radius['card-border-radius-hover']};
  }
`
