export default {
  en: {
    selectedStatus: 'is $t({{name}})',
    selectedStatus_other: 'is any of {{count}} statuses',
    EditFilter: 'Edit filter',
    RemoveFilter: 'Remove filter',
    EarliestDate: 'Earliest date',
    LatestDate: 'Latest date',
    AtEarliestDate: 'at earliest {{date}}',
    AtLatestDate: 'at latest {{date}}',
    includeLabel: 'include {{name}}',
    includeLabel_other: 'include all of {{count}} labels',
    includeWorkspaces: 'is {{name}}',
    includeWorkspaces_other: 'is any of {{count}} workspaces',
    MinValueLabel: 'min {{value}}',
    MaxValueLabel: 'max {{value}}',
    MinValue: 'Min value',
    MaxValue: 'Max value',
    OwnerLabel: 'is {{fName}} {{lName}}',
    OwnerLabel_other: 'is any of {{count}} persons',
  },
  de: {
    selectedStatus: 'ist $t({{name}})',
    selectedStatus_other: 'ist einer von {{count}} Status',
    EditFilter: 'Filter bearbeiten',
    RemoveFilter: 'Filter entfernen',
    EarliestDate: 'Frühestes Datum',
    LatestDate: 'Spätestes Datum',
    AtEarliestDate: 'frühstens {{date}}',
    AtLatestDate: 'spätestens {{date}}',
    includeLabel: 'enthalten {{name}}',
    includeLabel_other: 'enthalten alle {{count}} Labels',
    includeWorkspaces: 'ist {{name}}',
    includeWorkspaces_other: 'ist einer von {{count}} Arbeitsbereichen',
    MinValueLabel: 'mindestens {{value}}',
    MaxValueLabel: 'höchstens {{value}}',
    MinValue: 'Min Wert',
    MaxValue: 'Max Wert',
    OwnerLabel: 'ist {{fName}} {{lName}}',
    OwnerLabel_other: 'ist einer von {{count}} Personen',
  },
  fr: {
    selectedStatus: 'est $t({{name}})',
    selectedStatus_other: "est l'un des {{count}} status",
    EditFilter: 'Editer le filtre',
    RemoveFilter: 'Supprimer le filtre',
    EarliestDate: 'Date la plus ancienne',
    LatestDate: 'Date la plus récente',
    AtEarliestDate: 'au tout début {{date}}',
    AtLatestDate: 'à la fin {{date}}',
    includeLabel: 'inclut {{name}}',
    includeLabel_other: 'inclut tous les {{count}} labels',
    includeWorkspaces: 'est {{name}}',
    includeWorkspaces_other: "est l'un des {{count}} espaces de travail.",
    MinValueLabel: 'min {{value}}',
    MaxValueLabel: 'max {{value}}',
    MinValue: 'Valeur Min',
    MaxValue: 'Valeur Max',
    OwnerLabel: 'est {{fName}} {{lName}}',
    OwnerLabel_other: "est l'une des {{count}} personnes",
  },
}
