import styled from 'styled-components'

const BaseTabs = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  width: ${({ fitted }) => fitted && '100%'};
  padding: ${({ fitted, theme }) => !fitted && `0 ${theme.semantic.space['space-2x-small']}`};

  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    height: 1px;
    background: ${({ theme }) => theme.semantic.palette.border.border};
  }
`

export default BaseTabs
