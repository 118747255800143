/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */

export const getEmailRegexp = () =>
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi

export const emailValidator = (value) => {
  const localEmailRegexp = getEmailRegexp()
  return value && localEmailRegexp.test(value) ? '' : INVALID_EMAIL
}

export const INVALID_EMAIL = 'InvalidEmail'

export const DEFAULT_DATE_FORMAT = 'dd MMM yyyy'

export const contractAddress = process.env.REACT_APP_API_CONTRACTADDRESS

export const systemAddress = process.env.REACT_APP_API_SYSTEMADDRESS

let tempWsUrl
if (localStorage.getItem('localhost_mode') !== 'true') {
  tempWsUrl = `${process.env.REACT_APP_API_WS}`
} else {
  tempWsUrl = `${process.env.REACT_APP_API_LOCALWS}`
}

export const wsUrl = tempWsUrl

export const SiteVersion = process.env.REACT_APP_API_VERSION

export const getTableId = ({ archive, workspaceId, folderId }) => {
  if (archive) {
    return 'contracts-table-archive'
  }
  if (folderId) {
    return `contracts-table-folder-${folderId}`
  }
  if (workspaceId) {
    return `contracts-table-workspace-${workspaceId}`
  }
  if (!archive && !workspaceId && !folderId) {
    return `contracts-table`
  }
  if (archive && (workspaceId || folderId)) {
    throw new TypeError(
      `archive=true and workspaceId=${workspaceId} should not be set at the same time`,
    )
  }

  throw new Error('Unhandled case')
}

export const CALLAPI_CACHE_LENGHT = 1000 /* ms */
