import { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { keyframes, useTheme } from 'styled-components'
import { useHotkeys } from 'react-hotkeys-hook'
import { v4 } from 'uuid'

import BaseModal from './BaseModal'
import Elevation from '../Elevation'
import Spacing from '../Spacing'
import { useResponsive } from '../../../hooks'
import Divider from '../Divider'
import { Header } from './Header'
import Description from './Description'
import Flex from '../Flex'

const slideInAnimation = keyframes`
  0% {
    transform: translateY(-800px);
  }
  100% {
    transform: translateY(0);
  }
`

const slideOutAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-600px);
  }
`

const opacityAnimation = keyframes`
  0% {
    opacity: 0
  }
  45% {
    opacity: 1
  }
  100% {
    opacity: 1
  }
`

const opacityOutAnimation = keyframes`
  0% {
    opacity: 1
  }
  75% {
    opacity: 0
  }
  100% {
    opacity: 0
  }
`

const OldModalBody = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const animationDuration = '0.29s'
let modalVisibilityQueue = []

const dictionaries = {
  xsmall: 416,
  medium: 560,
  large: 848,
}

const DEFAULT_SIZE_DIALOG = 488
const DEFAULT_SIZE = 'medium'
const Modal = ({
  buttons,
  visible,
  onClose,
  className,
  children,
  header,
  headerI18n,
  subHeader,
  subHeaderI18n,
  emptyHeader,
  animated,
  disableCloseButton,
  disableOverlayClick,
  termsConditions,
  type,
  rightHeader,
  width,
  containerWidth,
  noBottomLine,
  noExistModal,
  scrollRef,
  leftButtons,
  size,
  ...props
}) => {
  const [show, setShow] = useState(false)
  const [modalId] = useState(v4())

  const theme = useTheme()

  useEffect(() => {
    if (visible) {
      setShow(true)
      modalVisibilityQueue.push(modalId)
    } else {
      modalVisibilityQueue = modalVisibilityQueue.filter((id) => id !== modalId)
      setTimeout(() => setShow(false), 250)
    }
  }, [modalId, visible])

  useHotkeys(
    'esc',
    (e) => {
      const lastModal = modalVisibilityQueue[modalVisibilityQueue.length - 1]
      if (modalId === lastModal) {
        if (noExistModal) {
          modalVisibilityQueue = modalVisibilityQueue.filter((id) => id !== lastModal)
        }
        onClose(e)
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
  )

  const stopPropagation = useCallback((e) => e.stopPropagation(), [])

  const contentWidth = useMemo(() => {
    if (containerWidth) {
      return containerWidth
    }
    if (width) {
      return width
    }
    if (!size) {
      return type === 'dialog' ? DEFAULT_SIZE_DIALOG : dictionaries[DEFAULT_SIZE]
    }
    return dictionaries[size]
  }, [containerWidth, size, type, width])

  const BodyContainer = useMemo(() => {
    if (!children) {
      return null
    }
    if (termsConditions) {
      return (
        <Scroll withoutPadding={containerWidth} ref={scrollRef}>
          <div>{children}</div>
        </Scroll>
      )
    }
    if (type === 'dialog') {
      return (
        <Scroll withoutPadding={containerWidth} $width={contentWidth} dialog>
          <div>{children}</div>
        </Scroll>
      )
    }
    if (type === 'default') {
      return (
        <>
          {(subHeader || subHeaderI18n) && <Spacing size={theme.semantic.space['space-medium']} />}
          <div>
            <Divider />
          </div>
          <Scroll withoutPadding={containerWidth} $width={contentWidth} ref={scrollRef}>
            <div>{children}</div>
          </Scroll>
          {!noBottomLine ? (
            <div>
              <Divider />
            </div>
          ) : undefined}
        </>
      )
    }
    if (type === 'withoutLines') {
      return (
        <Scroll withoutPadding={containerWidth} $width={contentWidth} ref={scrollRef}>
          <div>{children}</div>
        </Scroll>
      )
    }
    if (type === 'oldModal') {
      return <OldModalBody>{children}</OldModalBody>
    }
    return <div>{children}</div>
  }, [
    children,
    containerWidth,
    contentWidth,
    noBottomLine,
    scrollRef,
    subHeader,
    subHeaderI18n,
    termsConditions,
    theme.semantic.space,
    type,
  ])

  if (!show) {
    return null
  }

  const dataTest = props['data-test'] || 'modal_base-modal'

  let Container = MainStyleModal

  if (termsConditions) {
    Container = TermsConditionsModal
  }

  return (
    <Container
      hidden={!show}
      options={{ strategy: 'fixed' }}
      onMouseDown={
        disableOverlayClick
          ? undefined
          : (e) => {
              if (noExistModal) {
                modalVisibilityQueue = modalVisibilityQueue.filter((id) => id !== modalId)
              }
              onClose(e)
            }
      }
      className={className}
      data-test={dataTest}
      isAnimated={!!animated}
      width={contentWidth}
      {...props}
    >
      <div onClick={stopPropagation} onMouseDown={stopPropagation}>
        {!emptyHeader && (
          <Header
            i18n={headerI18n}
            onClose={(e) => {
              if (noExistModal) {
                modalVisibilityQueue = modalVisibilityQueue.filter((id) => id !== modalId)
              }
              onClose(e)
            }}
            disableCloseButton={disableCloseButton}
            rightColumn={rightHeader}
            type={type}
            paddingBottom={
              !(subHeader || subHeaderI18n) && type !== 'dialog' && type !== 'oldModal'
            }
          >
            {header}
          </Header>
        )}
        {(subHeader || subHeaderI18n) && (
          <Description description={subHeader} descriptionI18n={subHeaderI18n} />
        )}
        {BodyContainer}
        {buttons && (
          <ButtonsContainer $type={type}>
            {leftButtons && <Flex>{leftButtons}</Flex>}
            <Flex>{buttons}</Flex>
          </ButtonsContainer>
        )}
      </div>
    </Container>
  )
}

export const Scroll = styled.div.attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  display: flex;
  flex: auto;
  overflow: auto;
  scroll-behavior: smooth;
  padding: ${({ $isGreaterOrEqualThan, theme, withoutPadding }) => {
    if (!withoutPadding) return '0'
    if ($isGreaterOrEqualThan('md')) return `0 0 0 ${theme.semantic.space['space-medium']}`
    return `0 0 0 ${theme.semantic.space['space-2x-small']}`
  }};

  > div {
    box-sizing: border-box;
    width: ${({ $isGreaterOrEqualThan, withoutPadding, $width }) => {
      if (withoutPadding) return undefined
      if ($isGreaterOrEqualThan('md')) return `${$width - 24}px`
      return `${$width - 16}px`
    }};
    display: block;
    padding: ${({ $isGreaterOrEqualThan, withoutPadding, theme, dialog }) => {
      if (withoutPadding) return '0'
      if ($isGreaterOrEqualThan('md'))
        return dialog
          ? `${theme.semantic.space['space-medium']} 0 0 ${theme.semantic.space['space-medium']}`
          : `${theme.semantic.space['space-medium']} 0 ${theme.semantic.space['space-medium']} ${theme.semantic.space['space-medium']}`
      return dialog
        ? `${theme.semantic.space['space-2x-small']} 0 0 ${theme.semantic.space['space-2x-small']}`
        : `${theme.semantic.space['space-2x-small']} 0 ${theme.semantic.space['space-2x-small']} ${theme.semantic.space['space-2x-small']}`
    }};
    height: 100%;
  }
`

export const ButtonsContainer = styled.div.attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  box-sizing: border-box;
  padding: ${({ $isGreaterOrEqualThan, theme }) =>
    $isGreaterOrEqualThan('md')
      ? theme.semantic.space['space-medium']
      : theme.semantic.space['space-2x-small']};
  background-color: ${({ $type, theme }) =>
    $type === 'default' && theme.semantic.palette.surface['surface-secondary']};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  > div {
    flex: auto;
    gap: ${({ theme }) => theme.semantic.space['space-2x-small']};
    &:last-child {
      justify-content: flex-end;
    }
  }
`

export const TermsConditionsModal = styled(BaseModal).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  ${Elevation} {
    position: absolute;
    max-width: initial;
    height: auto;
    overflow: auto;
    display: flex;

    ${Scroll} {
      overflow-x: hidden;
      > div {
        padding: 0;
      }
    }

    ${({ $isGreaterOrEqualThan }) =>
      $isGreaterOrEqualThan('lg')
        ? `
          // width: 1016px;
          top: 40px;
          bottom: 40px;
          padding: 0;
          `
        : `
           top: 80px;
           bottom: 16px;
           padding: 0;
           left: 16px;
           right: 16px;
      `}
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`

export const MainStyleModal = styled(BaseModal).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  /* refactor with caution! */
  > ${Elevation} /* non-animated modal */,
  > div > div > ${Elevation} /* animated modal */ {
    width: ${({ $isGreaterOrEqualThan }) => ($isGreaterOrEqualThan('md') ? 'fit-content' : 'auto')};
    height: ${({ $isGreaterOrEqualThan }) =>
      $isGreaterOrEqualThan('md') ? 'fit-content' : 'inherit'};

    min-width: ${({ $isGreaterOrEqualThan, width }) =>
      $isGreaterOrEqualThan('lg') ? `${width}px` : '0'};
    max-width: ${({ width }) => width}px;
    padding: 0;
    > div {
      max-height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;
    }
  }
`
export default styled(Modal).attrs(() => {
  const { isGreaterOrEqualThan } = useResponsive()
  return { $isGreaterOrEqualThan: isGreaterOrEqualThan }
})`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background: ${({ theme }) => theme.palette.overlay.background};
  animation: ${({ visible }) => (visible ? opacityAnimation : opacityOutAnimation)}
    ${animationDuration} cubic-bezier(0.2, 0, 0, 1);

  ${Elevation} {
    box-sizing: border-box;
    max-height: calc(100vh - 80px);
    overflow: hidden;

    animation: ${({ visible }) => (visible ? slideInAnimation : slideOutAnimation)}
      ${animationDuration} cubic-bezier(0.2, 0, 0, 1);
  }
`
