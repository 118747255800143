import { useMemo } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { useSelector } from 'react-redux'
import map from 'lodash/map'
import compact from 'lodash/compact'

import { FEATURES, PERMISSIONS } from '../../enums'
import useAccountType from '../useAccountType'
import {
  betaFeaturesEnabledSelector,
  companyMembershipSelector,
  featuresSelector,
  sharepointUsersSelector,
} from '../../store/selectors'

const init = {}

export const useSingletonPermissions = singletonHook(init, () => {
  const features = useSelector(featuresSelector)
  const sharepointUsers = useSelector(sharepointUsersSelector)
  const companyMembership = useSelector(companyMembershipSelector)
  const betaFeaturesEnabled = useSelector(betaFeaturesEnabledSelector)

  const [isIssuer] = useAccountType('issuer')
  const [isBasic] = useAccountType('basic')
  const [isInhubberAdmin] = useAccountType('admin')

  const cachedPermissions = useMemo(() => {
    const conditions = {
      [PERMISSIONS.FEATURES_ANALYSE_QNA]: () => features.indexOf(FEATURES.AnalyseQna) !== -1,
      [PERMISSIONS.FEATURES_ANALYSE_DOCUMENTS]: () =>
        features.indexOf(FEATURES.AnalyseDocuments) !== -1,

      [PERMISSIONS.SHAREPOINT_ENABLED]: () => sharepointUsers?.length > 0,

      [PERMISSIONS.USER_ISSUER]: () => isIssuer,
      [PERMISSIONS.USER_BASIC]: () => isBasic,
      [PERMISSIONS.USER_INHUBBER_ADMIN]: () => isInhubberAdmin,

      [PERMISSIONS.COMPANY_ADMIN]: () => companyMembership?.attributes.role === 'admin',

      [PERMISSIONS.BETA_FEATURES]: () => betaFeaturesEnabled,

      [PERMISSIONS.INVITE_FOR_QES]: () => features.indexOf(FEATURES.InviteForQes) !== -1,
    }
    const conditionResult = map(conditions, (condition, index) => (condition() ? index : undefined))
    return compact(conditionResult)
  }, [
    betaFeaturesEnabled,
    companyMembership?.attributes.role,
    features,
    isBasic,
    isInhubberAdmin,
    isIssuer,
    sharepointUsers?.length,
  ])

  return cachedPermissions
})
